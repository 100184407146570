import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import {
  DHI_TRANSITS_ERROR, DHI_TRANSITS_IN_PROGRESS, DHI_TRANSITS_REQUEST, DHI_TRANSITS_SUCCESS
} from 'src/store/dhiTransits/actions'

export const INITIAL_STATE = {
  data: null,
  lastFetched: null,
  isLoading: false,
  error: null,
}

function dhiTransitsReducer (state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case DHI_TRANSITS_REQUEST:
      return {
        ...state,
        data: null,
        lastFetched: null,
        error: null,
      }
    case DHI_TRANSITS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case DHI_TRANSITS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case DHI_TRANSITS_SUCCESS:
      return {
        ...state,
        data: payload,
        lastFetched: new Date().toISOString(),
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default dhiTransitsReducer
