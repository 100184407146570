import {
  EXTRAS_IN_PROGRESS,
  EXTRAS_ERROR,
  EXTRAS_SUCCESS,
} from 'src/store/extras/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function extrasReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case EXTRAS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case EXTRAS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case EXTRAS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default extrasReducer
