import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOnlineStatus as setOnlineStatusToStore } from 'src/store/ui/actions'
import { isOnlinePingSelector } from 'src/store/onlinePing/selectors'
import { getOnlineStatus } from './../utils/offline'
import { EMPX_PING_LOG_ENABLED } from './../utils/constants'
import { featureFlagManualOnlineEnabled } from './../store/preferences/selectors'
import { setOnlinePingStatus } from 'src/store/onlinePing/actions'

export const useOnlineStatus = (updateStore = false) => {
  const dispatch = useDispatch()
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus())
  const isOnlinePing = useSelector(isOnlinePingSelector)
  const manualOfflineEnabled = useSelector(featureFlagManualOnlineEnabled)

  const goOnline = () => setOnlineStatus(true)

  const goOffline = () => setOnlineStatus(false)

  useEffect(() => {
    window.addEventListener('online', goOnline)
    window.addEventListener('offline', goOffline)

    return () => {
      window.removeEventListener('online', goOnline)
      window.removeEventListener('offline', goOffline)
    }
  }, [])

  useEffect(
    () => {
      if (updateStore) {
        dispatch(setOnlineStatusToStore(onlineStatus))
        if (!onlineStatus) {
          dispatch(setOnlinePingStatus(onlineStatus))
        }
      }
    },
    [updateStore, onlineStatus]
  )
  if (EMPX_PING_LOG_ENABLED) {
    console.log(`onlineStatus [${onlineStatus}] isOnlinePing [${isOnlinePing}]`)
  }
  if (manualOfflineEnabled) {
    return onlineStatus && isOnlinePing
  }
  return onlineStatus
}

export default useOnlineStatus
