import { round } from 'src/utils/formatters'
import { calculateSafetyMargin } from 'src/utils/constraint'

const getAirDraftMaxDraftValues = (vessel, maxDraft) => {
  const vesselAirDraft = parseFloat(vessel.metadata && vessel.metadata.air_draft)
  const airDraftFromVessel = !isNaN(vesselAirDraft)
  let airDraftValue = null
  if (airDraftFromVessel) {
    airDraftValue = round(vesselAirDraft, 2)
  }
  const maxDraftValue = typeof maxDraft === 'number' ? round(maxDraft, 2) : null
  return {
    airDraftValue,
    maxDraftValue
  }
}
/*
  If vessel.metadata.air_draft is defined, use that in the
  calculation, otherwise derive it from vessel.height and maxDraft
*/
function calculateOverheadClearance({
  constraint,
  maxDraft,
  tideValue,
  allowances,
  vessel,
  overrides,
}) {
  const errors = []
  const airDraftMaxDraftValues = getAirDraftMaxDraftValues(vessel, maxDraft)
  const airDraftValue = airDraftMaxDraftValues.airDraftValue
  const maxDraftValue = airDraftMaxDraftValues.maxDraftValue

  // EMPX-535 maxDraft of 0 is valid, as airDraft = heightOverall - maxDraft
  if (maxDraftValue === null) {
    errors.push('Vessel max. draft is missing')
  }
  // EMPX-535 vessel airDraft of 0 is not valid, treat it as null.
  if (vessel.height == null && (airDraftValue === null || airDraftValue === 0)) {
    errors.push('Either Air Draft or HOA must be specified')
  }

  if (errors.length) {
    return { errors }
  }

  // use NaN for missing/non-numeric value, as empty string gets converted to 0.
  const heightOverall = round(vessel.height, 2)
  const overheadClearance = round(constraint.value, 2)
  const availableClearance = round(overheadClearance - tideValue, 2)
  const airDraft = airDraftValue !== null ? airDraftValue : heightOverall - maxDraftValue

  const { calculatedSafetyMargin: calculatedSafetyMarginRaw, unitUsed } = calculateSafetyMargin(constraint, airDraft)
  const calculatedSafetyMargin = !isNaN(overrides.safetyMargin) ? Number(overrides.safetyMargin) : calculatedSafetyMarginRaw

  const requiredClearance = airDraft + calculatedSafetyMargin + allowances
  const result = availableClearance - requiredClearance
  const rawResult = availableClearance - airDraft

  const variables = {
    maxDraftValue,
    airDraft,
    overheadClearance,
    availableClearance,
    requiredClearance,
    heightOverall,
    calculatedSafetyMargin,
    calculatedSafetyMarginRaw,
    tideValue,
    unitUsed
  }

  return { result, rawResult, variables }
}

export default calculateOverheadClearance
