import React from 'react'
import styled from 'styled-components'
import * as d3 from 'd3'
import Loader from 'src/components/atoms/Loader'
import { TideGraphConstraintMarker } from './TideGraphConstraintMarker'
import { TideGraphConstraintMarkerLabel } from './TideGraphConstraintMarkerLabel'
import { TideGraphTheme, TideGraphProps, Tides, TideMark, RiskViewModel, RiskAssessment } from './types'

const TideGraphContainer = styled.div`
    position: relative;
    overflow: hidden;

    .zoom-control {
        position: absolute;
        right: 70px;
        bottom: 40px;
    }

    .zoom-control button {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        margin-left: 20px;
        outline: none;
        cursor: pointer;
    }

    .zoom-control button:disabled {
        opacity: 0.3;
    }
`

const TideGraph: React.FC<TideGraphProps> = ({
    tides,
    startTide,
    minTime,
    maxTime,
    minTide,
    maxTide,
    width,
    height,
    theme,
    riskViewModels,
    riskViewModelsDedupedByLabel,
    tideArea,
    tideLine,
    x,
    y,
    start,
    lastConstraint,
    hasAnyConstraintFailed,
    isMasterView,
    formatTime,
    hoursMargin,
    zoom
}) => {

    const { margin } = theme
    const labelSmall = riskViewModelsDedupedByLabel.length > 5
    const tidesLoading = Object.values(tides).find(({ isLoading }) => isLoading)

    return (
        <TideGraphContainer>
            {
                tidesLoading ?

                    <Loader>Loading tide predictions</Loader> :

                !(isFinite(minTime) && isFinite(maxTime) && isFinite(minTide) && isFinite(maxTide)) ?

                    null : // R prefers to show no error message here if insufficient data

                width > 0 &&
                <>
                    <svg
                        width={width + margin.left + margin.right}
                        height={height + margin.top + margin.bottom}>
                
                    <defs>

                        <linearGradient id="grad-water" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor={theme.waterFillColor} stopOpacity="1" />  
                            <stop offset="1" stopColor={theme.waterFillColor} stopOpacity="0" />  
                        </linearGradient>

                        <linearGradient id="grad-bottom" x1="0%" y1="0%" x2="0%" y2="100%">  
                            <stop offset="0%" stopColor="white" stopOpacity="1" />
                            <stop offset="100%" stopColor="white" stopOpacity="0" />  
                        </linearGradient>

                        <linearGradient id="grad-left" x1="0%" y1="0%" x2="100%" y2="0%">  
                            <stop offset="0%" stopColor={theme.waterFillColor} stopOpacity="0" />
                            <stop offset="30%" stopColor={theme.waterFillColor} stopOpacity="0.25" />
                            <stop offset="50%" stopColor={theme.waterFillColor} stopOpacity="0.5" />
                            <stop offset="70%" stopColor={theme.waterFillColor} stopOpacity="0.75" />
                            <stop offset="100%" stopColor={theme.waterFillColor} stopOpacity="1" />  
                        </linearGradient>

                        <linearGradient id="grad-left2" x1="0%" y1="0%" x2="100%" y2="0%">  
                            <stop offset="0%" stopColor="white" stopOpacity="0" />
                            <stop offset="100%" stopColor="white" stopOpacity="1" />  
                        </linearGradient>

                        <linearGradient id="grad-right" x1="100%" y1="0%" x2="0%" y2="0%">  
                            <stop offset="0%" stopColor={theme.waterFillColor} stopOpacity="0" /> 
                            <stop offset="30%" stopColor={theme.waterFillColor} stopOpacity="0.25" />
                            <stop offset="50%" stopColor={theme.waterFillColor} stopOpacity="0.5" />
                            <stop offset="70%" stopColor={theme.waterFillColor} stopOpacity="0.75" /> 
                            <stop offset="100%" stopColor={theme.waterFillColor} stopOpacity="1" />  
                        </linearGradient>

                        <linearGradient id="grad-right2" x1="100%" y1="0%" x2="0%" y2="0%">  
                            <stop offset="0%" stopColor="white" stopOpacity="0" />  
                            <stop offset="100%" stopColor="white" stopOpacity="1" />  
                        </linearGradient>

                        <mask id="grad-bottom-mask" maskContentUnits="objectBoundingBox">  
                            <rect width="1" height="1" fill="url(#grad-bottom)" />  
                        </mask>

                        <mask id="grad-left-mask" maskContentUnits="objectBoundingBox">  
                            <rect width="1" height="1" fill="url(#grad-left)" />  
                        </mask>

                        <mask id="grad-left-mask2" maskContentUnits="objectBoundingBox">  
                            <rect width="1" height="1" fill="url(#grad-left2)" />  
                        </mask>

                        <mask id="grad-right-mask" maskContentUnits="objectBoundingBox">  
                            <rect width="1" height="1" fill="url(#grad-right)" />  
                        </mask> 

                        <mask id="grad-right-mask2" maskContentUnits="objectBoundingBox">  
                            <rect width="1" height="1" fill="url(#grad-right2)" />  
                        </mask> 
                    </defs>
                
                    <g transform={"translate(" + margin.left + "," + margin.top + ")"}>

                        <g 
                            id="tide-area" 
                            mask="url(#grad-bottom-mask)" 
                            shapeRendering="crispEdges">
                        {
                            riskViewModels.map(({ 
                                untilConstraintData, 
                                afterConstraintData,
                                prevConstraint,
                                nextConstraint
                            }, index) => {

                                return (
                                    untilConstraintData && 
                                    afterConstraintData &&
                                    <React.Fragment key={index}>
                                        <path 
                                            stroke="none"
                                            strokeLinecap="round"
                                            d={tideArea(untilConstraintData) as string}
                                            fill={
                                                prevConstraint ?
                                                "url(#grad-left)" :
                                                theme.waterFillColor
                                            }
                                        />
                                        <path 
                                            stroke="none"
                                            strokeLinecap="round"
                                            d={tideArea(afterConstraintData) as string}
                                            fill={
                                                nextConstraint ?
                                                "url(#grad-right)" :
                                                theme.waterFillColor
                                            }
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                        </g>
                

                        <g id="tide-lines">
                        {
                            riskViewModels.map(({ 
                                untilConstraintData, 
                                afterConstraintData, 
                                prevConstraint,
                                nextConstraint 
                            }, index) => 

                                untilConstraintData && 
                                afterConstraintData &&
                                <React.Fragment key={index}>
                                    <path 
                                        fill="none"
                                        stroke={theme.waterLineColor}
                                        strokeWidth={2}
                                        strokeDasharray={2}
                                        d={tideLine(untilConstraintData) as string}
                                        mask={prevConstraint && "url(#grad-left-mask2)"}
                                    />
                                    <path 
                                        fill="none"
                                        stroke={theme.waterLineColor}
                                        strokeWidth={2}
                                        strokeDasharray={2}
                                        d={tideLine(afterConstraintData) as string}
                                        mask={nextConstraint && "url(#grad-right-mask2)"}
                                    />
                                </React.Fragment>
                            )
                        }
                        </g>


                        <rect 
                            id="pilotage-window"
                            x={x(start)}
                            y={-margin.top}
                            width={x(lastConstraint.timeAtConstraint.valueOf()) - x(start)}
                            height={height + margin.top}
                            fill={hasAnyConstraintFailed ? theme.failColor : theme.passColor}
                            style={{opacity: 0.15}}
                        />


                        <g id="tide-station-markers">
                        {
                            riskViewModelsDedupedByLabel
                            .map(({ riskTime, riskTide, highlight, labelText }, index) => {

                                const X = x(riskTime)
                                const Y = y(riskTide)

                                return (
                                    <TideGraphConstraintMarker 
                                        key={index}
                                        index={index}
                                        x={X}
                                        y={Y}
                                        height={height}
                                        highlight={highlight}
                                        marginTop={margin.top}
                                        labelText={labelText}
                                        labelSmall={isMasterView || labelSmall}
                                        align="right"
                                        theme={theme}
                                    />
                                )
                            })
                        }

                        <TideGraphConstraintMarker
                            index={0}
                            x={x(start)}
                            y={startTide ? y(startTide): height}
                            height={height}
                            highlight={theme.textColor}
                            marginTop={margin.top}
                            labelText={`Start: ${formatTime(start)}`}
                            labelSmall={isMasterView || labelSmall}
                            align="left"
                            theme={theme}
                            invert
                        />
                        </g>

                        <g 
                            className="axis"
                            transform={"translate(0," + height + ")"}
                            ref={(node: SVGGElement) => {
                            const axis = d3.select(node).call(
                                d3.axisBottom(x)
                                .ticks(d3.timeHour.every(hoursMargin >= 6 ? 2 : 1))
                                .tickFormat(formatTime)
                            )

                            axis.selectAll("line, path")
                                .style("stroke", theme.textColor)

                            axis .selectAll("text")
                                .style("fill", theme.textColor)
                            }}
                        />
                        

                        <g 
                            className="axis"
                            ref={(node: SVGGElement) => {
                            const axis = d3.select(node).call(
                                d3.axisLeft(y)
                                .ticks(5)
                                .tickFormat(m => `${m}m`)
                            )

                            axis.selectAll("line, path")
                                .style("stroke", theme.textColor)

                            axis.selectAll("text")
                                .style("fill", theme.textColor)

                            // offset top/bottom label so it's not cut off
                            axis.select(".tick:last-child text")
                                .attr('dy', '0.75em')

                            axis.select(".tick:nth-child(2) text")
                                .attr('dy', '0em')
                        }}/>
                    
                    </g>
                </svg>
                {
                    theme.showZoom &&
                    <div className="zoom-control">
                        <button onClick={() => zoom(0.5)} disabled={hoursMargin >= 12}>-</button>
                        <button onClick={() => zoom(-0.5)} disabled={hoursMargin <= 0.5}>+</button>
                    </div>
                }
            </>
        }

        {
            !tidesLoading &&
            <div className="tide-labels">

            {
                riskViewModelsDedupedByLabel
                .map(({ riskTime, riskTide, highlight, labelText }, index) => {

                    const X = x(riskTime)
                    const Y = y(riskTide)
                    return (
                        <TideGraphConstraintMarkerLabel
                            key={index}
                            index={index}
                            x={X}
                            y={Y}
                            height={height}
                            highlight={highlight}
                            marginTop={margin.top}
                            marginLeft={margin.left}
                            labelText={labelText}
                            labelSmall={isMasterView || labelSmall}
                            align="right"
                            theme={theme}
                        />
                    )
                })
            }

            <TideGraphConstraintMarkerLabel
                index={0}
                x={x(start)}
                y={startTide ? y(startTide): height}
                height={height}
                highlight={theme.textColor}
                marginTop={margin.top}
                marginLeft={margin.left}
                labelText={`Start: ${formatTime(start)}`}
                labelSmall={isMasterView || labelSmall}
                align="left"
                theme={theme}
                invert
            />
        </div>
        }

        </TideGraphContainer>
    )
}

export default TideGraph
