import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'

const SecondaryButton = ({ children, ...props }) => (
  <Button variant="outlined" color="secondary" {...props}>
    {children}
  </Button>
)

SecondaryButton.propTypes = {
  children: PropTypes.node,
}

export default React.memo(SecondaryButton)
