import hazardIcon from 'src/assets/images/icons/hazard.png'

export const DRAWING_VERSION = 1

export const CHART_SYMBOL_RENDER_TYPE = {
  SVG: 'SVG',
  IMAGE: 'IMAGE',
}

/**
 * Chart symbols render definition
 *
 * Two types of chart symbols:
 * - SVG file
 * - An image file
 *
 * Notes:
 * - width/height are used on the chart as the actual size values
 * - for SVG, make sure viewBox is specified so it's sized correctly
 * - for SVG, in order to apply the users chosen colour to the symbol, make sure
 *   it has an element with id "style-root". Both stroke and fill will be
 *   applied, so to unset either of these on any child elements we need to set
 *   fill="none" or stroke="none".
 */
export const CHART_SYMBOL_RENDER_DATA = {
  SHIP_MAIN: {
    variant: 'SHIP_MAIN',
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.2 8.4">
      <g id="style-root" transform="translate(-0.9 -0.8)">
        <path shape-rendering="geometricPrecision" d="M1 2.6v4.8A1.6 1.6 0 0 0 2.6 9h31.941a1.6 1.6 0 0 0 1.014-.362l2.932-2.4a1.6 1.6 0 0 0 0-2.476l-2.932-2.4A1.6 1.6 0 0 0 34.54 1H2.6A1.6 1.6 0 0 0 1 2.6z" />
      </g>
    </svg>
    `,
    width: 38 * 1.5,
    height: 8 * 1.5,
  },
  SHIP: {
    variant: 'SHIP',
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    svg: `
<svg viewBox="0 0 28 13" xmlns="http://www.w3.org/2000/svg">
  <path shape-rendering="geometricPrecision" d="M2 6C1.44771 6 1 6.44772 1 7L1 11C1 11.5523 1.44772 12 2 12H18.5401C18.7511 12 18.9566 11.9333 19.1273 11.8094L21.8842 9.80944C22.4346 9.41015 22.4346 8.58985 21.8842 8.19056L19.1273 6.19056C18.9566 6.0667 18.7511 6 18.5401 6L2 6Z" fill="white" stroke="white"/>
  <path shape-rendering="geometricPrecision" d="M0 1.75L2.5 3.19338V0.306624L0 1.75ZM23 1.75L20.5 0.306624V3.19338L23 1.75ZM2.25 2H20.75V1.5H2.25V2 M26.25 5L24.8066 7.5L27.6934 7.5L26.25 5ZM26.25 13L27.6934 10.5L24.8066 10.5L26.25 13ZM26 7.25L26 10.75L26.5 10.75L26.5 7.25L26 7.25Z" fill="white" />
</svg>
`,
  },
  TUG: {
    variant: 'TUG',
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    svg: `
<svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="style-root" stroke-width="0">
    <mask id="tug-path-mask" fill="white">
        <path d="M4 0C1.79086 0 0 1.79086 0 4V8C0 10.2091 1.79086 12 4 12H14.2095C15.5917 12 16.9123 11.4278 17.8575 10.4194L19.4359 8.73552C20.878 7.19695 20.878 4.80304 19.4359 3.26448L17.8575 1.5806C16.9123 0.57216 15.5917 0 14.2095 0H4Z"/>
    </mask>
    <path d="M4 0C1.79086 0 0 1.79086 0 4V8C0 10.2091 1.79086 12 4 12H14.2095C15.5917 12 16.9123 11.4278 17.8575 10.4194L19.4359 8.73552C20.878 7.19695 20.878 4.80304 19.4359 3.26448L17.8575 1.5806C16.9123 0.57216 15.5917 0 14.2095 0H4Z" />
    <path d="M17.8575 1.5806L17.1279 2.26448L17.8575 1.5806ZM19.4359 8.73552L18.7063 8.05164L19.4359 8.73552ZM19.4359 3.26448L20.1655 2.5806L19.4359 3.26448ZM17.8575 10.4194L18.5871 11.1033L17.8575 10.4194ZM0 8H-1H0ZM-1 4V8H1V4H-1ZM4 13H14.2095V11H4V13ZM18.5871 11.1033L20.1655 9.4194L18.7063 8.05164L17.1279 9.73552L18.5871 11.1033ZM20.1655 2.5806L18.5871 0.896718L17.1279 2.26448L18.7063 3.94836L20.1655 2.5806ZM14.2095 -1H4V1H14.2095V-1ZM18.5871 0.896718C17.4528 -0.313408 15.8682 -1 14.2095 -1V1C15.3153 1 16.3717 1.45773 17.1279 2.26448L18.5871 0.896718ZM20.1655 9.4194C21.9682 7.49619 21.9682 4.5038 20.1655 2.5806L18.7063 3.94836C19.7879 5.10228 19.7879 6.89772 18.7063 8.05164L20.1655 9.4194ZM14.2095 13C15.8682 13 17.4528 12.3134 18.5871 11.1033L17.1279 9.73552C16.3717 10.5423 15.3153 11 14.2095 11V13ZM-1 8C-1 10.7614 1.23858 13 4 13V11C2.34315 11 1 9.65685 1 8H-1ZM1 4C1 2.34315 2.34314 1 4 1V-1C1.23857 -1 -1 1.23858 -1 4H1Z" fill="#95A6B6" mask="url(#tug-path-mask)"/>
    <path d="M16.5 3.57143C15 3.14286 14 1 13 1H14.5H16.5L19 4C18.6667 4 17.7 3.91429 16.5 3.57143 M16.5 8.42857C15 8.85714 14 11 13 11H14.5H16.5L19 8C18.6667 8 17.7 8.08571 16.5 8.42857 M2.5 2.5C3.5 1.5 3 1 4.5 1H2.5L1 1.5V4.5C1 3.5 1.5 3.5 2.5 2.5 M2.5 9.5C3.5 10.5 3 11 4.5 11H2.5L1 10.5V7.5C1 8.5 1.5 8.5 2.5 9.5Z" fill="#95A6B6"/>
  </g>
</svg>
`,
    width: 21 * 2,
    height: 12 * 2,
  },
  LADDER: {
    variant: 'LADDER',
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    svg: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <g id="style-root">
    <path stroke="none" d="M6.71 9.136h5.395v-1.3H6.71v1.3zm0 1.7v1.086c1.025.016 1.548.395 2.1 1.21l.06.09c.31.454.47.556 1.108.556.642 0 .81-.106 1.131-.565l.066-.094c.294-.424.579-.729.93-.925v-1.358H6.71zm0-4.7h5.395V3.753c0-2.545 2.21-3.603 5.616-3.603v1.7c-2.583 0-3.916.638-3.916 1.903v8.195c.794.098 1.275.473 1.865 1.208l.01.012c.41.511.558.61 1.042.61.48 0 .55-.05.828-.498l.019-.03c.57-.912 1.158-1.328 2.387-1.328v1.7c-.56 0-.658.068-.945.528l-.018.03c-.56.896-1.108 1.298-2.271 1.298-1.11 0-1.667-.372-2.368-1.246l-.01-.012c-.405-.505-.544-.598-1.003-.598-.405 0-.484.055-.77.467l-.069.098c-.614.878-1.266 1.291-2.524 1.291-1.261 0-1.91-.416-2.512-1.3l-.063-.092c-.28-.412-.353-.464-.762-.464-.424 0-.517.064-.829.5l-.052.073c-.63.878-1.254 1.283-2.472 1.283-1.204 0-1.914-.415-2.58-1.246l-.19-.242c-.25-.316-.327-.368-.518-.368v-1.7c.826 0 1.31.33 1.85 1.013l.184.233c.37.461.625.61 1.254.61.595 0 .752-.101 1.09-.573l.051-.071c.194-.27.38-.493.582-.67V3.753C5.01 1.208 7.22.15 10.627.15v1.7c-2.583 0-3.916.638-3.916 1.903v2.383zM0 17.722v-1.7c.826 0 1.31.33 1.85 1.013l.184.234c.37.46.625.61 1.254.61.595 0 .752-.102 1.09-.574l.051-.07c.6-.839 1.138-1.213 2.212-1.213 1.07 0 1.605.378 2.17 1.21l.06.09c.31.455.469.557 1.107.557.642 0 .81-.107 1.131-.566l.066-.094c.573-.824 1.107-1.197 2.166-1.197 1.088 0 1.633.367 2.329 1.234l.01.012c.41.512.558.61 1.042.61.48 0 .55-.05.828-.497l.019-.03c.57-.913 1.158-1.329 2.387-1.329v1.7c-.56 0-.658.069-.945.528l-.018.03c-.56.897-1.108 1.299-2.271 1.299-1.11 0-1.667-.373-2.368-1.246l-.01-.012c-.405-.505-.544-.599-1.003-.599-.405 0-.484.056-.77.468l-.069.098c-.614.878-1.266 1.29-2.524 1.29-1.261 0-1.91-.415-2.512-1.299l-.063-.093c-.28-.412-.353-.464-.762-.464-.424 0-.517.065-.829.501l-.052.073c-.63.878-1.254 1.283-2.472 1.283-1.204 0-1.914-.415-2.58-1.247-.045-.055-.18-.23-.19-.242-.25-.316-.327-.368-.518-.368z" />
  </g>
</svg>
    `,
    width: 48 * 0.8,
    height: 48 * 0.8,
  },
  PROPELLOR: {
    variant: 'PROPELLOR',
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    svg: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23">
  <g id="style-root">
    <path stroke="none" d="M14.51 8.886c1.546-1.425 3.532-2.58 5.231-2.58 2.17 0 3.546 1.759 3.546 4.912 0 3.154-1.677 5.07-3.308 5.07-1.63 0-1.915-1.58-3.361-2.737a7.91 7.91 0 0 0-.748-.535 3.997 3.997 0 0 1-1.095 1.865c1.149 1.425 2.006 3.102 2.006 4.566 0 2.17-1.758 3.546-4.912 3.546S6.8 21.316 6.8 19.685c0-1.63 1.58-1.915 2.737-3.361.22-.276.41-.543.567-.801a4.019 4.019 0 0 1-1.313-1.135c-1.548 1.431-3.54 2.593-5.245 2.593C1.377 16.981 0 15.223 0 12.069S1.677 7 3.308 7c1.63 0 1.915 1.58 3.362 2.737.553.442 1.07.758 1.552.946.2-.574.528-1.09.949-1.51C7.577 7.58 6.21 5.393 6.21 3.545 6.21 1.377 7.969 0 11.123 0s5.069 1.677 5.069 3.308c0 1.63-1.58 1.915-2.737 3.361-.388.486-.678.943-.87 1.373a3.982 3.982 0 0 1 1.926.844zM12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
  </g>
</svg>
    `,
    width: 48 * 0.8,
    height: 46 * 0.8,
  },
  HAZARD: {
    variant: 'HAZARD',
    type: CHART_SYMBOL_RENDER_TYPE.IMAGE,
    src: hazardIcon,
    width: 48,
    height: 42,
  },
  BERTHING_STAGE_VESSEL: {
    type: CHART_SYMBOL_RENDER_TYPE.SVG,
    width: 69,
    height: 289,
    svg: `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 289">
    <g id="style-root">
      <path fill="none" d="M.495 60.53v174.537c0 3.03.281 6.053.84 9.03l7.644 40.774a4.455 4.455 0 0 0 4.38 3.634h42.283a4.455 4.455 0 0 0 4.379-3.634l7.645-40.773c.558-2.978.839-6.002.839-9.031V60.523C57.725 20.498 46.627.495 35.475.495 24.316.495 12.569 20.504.496 60.53zm2.483 187.34h63.044l-.19.99H3.156zM0 200.792h69v1H0z" />
    </g>
  </svg>
    `,
  },
}

// Make pinch to zoom a little faster, 1.03 is too slow
export const CHART_COLOR_PALETTE = [
  '#000',
  '#1F8EFA',
  '#D71717',
  '#05C985',
  '#FFF',
]

export const CHART_TOOL = {
  FREEHAND: 'freehand',
  HIGHLIGHTER: 'highlighter',
  STRAIGHT_LINE: 'straight_line',
  ARROW: 'arrow',
  RULER: 'ruler',
  TEXT: 'text',
  ERASER: 'eraser',
}

export const CHART_STACK_ACTION = {
  ADD: 'add',
  MODIFY: 'modify',
  DELETE: 'delete',
  CLEAR_ALL: 'clear_all',
  ROTATE_BERTHING_STAGE_VESSEL: 'rotate_berthing_stage_vessel',
}

export const CHART_SHAPE_TYPE = {
  SYMBOL: 'symbol',
  TEXT: 'text',
}

export const CHART_MIN_SCALE = 1
export const CHART_MAX_SCALE = 5

export const SYMBOL_MIN_SCALE = 0.05
export const SYMBOL_MAX_SCALE = 17

export const CHART_ZOOM_SPEED_WHEEL = 1.03
export const CHART_ZOOM_SPEED_PINCH = 1.08
export const CHART_TEXT_INPUT_WIDTH = 180
export const CHART_TEXT_INPUT_HEIGHT = 30
export const CHART_BASE_ARROW_HEAD_SIZE = 12
export const CHART_MIN_ARROW_HEAD_SIZE = 3
export const CHART_MIN_ARROW_STROKE_WIDTH = 1
export const CHART_BASE_WIDTH = 1200
export const CHART_BASE_FONT_SIZE = 18
export const CHART_MIN_FONT_SIZE = 6
export const CHART_BASE_HIGHLIGHT_STROKE_WIDTH = 18
export const CHART_MIN_HIGHLIGHT_STROKE_WIDTH = 6
export const CHART_BASE_RULER_FONT_SIZE = 15

export const CHART_MENU_SECTION = {
  COLOR: 'color',
  TOOL: 'tool',
  SYMBOL: 'symbol',
}

export const CANVAS_ELEMENT_NAMES = {
  ROTATE_HANDLE: 'rotate_handle',
  RESIZE_HANDLE: 'resize_handle',
}

// The size at which a main vessel (displayed to-scale) with maximum dimension below
// this will have a ring drawn around it with an arrow pointing in the direction of movement
// (pixels)
export const VESSEL_POINTER_SIZE_THRESHOLD = 28
