import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import idx from 'idx'

import {
  DataTable,
  DataTableRowLabel,
  DataTableRowValue,
} from 'src/components/atoms/DataTable'
import { vesselSelector } from 'src/store/vessel/selectors'
import { vesselFieldDisplayConfigSelector } from 'src/store/preferences/selectors'

const VesselParticulars = () => {

  const { data } = useSelector(vesselSelector)
  const vesselFieldDisplayConfig = useSelector(vesselFieldDisplayConfigSelector)

  const fieldEnabled = (fieldName) =>
    !vesselFieldDisplayConfig ||
    !vesselFieldDisplayConfig.disabled_pilot_app[fieldName.replace(/^metadata_/, '')]

  return (
    <Box mt={3}>
      <Typography variant="h3">Ship Particulars</Typography>
      <Box mt={1}>
        <DataTable>
          <tbody>
            {fieldEnabled('IMO') &&
              <tr>
                <DataTableRowLabel>Vessel IMO</DataTableRowLabel>
                <DataTableRowValue colSpan={2} value={idx(data, d => d.IMO)} />
              </tr>
            }
            {fieldEnabled('vesselType') &&
              <tr>
                <DataTableRowLabel>Vessel Type</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.vesselType)}
                />
              </tr>
            }
            {fieldEnabled('mmsi') &&
              <tr>
                <DataTableRowLabel>MMSI</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.mmsi)}
                />
              </tr>
            }
            {fieldEnabled('call_sign') &&
              <tr>
                <DataTableRowLabel>Call Sign</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.call_sign)}
                />
              </tr>
            }
            {fieldEnabled('year_build') &&
              <tr>
                <DataTableRowLabel>Year Built</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.year_built)}
                />
              </tr>
            }
            {fieldEnabled('prop_no') &&
              <tr>
                <DataTableRowLabel>Prop No</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.prop_no)}
                />
              </tr>
            }
            {fieldEnabled('cpp_fixed') &&
              <tr>
                <DataTableRowLabel>CPP/Fixed</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.cpp_fixed)}
                />
              </tr>
            }
            {fieldEnabled('prop_azi') &&
              <tr>
                <DataTableRowLabel>Prop/AZI</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.prop_azi)}
                />
              </tr>
            }
            {fieldEnabled('prop_effect') &&
              <tr>
                <DataTableRowLabel>Prop Effect</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.prop_effect)}
                />
              </tr>
            }
            {fieldEnabled('rotation') &&
              <tr>
                <DataTableRowLabel>Rotation</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.rotation)}
                />
              </tr>
            }
            {fieldEnabled('rudder') &&
              <tr>
                <DataTableRowLabel>Rudder</DataTableRowLabel>
                <DataTableRowValue
                  colSpan={2}
                  value={idx(data, d => d.metadata.rudder)}
                />
              </tr>
            }
            {fieldEnabled('thr_fwd') &&
              <tr>
                <DataTableRowLabel>Thr. FWD</DataTableRowLabel>
                <DataTableRowValue value={idx(data, d => d.metadata.thr_fwd)} />
                <DataTableRowValue value={idx(data, d => d.metadata.thr_fwd)}>
                  kW
              </DataTableRowValue>
              </tr>
            }
            {fieldEnabled('thr_aft') &&
              <tr>
                <DataTableRowLabel>Thr. AFT</DataTableRowLabel>
                <DataTableRowValue value={idx(data, d => d.metadata.thr_aft)} />
                <DataTableRowValue value={idx(data, d => d.metadata.thr_aft)}>
                  kW
              </DataTableRowValue>
              </tr>
            }
            {fieldEnabled('main_engine') &&
              <tr>
                <DataTableRowLabel>Main Engine</DataTableRowLabel>
                <DataTableRowValue
                  value={idx(data, d => d.metadata.main_engine)}
                />
                <DataTableRowValue value={idx(data, d => d.metadata.main_engine)}>
                  kW
              </DataTableRowValue>
              </tr>
            }
          </tbody>
        </DataTable>
      </Box>
    </Box>
  )
}

export default React.memo(VesselParticulars)
