import { useSelector, useDispatch } from 'react-redux'

import useOnlineStatus from 'src/hooks/useOnlineStatus'
import {
  syncStatusStatSelector,
  syncStatusSelector,
  getEntitiesByStatus,
} from 'src/store/sync/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { startSync, resetSync } from 'src/store/sync/actions'
import { pilotageRequest } from 'src/store/pilotage/actions'
import { SYNC_STATUS } from 'src/utils/constants'

export const useSync = () => {
  const isOnline = useOnlineStatus()
  const dispatch = useDispatch()
  const stat = useSelector(syncStatusStatSelector)
  const status = useSelector(syncStatusSelector)
  const selectedPilotageUuid = useSelector(pilotageUuidSelector)
  const errors = useSelector(getEntitiesByStatus(SYNC_STATUS.ERROR))
  const start = async () => {
    const result = await dispatch(startSync())
    if (selectedPilotageUuid) {
      // for safety we refetch the pilotage data
      // if we are on the pilotage details page
      dispatch(pilotageRequest(selectedPilotageUuid))
    }
    return isOnline && result
  }
  const reset = async () => {
    await dispatch(resetSync())
    if (selectedPilotageUuid) {
      // for safety we refetch the pilotage data
      // if we are on the pilotage details page
      dispatch(pilotageRequest(selectedPilotageUuid))
    }
  }

  const needSync =
    status === SYNC_STATUS.ERROR ||
    status === SYNC_STATUS.IN_PROGRESS ||
    stat[SYNC_STATUS.PENDING] > 0 ||
    stat[SYNC_STATUS.ERROR] > 0

  return {
    stat,
    status,
    start,
    reset,
    errors,
    needSync,
    canSync: isOnline && needSync,
    inProgress: status === SYNC_STATUS.IN_PROGRESS,
  }
}

export default useSync
