import { createSelector } from 'reselect'
import { labelSorter } from 'src/utils/sorting'

export const routesLookupSelector = state => state.routesLookup

export const routeOptionsSelector = createSelector(
  routesLookupSelector,
  routes => {
    if (!routes.data) {
      return null
    }
    return routes.data
      .map(route => ({
        value: route.uuid,
        label: route.name,
        route
      }))
      .sort(labelSorter)
  }
)
