// Painstakingly transcribed from Figma, as it doesn't have a very good export function
// https://www.figma.com/file/vux5Gze3WCTcl76tg7SmoZ/%5BPoAL01%5D-UI-Pilot-App-eMPX?node-id=4541%3A20522

export type BollardPositionName
    = 'bow'
    | 'bow_starboard'
    | 'fwd_starboard'
    | 'beam_starboard'
    | 'aft_starboard'
    | 'quarter_starboard'
    | 'stern_starboard'
    | 'stern'
    | 'stern_port'
    | 'quarter_port'
    | 'aft_port'
    | 'beam_port'
    | 'fwd_port'
    | 'bow_port'

interface Origin {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
}

export interface BollardPositionConfig {
    bollardEditAnchorOrigin: Origin
    bollardEditTransformOrigin: Origin
    lineNumber: number
}

const positions: Record<BollardPositionName, BollardPositionConfig> = {
    bow:
    {
        bollardEditAnchorOrigin: { vertical: 'center', horizontal: 'left' },
        bollardEditTransformOrigin: { vertical: 'center', horizontal: 'right' },
        lineNumber: 1
    },
    bow_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 2
    },
    fwd_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 3
    },
    beam_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 4
    },
    aft_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 5
    },
    quarter_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 6
    },
    stern:
    {
        bollardEditAnchorOrigin: { vertical: 'center', horizontal: 'right' },
        bollardEditTransformOrigin: { vertical: 'center', horizontal: 'left' },
        lineNumber: 7
    },
    quarter_port:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 8
    },
    aft_port:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 9
    },
    beam_port:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 10
    },
    fwd_port:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 11
    },
    bow_port:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 12
    },
    stern_starboard:
    {
        bollardEditAnchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'top', horizontal: 'center' },
        lineNumber: 13
    },
    stern_port:
    {
        bollardEditAnchorOrigin: { vertical: 'top', horizontal: 'center' },
        bollardEditTransformOrigin: { vertical: 'bottom', horizontal: 'center' },
        lineNumber: 14
    }
}

export default positions

export const getBollardKey = (pos: BollardPositionName) => {
    const { lineNumber } = positions[pos]
    return `swl_${pos}_${lineNumber}`
}
