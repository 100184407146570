export interface TideExtreme {
  dateTime: string
  tide: number
}

export enum TimelineItemType {
  HighTide,
  LowTide,
  PilotageStart,
  Unknown,
}

export interface TimelineItem extends TideExtreme {
  dateObj: Date
  formattedTime: string
  formattedDate: string
  type: TimelineItemType
}
