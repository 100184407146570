/**
 * Returns the candidate point that is closest in 2D space to the given point.
 * @param point
 * @param candidatePoints
 */
export function findClosestPoint(point, candidatePoints) {
  const distances = candidatePoints.map(cpt => distanceBetween(point, cpt))
  const min = Math.min(...distances)
  const index = distances.indexOf(min)
  return candidatePoints[index]
}

export function distanceBetween(pt1, pt2) {
  return Math.sqrt(Math.pow(pt1[0] - pt2[0], 2) + Math.pow(pt1[1] - pt2[1], 2))
}
