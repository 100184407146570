export function rotatePoint(point, center, angle) {
  if (angle % 360 === 0) {
    return [...point]
  }

  const angleRadians = (angle * Math.PI) / 180

  const s = Math.sin(angleRadians)
  const c = Math.cos(angleRadians)

  const [cx, cy] = center
  let [x, y] = point
  x -= cx
  y -= cy

  const x2 = x * c - y * s
  const y2 = x * s + y * c

  return [x2 + cx, y2 + cy]
}
