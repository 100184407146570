import { createSelector } from 'reselect'

export const berthsStationsSelector = state => state.berthsStations

export const getBerthStationListSelector = createSelector(
  berthsStationsSelector,
  state => state && state.data
)

export const getBerthStationByUuidSelector = uuid =>
  createSelector(
    getBerthStationListSelector,
    list => list && list.find(item => item.uuid === uuid)
  )
