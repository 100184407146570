import { formatToTimeZone } from 'date-fns-timezone'
import { format } from 'date-fns'
import idx from 'idx'

import {
  CONSTRAINT_TYPE,
  DATE_FORMAT,
  missingValueText,
  MOVEMENT_TYPE,
  TIME_FORMAT,
} from 'src/utils/constants'
import { deriveMaxDraft } from 'src/store/pilotage/selectors'

export const ifNumberMissing = value => {
  if (isNaN(value) || typeof value !== 'number') {
    return true
  }
  return false
}

export const numberOrMissing = (value, unit, decimals) => {
  const unitText = unit ? ` ${unit}` : ''
  if (ifNumberMissing(value)) {
    return `${missingValueText}${unitText}`
  }
  const formattedValue =
    typeof decimals === 'number' ? value.toFixed(decimals) : value
  return `${formattedValue}${unitText}`
}

export const valueOrMissingText = value => {
  return value == null || value === '' ? missingValueText : value
}

export const formatMovementType = mvt => {
  switch (mvt) {
    case MOVEMENT_TYPE.DEPARTURE:
    case MOVEMENT_TYPE.ARRIVAL:
      return mvt.substring(0, 3)
    default:
      return mvt
  }
}

export const formatUsername = user =>
  user && user.metadata
    ? `${user.metadata.firstName} ${user.metadata.lastName}`
    : null

export const formatPilotageData = (pilotage, timeZone) => {
  const date = new Date(pilotage.date)
  const pilot = formatUsername(idx(pilotage, p => p.pilot))
  const secondaryPilot = formatUsername(idx(pilotage, p => p.pilotSecond))

  return {
    ...pilotage,
    dateObj: date,
    dateStr: formatToTimeZone(date, DATE_FORMAT, { timeZone }),
    timeStr: formatToTimeZone(date, TIME_FORMAT, { timeZone }),
    vessel: valueOrMissingText(idx(pilotage, p => p.vessel.name)),
    vesselFlagCode: valueOrMissingText(idx(pilotage, p => p.vessel.metadata && p.vessel.metadata.flag_code)),
    from: valueOrMissingText(idx(pilotage, p => p.fromLocation.name)),
    to: valueOrMissingText(idx(pilotage, p => p.toLocation.name)),
    route: valueOrMissingText(idx(pilotage, p => p.route.name)),
    maxDraft: numberOrMissing(deriveMaxDraft(pilotage), null, 2),
    pilot: valueOrMissingText(pilot),
    pilotOrig: pilotage.pilot,
    secondaryPilot: valueOrMissingText(secondaryPilot),
    pilotSecondOrig: pilotage.pilotSecond,
    movementType: pilotage.movementType,
    sideTo: valueOrMissingText(pilotage.sideTo),
    raw: pilotage
  }
}

/**
 * @param value
 * @param dp
 * @param rest - if a third argument is provided, it will be returned if the provided value is not a number
 * @returns {string|*}
 */
export const formatIfNumber = (value, dp, ...rest) => {
  if (typeof value !== 'number') {
    if (value && !isNaN(Number(value))) {
      return Number(value).toFixed(dp)
    }
    return rest.length ? rest[0] : value
  } else {
    return value.toFixed(dp)
  }
}

export const isNumber = (value) => {
  if (typeof value !== 'number') {
    if (value && !isNaN(Number(value))) {
      return true
    }
    return false
  } else {
    return true
  }
}

export const isNumbersNotMatch = (value1, value2, dp) => {
  let notMatch = false
  if (isNumber(value1)) {
    // EMPX-523 we don't clear override values in BE, so for now
    // we compare override with tide value
    // We need to do this anyway, admin can always re-upload a new tides
    // file, and this check will highlight that there is an override
    // change.
    const overrideValue = formatIfNumber(value1, dp)
    const origValue = formatIfNumber(value2, dp)
    if (overrideValue !== origValue) {
      notMatch = true
    }
  }
  return notMatch
}
export const padRight = (value, length, paddingCharacter) => {
  let result = ''
  for (let i = 0; i < length; i++) {
    result += value[i] || paddingCharacter
  }
  return result
}

export const toNumberIfExists = value =>
  typeof value === 'number' ? value : value ? Number(value) : null

export const nonNegativeOrNull = value => (value >= 0 ? value : null)

export const round = (value, dp, valueIfNotNumber = NaN) =>
  typeof value === 'number'
    ? Math.round(value * Math.pow(10, dp)) / Math.pow(10, dp)
    : valueIfNotNumber

export const formatSunriseSunset = (date, data) => {
  let results = ''
  if (data) {
    const timePart = data.split(' ')[1]

    let dateTimeConcat
    if (timePart) {
      dateTimeConcat = `${date} ${timePart}:00`
    } else {
      // EMPX-525 GeoStorm passes in time in HH:MM:SS, so just concatenate it with date
      dateTimeConcat = `${date} ${data}`
    }
    const dateTime = dateTimeConcat.replace(/-/g, '/')
    results = format(new Date(dateTime), TIME_FORMAT)
  }
  return results
}

export const formatConstraintType = type => {
  switch (type) {
    case CONSTRAINT_TYPE.UKC:
      return 'Tide_UKC'
    case CONSTRAINT_TYPE.OHC:
      return 'Tide_OHC'
    case CONSTRAINT_TYPE.UKC_DYNA:
      return 'Tide_UKC_Dyna'
    default:
      return 'Unknown'
  }
}

export const formatConstraintTypeShort = type => {
  switch (type) {
    case CONSTRAINT_TYPE.UKC:
      return 'UKC'
    case CONSTRAINT_TYPE.OHC:
      return 'OHC'
    case CONSTRAINT_TYPE.UKC_DYNA:
      return 'UKC DYNA'
    default:
      return ''
  }
}

export const roundTwoDecimalPlace = val => {
  return Math.round((val + Number.EPSILON) * 100) / 100
}

export const kwToHp = (kw) => {
  return kw * 1.34102
}