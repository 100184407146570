import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const CollapseButton = styled(ExpandMoreIcon)`
  && {
    border: 1px solid;
    border-radius: 100%;
    transform: rotate(0deg);
    transition: transform 400ms;
  }
`
export const CollapseButtonOpen = styled(ExpandMoreIcon)`
  && {
    border: 1px solid;
    border-radius: 100%;
    transform: rotate(180deg);
    transition: transform 400ms;
  }
`
