import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getTugs } from 'src/utils/api/tugs'

const TUGS_BASE = 'TUGS'

export const [
  TUGS_REQUEST,
  TUGS_SUCCESS,
  TUGS_ERROR,
  TUGS_CANCEL,
  TUGS_IN_PROGRESS,
] = createBasicActionTypes(TUGS_BASE)

export const tugsRequest = (portUuid, showErrorToast) => async dispatch => {
  dispatch(tugsInProgress())

  try {
    const response = await getTugs(portUuid)
    const data = getPayloadFromResponse(response)
    dispatch(tugsLoaded(data))
    return data
  } catch (error) {
    dispatch(tugsError(error, showErrorToast))
    return false
  }
}

export const tugsInProgress = () => createInProgressAction(TUGS_BASE)

export const tugsError = (error, showErrorToast) => dispatch => {
  dispatch(createErrorAction(TUGS_BASE, error))
  if (showErrorToast) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.LOAD_TUGS_ERROR,
      })
    )
  }
}

export const tugsLoaded = data => createSuccessAction(TUGS_BASE, data)
