import {
  CONSTRAINT_STATUS
} from 'src/utils/constants'
import styled from 'styled-components'

export const RiskWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  border: 2px ${({ dashed }) => dashed ? 'dashed' : 'solid'}
    ${({ theme, status }) =>
    status === CONSTRAINT_STATUS.PASSED
      ? theme.palette.success.main
      : status === CONSTRAINT_STATUS.FAILED
        ? theme.palette.error.main
        : ''};
  background-color: ${({ theme, status }) =>
    status === CONSTRAINT_STATUS.PASSED
      ? theme.palette.background.light
      : status === CONSTRAINT_STATUS.FAILED
        ? theme.palette.error.mainFaded
        : ''};
  padding: 14px 24px;
  cursor: pointer;

  & svg {
    display: block;
  }
`

export const ConstraintName = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  color: ${({ theme }) => theme.palette.text.primary};
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  word-break: break-word;
  overflow-wrap: break-word;
`

export const ConstraintValue = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-right: 24px;
`

export const Timestamp = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  color: ${({ theme }) => theme.palette.text.primary};
`
