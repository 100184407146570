import {
  CANVAS_ADD_STACK_ACTION,
  CANVAS_DISPLAY_ADD_LABEL,
  CANVAS_REDO,
  CANVAS_REMOVE_ADD_LABEL,
  CANVAS_RESET,
  CANVAS_SELECT_SHAPE,
  CANVAS_SET_STAGE_DIMENSIONS,
  CANVAS_SET_STAGE_OFFSET,
  CANVAS_SET_STAGE_ZOOM,
  CANVAS_SET_UNSAVED_CHANGES,
  CANVAS_UNDO,
} from 'src/store/canvas/actions'
import {
  CHART_SHAPE_TYPE,
  CHART_STACK_ACTION,
} from 'src/utils/drawingConstants'
import { canRotateSymbol } from 'src/utils/drawingHelpers'

export const DEFAULT_CANVAS_STATE = {
  stageDimensions: [0, 0],
  stageOffset: [0, 0],
  stageZoom: 1,
  actionsStack: [],
  actionsStackPosition: 0,
  selectedShapeName: null,
  activeLabelName: null,
  activeLabelValue: '',
  activeLabelPosition: null,
  hasUnsavedChanges: false,
  excludedShapes: [],
}

const canvasReducer = (state = {}, { type, payload }) => {
  if (!payload || !payload.stageUuid) {
    // all canvas actions have a payload with stageUuid, ignore actions without one
    return state
  }

  const { stageUuid, ...data } = payload
  const stateForStage = state[stageUuid] || { ...DEFAULT_CANVAS_STATE }

  switch (type) {
    case CANVAS_RESET:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          stageZoom: 1,
          actionsStack: [],
          actionsStackPosition: 0,
          selectedShapeName: null,
          activeLabelName: null,
          activeLabelValue: '',
          activeLabelPosition: null,
          hasUnsavedChanges: false,
          excludedShapes: [],
        },
      }
    case CANVAS_ADD_STACK_ACTION:
      const actionsStack =
        stateForStage.actionsStackPosition === 0
          ? [...stateForStage.actionsStack]
          : stateForStage.actionsStack.slice(
              0,
              stateForStage.actionsStackPosition
            )
      actionsStack.push(data)

      // Preselect new ships to make rotation quicker
      const selectedShapeName =
        data.action === CHART_STACK_ACTION.ADD &&
        data.type === CHART_SHAPE_TYPE.SYMBOL &&
        canRotateSymbol(data.attrs.variant)
          ? data.name
          : stateForStage.selectedShapeName

      let excludedShapes = stateForStage.excludedShapes
      if (stateForStage.actionsStackPosition < 0) {
        const actionsToBeRemoved = stateForStage.actionsStack.slice(
          stateForStage.actionsStackPosition
        )
        const shapeAddActionsToBeRemoved = actionsToBeRemoved.filter(
          ({ action }) => action === CHART_STACK_ACTION.ADD
        )
        excludedShapes = [
          ...excludedShapes,
          ...shapeAddActionsToBeRemoved.map(({ name }) => name),
        ]
      }

      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          actionsStack,
          actionsStackPosition: 0,
          selectedShapeName,
          hasUnsavedChanges: true,
          excludedShapes,
        },
      }
    case CANVAS_UNDO:
      if (
        stateForStage.actionsStackPosition <= -stateForStage.actionsStack.length
      ) {
        return state
      }
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          actionsStackPosition: stateForStage.actionsStackPosition - 1,
          selectedShapeName: null,
          hasUnsavedChanges: true,
        },
      }
    case CANVAS_REDO:
      if (stateForStage.actionsStackPosition === 0) {
        return state
      }
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          actionsStackPosition: stateForStage.actionsStackPosition + 1,
          selectedShapeName: null,
          hasUnsavedChanges: true,
        },
      }
    case CANVAS_SELECT_SHAPE:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          selectedShapeName: data.shapeName,
        },
      }
    case CANVAS_SET_STAGE_DIMENSIONS:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          stageDimensions: data.dims,
        },
      }
    case CANVAS_SET_STAGE_ZOOM:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          stageZoom: data.zoom,
        },
      }
    case CANVAS_SET_STAGE_OFFSET:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          stageOffset: data.offset,
        },
      }
    case CANVAS_DISPLAY_ADD_LABEL:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          activeLabelName: data.name || null,
          activeLabelValue: data.text || '',
          activeLabelPosition: [data.x, data.y],
        },
      }
    case CANVAS_REMOVE_ADD_LABEL:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          activeLabelPosition: null,
          activeLabelName: null,
          activeLabelValue: '',
        },
      }
    case CANVAS_SET_UNSAVED_CHANGES:
      return {
        ...state,
        [stageUuid]: {
          ...stateForStage,
          hasUnsavedChanges: data.hasUnsavedChanges,
        },
      }
    default:
      return state
  }
}

export default canvasReducer
