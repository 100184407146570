import {
  BERTHS_STATIONS_IN_PROGRESS,
  BERTHS_STATIONS_ERROR,
  BERTHS_STATIONS_SUCCESS,
} from 'src/store/berthsStations/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function berthsStationsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case BERTHS_STATIONS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case BERTHS_STATIONS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case BERTHS_STATIONS_SUCCESS:
      return {
        ...state,
        // TODO: find a better fix. There is an issue which causes an infinite
        //  render loop in PilotageDetails component due to the data structure of
        //  GET /berths_stations being different to the fromLocation/toLocation
        //  data structure on the pilotage object. This issue can be reproduced
        //  by removing this workaround, then trying to edit the from location and
        //  the route of a pilotage, then save the changes.
        data: payload.map(({ charts, port, ...rest }) => rest),
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default berthsStationsReducer
