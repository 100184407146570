import { WeatherLocation } from 'src/types/WeatherLocation'
import { getWeatherLocations } from 'src/utils/api/weatherLocations'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { addErrorToast } from '../toast/actions'

export type WeatherLocationsAction
  = {
    type: 'WEATHER_LOCATIONS_LIST_REQUEST'
    portUuid: string
  }
  | {
    type: 'WEATHER_LOCATIONS_LIST_SUCCESS'
    portUuid: string
    weatherLocations: WeatherLocation[]
  }
  | {
    type: 'WEATHER_LOCATIONS_LIST_ERROR'
    portUuid: string
    error: Error
  }

type Dispatch = (action: WeatherLocationsAction) => void

export const weatherLocationsRequest = (portUuid: string, showErrorToast = false) => async (dispatch: Dispatch) => {

  dispatch({ type: 'WEATHER_LOCATIONS_LIST_REQUEST', portUuid })

  try {
    const weatherLocations = await getWeatherLocations(portUuid)
    dispatch({
      type: 'WEATHER_LOCATIONS_LIST_SUCCESS',
      portUuid,
      weatherLocations: weatherLocations || []
    })
    return weatherLocations

  } catch (error) {
    dispatch({
      type: 'WEATHER_LOCATIONS_LIST_ERROR',
      portUuid,
      error
    })
    if (showErrorToast) {
      if (showErrorToast) {
        dispatch(
          addErrorToast({
            message: TOAST_MESSAGES.LOAD_WEATHER_LOCATIONS_ERROR,
          }) as any
        )
      }
    }
  }
}
