import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-konva'

const ImageSymbol = ({ renderData, ...props }) => {
  const { width, height, src } = renderData

  const image = new window.Image()
  image.src = src

  return (
    <Image
      offsetX={0.5 * width}
      offsetY={0.5 * height}
      width={width}
      height={height}
      image={image}
      {...props}
      fill="transparent"
    />
  )
}

ImageSymbol.propTypes = {
  renderData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
  }),
  color: PropTypes.string,
}

export default React.memo(ImageSymbol)
