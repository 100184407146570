import React from 'react'
import styled from 'styled-components'
import { TideGraphConstraintMarkerProps } from './TideGraphConstraintMarker'

const TideGraphConstraintMarkerLabelContainer = styled.div`
    position: absolute;
    z-index: 10;

    .constraint-marker-bg {
        position: absolute;
        font-family: monospace;
        padding: 2px 6px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .constraint-point {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        display: block;
        margin: 0px 10px 0px 0px;
    }
`

export const TideGraphConstraintMarkerLabel: React.FC<TideGraphConstraintMarkerProps> = ({
    index,
    x,
    height,
    highlight,
    marginTop,
    marginLeft = 0,
    labelText,
    labelSmall,
    align,
    theme,
    invert
}) => {

const yOffset = (index + 1) * 20
const top = invert ? (height - yOffset) : (-marginTop + yOffset)
const labelHeight = labelSmall ? 14 : 16
const labelPad = labelHeight / 2
const left = align === 'left'
const fontSize = labelSmall ? 10 : 14

return (
    <TideGraphConstraintMarkerLabelContainer style={{
        left: left ? `${x + marginLeft + labelPad}px` : `${x + marginLeft - labelPad}px`,
        top: `${top - labelPad}px`,
        lineHeight: `${fontSize}px`
    }}>

        <span className="constraint-marker-bg" 
            style={{
                background: theme.labelBackgroundColor,
                color: theme.labelTextColor,
                fontSize,
                lineHeight: `${fontSize}px`,
                right: left ? '0px' : undefined
            }}>
            {!left && <span className="constraint-point" style={{background: highlight}}></span>}
            {labelText}
            {left && <span className="constraint-point" style={{background: highlight, margin: '0px 0px 0px 10px'}}></span>}
        </span>

    </TideGraphConstraintMarkerLabelContainer>
)
}
