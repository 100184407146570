import { Box } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/Place'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import {
  numberOrMissing,
  valueOrMissingText
} from 'src/utils/formatters'
import styled from 'styled-components'
import { ConstraintName, ConstraintValue, RiskWrapper, Timestamp } from './../RiskAssessmentConstraint/RiskAssessmentCommon'
import { formatIfNumber } from './../../../utils/formatters'
import TidalRateLabel from 'src/components/atoms/TidalRateLabel'

const PlaceIconEx = styled(PlaceIcon)`
&& {
  width: 43px;
  font-size: 30px;
}
`
const RiskWrapperWaypoint = styled(RiskWrapper)`
&& {
  padding: 8px 24px;
  border: 2px ${({ dashed }) => dashed ? 'dashed' : 'solid'} #FFF;
}
`

const RiskAssessmentWaypoint = ({
  data,
  timezone,
  tideExtremes,
  tideRates,
  tideRateSource,
  hideWaypointLocation,
  ...props
}) => {

  const {
    routeConstraint,
    tideRateStationConfig,
    tideRateStationName,
    timeAtConstraint
  } = data

  const { constraint } = routeConstraint
  const time = timeAtConstraint

  let timeStr = null
  if (time) {
    const dateLuxon = DateTime.fromMillis(time.valueOf(), { zone: timezone })
    timeStr = dateLuxon.toFormat('HH:mm')
  }

  let constraintTypeIcon = <PlaceIconEx/>
  let rawResultLabel = 'Latitude'
  let resultLabel = 'Longitude'
  let constraintStatusIcon = null

  const isHideConstraint = formatIfNumber(data.overrides.hideConstraint, 0, '0') === '1'

  return (
    <RiskWrapperWaypoint {...props} dashed={isHideConstraint}>
      <Box p={0} display="flex" alignItems="center">

        <Box flex="0 0 auto" pr={2}>
          {constraintTypeIcon}
        </Box>

        <Box
          flex={3}
          pr={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <ConstraintName>{constraint.name}</ConstraintName>
            {
              tideRateSource === 'config' &&
              tideRateStationConfig &&
              tideRateStationConfig.enabled &&
              tideExtremes &&
              tideExtremes.data &&
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={tideRateStationName}
                config={tideRateStationConfig}
                tideExtremes={tideExtremes.data}
                isLoading={tideExtremes.isLoading}
                time={new Date(time).valueOf()}
              />
            }
            {
              tideRateSource === 'csv' &&
              tideRates && tideRates.data &&
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={tideRateStationName}
                tideRates={tideRates.data}
                isLoading={tideRates.isLoading}
                time={new Date(time).valueOf()}
              />
            }
          </div>
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={50}
        >
          <span>Time</span>
          <Timestamp>{valueOrMissingText(timeStr)}</Timestamp>
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={50}
        >
          {
            !hideWaypointLocation && rawResultLabel &&
            <>
              <span>{rawResultLabel}</span>
              <ConstraintValue>{numberOrMissing(constraint.location.latitude, null, 6)}</ConstraintValue>
            </>
          }
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={50}
        >
          {!hideWaypointLocation &&
          <>
            <span>{resultLabel}</span>
            <ConstraintValue>
              {numberOrMissing(constraint.location.longitude, null, 6)}
            </ConstraintValue>
          </>
          }
        </Box>

        <Box width={30}>
          {constraintStatusIcon}
        </Box>
      </Box>
    </RiskWrapperWaypoint>
  )
}

RiskAssessmentWaypoint.propTypes = {
  data: PropTypes.object,
  timezone: PropTypes.string,
  tideExtremes: PropTypes.object,
  tideRates: PropTypes.object,
  tideRateSource: PropTypes.string,
  hideWaypointLocation: PropTypes.bool
}

export default RiskAssessmentWaypoint
