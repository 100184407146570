import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { string } from 'yup'
import styled from 'styled-components'
import idx from 'idx'
import {
  Box,
  InputAdornment,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import EmailIcon from '@material-ui/icons/Email'

import ButtonRow from 'src/components/atoms/ButtonRow'
import PrimaryButton from 'src/components/atoms/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import {
  isAssignedToActivePilotageSelector,
  pilotageSelector,
  // EMPX-178 EMPX-183 handled in BE
  // isPilotageArchivedSelector,
  // isPilotageCancelledSelector,
  pilotageUuidSelector,
} from 'src/store/pilotage/selectors'
import { isAdminSelector } from 'src/store/auth/selectors'
import { downloadPdfRequest, pilotageRequest } from 'src/store/pilotage/actions'
import Modal from 'src/components/molecules/Modal'
import { sharePilotagePlan } from 'src/utils/api/pilotages'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { vesselSelector } from 'src/store/vessel/selectors'
import TextInput from 'src/components/atoms/TextInput'
import FormFieldLabel from 'src/components/atoms/FormFieldLabel'
import SubHeading from 'src/components/atoms/SubHeading'
import uniqueValues from 'src/utils/uniqueValues'
import { timezoneSelector, selectedPortSelector } from 'src/store/ports/selectors'
import { formatToTimeZone } from 'date-fns-timezone'

const AddButton = styled(SecondaryButton)`
  && {
    font-size: 24px;

    min-width: auto;
  }
`

const DownloadPdfButton = styled(SecondaryButton)`
  && {
    width: 180px;
  }
`

const ConfirmSendButton = styled(PrimaryButton)`
  && {
    width: 150px;
  }
`

const StyledCancelIcon = styled(CancelIcon)(
  ({ theme }) => `
    color: ${theme.palette.secondary.contrastText};
    cursor: pointer;
`
)

const isValidEmail = value =>
  string()
    .email()
    .isValidSync(value)

const getFieldError = (items, val, index) => {
  if (!val || !val.trim()) {
    return 'Required field'
  }
  if (!isValidEmail(val)) {
    return 'Invalid email'
  }
  if (items.indexOf(val) !== index) {
    return 'Duplicate email'
  }
  return null
}

const calculateErrors = items => {
  return items.map((val, index) => getFieldError(items, val, index))
}

const SharePilotagePlan = () => {
  const dispatch = useDispatch()
  const isOnline = useOnlineStatus()
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const { data } = useSelector(pilotageSelector)
  const timeZone = useSelector(timezoneSelector)
  const isAdmin = useSelector(isAdminSelector)
  const { data: vessel } = useSelector(vesselSelector)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [isSending, setIsSending] = useState(false)
  const [emails, setEmails] = useState([])
  const selectedPort = useSelector(selectedPortSelector)

  // EMPX-178 EMPX-183 handled in BE
  // const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  // const isPilotageCancelled = useSelector(isPilotageCancelledSelector)

  const existingEmails = idx(vessel, data => data.emails)
  const commonEmails = idx(selectedPort, data => data.commonEmails)

  useEffect(
    () => {
      // Start with empty string in the array so we have a single empty input.
      const existingEmailsCleansed = Array.isArray(existingEmails) && existingEmails.length > 0 ? uniqueValues(existingEmails) : []
      const commonEmailsCleansed = Array.isArray(commonEmails) && commonEmails.length > 0 ? uniqueValues(commonEmails) : []
      const combinedEmails = uniqueValues(existingEmailsCleansed.concat(commonEmailsCleansed))

      const emails = combinedEmails.length > 0 ? combinedEmails : ['']
      setEmails(emails)

      // If there are already multiple emails or the first one does already exist
      // run the validation straight away.
      if (emails.length > 1 || emails[0]) {
        updateValidation(emails)
      } else {
        setErrors(emails.map(() => ''))
      }
    },
    [existingEmails]
  )

  const downloadPdf = async () => {
    setIsPdfLoading(true)
    const response = await dispatch(downloadPdfRequest(pilotageUuid))
    setIsPdfLoading(false)
    if (response) {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/octet-stream',
        })
      )
      const link = document.createElement('a')
      link.href = url
      const vesselNameNoSymbols = data.vessel.name.replace(/[^a-zA-Z0-9 ]/g, '')
      const vesselNameTrimSpaces = vesselNameNoSymbols.replace(/  +/g, ' ').trim()
      const timstamp = formatToTimeZone(data.date, 'YYYYMMDD', { timeZone })
      const filename = `${timstamp}_pilotage_plan_${vesselNameTrimSpaces}_${data.movementType}.pdf`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // update causes a status update, so refetch the latest from the BE
      dispatch(pilotageRequest(pilotageUuid))
    }
  }

  const onClose = () => setIsConfirmModalOpen(false)

  const sendEmail = async () => {
    setIsSending(true)
    let isShareSuccessful
    try {
      await sharePilotagePlan(pilotageUuid, emails.filter(email => !!email))
      isShareSuccessful = true
      setIsSending(false)
      dispatch(
        addSuccessToast({
          message: TOAST_MESSAGES.SHARE_PILOTAGE_SUCCESS,
        })
      )
      onClose()
    } catch (e) {
      setIsSending(false)
      dispatch(
        addErrorToast({
          message: TOAST_MESSAGES.SHARE_PILOTAGE_ERROR,
        })
      )
    }

    if (isShareSuccessful) {
      // update causes a status update, so refetch the latest from the BE
      dispatch(pilotageRequest(pilotageUuid))
    }
  }

  const addEmail = () => {
    setEmails(emails => [...emails, ''])
    setErrors(errors => [...errors, null])
  }

  const removeEmail = index => {
    const updatedEmails = [
      ...emails.slice(0, index),
      ...emails.slice(index + 1),
    ]
    setEmails(updatedEmails)
    updateValidation(updatedEmails)
  }

  const updateEmail = (index, value) => {
    const updatedEmails = [
      ...emails.slice(0, index),
      value,
      ...emails.slice(index + 1),
    ]
    setEmails(updatedEmails)

    // If the field was invalid and becomes valid while the user is focused
    // on the field, update the validation immediately
    if (errors[index] && !getFieldError(updatedEmails, value, index)) {
      updateValidation(updatedEmails)
    }
  }

  const updateValidation = (items = emails) => {
    setErrors(calculateErrors(items))
  }

  const anyInvalid = () => {
    const errors = calculateErrors(emails)
    return !!errors.find(error => !!error)
  }

  return (
    <>
      <Typography variant="h2">Share Pilotage Plan</Typography>
      <SubHeading>
        When you have finished creating your plan you can share it with the
        vessel, download and print.
      </SubHeading>
      <InfoBox extraPadding mt={3}>
        <FormFieldLabel htmlFor="input-vessel-email-1">
          Email Address
        </FormFieldLabel>
        {emails.map((email, index) => {
          const isReadOnly = !(isOnline && (isAssigned || isAdmin))
          return (
            <Box key={index} display="flex" mt={index === 0 ? 0 : 2}>
              <Box flex={1} pr={2}>
                <TextInput
                  placeholder="Email address"
                  readOnly={isReadOnly}
                  id={`vessel-email-${index + 1}`}
                  value={email}
                  onChange={value => updateEmail(index, value)}
                  onBlur={isReadOnly ? null : () => updateValidation()}
                  error={errors[index]}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment
                        position="end"
                        onClick={() => removeEmail(index)}
                      >
                        <StyledCancelIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                width={50}
                pb={index === emails.length - 1 && errors[index] ? 3 : 0}
              >
                {index === emails.length - 1 && (
                  <AddButton
                    disabled={anyInvalid() || isReadOnly}
                    onClick={addEmail}
                  >
                    +
                  </AddButton>
                )}
              </Box>
            </Box>
          )
        })}
        <ButtonRow pt={4}>
          <PrimaryButton
            disabled={
              !(isOnline && (isAssigned || isAdmin)) ||
              anyInvalid() ||
              // EMPX-178 EMPX-183 handled in BE
              // (isPilotageArchived || isPilotageCancelled) ||
              isSending
            }
            onClick={() => setIsConfirmModalOpen(true)}
          >
            Send To Vessel
          </PrimaryButton>
          <DownloadPdfButton
            disabled={isPdfLoading || !isOnline}
            onClick={downloadPdf}
          >
            {isPdfLoading ? <CircularProgress size={20} /> : 'Download PDF'}
          </DownloadPdfButton>
        </ButtonRow>
      </InfoBox>
      <Modal
        heading="Share with Vessel"
        open={isConfirmModalOpen}
        onClose={onClose}
      >
        <Typography variant="h4">
          Are you sure you want to share this pilotage with these email
          addresses?
        </Typography>
        <Box mt={1}>
          <List>
            {emails
              .filter(email => email && email.trim())
              .map((email, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <EmailIcon color="secondary" />
                  </ListItemIcon>
                  <Typography variant="body2">{email}</Typography>
                </ListItem>
              ))}
          </List>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={6}>
          <ConfirmSendButton onClick={sendEmail} disabled={isSending}>
            {isSending ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              'Send'
            )}
          </ConfirmSendButton>
        </Box>
      </Modal>
    </>
  )
}

export default SharePilotagePlan
