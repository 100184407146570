import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  Box,
  Dialog,
  DialogActions,
  Slide,
  Typography,
  Fade,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import useSync from 'src/hooks/useSync'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import { getOfflineChangedPilotages } from 'src/store/pilotageList/selectors'
import PilotageTable from 'src/components/organisms/PilotageTable'
import { timezoneSelector } from 'src/store/ports/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import { getEntitiesToSyncByType } from 'src/store/sync/selectors'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import SyncStatusIcon from 'src/components/molecules/SyncStatusIcon'
import { createSyncUuid } from 'src/utils/sync/vessel'
import FullscreenWrapper from 'src/components/atoms/FullscreenWrapper'

const ErrorMessage = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.background.dark};
  border-radius: 4px;
  color: ${theme.palette.error.main};
  padding: ${theme.spacing(1, 3)};
`
)

const ListTitle = styled(Typography).attrs({
  variant: 'h3',
})`
  && {
    margin: ${({ theme }) => theme.spacing(2)}px 0;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Sync = ({ open = true }) => {
  const { errors, start, reset, canSync, needSync, inProgress } = useSync()

  const timezone = useSelector(timezoneSelector)
  const vesselEntities = useSelector(
    getEntitiesToSyncByType(SYNC_ENTITY_TYPES.PILOTAGE_VESSEL)
  )
  const changedVessels = useMemo(
    () => vesselEntities.map(item => item.entity && item.entity.data),
    [vesselEntities]
  )
  const changedPilotages = useSelector(getOfflineChangedPilotages)
  const formattedData = useMemo(
    () => changedPilotages.map(item => formatPilotageData(item, timezone)),
    [changedPilotages, timezone]
  )

  return (
    <Dialog
      open={open}
      fullScreen
      heading="Sync"
      disableBackdropClick
      disableEscapeKeyDown
      TransitionComponent={Transition}
    >
      <FullscreenWrapper>
        <Typography variant="h1">Sync back your changes</Typography>

        <Box mt={3}>
          You made changes while you were offline. You need to sync them back to
          the server.
        </Box>
        {changedPilotages && changedPilotages.length > 0 && (
          <>
            <ListTitle>Changed pilotages</ListTitle>
            <PilotageTable
              data={formattedData}
              timezone={timezone}
              showOfflineReadyStatus={false}
              showSyncStatus
              onRowClick={() => {}}
              isAscending
            />
          </>
        )}

        {changedVessels && changedVessels.length > 0 && (
          <>
            <ListTitle>Changed vessels</ListTitle>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>IMO</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Sync</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {changedVessels.map(vessel => {
                  const syncUuid = createSyncUuid(vessel.IMO)
                  return (
                    <TableRow key={vessel.IMO}>
                      <TableCell>{vessel.IMO}</TableCell>
                      <TableCell>{vessel.name}</TableCell>
                      <TableCell>
                        <SyncStatusIcon uuid={syncUuid} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </>
        )}

        <Fade in={!!(errors && errors.length)}>
          <ErrorMessage>
            An error occurred and some changes were not able to by synced.
          </ErrorMessage>
        </Fade>

        {canSync && needSync && (
          <DialogActions>
            <SecondaryButton disabled={inProgress} onClick={reset}>
              Abandon changes
            </SecondaryButton>
            <PrimaryButton disabled={inProgress || !canSync} onClick={start}>
              {!errors || !errors.length ? 'Start Sync' : 'Retry sync'}
            </PrimaryButton>
          </DialogActions>
        )}
      </FullscreenWrapper>
    </Dialog>
  )
}
Sync.propTypes = {
  open: PropTypes.bool,
}

export default Sync
