import React from 'react'
import { Group, Image, Path } from 'react-konva'

import rotateImageSrc from 'src/assets/images/icons/rotate.png'
import resizeImageSrc from 'src/assets/images/icons/resize.png'
import { ChartSymbol } from 'src/types/ChartShape'
import { getTheme } from 'src/styles/theme'
import { CANVAS_ELEMENT_NAMES } from 'src/utils/drawingConstants'

interface Props {
  shape: ChartSymbol
  zoom: number
  radius: number
  angle: number
  chartDimensions: number[]
  chartOffset: number[]
  dragOffset: number[]
  resizeEnabled: boolean
}

/**
 * ChartVesselControls is used to allow the user to manipulate a vessel symbol
 * either by rotating it or resizing it (if the chart is not to scale or the
 * vessel dimensions are missing). It displays a circle/ring around the active
 * selected vessel with controls placed on the ring.
 */
const ChartVesselControls: React.FC<Props> = ({
  shape,
  zoom,
  radius: _radius,
  angle,
  chartDimensions,
  chartOffset,
  dragOffset,
  resizeEnabled,
}) => {
  // Use the angle from props if it's number, otherwise use the value stored on
  // the shape, otherwise default to 0 (no rotation)
  const rotation = (angle === null ? shape.attrs.rotation : angle) || 0

  // [cx, cy] represents the center coordinate of the vessel controls
  let { x: cx, y: cy } = shape.attrs
  cx = chartDimensions[0] * (cx + dragOffset[0]) + chartOffset[0]
  cy = chartDimensions[1] * (cy + dragOffset[1]) + chartOffset[1]

  // Each handle on the controls (rotation handle, resize handle) will have an
  // equal fixed size, regardless of zoom
  const handleRadius = 20 / zoom

  // Add the handle radius to the specified radius value, and set a minimum size.
  const radius = Math.max(64 / zoom, _radius + handleRadius)

  // Calculate the ring border dash using the circumference, so that as
  // the ring grows the border dash length grows as well (looks more natural)
  const circumference = 2 * Math.PI * radius
  const borderDash = circumference / 50

  // SVG path data for defining a circle
  const pathData = `M ${cx -
    radius}, ${cy} a ${radius},${radius} 0 1,0 ${radius *
    2},0 a ${radius},${radius} 0 1,0 -${radius * 2},0`

  // Find the [x, y] coordinate where the rotation handle should be placed
  const rotationRadians = (rotation * Math.PI) / 180
  const rotateHandlePosition = [
    cx + radius * Math.cos(rotationRadians) - handleRadius,
    cy - radius * Math.sin(rotationRadians) - handleRadius,
  ]

  // The resize handle (if present) will be placed directly opposite the rotate handle,
  // so add Math.PI radians to the rotation angle
  const resizeHandlePosition = [
    cx + radius * Math.cos(rotationRadians + Math.PI) - handleRadius,
    cy - radius * Math.sin(rotationRadians + Math.PI) - handleRadius,
  ]

  const rotateImage = new window.Image()
  rotateImage.src = rotateImageSrc

  const resizeImage = new window.Image()
  resizeImage.src = resizeImageSrc

  const theme = getTheme()

  return (
    <Group>
      <Path
        data={pathData}
        dash={[borderDash, borderDash]}
        stroke={theme.palette.primary.main}
        strokeWidth={2 / zoom}
        listening={false}
      />
      <Image
        image={rotateImage}
        x={rotateHandlePosition[0]}
        y={rotateHandlePosition[1]}
        angle={rotation}
        width={handleRadius * 2}
        height={handleRadius * 2}
        name={CANVAS_ELEMENT_NAMES.ROTATE_HANDLE}
      />
      {resizeEnabled && (
        <Image
          image={resizeImage}
          x={resizeHandlePosition[0]}
          y={resizeHandlePosition[1]}
          angle={rotation}
          width={handleRadius * 2}
          height={handleRadius * 2}
          name={CANVAS_ELEMENT_NAMES.RESIZE_HANDLE}
        />
      )}
    </Group>
  )
}

export default ChartVesselControls
