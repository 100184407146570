import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'react-router5'
import styled from 'styled-components'
import { Field, Form, Formik } from 'formik'
import { Box, Typography } from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

import { LOGIN } from 'src/router/routes'
import CircleButton from 'src/components/atoms/CircleButton'
import forgotPasswordValidator from 'src/utils/validators/forgotPasswordValidator'
import { requestNewPassword } from 'src/store/auth/actions'
import TextInput from 'src/components/atoms/TextInput'
import AuthSubmitButton from 'src/components/atoms/AuthSubmitButton'
import LoginPaneSection from 'src/components/organisms/LoginLayout/LoginPaneSection'

const BackButtonSection = styled(LoginPaneSection)`
  flex: 0;
  align-items: flex-start;
  padding: 52px 0;
`

const RequestNewPasswordForm = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const [isReset, setIsReset] = useState(false)

  return (
    <Box width="100%">
      <BackButtonSection>
        <CircleButton onClick={() => router.navigate(LOGIN)}>
          <KeyboardArrowLeftIcon />
        </CircleButton>
      </BackButtonSection>
      <Typography variant="h1">Forgot Password</Typography>
      <Typography variant="subtitle2">
        Having trouble signing in? Enter your email address to reset your
        password.
      </Typography>
      {isReset ? (
        <Typography variant="body1">
          We&#39;ve sent the instructions to reset your password, please check
          your email.
        </Typography>
      ) : (
        <Formik
          initialValues={{ email: '' }}
          validate={forgotPasswordValidator}
          onSubmit={(values, actions) => {
            dispatch(requestNewPassword(values.email)).then(result => {
              if (result) {
                setIsReset(true)
              } else {
                actions.setSubmitting(false)
              }
            })
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form noValidate>
              <Field
                name="email"
                render={({ field }) => (
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Your email"
                    label="Email Address"
                    formikField={field}
                    error={touched.email && errors.email}
                  />
                )}
              />

              <AuthSubmitButton disabled={isSubmitting}>
                Reset Password
              </AuthSubmitButton>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  )
}

export default RequestNewPasswordForm
