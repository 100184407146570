import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import 'react-datepicker/dist/react-datepicker.css'
import 'src/styles/date-picker.css'
import 'src/styles/utils.css'

/**
 * Global base styles
 */

export const maxWidth = 1024

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  html,
  body {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    height: 100%;
  }

  html {
    box-sizing: border-box;
  }
  
  #root {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: ${({ theme }) => theme.weights.fontWeightMedium};
    letter-spacing: 0.53px;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  img {
    max-width: 100%;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  
  form {
    width: 100%;
  }

  /* clears the 'X' from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  
  input, label {
    font-weight: 500;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  /* hide arrows on number */
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: ${({ theme }) => theme.weights.fontWeightLight};
    src: url('../assets/fonts/Gilroy/Gilroy-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: ${({ theme }) => theme.weights.fontWeightRegular};
    src: url('../assets/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: ${({ theme }) => theme.weights.fontWeightMedium};
    src: url('../assets/fonts/Gilroy/Gilroy-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: ${({ theme }) => theme.weights.fontWeightSemiBold};
    src: url('../assets/fonts/Gilroy/Gilroy-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Gilroy';
    font-weight: ${({ theme }) => theme.weights.fontWeightBold};
    src: url('../assets/fonts/Gilroy/Gilroy-Bold.otf') format('opentype');
  }


  /* Add @font-face here */
  
 `

export default GlobalStyle
