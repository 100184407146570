import { createSelector } from 'reselect'

import {
  PREFETCH_PILOTAGE_IN_PROGRESS,
  PREFETCH_PILOTAGE_SUCCESS,
  PREFETCH_PILOTAGE_ERROR,
  PREFETCH_COMMONS_IN_PROGRESS,
  PREFETCH_COMMONS_SUCCESS,
  PREFETCH_COMMONS_ERROR
} from 'src/store/prefetch/actions'

export const prefetchStateSelector = state => state.prefetch

// COMMONS PREFETCH
export const commonsPrefetchStatusSelector = createSelector(
  prefetchStateSelector,
  state => state.commons
)

export const commonsArePrefetchingSelector = createSelector(
  commonsPrefetchStatusSelector,
  status => status === PREFETCH_COMMONS_IN_PROGRESS
)

export const commonsPrefetchFailedSelector = createSelector(
  commonsPrefetchStatusSelector,
  status => status === PREFETCH_COMMONS_ERROR
)

export const commonsPrefetchSuccessSelector = createSelector(
  commonsPrefetchStatusSelector,
  status => status === PREFETCH_COMMONS_SUCCESS
)

// CHART IMAGE PREFETCH
export const chartImagePrefetchStatusSelector = createSelector(
  prefetchStateSelector,
  state => state.charts
)

// PILOTAGES PREFETCH
export const pilotagePrefetchStatusSelector = createSelector(
  prefetchStateSelector,
  state => state.pilotages
)
export const pilotagePrefetchByUuidSelector = uuid =>
  createSelector(
    pilotagePrefetchStatusSelector,
    pilotages => pilotages && pilotages[uuid]
  )

export const pilotagePrefetchStatusByUuidSelector = uuid =>
  createSelector(
    pilotagePrefetchByUuidSelector(uuid),
    pilotage => pilotage && pilotage.status
  )

export const pilotagePrefetchIsInProgressSelector = uuid =>
  createSelector(
    pilotagePrefetchStatusByUuidSelector(uuid),
    status => status === PREFETCH_PILOTAGE_IN_PROGRESS
  )

export const pilotagePrefetchIsSuccessSelector = uuid =>
  createSelector(
    pilotagePrefetchStatusByUuidSelector(uuid),
    status => status === PREFETCH_PILOTAGE_SUCCESS
  )

export const pilotagePrefetchIsFailedSelector = uuid =>
  createSelector(
    pilotagePrefetchStatusByUuidSelector(uuid),
    status => status === PREFETCH_PILOTAGE_ERROR
  )

export const prefetchedPilotageUuidsSelector = createSelector(
  pilotagePrefetchStatusSelector,
  status =>
    status
      ? Object.keys(status).reduce((uuids, uuid) => {
          if (status[uuid].status === PREFETCH_PILOTAGE_SUCCESS) {
            return [...uuids, uuid]
          }
          return uuids
        }, [])
      : []
)

const checkIfAnyInProgress = (status) => {
  const uuids = Object.keys(status)
  const firstUuidInProgress = uuids.find((uuid) => status[uuid].status === PREFETCH_PILOTAGE_IN_PROGRESS)
  return !!firstUuidInProgress
}

export const prefetchedPilotagesAnyInProgressSelector = createSelector(
  pilotagePrefetchStatusSelector,
  status => status ? checkIfAnyInProgress(status) : false
)
  
// TIDES (FOR TIDE STATIONS) PREFETCH
export const tidesPrefetchStatusSelector = createSelector(
  prefetchStateSelector,
  state => state.tides
)

export const tidesPrefetchStatusByUuidSelector = uuid =>
  createSelector(
    tidesPrefetchStatusSelector,
    tidesStatus => tidesStatus && tidesStatus[uuid]
  )

// TIDE RATES (FOR TIDE RATE STATIONS) PREFETCH
export const tideRatesPrefetchStatusSelector = createSelector(
  prefetchStateSelector,
  state => state.tideRates
)

export const tideRatesPrefetchStatusByUuidSelector = uuid =>
  createSelector(
    tideRatesPrefetchStatusSelector,
    tideRatesStatus => tideRatesStatus && tideRatesStatus[uuid]
  )
