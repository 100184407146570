import React from 'react'
import styled from 'styled-components'
import { Dialog, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import FullscreenWrapper from 'src/components/atoms/FullscreenWrapper'

const DialogBodyText = styled(Typography).attrs({ variant: 'body1' })`
  && {
    margin: ${({ theme }) => theme.spacing(3, 0)};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

const VesselConfirmationDialog = ({
  isConfirmationOpen,
  Transition,
  closeConfirmationModal,
  acceptNewVessel,
}) => (
  <Dialog open={isConfirmationOpen} TransitionComponent={Transition}>
    <FullscreenWrapper>
      <Typography variant="h2">Create a new vessel?</Typography>
      <DialogBodyText>
        No matching vessel found. Would you like to create a new entry in the
        vessel database for this vessel?
      </DialogBodyText>
      <ButtonContainer>
        <StyledSecondaryButton onClick={closeConfirmationModal}>
          Cancel
        </StyledSecondaryButton>
        <PrimaryButton onClick={acceptNewVessel}>Create Vessel</PrimaryButton>
      </ButtonContainer>
    </FullscreenWrapper>
  </Dialog>
)

VesselConfirmationDialog.propTypes = {
  isConfirmationOpen: PropTypes.bool,
  closeConfirmationModal: PropTypes.func,
  acceptNewVessel: PropTypes.func,
  Transition: PropTypes.object,
}

export default VesselConfirmationDialog
