import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Box, Divider, Typography } from '@material-ui/core'

import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import { isAssignedToActivePilotageSelector } from 'src/store/pilotage/selectors'
import { useChecklist } from 'src/hooks/useChecklist'
import { CommentProps } from 'src/components/molecules/ChecklistComment'
import { ExtraType, PassFailChecklistItem } from 'src/types/Extras'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import FormFieldLabel from 'src/components/atoms/FormFieldLabel'
import FormFieldError from 'src/components/atoms/FormFieldError'
import {
  CheckboxCautionIcon,
  CheckboxInactiveCautionIcon,
  CheckboxInactiveTickIcon,
  CheckboxTickIcon,
} from 'src/components/atoms/Icons/Checkbox'
import ChecklistItemComment from 'src/components/atoms/ChecklistItemComment/ChecklistItemComment'
import { WidePrimaryButton } from 'src/components/atoms/PrimaryButton'
import { MovementType } from 'src/types/Pilotage'
import { useRouter } from 'react-router5'
import { PILOTAGE_SUMMARY } from 'src/router/routes'
import ButtonRow from './../../atoms/ButtonRow/ButtonRow'

const StyledCheckbox = styled(Checkbox).attrs({ size: 28 })`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const StyledCheckboxInactiveTickIcon = styled(CheckboxInactiveTickIcon)(
  ({ theme }) => css`
    border-color: ${theme.palette.primary.main};
  `
)

const GENERAL_COMMENT_MAX_CHARS = 500

interface PassFailChecklistRowProps {
  item: PassFailChecklistItem
  disabled: boolean
  onPassChecked: (item: PassFailChecklistItem, isChecked: boolean) => void
  onFailChecked: (item: PassFailChecklistItem, isChecked: boolean) => void
  onItemComment: (item: PassFailChecklistItem, value: string) => void
  itemCommentCharacterLimit: number
}

const PassFailChecklistRow: React.FC<PassFailChecklistRowProps> = React.memo(
  ({
    item,
    disabled,
    onPassChecked,
    onFailChecked,
    onItemComment,
    itemCommentCharacterLimit,
  }) => {
    const onFailCheckedCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        onFailChecked(item, e.target.checked),
      [item, onFailChecked]
    )

    const onPassCheckedCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        onPassChecked(item, e.target.checked),
      [item, onPassChecked]
    )

    const onItemCommentCallback = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        onItemComment(item, e.target.value),
      [item, onItemComment]
    )

    return (
      <Box key={item.uuid} display="flex" mb={1}>
        { !item.isHeader && (
          <>
            <Box display="flex" alignItems="flex-start" flex={1}>
              <StyledCheckbox
                checked={item.passChecked}
                checkedIcon={<CheckboxTickIcon />}
                uncheckedIcon={<StyledCheckboxInactiveTickIcon />}
                onChange={onPassCheckedCallback}
                disabled={disabled}
              />
              <StyledCheckbox
                checked={item.failChecked}
                checkedIcon={<CheckboxCautionIcon />}
                uncheckedIcon={<CheckboxInactiveCautionIcon />}
                onChange={onFailCheckedCallback}
                disabled={disabled}
              />
              <Box ml={3} color="text.primary">
                {item.label}
              </Box>
            </Box>
            <Box flex={1} ml={2}>
              <ChecklistItemComment
                value={item.comment || ''}
                onChange={onItemCommentCallback}
                disabled={disabled}
                maxChars={itemCommentCharacterLimit}
              />
            </Box>
          </>
        )
        }
        { item.isHeader && (
          <Box mt={2} mb={2}>
            <Typography variant="h3">{item.label}</Typography>
          </Box>
        )
        }
      </Box>
    )
  }
)

PassFailChecklistRow.displayName = 'PassFailChecklistRow'

interface PilotagePassFailChecklistBaseProps {
  readOnly: boolean
  movementType: MovementType
  CommentComponent: React.FC<CommentProps>
}

export const PilotagePassFailChecklistBase: React.FC<
  PilotagePassFailChecklistBaseProps
> = ({ readOnly, movementType, CommentComponent }) => {
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)

  const {
    setItemState,
    checklist,
    comment,
    onCommentChange,
    save,
    isSaving,
    isDirty,
    isInvalid,
    itemCommentCharacterLimit,
    selectAllPassFailChecklistItems,
  } = useChecklist({
    extraType: ExtraType.PassFailChecklist,
    movementType
  })

  const router = useRouter()
  router.canDeactivate(PILOTAGE_SUMMARY, () => () => {
    return !isDirty || confirm('You have unsaved pass fail checklist changes, are you sure you want to leave this page?')
  })

  const items = checklist as PassFailChecklistItem[]

  const onPassChecked = useCallback(
    (item: PassFailChecklistItem, isChecked: boolean) =>
      setItemState(item, 'passChecked', isChecked),
    [setItemState]
  )

  const onFailChecked = useCallback(
    (item: PassFailChecklistItem, isChecked: boolean) =>
      setItemState(item, 'failChecked', isChecked),
    [setItemState]
  )

  const onItemComment = useCallback(
    (item: PassFailChecklistItem, comment: string) =>
      setItemState(item, 'comment', comment),
    [setItemState]
  )

  const commentTooLong = comment && comment.length > GENERAL_COMMENT_MAX_CHARS

  return checklist.length > 0 ? (
    <>
      <Divider />
      <Typography variant="h2">Pass/Caution Checklist</Typography>
      <InfoBox extraPadding mt={3}>
        {/* <Box ml="auto" pl={1} width="50%">
          <FormFieldLabel readOnly>Comment</FormFieldLabel>
        </Box> */}
        {items.map(item => (
          <PassFailChecklistRow
            key={item.uuid}
            item={item}
            disabled={!isAssigned || readOnly}
            onFailChecked={onFailChecked}
            onPassChecked={onPassChecked}
            onItemComment={onItemComment}
            itemCommentCharacterLimit={itemCommentCharacterLimit}
          />
        ))}
        <CommentComponent
          comment={comment}
          onChange={onCommentChange}
          isDisabled={!isAssigned || readOnly}
        />
        {commentTooLong && (
          <FormFieldError>
            {comment.length} / {GENERAL_COMMENT_MAX_CHARS} character limit
          </FormFieldError>
        )}

        {!readOnly && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <ButtonRow>
              <WidePrimaryButton
                onClick={() => selectAllPassFailChecklistItems()}
              >
                Select All
              </WidePrimaryButton>
              <WidePrimaryButton
                onClick={() => save()}
                disabled={
                  !isDirty ||
                  isSaving ||
                  !isAssigned ||
                  isInvalid ||
                  !!commentTooLong
                }
              >
                {isSaving ? 'Saving...' : 'Save'}
              </WidePrimaryButton>
            </ButtonRow>
          </Box>
        )}
      </InfoBox>
    </>
  ) : null
}
