import storeInstance from 'src/store/store'
import configureRouter from './configureRouter'
import { PILOTAGES } from 'src/router/routes'

const router = configureRouter(storeInstance.store)

// track previous route
export let previousRoute

// track whether user has been to pilotages screen yet on this window/tab
export let hasVisitedPilotagesList = false

router.subscribe(state => {
  previousRoute = state.previousRoute

  if (previousRoute && previousRoute.name === PILOTAGES) {
    hasVisitedPilotagesList = true
  }
})

export default router
