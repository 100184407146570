import { createPilotage, updatePilotage } from 'src/utils/api/pilotages'

export const sync = syncItem => {
  const { isNew, entity } = syncItem
  // remove status (this field should be controlled by BE)
  // EMPX-462 need status for reverting done
  const { uuid, ...payload } = entity
  return isNew ? createPilotage(payload) : updatePilotage(uuid, payload)
}

export const createSyncUuid = pilotageUuid => pilotageUuid

export default sync
