import React from 'react'
import { Grid, Divider } from '@material-ui/core'
import { Form, Formik } from 'formik'
import {
  SearchQuery,
  FilterOptions,
  editable,
} from 'src/utils/searchAndFilterUtils'
import Select from 'src/components/atoms/Select'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'

interface FilterFormProps {
  options: FilterOptions
  query: SearchQuery
  onSubmit: (query: SearchQuery) => void
  onCancel: () => void
}

const ALL = 'All'

const FilterForm: React.FC<FilterFormProps> = ({
  options,
  query,
  onSubmit,
  onCancel,
}) => {
  const handleSubmit = (values: Partial<SearchQuery>) => {
    const nextQuery = JSON.parse(JSON.stringify(query)) as SearchQuery // break pointer references
    for (const key of Object.keys(values) as Array<keyof SearchQuery>) {
      nextQuery[key] = values[key] === ALL ? undefined : values[key]
    }
    onSubmit(nextQuery)
  }

  return (
    <Formik initialValues={{ ...query }} onSubmit={handleSubmit}>
      {({ setFieldValue, values, submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            {editable.map(key => (
              <Grid item xs={6} key={key as string}>
                <Select
                  label={key}
                  value={values[key] || ALL}
                  onChange={(value: string) => {
                    setFieldValue(String(key), value)
                  }}
                  options={[{ value: ALL, label: ALL }].concat(options[key])}
                  active={values[key] && values[key] !== ALL}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton
                onClick={submitForm}
                disabled={isSubmitting}
                style={{ float: 'right' }}
              >
                Apply
              </PrimaryButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FilterForm
