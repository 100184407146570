import { PilotageSignature } from './types'
import { SYNC_ENTITY_SUCCESS } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants';
import { SIGNING_USER } from './../../utils/constants';

export interface PilotageSignatureState {
    [pilotageUuid: string]: {
        loading: boolean
        signatures: PilotageSignature[]
    }
}

export const getSignaturesInState = (entityInState: any) => {
  let signaturePilot
  let signatureMaster
  for (let i = 0; i < entityInState.signatures.length; i++) {
    const signature = entityInState.signatures[i]
    if (signature.role === SIGNING_USER.PILOT) {
      signaturePilot = signature
    } else if (signature.role === SIGNING_USER.MASTER) {
      signatureMaster = signature
    } else {
      // internal error
      console.log(`Internal Error: Invalid signature role [${signature.role}]`)
    }
  }
  return {
    signaturePilot,
    signatureMaster,
  }
}
const getInitialState = () => ({})

export default function pilotageSignatureReducer (
  state: any = getInitialState(),
  action: any
): PilotageSignatureState {

  switch (action.type) {
    case 'PILOTAGE_SIGNATURES_LOADING': {
      const { pilotageUuid, loading } = action.payload
      return {
        ...state,
        [pilotageUuid]: {
          signatures: state[pilotageUuid] ? state[pilotageUuid].signatures : [],
          loading
        }
      }
    }
    case 'PILOTAGE_SIGNATURES_SET': {
      const { pilotageUuid, signatures } = action.payload
      return {
        ...state,
        [pilotageUuid]: {
          signatures,
          loading: false
        }
      }
    }
    case SYNC_ENTITY_SUCCESS: {
      const { payload } = action
      const { type: syncType, pilotageUuid, entity: syncItem } = payload
      if (syncType !== SYNC_ENTITY_TYPES.PILOTAGE_SIGNATURES) {
        return state
      }
      let signaturePilot
      let signatureMaster
      let loading = false
      // get signatures from sync items first
      if (syncItem.signature.role === SIGNING_USER.PILOT) {
        signaturePilot = syncItem.signature
      } else if (syncItem.signature.role === SIGNING_USER.MASTER) {
        signatureMaster = syncItem.signature
      } else {
        // internal error
        console.log(`Internal Error: Invalid signature role [${syncItem.signature.role}]`)
      }
      // then, get signatures from state
      const entityInState = state[pilotageUuid]
      if (entityInState) {
        loading = entityInState.loading
        const signaturesInState = getSignaturesInState(entityInState)
        if (!signaturePilot && signaturesInState.signaturePilot) {
          signaturePilot = signaturesInState.signaturePilot
        }
        if (!signatureMaster && signaturesInState.signatureMaster) {
          signatureMaster = signaturesInState.signatureMaster
        }
      }
      let signatures = []
      if (signaturePilot) {
        signatures.push(signaturePilot)
      }
      if (signatureMaster) {
        signatures.push(signatureMaster)
      }
      return {
        ...state,
        [pilotageUuid]: {
          signatures,
          loading: loading
        }
      }
    }
    default:
      return state
  }
}
