import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { lookupRoutes } from 'src/utils/api/routes'

const ROUTES_LOOKUP_BASE = 'ROUTES_LOOKUP_BASE'

export const [
  ROUTES_LOOKUP_REQUEST,
  ROUTES_LOOKUP_SUCCESS,
  ROUTES_LOOKUP_ERROR,
  ROUTES_LOOKUP_CANCEL,
  ROUTES_LOOKUP_IN_PROGRESS,
] = createBasicActionTypes(ROUTES_LOOKUP_BASE)

export const CLEAR_ROUTES_LOOKUP = 'CLEAR_ROUTES_LOOKUP'

export const routesLookupRequest = (from, to) => async dispatch => {
  dispatch(routesLookupInProgress())

  try {
    const response = await lookupRoutes(from, to)
    const data = getPayloadFromResponse(response)
    dispatch(routesLookupLoaded(data))
  } catch (error) {
    dispatch(routesLookupError(error))
  }
}

export const routesLookupInProgress = () =>
  createInProgressAction(ROUTES_LOOKUP_BASE)

export const routesLookupError = error => dispatch => {
  dispatch(createErrorAction(ROUTES_LOOKUP_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.ROUTES_LOOKUP_ERROR,
    })
  )
}

export const routesLookupLoaded = data =>
  createSuccessAction(ROUTES_LOOKUP_BASE, data)

export const clearRoutesLookup = () => async dispatch => {
  dispatch({
    type: CLEAR_ROUTES_LOOKUP
  })
}
