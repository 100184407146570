import {
  VESSEL_LOOKUP_IN_PROGRESS,
  VESSEL_LOOKUP_ERROR,
  VESSEL_LOOKUP_SUCCESS,
  SELECT_VESSEL,
} from 'src/store/vesselLookup/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'

const INITIAL_STATE = {
  data: null,
  searchText: null,
  isLoading: false,
  error: null,
  selectedItem: null,
}

function vesselLookupReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case VESSEL_LOOKUP_IN_PROGRESS:
      return {
        ...state,
        data: null,
        isLoading: true,
        error: null,
      }
    case VESSEL_LOOKUP_ERROR:
      return {
        data: null,
        error: payload,
        isLoading: false,
      }
    case VESSEL_LOOKUP_SUCCESS:
      return {
        ...state,
        data: payload.data,
        searchText: payload.searchText,
        isLoading: false,
        error: null,
      }
    case SELECT_VESSEL:
      return {
        ...state,
        selectedItem: payload,
      }
    default:
      return state
  }
}

export default vesselLookupReducer
