import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getBerthsStations } from 'src/utils/api/pilotages'

const BERTHS_STATIONS_BASE = 'BERTHS_STATIONS'

export const [
  BERTHS_STATIONS_REQUEST,
  BERTHS_STATIONS_SUCCESS,
  BERTHS_STATIONS_ERROR,
  BERTHS_STATIONS_CANCEL,
  BERTHS_STATIONS_IN_PROGRESS,
] = createBasicActionTypes(BERTHS_STATIONS_BASE)

export const berthsStationsRequest = portUuid => async dispatch => {
  dispatch(berthsStationsInProgress())

  try {
    const response = await getBerthsStations(portUuid)
    const data = getPayloadFromResponse(response)
    dispatch(berthsStationsLoaded(data))

    if (!data) {
      dispatch(
        addErrorToast({
          message: TOAST_MESSAGES.VESSEL_LOOKUP_NO_RESULT,
        })
      )
    }
    return data
  } catch (error) {
    dispatch(berthsStationsError(error))
  }
}

export const berthsStationsInProgress = () =>
  createInProgressAction(BERTHS_STATIONS_BASE)

export const berthsStationsLoaded = data =>
  createSuccessAction(BERTHS_STATIONS_BASE, data)

export const berthsStationsError = error => dispatch => {
  dispatch(createErrorAction(BERTHS_STATIONS_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_BERTHS_STATIONS_ERROR,
    })
  )
}
