import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { missingValueText } from 'src/utils/constants'

const WeatherDescription = styled.div`
  margin-bottom: 8px;
`

const TemperatureLabel = styled.div(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(20)};
  font-weight: ${theme.weights.fontWeightRegular};
`
)

export const WeatherConditions = ({
  iconSrc,
  description,
  temperature,
  ...props
}) => (
  <Box display="flex" {...props}>
    <div>
      <img
        src={iconSrc.replace(/^http:\/\/(.*)$/, 'https://$1')}
        alt="Weather icon"
      />
    </div>
    <Box ml={2} color="text.primary" fontSize={16}>
      <WeatherDescription>{description}</WeatherDescription>
      <TemperatureLabel>
        {
          typeof temperature === 'number' && !isNaN(temperature)
            ? `${temperature} °C`
            : missingValueText
        }
      </TemperatureLabel>
    </Box>
  </Box>
)

WeatherConditions.propTypes = {
  iconSrc: PropTypes.string,
  description: PropTypes.string,
  temperature: PropTypes.number,
}
