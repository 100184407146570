import ReactGA from 'react-ga'

const useAnalytics = () => {

  const trackingId = process.env.REACT_APP_GA_TRACKING_ID

  const initGA = () => {
    if (trackingId) {
      ReactGA.initialize({ trackingId })
    }
  }
  const recordPageView = route => {
    if (trackingId) {
      ReactGA.set({ page: route })
      ReactGA.pageview(route)
    }
  }
  const recordEvent = ({ category, action }) => {
    if (trackingId) {
      ReactGA.event({ category, action })
    }
  }
  return {
    initGA,
    recordPageView,
    recordEvent,
  }
}

export default useAnalytics
