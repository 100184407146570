import { createSelector } from 'reselect'
import idx from 'idx'

import { entitiesToSyncSelector } from 'src/store/sync/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { createSyncUuid } from 'src/utils/sync/pilotageExtras'

export const pilotageExtrasStateSelector = state => state.pilotageExtras
export const pilotageExtrasSelector = createSelector(
  pilotageExtrasStateSelector,
  pilotageUuidSelector, // we need the pilotageUuid because this is the base for every (pilotage extras) sync UUID
  entitiesToSyncSelector, // we will merge the offline changed items if there is any
  (state, pilotageUuid, entitiesToSync) => {
    const syncUuid = pilotageUuid && createSyncUuid(pilotageUuid)
    const dataInSync = idx(entitiesToSync[syncUuid], _ => _.entity.data)
    let data = state.data
    // this part is not required right now since we have only one extra for pilotages
    // however to avoid future confusion we add this merge now
    // it should not have a big performance footprint
    if (dataInSync) {
      // so we have something which is changed while offline and not synced back yet
      // replace the original extras with the changed items in the sync
      data =
        data &&
        data.map(
          extra => dataInSync.find(se => se.uuid === extra.uuid) || extra
        )
      // add those which are new, added since then
      // maybe we only have new ones
      data = data
        ? [
            ...data,
            ...dataInSync.filter(se => !data.find(e => e.uuid === se.uuid)),
          ]
        : dataInSync
    }
    return {
      ...state,
      data,
    }
  }
)

export const getPilotageExtraSelector = extraType =>
  createSelector(
    pilotageExtrasSelector,
    extras =>
      extras.data
        ? extras.data.find(item => item.extraType === extraType)
        : null
  )
