import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute, useRouter } from 'react-router5'
import styled from 'styled-components'
import { Typography, Box, Divider } from '@material-ui/core'
import { formatToTimeZone } from 'date-fns-timezone'

import { EDIT_VESSEL, EDIT_PILOTAGE } from 'src/router/routes'
import PilotageInfoBar from 'src/components/atoms/PilotageInfoBar'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import {
  VesselTopDiagram,
  VesselSideDiagram,
} from 'src/components/molecules/VesselDiagram'
import MaxDraftInput from 'src/components/molecules/MaxDraftInput'
import VesselParticulars from 'src/components/molecules/VesselParticulars'
import PrimaryButton from 'src/components/atoms/PrimaryButton/PrimaryButton'
import Modal from 'src/components/molecules/Modal'
import PilotageDetails from 'src/components/organisms/PilotageDetails/PilotageDetails'
import EditVesselDetails from 'src/components/organisms/EditVesselDetails'
import SubHeading from 'src/components/atoms/SubHeading'
import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  pilotageSelector,
  deriveMaxDraft,
  deriveFreeboard,
} from 'src/store/pilotage/selectors'
import {
  openEditPilotageDetails,
  openEditVesselDetails,
} from 'src/store/pilotage/actions'
import { formatPilotageData, valueOrMissingText } from 'src/utils/formatters'
import { vesselSelector } from 'src/store/vessel/selectors'
import {
  unsavedPilotageSelector,
  unsavedVesselSelector,
} from 'src/store/ui/selectors'
import InfoBox from 'src/components/atoms/InfoBox'
import { vesselRequest } from 'src/store/vessel/actions'
import { CrossIcon } from 'src/components/atoms/Icons'
import { DATETIME_FORMAT } from 'src/utils/constants'
import { timezoneSelector } from 'src/store/ports/selectors'
import useOmcDukc from 'src/hooks/useOmcDukc'
import InfoIcon from 'src/components/atoms/Icons/Info'
import Tooltip from './../../components/atoms/Tooltip/Tooltip'
import { preferencesRequest } from 'src/store/preferences/actions'
import Displacement from './../../components/molecules/Displacement/index';
import { ItemLabel, ItemValue } from './../../components/atoms/PilotageInfoBar/PilotageInfoBar';

const FullDetailsEditBtn = styled(PrimaryButton)`
  width: 100%;
`

const Notes = styled.div`
  color: rgb(255, 171, 79);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    margin-right: 5px;
  }
`

const PilotRemarksTooltip = styled.div`
  float: right;
  margin-left: 10px;
`
const PilotageVessel = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { route } = useRoute()
  const pilotageId = route.params.id

  const isEditVesselModalOpen = route.name === EDIT_VESSEL
  const isEditPilotageModalOpen = route.name === EDIT_PILOTAGE

  const timeZone = useSelector(timezoneSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)

  const { data: pilotageRawData } = useSelector(pilotageSelector)
  const pilotageData = pilotageRawData
    ? formatPilotageData(pilotageRawData, timeZone)
    : null

  const {
    data: vesselData,
    error: vesselError,
    isLoading: vesselLoading,
  } = useSelector(vesselSelector)

  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled

  let vesselUpdated, pilotageUpdated

  if (vesselData) {
    vesselUpdated = formatToTimeZone(
      new Date(vesselData.updatedAt),
      DATETIME_FORMAT,
      { timeZone }
    )
  }
  if (pilotageRawData) {
    pilotageUpdated = formatToTimeZone(
      new Date(pilotageRawData.updatedAt),
      DATETIME_FORMAT,
      { timeZone }
    )
  }

  useEffect(() => {
    if (pilotageId) {
      dispatch(preferencesRequest())
    }
  }, [pilotageId])

  const hasPilotageUnsavedChanges = useSelector(unsavedPilotageSelector)
  const hasVesselUnsavedChanges = useSelector(unsavedVesselSelector)

  // fetch omc dukc ahead of arriving on the risks page
  const omc = useOmcDukc()
  const shouldLoadOmc = useRef(true)

  useEffect(() => {
    if (
      !process.env.REACT_APP_OMC_DISABLE &&
      pilotageRawData &&
      pilotageRawData.vessel &&
      pilotageRawData.vessel.IMO &&
      pilotageRawData.date &&
      pilotageRawData.movementType &&
      shouldLoadOmc.current
    ) {
      omc.fetch({
        vesselIMO: pilotageRawData.vessel.IMO,
        pilotageDate: pilotageRawData.date,
        movementType: pilotageRawData.movementType,
        portUuid: pilotageRawData.port.uuid,
      })
    }
    // prevent memory leak
    return () => {
      shouldLoadOmc.current = false
    }
  }, [
    pilotageRawData && pilotageRawData.vessel && pilotageRawData.vessel.IMO,
    pilotageRawData && pilotageRawData.date,
    pilotageRawData && pilotageRawData.movementType
  ])

  router.canDeactivate(EDIT_VESSEL, () => () => {
    if (hasVesselUnsavedChanges) {
      // TODO: use a designed modal
      return window.confirm('Discard unsaved changes?')
    }
    return Promise.resolve()
  })

  router.canDeactivate(EDIT_PILOTAGE, () => () => {
    if (hasPilotageUnsavedChanges) {
      // TODO: use a designed modal
      return window.confirm('Discard unsaved changes?')
    }
    return Promise.resolve()
  })

  const retryLoadVessel = () => {
    dispatch(
      vesselRequest(pilotageRawData.port.uuid, pilotageRawData.vessel.IMO)
    )
  }

  const lastPilotRemarks = (vesselData && vesselData.metadata && vesselData.metadata.comments) || ''

  const derivedMaxDraft = pilotageRawData ? deriveMaxDraft(pilotageRawData) : null
  const derivedFreeboard = derivedMaxDraft !== null && vesselData && vesselData.metadata ? deriveFreeboard(derivedMaxDraft, vesselData.metadata.vessel_depth) : null

  return (
    <>
      <Box display="flex">
        <Box flex={1} mb={1} mr={1}>
          {pilotageData && <PilotageInfoBar {...pilotageData} />}
        </Box>
        <Box>
          {pilotageData && (
            <SecondaryButton
              style={{minWidth: 100}}
              onClick={() => dispatch(openEditPilotageDetails(pilotageId))}
            >
              {isPilotageDone ? 'Details' : 'Edit'}
            </SecondaryButton>
          )}
        </Box>
      </Box>
      {pilotageData &&
        <>
          {pilotageData.notes && <Notes><InfoIcon size={16} color={'rgb(255, 171, 79)'} />{pilotageData.notes}</Notes>}
        </>
      }
      <Divider style={{ marginTop: 0 }}/>
      {!vesselData && vesselError && (
        <InfoBox
          title="Error - Vessel Data"
          buttonText="Retry"
          onButtonClick={retryLoadVessel}
          loading={vesselLoading}
          Icon={CrossIcon}
        >
          Something went wrong trying to load the vessel data.
        </InfoBox>
      )}
      {vesselData && (
        <Box display="flex" mt={3}>
          <Box flex={1} pr="5%">
            <Typography variant="h1">Vessel Details</Typography>
            <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
              <SubHeading>{vesselData ? vesselData.vesselType : ''}</SubHeading>
              { lastPilotRemarks && (
                <SubHeading>
                  Last Pilot(s) Remarks
                  <PilotRemarksTooltip>
                    <Tooltip content={lastPilotRemarks} />
                  </PilotRemarksTooltip>
                </SubHeading>
              )}
            </Box>
            <Box pt={3}>
              <VesselTopDiagram
                beam={vesselData ? vesselData.beam : null}
                bridgeToBow={vesselData ? vesselData.bridgeToBow : null}
                bridgeToStern={vesselData ? vesselData.bridgeToStern : null}
                unit={vesselData ? vesselData.measurementsUnit : null}
              />
            </Box>
            <Box pt={3}>
              <VesselSideDiagram
                bridgeToBow={vesselData ? vesselData.bridgeToBow : null}
                bridgeToStern={vesselData ? vesselData.bridgeToStern : null}
                heightOverall={vesselData ? vesselData.height : null}
                lengthOverall={vesselData ? vesselData.length : null}
                maxDraft={derivedMaxDraft}
                freeboard={derivedFreeboard}
                metaAirDraft={
                  vesselData && vesselData.metadata ? parseFloat(vesselData.metadata.air_draft) : null
                }
                unit={vesselData ? vesselData.measurementsUnit : null}
              />
            </Box>
          </Box>
          <Box display="flex" flex="0 0 235px" flexDirection="column">
            <MaxDraftInput readOnly={isReadOnly} />
            <Box mt="20px">
              <Displacement readOnly={isReadOnly} />
            </Box>
            {pilotageData &&
              <>
                {pilotageData.transferMethod && (
                  <Box mt="20px">
                    <ItemLabel>Transfer Method</ItemLabel>
                    <ItemValue>
                      {pilotageData.transferMethod}
                    </ItemValue>
                  </Box>
                )}
              </>
            }
            {pilotageData &&
              <>
                {pilotageData.vesselAgent && (
                  <Box mt="20px">
                    <ItemLabel>Agent</ItemLabel>
                    <ItemValue>
                      {pilotageData.vesselAgent}
                    </ItemValue>
                  </Box>
                )}
              </>
            }
            <VesselParticulars />
            <Box mt="auto">
              <FullDetailsEditBtn
                onClick={() => dispatch(openEditVesselDetails(pilotageId))}
              >
                Full Details{isAssigned && !isPilotageDone && ' (Edit)'}
              </FullDetailsEditBtn>
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        open={isEditVesselModalOpen || isEditPilotageModalOpen}
        onClose={() => {
          window.history.back()
        }}
        heading={
          isEditVesselModalOpen ? 'Full Vessel Details' : 'Pilotage Details'
        }
        subHeading={`Last updated: ${valueOrMissingText(
          isEditVesselModalOpen ? vesselUpdated : pilotageUpdated
        )}`}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {isEditVesselModalOpen ? (
          <EditVesselDetails />
        ) : (
          <PilotageDetails isNew={false} />
        )}
      </Modal>
    </>
  )
}

export default PilotageVessel
