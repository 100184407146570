import { createSelector } from 'reselect'
import idx from 'idx'

import { entitiesToSyncSelector } from 'src/store/sync/selectors'
import { createSyncUuid } from 'src/utils/sync/vessel'

export const vesselStateSelector = state => state.vessel
export const vesselSelector = createSelector(
  vesselStateSelector,
  entitiesToSyncSelector,
  (state, entitiesToSync) => {
    let { data, ...restOfState } = state
    data =
      // use the changed entity from the sync store if any
      (data &&
        idx(entitiesToSync, _ => _[createSyncUuid(data.IMO)].entity.data)) ||
      data

    return {
      ...restOfState,
      data,
    }
  }
)
