import styled from 'styled-components'
import { Box, Divider } from '@material-ui/core'

export const PrimaryText = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const FieldLabel = styled.div`
  text-transform: uppercase;
`

export const CalculationDivider = styled(Divider)`
  && {
    margin: ${({ theme }) => theme.spacing(1, 0)};
    width: 100%;
  }
`

export const HighlightedCalculationDivider = styled(CalculationDivider)`
  && {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const DarkBox = styled(Box)(
  ({ theme }) => `
    margin: ${theme.spacing(1, 0)};
    background-color: ${theme.palette.background.default};
    border-radius: 4px;
  `
)
