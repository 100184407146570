import {
  ROUTE_CHECK_SAVE_ERROR,
  ROUTE_CHECK_SAVE_IN_PROGRESS,
  ROUTE_CHECK_SAVE_SUCCESS,
} from 'src/store/routeCheck/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'

const INITIAL_STATE = {
  isSaving: false,
  saveError: null,
}

function routeCheckReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action
  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case ROUTE_CHECK_SAVE_IN_PROGRESS:
      return {
        isSaving: true,
        saveError: false,
      }
    case ROUTE_CHECK_SAVE_SUCCESS:
      return {
        isSaving: false,
        saveError: false,
      }
    case ROUTE_CHECK_SAVE_ERROR:
      return {
        isSaving: false,
        saveError: payload,
      }
    default:
      return state
  }
}

export default routeCheckReducer
