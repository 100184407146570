import React from 'react'

const PlanChannelIcon = () => (
  <svg width="93" height="68">
    <g fillRule="nonzero" stroke="#FFF" fill="none">
      <path d="M1 68l9.552-10.268a26.911 26.911 0 0 1 32.46-5.365c10.621 5.718 23.806 3.083 31.413-6.278l10.674-13.135C93.247 22.928 91.767 8.204 81.79 0" />
      <path
        d="M91.26 25.565l-3.98-2.685a1.6 1.6 0 0 0-2.221.432L74.467 39.015a1.6 1.6 0 0 0-.267 1.043l.35 3.773a1.6 1.6 0 0 0 2.053 1.385l3.63-1.09a1.6 1.6 0 0 0 .866-.637l10.592-15.703a1.6 1.6 0 0 0-.432-2.221z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default PlanChannelIcon
