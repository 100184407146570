import { combineReducers } from 'redux'
import { router5Reducer as router } from 'redux-router5'

import auth from 'src/store/auth/reducer'
import toast from 'src/store/toast/reducer'
import ports from 'src/store/ports/reducer'
import drawings from 'src/store/drawings/reducer'
import canvas from 'src/store/canvas/reducer'
import canvasMenu from 'src/store/canvasMenu/reducer'
import pilotages from 'src/store/pilotageList/reducer'
import pilotage from 'src/store/pilotage/reducer'
import preferences from 'src/store/preferences/reducer'
import route from 'src/store/route/reducer'
import charts from 'src/store/charts/reducer'
import dhi from 'src/store/dhi/reducer'
import dhiTransits from 'src/store/dhiTransits/reducer'
import dhiTransitsPdf from 'src/store/dhiTransitsPdf/reducer'
import vessel from 'src/store/vessel/reducer'
import vesselLookup from 'src/store/vesselLookup/reducer'
import tugs from 'src/store/tugs/reducer'
import pilotageTugs from 'src/store/pilotageTugs/reducer'
import pilots from 'src/store/pilots/reducer'
import berthsStations from 'src/store/berthsStations/reducer'
import routesLookup from 'src/store/routesLookup/reducer'
import ui from 'src/store/ui/reducer'
import onlinePing from 'src/store/onlinePing/reducer'
import weather from 'src/store/weather/reducer'
import weatherLocations from 'src/store/weatherLocations/reducer'
import tides from 'src/store/tides/reducer'
import tideExtremes from 'src/store/tideExtremes/reducer'
import tideRates from 'src/store/tideRates/reducer'
import pilotageThread from 'src/store/pilotageThread/reducer'
import pilotageExtras from 'src/store/pilotageExtras/reducer'
import pilotageSignatures from 'src/store/pilotageSignature/reducer'
import extras from 'src/store/extras/reducer'
import masterview from 'src/store/masterview/reducer'
import routeCheck from 'src/store/routeCheck/reducer'
import sync from 'src/store/sync/reducer'
import prefetch from 'src/store/prefetch/reducer'
import omc from 'src/store/omc/reducer'

import {
  SESSION_EXPIRED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
} from 'src/store/auth/actions'

export const appReducers = combineReducers({
  auth,
  toast,
  ports,
  canvas,
  canvasMenu,
  drawings,
  router,
  pilotages,
  pilotage,
  preferences,
  route,
  charts,
  dhi,
  dhiTransits,
  dhiTransitsPdf,
  vessel,
  vesselLookup,
  tugs,
  pilotageTugs,
  pilots,
  berthsStations,
  routesLookup,
  ui,
  onlinePing,
  weather,
  weatherLocations,
  tides,
  tideExtremes,
  tideRates,
  pilotageThread,
  pilotageExtras,
  pilotageSignatures,
  extras,
  masterview,
  routeCheck,
  sync,
  prefetch,
  omc,
})

export const rootReducers = (state, action) => {
  let currentState
  // Make sure the local storage auth tokens are already cleared before
  // resetting the store state, as auth initial state depends on whether or
  // not an auth token is saved. TODO: think about a safer solution.
  switch (action.type) {
    case SESSION_EXPIRED:
    case AUTH_LOGOUT_SUCCESS:
    case AUTH_LOGOUT_ERROR:
      currentState = undefined
      break
    default:
      currentState = state
  }
  return appReducers(currentState, action)
}

export default rootReducers
