import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Divider } from '@material-ui/core'
import { useRoute } from 'react-router5'
import deepEqual from 'fast-deep-equal'
import usePrevious from 'src/hooks/usePrevious'

import idx from 'idx'

import PilotageTable from 'src/components/organisms/PilotageTable'
import PilotagesEmptyState from 'src/components/organisms/PilotageEmptyState'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { NEW_PILOTAGE } from 'src/router/routes'
import Modal from 'src/components/molecules/Modal'
import PilotageDetails from 'src/components/organisms/PilotageDetails'
import {
  pilotageListRequest,
  setFilter,
  startNewPilotage,
} from 'src/store/pilotageList/actions'
import {
  pilotageListSelector,
  pilotageIsLoadingSelector,
  pilotageErrorSelector,
  pilotageFilterSelector,
  pilotageLastLoadedPortUuidSelector,
} from 'src/store/pilotageList/selectors'
import { featureFlagPilotCreatePilotageDisabled } from 'src/store/preferences/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import { padCalendarDateString } from 'src/utils/date'
import {
  portIsLoadingSelector,
  selectedPortSelector,
  timezoneSelector,
} from 'src/store/ports/selectors'
import FixedHeaderLayout from 'src/components/molecules/FixedHeaderLayout'
import AppVersion from 'src/components/atoms/AppVersion'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import {
  authUserSelector,
  sessionIsValidSelector,
} from 'src/store/auth/selectors'
import { updateAvailableSelector } from 'src/store/ui/selectors'
import PilotageListHeaderContent from 'src/components/molecules/PilotageListHeaderContent'
import PaginationControls from 'src/components/organisms/PaginationControls'
import { extrasRequest } from 'src/store/extras/actions'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { tugsRequest } from 'src/store/tugs/actions'
import { preferencesRequest } from 'src/store/preferences/actions'
import getPilotageChangesNeedingReview from 'src/utils/getPilotageChangesNeedingReview'
import {
  makePilotageMatcher,
  queryToString,
  dateRangeToString,
  extractFilterOptions,
} from 'src/utils/searchAndFilterUtils'
import { omcGetSystem } from 'src/store/omc/actions'
import { weatherLocationsRequest } from 'src/store/weatherLocations/actions'
import { clearRoutesLookup } from './../../store/routesLookup/actions'
import { pilotageAscendingSelector } from './../../store/pilotageList/selectors';

const ContentWrapper = styled(({ isUpdateAvailable, ...rest }) => (
  <div {...rest} />
))(
  ({ isUpdateAvailable }) => `
  position: relative;
  top: ${isUpdateAvailable ? 210 : 150}px;
  padding-bottom: 120px;
`
)

const AddNewButton = styled(PrimaryButton)`
  && {
    position: fixed;
    bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`

const ResultSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -20px;

  .result-title {
    color: #fff;
    font-size: 14px;
  }

  .result-value {
    text-transform: capitalize;
    font-size: 14px;
  }
`

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250, 500]
const DEFAULT_PAGE_SIZE = 25

const PilotageList = () => {
  const { route } = useRoute()
  const { params } = route
  const savedFilter = useSelector(pilotageFilterSelector)

  const query = {
    search:
      params.search && params.search.length > 2
        ? params.search.toLowerCase()
        : undefined,
    status: params.status,
    movement: params.movement,
    pilot: params.pilot,
    vessel: params.vessel,
    startDate: padCalendarDateString(params.startDate),
    endDate: padCalendarDateString(params.endDate),
  }

  // when search/filter query is different to previous, we need to reset page to 1 because it's a new result set
  const previousQuery = usePrevious(query)
  const resetPage = !deepEqual(query, previousQuery)
  const pagination = {
    page: resetPage ? 1 : parseInt(params.page || '1', 10) || 1,
    pageSize:
      parseInt(params.pageSize || String(DEFAULT_PAGE_SIZE), 10) ||
      DEFAULT_PAGE_SIZE,
  }

  const dispatch = useDispatch()
  const isNewPilotageModalOpen = route.name === NEW_PILOTAGE
  const isOnline = useOnlineStatus()
  const sessionIsValid = useSelector(sessionIsValidSelector)
  const isUpdateAvailable = useSelector(updateAvailableSelector)
  const pilotCreatePilotageDisabled = useSelector(
    featureFlagPilotCreatePilotageDisabled
  )

  const data = useSelector(pilotageListSelector)
  const isLoading = useSelector(pilotageIsLoadingSelector)
  const error = useSelector(pilotageErrorSelector)
  const lastLoadedPortUuid = useSelector(pilotageLastLoadedPortUuidSelector)
  const selectedPort = useSelector(selectedPortSelector)
  const portIsLoading = useSelector(portIsLoadingSelector)
  const user = useSelector(authUserSelector)
  const timezone = useSelector(timezoneSelector)
  const isAscending = useSelector(pilotageAscendingSelector)

  const [isRefreshing, setIsRefreshing] = useState(false)

  // Only display pilotages for the active selected port
  // pilotages that are in memory could of a different port than selected port
  const pilotages = useMemo(
    () => {
      return selectedPort
        ? data.filter(
          item =>
            idx(item, _ => _.port.uuid) === idx(selectedPort, _ => _.uuid)
        )
        : data || []
    },
    [data, selectedPort]
  )

  const formattedData = useMemo(
    () =>
      pilotages
        .filter(makePilotageMatcher(query, timezone))
        .map(item => ({
          ...formatPilotageData(item, timezone),
          needsReview: !!getPilotageChangesNeedingReview(item, user),
        })).sort((A, B) => {
          const aTime = new Date(A.date).valueOf()
          const bTime = new Date(B.date).valueOf()
          const sort = (a, b) => a < b ? -1 : a > b ? 1 : 0
          return isAscending ? sort(aTime, bTime) : sort(bTime, aTime)
        }),
    [pilotages, timezone, query, user, isAscending]
  )

  const pageData = useMemo(
    () => {
      const pageStartIndex = (pagination.page - 1 || 0) * pagination.pageSize
      return formattedData.slice(
        pageStartIndex,
        pageStartIndex + pagination.pageSize
      )
    },
    [pagination.page, pagination.pageSize, formattedData]
  )

  const myPilotages = useMemo(
    () => {
      const uuid = idx(user, _ => _.uuid)
      return pageData.filter(
        item =>
          /* @note Ronak to validate if this is needed
          item.status !== PILOTAGE_STATUS.CANCELLED &&
          item.status !== PILOTAGE_STATUS.ARCHIVED &&
          */
          (item.pilotOrig && item.pilotOrig.uuid === uuid) ||
          (item.pilotSecondOrig && item.pilotSecondOrig.uuid === uuid)
      )
    },
    [pageData, user]
  )

  const showVesselVisitCode = useMemo(() =>
    !!pilotages.find(({ vesselVisitCode }) => !!vesselVisitCode),
  [pilotages]
  )

  // filter options are extracted from the full (unfiltered) pilotage result set
  const filterOptions = useMemo(() =>
    extractFilterOptions(pilotages),
  [pilotages]
  )

  // after changing port, clear out any filter values that are no longer in the option set
  useEffect(() => {

    if (isLoading || portIsLoading || !selectedPort || !lastLoadedPortUuid || lastLoadedPortUuid !== selectedPort.uuid) {
      return
    }

    const { status, movement, pilot, vessel } = params

    const paramsInOptions = {
      status: filterOptions.status.find(({ value }) => value === status) && status,
      movement: filterOptions.movement.find(({ value }) => value === movement) && movement,
      pilot: filterOptions.pilot.find(({ value }) => value === pilot) && pilot,
      vessel: filterOptions.vessel.find(({ value }) => value === vessel) && vessel
    }

    if (!deepEqual({ status, movement, pilot, vessel }, paramsInOptions)) {
      dispatch(setFilter({
        page: 1,
        startDate: padCalendarDateString(params.startDate),
        endDate: padCalendarDateString(params.endDate),
        ...paramsInOptions
      }))
    }

  }, [filterOptions, params, isLoading, portIsLoading, lastLoadedPortUuid])

  // If there is a saved filter that doesn't match what's in the url, update the url
  useEffect(() => {
    if (savedFilter && !deepEqual(savedFilter, params)) {
      dispatch(setFilter(savedFilter))
    }
  }, [savedFilter, params])

  const dateRangeString = useMemo(() => dateRangeToString(query, timezone), [
    query,
  ])

  const queryString = useMemo(() => queryToString(query, filterOptions), [
    query,
    filterOptions,
  ])

  const queryAndDateRangeString = [dateRangeString, queryString]
    .filter(s => s)
    .join(', ')

  useEffect(() => {
    // fetch list only when it is online
    // otherwise the cached api response
    // will override the changes (if any)
    if (isOnline && sessionIsValid && selectedPort) {
      dispatch(pilotageListRequest(selectedPort.uuid))
      dispatch(preferencesRequest())
    }
  }, [selectedPort])

  const startNew = () => {
    dispatch(clearRoutesLookup())
    dispatch(startNewPilotage())
  }

  const startNewButton = !pilotCreatePilotageDisabled && (
    <Box display="flex" justifyContent="flex-end">
      <AddNewButton disabled={!isOnline} onClick={startNew}>
        New Pilotage
      </AddNewButton>
    </Box>
  )

  const refresh = async () => {
    if (isOnline) {

      setIsRefreshing(true)

      await dispatch(preferencesRequest())

      let success
      if (selectedPort) {
        success = !!(await dispatch(pilotageListRequest(selectedPort.uuid, false)))
        const extrasResult = await dispatch(extrasRequest(selectedPort.uuid, false))
        const tugsResult = await dispatch(tugsRequest(selectedPort.uuid, false))
        const weatherLocationsResult = await dispatch(weatherLocationsRequest(selectedPort.uuid, false))

        if (!process.env.REACT_APP_OMC_DISABLE) {
          await dispatch(omcGetSystem(selectedPort.uuid))
        }

        success = success && !!extrasResult && !!tugsResult && !!weatherLocationsResult
      }

      if (success) {
        dispatch(
          addSuccessToast({
            message: TOAST_MESSAGES.REFRESH_SUCCESS,
          })
        )
      } else {
        dispatch(
          addErrorToast({
            message: TOAST_MESSAGES.REFRESH_ERROR,
          })
        )
      }
      setIsRefreshing(false)
    }
  }

  return (
    <>
      <FixedHeaderLayout
        headerContent={
          <PilotageListHeaderContent
            userUuid={idx(user, _ => _.uuid)}
            pilotageCount={myPilotages.length}
            onRefresh={() => refresh()}
            isRefreshing={isRefreshing}
            isOnline={isOnline}
            query={query}
            filterOptions={filterOptions}
          />
        }
      >
        <ContentWrapper isUpdateAvailable={isUpdateAvailable}>

          {queryAndDateRangeString && (
            <>
              <ResultSummary>
                <div>
                  <div className="result-title">Showing results for:</div>
                  <div className="result-value">{queryAndDateRangeString}</div>
                </div>
              </ResultSummary>
              <Divider />
            </>
          )}

          {formattedData && formattedData.length > 0 && (
            <>
              <PilotageTable
                data={pageData}
                timezone={timezone}
                showVesselVisitCode={showVesselVisitCode}
                isAscending={isAscending}
              />

              {formattedData.length > PAGE_SIZE_OPTIONS[0] && (
                <PaginationControls
                  totalRecords={formattedData.length}
                  page={pagination.page}
                  pageSize={pagination.pageSize}
                  onSetPage={page =>
                    dispatch(setFilter({
                      ...query,
                      ...pagination,
                      page,
                    }))
                  }
                  onSetPageSize={pageSize =>
                    // reset page to 1 when page size is changed
                    dispatch(setFilter({
                      ...query,
                      page: '1',
                      pageSize,
                    }))
                  }
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                />
              )}

              <AppVersion position="absolute" bottom={32} left={0} />

              {startNewButton}
            </>
          )}
          {!isLoading && formattedData && formattedData.length === 0 && (
            <PilotagesEmptyState
              onNew={startNew}
              pilotCreatePilotageDisabled={pilotCreatePilotageDisabled}
              totalResultCount={pilotages ? pilotages.length : 0}
              isFilterActive={queryString !== ''}
              isDateRangeActive={!!(query.startDate || query.endDate)}
              isOnline={isOnline}
            />
          )}
          {!data && isLoading && <p>Loading...</p>}
          {!data && !isLoading && error && (
            <>
              <p>Unable to load pilotages.</p>
              {startNewButton}
            </>
          )}
        </ContentWrapper>
      </FixedHeaderLayout>
      <Modal
        open={isNewPilotageModalOpen}
        onClose={() => window.history.back()}
        heading="New Pilotage Details"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <PilotageDetails isNew />
      </Modal>
    </>
  )
}

export default PilotageList
