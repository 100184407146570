import React from 'react'

const PlanApproachIcon = () => (
  <svg width="83" height="69">
    <g fillRule="nonzero" stroke="#FFF" fill="none">
      <path d="M1 62.306l5.339-27.73a3 3 0 0 1 3.619-2.356l12.884 2.967a3 3 0 0 1 2.287 3.41l-3.904 23.751a3 3 0 0 0 2.395 3.433l13.707 2.63a3 3 0 0 0 3.532-2.504l9.23-61.913a3 3 0 0 1 3.436-2.52L68.908 3.91a3 3 0 0 1 2.504 3.364l-6.914 51.298a3 3 0 0 0 2.514 3.365L83 64.412" />
      <path
        d="M42.404 7.535l-4.74-.75a1.6 1.6 0 0 0-1.831 1.33l-2.964 18.708a1.6 1.6 0 0 0 .2 1.057l1.911 3.272a1.6 1.6 0 0 0 2.446.387l2.83-2.52a1.6 1.6 0 0 0 .515-.945l2.963-18.708a1.6 1.6 0 0 0-1.33-1.83z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default PlanApproachIcon
