import styled from 'styled-components'

import InfoBox from 'src/components/atoms/InfoBox'

const ErrorMessage = styled(InfoBox)(
  ({ theme }) => `
  border: 1px solid ${theme.palette.error.main};
  background-color: rgba(255, 0, 0, 0.1);
`
)

export default ErrorMessage
