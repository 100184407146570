import api from 'src/utils/api/core'

export function getTideData(stationUuid, fromDate, toDate, tidesType) {
  return api.get(`/tide_data`, {
    params: {
      stationUuid,
      fromDate,
      toDate,
      tidesType,
    },
  })
}
