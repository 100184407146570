import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const PilotageCountText = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 0)};
    text-transform: uppercase;
    font-weight: ${theme.weights.fontWeightSemiBold};
    color: ${theme.palette.text.primary};
    margin-right: auto;
    letter-spacing: 0.6px;
    cursor: pointer;
  `
)

const PilotageCountValue = styled.span(
  ({ theme }) => css`
    color: ${theme.palette.primary.light};
    font-size: ${theme.typography.pxToRem(24)};
    padding: ${theme.spacing(0, 0.5)};
  `
)

const PilotageFilter = ({ totalPilotages, onClick }) => (
  <PilotageCountText onClick={onClick}>
    You have <PilotageCountValue>{totalPilotages}</PilotageCountValue> pilotage
    {totalPilotages === 1 ? '' : 's'}
  </PilotageCountText>
)

PilotageFilter.propTypes = {
  totalPilotages: PropTypes.number,
  onClick: PropTypes.func,
}

export default React.memo(PilotageFilter)
