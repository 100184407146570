import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getMasterViewToken as apiGetMasterViewToken } from 'src/utils/api/pilotages.js'
import {
  setAuthTokenHeader,
  getSinglePayloadFromResponse,
} from 'src/utils/api/core'

const MASTERVIEW_TOKEN_BASE = 'MASTERVIEW'

export const [
  MASTERVIEW_TOKEN_REQUEST,
  MASTERVIEW_TOKEN_SUCCESS,
  MASTERVIEW_TOKEN_ERROR,
  MASTERVIEW_TOKEN_CANCEL,
  MASTERVIEW_TOKEN_IN_PROGRESS,
] = createBasicActionTypes(MASTERVIEW_TOKEN_BASE)

export const getMasterViewToken = token => async dispatch => {
  dispatch(getMasterViewTokenInProgress(token))
  try {
    const response = await apiGetMasterViewToken(token)
    const result = getSinglePayloadFromResponse(response)
    setAuthTokenHeader(result.token)
    dispatch(getMasterViewTokenSuccess(result))
  } catch (error) {
    dispatch(getMasterViewTokenError(error))
  }
}

export const getMasterViewTokenInProgress = token =>
  createInProgressAction(MASTERVIEW_TOKEN_BASE, token)

export const getMasterViewTokenSuccess = payload =>
  createSuccessAction(MASTERVIEW_TOKEN_BASE, payload)

export const getMasterViewTokenError = error =>
  createErrorAction(MASTERVIEW_TOKEN_BASE, error)
