import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as MaterialTooltip } from '@material-ui/core'
import styled from 'styled-components'

import { InfoIcon } from 'src/components/atoms/Icons'

const IconWrapper = styled.div`
  & svg {
    display: block;
  }
`

const Tooltip = ({ content, color }) => (
  <MaterialTooltip title={content} enterTouchDelay={0}>
    <IconWrapper>
      <InfoIcon size={20} color={color} />
    </IconWrapper>
  </MaterialTooltip>
)

Tooltip.propTypes = {
  content: PropTypes.string,
  color: PropTypes.string
}

export default Tooltip
