import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChartMenuSection from 'src/components/molecules/ChartMenuSection'
import { CHART_COLOR_PALETTE } from 'src/utils/drawingConstants'

const ColorDot = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: ${({ value }) => value};
`

const colors = CHART_COLOR_PALETTE.map(color => ({ value: color }))

const ChartColorPicker = props => (
  <ChartMenuSection
    {...props}
    label="Colour"
    options={colors}
    renderItem={({ value }) => <ColorDot value={value} />}
  />
)

ChartColorPicker.propTypes = {
  disabled: PropTypes.bool,
  current: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any })),
  onSelect: PropTypes.func,
  onToggle: PropTypes.func,
}

export default ChartColorPicker
