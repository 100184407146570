import React from 'react'
import { useRoute } from 'react-router5'

import seaImage from 'src/assets/images/sea.jpg'
import LoginLayout from 'src/components/organisms/LoginLayout/LoginLayout'
import RequestNewPasswordForm from 'src/components/organisms/RequestNewPasswordForm'
import CreateNewPasswordForm from 'src/components/organisms/CreateNewPasswordForm'

const ResetPassword = () => {
  const { route } = useRoute()
  const { token } = route.params

  const loginPaneContent = token ? (
    <CreateNewPasswordForm token={token} />
  ) : (
    <RequestNewPasswordForm />
  )

  return <LoginLayout loginPaneContent={loginPaneContent} imageSrc={seaImage} />
}

export default ResetPassword
