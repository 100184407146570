import { createSelector } from 'reselect'

import { SYNC_STATUS } from 'src/utils/constants'

export const syncStateSelector = state => state.sync

export const syncStatusSelector = createSelector(
  syncStateSelector,
  state => state.status
)

export const syncInProgressSelector = createSelector(
  syncStatusSelector,
  status => SYNC_STATUS.IN_PROGRESS === status
)

export const syncErrorSelector = createSelector(
  syncStateSelector,
  state => state.error
)

export const syncLastSyncAttemptSelector = createSelector(
  syncStateSelector,
  state => state.lastSyncAttempt
)

export const entitiesSelector = createSelector(
  syncStateSelector,
  state => state.entities || {}
)
export const entitiesToSyncSelector = createSelector(
  entitiesSelector,
  entities =>
    Object.keys(entities).reduce((entitiesToSync, uuid) => {
      const entity = entities[uuid]
      return [
        SYNC_STATUS.PENDING,
        SYNC_STATUS.ERROR,
        SYNC_STATUS.IN_PROGRESS,
      ].includes(entity.status)
        ? { ...entitiesToSync, [uuid]: entity }
        : entitiesToSync
    }, {})
)

export const getStatusByUuid = uuid =>
  createSelector(
    entitiesSelector,
    entities => entities[uuid] && entities[uuid].status
  )

export const getEntitiesByStatus = status =>
  createSelector(
    syncStateSelector,
    state =>
      Object.values(state.entities).filter(entity => status === entity.status)
  )

export const getEntitiesToSyncByType = type =>
  createSelector(
    entitiesToSyncSelector,
    entities => Object.values(entities).filter(entity => type === entity.type)
  )

export const syncStatusStatSelector = createSelector(
  entitiesToSyncSelector,
  entities =>
    Object.keys(entities).reduce((stat, uuid) => {
      const entity = entities[uuid]
      return {
        ...stat,
        [entity.status]: (stat[entity.status] || 0) + 1,
      }
    }, {})
)

export const entityByUuidSelector = uuid =>
  createSelector(
    entitiesToSyncSelector,
    entities => entities && entities[uuid]
  )

export const pilotageSyncStatusByUuidSelector = pilotageUuid =>
  createSelector(
    entitiesSelector,
    entities => {
      return Object.values(entities)
        .filter(
          entity =>
            entity.pilotageUuid === pilotageUuid || entity.uuid === pilotageUuid
        )
        .reduce((pilotageStatus, { status }) => {
          switch (status) {
            case SYNC_STATUS.IN_PROGRESS:
              return SYNC_STATUS.IN_PROGRESS
            case SYNC_STATUS.ERROR:
              return SYNC_STATUS.ERROR
            case SYNC_STATUS.PENDING:
              return SYNC_STATUS.PENDING
            default:
              return pilotageStatus || SYNC_STATUS.SUCCESS
          }
        }, null)
    }
  )
