import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from 'react-router5'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import idx from 'idx'
import isEqual from 'fast-deep-equal'

import {
  ENTITY_NAME,
  MOVEMENT_TYPE,
  PILOTAGE_FORM_ACTION,
} from 'src/utils/constants'
import pilotageValidator from 'src/utils/validators/pilotageValidator'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { pilotsRequest } from 'src/store/pilots/actions'
import { pilotOptionsSelector } from 'src/store/pilots/selectors'
import { berthsStationsRequest } from 'src/store/berthsStations/actions'
import { berthsStationsSelector } from 'src/store/berthsStations/selectors'
import { routesLookupRequest } from 'src/store/routesLookup/actions'
import {
  routeOptionsSelector,
  routesLookupSelector,
} from 'src/store/routesLookup/selectors'
import { setUnsavedEntityStatus } from 'src/store/ui/actions'
import {
  goToPilotage,
  clearPilotage,
  pilotageCreateRequest,
  pilotageLoaded,
  pilotageUpdateRequest,
} from 'src/store/pilotage/actions'
import {
  selectedPortUuidSelector,
  timezoneSelector,
} from 'src/store/ports/selectors'
import { selectedVesselSelector } from 'src/store/vesselLookup/selectors'
import PilotageDetailsForm from 'src/components/organisms/PilotageDetails/PilotageDetailsForm'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { updateVesselRequest, vesselRequest } from 'src/store/vessel/actions'
import { authUserSelector } from 'src/store/auth/selectors'
import PilotageUpdates from 'src/components/atoms/PilotageUpdates/PilotageUpdates'
import getPilotageChangesNeedingReview from 'src/utils/getPilotageChangesNeedingReview'
import { formatUsername } from 'src/utils/formatters'
import {
  formValuesToPilotageData,
  pilotageDataToFormValues,
} from 'src/utils/pilotageDataFormConverters'
import { BerthStationType } from 'src/types/Pilotage'
import { labelSorter } from 'src/utils/sorting'
import { addToast } from 'src/store/toast/actions'
import { clearRoutesLookup } from './../../../store/routesLookup/actions'

const PilotageDetails = ({ isNew, onVesselSearch, confirmNewVessel }) => {
  const dispatch = useDispatch()
  const { route } = useRoute()

  const isOnline = useSelector(isOnlineSelector)
  const { data: pilotageData, isLoading: pilotageLoading } = useSelector(
    pilotageSelector
  )

  let pilotageDataToUse = isNew ? undefined : pilotageData

  const fromLocation = idx(pilotageDataToUse, _ => _.fromLocation)
  const toLocation = idx(pilotageDataToUse, _ => _.toLocation)

  const pilotOptions = useSelector(pilotOptionsSelector)
  const routeOptions = useSelector(routeOptionsSelector)

  const [formState, setFormState] = useState(null)

  const routeOptionsFiltered = useMemo(() =>
    (routeOptions || []).filter(({ route }) => {

      if (!route) { return true } // probably a stale client, keep old behaviour

      const { sideToRestrictions } = route

      return !formState || !formState.sideTo ||
      !Array.isArray(sideToRestrictions) ||
      sideToRestrictions.indexOf(formState.sideTo) === -1

    }),
  [routeOptions, formState && formState.sideTo]
  )

  // Pilotage status
  const status =
    pilotageDataToUse && pilotageDataToUse.uuid === route.params.id
      ? pilotageDataToUse.status
      : null

  // for existing pilotage, make sure a route that is not present in the lookup list
  // still shows in the box
  if (
    !isNew &&
    pilotageDataToUse &&
    pilotageDataToUse.uuid === route.params.id &&
    pilotageDataToUse.route
  ) {
    const pilotageRoute = pilotageDataToUse.route
    const routeIndex = routeOptionsFiltered.findIndex(
      item => item.value === pilotageRoute.uuid
    )

    if (routeIndex !== -1) {
      // Select input uses object equality for comparison, so replace the selected item
      // with the object from the pilotage so it's correctly pre-selected in the UI.
      routeOptionsFiltered[routeIndex] = {
        value: pilotageRoute.uuid,
        label: pilotageRoute.name,
        route: pilotageRoute,
      }
      if (formState && formState.route !== '' &&
          !routeOptionsFiltered.find(item => item.value === formState.route)) {
        setFormState({
          ...formState,
          route: '',
        })
      }
    } else if (
      formState &&
      fromLocation &&
      toLocation &&
      formState.from === fromLocation.uuid &&
      formState.to === toLocation.uuid
    ) {
      // Deleted route or a route no longer valid between the given from and to values.
      // Add the existing value to options anyway, so the UI doesn't show an empty input.
      // EMPX-587 only add if sideTo changed, ideally should check if more restrictive
      // but we only store pilotageRoute.uuid and pilotageRoute.name, so only can detect change.
      if (pilotageDataToUse.sideTo === formState.sideTo) {
        routeOptionsFiltered.unshift({
          value: pilotageRoute.uuid,
          label: pilotageRoute.name,
          route: pilotageRoute,
        })
        setFormState({
          ...formState,
          route: pilotageRoute.uuid,
        })
      } else {
        if (formState.route !== '' &&
            !routeOptionsFiltered.find(item => item.value === formState.route)) {
          setFormState({
            ...formState,
            route: '',
          })
        }
      }
    } else if (
      formState &&
      formState.route &&
      !routeOptionsFiltered.find(item => item.value === formState.route)
    ) {
      // No routes match the existing one, and the from/to selection has been altered.
      // Set the field to empty in this scenario, so the user must choose a new route.
      setFormState({
        ...formState,
        route: '',
      })
    }
  } else if (isNew && formState && formState.route) {
    // For a new pilotage, if the user changes the from/to and the existing route
    // selected in the form is not valid for this from/to combination, clear the
    // route field.
    const routeIndex = routeOptionsFiltered.findIndex(
      item => item.value === formState.route
    )
    if (routeIndex === -1) {
      setFormState({
        ...formState,
        route: '',
      })
    }
  }

  const { isLoading: routeOptionsLoading } = useSelector(routesLookupSelector)
  const {
    data: berthsStations,
    isLoading: berthsStationsLoading,
  } = useSelector(berthsStationsSelector)

  const selectedPortUuid = useSelector(selectedPortUuidSelector)
  const timeZone = useSelector(timezoneSelector)
  const selectedVessel = useSelector(selectedVesselSelector)

  const user = useSelector(authUserSelector)
  const isAssignee =
    formState &&
    user &&
    (formState.pilot === user.uuid || formState.pilotSecond === user.uuid)

  // The type of berthStations that can be selected in the from and to fields
  // are dynamic and depend on the movement type the user selects.
  const [fromStationType, setFromStationType] = useState(null)
  const [toStationType, setToStationType] = useState(null)

  // Counter is used when user presses the Create another button. We can
  // increment the value to trigger a form state reinitialisation.
  const [counter, setCounter] = useState(0)

  // The form has multiple submit buttons which perform different additional
  // actions after saving. Use a ref to track which one was clicked.
  const formAction = useRef(null)

  // Initial pilots loading (once online)
  useEffect(
    () => {
      if (isOnline) {
        dispatch(pilotsRequest())
      }
    },
    [isOnline]
  )

  // Initial berths boarding places loading (once online)
  useEffect(
    () => {
      if (isOnline && selectedPortUuid) {
        dispatch(berthsStationsRequest(selectedPortUuid))
      }
    },
    [isOnline, selectedPortUuid]
  )

  const loadRouteOptions = (fromUuid, toUuid) => {
    dispatch(routesLookupRequest(fromUuid, toUuid))
  }

  // Initial route options loading (once online)
  useEffect(
    () => {
      if (!isNew && fromLocation && toLocation && isOnline) {
        loadRouteOptions(fromLocation.uuid, toLocation.uuid)
      }
    },
    [fromLocation, toLocation, isOnline]
  )

  // Set the initial from and to station type based on existing pilotage
  // data if it exists.
  useEffect(
    () => {
      if (pilotageDataToUse) {
        if (fromStationType === null) {
          let nextFromStationType = null
          if (pilotageDataToUse.fromLocation) {
            nextFromStationType = pilotageDataToUse.fromLocation.berthStationType
          } else {
            switch (pilotageDataToUse.movementType) {
              case MOVEMENT_TYPE.ARRIVAL:
                nextFromStationType = BerthStationType.Station
                break
              case MOVEMENT_TYPE.DEPARTURE:
                nextFromStationType = BerthStationType.Berth
                break
              default:
                nextFromStationType = null
            }
          }
          setFromStationType(nextFromStationType)
        }

        if (toStationType === null) {
          let nextToStationType = null
          if (pilotageDataToUse.toLocation) {
            nextToStationType = pilotageDataToUse.toLocation.berthStationType
          } else {
            switch (pilotageDataToUse.movementType) {
              case MOVEMENT_TYPE.ARRIVAL:
                nextToStationType = BerthStationType.Berth
                break
              case MOVEMENT_TYPE.DEPARTURE:
                nextToStationType = BerthStationType.Station
                break
              default:
                nextToStationType = null
            }
          }
          setToStationType(nextToStationType)
        }
      }
    },
    [pilotageDataToUse, fromStationType, toStationType]
  )

  const vesselMatchResult = useRef(null)

  // Track the form action (which button was pressed) and attempt to
  // submit the form (fails if validation returns errors).
  const attemptSubmit = async (submitForm, action) => {

    formAction.current = action
    submitForm()
  }

  const onSubmit = async (values, actions) => {

    if (
      !isNew &&
      pilotageDataToUse.route &&
      pilotageDataToUse.route.uuid !== values.route
    ) {
      const confirmResult = window.confirm(
        'Are you sure you want to change the route? Passage planning may need to be redone when switching routes.'
      )
      if (!confirmResult) {
        // Reset route, to, from back to original value
        setFormState(state => ({
          ...state,
          route: pilotageDataToUse.route || '',
          to: pilotageDataToUse.toLocation || '',
          from: pilotageDataToUse.fromLocation || '',
        }))
        actions.setSubmitting(false)
        return
      }
    }

    try {
      vesselMatchResult.current = await dispatch(
        vesselRequest(selectedPortUuid, formState.vesselIMO)
      )
      if (!vesselMatchResult.current) {
        if (!(await confirmNewVessel())) {
          actions.setSubmitting(false)
          return
        }
      }
    } catch (e) {
      // proceed, do nothing
    }

    const data = formValuesToPilotageData(values, timeZone, pilotageDataToUse)
    if (isNew && selectedPortUuid) {
      data.port = {
        uuid: selectedPortUuid,
      }
    }

    // Let pilots know if max draft override has been changed
    if (
      data && typeof data.vesselMaxDraft === 'number' &&
      pilotageDataToUse &&
      (
        parseFloat(data.vesselMaxDraft).toFixed(2) !==
        parseFloat(pilotageDataToUse.vesselMaxDraft).toFixed(2)
      )
    ) {
      await dispatch(addToast({ message: `Max Draft has been defaulted to ${data.vesselMaxDraft}m` }))
    }

    // EMPX-79: Offline prefetch for tides is between -1d and 3d.
    // This ensures that we can safely update the pilotage time without
    // triggering the error below. Tidy up onces things settle.
    // if (!isOnline) {
    //   const date = parseFromTimeZone(data.date, { timeZone })
    //   const minDate = convertToTimeZone(new Date(), { timeZone })
    //   minDate.setHours(
    //     minDate.getHours() - OFFLINE_PILOTAGES_PREFETCH_PAST_MARGIN_HOURS
    //   )
    //   const maxDate = convertToTimeZone(new Date(), { timeZone })
    //   maxDate.setDate(maxDate.getDate() + OFFLINE_PILOTAGES_DAYS_TO_PREFETCH)
    //   if (date < minDate || date > maxDate) {
    //     dispatch(
    //       addErrorToast({
    //         message: TOAST_MESSAGES.UPDATE_PILOTAGE_ERROR_NO_OFFLINE_TIDE_DATA,
    //       })
    //     )
    //     actions.setSubmitting(false)
    //   }
    // }

    // We'll need to send a vessel update request if the matched vessel name doesn't equal
    // the user-entered value, or if for some reason the matched vessel is missing, we can
    // check the vessel name on the existing pilotage object.
    // EMPX-97 only update vessel name if it is not a new vessel
    let isVesselChange = false
    if (vesselMatchResult.current) {
      isVesselChange =
        (vesselMatchResult.current &&
          vesselMatchResult.current.name !== data.vessel.name) ||
        (!isNew &&
          pilotageDataToUse &&
          pilotageDataToUse.vessel &&
          pilotageDataToUse.vessel.name !== data.vessel.name)
    }

    let savedVessel

    if (isVesselChange) {
      savedVessel = await dispatch(
        updateVesselRequest(data.vessel.IMO, {
          name: data.vessel.name,
          port: { uuid: selectedPortUuid },
        })
      )
    }

    const changeSet = isNew
      ? null
      : getPilotageChangesNeedingReview(pilotageDataToUse, user)

    const isReviewMode = !!changeSet

    // NOTE: Updating the vessel name causes the pilotage object to be modified on the BE.
    // To stop the "pilotage updates" screen immediately appearing we need to send the
    // create/update pilotage request to mark it reviewed after the update vessel request,
    // even if no fields on the pilotage itself actually changed.
    const savedPilotage = await dispatch(
      isNew
        ? pilotageCreateRequest(data)
        : pilotageUpdateRequest(
          pilotageDataToUse.uuid,
          data,
          null,
          false,
          isReviewMode
        )
    )

    actions.setSubmitting(false)

    const pilotageSuccess = !!savedPilotage
    const vesselSuccess = !isVesselChange || !!savedVessel

    if (pilotageSuccess && vesselSuccess) {
      switch (formAction.current) {
        case PILOTAGE_FORM_ACTION.SAVE_AND_START_PLANNING:
          // Note that savedPilotage will always exist here - this action is only available
          // on new/draft screen where the create/update pilotage request always gets sent.
          dispatch(pilotageLoaded(savedPilotage, true))
          dispatch(clearRoutesLookup())
          dispatch(clearPilotage(savedPilotage))
          // replace the state so that back button goes back to pilotage list
          dispatch(goToPilotage({ uuid: savedPilotage.uuid, replace: true }))
          break
        case PILOTAGE_FORM_ACTION.SAVE_AND_START_ANOTHER:
          // increment the counter to trigger a form initial state
          // recalculation, which in turn reinitialises the form.
          setCounter(counter + 1)
          break
        case PILOTAGE_FORM_ACTION.SAVE_AND_CLOSE:
        default:
          window.history.back()
          break
      }
    }
  }

  const onFormChange = state => {
    // Keep form state synced
    setFormState(state)
    const hasChanged = !isEqual(
      state,
      pilotageDataToFormValues(isNew ? null : pilotageDataToUse, timeZone)
    )
    dispatch(setUnsavedEntityStatus(ENTITY_NAME.PILOTAGE, hasChanged))
  }

  useEffect(() => {
    return () => {
      dispatch(setUnsavedEntityStatus(ENTITY_NAME.PILOTAGE, false))
    }
  }, [])

  const updateRouteFields = (movementType, from, to, setFieldValue) => {
    let fromType, toType

    switch (movementType) {
      case MOVEMENT_TYPE.ARRIVAL:
        fromType = BerthStationType.Station
        toType = BerthStationType.Berth
        break
      case MOVEMENT_TYPE.DEPARTURE:
        fromType = BerthStationType.Berth
        toType = BerthStationType.Station
        break
      case MOVEMENT_TYPE.SHIFT:
      default:
        fromType = BerthStationType.Berth
        toType = BerthStationType.Berth
        break
    }

    if (from && fromType !== from.berthStationType) {
      setFieldValue('from', '')
    }

    if (to && toType !== toType.berthStationType) {
      setFieldValue('to', '')
    }

    setFieldValue('route', '')

    setFromStationType(fromType)
    setToStationType(toType)
  }

  const fromOptions = useMemo(
    () => {
      if (!berthsStations) {
        return []
      }
      const filtered = fromStationType
        ? berthsStations.filter(bs => bs.berthStationType === fromStationType)
        : berthsStations

      if (fromLocation && fromLocation.berthStationType === fromStationType) {
        const fromLocationIndex = filtered.findIndex(
          item => item.uuid === fromLocation.uuid
        )
        if (fromLocationIndex === -1) {
          // Deleted berth/pilot boarding place. Add to options so the UI doesn't show an empty input
          filtered.unshift(fromLocation)
        } else {
          // Select input uses object equality for comparison, so replace the selected item
          // with the object from the pilotage so it's correctly pre-selected in the UI.
          filtered[fromLocationIndex] = fromLocation
        }
      }

      return filtered
        .map(bs => ({ label: bs.name, value: bs.uuid }))
        .sort(labelSorter)
    },
    [berthsStations, fromStationType, fromLocation]
  )

  const toOptions = useMemo(
    () => {
      if (!berthsStations) {
        return []
      }
      const filtered = toStationType
        ? berthsStations.filter(bs => bs.berthStationType === toStationType)
        : berthsStations

      if (toLocation && toLocation.berthStationType === toStationType) {
        const toLocationIndex = filtered.findIndex(
          item => item.uuid === toLocation.uuid
        )
        if (toLocationIndex === -1) {
          // Deleted berth/pilot boarding place. Add to options so the UI doesn't show an empty input
          filtered.unshift(toLocation)
        } else {
          // Select input uses object equality for comparison, so replace the selected item
          // with the object from the pilotage so it's correctly pre-selected in the UI.
          filtered[toLocationIndex] = toLocation
        }
      }

      return filtered
        .map(bs => ({ label: bs.name, value: bs.uuid }))
        .sort(labelSorter)
    },
    [berthsStations, toStationType, toLocation]
  )

  const onReset = overrides => {
    // Allow the form to be reset with optional overrides.
    setFormState({
      ...pilotageDataToFormValues(isNew ? null : pilotageDataToUse, timeZone),
      ...(overrides || {}),
    })
  }

  useEffect(
    () => {
      // Make sure we don't prefill the form with data from the store if this
      // is a new pilotage being created
      onReset()
    },
    [pilotageData, counter]
  )

  // For a pilot that doesn't exist anymore (e.g. deleted) still show their name
  // EMPX-512 when pilot is deleted while pilotage can still be edited, pilotageData.pilot or
  // pilotageData.pilotSecond can be null, so we check for null below.
  let pilotOptionsFinal = [...pilotOptions]
  if (pilotageDataToUse && formState) {
    if (pilotageDataToUse.pilot && !pilotOptions.find(item => item.value === formState.pilot)) {
      pilotOptionsFinal.push({
        value: pilotageDataToUse.pilot.uuid,
        label: formatUsername(pilotageDataToUse.pilot),
      })
    }
    if (pilotageDataToUse.pilotSecond && !pilotOptions.find(item => item.value === formState.pilotSecond)) {
      pilotOptionsFinal.push({
        value: pilotageDataToUse.pilotSecond.uuid,
        label: formatUsername(pilotageDataToUse.pilotSecond),
      })
    }
  }

  if (formState && routeOptionsFiltered.length === 1 && routeOptionsFiltered[0].route &&
    routeOptionsFiltered[0].route.uuid !== formState.route) {
    setFormState({
      ...formState,
      route: routeOptionsFiltered[0].route.uuid,
    })
  }

  const changeSet = isNew
    ? null
    : getPilotageChangesNeedingReview(pilotageDataToUse, user)

  const isReviewMode = !!changeSet

  const formProps = {
    status,
    isReviewMode,
    isAssignee,
    pilotageLoading,
    onFormChange,
    fromOptions,
    toOptions,
    routeOptions: routeOptionsFiltered,
    pilotOptions: pilotOptionsFinal,
    attemptSubmit,
    routeOptionsLoading,
    updateRouteFields,
    loadRouteOptions,
    berthsStationsLoading,
    selectedVessel,
    onVesselSearch,
    pilotage: pilotageDataToUse,
  }

  return (
    formState && (
      <Formik
        enableReinitialize
        initialValues={formState}
        validate={pilotageValidator}
        onSubmit={onSubmit}
      >
        {props => (
          <>
            {changeSet && (
              <PilotageUpdates changeSet={changeSet} timeZone={timeZone} />
            )}
            <PilotageDetailsForm formik={props} {...formProps} />
          </>
        )}
      </Formik>
    )
  )
}

PilotageDetails.propTypes = {
  isNew: PropTypes.bool,
  onVesselSearch: PropTypes.func,
  confirmNewVessel: PropTypes.func,
}

export default PilotageDetails
