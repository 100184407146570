import React, { ReactElement } from 'react'
import { TideGraphInputs, TideGraphProps } from './types'
import { useTideGraph } from './useTideGraph'

export const TideGraphDataLoader: React.FC<
    TideGraphInputs & {
        children: (tideGraphProps: TideGraphProps) => ReactElement
    }
> = (props) => {
    const tideGraphProps = useTideGraph(props) 

    return props.children(tideGraphProps)
}

export default TideGraphDataLoader
