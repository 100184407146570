import React, { Component, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { routeNode, useRouter, useRouteNode } from 'react-router5'
import { RouteContext } from 'react-router5/types/types'
import * as Sentry from "@sentry/react"
import {
  RESET_PASSWORD,
  LOCKED,
  LOGIN,
  LOGINSSO,
  PILOTAGES,
  NEW_PILOTAGE,
  PILOTAGE_PLAN_STAGE,
  SIGN_UP,
  RESET_PASSWORD_TOKEN,
  isAnonymousRoute,
  DRAFT_PILOTAGE,
  REVIEW_PILOTAGE,
  PILOTAGE_DETAIL,
  DEBUG,
  LOGOUT,
} from 'src/router/routes'

import ErrorUi from 'src/components/atoms/ErrorUi'
import OfflineMessage from 'src/components/atoms/OfflineMessage'
import Loading from 'src/containers/Loading'
import Signup from 'src/containers/Signup'
import Login from 'src/containers/Login'
import LoginSso from 'src/containers/LoginSso'
import PilotageList from 'src/containers/PilotageList'
import ResetPassword from 'src/containers/ResetPassword'
import RouterTransition from 'src/containers/App/RouterTransition'
import { PilotagePlanStage } from 'src/containers/Pilotage'
import PilotageDefinition from 'src/containers/PilotageDefinition'
import Debug from 'src/containers/Debug'
import Sync from 'src/containers/Sync'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import useSync from 'src/hooks/useSync'
import useSession from 'src/hooks/useSession'
import usePrefetch from 'src/hooks/usePrefetch'
import useAnalytics from 'src/hooks/useAnalytics'
import { isLoggedInSelector } from 'src/store/auth/selectors'
import { SESSION_STATUS } from 'src/utils/constants'
import { Updater } from 'src/serviceWorker'
import { updateAvailable } from 'src/store/ui/actions'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'
import { useServiceWorker } from 'src/hooks/useServiceWorker'
import DetailsLayout from 'src/components/organisms/DetailsLayout/DetailsLayout'
import { prefetchResetAll } from 'src/store/prefetch/actions'
import { prefetchedPilotagesAnyInProgressSelector } from 'src/store/prefetch/selectors'
import { isHostSsoDomain } from 'src/utils/ssoHelper'
import { requestLogout } from 'src/store/auth/actions'
import { SSO_ENABLE_CONSOLE_LOG } from 'src/utils/ssoConstants'
import useHotjar from 'src/hooks/useHotjar'
import Locked from '../Locked'
import { AxiosInterceptor } from 'src/utils/api/core'

class AppContainer extends Component<RouteContext, { error?: Error }> {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={ErrorUi}>
        <AxiosInterceptor>
          <App route={this.props.route} />
        </AxiosInterceptor>
      </Sentry.ErrorBoundary>
    )
  }
}

const App = () => {
  const router = useRouter()
  const { route } = useRouteNode('')
  const topRouteName = route.name.split('.')[0]

  const dispatch = useDispatch()
  const { initGA, recordPageView } = useAnalytics()
  const { initHJ } = useHotjar()
  const isOnline = useOnlineStatus(true)
  const { needSync, canSync, inProgress: syncInProgress } = useSync()
  const { isReady: serviceWorkerIsReady } = useServiceWorker()

  const { fetch } = usePrefetch()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const selectedPortUuid = useSelector(selectedPortUuidSelector)
  const anyInPrefetch = useSelector(prefetchedPilotagesAnyInProgressSelector)
  const {
    refresh: refreshSession,
    isValid: sessionIsValid,
    status: sessionStatus,
  } = useSession()

  useEffect(() => {
    initGA()
    initHJ()
    // pilotage prefetch stores state in redux, so if indicator is loading
    // we hit refresh, the pilotage will never load again (indicator is loading forever)
    // we clear prefetch if online
    if (isOnline) {
      if (anyInPrefetch) {
        dispatch(prefetchResetAll())
      }
    }
  }, [])

  useEffect(
    () => {
      recordPageView(route.name)
    },
    [route]
  )

  // Navigate to the login if the session is not valid
  // or the user is not logged in
  useEffect(
    () => {
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`route.name [${route.name}]`)
      }
      if (route.name === LOGOUT) {
        dispatch(requestLogout())
        return
      }
      if (
        (!isLoggedIn || sessionStatus === SESSION_STATUS.INVALID) &&
        !isAnonymousRoute(route.name)
      ) {
        if (isHostSsoDomain()) {
          router.navigate(LOGINSSO, { code: 'logout' }, { replace: true })
        } else {
          router.navigate(LOGIN)
        }
      }
    },
    [isLoggedIn, sessionIsValid]
  )

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && isOnline) {
        refreshSession()
      }
    })
  }, [])

  const handlePrefetchError = (error: Error) => {
    // intentional console
    // TODO decide if we warn the user that
    // the app is not ready for offline usage
    console.warn(error)
  }
  useEffect(
    () => {
      // prefetch every time, when the session has been revalidated
      // or the pilotage list changes
      if (
        serviceWorkerIsReady &&
        sessionIsValid &&
        !needSync &&
        selectedPortUuid
      ) {
        fetch(true).catch(error => handlePrefetchError(error))
      }
    },
    [sessionIsValid, needSync, selectedPortUuid, serviceWorkerIsReady]
  )

  useEffect(() => {
    Updater.onUpdateAvailable(() => {
      dispatch(updateAvailable())
    })
  }, [])

  let content

  if (route.name === LOCKED) {
    content = <Locked />
  } else if ((needSync || syncInProgress) && canSync) {
    content = <Sync />
  } else if (sessionStatus === SESSION_STATUS.INITIALISING) {
    content = <Loading />
  } else {
    if (topRouteName === PILOTAGE_DETAIL) {
      return <DetailsLayout />
    }

    switch (route.name) {
      case SIGN_UP:
        content = <Signup />
        break
      case LOGIN:
        content = <Login />
        break
      case LOGINSSO:
        content = <LoginSso />
        break
      case LOGOUT:
        content = <Loading />
        break
      case RESET_PASSWORD:
      case RESET_PASSWORD_TOKEN:
        content = <ResetPassword />
        break
      case PILOTAGES:
        content = <PilotageList />
        break
      case NEW_PILOTAGE:
        content = <PilotageDefinition context="New" />
        break
      case DRAFT_PILOTAGE:
        content = <PilotageDefinition context="Draft" />
        break
      case REVIEW_PILOTAGE:
        content = <PilotageDefinition context="Review" />
        break
      case PILOTAGE_PLAN_STAGE:
        content = <PilotagePlanStage />
        break
      case DEBUG:
        content = <Debug />
        break
      default:
      // TODO: 404 screen component
    }
  }

  return (
    <>
      { route.name !== PILOTAGE_PLAN_STAGE &&
        <OfflineMessage isOnline={isOnline} />
      }
      <RouterTransition route={route}>
        {content || <div>Not found</div>}
      </RouterTransition>
    </>
  )
}

App.propTypes = {
  route: PropTypes.object,
}

export default routeNode('')(AppContainer)
