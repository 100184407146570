import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const MpxButton = styled(({ canUndone, active, ...props }) => (
  <Button
    readOnly
    disableRipple
    variant={active ? 'outlined' : 'text'}
    {...props}
  />
))(
  ({ theme, active }) => `
  && {
    position: relative;
    width: 308px;
    padding: ${theme.spacing(2)}px;
    background-color: ${
      active ? theme.palette.background.light : theme.palette.background.default
    };
    cursor: default;
    &:hover {
      background-color: ${
        active
          ? theme.palette.background.light
          : theme.palette.background.default
      };
    }
    & svg {
      display: block;
    }
  }
`
)

export const MpxDoneButton = styled(MpxButton)(
  ({ theme, canUndone }) => `
  && {
    cursor: ${canUndone ? 'pointer' : 'default'};
    margin-bottom: ${theme.spacing(2)}px;
    border-color: ${theme.palette.success.main};
  }
`
)

export const ArchivePilotageButton = styled(MpxButton)(
  ({ theme, active }) => `
  && {
    cursor: ${active ? 'pointer' : 'default'};
    border-color: ${theme.palette.primary.main};
    border-style: dashed;

    &:disabled {
      color: ${theme.palette.readOnly};
      border-color: ${theme.palette.readOnly};
    }
  }
`
)
