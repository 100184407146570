import { formatToTimeZone } from 'date-fns-timezone'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { getWeatherData, getWeatherDataMulti } from 'src/utils/api/weather'
import { WEATHER_MAX_INTERVAL, WEATHER_USE_MULTI_ENDPOINT } from 'src/utils/constants'
import { WeatherLocation } from 'src/types/WeatherLocation'
import { APITypeEnum, WeatherWrapper } from 'src/types/Weather'
import { WeatherLocationsState } from 'src/store/weatherLocations/reducer'
import { weatherLocationsStateSelector } from 'src/store/weatherLocations/selectors'
import { getPayloadFromResponse } from './../../utils/api/core';

export type WeatherAction
  = { type: 'CLEAR_PILOTAGE_DATA' }
  | { type: 'WEATHER_CLEAR' }
  | { type: 'WEATHER_REQUEST', weatherLocationUuid: string }
  | { type: 'WEATHER_SUCCESS', weatherLocationUuid: string, weather: WeatherWrapper }
  | { type: 'WEATHER_ERROR', weatherLocationUuid: string, error: string }

type Dispatch = (action: WeatherAction) => void

export const weatherRequest = (
  pilotage: { date: string, port: { uuid: string, timezone: string }},
  isPrefetch?: boolean
) => async (
  dispatch: Dispatch,
  getState: () => ({ weatherLocations: WeatherLocationsState })
) => {

  const { portUuid, weatherLocations, isLoading } = weatherLocationsStateSelector(getState())

  if (portUuid !== pilotage.port.uuid || isLoading) { return }

  await dispatch({ type: 'WEATHER_CLEAR' })

  let results
  const useMulti = WEATHER_USE_MULTI_ENDPOINT
  if (!useMulti) {
    results = await Promise.all(
      weatherLocations.map(
        weatherLocation =>
          weatherAtLocationRequest({
            time: pilotage.date,
            timeZone: pilotage.port.timezone,
            portUuid: pilotage.port.uuid,
            weatherLocation,
            isPrefetch
          })(dispatch)
      )
    )
  } else {
    results = await weatherAtLocationsRequest({
      time: pilotage.date,
      timeZone: pilotage.port.timezone,
      portUuid: pilotage.port.uuid,
      weatherLocations,
      isPrefetch
    })(dispatch)
  }

  return results
}

interface WeatherForWeatherLocationRequest {
  time: string
  timeZone: string
  portUuid: string
  weatherLocation: WeatherLocation
  isPrefetch?: boolean
}
interface WeatherForWeatherLocationsRequest {
  time: string
  timeZone: string
  portUuid: string
  weatherLocations: WeatherLocation[]
  isPrefetch?: boolean
}

export const weatherAtLocationRequest = ({
  time,
  timeZone,
  portUuid,
  weatherLocation,
  isPrefetch
}: WeatherForWeatherLocationRequest) => async (dispatch: Dispatch): Promise<WeatherWrapper> => {

  const { uuid, location } = weatherLocation
  const { latitude, longitude } = location
  const updateProgress = !isPrefetch
  const fromDate = new Date(time)
  const now = new Date()
  const inRange = fromDate.valueOf() < (now.valueOf() + WEATHER_MAX_INTERVAL)

  try {
    if (inRange) {

      const toDate = new Date(time)
      toDate.setDate(fromDate.getDate() + 1) // may need to be longer to cover long pilotages, or take actual pilotage end time

      if (updateProgress) {
        weatherInProgress(uuid)(dispatch)
      }

      const response = await getWeatherData(
        portUuid,
        formatToTimeZone(fromDate, 'YYYY-MM-DD', { timeZone }),
        formatToTimeZone(toDate, 'YYYY-MM-DD', { timeZone }),
        latitude,
        longitude,
        { timeout: 0 }
      )

      const weather = getSinglePayloadFromResponse(response) as WeatherWrapper
      if (updateProgress) {
        weatherLoaded(uuid, weather)(dispatch)
      }
      return weather

    } else {
      const weather = { source: APITypeEnum.NONE, error: 'No weather forecast data available for this date and time', weatherData: [] }
      weatherError(uuid, weather.error)(dispatch)
      return weather
    }

  } catch (error) {
    if (!isPrefetch) {
      weatherError(uuid, error.message)(dispatch)
      return Promise.resolve({ source: APITypeEnum.NONE, error: error.message, weatherData: [] })
    } else {
      throw error
    }
  }
}

export const weatherAtLocationsRequest = ({
  time,
  timeZone,
  portUuid,
  weatherLocations,
  isPrefetch
}: WeatherForWeatherLocationsRequest) => async (dispatch: Dispatch): Promise<WeatherWrapper[]> => {

  const updateProgress = !isPrefetch
  const fromDate = new Date(time)
  const now = new Date()
  const inRange = fromDate.valueOf() < (now.valueOf() + WEATHER_MAX_INTERVAL)

  try {
    if (inRange) {

      if (updateProgress) {
        for (let i: number = 0; i < weatherLocations.length; i++) {
          const weatherLocation: WeatherLocation = weatherLocations[i]
          const { uuid } = weatherLocation
          weatherInProgress(uuid)(dispatch)
        }
      }

      const weatherLocationParams = []
      for (let i: number = 0; i < weatherLocations.length; i++) {
        const weatherLocation: WeatherLocation = weatherLocations[i]
        const { location } = weatherLocation
        const { latitude, longitude } = location

        const toDate = new Date(time)
        toDate.setDate(fromDate.getDate() + 1) // may need to be longer to cover long pilotages, or take actual pilotage end time
        weatherLocationParams.push(
          {
            portUuid,
            fromDate: formatToTimeZone(fromDate, 'YYYY-MM-DD', { timeZone }),
            toDate: formatToTimeZone(toDate, 'YYYY-MM-DD', { timeZone }),
            latitude,
            longitude,
          }
        )
      }

      const response = await getWeatherDataMulti(
        weatherLocationParams,
        { timeout: 0 }
      )

      const weathers = getPayloadFromResponse(response) as WeatherWrapper[]
      if (updateProgress) {
        for (let i: number = 0; i < weathers.length; i++) {
          const weather: WeatherWrapper = weathers[i]
          const weatherLocation: WeatherLocation = weatherLocations[i]
          const { uuid } = weatherLocation
          weatherLoaded(uuid, weather)(dispatch)
        }
      }
      return weathers

    } else {
      const weathers = []
      for (let i: number = 0; i < weatherLocations.length; i++) {
        const weatherLocation: WeatherLocation = weatherLocations[i]
        const { uuid } = weatherLocation
        const weather = { source: APITypeEnum.NONE, error: 'No weather forecast data available for this date and time', weatherData: [] }
        weatherError(uuid, weather.error)(dispatch)
        weathers.push(weather)
      }
      return weathers
    }

  } catch (error) {
    if (!isPrefetch) {
      const weathers = []
      for (let i: number = 0; i < weatherLocations.length; i++) {
        const weatherLocation: WeatherLocation = weatherLocations[i]
        const { uuid } = weatherLocation
        const weather = { source: APITypeEnum.NONE, error: error.message, weatherData: [] }
        weatherError(uuid, error.message)(dispatch)
        weathers.push(weather)
      }
      // return Promise.resolve({ source: APITypeEnum.NONE, error: error.message, weatherData: [] })
      return weathers
    } else {
      throw error
    }
  }
}

export const weatherInProgress = (weatherLocationUuid: string) =>
  (dispatch: Dispatch) =>
    dispatch({ type: 'WEATHER_REQUEST', weatherLocationUuid })

export const weatherError = (weatherLocationUuid: string, error: string) =>
  (dispatch: Dispatch) => {
    dispatch({ type: 'WEATHER_ERROR', weatherLocationUuid, error })
  }

export const weatherLoaded = (weatherLocationUuid: string, weather: WeatherWrapper) =>
  (dispatch: Dispatch) =>
    dispatch({ type: 'WEATHER_SUCCESS', weatherLocationUuid, weather })
