import React from 'react'
import PropTypes from 'prop-types'

const InfoIcon = ({ size, color = "#1F8EFA", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(1 1)" fill="none">
      <circle stroke={color} cx="7" cy="7" r="7" />
      <text fontSize="11" fontWeight="500" fill={color}>
        <tspan x="5.535" y="11" style={{textTransform: 'lowercase'}}>
          i
        </tspan>
      </text>
    </g>
  </svg>
)

InfoIcon.propTypes = {
  size: PropTypes.number,
}

InfoIcon.defaultProps = {
  size: 28,
}

export default InfoIcon
