import {
  PILOTAGE_TUGS_IN_PROGRESS,
  PILOTAGE_TUGS_ERROR,
  PILOTAGE_TUGS_SUCCESS,
  PILOTAGE_TUGS_ADD_IN_PROGRESS,
  PILOTAGE_TUGS_ADD_ERROR,
  PILOTAGE_TUGS_REMOVE_IN_PROGRESS,
  PILOTAGE_TUGS_REMOVE_ERROR,
} from 'src/store/pilotageTugs/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import { SYNC_ENTITY_SUCCESS } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'

const INITIAL_STATE = {
  pilotageUuid: null,
  data: null,
  isLoading: false,
  error: null,
}

function pilotageTugsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case PILOTAGE_TUGS_IN_PROGRESS:
    case PILOTAGE_TUGS_ADD_IN_PROGRESS:
    case PILOTAGE_TUGS_REMOVE_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PILOTAGE_TUGS_ERROR:
    case PILOTAGE_TUGS_ADD_ERROR:
    case PILOTAGE_TUGS_REMOVE_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PILOTAGE_TUGS_SUCCESS:
      return {
        ...state,
        data: payload.data.reduce(
          (data, pilotageTug) => ({
            ...data,
            [pilotageTug.lineNumber]: pilotageTug,
          }),
          {}
        ),
        isLoading: false,
        error: null,
        pilotageUuid: payload.pilotageUuid,
      }
    case SYNC_ENTITY_SUCCESS: {
      const {
        type: syncType,
        entity: { pilotageUuid },
      } = payload
      const { result } = action
      return syncType !== SYNC_ENTITY_TYPES.PILOTAGE_TUGS ||
        pilotageUuid !== state.pilotageUuid
        ? state
        : {
            ...state,
            data: result.reduce(
              (data, pilotageTug) => ({
                ...data,
                [pilotageTug.lineNumber]: pilotageTug,
              }),
              {}
            ),
          }
    }
    default:
      return state
  }
}

export default pilotageTugsReducer
