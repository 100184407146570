import React from 'react'

const ConstraintUkcIcon = () => (
  <svg width="43" height="43" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <g stroke="#95A6B6">
        <path
          d="M11.555 15.667h16.571l-2.506 3.227a2 2 0 01-1.58.773h-8.4a2 2 0 01-1.579-.773l-2.506-3.227h0z"
          fill="#95A6B6"
          strokeLinejoin="round"
        />
        <path d="M17.687 13h4.307a1 1 0 01.888.54l1.101 2.127h0-8.285l1.1-2.127a1 1 0 01.889-.54zM40.745 19.463c-3.157 0-3.157-2.463-5.1-2.463-1.943 0-2.43 2.463-4.392 2.463-1.962 0-1.551-2.463-4.508-2.463M0 19.463C3.157 19.463 2.974 17 5.1 17c2.126 0 2.562 2.463 4.392 2.463C11.322 19.463 11.043 17 14 17" />
        <path
          d="M14.317 27.667h11.047M19.84 22.333V25M18.805 21.667h2.071M18.805 25.667h2.071"
          strokeLinecap="square"
        />
      </g>
      <circle stroke="#95A6B6" cx="20.5" cy="20.5" r="20.5" />
      <path
        d="M39.602 27C36.62 35.195 29.192 41 20.5 41S4.38 35.195 1.398 27z"
        fill="#92A6B8"
      />
    </g>
  </svg>
)

export default ConstraintUkcIcon
