import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as routerActions } from 'redux-router5'
import { useRoute } from 'react-router5'
import { Box, Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'
import idx from 'idx'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import DrawingMenu from 'src/components/organisms/DrawingMenu'
import DrawingCanvas from 'src/components/organisms/DrawingCanvas'
import Modal from 'src/components/molecules/Modal'
import {
  canvasReset,
  addStackAction,
  setUnsavedChanges,
} from 'src/store/canvas/actions'
import {
  updateDrawingRequest,
  createDrawingRequest,
  loadDrawings,
} from 'src/store/drawings/actions'
import { drawingSelector } from 'src/store/drawings/selectors'
import {
  createShapesSelector,
  createStageCanvasSelector,
} from 'src/store/canvas/selectors'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { PILOTAGE_PLAN_STAGE } from 'src/router/routes'
import PassagePlanNavigation from 'src/components/molecules/PassagePlanNavigation'
import CircleButton from 'src/components/atoms/CircleButton/CircleButton'
import { routeSelector } from 'src/store/route/selectors'
import { PILOTAGE_STAGE_ID } from 'src/utils/constants'
import {
  canDisplayVesselToScale,
  getVesselRotation,
} from 'src/utils/drawingHelpers'
import { createChartSelector } from 'src/store/charts/selectors'
import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
} from 'src/store/pilotage/selectors'
import { CHART_STACK_ACTION, DRAWING_VERSION } from 'src/utils/drawingConstants'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import {
  setActiveColor,
  setActiveSymbol,
  setActiveTool,
  startSelectMode,
  toggleChartMenu,
} from 'src/store/canvasMenu/actions'
import { canvasMenuSelector } from 'src/store/canvasMenu/selectors'
import { vesselSelector } from 'src/store/vessel/selectors'
import getChartScale from 'src/utils/getChartScale'
import withPilotageLoader from 'src/containers/withPilotageLoader'
import HorizontalScroll from 'src/components/atoms/HorizontalScroll/HorizontalScroll'
import { featureFlagUseChartToolsGrid } from 'src/store/preferences/selectors'

const PilotagePlanContainer = styled.div`
  display: flex;
  height: 100%;
`

const MainContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`

const SidebarContainer = styled.div`
  flex: 0 0 ${({ width }) => width}px;
`

const NavBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : 100)}%);
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: rgba(27, 27, 27, 0.3);
  transition: transform 200ms;
  z-index: 20;
`

const ToggleNavBtnWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(-50%)' : 'translateY(-150%)'};
  transition: transform 200ms;

  & svg {
    transform: ${({ isVisible }) =>
    isVisible ? 'rotate(0deg)' : 'rotate(180deg)'};
    transition: transform 400ms;
  }
`

const NotToScale = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px;
    text-transform: uppercase;
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.status.incomplete};
    font-weight: bold;
    text-align: center;
  `
)

const PilotagePlanStage = () => {
  const { route } = useRoute()
  const { id: pilotageId, stageId: _stageId } = route.params
  const chartToolsGridEnabled = useSelector(featureFlagUseChartToolsGrid)

  // This is to get around an issue where stageId can be undefined in the route params
  // during a route exit transition, causing the UI to incorrectly change while the
  // component is transitioning out of the DOM. We get around this with local state
  // and an effect to ignore undefined values.
  const [stageId, setStageId] = useState(_stageId)
  useEffect(
    () => {
      if (_stageId) {
        setStageId(_stageId)
      }
    },
    [_stageId]
  )

  const isOnline = useOnlineStatus()
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const { data: vessel } = useSelector(vesselSelector)
  const canvasState = useSelector(createStageCanvasSelector(stageId))
  const canvasMenuState = useSelector(canvasMenuSelector)
  const pilotageRoute = useSelector(routeSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const dispatch = useDispatch()

  const stage = pilotageRoute
    ? pilotageRoute.stages.find(stage => stage.uuid === stageId)
    : null

  const drawingData = useSelector(drawingSelector(stageId))
  const canvasShapes = useSelector(createShapesSelector(stageId))
  const chartSelector = createChartSelector(idx(stage, s => s.chart.uuid))
  const chart = useSelector(chartSelector)

  const isBerthingStage =
    stageId === PILOTAGE_STAGE_ID.DEPARTURE_BERTHING ||
    stageId === PILOTAGE_STAGE_ID.ARRIVAL_BERTHING

  const chartScale = chart ? getChartScale(chart.data) : null
  const isVesselToScale = canDisplayVesselToScale(chartScale, vessel)

  useEffect(
    () => {
      if (pilotageId) {
        dispatch(loadDrawings(pilotageId))
      }
    },
    [pilotageId]
  )

  const [isConfirmExitModalOpen, setIsConfirmModalExitOpen] = useState(false)
  const [confirmModalReason, setConfirmModalReason] = useState(null)
  const [navigationVisible, setNavigationVisible] = useState(true)
  const nextStageIdRef = useRef(null)

  const toggleNav = () => setNavigationVisible(isVisible => !isVisible)

  const onClear = () => {
    dispatch(addStackAction(stageId, CHART_STACK_ACTION.CLEAR_ALL))
  }

  const onToolChange = tool => {
    dispatch(setActiveTool(tool))
  }

  const onSymbolChange = symbol => {
    dispatch(setActiveSymbol(symbol))
  }

  const onColorChange = color => {
    dispatch(setActiveColor(color))
  }

  const onSelectPress = () => {
    dispatch(startSelectMode())
  }

  const onSave = async shapes => {
    const metadata = {
      shapes,
      // store the drawing version in case in future we make updates to the drawing tool
      // and need to retain backwards compatibility
      version: idx(drawingData, _ => _.metadata.version) || DRAWING_VERSION,
    }
    if (isBerthingStage) {
      metadata.vesselRotation = getVesselRotation(
        canvasState.actionsStack,
        canvasState.actionsStackPosition,
        idx(drawingData, d => d.metadata.vesselRotation) || 0
      )
    }
    let isDrawingSaved
    if (drawingData) {
      isDrawingSaved = await dispatch(
        updateDrawingRequest(drawingData.uuid, metadata)
      )
    } else {
      isDrawingSaved = await dispatch(
        createDrawingRequest(pilotageId, stageId, metadata)
      )
    }

    if (isDrawingSaved) {
      dispatch(setUnsavedChanges(stageId, false))
    }

    return isDrawingSaved
  }

  const goBack = () => {
    dispatch(canvasReset(stageId))
    window.history.back()
  }

  const onBack = () => {
    if (canvasState.hasUnsavedChanges) {
      setConfirmModalReason('exit')
      setIsConfirmModalExitOpen(true)
    } else {
      goBack()
    }
  }

  const onStageChange = nextStageId => {
    if (nextStageId !== stageId) {
      if (canvasState.hasUnsavedChanges) {
        nextStageIdRef.current = nextStageId
        setConfirmModalReason('stageChange')
        setIsConfirmModalExitOpen(true)
      } else {
        goToStage(nextStageId, stageId)
      }
    }
  }

  const goToStage = (stageId, prevStageId) => {
    dispatch(canvasReset(stageId))
    dispatch(startSelectMode())
    dispatch(
      routerActions.navigateTo(
        PILOTAGE_PLAN_STAGE,
        {
          id: route.params.id,
          stageId,
        },
        {
          replace: !!prevStageId,
        }
      )
    )
  }

  const onModalConfirm = async saveChangesFirst => {
    let willClose
    if (saveChangesFirst) {
      willClose = await onSave(canvasShapes)
    } else {
      willClose = true
    }
    if (willClose) {
      setIsConfirmModalExitOpen(false)
      if (confirmModalReason === 'stageChange') {
        goToStage(nextStageIdRef.current, stageId)
      } else {
        goBack()
      }
    }
  }

  const onToggleMenu = menu => {
    dispatch(
      toggleChartMenu(canvasMenuState.expandedMenu === menu ? null : menu)
    )
  }

  return (
    <>
      <PilotagePlanContainer>
        <MainContainer>
          {!isVesselToScale && !isBerthingStage && (
            <NotToScale>
              Vessel on chart may not be to the right scale
            </NotToScale>
          )}
          <DrawingCanvas
            stageId={stageId}
            state={canvasState}
            menuState={canvasMenuState}
            data={drawingData}
            chart={chart}
            chartScale={chartScale}
            isBerthingStage={isBerthingStage}
            onBack={onBack}
            onSave={onSave}
            readOnly={
              !isAssigned ||
              isPilotageDone ||
              isPilotageArchived ||
              isPilotageCancelled
            }
            vessel={vessel}
          />
          <NavBar
            isVisible={navigationVisible}
            readOnly={
              !isAssigned ||
              !isOnline ||
              isPilotageDone ||
              isPilotageArchived ||
              isPilotageCancelled
            }
          >
            <ToggleNavBtnWrapper isVisible={navigationVisible}>
              <CircleButton
                size="small"
                dark
                primaryHighlight
                onClick={toggleNav}
              >
                <KeyboardArrowDownIcon />
              </CircleButton>
            </ToggleNavBtnWrapper>

            <HorizontalScroll>
              <PassagePlanNavigation
                onChange={onStageChange}
              />
            </HorizontalScroll>

          </NavBar>
        </MainContainer>
        {isAssigned &&
          !isPilotageDone &&
          !isPilotageArchived &&
          !isPilotageCancelled && (
          <SidebarContainer
            width={chartToolsGridEnabled ? '180' : '105'}
          >
            <DrawingMenu
              {...canvasMenuState}
              isVesselToScale={isVesselToScale}
              chartScale={chartScale}
              onColorChange={onColorChange}
              onToolChange={onToolChange}
              onSymbolChange={onSymbolChange}
              onClear={onClear}
              onSelectPress={onSelectPress}
              onToggleMenu={onToggleMenu}
            />
          </SidebarContainer>
        )}
      </PilotagePlanContainer>
      <Modal
        autoWidth
        open={isConfirmExitModalOpen}
        onClose={() => setIsConfirmModalExitOpen(false)}
      >
        <div>
          Are you sure you want to proceed? Any unsaved changes will be lost.
        </div>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SecondaryButton
                fullWidth
                onClick={() => setIsConfirmModalExitOpen(false)}
              >
                Cancel
              </SecondaryButton>
            </Grid>
            <Grid item xs={3}>
              <SecondaryButton fullWidth onClick={() => onModalConfirm(false)}>
                Continue
              </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton fullWidth onClick={() => onModalConfirm(true)}>
                Save & continue
              </PrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default withPilotageLoader(PilotagePlanStage)
