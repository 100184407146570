import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { missingValueText } from 'src/utils/constants'

export const ItemLabel = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    margin-right: 8px;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 12px;
  `
)

export const ItemValue = styled.div(
  ({ theme, variant }) => css`
    color: ${theme.palette.text.primary};
    font-size: ${variant === 'small' ? '12px' : theme.typography.pxToRem(16)};
    line-height: ${variant === 'small' ? '12px' : 'inherit'};
    margin-right: 8px;
    margin-bottom: 8px;
  `
)

export const InfoBar = ({ items, variant, ...props }) => (
  <Box display="flex" {...props}>
    {items.map(({ label, value, secondaryValue, flex }) => (
      <Box key={label} px={1} flex={`${flex} ${flex}`} style={{boxSizing: 'border-box', padding: 0}}>
        <ItemLabel variant={variant}>{label}</ItemLabel>
        <ItemValue variant={variant}>
          {value}
          {secondaryValue !== missingValueText && (
            <div>{secondaryValue}</div>
          )}
        </ItemValue>
      </Box>
    ))}
  </Box>
)

InfoBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      width: PropTypes.number,
    })
  ),
  variant: PropTypes.string,
}

const PilotageInfoBar = ({
  timeStr,
  dateStr,
  vessel,
  vesselVisitCode,
  vesselFwdDraft,
  vesselAftDraft,
  outerEnd,
  innerEnd,
  bridgeMark,
  movementType,
  from,
  to,
  pilot,
  secondaryPilot,
  className,
  variant,
  pilotageMode
}) => {

  const hasExtraFields =
    vesselVisitCode ||
    outerEnd ||
    innerEnd ||
    bridgeMark

  const firstRow = [
    { label: 'Time', value: timeStr, flex: 1 },
    { label: 'Vessel', value: vessel, flex: 2 },
    { label: 'MVT', value: movementType, flex: 1 },
    { label: 'From', value: from, flex: 1 },
    { label: 'To', value: to, flex: 1 },

    hasExtraFields &&
    {
      label: 'Visit No.',
      value: vesselVisitCode || '--',
      flex: 1
    },
    hasExtraFields &&
    {
      label: 'Pilotage mode',
      value: pilotageMode || '--',
      flex: 1
    }
  ].filter(v => v)

  const pilotNames = [pilot, secondaryPilot].filter(name => name && name !== '--')

  const secondRow = [
    { label: 'Date', value: dateStr, flex: 1 },
    {
      label: `Pilot${pilotNames.length > 0 ? 's' : ''}`,
      value: pilotNames.join(' & '),
      flex: 2,
    },
    {
      label: 'Fwd. Draft',
      value: typeof vesselFwdDraft === 'number' ? `${Number(vesselFwdDraft).toFixed(2)}m` : '--',
      flex: 1
    },
    {
      label: 'Aft Draft',
      value: typeof vesselAftDraft === 'number' ? `${Number(vesselAftDraft).toFixed(2)}m` : '--',
      flex: 1
    },
    hasExtraFields &&
    {
      label: 'Outer End',
      value: typeof outerEnd === 'number' ? `${Number(outerEnd).toFixed(2)}m` : '--',
      flex: 1
    },
    hasExtraFields &&
    {
      label: 'Inner End',
      value: typeof innerEnd === 'number' ? `${Number(innerEnd).toFixed(2)}m` : '--',
      flex: 1
    },
    hasExtraFields &&
    {
      label: 'Bridge Mark',
      value: typeof bridgeMark === 'number' ? `${Number(bridgeMark).toFixed(2)}m` : '--',
      flex: 1
    },
    !hasExtraFields &&
    {
      label: 'Pilotage mode',
      value: pilotageMode || '--',
      flex: 1
    }
  ].filter(v => v)

  return <>
    <InfoBar items={firstRow} className={className} variant={variant} />
    <InfoBar items={secondRow} className={className} variant={variant} />
  </>
}

PilotageInfoBar.propTypes = {
  className: PropTypes.string,
  timeStr: PropTypes.string,
  dateStr: PropTypes.string,
  vessel: PropTypes.string,
  movementType: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  pilot: PropTypes.string,
  secondaryPilot: PropTypes.string,
  variant: PropTypes.string,
}

export default PilotageInfoBar
