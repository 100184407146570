import { convertToTimeZone } from 'date-fns-timezone'

export const findNearestWeatherForecast = (
  dateTime,
  timeZone,
  weather,
  isWeatherLoading
) => {
  let nearestWeatherForecast = null

  if (!isWeatherLoading && weather && weather.data && weather.data.length > 0) {
    // Reverse the time diffs so that if 2 forecast datasets are equal time distance from the pilotage,
    // the later one is chosen over the earlier one.
    const pilotageDate = convertToTimeZone(dateTime, { timeZone })
    const timeDistance = weather.data
      .map(item => {
        // Don't convert item.dateTime because it's already assumed to be in the port local timezone (not stored in UTC)
        const dateTime = item.dateTime.replace(/-/g, '/')
        return Math.abs(new Date(dateTime).getTime() - pilotageDate.getTime())
      })
      .reverse()
    const minTimeDistance = Math.min(...timeDistance)

    // Only use the forecast if it's within 3 hours of the pilotage start time
    if (minTimeDistance <= 3 * 60 * 60000) {
      const reversedIndex = timeDistance.indexOf(minTimeDistance)
      const index = weather.data.length - 1 - reversedIndex
      nearestWeatherForecast = weather.data[index]
    }
  }

  // Removes the spaces from the apiType returned to us by the back end for both StormGeo and MetOcean
  const weatherSourceName =
    weather && weather.source
      ? weather.source !== 'World Weather Online'
        ? weather.source.replace(/\s/g, '')
        : weather.source
      : null

  return {
    nearestWeatherForecast,
    weatherSourceName,
  }
}
