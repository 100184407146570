import { SystemModel, UkcPlanModel, PassagePlanModelDirectionEnum } from 'dukcapi'
import { MovementType } from 'src/types/Pilotage'
import api from 'src/utils/api/core'
import quantizeNumber from 'src/utils/quantize'
import { addDays, differenceInSeconds, isWithinRange } from 'date-fns'

export async function getSystem(portUuid: string): Promise<SystemModel | undefined> {

  try {
    const res = await api.get<{ data: SystemModel[] }>(`/omc/system`, { params: { portUuid } })
    return res && res.data && res.data.data && res.data.data[0]

  } catch (error) {
    console.error(error)
  }
}

export interface GetPlanQuery {
  vesselIMO: number
  pilotageDate: string
  movementType: MovementType
  portUuid: string
}

export interface GetPlansQuery {
  vesselIMO: number
  fromTime: string
  toTime: string
  movementType: MovementType
  portUuid: string
}

export async function getPlans(query: GetPlansQuery): Promise<UkcPlanModel[]> {

  // for now assumes that first plan is what is used - use real selection logic when ready
  try {
    const res = await api.get<{ data: UkcPlanModel[] }>(`/omc/plan`, { params: query })
    return res && res.data && res.data.data

  } catch (error) {
    return Promise.reject(error)
  }
}

export function downloadAsPdf(base64: string, filename: string) {

  const array = Uint8Array.from(atob(base64), c => c.charCodeAt(0))

  const url = window.URL.createObjectURL(
    new Blob([array], {
      type: 'application/pdf',
    })
  )

  open(url, filename, 'menubar=no,location=yes,resizable=yes,scrollbars=no,status=yes')
}

// for debugging
(window as any).omcGetVesselIMOs = async function () {

    const res = await api.get<{ data: UkcPlanModel[] }>(`/omc/plan`, {})
    const imos = res && res.data && res.data.data.map(plan => ({
      IMO: plan.calculationInputs && plan.calculationInputs.vesselId,
      date: plan.passagePlan && plan.passagePlan.timeStamp && new Date(plan.passagePlan.timeStamp)
    }))

    console.log(imos)
}


//  Use 4 x 6-hour time buckets in the day

export interface TimeBucket { fromTime: string, toTime: string }

export const DAY = 1000 * 60 * 60 * 24
export const SEVEN_DAYS = 7 * DAY
export const QUANTUM = DAY / 4

export function getBoundingTimeBuckets(pilotageDate: string): TimeBucket[] {

  const time = new Date(pilotageDate).valueOf()

  return [-QUANTUM, 0, QUANTUM].map(offset => getTimeBucket(time + offset))
}

export function getTimeBucket(time: number): TimeBucket {
  const exactnessBreaker = 1 // epsilon to handle when time falls exactly on bucket border
  const second = 1000
  return {
    fromTime: new Date(quantizeNumber(time - exactnessBreaker, QUANTUM, false)).toISOString(),
    toTime: new Date(quantizeNumber(time - exactnessBreaker, QUANTUM, true) - second).toISOString()
    // one second less, so toTime doesn't overlap with following fromTime
  }
}

interface UkcPlanWithTimeStamp extends UkcPlanModel {
  passagePlan: {
      timeStamp: string
      direction: PassagePlanModelDirectionEnum
  }
}

/*
@note: this is also used on the backend, in OmcService. Keep logic in sync
*/
export function heuristicallySortPlans(
    plans: UkcPlanModel[],
    pilotageDate: string
): UkcPlanModel[] {

    if (plans.length === 0) { return [] }

    const now = new Date().toISOString()
    const start = new Date(pilotageDate)

    // Plans need a timeStamp, and they must be within 6 hours of pilotage start time
    const validPlans = plans.filter(
        plan =>
            plan.passagePlan && plan.passagePlan.timeStamp &&
            isWithinRange(
                new Date(plan.passagePlan.timeStamp),
                addDays(start, -7),
                addDays(start, 7)
            )
    ) as UkcPlanWithTimeStamp[]

    const byProximityTo = (a: UkcPlanWithTimeStamp, b: UkcPlanWithTimeStamp, targetTime: string) => {
        const A = Math.abs(differenceInSeconds(a.passagePlan.timeStamp, targetTime))
        const B = Math.abs(differenceInSeconds(b.passagePlan.timeStamp, targetTime))
        return A - B
    }

    const getNumericStatus = (a: UkcPlanWithTimeStamp) => {
      if (a.status === 'FINALISED') {
        return 1
      } else if (a.status === 'UNDERWAY') {
        return 2
      } else if (a.status === 'APPROVED') {
        return 3
      } else if (a.status === 'PRELIMINARY') {
        return 4
      }
      return 5
    }

    // APPROVED beats PRELIMINARY
    const byStatus = (a: UkcPlanWithTimeStamp, b: UkcPlanWithTimeStamp) => {
      return getNumericStatus(a) - getNumericStatus(b)
    }

    // Known movement type is preferred
    const byMovementType = (a: UkcPlanWithTimeStamp, b: UkcPlanWithTimeStamp) => {
      let aKnown = a.passagePlan.direction && a.passagePlan.direction !== PassagePlanModelDirectionEnum.UNKNOWN
      let bKnown = b.passagePlan.direction && b.passagePlan.direction !== PassagePlanModelDirectionEnum.UNKNOWN
      if (aKnown && !bKnown) {
          return -1
      }
      if (!aKnown && bKnown) {
          return 1
      }
      return 0
    }

    // Sort plans by progressive rules and pick first
    // (may need to change application order)
    return validPlans.sort((a, b) =>
        byMovementType(a, b) ||
        byStatus(a, b) ||
        byProximityTo(a, b, pilotageDate) || // closer to pilotage start beats further from pilotage start
        byProximityTo(a, b, now) || // closer to now beats further from now
        byStatus(a, b)
    )
}

/*
  The time bucket is so that the pilots can change the pilotage start time within a day
  and still see a plan result when offline.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getPlanBucketKey({ vesselIMO, fromTime, toTime, movementType, portUuid }: GetPlansQuery) {
  return `${vesselIMO}/${fromTime}/${toTime}/${movementType}`
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getPlanKey({ vesselIMO, pilotageDate, movementType, portUuid }: GetPlanQuery) {
  return `${vesselIMO}/${pilotageDate}/${movementType}`
}
