const TOOLTIPS = {
  PILOTAGE_MODE_DIRECT: 'To have the conduct of the ship in a direct mode means that the pilot is directing the navigation and movement of the ship while inside a compulsory pilotage area. ',
  PILOTAGE_MODE_INDIRECT: 'To have the conduct of the ship in an indirect mode means that the pilot is issuing guidance or a recommendation to the Master who is directing the navigation and movement of the ship.',
  VESSEL_TRACK_CONTROL: 'An Autopilot mode that combines an ECDIS route and the autopilot. The autopilot system receives its steering orders from a planned ECDIS route and transmits steering commands to the steering system.',
  VESSEL_PROP_EFFECT: 'Tendency of the propeller to steer the vessel in a direction.',
  VESSEL_EMAILS: 'Use commas to separate multiple emails.',
  CONSTRAINT_TIME: 'This is the default duration (e.g. 15 minutes) set by port admin to calculate the time (12:45) and tide at the constraint. Please note that if you override duration, then all the following constraints after this will be automatically adjusted.',
  CONSTRAINT_ALLOWANCES: 'Total of additional allowances (e.g. squat, pitch, rolling, heave, etc.) if any.',
  ACTUAL_OHC: 'Overhead clearance minus Air draft minus Predicted tide.',
  REMAINING_OHC: 'Available clearance minus Required clearance.',
  SWELL_ALLOWANCE: 'Swell, pitch, roll, heave allowances. (+)',
  MAX_DRAFT: 'Max draft is derived from the highest value out of the Fwd or Aft draft but can be overridden for ship sagging scenarios where Mid draft is higher.',
  DISPLACEMENT: 'Ships Total Displacement.',
}
export default TOOLTIPS
