import { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadAsPdf, GetPlanQuery } from 'src/utils/api/omc'
import { omcLoadDukcPlan } from 'src/store/omc/actions'
import { omcPlanSelector } from 'src/store/omc/selectors'
import { omcChosenPreliminaryPlanIdSelector } from 'src/store/pilotage/selectors'
import { addErrorToast } from './../store/toast/actions';
import TOAST_MESSAGES from './../utils/toastMessages';

const PRELIMINARY = 'PRELIMINARY'

export default function useOmcDukc() {

    const [error, setError] = useState<Error | null>(null)
    const [planQuery, setPlanQuery] = useState<GetPlanQuery | null>(null)
    const plansResult = useSelector(omcPlanSelector(planQuery))
    const planVariantActive = useSelector(omcChosenPreliminaryPlanIdSelector)
    const dispatch = useDispatch()

    // If there's more than 1 preliminary plan in the result,
    // the user will need to choose which one should be shown
    const planVariants = useMemo(() =>
        plansResult &&
        plansResult.plans[0] &&
        plansResult.plans[0].status === PRELIMINARY &&
        plansResult.plans.filter(p => p.status === PRELIMINARY) || // @todo check if we need to match on plan.passagePlan.timestamp
        [],
        [plansResult]
    )

    const fetch = async (query: GetPlanQuery) => {

        setPlanQuery(query)

        const { vesselIMO, pilotageDate, movementType, portUuid } = query

        setError(null)

        try {
            await dispatch(omcLoadDukcPlan(vesselIMO, pilotageDate, movementType, portUuid))
            setError(null)

        } catch (error) {
            setError(error)
            dispatch(
                addErrorToast({
                  message: TOAST_MESSAGES.LOAD_OMC_ERROR,
                })
              )
        }
    }


    const getPlan = () => {

        const firstPlan = plansResult && plansResult.plans[0]
        if (!firstPlan) { return }

        if (firstPlan.status !== PRELIMINARY || planVariants.length === 1) {
            return firstPlan
        }

        return planVariantActive &&
            planVariants.find(({ id }) => id === planVariantActive) ||
            planVariants[0]
    }

    const getLoading = () => {
        return plansResult && plansResult.loading
    }

    const getPdf = () => {
        const plan = getPlan()
        if (!plan) { return }

        const { report, status, id } = plan
        if (report && status && id) {
            downloadAsPdf(report, `omc_dukc_report_${status}_${id}`)
        }
    }



    return {
        setPlan: setPlanQuery,
        getPlan,
        getLoading,
        planVariants,
        planVariantActive,
        // planVariantActivate, // update choice on pilotage
        getPdf,
        fetch,
        error
    }
}
