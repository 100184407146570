import idx from 'idx'

import {
  PILOTAGE_EXTRAS_IN_PROGRESS,
  PILOTAGE_EXTRAS_ERROR,
  PILOTAGE_EXTRAS_SUCCESS,
  PILOTAGE_CREATE_EXTRA_IN_PROGRESS,
  PILOTAGE_CREATE_EXTRA_SUCCESS,
  PILOTAGE_CREATE_EXTRA_ERROR,
  PILOTAGE_UPDATE_EXTRA_IN_PROGRESS,
  PILOTAGE_UPDATE_EXTRA_SUCCESS,
  PILOTAGE_UPDATE_EXTRA_ERROR,
} from 'src/store/pilotageExtras/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import { SYNC_ENTITY_SUCCESS } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function pilotageExtrasReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case PILOTAGE_EXTRAS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PILOTAGE_CREATE_EXTRA_IN_PROGRESS:
    case PILOTAGE_UPDATE_EXTRA_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case PILOTAGE_EXTRAS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PILOTAGE_EXTRAS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    case PILOTAGE_CREATE_EXTRA_SUCCESS:
      return {
        ...state,
        data: [...state.data, payload],
        isLoading: false,
        error: null,
      }
    case PILOTAGE_UPDATE_EXTRA_SUCCESS:
      return {
        ...state,
        data: state.data.map(item =>
          // TODO: remove the merge workaround when the API fixed so the PUT request response isn't missing the uuid
          item.extraType === payload.extraType ? { ...item, ...payload } : item
        ),
        isLoading: false,
        error: null,
      }

    case PILOTAGE_CREATE_EXTRA_ERROR:
    case PILOTAGE_UPDATE_EXTRA_ERROR:
      return {
        ...state,
        isLoading: true,
      }
    case SYNC_ENTITY_SUCCESS: {
      const {
        type: syncType,
        entity: { pilotageUuid },
      } = payload
      const { result } = action
      // theoretically we can have extras only with the same pilotage uuid
      // but for safety we collect them all, so we can check if we have to
      // update the state
      // however right now we have only one here which is the check list
      const pilotageUuids = (state.data || []).map(extra =>
        idx(extra, _ => _.pilotage.uuid)
      )
      return syncType !== SYNC_ENTITY_TYPES.PILOTAGE_EXTRAS ||
        !pilotageUuids.includes(pilotageUuid)
        ? state
        : {
            ...state,
            data: result,
          }
    }
    default:
      return state
  }
}

export default pilotageExtrasReducer
