import { addDays, addMinutes, subMinutes } from 'date-fns'
import { formatToTimeZone } from 'date-fns-timezone'
import { TIME_FORMAT, TIME_FORMAT_OMC } from 'src/utils/constants'
import { DateTime } from 'luxon'

// date params are passed around as calendar ISO strings, e.g: 2020-12-30

export const showDate = (date: Date | null | undefined) =>
  date ? toCalendarDateString(date) : undefined

export const defaultDateRange = () => {
  const now = new Date()
  return {
    startDate: showDate(now),
    endDate: showDate(addDays(now, 7))
  }
}

export const formatCalendarDate = (dateString?: string) => {
  if (!dateString) {
    return
  }
  const [y, m, d] = dateString.split('-')
  return `${d}/${m}/${y}`
}

export const calendarDateAtTimeZone = (
  dateString: string, // e.g: 2020-12-30
  timeZone: string,
  hours = 0, // at timeZone
  minutes = 0,
  seconds = 0,
  milliseconds = 0
) => {
  const pad = (n: number, length: number) => {
    let str = String(n)
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }
  const dateLuxon = DateTime.fromFormat(`${dateString} ${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(milliseconds, 3)}`,
    'yyyy-MM-dd HH:mm:ss.SSS', { zone: timeZone })
  return new Date(dateLuxon.valueOf())
}

// required for safari support
export const padCalendarDateString = (calendarDate?: string) => {
  if (!calendarDate) { return }
  const [yyyy, mm, dd] = calendarDate.split('-')
  return `${yyyy}-${mm.padStart(2, '0')}-${dd.padStart(2, '0')}`
}

export const toCalendarDateString = (date: Date) =>
  padCalendarDateString(
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  )

// workaround for DST, see https://github.com/date-fns/date-fns/issues/571
export function agnosticAddDays(date: Date | number | string, amount: number) {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }
  const originalTZO = date.getTimezoneOffset()
  const endDate = addDays(date, amount)
  const endTZO = endDate.getTimezoneOffset()

  const dstDiff = originalTZO - endTZO

  return dstDiff >= 0
    ? addMinutes(endDate, dstDiff)
    : subMinutes(endDate, Math.abs(dstDiff))
}

export const friendlyTime = (label: string, time: string | number, timeZone: string, format?: string) =>
  `${label}: ${formatToTimeZone(time, 'D MMM', { timeZone })
  } @ ${formatToTimeZone(time, format || TIME_FORMAT, { timeZone })
  }`

export const labelTime = (time: Date | string | number | undefined, timeZone: string) =>
  time ? formatToTimeZone(time, TIME_FORMAT_OMC, { timeZone }) : '-'
