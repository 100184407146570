import React from 'react'
import PropTypes from 'prop-types'

const CrossIcon = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 28 28">
    <g transform="translate(-3 -3)">
      <circle fill="#ED1C24" cx="17" cy="17" r="14" />
      <path
        fill="#FFF"
        d="M21.243 11.343L17 15.586l-4.243-4.243-1.414 1.414L15.586 17l-4.243 4.243 1.414 1.414L17 18.414l4.243 4.243 1.414-1.414L18.414 17l4.243-4.243z"
      />
    </g>
  </svg>
)

CrossIcon.propTypes = {
  size: PropTypes.number,
}

CrossIcon.defaultProps = {
  size: 28,
}

export default CrossIcon
