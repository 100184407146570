import { addSignature, getSignatures } from 'src/utils/api/pilotages'
import { addErrorToast } from '../toast/actions'
import { PilotageSignature } from './types'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { addEntity } from './../sync/actions'
import { createSyncUuid } from 'src/utils/sync/pilotageSignatures'
import { v4 as uuidv4 } from 'uuid'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants';
export interface PilotageSignaturesSetAction {
  type: 'PILOTAGE_SIGNATURES_SET'
  payload: {
    pilotageUuid: string
    signatures: PilotageSignature[]
  }
}

export interface PilotagesSignaturesLoadingAction {
  type: 'PILOTAGE_SIGNATURES_LOADING',
  payload: {
    pilotageUuid: string
    loading: boolean
  }
}

export type PilotageSignatureAction
  = PilotageSignaturesSetAction
  | PilotagesSignaturesLoadingAction

type Dispatch = (action: PilotageSignatureAction) => void

export const pilotageSignaturesRequest = (
  pilotageUuid: string,
  { isPrefetch }: { isPrefetch?: true } = {}
) => async (dispatch: Dispatch) => {
  try {
    if (!isPrefetch) {
      dispatch({ type: 'PILOTAGE_SIGNATURES_LOADING', payload: { pilotageUuid, loading: true } })
    }

    const res = await getSignatures(pilotageUuid)
    const signatures = res.data && res.data.data

    if (!isPrefetch) {
      dispatch({
        type: 'PILOTAGE_SIGNATURES_SET',
        payload: {
          pilotageUuid,
          signatures: signatures || []
        }
      })
    }
    return signatures

  } catch (err) {
    if (!isPrefetch) {
      addErrorToast({ message: 'Failed to get pilotage signatures' })(dispatch)
    }
  }
}

export const pilotageSignatureCreate = (
  pilotageUuid: string,
  signature: Partial<PilotageSignature>
) => async (dispatch: any, getState: any) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)
  try {
    if (isOnline) {
      await addSignature(pilotageUuid, signature)
      await pilotageSignaturesRequest(pilotageUuid)(dispatch)
    } else {
      const uuid = uuidv4()
      const syncEntityUuid = createSyncUuid(pilotageUuid, signature.role)
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_SIGNATURES,
          pilotageUuid: pilotageUuid,
          isNew: true,
          entity: {
            uuid,
            signature: signature,
            pilotage: { uuid: pilotageUuid },
          },
        })
      )
    }
    return true
  } catch (err) {
    addErrorToast({ message: 'Failed to add pilotage signature' })(dispatch)
    return false
  }
}
