import styled from 'styled-components'

// Common components used by the Vessel diagrams

export const DiagramLabel = styled.text`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  fill: ${({ theme }) => theme.palette.text.secondary};
`

export const LightRect = styled.rect`
  fill: ${({ theme }) => theme.palette.background.light};
`

export const Measurement = styled.text`
  fill: ${({ theme }) => theme.palette.text.primary};
  font-size: 30px;
`

export const VesselDiagramContainer = styled.div`
  position: relative;
  width: 100%;
  & svg {
    display: block;
  }
`

export const MeasurementLines = styled.g`
  shape-rendering: crispEdges;
  stroke: ${({ theme }) => theme.palette.primary.main};
`

export const DottedLines = styled.g`
  stroke-dasharray: 2.2, 5.8;
  stroke-width: 0.73px;
`

export const markerRadius = 4

export const linesToPath = (lines) => lines.map(([sx, sy, dx, dy]) => 
  `M${sx},${sy} L${dx},${dy}`
).join(' ')