import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Button, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'

import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import ChartColorPicker from 'src/components/molecules/ChartColorPicker'
import ChartTools from 'src/components/molecules/ChartTools'
import ChartSymbolPicker from 'src/components/molecules/ChartSymbolPicker'
import Modal from 'src/components/molecules/Modal'
import ToolIcon from 'src/components/atoms/ToolIcon'
import { CHART_MENU_SECTION, CHART_TOOL } from 'src/utils/drawingConstants'
import { featureFlagUseChartToolsGrid } from 'src/store/preferences/selectors'

const MenuContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.light};
  border-left: 1px solid ${({ theme }) => theme.palette.background.light};
  z-index: 101;
  display: flex;
  flex-direction: column;
  text-align: center;
  touch-action: pan-y;
  height: 100%;
`

const TextButton = styled(Button).attrs({
  fullWidth: true,
})`
  && {
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
`

const ClearButton = styled(TextButton)`
  && {
    color: #da6060;
    padding: 16px 0;
  }
`

const ScrollSectionGrid = styled.div`
`

const ScrollSection = styled.div`
  overflow-y: auto;
  -ms-overflow-style: none;
  touch-action: pan-y;

  &::-webkit-scrollbar {
    display: none;
  }
`

const DrawingMenu = ({
  activeColor,
  activeTool,
  activeSymbol,
  expandedMenu,
  onColorChange,
  onToolChange,
  onSymbolChange,
  onClear,
  onSelectPress,
  onToggleMenu,
  isVesselToScale,
  chartScale,
}) => {
  const chartToolsGridEnabled = useSelector(featureFlagUseChartToolsGrid)
  const [isClearConfirmationOpen, setIsClearConfirmationOpen] = useState(false)

  const confirmClear = () => {
    onClear()
    setIsClearConfirmationOpen(false)
  }

  const Tools = () => {
    return (
    <>
      <ChartColorPicker
        disabled={activeTool === CHART_TOOL.HIGHLIGHTER}
        current={activeColor}
        isExpanded={expandedMenu === CHART_MENU_SECTION.COLOR || chartToolsGridEnabled}
        onSelect={onColorChange}
        onToggle={() => onToggleMenu(CHART_MENU_SECTION.COLOR)}
      />
      <ChartTools
        current={activeTool}
        isExpanded={expandedMenu === CHART_MENU_SECTION.TOOL || chartToolsGridEnabled}
        chartScale={chartScale}
        onSelect={onToolChange}
        onToggle={() => onToggleMenu(CHART_MENU_SECTION.TOOL)}
      />
      <ChartSymbolPicker
        current={activeSymbol}
        isExpanded={expandedMenu === CHART_MENU_SECTION.SYMBOL || chartToolsGridEnabled}
        onSelect={onSymbolChange}
        onToggle={() => onToggleMenu(CHART_MENU_SECTION.SYMBOL)}
        isMainVesselToScale={isVesselToScale}
      />
    </>)
  }

  return (
    <>
      <MenuContainer>
        <Box mt={2}>
          <ToolIcon
            isSelected={!(activeTool || activeSymbol)}
            onClick={onSelectPress}
          >
            <svg width="19" height="24">
              <path
                d="M18.768 12.476c-.15 6.161-2.57 11.89-9.972 10.294C4.17 21.772 2.774 16.543.34 12.327c-.782-1.355 1.315-2.513 2.035-1.175l2.28 3.952c.309.532 1.121.306 1.122-.302L5.78 2.02c0-1.659 2.345-1.763 2.345.073v6.362c0 .775 1.176.8 1.203.022l.002-7.206a1.176 1.176 0 0 1 2.346 0v8.867c0 .752 1.136.827 1.202.035l.002-6.79a1.176 1.176 0 0 1 2.345 0v6.685l.001.07v1.71c0 .755 1.15.827 1.204.019l.002-5.256a1.176 1.176 0 0 1 2.345 0v5.865h-.008z"
                fill="#FFF"
              />
            </svg>
          </ToolIcon>
        </Box>
        { !chartToolsGridEnabled && <ScrollSection><Tools></Tools></ScrollSection>}
        { chartToolsGridEnabled && <ScrollSectionGrid><Tools></Tools></ScrollSectionGrid>}
        <Box mt="auto">
          <ClearButton onClick={() => setIsClearConfirmationOpen(true)}>
            <DeleteIcon />
            Clear
          </ClearButton>
        </Box>
      </MenuContainer>
      <Modal
        autoWidth
        open={isClearConfirmationOpen}
        onClose={() => setIsClearConfirmationOpen(false)}
      >
        <div>
          Are you sure you want to remove all drawings from this chart? You can
          undo this action using the undo button.
        </div>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SecondaryButton
                fullWidth
                onClick={() => setIsClearConfirmationOpen(false)}
              >
                Cancel
              </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
              <PrimaryButton fullWidth onClick={confirmClear}>
                Clear
              </PrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

DrawingMenu.propTypes = {
  activeColor: PropTypes.string,
  activeTool: PropTypes.string,
  activeSymbol: PropTypes.string,
  expandedMenu: PropTypes.string,
  onColorChange: PropTypes.func,
  onToolChange: PropTypes.func,
  onSymbolChange: PropTypes.func,
  onClear: PropTypes.func,
  onSelectPress: PropTypes.func,
  onToggleMenu: PropTypes.func,
  isVesselToScale: PropTypes.bool,
  chartScale: PropTypes.number,
}

export default DrawingMenu
