import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getExtras } from 'src/utils/api/extras'

const EXTRAS_BASE = 'EXTRAS'

export const [
  EXTRAS_REQUEST,
  EXTRAS_SUCCESS,
  EXTRAS_ERROR,
  EXTRAS_CANCEL,
  EXTRAS_IN_PROGRESS,
] = createBasicActionTypes(EXTRAS_BASE)

export const extrasRequest = (
  portUuid,
  showErrorToast = true
) => async dispatch => {
  dispatch(extrasInProgress())

  try {
    const response = await getExtras(portUuid)
    const data = getPayloadFromResponse(response)
    dispatch(extrasLoaded(data))
    return data
  } catch (error) {
    dispatch(extrasError(error, showErrorToast))
    return false
  }
}

export const extrasInProgress = () => createInProgressAction(EXTRAS_BASE)

export const extrasError = (error, showErrorToast) => dispatch => {
  dispatch(createErrorAction(EXTRAS_BASE, error))
  if (showErrorToast) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.LOAD_EXTRAS_ERROR,
      })
    )
  }
}

export const extrasLoaded = data => createSuccessAction(EXTRAS_BASE, data)
