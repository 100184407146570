import api from 'src/utils/api/core'
import { PILOTAGE_STAGE_ID } from 'src/utils/constants'

export const getDrawings = pilotageId => {
  return api.get(`/drawings?pilotageUuid=${pilotageId}`)
}

export const createDrawing = (pilotageId, stageId, metadata) => {
  const payload = {
    pilotage: { uuid: pilotageId },
    metadata: JSON.stringify(metadata),
  }

  switch (stageId) {
    case PILOTAGE_STAGE_ID.ARRIVAL_BERTHING:
      payload.isLast = true
      break
    case PILOTAGE_STAGE_ID.DEPARTURE_BERTHING:
      payload.isFirst = true
      break
    default:
      payload.stage = {
        uuid: stageId,
      }
      break
  }

  return api.post(`/drawings`, payload)
}

export const updateDrawing = (drawingId, metadata) => {
  return api.put(`/drawings/${drawingId}`, {
    metadata: JSON.stringify(metadata),
  })
}
