import styled from 'styled-components'
import { Button } from '@material-ui/core'

const DarkButton = styled(Button)`
  && {
    padding: ${({ theme }) => theme.spacing(1, 4)};
    background-color: ${({ theme }) => theme.palette.background.light};
    &:hover {
      background-color: ${({ theme }) => theme.palette.background.xlight};
    }
    &.MuiButton-outlined {
      border-color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

export default DarkButton
