import styled from 'styled-components'

const FormFieldError = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  color: ${({ theme }) => theme.palette.error.light};
  margin-top: 2px;
  margin-bottom: -2px;
`

export default FormFieldError
