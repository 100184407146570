import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Snackbar, SnackbarContent, IconButton, Box } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import { removeToast } from 'src/store/toast/actions'

import { toastSelector } from 'src/store/toast/selectors'

const StyledSnackbar = styled(Snackbar)`
  && {
    padding: 0;
  }
`

const StyledSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(13)};
    font-weight: ${({ theme }) => theme.weights.fontWeightBold};
    max-width: 100%;
    padding: 0.5em 1em;
    .message {
      align-items: center;
      display: flex;
      width: 100%;
    }
    .icon {
      opacity: 0.7;
      margin-right: ${({ theme }) => theme.spacing(1)}px;
      color: ${({ theme }) => theme.palette.toast.main};
    }
    .error-icon {
      color: ${({ theme }) => theme.palette.toast.error};
    }
    .success-icon {
      color: ${({ theme }) => theme.palette.toast.success};
    }
  }
`

export const TOAST_VARIANT_ERROR = 'error'
export const TOAST_VARIANT_SUCCESS = 'success'
export const TOAST_VARIANT_INFO = 'info'

const Toast = () => {
  const toasts = useSelector(toastSelector)
  const dispatch = useDispatch()

  return toasts.map(({ variant, message, id }, index) => (
    <StyledSnackbar
      key={id}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ top: `${24 + (index * 70)}px` }}
      open
    >
      <StyledSnackbarContent
        aria-describedby={`client-snackbar-${id}`}
        message={
          <Box id={`client-snackbar-${id}`} display="flex" alignItems="center">
            {variant === TOAST_VARIANT_ERROR && (
              <WarningIcon className="icon error-icon" />
            )}
            {variant === TOAST_VARIANT_SUCCESS && (
              <CheckCircleIcon className="icon success-icon" />
            )}
            {variant === TOAST_VARIANT_INFO && (
              <InfoIcon className="icon info-icon" />
            )}
            {message}
          </Box>
        }
        action={ 
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(removeToast(id))}
            size="small"
          >
            <CloseIcon size="small" />
          </IconButton>
        }
      />
    </StyledSnackbar>
  ))
}

export default Toast
