import api from 'src/utils/api/core'

export function signupUser(token, password) {
  if (!token) {
    return Promise.reject(new Error('Missing token'))
  }
  if (!password) {
    return Promise.reject(new Error('Missing credentials'))
  }
  return api.post(`/users/signup/${token}`, { password })
}

export function loginUser(email, password, isAdmin) {
  if (!email || !password) {
    return Promise.reject(new Error('Missing credentials'))
  }
  return api.post(`/users/login`, {
    isAdmin,
    password,
    contact: {
      contactType: 'email',
      value: email.toLowerCase(),
    },
  })
}

export const loginUserSso = (token, redirectUrl) => {
  return api.post(`/sso/loginsso`, {
    redirectUrl,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export const refreshSession = refreshToken => {
  if (!refreshToken) {
    return Promise.reject(new Error("Parameter 'refreshToken' is required"))
  }
  return api.post(`/users/refresh`, { refreshToken })
}

export async function logoutUser(config) {
  return api.post(`/users/logout`, null, config)
}

export async function requestNewPassword(payload, config) {
  try {
    if (!payload) {
      throw new Error("The payload with parameter 'email' is required")
    }
    if (!payload.email) {
      throw new Error("Parameter 'email' is required")
    }
    return api.post(`/users/password`, payload, config)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function resetPassword(data, config) {
  try {
    if (!data) {
      throw new Error(
        'Reset password function needs token and the new password'
      )
    }
    if (!data.token) {
      throw new Error('Reset password function needs token')
    }
    if (!data.passwordHash) {
      throw new Error('Reset password function needs the new password')
    }
    return api.put(`/users/password/reset`, data, config)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updatePassword(data, config) {
  try {
    if (!data) {
      return Promise.reject(
        new Error("Parameters 'password' and 'newPassword' are required")
      )
    }
    if (!data.password) {
      return Promise.reject(new Error("Parameter 'password' is required"))
    }
    if (!data.newPassword) {
      return Promise.reject(new Error("Parameter 'newPassword' is required"))
    }
    return api.put(`/users/password`, data, config)
  } catch (error) {
    return Promise.reject(error)
  }
}
