import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const StyledPath = styled.path(
  ({ theme }) => css`
    stroke: none;
    fill: ${theme.palette.text.secondary};
  `
)

const DirectionArrow = ({ color, rotation, scale, translateY }) => (
  <svg
    width="14"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    style={{ 
      transform: `${translateY ? `translateY(${translateY}px)` : ''} rotate(${rotation}deg) ${scale ? `scale(${scale})` : ''}` }}
  >
    <StyledPath d="M7,16 L0,0 L7,6 L14,0Z" style={color ? { fill: color } : undefined} />
  </svg>
)

DirectionArrow.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number.isRequired,
  scale: PropTypes.number,
  translateY: PropTypes.number
}

export default DirectionArrow
