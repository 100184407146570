import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography, Box, Divider } from '@material-ui/core'
import idx from 'idx'
import { formatToTimeZone } from 'date-fns-timezone'

import SubHeading from 'src/components/atoms/SubHeading'
import RiskAssessmentConstraint from 'src/components/molecules/RiskAssessmentConstraint'
import MaxDraftInput from 'src/components/molecules/MaxDraftInput'
import { routeSelector, tideStationsSelector } from 'src/store/route/selectors'
import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  pilotageSelector,
  pilotageRouteChecksSelector,
  derivedMaxDraftSelector
} from 'src/store/pilotage/selectors'
import { formatConstraintType, formatIfNumber, valueOrMissingText, formatPilotageData } from 'src/utils/formatters'
import ConstraintDetails from 'src/components/organisms/ConstraintDetails'
import Modal from 'src/components/molecules/Modal'
import useTideExtremes from 'src/hooks/useTideExtremes'
import useRiskAssessment from 'src/hooks/useRiskAssessment'
import { MOVEMENT_TYPE, TIME_FORMAT } from 'src/utils/constants'
import { timezoneSelector } from 'src/store/ports/selectors'
import ExternalLink from 'src/components/atoms/ExternalLink'
import { externalLink1Selector } from 'src/store/extras/selectors'
import TideExtremes from 'src/components/molecules/TideExtremes/TideExtremes'
import useTideDataLoader from 'src/hooks/useTideDataLoader'
import useTideRateDataLoader from 'src/hooks/useTideRateDataLoader'
import getRouteConstraints from 'src/utils/getRouteConstraints'
import OmcDukc from 'src/components/organisms/OmcDukc'
import TideGraph, { TideGraphDataLoader } from 'src/components/organisms/TideGraph'
import Ruler from 'src/components/atoms/Ruler'
import { tidesSelector } from 'src/store/tides/selectors'
import { preferencesRequest } from 'src/store/preferences/actions'
import { featureFlagUseBerthingConditions, featureFlagTideRateSource, featureFlagActiveDefault, featureFlagHideWaypointLocation } from 'src/store/preferences/selectors'
import TidalRateLabel from 'src/components/atoms/TidalRateLabel'
import { pilotageUpdateRequest } from 'src/store/pilotage/actions'
import BerthingConditions from 'src/components/organisms/BerthingConditions'
import { getDepartingForPilotage } from 'src/store/pilotageList/selectors'
import { authUserSelector } from 'src/store/auth/selectors'
import { tideRatesSelector } from 'src/store/tideRates/selectors'
import BerthingConditionsDataLoader from 'src/components/organisms/BerthingConditions/BerthingConditionsDataLoader'
import DhiNcosGraph from 'src/components/organisms/Dhi/DhiNcosGraph'
import RiskAssessmentWaypoint from './../../components/molecules/RiskAssessmentWaypoint/index'
import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'
import DhiTransits from 'src/components/organisms/Dhi/DhiTransits'
import ConstraintDetailsDhi from 'src/components/organisms/ConstraintDetails/ConstraintDetailsDhi'
import { DateTime } from 'luxon'
import { dhiTransitsStateSelector } from './../../store/dhiTransits/selectors'

const StartTime = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`

const StartTimeBox = styled.div`
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: ${({ theme }) => theme.palette.background.lightFaded};
  padding: 24px;
`

const TideContainer = styled.div`
  display: flex;
  margin: 30px 0px 0px 0px;
  .tide-graph {
    flex: 5;
  }
  .tide-list {
    flex: 2;
    display: flex;
  }
`

const ListSelector = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  .listType {
    background: #2F3B52;
    padding: 10px 13px;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    margin-right: 15px;
    border-radius: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .listTypeLabel {
    padding: 0px 10px;
  }
`

const ListTypeLabel = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  margin-left: 16px;
  margin-right: 16px;
`

const R = <sub style={{ fontSize: 8, bottom: '2px', marginRight: 5 }}>®</sub>

const PilotageRisks = () => {
  const dispatch = useDispatch()

  const externalLink = useSelector(externalLink1Selector)
  const { data: pilotage } = useSelector(pilotageSelector)
  const departingPilotage = useSelector(getDepartingForPilotage(pilotage))
  const pilotageRoute = useSelector(routeSelector)
  const maxDraft = useSelector(derivedMaxDraftSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const tideStations = useSelector(tideStationsSelector)
  const timeZone = useSelector(timezoneSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled
  const berthingConditionsEnabled = useSelector(featureFlagUseBerthingConditions)
  const tideRateSource = useSelector(featureFlagTideRateSource)
  const routeChecks = useSelector(pilotageRouteChecksSelector)
  const user = useSelector(authUserSelector)
  const { data: transitData } = useSelector(dhiTransitsStateSelector)

  const { data: pilotageRawData } = useSelector(pilotageSelector)

  const timezone = useSelector(timezoneSelector)
  const pilotageData = pilotageRawData ? formatPilotageData(pilotageRawData, timezone) : null

  const pilotageStartTime = pilotage ? pilotage.date : null
  const formattedStartTime = pilotageStartTime
    ? formatToTimeZone(new Date(pilotageStartTime), TIME_FORMAT, { timeZone })
    : null

  // Need useMemo here to prevent infinite tide data requests
  const routeConstraints = useMemo(() => getRouteConstraints(pilotageRoute), [
    pilotageRoute,
  ])

  useTideDataLoader(routeConstraints)
  useTideRateDataLoader(routeConstraints)

  const tideExtremes = useTideExtremes()
  const tides = useSelector(tidesSelector) || {}
  const tideRates = useSelector(tideRatesSelector) || {}
  const [width, setWidth] = useState(0)
  const tideGraphWidth = (width / 7) * 5 // matches flex proportion in TideContainer

  const riskAssessment = useRiskAssessment(routeConstraints)
  const activeDefaultEnabled = useSelector(featureFlagActiveDefault)
  const hideWaypointLocation = useSelector(featureFlagHideWaypointLocation)

  useEffect(() => {
    if (pilotage) {
      dispatch(preferencesRequest())
    }
  }, [pilotage])

  const [isShowHidden, setIsShowHidden] = useState(false)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [isDetailWaypointOpen, setIsDetailWaypointOpen] = useState(false)
  const [activeRouteConstraint, setActiveRouteConstraint] = useState(null)
  const [activeRouteConstraintPrev, setActiveRouteConstraintPrev] = useState(null)
  const [isDetailDhiOpen, setIsDetailDhiOpen] = useState(false)

  // NOTE: active here means selected, not active as in not hidden.
  const activeRiskAssessment = useMemo(() =>
    riskAssessment.find(item =>
      item.routeConstraint.uuid === idx(activeRouteConstraint, data => data.uuid)
    ),
  [riskAssessment, activeRouteConstraint]
  )

  let tidalRateConfig
  if (activeRiskAssessment) {
    tidalRateConfig = activeRiskAssessment.tideRateStationConfig
  }

  const openConstraintDetailsModalDhi = () => {
    setIsDetailDhiOpen(true)
  }

  const openConstraintDetailsModal = (routeConstraint, routeConstraintPrev) => {
    const riskData = riskAssessment.find(
      item =>
        item.routeConstraint.uuid === idx(routeConstraint, data => data.uuid)
    )
    if (!riskData.calculation.isLoading) {
      setActiveRouteConstraint(routeConstraint)
      setActiveRouteConstraintPrev(routeConstraintPrev)
      setIsDetailOpen(true)
    }
  }

  const openWaypointDetailsModal = (routeConstraint, routeConstraintPrev) => {
    const riskData = riskAssessment.find(
      item =>
        item.routeConstraint.uuid === idx(routeConstraint, data => data.uuid)
    )
    if (!riskData.calculation.isLoading) {
      setActiveRouteConstraint(routeConstraint)
      setActiveRouteConstraintPrev(routeConstraintPrev)
      setIsDetailWaypointOpen(true)
    }
  }

  const handleOmcPlanVariantChange = planId => {
    dispatch(
      pilotageUpdateRequest(
        pilotage.uuid,
        {
          omcChosenPreliminaryPlanId: planId
        },
        <>Default DUKC{R} plan updated</>,
        <>Failed to update default DUKC{R} plan</>,
        false
      )
    )
  }

  const activeConstraintName = idx(
    activeRouteConstraint,
    _ => _.constraint.name
  )
  const activeConstraintType = idx(
    activeRouteConstraint,
    _ => _.constraint.constraintType
  )

  const activeConstraintPrevIsWaypoint = idx(
    activeRouteConstraintPrev,
    _ => _.constraint.isWaypoint
  )

  const riskAssessmentWithoutWaypoints = riskAssessment.filter(risk => !risk.routeConstraint.constraint.isWaypoint)
  const riskAssessmentActiveOnly =
    riskAssessmentWithoutWaypoints.filter(risk => formatIfNumber(risk.overrides.hideConstraint, 0, '0') !== '1')

  const showTideGraph =
    tideGraphWidth &&
    riskAssessmentActiveOnly &&
    riskAssessmentActiveOnly[0] &&
    riskAssessmentActiveOnly.find(({ timeAtConstraint, tide }) => timeAtConstraint && tide) &&
    pilotageStartTime &&
    timeZone

  // EMPX-551 hardcode for Timaru for now.
  const showDhi =
  pilotage && pilotage.port && pilotage.port.name &&
  (pilotage.port.name.toLowerCase().indexOf(`timaru`) > -1 ||
  pilotage.port.name.toLowerCase().indexOf(`dev port`) > -1) &&
  pilotage.vessel &&
  pilotage.vessel.IMO

  // EMPX-806 hardcode for POAL for now.
  const showDhiTransits =
  pilotage && pilotage.port && pilotage.port.name &&
  (pilotage.port.name.toLowerCase().indexOf(`ports of auckland`) > -1 ||
  pilotage.port.name.toLowerCase().indexOf(`dev port`) > -1) &&
  pilotage.vessel &&
  pilotage.vessel.IMO

  let dhiFragment = null
  if (showDhiTransits) {
    dhiFragment = {
      type: 'dhi',
      fragment: <DhiTransits
        key={`dhi-transits-${pilotage.vessel.IMO}`}
        portUuid="empxPortUuidForPoal"
        pilotageStart={pilotage.date}
        vesselIMO={pilotage.vessel.IMO}
        // pilotageStart={'2023-06-11T00:20:00.000Z'}
        timeZone={timeZone}
        // timeZone="UTC"
        onClick={(transitData) => openConstraintDetailsModalDhi(transitData)}
      />
    }
  }

  // hide count label
  let totalCount = 0
  let hiddenCount = 0
  let dhiIndex = -1
  if (activeDefaultEnabled) {
    routeConstraints.forEach((routeConstraint) => {
      const risk = riskAssessment.find(item =>
        item.routeConstraint.uuid === idx(routeConstraint, data => data.uuid)
      )
      if (transitData) {
        const time = risk.timeAtConstraint
        const dateLuxon = DateTime.fromMillis(time.valueOf(), { zone: timezone })
        const dateLuxonDhi = DateTime.fromISO(transitData.timeOfMinimumUKC).setZone(timezone)
        if (dateLuxon.valueOf() < dateLuxonDhi.valueOf()) {
          if (dhiIndex === -1) {
            dhiIndex = 1
          } else {
            dhiIndex += 1
          }
        }
      }
      totalCount += 1
      const isHideConstraint = formatIfNumber(risk.overrides.hideConstraint, 0, '0') === '1'
      if (isHideConstraint) {
        hiddenCount += 1
      }
    })
  }

  const routeConstraintsWithDhi = [...routeConstraints]
  let dhiFragmentToInsert
  if (showDhiTransits) {
    if (dhiIndex === -1) {
      dhiFragmentToInsert = dhiFragment.fragment
    } else {
      routeConstraintsWithDhi.splice(dhiIndex, 0, dhiFragment)
    }
  }

  return (
    <>
      <Ruler onChange={setWidth} />
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      <Box display="flex" justifyContent="space-between">
        <div>
          <Typography variant="h1">Predicted Conditions</Typography>
          <SubHeading>
            Tides predicted for beginning of pilotage{' '}
            {valueOrMissingText(formattedStartTime)}. <br />
            Sourced from the tide prediction data set as provided by the Port admin.
          </SubHeading>
        </div>
        {externalLink && (
          <Box mr={6} ml="auto" display="grid">
            <ExternalLink externalLink={externalLink} />
          </Box>
        )}
        <Box width={235}>
          <MaxDraftInput readOnly={isReadOnly} />
        </Box>
      </Box>

      <TideContainer>
        {
          showTideGraph &&
          <TideGraphDataLoader
            timeZone={timeZone}
            tides={tides}
            tideExtremes={tideExtremes}
            pilotageStartTime={pilotageStartTime}
            riskAssessment={riskAssessmentActiveOnly}
            containerWidth={tideGraphWidth}
          >
            {tideGraphInputs =>
              <div className="tide-graph">
                <TideGraph {...tideGraphInputs} />
              </div>
            }
          </TideGraphDataLoader>
        }

        <div className="tide-list">
          <TideExtremes
            timeZone={timeZone}
            tideExtremes={tideExtremes}
            isError={!!tideExtremes.error}
            pilotageStartTime={pilotageStartTime}
            tideStations={tideStations}
          />
        </div>

        {
          !showTideGraph && <div className="tide-graph"></div>
        }
      </TideContainer>
      <Divider />
      <Typography variant="h1">Risk Assessment</Typography>
      <SubHeading>Check potential passage restrictions</SubHeading>
      <Box mt={3} mb={5}>
        <StartTimeBox>
          <Box p={0} display="flex" alignItems="center">
            <Box flex={1} display="flex">
              <span>Start of pilotage</span>
            </Box>
            <Box flex={1} display="flex" alignItems="center">
              <span style={{ paddingRight: 10 }}>Start time</span>
              <StartTime>{valueOrMissingText(formattedStartTime)}</StartTime>
            </Box>
          </Box>
        </StartTimeBox>
        { activeDefaultEnabled && (
          <Box display="flex" alignItems="center">
            <ListSelector>
              {
                <>
                  <div className="listType">
                    <Checkbox
                      label={<ListTypeLabel>Active</ListTypeLabel>}
                      checked={!isShowHidden} onChange={(e) => e.target.checked ? setIsShowHidden(false) : setIsShowHidden(true)} />
                  </div>
                  <div className="listType">
                    <Checkbox
                      label={<ListTypeLabel>Default</ListTypeLabel>}
                      checked={isShowHidden} onChange={(e) => e.target.checked ? setIsShowHidden(true) : setIsShowHidden(false)} />
                  </div>
                </>
              }
            </ListSelector>
            <div>You are hiding {hiddenCount} item{hiddenCount !== 1 ? 's' : ''} out of {totalCount}</div>
          </Box>
        )}
        { dhiFragmentToInsert }
        {
          routeConstraintsWithDhi.map((routeConstraint, index) => {
            if (routeConstraint.type === 'dhi') {
              return routeConstraint.fragment
            }
            let routeConstraintPrev
            if (index > 0) {
              routeConstraintPrev = routeConstraints[index - 1]
            }
            const risk = riskAssessment.find(item =>
              item.routeConstraint.uuid === idx(routeConstraint, data => data.uuid)
            )
            if (activeDefaultEnabled) {
              const isHideConstraint = formatIfNumber(risk.overrides.hideConstraint, 0, '0') === '1'
              if (isHideConstraint && !isShowHidden) {
                return null
              }
            }
            if (!risk.routeConstraint.constraint.isWaypoint) {
              return (
                <RiskAssessmentConstraint
                  key={routeConstraint.uuid}
                  data={risk}
                  timezone={timeZone}
                  tideExtremes={risk && tideExtremes && tideExtremes[risk.tideStationUuid]}
                  tideRates={risk && tideRates && tideRates[risk.tideRateStationUuid]}
                  tideRateSource={tideRateSource}
                  onClick={() => openConstraintDetailsModal(routeConstraint, routeConstraintPrev)}
                />
              )
            }
            return (
              <RiskAssessmentWaypoint
                key={routeConstraint.uuid}
                data={risk}
                timezone={timeZone}
                tideExtremes={risk && tideExtremes && tideExtremes[risk.tideStationUuid]}
                tideRates={risk && tideRates && tideRates[risk.tideRateStationUuid]}
                tideRateSource={tideRateSource}
                hideWaypointLocation={hideWaypointLocation}
                onClick={() => openWaypointDetailsModal(routeConstraint, routeConstraintPrev)}
              />
            )
          })
        }
      </Box>
      <Modal
        heading={activeConstraintName}
        subHeading={`Constraint type: ${activeConstraintType ? formatConstraintType(activeConstraintType) : ''
        }`}
        includeHeadingDivider={false}
        open={isDetailOpen}
        onClose={() => {
          setIsDetailOpen(false)
          if (activeRiskAssessment) { // clear any unsaved edits
            activeRiskAssessment.setEditingToSaved()
          }
        }}
      >
        {
          tideRateSource === 'config' &&
          tidalRateConfig &&
          tidalRateConfig.enabled &&
          activeRiskAssessment &&
          activeRiskAssessment.timeAtConstraint &&
          tideExtremes &&
          tideExtremes[activeRiskAssessment.tideStationUuid] &&
          tideExtremes[activeRiskAssessment.tideStationUuid].data &&
          <div style={{ position: 'relative', zIndex: 100, width: '100%' }}>
            <div style={{ position: 'absolute', top: '-45px', right: '10px' }}>
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={activeRiskAssessment.tideRateStationName}
                config={tidalRateConfig}
                tideExtremes={tideExtremes[activeRiskAssessment.tideStationUuid].data}
                isLoading={tideExtremes[activeRiskAssessment.tideStationUuid].isLoading}
                time={new Date(activeRiskAssessment.timeAtConstraint).valueOf()}
              />
            </div>
          </div>
        }
        {
          tideRateSource === 'csv' &&
          activeRiskAssessment &&
          activeRiskAssessment.timeAtConstraint &&
          tideRates &&
          tideRates[activeRiskAssessment.tideRateStationUuid] &&
          tideRates[activeRiskAssessment.tideRateStationUuid].data &&
          <div style={{ position: 'relative', zIndex: 100, width: '100%' }}>
            <div style={{ position: 'absolute', top: '-45px', right: '10px' }}>
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={activeRiskAssessment.tideRateStationName}
                tideRates={tideRates[activeRiskAssessment.tideRateStationUuid].data}
                isLoading={tideRates[activeRiskAssessment.tideRateStationUuid].isLoading}
                time={new Date(activeRiskAssessment.timeAtConstraint).valueOf()}
              />
            </div>
          </div>
        }
        {
          activeRouteConstraint &&
          activeRiskAssessment &&
          <ConstraintDetails
            readOnly={isReadOnly}
            isFirst={routeConstraints && routeConstraints[0] && routeConstraints[0].uuid === activeRouteConstraint.uuid}
            renderConstraint
            routeConstraint={activeRouteConstraint}
            activeConstraintPrevIsWaypoint={activeConstraintPrevIsWaypoint}
            riskAssessment={activeRiskAssessment}
            closeModal={() => setIsDetailOpen(false)}
          />
        }
      </Modal>
      <Modal
        heading={transitData ? transitData.transitName : ''}
        subHeading=" "
        includeHeadingDivider={false}
        open={isDetailDhiOpen}
        onClose={() => {
          setIsDetailDhiOpen(false)
        }}
      >
        {
          transitData &&
          <ConstraintDetailsDhi
            readOnly={isReadOnly}
            transitData={transitData}
            closeModal={() => setIsDetailDhiOpen(false)}
          />
        }
      </Modal>
      <Modal
        heading={activeConstraintName}
        subHeading='Waypoint'
        includeHeadingDivider={false}
        open={isDetailWaypointOpen}
        onClose={() => {
          setIsDetailWaypointOpen(false)
          if (activeRiskAssessment) { // clear any unsaved edits
            activeRiskAssessment.setEditingToSaved()
          }
        }}
      >
        {
          tideRateSource === 'config' &&
          tidalRateConfig &&
          tidalRateConfig.enabled &&
          activeRiskAssessment &&
          activeRiskAssessment.timeAtConstraint &&
          tideExtremes &&
          tideExtremes[activeRiskAssessment.tideStationUuid] &&
          tideExtremes[activeRiskAssessment.tideStationUuid].data &&
          <div style={{ position: 'relative', zIndex: 100, width: '100%' }}>
            <div style={{ position: 'absolute', top: '-45px', right: '10px' }}>
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={activeRiskAssessment.tideRateStationName}
                config={tidalRateConfig}
                tideExtremes={tideExtremes[activeRiskAssessment.tideStationUuid].data}
                isLoading={tideExtremes[activeRiskAssessment.tideStationUuid].isLoading}
                time={new Date(activeRiskAssessment.timeAtConstraint).valueOf()}
              />
            </div>
          </div>
        }
        {
          tideRateSource === 'csv' &&
          activeRiskAssessment &&
          activeRiskAssessment.timeAtConstraint &&
          tideRates &&
          tideRates[activeRiskAssessment.tideRateStationUuid] &&
          tideRates[activeRiskAssessment.tideRateStationUuid].data &&
          <div style={{ position: 'relative', zIndex: 100, width: '100%' }}>
            <div style={{ position: 'absolute', top: '-45px', right: '10px' }}>
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={activeRiskAssessment.tideRateStationName}
                tideRates={tideRates[activeRiskAssessment.tideRateStationUuid].data}
                isLoading={tideRates[activeRiskAssessment.tideRateStationUuid].isLoading}
                time={new Date(activeRiskAssessment.timeAtConstraint).valueOf()}
              />
            </div>
          </div>
        }
        {
          activeRouteConstraint &&
          activeRiskAssessment &&
          <Box mt={2}>
            <ConstraintDetails
              readOnly={isReadOnly}
              isFirst={routeConstraints && routeConstraints[0] && routeConstraints[0].uuid === activeRouteConstraint.uuid}
              renderConstraint={false}
              routeConstraint={activeRouteConstraint}
              activeConstraintPrevIsWaypoint={activeConstraintPrevIsWaypoint}
              riskAssessment={activeRiskAssessment}
              closeModal={() => setIsDetailWaypointOpen(false)}
            />
          </Box>
        }
      </Modal>

      {
        !process.env.REACT_APP_OMC_DISABLE &&
        pilotage &&
        pilotage.vessel &&
        pilotage.vessel.IMO &&
        pilotage.date &&
        pilotage.movementType &&
        <OmcDukc
          vesselIMO={pilotage.vessel.IMO}
          pilotageDate={pilotage.date}
          movementType={pilotage.movementType}
          timeZone={timeZone}
          portUuid={pilotage.port.uuid}
          pilot={pilotage.pilot}
          pilotSecond={pilotage.pilotSecond}
          user={user}
          onPlanVariantChange={handleOmcPlanVariantChange}
        />
      }

      { showDhi &&
        <DhiNcosGraph
          portUuid="empxPortUuidForTimaru"
          vesselIMO={pilotage.vessel.IMO}
          // vesselIMO={'9348156'}
          pilotageStart={pilotage.date}
          // pilotageStart={'2021-06-11T00:20:00.000Z'}
          timeZone={timeZone}
          // timeZone="UTC"
        />
      }
      {
        berthingConditionsEnabled &&
        pilotage &&
        pilotage.movementType !== MOVEMENT_TYPE.DEPARTURE &&
        riskAssessmentActiveOnly &&
        maxDraft &&
        <BerthingConditionsDataLoader
          pilotage={pilotage}
          departingPilotage={departingPilotage}
          timeZone={timeZone}
          riskAssessment={riskAssessmentActiveOnly}
          tides={tides}
          tideExtremes={tideExtremes}
          maxDraft={maxDraft}
          routeChecks={routeChecks || []}
        >
          {
            (berthingCalculation) => (
              <>
                <Divider />
                <BerthingConditions
                  berthingCalculation={berthingCalculation}
                  timeZone={timeZone}
                />
              </>
            )

          }
        </BerthingConditionsDataLoader>
      }
    </>
  )
}

export default PilotageRisks
