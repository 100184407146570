import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setServiceWorkerStatus } from 'src/store/ui/actions'
import { isLoggedInSelector } from 'src/store/auth/selectors'
import { isServiceWorkerAvailableSelector } from 'src/store/ui/selectors'

export const useServiceWorker = () => {
  const dispatch = useDispatch()
  const [serviceWorker, setServiceWorker] = useState()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const isServiceWorkerAvailable = useSelector(isServiceWorkerAvailableSelector)
  const handleServiceWorkerReady = registration => {
    setServiceWorker(registration && registration.active)
    let isAvailable = !!(registration && registration.active)
    isAvailable = isAvailable && !!navigator.serviceWorker.controller
    if (isAvailable !== isServiceWorkerAvailable) {
      dispatch(setServiceWorkerStatus(isAvailable))
    }
  }
  useEffect(
    () => {
      try {
        navigator.serviceWorker.getRegistration().then(handleServiceWorkerReady)
        navigator.serviceWorker.ready.then(handleServiceWorkerReady)
      } catch (error) {
        // intentional console for dev
        console.warn(error)
      }
    },
    [isLoggedIn]
  )
  useEffect(
    () => {
      dispatch(setServiceWorkerStatus(!!serviceWorker && !!navigator.serviceWorker.controller))
    },
    [serviceWorker]
  )
  return {
    serviceWorker,
    isReady: !!serviceWorker && !!navigator.serviceWorker.controller,
  }
}
