import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

import PilotageStatus from 'src/components/atoms/PilotageStatus'
import PilotageInfoBar from 'src/components/atoms/PilotageInfoBar'
import InfoIcon from 'src/components/atoms/Icons/Info'

const Notes = styled.div`
  color: rgb(255, 171, 79);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    margin-right: 5px;
  }
`
interface InfoBarHeaderNoHeaderProps {
  pilotageData: any
}

const InfoBarHeaderNoTitle: React.FC<InfoBarHeaderNoHeaderProps> = ({
  pilotageData,
}) => (
  <>
    <Box display="flex">
      <Box flex={1} mb={1} mr={1}>
        <PilotageInfoBar {...pilotageData} />
      </Box>
      <Box>
        <PilotageStatus status={pilotageData ? pilotageData.status : ''} />
      </Box>
    </Box>
    {pilotageData &&
        <>
          {pilotageData.notes && <Notes><InfoIcon size={16} color={'rgb(255, 171, 79)'} />{pilotageData.notes}</Notes>}
        </>
    }
  </>
)

export default React.memo(InfoBarHeaderNoTitle)
