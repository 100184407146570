import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import TextInput from 'src/components/atoms/TextInput'
import { missingValueText } from 'src/utils/constants'

const Select = ({
  value,
  onClose,
  placeholder,
  useMissingPlaceholder,
  options,
  readOnly,
  formatValue,
  getKeyFromValue,
  active,
  ...props
}) => {
  const placeholderText =
    placeholder || (useMissingPlaceholder ? missingValueText : 'Select')

  return (
    <TextInput
      active={active}
      value={readOnly && formatValue ? formatValue(value) : value}
      select={!readOnly}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        displayEmpty: true,
        onClose,
      }}
      readOnly={readOnly}
      InputProps={{
        readOnly,
        // To show a placeholder for a readOnly select, need to pass it down to the Input
        placeholder: readOnly ? placeholderText : undefined,
      }}
      {...props}
    >
      {placeholder !== null && !value && !readOnly && (
        <MenuItem value="" disabled>
          {placeholderText}
        </MenuItem>
      )}
      {!readOnly &&
        options.map(({ value, label }) => (
          <MenuItem
            key={getKeyFromValue ? getKeyFromValue(value) : value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
    </TextInput>
  )
}

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  useMissingPlaceholder: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  active: PropTypes.bool,
  readOnly: PropTypes.bool,
  formatValue: PropTypes.func,
  getKeyFromValue: PropTypes.func,
}

export default React.memo(Select)
