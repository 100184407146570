import { createSelector } from 'reselect'

export const routeStateSelector = state => state.route

export const routeSelector = createSelector(
  routeStateSelector,
  route => route.data
)

export const routeConstraintsSelector = createSelector(
  routeSelector,
  route => (route ? route.routeConstraints : [])
)

export const constraintsSelector = createSelector(
  routeConstraintsSelector,
  routeConstraints => routeConstraints.map(rc => rc.constraint)
)

export const tideStationsSelector = createSelector(
  constraintsSelector,
  constraints => {
    const tideStations = constraints
      .map(c => c.tideStation)
      .filter(item => !!item)

    // remove duplicates
    return tideStations.filter(
      (item, index) =>
        tideStations.findIndex(({ uuid }) => uuid === item.uuid) === index
    )
  }
)

export const tideRateStationsSelector = createSelector(
  constraintsSelector,
  constraints => {
    const tideRateStations = constraints
      .map(c => c.tideRateStation)
      .filter(item => !!item)

    // remove duplicates
    return tideRateStations.filter(
      (item, index) =>
        tideRateStations.findIndex(({ uuid }) => uuid === item.uuid) === index
    )
  }
)
