interface IChart {
  pixels: number | null
  meters: number | null
}

export default function getChartScale(chart: IChart): number | undefined {
  let chartScale
  if (
    chart &&
    typeof chart.pixels === 'number' &&
    typeof chart.meters === 'number' &&
    chart.pixels > 0 &&
    chart.meters > 0
  ) {
    chartScale = chart.meters / chart.pixels
  }
  return chartScale
}
