import { useEffect } from 'react'

export const usePrintCanvasImageConverter = (isEnabled, container, constrainedHeight) => {
  useEffect(
    () => {
      let print, printListener

      if (isEnabled && container) {
        print = window.matchMedia('print')

        printListener = () => {
          if (print.matches) {
            // Hide the canvas container
            const canvas = container.querySelector('canvas')
            canvas.parentNode.parentNode.style.display = 'none'

            // Convert canvas to an image for printing; this is because the canvas is set
            // to fixed dimensions and does not scale when printed, causing them to get
            // cut off.
            const drawingsImg = new window.Image()
            drawingsImg.src = canvas.toDataURL()
            if (constrainedHeight) {
              drawingsImg.height = constrainedHeight - 10 // 10 is for 5px top bottom padding of parent container
            }
            drawingsImg.className = 'drawings-image'

            const imageContainer = container.querySelector('.img-container')
            imageContainer.appendChild(drawingsImg)
          } else {
            // We are changing back out of print mode - remove the image and make the canvas visible again
            const drawingsImg = container.querySelector('.drawings-image')
            if (drawingsImg && drawingsImg.parentNode) {
              drawingsImg.parentNode.removeChild(drawingsImg)
            }

            const canvas = container.querySelector('canvas')
            canvas.parentNode.parentNode.style.display = 'block'
          }
        }

        // Safari does not support MediaQueryList.addEventListener yet
        // So in that case use the deprecated methods instead
        if (print.addEventListener) {
          print.addEventListener('change', printListener)
        } else {
          print.addListener(printListener)
        }
      }

      return () => {
        if (print) {
          if (print.removeEventListener) {
            print.removeEventListener('change', printListener)
          } else {
            print.removeListener(printListener)
          }
        }
      }
    },
    [isEnabled, container, constrainedHeight]
  )
}
