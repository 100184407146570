import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTheme, Box } from '@material-ui/core'

import { PILOTAGE_STATUS } from 'src/utils/constants'

const StatusIndicator = styled.div`
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  background-color: ${props => props.color};
  border: 1px solid ${props => props.color};
  border-radius: 50%;
`

StatusIndicator.propTypes = {
  fillColor: PropTypes.string,
  borderColor: PropTypes.string,
}

const StatusLabel = styled.span`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.fontWeightMedium};
  line-height: 16px;
  margin-left: 12px;
  color: ${props => props.color};
`

const PilotageStatus = ({ status, theme }) => {
  let text
  let color

  switch (status) {
    case PILOTAGE_STATUS.DRAFT:
      text = 'Draft'
      color = '#FF842B'
      break
    case PILOTAGE_STATUS.NEW:
      text = 'New'
      color = theme.palette.text.primary
      break
    case PILOTAGE_STATUS.SENT_READ:
      text = 'Sent'
      color = '#05C985'
      break
    case PILOTAGE_STATUS.SENT_UNREAD:
      text = 'Sent'
      color = '#FFD24F'
      break
    case PILOTAGE_STATUS.SENT_NEW_COMMENT:
      text = 'Sent'
      color = '#E6042B'
      break
    case PILOTAGE_STATUS.PLANNING:
      color = '#E6042B'
      text = 'Plan'
      break
    case PILOTAGE_STATUS.DONE:
      color = theme.palette.text.secondary
      text = 'Done'
      break
    default:
      text = status
      color = ''
  }

  return (
    <Box style={{minWidth: 100}} display="flex" alignItems="center">
      <StatusIndicator color={color} />
      <StatusLabel color={color}>{text}</StatusLabel>
    </Box>
  )
}

PilotageStatus.propTypes = {
  status: PropTypes.string.isRequired,
  theme: PropTypes.object,
}

export default React.memo(withTheme(PilotageStatus))
