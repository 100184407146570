import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ToolIconInner = styled.div`
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-style: solid;
  border-width: ${({ isSelected }) => (isSelected ? 2 : 1)}px;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.2)'};
`

const ToolIconWrapper = styled.div(
  ({ disabled }) => `
  width: 100%;
  cursor: ${disabled ? 'auto' : 'pointer'};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  opacity: ${disabled ? 0.3 : 1};
`
)

ToolIconInner.propTypes = {
  isSelected: PropTypes.bool,
}

const ToolIcon = ({ disabled, ...props }) => (
  <ToolIconWrapper disabled={disabled}>
    <ToolIconInner {...props} />
  </ToolIconWrapper>
)

ToolIcon.propTypes = {
  disabled: PropTypes.bool,
}

export default React.memo(ToolIcon)
