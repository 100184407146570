import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Button } from '@material-ui/core'

const RotateBtn = styled(Button)`
  && {
    width: 57px;
    height: 57px;
    background-color: ${({ theme }) => theme.palette.background.light};
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.light};
    }
    &:disabled {
      opacity: 0.5;
    }
  }
`

const BerthingExtrasContainer = styled.div`
  position: absolute;
  bottom: 190px;
  left: 16px;
  z-index: 2;
`

const BerthingStageRotator = ({ onRotate, disabled }) => (
  <BerthingExtrasContainer>
    <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
      <div>N</div>
      <svg width="18" height="21">
        <path d="M9 0L0 21l9-5.527L18 21z" fill="#FFF" />
      </svg>
    </Box>
    <RotateBtn onClick={onRotate} disabled={disabled}>
      <svg width="16" height="39">
        <g fill="none">
          <path
            d="M12.362 2.5a7.002 7.002 0 0 1 1.489 5.927l2.117.186-3.128 2.624-2.625-3.128 2.464.216a5.829 5.829 0 0 0-1.21-5.075 5.825 5.825 0 0 0-8.219-.719 5.824 5.824 0 0 0-.719 8.219l-.893.75C-.842 8.543-.456 4.118 2.5 1.638c2.957-2.48 7.382-2.094 9.862.862z"
            fill="#1F8EFA"
          />
          <path
            d="M9.263 38.878h-4.14a1.68 1.68 0 0 1-1.68-1.68V16.88c0-.352.11-.695.316-.98l2.07-2.88a1.68 1.68 0 0 1 2.728 0l2.07 2.88c.206.285.316.628.316.98v20.317a1.68 1.68 0 0 1-1.68 1.68z"
            fill="#D8D8D8"
          />
        </g>
      </svg>
    </RotateBtn>
  </BerthingExtrasContainer>
)

BerthingStageRotator.propTypes = {
  onRotate: PropTypes.func,
  disabled: PropTypes.bool,
}

export default React.memo(BerthingStageRotator)
