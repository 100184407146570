import { TideExtremesData, TideStation } from 'src/types/Tides'
import { formatToTimeZone } from 'date-fns-timezone'
import { DATE_FORMAT, TIME_FORMAT } from 'src/utils/constants'
import {
  TimelineItem,
  TimelineItemType,
} from 'src/components/molecules/TideExtremes/types'

export function getTideExtremesTimeline(
  tideExtremes: TideExtremesData,
  tideStations: TideStation[],
  pilotageStartTime: string,
  timeZone: string
) {
  const pilotageStart = new Date(pilotageStartTime)

  const pilotageStartItem = {
    formattedDate: formatToTimeZone(pilotageStartTime, DATE_FORMAT, {
      timeZone,
    }),
    formattedTime: formatToTimeZone(pilotageStartTime, TIME_FORMAT, {
      timeZone,
    }),
    type: TimelineItemType.PilotageStart,
  }

  return tideStations.map(tideStation => {
    const tideExtremesForStation = tideExtremes[tideStation.uuid]
    if (!tideExtremesForStation) {
      return {
        timeline: [] as Partial<TimelineItem>[],
        tideStation,
        isLoading: false,
        error: null,
      }
    }
    const { data, isLoading, error } = tideExtremesForStation

    const filteredData = (data || []).filter(
      // Remove data points more than 48 hours outside the range we are interested in
      item =>
        Math.abs(new Date(item.dateTime).getTime() - pilotageStart.getTime()) <
        48 * 60 * 60 * 1000
    )

    const firstTideType =
      filteredData.length < 2
        ? TimelineItemType.Unknown
        : filteredData[0].tide > filteredData[1].tide
        ? TimelineItemType.HighTide
        : TimelineItemType.LowTide

    const timeline: Partial<TimelineItem>[] = filteredData.map(
      (item, index) => {
        const dateObj = new Date(item.dateTime)
        return {
          ...item,
          dateObj,
          formattedDate: formatToTimeZone(dateObj, DATE_FORMAT, { timeZone }),
          formattedTime: formatToTimeZone(dateObj, TIME_FORMAT, { timeZone }),
          type:
            index === 0
              ? firstTideType
              : filteredData[index - 1].tide > item.tide
              ? TimelineItemType.LowTide
              : TimelineItemType.HighTide,
        }
      }
    )

    if (timeline.length > 0) {
      // Find where the pilotage start time sits within the timeline,
      // and add an item representing the start time into the timeline
      let i, n
      for (i = 0, n = timeline.length; i < n; i++) {
        if (pilotageStart < (timeline[i] as TimelineItem).dateObj) {
          timeline.splice(i, 0, pilotageStartItem)
          break
        }
      }
      if (i === n) {
        timeline.push(pilotageStartItem)
      }

      const startIndex = timeline.indexOf(pilotageStartItem)

      if (timeline.length > startIndex + 3) {
        // We only want to include a max of 3 tide extremes after the pilotage
        // start time - remove any items farther in the future
        timeline.splice(startIndex + 4)
      }

      if (startIndex > 1) {
        // We only want to include a max of 1 tide extreme before the pilotage
        // start time - remove any items farther in the past
        timeline.splice(0, startIndex - 1)
      }
    }

    return { timeline, tideStation, isLoading, error }
  })
}
