import {
  VESSEL_REQUEST,
  VESSEL_IN_PROGRESS,
  VESSEL_ERROR,
  VESSEL_SUCCESS,
} from 'src/store/vessel/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import { SYNC_ENTITY_SUCCESS } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function vesselReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case VESSEL_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
      }
    case VESSEL_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case VESSEL_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case VESSEL_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    case SYNC_ENTITY_SUCCESS: {
      const { type: syncType } = payload
      const { result } = action
      return syncType !== SYNC_ENTITY_TYPES.PILOTAGE_VESSEL ||
        result.imo !== (state.data && state.data.imo)
        ? state
        : {
            ...state,
            data: result,
          }
    }
    default:
      return state
  }
}

export default vesselReducer
