import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'

import {
  DataTable,
  DataTableRowLabel,
  DataTableRowValue,
} from 'src/components/atoms/DataTable'
import {
  bearingToCompassPoint,
  kmhToKnots,
  WEATHER_CONFIG,
} from 'src/utils/weather'
import { WeatherConditions } from 'src/components/molecules/WeatherDataTable/common'
import DirectionArrow from 'src/components/atoms/DirectionArrow'
import { missingValueText } from 'src/utils/constants'
import { formatIfNumber, formatSunriseSunset } from 'src/utils/formatters'

const WeatherDataWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `
)

const PaddedDataTableRowValue = styled(DataTableRowValue)`
  padding-right: 30px;
`

const PaddedWeatherConditions = styled(WeatherConditions)(
  ({ theme }) => css`
    padding: 2px 24px 0 0;
    width: 240px;

    img {
      display: block;
    }

    ${theme.breakpoints.down('sm')} {
      padding-bottom: ${theme.spacing(2)}px;
    }
  `
)

const WeatherDataTable = ({ weather, source }) => {
  const {
    icon,
    temperature,
    description,
    windDirection,
    windSpeed,
    windGust,
    swellHeight,
    swellDirection,
    precipitation,
    visibility,
    sunrise,
    sunset,
    cloudCover,
    pressure,
    date,
  } = weather

  return (
    <WeatherDataWrapper>
      {source === WEATHER_CONFIG.worldWeatherOnline && (
        <PaddedWeatherConditions
          iconSrc={icon}
          temperature={temperature}
          description={description}
        />
      )}
      <Box flex={1}>
        <DataTable>
          <tbody>
            <tr>
              {source && source !== WEATHER_CONFIG.worldWeatherOnline ? (
                <>
                  <DataTableRowLabel>Temperature</DataTableRowLabel>
                  <PaddedDataTableRowValue>
                    {
                      typeof temperature === 'number' && !isNaN(temperature)
                        ? `${temperature} °C`
                        : missingValueText
                    }
                  </PaddedDataTableRowValue>
                </>
              ) : null}
              <DataTableRowLabel>Wind Dir.</DataTableRowLabel>
              <PaddedDataTableRowValue>
                <Box display="flex" alignItems="center">
                  <DirectionArrow rotation={windDirection} />
                  <Box ml={1}>{bearingToCompassPoint(windDirection)}</Box>
                </Box>
              </PaddedDataTableRowValue>
              <DataTableRowLabel>Wind Speed</DataTableRowLabel>
              <PaddedDataTableRowValue>
                {kmhToKnots(windSpeed)} kn
              </PaddedDataTableRowValue>
              {windGust ? (
                <>
                  <DataTableRowLabel>Gust</DataTableRowLabel>
                  <DataTableRowValue>
                    {kmhToKnots(windGust)} kn
                  </DataTableRowValue>
                </>
              ) : null}
              {cloudCover && (source === WEATHER_CONFIG.stormGeo || source === WEATHER_CONFIG.weatherzone) ? (
                <>
                  <DataTableRowLabel>Cloud</DataTableRowLabel>
                  <DataTableRowValue>
                    {cloudCover} %
                  </DataTableRowValue>
                </>
              ) : null}
            </tr>
            <tr>
              {description && (source === WEATHER_CONFIG.stormGeo || source === WEATHER_CONFIG.weatherzone) ? (
                <>
                  <DataTableRowLabel>Conditions</DataTableRowLabel>
                  <DataTableRowValue>{description}</DataTableRowValue>
                </>
              ) : null}
              <DataTableRowLabel>Swell Dir.</DataTableRowLabel>
              {swellDirection !== null ? (
                <PaddedDataTableRowValue>
                  <Box display="flex" alignItems="center">
                    <DirectionArrow rotation={swellDirection} />
                    <Box ml={1}>{bearingToCompassPoint(swellDirection)}</Box>
                  </Box>
                </PaddedDataTableRowValue>
              ) : (
                  <DataTableRowValue>{missingValueText}</DataTableRowValue>
                )}
              <DataTableRowLabel>Swell</DataTableRowLabel>
              {swellHeight !== null ? (
                <DataTableRowValue>
                  {swellHeight.toFixed(2)} m
                </DataTableRowValue>
              ) : (
                  <DataTableRowValue>{missingValueText}</DataTableRowValue>
                )}
              <DataTableRowLabel>Visibility</DataTableRowLabel>
              <DataTableRowValue>
              {
                typeof visibility === 'number'
                ? `${visibility} km`
                : missingValueText
              }
              </DataTableRowValue>
              {sunrise && source === WEATHER_CONFIG.stormGeo ? (
                <>
                  <DataTableRowLabel>Sunrise</DataTableRowLabel>
                  <DataTableRowValue>
                    {formatSunriseSunset(date, sunrise)}
                  </DataTableRowValue>
                </>
              ) : null
              }{sunset && source === WEATHER_CONFIG.stormGeo ? (
                <>
                  <DataTableRowLabel>Sunset</DataTableRowLabel>
                  <DataTableRowValue>
                    {formatSunriseSunset(date, sunset)}
                  </DataTableRowValue>
                </>
              ) : null
              }
              {
                typeof pressure === 'number' &&
                <>
                  <DataTableRowLabel>Pressure</DataTableRowLabel>
                  <DataTableRowValue>
                    {`${formatIfNumber(pressure, 1, missingValueText)} hPa`}
                  </DataTableRowValue>
                </>
              }
            </tr>
            <tr>
              <DataTableRowLabel>Precipitation</DataTableRowLabel>
              <DataTableRowValue>
                {precipitation !== null
                  ? `${formatIfNumber(precipitation, 1, missingValueText)} mm`
                  : missingValueText}
              </DataTableRowValue>
            </tr>
          </tbody>
        </DataTable>
      </Box>
    </WeatherDataWrapper>
  )
}

WeatherDataTable.propTypes = {
  weather: PropTypes.object,
  source: PropTypes.string,
}

export default WeatherDataTable
