import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { groupBy } from 'src/utils/groupBy'
import { DateTime } from 'luxon'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import PilotageStatus from 'src/components/atoms/PilotageStatus/PilotageStatus'
import { PilotageSummaryPropType } from 'src/propTypes'
import { clearPilotage, goToPilotage } from 'src/store/pilotage/actions'
import { PILOTAGE_STATUS, LONG_DATE_FORMAT_LUXON, missingValueText } from 'src/utils/constants'
import { prefetchedPilotageUuidsSelector } from 'src/store/prefetch/selectors'
import {
  isOnlineSelector,
  isServiceWorkerAvailableSelector,
} from 'src/store/ui/selectors'
import SyncStatusIcon from 'src/components/molecules/SyncStatusIcon'
import PrefetchStatusIndicator from 'src/components/molecules/PrefetchStatusIndicator'
import WarningIcon from 'src/components/atoms/Icons/Warning'
import { preferencesLoadedSelector } from './../../../store/preferences/selectors'
import 'flag-icon-css/css/flag-icons.css'

const StyledTable = styled(Table)`
  && {
    @media (max-width: 768px) {
      margin: 0 -32px;
      width: calc(100% + 64px);
    }
    margin-bottom: 30px;
  }

  & td {
    font-size: 18px;
  }

  & tbody tr:not(.date-separator) {
    cursor: pointer;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  tbody.offline {
    tr:not(.prefetched):not(.date-separator) {
      opacity: 0.5;
      cursor: default;
    }
  }
`

const StyledVesselName = styled.span`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weights.fontWeightSemiBold};
`

const StyledTime = styled.span`
  font-weight: ${({ theme }) => theme.weights.fontWeightRegular};
`

const StyledDateCell = styled(TableCell)`
  && {
    background-color: transparent;
    padding: ${({ theme }) => theme.spacing(5)}px 0 0 0;
    height: auto;
  }
`

const StyledDate = styled(Typography).attrs({ variant: 'h5' })`
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
`

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`
// EMPX-743: comment out for now, until we support personalisation to this UI.
// const getFlagCode = (item) => {
//   let result = item.vesselFlagCode
//   if (result) {
//     result = result.toLowerCase()
//   }
//   return result
// }

const PilotageTableRow = React.memo(
  ({
    item,
    serviceWorkerIsAvailable,
    showOfflineReadyStatus = true,
    showSyncStatus,
    showVesselVisitCode,
    isPilotageDone,
    isPilotageArchived,
    isPilotageCancelled,
    isPreferencesLoaded,
    ...props
  }) => (
    <TableRow {...props}>
      <TableCell>
        <StyledTime>{item.timeStr}</StyledTime>
      </TableCell>
      <TableCell>
        <StyledVesselName>
          {item.vessel}
        </StyledVesselName>
      </TableCell>
      {/* EMPX-743: comment out for now, until we support personalisation to this UI.
      <TableCell>
        { getFlagCode(item) ? <span className={`flag-icon flag-icon-${getFlagCode(item)}`}></span> : <span>--</span>}
      </TableCell> */}
      {showVesselVisitCode &&
        <TableCell>{item.vesselVisitCode || '--'}</TableCell>
      }
      <TableCell>{item.movementType}</TableCell>
      <TableCell>{item.from}</TableCell>
      <TableCell>{item.to}</TableCell>
      <TableCell>
        { item.sideTo ? (item.sideTo === 'Starboard' ? 'Stbd' : (item.sideTo === '--' ? 'Either' : item.sideTo)) : '' }
      </TableCell>
      <TableCell>
        {item.pilot}
        {item.secondaryPilot !== missingValueText && (
          <>
            <br />
            {item.secondaryPilot}
          </>
        )}
      </TableCell>
      <TableCell>{item.maxDraft}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <PilotageStatus status={item.status} />
          {item.needsReview && <StyledWarningIcon />}
        </Box>
      </TableCell>
      {serviceWorkerIsAvailable && showOfflineReadyStatus && (
        <TableCell>
          {(isPilotageDone || isPilotageArchived || isPilotageCancelled || !isPreferencesLoaded) ? (
            <></>
          ) : <PrefetchStatusIndicator pilotage={item.raw} />}
        </TableCell>
      )}
      {serviceWorkerIsAvailable && showSyncStatus && (
        <TableCell>
          <SyncStatusIcon pilotageUuid={item.uuid} />
        </TableCell>
      )}
    </TableRow>
  )
)

PilotageTableRow.displayName = 'PilotageTableRow'

PilotageTableRow.propTypes = {
  item: PilotageSummaryPropType,
  serviceWorkerIsAvailable: PropTypes.bool,
  showOfflineReadyStatus: PropTypes.bool,
  showSyncStatus: PropTypes.bool,
  showVesselVisitCode: PropTypes.bool,
  isPilotageDone: PropTypes.bool,
  isPilotageArchived: PropTypes.bool,
  isPilotageCancelled: PropTypes.bool,
  isPreferencesLoaded: PropTypes.bool,
}

const PilotageTableHeader = ({
  serviceWorkerIsAvailable,
  showOfflineReadyStatus = true,
  showSyncStatus = false,
  showVesselVisitCode,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Time</TableCell>
        <TableCell>Vessel</TableCell>
        {/* EMPX-743: comment out for now, until we support personalisation to this UI.
        <TableCell>Flag</TableCell> */}
        {showVesselVisitCode &&
          <TableCell>Visit&nbsp;No.</TableCell>
        }
        <TableCell>MVT</TableCell>
        <TableCell>From</TableCell>
        <TableCell>To</TableCell>
        <TableCell>SideTo</TableCell>
        <TableCell>Pilot(s)</TableCell>
        <TableCell>Max. Draft</TableCell>
        <TableCell>Status</TableCell>
        {serviceWorkerIsAvailable && showOfflineReadyStatus && (
          <TableCell>Offline</TableCell>
        )}
        {serviceWorkerIsAvailable && showSyncStatus && (
          <TableCell>Sync</TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
PilotageTableHeader.propTypes = {
  serviceWorkerIsAvailable: PropTypes.bool,
  showOfflineReadyStatus: PropTypes.bool,
  showSyncStatus: PropTypes.bool,
  showVesselVisitCode: PropTypes.bool,
}

const PilotageTable = ({
  data,
  timezone: timeZone,
  showOfflineReadyStatus = true,
  showSyncStatus = false,
  showVesselVisitCode = false,
  onRowClick,
  isAscending,
}) => {
  const isPreferencesLoaded = useSelector(preferencesLoadedSelector)
  const isOnline = useSelector(isOnlineSelector)
  const dispatch = useDispatch()

  const prefetchedPilotageUuids = useSelector(prefetchedPilotageUuidsSelector)
  const serviceWorkerIsAvailable = useSelector(isServiceWorkerAvailableSelector)

  // The table rows are sorted into groups by date, and visually
  // are displayed in individual sections.

  const dateLuxon = DateTime.now().setZone(timeZone)
  const todayFormattedDate = dateLuxon.toFormat(LONG_DATE_FORMAT_LUXON)

  const groupedRows = groupBy(data, item => {
    const dateLuxon = DateTime.fromISO(item.date).setZone(timeZone)
    return dateLuxon.toFormat(LONG_DATE_FORMAT_LUXON)
  }
  )
    .map(({ key, items }) => {
      return {
        formattedDate: key === todayFormattedDate ? `Today - ${key}` : key,
        items: isAscending ? items.sort((a, b) => a.dateObj - b.dateObj) : items.sort((a, b) => b.dateObj - a.dateObj),
      }
    })

  const goToDetail = item => {
    dispatch(clearPilotage(item))
    dispatch(goToPilotage(item))
  }

  const handleClick = item => {
    onRowClick
      ? onRowClick(item.uuid)
      : (isOnline || prefetchedPilotageUuids.includes(item.uuid)) &&
        goToDetail(item)
  }

  return (
    <Fragment>
      <StyledDate>{groupedRows[0] && groupedRows[0].formattedDate}</StyledDate>
      <StyledTable>
        <PilotageTableHeader
          serviceWorkerIsAvailable={serviceWorkerIsAvailable}
          showSyncStatus={showSyncStatus}
          showOfflineReadyStatus={showOfflineReadyStatus}
          showVesselVisitCode={showVesselVisitCode}
        />
        <TableBody className={isOnline ? '' : 'offline'}>
          {groupedRows.map((group, index) => {
            return (
              <Fragment key={group.formattedDate}>
                {index > 0 && (
                  <TableRow className="date-separator">
                    <StyledDateCell colSpan={8}>
                      <StyledDate>{group.formattedDate}</StyledDate>
                    </StyledDateCell>
                  </TableRow>
                )}
                {group.items.map(item => (
                  <PilotageTableRow
                    serviceWorkerIsAvailable={serviceWorkerIsAvailable}
                    showSyncStatus={showSyncStatus}
                    showOfflineReadyStatus={showOfflineReadyStatus}
                    showVesselVisitCode={showVesselVisitCode}
                    isPilotageDone={item.status === PILOTAGE_STATUS.DONE}
                    isPilotageArchived={item.status === PILOTAGE_STATUS.ARCHIVED}
                    isPilotageCancelled={item.status === PILOTAGE_STATUS.CANCELLED}
                    isPreferencesLoaded={isPreferencesLoaded}
                    key={item.uuid}
                    item={item}
                    className={
                      prefetchedPilotageUuids.includes(item.uuid)
                        ? 'prefetched'
                        : ''
                    }
                    onClick={() => handleClick(item)}
                  />
                ))}
              </Fragment>
            )
          })}
        </TableBody>
      </StyledTable>
    </Fragment>
  )
}

PilotageTable.propTypes = {
  data: PropTypes.arrayOf(PilotageSummaryPropType),
  timezone: PropTypes.string,
  showOfflineReadyStatus: PropTypes.bool,
  showSyncStatus: PropTypes.bool,
  onRowClick: PropTypes.func,
  isAscending: PropTypes.bool,
}

export default React.memo(PilotageTable)
