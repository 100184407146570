import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute, useRouter } from 'react-router5'
import styled from 'styled-components'
import { Box, Typography, Button, Dialog, Slide } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'

import PilotageDetails from 'src/components/organisms/PilotageDetails'
import FixedHeaderLayout from 'src/components/molecules/FixedHeaderLayout'
import VesselSearch from 'src/components/organisms/VesselSearch'
import {
  DRAFT_PILOTAGE,
  NEW_PILOTAGE,
  REVIEW_PILOTAGE,
} from 'src/router/routes'
import { selectVessel } from 'src/store/vesselLookup/actions'
import { unsavedPilotageSelector } from 'src/store/ui/selectors'
import FullscreenWrapper from 'src/components/atoms/FullscreenWrapper'
import FullscreenCloseIcon from 'src/components/atoms/FullscreenCloseIcon'
import usePilotageLoader from 'src/hooks/usePilotageLoader'
import VesselConfirmationDialog from 'src/components/organisms/VesselConfirmationDialog'

const ContentWrapper = styled.div`
  position: relative;
  top: 122px;
  max-width: 800px;
  margin: auto;
  padding-bottom: 20px;
`

const FormWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3)}px;
  background-color: ${theme.palette.background.light};
  border-radius: 10px;
`
)

const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  & button {
    text-transform: none;
    min-height: 0;
  }
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PilotageDefinition = ({ context }) => {
  const router = useRouter()

  // id can be passed in the route to finish off draft pilotages
  const { route } = useRoute()
  const pilotageId = route.params.id

  // New Pilotage is reused for editing draft pilotages, so we need to include
  // pilotage loader here.
  usePilotageLoader(pilotageId)

  const dispatch = useDispatch()
  const hasUnsavedChanges = useSelector(unsavedPilotageSelector)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isVesselSearchOpen, setIsVesselSearchOpen] = useState(false)
  const newVesselConfirmationPromiseRef = useRef(null)

  const onDeactivate = () => {
    if (hasUnsavedChanges) {
      // TODO: use a designed modal
      const result = window.confirm('Discard unsaved changes?')
      if (result) {
        // If the user chooses to discard the changes, clear any selected vessel.
        // This handles the case where if the user reopens the new pilotage screen
        // the selected vessel from before is already pre-filled.
        dispatch(selectVessel(null))
      }
      return result
    }
    return Promise.resolve()
  }

  // Display browser prompt when the user leaves the view with unsaved changes
  router.canDeactivate(NEW_PILOTAGE, () => onDeactivate)
  router.canDeactivate(DRAFT_PILOTAGE, () => onDeactivate)
  router.canDeactivate(REVIEW_PILOTAGE, () => onDeactivate)

  const acceptNewVessel = () => {
    setIsConfirmationOpen(false)
    newVesselConfirmationPromiseRef.current.resolve(true)
  }

  const rejectNewVessel = () => {
    setIsConfirmationOpen(false)
    newVesselConfirmationPromiseRef.current.resolve(false)
  }

  const closeVesselModal = () => setIsVesselSearchOpen(false)
  const openVesselModal = () => setIsVesselSearchOpen(true)

  const vesselLookupDone = vessel => {
    closeVesselModal()
    if (vessel) {
      dispatch(selectVessel(vessel))
    }
  }

  const headerContent = (
    <BackWrapper>
      <Button onClick={() => window.history.back()}>
        <KeyboardBackspaceIcon />
        &nbsp;&nbsp;Pilotage List
      </Button>
    </BackWrapper>
  )

  let title
  switch (context) {
    case 'New':
      title = 'New Pilotage'
      break
    case 'Draft':
      title = 'Draft Pilotage'
      break
    default:
      title = 'Pilotage Definition'
      break
  }

  const confirmNewVessel = () => {
    setIsConfirmationOpen(true)
    return new Promise((resolve, reject) => {
      newVesselConfirmationPromiseRef.current = { resolve, reject }
    })
  }

  return (
    <>
      <FixedHeaderLayout disablePortSelector headerContent={headerContent}>
        <ContentWrapper>
          <FormWrapper>
            <Typography variant="h2">{title}</Typography>
            <Box mt={3}>
              <PilotageDetails
                isNew={!pilotageId}
                setIsConfirmationOpen={setIsConfirmationOpen}
                onVesselSearch={openVesselModal}
                confirmNewVessel={confirmNewVessel}
              />
            </Box>
          </FormWrapper>
        </ContentWrapper>
      </FixedHeaderLayout>
      <Dialog
        open={isVesselSearchOpen}
        fullScreen
        TransitionComponent={Transition}
      >
        <FullscreenWrapper>
          <FullscreenCloseIcon size="small" onClick={closeVesselModal}>
            <CloseIcon size="small" />
          </FullscreenCloseIcon>
          <VesselSearch onDone={vesselLookupDone} />
        </FullscreenWrapper>
      </Dialog>
      <VesselConfirmationDialog
        isConfirmationOpen={isConfirmationOpen}
        Transition={Transition}
        acceptNewVessel={acceptNewVessel}
        closeConfirmationModal={rejectNewVessel}
      />
    </>
  )
}

PilotageDefinition.propTypes = {
  context: PropTypes.oneOf(['New', 'Draft', 'Review']),
}

export default PilotageDefinition
