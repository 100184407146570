import React from 'react'
import PropTypes from 'prop-types'
import { Text as KonvaText } from 'konva'
import { Text } from 'react-konva'
import {
  CHART_SHAPE_TYPE,
  CHART_BASE_FONT_SIZE,
} from 'src/utils/drawingConstants'
import { getScaledFontSize } from 'src/utils/drawingHelpers'

const ChartLabel = ({
  label,
  chartDimensions,
  chartOffset,
  isEditing,
  ...props
}) => {
  // The text tool itself is versioned - this doesn't refer to overall drawing version
  // Text tool version 1 adds font size scaling based on the image width
  const { text, x, y, color, version } = label.attrs
  const fontSize =
    version === 1 ? getScaledFontSize(chartDimensions[0]) : CHART_BASE_FONT_SIZE

  // It seems the only way to center a text element is to create a Konva.Text instance
  // and calculate it's width and height, when derive the offset from these values.
  const t = new KonvaText({
    fontSize,
    fontStyle: 'bold',
    text,
  })
  const offset = [t.width() / 2, t.height() / 2]

  // Do not render the label if it is in edit mode (a text input is shown instead).
  return (
    !isEditing && (
      <Text
        name={label.name}
        type={CHART_SHAPE_TYPE.TEXT}
        x={x * chartDimensions[0] + (chartOffset ? chartOffset[0] : 0)}
        y={y * chartDimensions[1] + (chartOffset ? chartOffset[1] : 0)}
        offsetX={offset[0]}
        offsetY={offset[1]}
        fill={color}
        text={text}
        fontSize={fontSize}
        fontStyle="bold"
        isSelectable
        {...props}
      />
    )
  )
}

ChartLabel.propTypes = {
  label: PropTypes.shape({
    name: PropTypes.string,
    attrs: PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
      version: PropTypes.number,
    }),
  }),
  chartDimensions: PropTypes.arrayOf(PropTypes.number),
  chartOffset: PropTypes.arrayOf(PropTypes.number),
  isEditing: PropTypes.bool,
}

export default React.memo(ChartLabel)
