import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useRoute, useRouter } from 'react-router5'
import { Formik, Form, Field } from 'formik'
import { Typography, Grid, Box } from '@material-ui/core'

import containerShipImage from 'src/assets/images/container-ship.jpg'
import TextInput from 'src/components/atoms/TextInput/TextInput'
import LoginLayout from 'src/components/organisms/LoginLayout/LoginLayout'
import LoginPaneSection, {
  SmallLoginPaneSection,
} from 'src/components/organisms/LoginLayout/LoginPaneSection'
import AuthSubmitButton from 'src/components/atoms/AuthSubmitButton'
import EmpxLogo from 'src/components/organisms/LoginLayout/EmpxLogo'
import newPasswordValidator from 'src/utils/validators/newPasswordValidator'
import { requestSignup } from 'src/store/auth/actions'
import { LOGIN, SIGN_UP } from 'src/router/routes'

const Signup = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { route } = useRoute()
  const { token } = route.params

  const loginPaneContent = useMemo(
    () => (
      <>
        <SmallLoginPaneSection align="center">
          <EmpxLogo />
        </SmallLoginPaneSection>

        <LoginPaneSection>
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validate={newPasswordValidator}
            onSubmit={(values, actions) => {
              dispatch(requestSignup(token, values.password)).then(result => {
                if (result) {
                  router.navigate(LOGIN, { from: SIGN_UP })
                } else {
                  actions.setSubmitting(false)
                }
              })
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form noValidate>
                <Typography variant="body1">
                  Welcome! Please set your password so you can finish signing
                  up.
                </Typography>
                <Box mt={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        render={({ field }) => (
                          <TextInput
                            type="password"
                            id="create-password"
                            placeholder="Password"
                            label="Create Password"
                            formikField={field}
                            error={touched.password && errors.password}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="confirmPassword"
                        render={({ field }) => (
                          <TextInput
                            type="password"
                            id="confirm-password"
                            placeholder="Password"
                            label="Confirm Password"
                            formikField={field}
                            error={
                              touched.confirmPassword && errors.confirmPassword
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <AuthSubmitButton disabled={isSubmitting}>
                    Sign Up
                  </AuthSubmitButton>
                </Box>
              </Form>
            )}
          </Formik>
        </LoginPaneSection>
      </>
    ),
    []
  )

  return (
    <LoginLayout
      loginPaneContent={loginPaneContent}
      imageSrc={containerShipImage}
    />
  )
}

export default Signup
