import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Divider } from '@material-ui/core'
import { routeSelector } from 'src/store/route/selectors'
import {
  pilotageSelector,
} from 'src/store/pilotage/selectors'
import useRiskAssessment from 'src/hooks/useRiskAssessment'
import PilotageWeather from 'src/components/organisms/PilotageWeather'
import getRouteConstraints from 'src/utils/getRouteConstraints'
import { weatherLocationsStateSelector } from 'src/store/weatherLocations/selectors'
import PilotageWeatherDataLoader from 'src/components/organisms/PilotageWeather/PilotageWeatherDataLoader'
import { weatherLocationsRequest } from 'src/store/weatherLocations/actions'
import { timezoneSelector } from 'src/store/ports/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import InfoBarHeaderNoTitle from 'src/containers/Pilotage/InfoBarHeaderNoTitle'

const MainPilotageWeather = () => {
  const dispatch = useDispatch()

  const { data: pilotage } = useSelector(pilotageSelector)
  const pilotageRoute = useSelector(routeSelector)
  const weatherLocations = useSelector(weatherLocationsStateSelector)
  const { data: pilotageRawData } = useSelector(pilotageSelector)

  const timezone = useSelector(timezoneSelector)
  const pilotageData = pilotageRawData ? formatPilotageData(pilotageRawData, timezone) : null

  // Need useMemo here to prevent infinite tide data requests
  const routeConstraints = useMemo(() => getRouteConstraints(pilotageRoute), [
    pilotageRoute,
  ])

  const riskAssessment = useRiskAssessment(routeConstraints)

  useEffect(() => {
    if (pilotage) {
      dispatch(weatherLocationsRequest(pilotage.port.uuid, true))
    }
  }, [pilotage])

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      {
        pilotage &&
        !weatherLocations.isLoading &&
        weatherLocations.weatherLocations &&
        weatherLocations.weatherLocations.length > 0 &&
        pilotage.port.uuid === weatherLocations.portUuid && (
          <PilotageWeatherDataLoader
            pilotage={pilotage}
            riskAssessment={riskAssessment}
          >
            {pilotageWeatherInputs => (
              <>
                <Box>
                  <PilotageWeather {...pilotageWeatherInputs} />
                </Box>
              </>
            )}
          </PilotageWeatherDataLoader>
        )
      }
    </>
  )
}

export default MainPilotageWeather
