import { MovementType } from './Pilotage'

export enum ExtraType {
  Checklist = 'checklist',
  Flowlist = 'flowlist',
  PassFailChecklist = 'pass_fail_checklist',
  TimestampChecklist = 'timestamp_checklist',
  ValuesChecklist = 'values_checklist',
  Terms = 'terms',
  Guidelines = 'guidelines',
  ExternalLink = 'external_link',
  GeneralNotes = 'general_notes',
}

export interface Extra<T> {
  uuid: string
  name?: string
  metadata: T
  extraType: ExtraType
  pilotage?: any
}

export interface BaseChecklistItem {
  uuid: string
  label: string
  comment?: string
  disableForMovementTypes?: MovementType[]
}

export interface ChecklistItem extends BaseChecklistItem {
  isSelected: boolean
  isHeader: boolean
}

export interface ChecklistMetadata {
  value: ChecklistItem[]
  comment?: string
}

export interface PassFailChecklistItem extends BaseChecklistItem {
  passChecked: boolean
  failChecked: boolean
  isHeader: boolean
}

export interface PassFailChecklistMetadata {
  value: PassFailChecklistItem[]
  comment?: string
}

export interface ChecklistExtra extends Extra<ChecklistMetadata> {}

export interface PassFailChecklistExtra
  extends Extra<PassFailChecklistMetadata> {}
