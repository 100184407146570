import {
  ROUTES_LOOKUP_REQUEST,
  ROUTES_LOOKUP_IN_PROGRESS,
  ROUTES_LOOKUP_ERROR,
  ROUTES_LOOKUP_SUCCESS,
  CLEAR_ROUTES_LOOKUP,
} from 'src/store/routesLookup/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function routesLookupReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case CLEAR_ROUTES_LOOKUP:
      return { ...INITIAL_STATE }
    case ROUTES_LOOKUP_REQUEST:
      return {
        ...state,
        data: null,
      }
    case ROUTES_LOOKUP_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case ROUTES_LOOKUP_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case ROUTES_LOOKUP_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default routesLookupReducer
