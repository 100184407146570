import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import TimestampItem from 'src/components/organisms/PilotageTimestamps/TimestampItem'
import { CrossIcon } from 'src/components/atoms/Icons'
import ErrorMessage from 'src/components/atoms/ErrorMessage'
import Modal from './../../molecules/Modal/Modal'
import TimestampClear from './TimestampClear'

const PilotageTimestamps = ({
  timeZone,
  data,
  disabled,
  editTime,
  editDate,
  editTimeEta,
  editDateEta,
  recordNow,
  clearDateTime,
  clearDateTimeEta,
  error,
  retry,
}) => {
  const [clearingTimestamp, setClearingTimestamp] = useState(null)
  const [isClearing, setIsClearing] = useState(false)

  const [clearingTimestampEta, setClearingTimestampEta] = useState(null)
  const [isClearingEta, setIsClearingEta] = useState(false)

  const openClearModal = item => {
    setClearingTimestamp(item)
  }

  const closeClearModal = () => setClearingTimestamp(null)

  const confirmClear = async () => {
    setIsClearing(true)
    // const result = await clearDateTime(clearingTimestamp)
    clearDateTime(clearingTimestamp)
    setIsClearing(false)
    // if (result) {
    setClearingTimestamp(null)
    // }
  }

  const openClearEtaModal = item => {
    setClearingTimestampEta(item)
  }

  const closeClearEtaModal = () => setClearingTimestampEta(null)

  const confirmClearEta = async () => {
    setIsClearingEta(true)
    // const result = await clearDateTimeEta(clearingTimestampEta)
    clearDateTimeEta(clearingTimestampEta)
    setIsClearingEta(false)
    // if (result) {
    setClearingTimestampEta(null)
    // }
  }

  return (
    <Box mt={4}>
      {error && (
        <ErrorMessage
          buttonText="Retry"
          onButtonClick={retry}
          loading={false}
          Icon={CrossIcon}
          mb={1}
        >
          Something went wrong trying to save timestamps. Please try again.
        </ErrorMessage>
      )}
      {data.map(item => (
        <TimestampItem
          key={item.uuid}
          timeZone={timeZone}
          disabled={disabled}
          {...item}
          onRecordNow={() => recordNow(item)}
          onClearDateTime={() => openClearModal(item)}
          onClearDateTimeEta={() => openClearEtaModal(item)}
          onEditTime={value => editTime(item, value)}
          onEditDate={value => editDate(item, value)}
          onEditTimeEta={value => editTimeEta(item, value)}
          onEditDateEta={value => editDateEta(item, value)}
        />
      ))}
      <Modal
        open={!!clearingTimestamp}
        heading="Clear Timestamp"
        includeHeadingDivider={false}
        onClose={closeClearModal}
      >
        <TimestampClear
          isLoading={isClearing}
          onCancel={closeClearModal}
          onConfirm={confirmClear}
        />
      </Modal>
      <Modal
        open={!!clearingTimestampEta}
        heading="Clear ETA Timestamp"
        includeHeadingDivider={false}
        onClose={closeClearEtaModal}
      >
        <TimestampClear
          isLoading={isClearingEta}
          onCancel={closeClearEtaModal}
          onConfirm={confirmClearEta}
        />
      </Modal>
    </Box>
  )
}

PilotageTimestamps.propTypes = {
  timeZone: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      dateTime: PropTypes.string,
      editedTime: PropTypes.string,
      editedDate: PropTypes.instanceOf(Date),
      isValidTime: PropTypes.bool,
      dateTimeEta: PropTypes.string,
      editedTimeEta: PropTypes.string,
      editedDateEta: PropTypes.instanceOf(Date),
      isValidTimeEta: PropTypes.bool,
    })
  ),
  editTime: PropTypes.func,
  editDate: PropTypes.func,
  editTimeEta: PropTypes.func,
  editDateEta: PropTypes.func,
  recordNow: PropTypes.func,
  clearDateTime: PropTypes.func,
  clearDateTimeEta: PropTypes.func,
  error: PropTypes.bool,
  retry: PropTypes.func,
  disabled: PropTypes.bool,
}

export default React.memo(PilotageTimestamps)
