import { TidalRateConfig, TidalRateCalculation, TideExtreme, TideType, TideFlowDirection } from 'src/utils/api/preferences'

export class InsufficientDataError extends Error {
    constructor(message: string) {
        super(message)
    }
}

export function calculateTidalRate(
    config: TidalRateConfig,
    tideExtremes: TideExtreme[], // taken from the tide station extreme data for the relevant constraint
    timeAtConstraint: number
): TidalRateCalculation {

    const [before, after] = getBoundingTideExtremes(tideExtremes, timeAtConstraint)

    if (!before || !after) {
        throw new InsufficientDataError(`Cant get bounding tide extremes at time ${timeAtConstraint}`)
    }

    const tideRange = Math.abs(before.tide - after.tide)

    const direction = before.tide > after.tide ?
        TideFlowDirection.Ebb :
        TideFlowDirection.Flood

    // @todo - question for pilots - is high water in bounding range or closest absolute high water more relevant?
    const closestHighWater = direction === TideFlowDirection.Ebb ?
        before :
        after

    const highWaterOffset = quantizeMsToHourOffset(timeAtConstraint - closestHighWater.dateTime)

    const rate = Object
        .values(config.rates)
        .find(({ hoursOffset }) => hoursOffset === highWaterOffset)

    if (!rate) {
        throw new Error(`Can't get tidal rate for high water offset ${highWaterOffset}`)
    }

    const tideType = tideRange >= config.tideTypeRangeThreshold ?
        TideType.Spring :
        TideType.Neap

    const rangeUuid = tideType === TideType.Spring ?
        rate.springRangeUuid :
        rate.neapRangeUuid

    const rateRange = config.ranges[rangeUuid]

    if (!rateRange) {
        throw new Error(`Cant get tidal rate range for uuid ${rangeUuid}`)
    }

    return {
        tideType,
        tideDirection: direction,
        highWaterOffset,
        range: rateRange,
        degrees: rate.directionDegrees
    }
}

const quantizeMsToHourOffset = (ms: number) => {
    const offset = Math.round((ms / (1000 * 60 * 60)) % 24)
    const sign = offset < 0 ? -1 : 1
    return Math.min(Math.abs(offset), 6) * sign // can't be more than 6 away from 0
}

// get tide extremes immediately before and after time
// assumes tideExtremes is sorted by time asc
export function getBoundingTideExtremes(
    tideExtremes: TideExtreme[],
    time: number
): [TideExtreme | undefined, TideExtreme | undefined] {

    for (let i = 0; i < tideExtremes.length; i++) {

        const extreme = tideExtremes[i]
        const prev = tideExtremes[i - 1]
        const next = tideExtremes[i + 1]

        // time is out of range of the given data, can't return anything
        if (i === 0 && time < extreme.dateTime) {
            return [undefined, undefined]

            // when the time falls exactly on an extreme. unlikely, but we need to handle it
            // we can choose either the prev or next, whichever is defined
        } else if (extreme.dateTime === time) {
            return prev ? [prev, extreme] :
                next && time <= next.dateTime ? [extreme, next] :
                    [undefined, undefined]

            // common case - the first extreme after a given time    
        } else if (extreme.dateTime >= time) {
            return [prev, extreme]
        }
    }
    return [undefined, undefined]
} 
