import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { InfoIcon } from '../Icons'

const StyledInfoIcon = styled(InfoIcon)`
  display: block;
`

interface Props {
  children: React.ReactNode
}

const InfoMessage: React.FC<Props> = ({ children, ...props }) => (
  <Box display="flex" alignItems="center" {...props}>
    <Box pr={1}>
      <StyledInfoIcon size={20} />
    </Box>
    <Box flex={1}>{children}</Box>
  </Box>
)

export default InfoMessage
