import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import SecondaryButton from 'src/components/atoms/SecondaryButton'
import { LinkIcon } from 'src/components/atoms/Icons'

const StyledButton = styled(SecondaryButton)`
  && {
    max-height: 50px;
    .MuiButton-label {
      padding: 0 10px;
    }
  }
`

const ButtonText = styled(Typography).attrs({ variant: 'h4' })`
  && {
    font-weight: 600;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

interface Props {
  externalLink: {
    metadata: {
      name: string
      url: string
    }
  }
}

const ExternalLink: React.FC<Props> = ({ externalLink }) => {
  const handleClick = () => {
    window.open(
      `${externalLink.metadata.url}`,
      `${externalLink.metadata.name}`,
      'menubar=no,location=yes,resizable=yes,scrollbars=no,status=yes'
    )
  }

  return (
    <>
      {externalLink && externalLink.metadata ? (
        <StyledButton onClick={handleClick}>
          <ButtonText>{externalLink.metadata.name}</ButtonText>
          <LinkIcon />
        </StyledButton>
      ) : null}
    </>
  )
}

export default React.memo(ExternalLink)
