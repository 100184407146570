import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import GlobalStyle from 'src/styles/GlobalStyles'
import Toast from 'src/containers/Toast'
import theme from 'src/styles/theme'

const RootLayout = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <GlobalStyle />
          {children}
          <Toast />
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

RootLayout.propTypes = {
  children: PropTypes.element,
}

export default RootLayout
