import React from 'react'
import PropTypes from 'prop-types'

import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import { valueOrMissingText } from 'src/utils/formatters'
import { TickIcon } from 'src/components/atoms/Icons'

const SummaryPlanInfoBox = ({
  onDetailsClick,
  showTugsInfo,
  numberOfTugs,
  numberOfStages,
}) => (
  <InfoBox
    title="Pilotage Plan"
    buttonText="Details"
    onButtonClick={onDetailsClick}
    Icon={TickIcon}
  >
    {showTugsInfo && (
      <>
      {valueOrMissingText(numberOfTugs === 0 ? 'No' : numberOfTugs)}{' '}
      {numberOfTugs === 1 ? 'Tug' : 'Tugs'} selected
      <br/>
      </>
    )}
    {valueOrMissingText(numberOfStages)} stage passage plan created
  </InfoBox>
)

SummaryPlanInfoBox.propTypes = {
  onDetailsClick: PropTypes.func,
  showTugsInfo: PropTypes.bool,
  numberOfTugs: PropTypes.number,
  numberOfStages: PropTypes.number,
}

export default React.memo(SummaryPlanInfoBox)
