import { v1 as uuidv1 } from 'uuid'

import {
  TOAST_VARIANT_INFO,
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'

export const TOAST_ADD = 'TOAST_ADD'
export const TOAST_REMOVE = 'TOAST_REMOVE'

const DEFAULT_AUTOHIDE_DURATION = 8000

export const addToast = toast => dispatch => {
  const queryParams = new URLSearchParams(window.location.href)
  const isPdf = queryParams.get('pdf') === 'true'
  if (!isPdf) {

    const id = uuidv1()

    dispatch({
      type: TOAST_ADD,
      payload: {
        title: '',
        message: '',
        variant: TOAST_VARIANT_INFO,
        id,
        created: new Date(),
        ...toast,
      },
    })

    setTimeout(() => {
      removeToast(id)(dispatch)
    }, DEFAULT_AUTOHIDE_DURATION)
  }
}

export const removeToast = toastId => dispatch => {
  dispatch({ type: TOAST_REMOVE, payload: { id: toastId }})
}

export const addErrorToast = toast =>
  addToast({
    variant: TOAST_VARIANT_ERROR,
    ...toast,
  })

export const addSuccessToast = toast =>
  addToast({
    variant: TOAST_VARIANT_SUCCESS,
    ...toast,
  })
