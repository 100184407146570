import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import TidalRateLabel from 'src/components/atoms/TidalRateLabel'
import { DateTime } from 'luxon'

import {
  ConstraintUkcIcon,
  ConstraintOhcIcon,
  CrossIcon,
  TickIcon,
} from 'src/components/atoms/Icons'
import {
  formatConstraintType,
  numberOrMissing,
  valueOrMissingText,
} from 'src/utils/formatters'
import {
  CONSTRAINT_STATUS,
  CONSTRAINT_TYPE,
} from 'src/utils/constants'

import { ConstraintName, ConstraintValue, RiskWrapper, Timestamp } from './../RiskAssessmentConstraint/RiskAssessmentCommon'
import { formatIfNumber } from './../../../utils/formatters'
import styled from 'styled-components'

const ResidualLabel = styled.div`
    border-radius: 5px;
    box-sizing: border-box;
    background: #ED1C24;
    color: #FFF;
    text-transform: uppercase;
    padding: 0px 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 5px -5px 0px;
    span {
        padding: 0px 2px;
        font-size: 12px;
        display: inline-block;
    }
    svg {
        margin-right: 3px;
    }
`

const RiskAssessmentConstraint = ({
  data,
  timezone,
  tideExtremes,
  tideRates,
  tideRateSource,
  ...props
}) => {

  const {
    routeConstraint,
    constraintStatus,
    calculation,
    tideRateStationConfig,
    tideRateStationName,
    timeAtConstraint
  } = data

  const { constraint } = routeConstraint
  const constraintType = constraint.constraintType || CONSTRAINT_TYPE.UKC
  const time = timeAtConstraint

  let timeStr = null
  if (time) {
    const dateLuxon = DateTime.fromMillis(time.valueOf(), { zone: timezone })
    timeStr = dateLuxon.toFormat('HH:mm')
  }

  let constraintStatusIcon
  switch (constraintStatus) {
    case CONSTRAINT_STATUS.PASSED:
      constraintStatusIcon = <TickIcon />
      break
    case CONSTRAINT_STATUS.FAILED:
      constraintStatusIcon = <CrossIcon />
      break
    default:
      constraintStatusIcon = null
  }

  let constraintTypeIcon
  switch (constraintType) {
    case CONSTRAINT_TYPE.OHC:
      constraintTypeIcon = <ConstraintOhcIcon />
      break
    case CONSTRAINT_TYPE.UKC:
    default:
      constraintTypeIcon = <ConstraintUkcIcon />
      break
  }

  let resultLabel
  switch (constraintType) {
    case CONSTRAINT_TYPE.OHC:
      resultLabel = 'Rem. OHC'
      break
    case CONSTRAINT_TYPE.UKC:
    case CONSTRAINT_TYPE.UKC_DYNA:
      resultLabel = 'Rem. UKC'
      break
    default:
      break
  }

  let rawResultLabel
  switch (constraintType) {
    case CONSTRAINT_TYPE.OHC:
      rawResultLabel = 'Actual OHC'
      break
    case CONSTRAINT_TYPE.UKC:
    case CONSTRAINT_TYPE.UKC_DYNA:
      rawResultLabel = 'Static UKC'
      break
    default:
      break
  }

  const isHideConstraint = formatIfNumber(data.overrides.hideConstraint, 0, '0') === '1'
  return (
    <RiskWrapper status={constraintStatus} {...props} dashed={isHideConstraint}>
      <Box p={0} display="flex" alignItems="center">

        <Box flex="0 0 auto" pr={2}>
          {constraintTypeIcon}
        </Box>

        <Box
          flex={3}
          pr={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <ConstraintName>{constraint.name}</ConstraintName>
            <div>Constraint type: {formatConstraintType(constraintType)}</div>
            {
              data.hasResidualDiff &&
              <ResidualLabel>
                <span>Res {formatIfNumber(data.overrides.residual, 2, '--')}</span>
              </ResidualLabel>
            }
            {
              tideRateSource === 'config' &&
              tideRateStationConfig &&
              tideRateStationConfig.enabled &&
              tideExtremes &&
              tideExtremes.data &&
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={tideRateStationName}
                config={tideRateStationConfig}
                tideExtremes={tideExtremes.data}
                isLoading={tideExtremes.isLoading}
                time={new Date(time).valueOf()}
              />
            }
            {
              tideRateSource === 'csv' &&
              tideRates && tideRates.data &&
              <TidalRateLabel
                source={tideRateSource}
                tideRateStationName={tideRateStationName}
                tideRates={tideRates.data}
                isLoading={tideRates.isLoading}
                time={new Date(time).valueOf()}
              />
            }
          </div>
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={60}
        >
          <span>Time</span>
          <Timestamp>{valueOrMissingText(timeStr)}</Timestamp>
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={60}
        >
          {
            rawResultLabel &&
            <>
              <span>{rawResultLabel}</span>
              <ConstraintValue>{numberOrMissing(calculation.rawResult, null, 2)} m</ConstraintValue>
            </>
          }
        </Box>

        <Box
          flex={1}
          pr={2}
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          height={60}
        >
          <span>{resultLabel}</span>
          <ConstraintValue>
            {numberOrMissing(calculation.result, null, 2)} m
          </ConstraintValue>
        </Box>

        <Box width={30}>
          {constraintStatusIcon}
        </Box>
      </Box>
    </RiskWrapper>
  )
}

RiskAssessmentConstraint.propTypes = {
  data: PropTypes.object,
  timezone: PropTypes.string,
  tideExtremes: PropTypes.object,
  tideRates: PropTypes.object,
  tideRateSource: PropTypes.string
}

export default RiskAssessmentConstraint
