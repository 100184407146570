import { useEffect, useRef, useState } from 'react'

// Canvas size on horizontal ipad. We use this as a baseline size.
// Symbols will be scaled up and down by comparing the screen size to this
// baseline size.
const BASE_CHART_SIZE = [919, 730]

const useChartImage = (
  chart,
  stageId,
  src,
  containerWidth,
  containerHeight,
  isMasterView
) => {
  let imageObj

  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [img, setImg] = useState(null)
  const [imgStageId, setImgStageId] = useState(null)
  const [offset, setOffset] = useState([0, 0])
  const [dimensions, setDimensions] = useState([0, 0])
  const [scaleFactor, setScaleFactor] = useState(null)
  const [fullSizeScaleFactor, setFullSizeScaleFactor] = useState(null)

  const lastImage = useRef(null)
  // add this because two stage can use the same chart
  // so without this the imgStageId would not change
  const previousSrcRef = useRef(src)
  useEffect(
    () => {
      if (src === previousSrcRef.current) {
        setImgStageId(stageId)
      }
    },
    [stageId]
  )

  const onLoad = () => {
    lastImage.current = imageObj
    // Make sure the image is displayed with maximal dimensions
    // possible without overflowing outside the available drawing area.
    const { naturalWidth, naturalHeight } = imageObj

    const scaleWidth = BASE_CHART_SIZE[0] / naturalWidth
    const scaleHeight = BASE_CHART_SIZE[1] / naturalHeight

    const baseScale =
      naturalHeight * scaleWidth <= BASE_CHART_SIZE[1]
        ? scaleWidth
        : scaleHeight

    const baseSize = [naturalWidth * baseScale, naturalHeight * baseScale]

    let displaySize = [
      containerWidth,
      (baseSize[1] * containerWidth) / baseSize[0],
    ]

    if (displaySize[1] > containerHeight) {
      displaySize = [
        (baseSize[0] * containerHeight) / baseSize[1],
        containerHeight,
      ]
    }

    imageObj.width = displaySize[0]
    imageObj.height = displaySize[1]

    setImg(imageObj)

    const scaleFactor = displaySize[0] / baseSize[0]

    // If requesting compressed charts, naturalWidth doesn't tell us the true
    // width of chart, which is required for correctly displaying vessels to
    // scale. Need to use the width returned on the BE chart object for this.
    const fullSizeScaleFactor = displaySize[0] / chart.width
    if (isMasterView) {
      // EMPX-513 need setTimeout to show drawings on PDF
      setTimeout(() => setOffsetDimensionsScaling(displaySize, scaleFactor, fullSizeScaleFactor), 0)
    } else {
      // EMPX-513 need to skip setTimeout to avoid scaling bug on iPad
      setOffsetDimensionsScaling(displaySize, scaleFactor, fullSizeScaleFactor)
    }
    setLoaded(true)
    setError(false)
    setImgStageId(stageId)
  }

  const setOffsetDimensionsScaling = (displaySize, scaleFactor, fullSizeScaleFactor) => {
    setOffset([
      0.5 * (containerWidth - displaySize[0]),
      0.5 * (containerHeight - displaySize[1]),
    ])
    setDimensions([displaySize[0], displaySize[1]])
    setScaleFactor(scaleFactor)
    setFullSizeScaleFactor(fullSizeScaleFactor)
  }

  const onError = () => {
    setLoaded(true)
    setError(true)
    setOffset([0, 0])
    setDimensions([containerWidth, containerHeight])
    setImgStageId(stageId)
  }

  useEffect(
    () => {
      previousSrcRef.current = src
      if (src) {
        if (lastImage.current && lastImage.current.src === src) {
          imageObj = lastImage.current
          onLoad()
          return
        }
        imageObj = new window.Image()
        // Set crossorigin to anonymous so that if the canvas is attempted to be exported to
        // a data URL we don't get a tainted canvas error due to cross origin images. Only
        // for the master view (it breaks offline chart loading in the pilot app).
        if (isMasterView) {
          imageObj.crossOrigin = 'anonymous'
        }
        imageObj.src = src
        imageObj.addEventListener('load', onLoad)
        imageObj.addEventListener('error', onError)

        return () => {
          imageObj.removeEventListener('load', onLoad)
          imageObj.removeEventListener('error', onError)
        }
      } else {
        const aspect = (1024 - 105) / 768
        let w = containerWidth
        let h = containerWidth / aspect
        if (h > containerHeight) {
          h = containerHeight
          w = h * aspect
        }
        setDimensions([w, h])
        setScaleFactor(containerWidth / BASE_CHART_SIZE[0])
        setOffset([0.5 * (containerWidth - w), 0.5 * (containerHeight - h)])
        lastImage.current = null
      }
    },
    [src, containerWidth, containerHeight]
  )

  useEffect(
    () => {
      // Only set loaded to true if we know there won't be an image. Otherwise for
      // charts with an image, the shapes still appear prematurely anyway.
      setImg(null)
      setError(false)
      setLoaded(src === false)
      if (src === false) {
        setImgStageId(stageId)
      }
    },
    [src]
  )

  return {
    img,
    offset,
    dimensions,
    scaleFactor,
    fullSizeScaleFactor,
    loaded,
    error,
    stageId: imgStageId,
  }
}

export default useChartImage
