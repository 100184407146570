import { v4 as uuidv4 } from 'uuid'
import idx from 'idx'

import { createRouteCheck, updateRouteCheck } from 'src/utils/api/routes'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { addEntity } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { pilotageRequest } from 'src/store/pilotage/actions'
import flags from 'src/utils/flags'

export const ROUTE_CHECK_SAVE_IN_PROGRESS = 'ROUTE_CHECK_SAVE_IN_PROGRESS'
export const ROUTE_CHECK_SAVE_SUCCESS = 'ROUTE_CHECK_SAVE_SUCCESS'
export const ROUTE_CHECK_SAVE_ERROR = 'ROUTE_CHECK_SAVE_ERROR'

export const routeCheckSaveInProgress = () => ({
  type: ROUTE_CHECK_SAVE_IN_PROGRESS,
})

export const routeCheckSaveSuccess = payload => ({
  type: ROUTE_CHECK_SAVE_SUCCESS,
  payload,
})

export const routeCheckSaveError = error => ({
  type: ROUTE_CHECK_SAVE_ERROR,
  payload: error,
})

export const routeCheckSave = payload => async (dispatch, getState) => {
  const isOnline = isOnlineSelector(getState())
  try {
    let data
    if (isOnline) {
      dispatch(routeCheckSaveInProgress())
      const request = payload.uuid
        ? updateRouteCheck(payload.uuid, payload)
        : createRouteCheck(payload)
      const response = await request
      data = getSinglePayloadFromResponse(response)
      dispatch(
        addSuccessToast({
          message: payload.routeConstraint.constraint.isWaypoint ? TOAST_MESSAGES.SAVE_ROUTE_CHECK_WAYPOINT_SUCCESS : TOAST_MESSAGES.SAVE_ROUTE_CHECK_SUCCESS,
        })
      )
      dispatch(routeCheckSaveSuccess(data))
      if (flags.PLAN_STATUS_UPDATE_ENABLED) {
        // update causes a status update, so refetch the latest from the BE
        dispatch(pilotageRequest(payload.pilotage.uuid))
      }
    } else {
      // EMPX-97 don't need to create fake UUID, otherwise on sync
      // it will do an edit on multiple changes (existing record), instead of a create.
      // For new records, we track this using a constant 'route-check-no-uuid' + uuid string.
      // uuid is so that we can sync multiple route checks records when offline.
      data = {
        ...payload,
        uuid: payload.uuid || `route-check-no-uuid-${uuidv4()}`,
      }
      const syncUuid = `${data.uuid}`
      const isNew =
        !payload.uuid || payload.uuid.startsWith('route-check-no-uuid')
      dispatch(
        addEntity({
          isNew,
          uuid: syncUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_ROUTE_CHECKS,
          pilotageUuid: idx(data, _ => _.pilotage.uuid),
          entity: data,
        })
      )
      dispatch(routeCheckSaveSuccess(data))
    }
  } catch (error) {
    dispatch(routeCheckSaveError(error))
    dispatch(
      addErrorToast({
        message: payload.routeConstraint.constraint.isWaypoint ? TOAST_MESSAGES.SAVE_ROUTE_CHECK_WAYPOINT_ERROR : TOAST_MESSAGES.SAVE_ROUTE_CHECK_ERROR,
      })
    )
  }
}
