import React from 'react'
import { useSelector } from 'react-redux'
import {
  isAssignedToActivePilotageSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
} from 'src/store/pilotage/selectors'
import FormFieldError from 'src/components/atoms/FormFieldError'
import { Box, Divider, Typography } from '@material-ui/core'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import { WidePrimaryButton } from 'src/components/atoms/PrimaryButton'
import { ExtraType } from 'src/types/Extras'
import { usePilotageExtra } from 'src/hooks/usePilotageExtra'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import FreeTextField from 'src/components/atoms/FreeTextField'
import { useRouter } from 'react-router5'
import { PILOTAGE_SUMMARY } from 'src/router/routes'
import { isAdminSelector } from 'src/store/auth/selectors'

const MAX_CHARS = 500

interface NotesMeta {
  value: string
}

export const PilotageNotes: React.FC = () => {
  const { metadata, isDirty, isSaving, save, update } = usePilotageExtra<
    NotesMeta
  >({
    extraType: ExtraType.GeneralNotes,
    successMessage: TOAST_MESSAGES.SAVE_NOTES_SUCCESS,
    errorMessage: TOAST_MESSAGES.SAVE_NOTES_ERROR,
  })

  const router = useRouter()
  router.canDeactivate(PILOTAGE_SUMMARY, () => () => {
    return !isDirty || confirm('You have unsaved general notes changes, are you sure you want to leave this page?')
  })
  const isAdmin = useSelector(isAdminSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  let isReadOnly = !isAssigned || isPilotageArchived || isPilotageCancelled
  if (isReadOnly) {
    if (isAdmin) {
      isReadOnly = false
    }
  }

  const isInvalid = false

  const onChangeCallback = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    update({ value: event.target.value })
  }

  const value = metadata ? metadata.value : ''
  const tooLong = value.length > MAX_CHARS

  return (
    <>
      <Divider />
      <Typography variant="h2">General Notes</Typography>
      <InfoBox extraPadding mt={3} className="unbreakable">
        <FreeTextField
          rows={8}
          placeholder="Enter any general comments or notes regarding this pilotage here"
          value={value}
          onChange={onChangeCallback}
          disabled={isSaving || isReadOnly}
        />

        {tooLong && (
          <FormFieldError>
            {value.length} / {MAX_CHARS} character limit
          </FormFieldError>
        )}

        {!isReadOnly && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <WidePrimaryButton
              onClick={save}
              disabled={!isDirty || isSaving || isInvalid || !!tooLong}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </WidePrimaryButton>
          </Box>
        )}
      </InfoBox>
    </>
  )
}
