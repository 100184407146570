import React from 'react'
import styled from 'styled-components'

const FormFieldLabel = styled(({ readOnly, ...props }) => <label {...props} />)`
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  margin-bottom: 5px;
  cursor: ${({ disabled, readOnly }) =>
    disabled || readOnly ? 'default' : 'pointer'};
`

export default FormFieldLabel
