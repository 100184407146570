import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from 'react-router5'
import { Typography, Box, Divider } from '@material-ui/core'
import { actions as routerActions } from 'redux-router5'

import { PILOTAGE_PLAN_STAGE } from 'src/router/routes'
import SubHeading from 'src/components/atoms/SubHeading'
import { canvasReset } from 'src/store/canvas/actions'
import { startSelectMode } from 'src/store/canvasMenu/actions'
import PassagePlanGrid from './../../components/molecules/PassagePlanNavigation/PassagePlanGrid'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { timezoneSelector } from 'src/store/ports/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'

const PilotagePlan = () => {
  const dispatch = useDispatch()
  const { route } = useRoute()
  const pilotageId = route.params.id
  const { data: pilotageRawData } = useSelector(pilotageSelector)

  const timezone = useSelector(timezoneSelector)
  const pilotageData = pilotageRawData ? formatPilotageData(pilotageRawData, timezone) : null

  const goToStage = stageId => {
    dispatch(canvasReset(stageId))
    dispatch(startSelectMode())
    dispatch(
      routerActions.navigateTo(PILOTAGE_PLAN_STAGE, { id: pilotageId, stageId })
    )
  }

  const StyledBox = styled(Box)`
    && {
      overflow-x: scroll;
      padding-bottom: 20px;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      <StyledBox>
        <Typography variant="h1">Passage Plan</Typography>
        <SubHeading>Create plan details</SubHeading>
        <PassagePlanGrid topMargin onChange={goToStage} />
      </StyledBox>
    </>
  )
}

export default PilotagePlan
