import React from 'react'

const LinkIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 13"
    padding="15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0985 0.96875H6.16101L12.0985 6.90625V0.96875Z"
      fill="#1F8EFA"
    />
    <rect
      x="8.87463"
      y="3.04807"
      width="1.61852"
      height="5.625"
      transform="rotate(45 8.87463 3.04807)"
      fill="#1F8EFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 2.30964 0.559644 1.75 1.25 1.75H4.375L5.625 3H1.25V11.75H10V7.375L11.25 8.625V11.75C11.25 12.4404 10.6904 13 10 13H1.25C0.559644 13 0 12.4404 0 11.75V3Z"
      fill="#1F8EFA"
    />
  </svg>
)

export default LinkIcon
