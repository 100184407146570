import { createBasicActionTypes } from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import { getTideData } from 'src/utils/api/tides'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { TIDE_TYPE } from 'src/utils/constants'

export const [
  TIDE_EXTREMES_REQUEST,
  TIDE_EXTREMES_SUCCESS,
  TIDE_EXTREMES_ERROR,
  TIDE_EXTREMES_IN_PROGRESS,
] = createBasicActionTypes('TIDE_EXTREMES')

export const tideExtremesRequest = (
  tideStationUuid,
  fromDate,
  toDate,
  opts
) => async (dispatch) => {
  dispatch({ type: TIDE_EXTREMES_REQUEST, payload: { tideStationUuid } })

  if (!opts || !opts.isPrefetch) {
    dispatch({ type: TIDE_EXTREMES_IN_PROGRESS, payload: { tideStationUuid } })
  }

  try {
    const response = await getTideData(
      tideStationUuid,
      fromDate,
      toDate,
      TIDE_TYPE.EXTREMES
    )
    const payload = getPayloadFromResponse(response)
    const data = [].concat(...payload.map(item => item.metadata)).map(item => {
      const dateTime = new Date(item.dateTime).valueOf()
      return {
        tide: item.tide,
        dateTime,
      }
    })

    dispatch({
      type: TIDE_EXTREMES_SUCCESS,
      payload: { data, tideStationUuid },
    })
    return data
  } catch (error) {
    dispatch({ type: TIDE_EXTREMES_ERROR, payload: { error, tideStationUuid } })
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.TIDE_EXTREMES_ERROR,
      })
    )
    return false
  }
}
