export const PASSWORD_MIN_LENGTH = 8

function newPasswordValidator(values) {
  const errors = {}
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < PASSWORD_MIN_LENGTH) {
    errors.password = `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`
  } else if (!/(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)/.test(values.password)) {
    errors.password =
      'Password must contain a lowercase character, an uppercase character, and a number'
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'Please confirm your password'
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match'
  }
  return errors
}

export default newPasswordValidator
