import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import idx from 'idx'

import useChartImage from 'src/hooks/useChartImage'
import {
  calculateMainVesselDimensions,
  canRotateSymbol,
  clamp,
  convertActionsStackToShapes,
  getSymbolInstanceRenderData,
  getVesselRotation,
} from 'src/utils/drawingHelpers'
import {
  CANVAS_ELEMENT_NAMES,
  CHART_MAX_SCALE,
  CHART_MIN_SCALE,
  CHART_SHAPE_TYPE,
  CHART_STACK_ACTION,
  CHART_SYMBOL_RENDER_DATA,
  CHART_TEXT_INPUT_HEIGHT,
  CHART_TEXT_INPUT_WIDTH,
  CHART_TOOL,
  CHART_ZOOM_SPEED_PINCH,
  CHART_ZOOM_SPEED_WHEEL,
  SYMBOL_MAX_SCALE,
  SYMBOL_MIN_SCALE,
} from 'src/utils/drawingConstants'
import {
  addStackAction,
  displayAddLabel,
  removeAddLabel,
  selectShape,
  setStageDimensions,
  setStageOffset,
  setStageZoom,
} from 'src/store/canvas/actions'
import { distanceBetween } from 'src/utils/findClosestPoint'
import calculateRotationAngle from 'src/utils/calculateRotationAngle'
import { findLineIntersection } from 'src/utils/findLineIntersection'
import { rotatePoint } from 'src/utils/rotatePoint'
import { imageTokenSelector } from 'src/store/auth/selectors'
import getChartImageUrl from 'src/utils/getChartImageUrl'
import { getMasterViewImageToken } from 'src/store/masterview/selectors'
import getChartScale from 'src/utils/getChartScale'

const knownSymbolVariants = Object.values(CHART_SYMBOL_RENDER_DATA).map(
  item => item.variant
)

const useCanvas = ({
  canvasState: state,
  canvasMenuState: menuState,
  readOnly,
  isMasterView,
  chart,
  isBerthingStage,
  drawingData,
  stageId,
  containerRef,
  parentRef,
  vessel,
  imageType,
}) => {
  const dispatch = useDispatch()
  const imageToken = useSelector(
    isMasterView ? getMasterViewImageToken : imageTokenSelector
  )

  // Holds the value of the previous point in the drawing when erasing is active
  const prevEraserPtRef = useRef(null)

  // Holds a list of shapes deleted when erasing mode is active. Used to recording which
  // shapes are marked for deletion so multiple delete actions are not dispatched
  const deletedShapesRef = useRef([])

  // Holds the coordinates of the point where dragging started
  const dragStartPosRef = useRef(null)

  // Tracks whether a touch event is a tap or a drag
  const isTap = useRef(false)

  // Tracks whether the mouse is currently down - for desktop compatibility
  const isMouseDown = useRef(false)

  // Tracks distance between touch points when pinching to zoom. Subsequent values are
  // compared to determine if user is zooming in or out
  const lastPinchZoomDistance = useRef(null)

  // state which represents how far in 2D space a shape has been dragged. This is used when
  // a ship is the selected shape, and it is dragged - the rotation control also needs to
  // be updated to follow the ship's position
  const [dragOffset, setDragOffset] = useState([0, 0])

  // state which represents the rotation angle of a ship symbol in the process of
  // being rotated
  const startRotationOffset = useRef(0)
  const lastRotationAngle = useRef(null)
  const [rotationAngle, setRotationAngle] = useState(null)

  const initialZoomDistance = useRef(null)

  // Use a ref to store the last used scale value for each scalable symbol.
  // This allows the user to retain the same scale for like symbols.
  const lastScale = useRef({})
  const [symbolScale, setSymbolScale] = useState(null)

  // For realtime line drawing, we don't use redux store state because the dispatch
  // and data flow-down cycle causes lagging in the UI. Use local state to
  // create the new line segment and update the store at the end.
  const [lineSegment, setLineSegment] = useState([])

  // set minimums on line/arrow lengths
  const minLineLength = 0.01
  const minArrowLength = 0.03

  const [imageUrl, setImageUrl] = useState(null)

  const {
    stageDimensions,
    stageZoom,
    stageOffset,
    actionsStack,
    actionsStackPosition,
    selectedShapeName,
    activeLabelName,
    activeLabelPosition,
    excludedShapes,
  } = state

  const {
    activeSymbol: currentActiveSymbol,
    activeTool: currentActiveTool,
    activeColor,
  } = menuState

  const activeTool = readOnly || isMasterView ? null : currentActiveTool
  const activeSymbol = readOnly || isMasterView ? null : currentActiveSymbol

  // Keep a ref to the displayed image url. This is because if an image has expired but we already downloaded
  // it and have it displayed, we don't want to invalidate it while the user is drawing.
  const displayedImageUrl = useRef(null)
  const chartUuid = useRef()
  useEffect(
    () => {
      // we set only the imageUrl if the chart is changed
      // to avoid the reload of the image on visibility change
      if (
        chart &&
        !chart.error &&
        imageToken &&
        chart.data.uuid !== chartUuid.current
      ) {
        chartUuid.current = chart.data.uuid
        setImageUrl(getChartImageUrl({ ...chart.data }, imageToken, imageType))
      } else if (isBerthingStage) {
        // explicitly use false for no image expected at all
        setImageUrl(false)
        chartUuid.current = null
      }
    },
    [chart, imageToken]
  )

  displayedImageUrl.current = imageUrl

  const chartImage = useChartImage(
    chart ? chart.data : {},
    stageId,
    imageUrl,
    stageDimensions[0],
    stageDimensions[1],
    isMasterView
  )

  const savedShapes = idx(drawingData, d => d.metadata.shapes) || []

  // Only display the shapes if the chart image for the current stage has loaded
  const shapes = useMemo(
    () => {
      if (!chart && !isBerthingStage) {
        return []
      }
      return !chart ||
        chart.error ||
        (chartImage.loaded && chartImage.stageId === stageId)
        ? convertActionsStackToShapes(
            actionsStack,
            actionsStackPosition,
            savedShapes,
            excludedShapes
          )
        : []
    },
    [actionsStack, actionsStackPosition, savedShapes, chartImage, stageId]
  )

  const symbols = useMemo(
    () =>
      shapes.filter(
        shape =>
          shape.type === CHART_SHAPE_TYPE.SYMBOL &&
          knownSymbolVariants.indexOf(shape.attrs.variant) !== -1
      ),
    [shapes]
  )

  // When the drawing image offset changes, update store
  useEffect(
    () => {
      dispatch(setStageOffset(stageId, chartImage.offset))
    },
    [chartImage.offset]
  )

  const onResize = stageId => {
    // Fix for iOS chrome, when backgrounded resize events fire and mess up the view
    if (document.visibilityState !== 'hidden' && containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current
      dispatch(setStageDimensions(stageId, [offsetWidth, offsetHeight]))
    }
  }

  useEffect(
    () => {
      onResize(stageId)
    },
    [stageId, chartImage.loaded]
  )

  // Make sure stage is resized when window resizes or screen orientation changed
  useEffect(
    () => {
      const fn = () => onResize(stageId)
      window.addEventListener('resize', fn)
      window.addEventListener('orientationchange', fn)

      return () => {
        window.removeEventListener('resize', fn)
        window.removeEventListener('orientationChange', fn)
      }
    },
    [stageId]
  )

  const zoom = useCallback(
    (isZoomingIn, isPinchToZoom, zoomCenterPt) => {
      const oldScale = parentRef.current.scaleX()
      const speed = isPinchToZoom
        ? CHART_ZOOM_SPEED_PINCH
        : CHART_ZOOM_SPEED_WHEEL

      const newScale = clamp(
        isZoomingIn ? oldScale * speed : oldScale / speed,
        CHART_MIN_SCALE,
        CHART_MAX_SCALE
      )

      // If the scale changed, update zoom and offset
      if (newScale !== oldScale) {
        const [stageX, stageY] = zoomCenterPt

        const [x, y] = [
          (stageX - parentRef.current.x()) / oldScale,
          (stageY - parentRef.current.y()) / oldScale,
        ]

        const offsetX = -(x - stageX / newScale) * newScale
        const offsetY = -(y - stageY / newScale) * newScale

        dispatch(setStageZoom(stageId, newScale))
        dispatch(setStageOffset(stageId, [offsetX, offsetY]))
      }
    },
    [stageId]
  )

  const onWheel = useCallback(
    e => {
      if (isMasterView) {
        return
      }
      // prevent scroll
      e.evt.preventDefault()
      if (!isBerthingStage) {
        const { x, y } = e.target.getStage().getPointerPosition()
        zoom(e.evt.deltaY < 0, false, [x, y])
      }
    },
    [isBerthingStage, isMasterView, zoom]
  )

  const onChartDragStart = useCallback(
    e => {
      if (isMasterView || (e.evt.touches && e.evt.touches.length > 1)) {
        e.target.stopDrag()
        return
      }
      // If drawing being dragged, set selected shape back to null
      if (e.target.name() === 'root') {
        dispatch(selectShape(stageId, null))
      }
      isTap.current = false
    },
    [dispatch, isTap, isMasterView]
  )

  const onChartDragEnd = e => {
    // If the drawing was dragged, update the stage offset
    if (e.target.name() === 'root') {
      const { x, y } = e.target.getPosition()
      dispatch(setStageOffset(stageId, [x, y]))
    }
  }

  const getNormalisedCoordinates = event => {
    // Convert drawing position coordinates to a normalised set
    // of coordinates in the range [0, 1]
    let { x, y } = event.target.getStage().getPointerPosition()

    // Factor out zoom and offset
    x = (x - stageOffset[0]) / stageZoom
    y = (y - stageOffset[1]) / stageZoom

    return [x / chartImage.dimensions[0], y / chartImage.dimensions[1]]
  }

  const onChartClick = e => {
    const { isSelectable, name, type } = e.target.attrs

    // If selectable shape clicked, select it
    if (isSelectable) {
      if (name !== selectedShapeName) {
        dispatch(selectShape(stageId, name))
      }
      if (type === CHART_SHAPE_TYPE.TEXT) {
        // Enter into edit text mode
        const shape = shapes.find(s => s.name === name)
        let { x, y } = shape.attrs

        // Order is important here - set the active label before the position,
        // otherwise the input renders empty instead of containing the expected
        // default value.
        x =
          x * chartImage.dimensions[0] * stageZoom +
          stageOffset[0] -
          CHART_TEXT_INPUT_WIDTH / 2
        y =
          y * chartImage.dimensions[1] * stageZoom +
          stageOffset[1] -
          CHART_TEXT_INPUT_HEIGHT / 2

        dispatch(displayAddLabel(stageId, name, shape.attrs.text, x, y))
      }
      return
    }

    // If a shape is already selected, deselect it
    if (selectedShapeName) {
      dispatch(selectShape(stageId, null))
      return
    }

    // Otherwise if a symbol item is active in the menu, add a symbol to the drawing,
    // or if the text tool is active, add a label to the drawing.
    if (activeSymbol) {
      const [x, y] = getNormalisedCoordinates(e)
      const { width, height } = getSymbolInstanceRenderData(activeSymbol)

      let scaleX, scaleY
      if (activeSymbol === CHART_SYMBOL_RENDER_DATA.SHIP_MAIN.variant) {
        const [lengthPx, beamPx] = calculateMainVesselDimensions(
          chartImage.scaleFactor || 1,
          getChartScale(chart.data),
          vessel
        )
        scaleX = lengthPx / width
        scaleY = beamPx / height
      } else {
        if (lastScale.current[activeSymbol] !== undefined) {
          scaleX = lastScale.current[activeSymbol]
          scaleY = lastScale.current[activeSymbol]
        } else {
          scaleX = 1
          scaleY = 1
        }
        lastScale.current[activeSymbol] = scaleX
      }

      const attrs = {
        variant: activeSymbol,
        x,
        y,
        color: getToolColor(),
        scale: [scaleX, scaleY],
      }

      if (canRotateSymbol(activeSymbol)) {
        if (lastRotationAngle.current !== null) {
          attrs.rotation = lastRotationAngle.current
        }
      }

      dispatch(
        addStackAction(
          stageId,
          CHART_STACK_ACTION.ADD,
          `${activeSymbol}_${uuidv4()}`,
          CHART_SHAPE_TYPE.SYMBOL,
          attrs
        )
      )
    } else if (activeTool === CHART_TOOL.TEXT) {
      let { x, y } = e.target.getStage().getPointerPosition()
      x = x - CHART_TEXT_INPUT_WIDTH / 2
      y = y - CHART_TEXT_INPUT_HEIGHT / 2
      dispatch(displayAddLabel(stageId, null, null, x, y))
    }
  }

  const onChartTouchStart = e => {
    e.evt.preventDefault()
    setLineSegment([])

    // Don't allow multi touch events if a draw tool is active or it is the berthing stage
    if (
      e.evt.touches &&
      e.evt.touches.length > 1 &&
      (activeTool || isBerthingStage)
    ) {
      return
    }

    if (e.evt.touches && e.evt.touches.length > 1) {
      const pt1 = [e.evt.touches[0].clientX, e.evt.touches[0].clientY]
      const pt2 = [e.evt.touches[1].clientX, e.evt.touches[1].clientY]
      lastPinchZoomDistance.current = distanceBetween(pt1, pt2)
      return
    }

    if (e.target.name() === CANVAS_ELEMENT_NAMES.ROTATE_HANDLE) {
      // shape rotation is starting, initialise start angle
      const selectedShape = shapes.find(s => s.name === selectedShapeName)
      const { x: cx, y: cy, rotation } = selectedShape.attrs
      const existingAngle = rotation || 0
      const currentAngle = calculateRotationAngle(
        getNormalisedCoordinates(e),
        [cx, cy],
        64 / chartImage.dimensions[0]
      )
      // We need to record the angle offset when the drag of the rotate control begins.
      // This is so that no matter what part of the rotate control the drag starts on,
      // it behaves as if the drag started at the center of the rotate control and so
      // the UI experience is smooth.
      const offset = currentAngle - existingAngle
      startRotationOffset.current = currentAngle < 180 ? offset : offset - 360
      setRotationAngle(e.target.attrs.angle || 0)
    } else if (e.target.name() === CANVAS_ELEMENT_NAMES.RESIZE_HANDLE) {
      const selectedShape = shapes.find(s => s.name === selectedShapeName)
      const { x: cx, y: cy, scale } = selectedShape.attrs
      initialZoomDistance.current = distanceBetween(
        [cx, cy],
        getNormalisedCoordinates(e)
      )
      setSymbolScale(scale[0] || 1)
    } else if (
      activeTool === CHART_TOOL.FREEHAND ||
      activeTool === CHART_TOOL.STRAIGHT_LINE ||
      activeTool === CHART_TOOL.ARROW ||
      activeTool === CHART_TOOL.RULER ||
      activeTool === CHART_TOOL.HIGHLIGHTER
    ) {
      setLineSegment([getNormalisedCoordinates(e)])
    } else if (activeTool === CHART_TOOL.ERASER) {
      // erasing is starting
      prevEraserPtRef.current = e.target.getStage().getPointerPosition()
    } else if (activeLabelPosition) {
      dispatch(removeAddLabel(stageId))
    } else {
      dragStartPosRef.current = getNormalisedCoordinates(e)
      isTap.current = true
    }
  }

  const onChartTouchMove = e => {
    e.evt.preventDefault()

    // Don't allow multi touch events if a draw tool is active or it is the berthing stage
    if (
      e.evt.touches &&
      e.evt.touches.length > 1 &&
      (activeTool || isBerthingStage)
    ) {
      return
    }

    // Multi touch gestures are assumed to be pinch to zoom, and only the first
    // two touches are used
    if (e.evt.touches && e.evt.touches.length >= 2) {
      const pt1 = [e.evt.touches[0].clientX, e.evt.touches[0].clientY]
      const pt2 = [e.evt.touches[1].clientX, e.evt.touches[1].clientY]

      const midpoint = [0.5 * (pt1[0] + pt2[0]), 0.5 * (pt1[1] + pt2[1])]
      const d = distanceBetween(pt1, pt2)

      // If distance between the two touch points has increased, user is zooming in,
      // otherwise if it decreased they are zooming out. The point exactly halfway
      // between the two touch points is used as the zoom center point.
      zoom(d > lastPinchZoomDistance.current, true, midpoint)
      return
    }

    if (
      activeTool === CHART_TOOL.FREEHAND ||
      activeTool === CHART_TOOL.HIGHLIGHTER
    ) {
      setLineSegment([...lineSegment, getNormalisedCoordinates(e)])
    } else if (
      activeTool === CHART_TOOL.STRAIGHT_LINE ||
      activeTool === CHART_TOOL.ARROW ||
      activeTool === CHART_TOOL.RULER
    ) {
      if (lineSegment.length > 0) {
        const segment = [lineSegment[0], getNormalisedCoordinates(e)]
        const minLength =
          activeTool === CHART_TOOL.ARROW ? minArrowLength : minLineLength
        if (distanceBetween(segment[0], segment[1]) > minLength) {
          setLineSegment(segment)
        }
      }
    } else if (rotationAngle !== null) {
      const selectedShape = shapes.find(s => s.name === selectedShapeName)
      const { x: cx, y: cy } = selectedShape.attrs

      const angle = calculateRotationAngle(
        getNormalisedCoordinates(e),
        [cx, cy],
        64 / chartImage.dimensions[0]
      )
      // apply the offset angle, to give a smooth user experience
      setRotationAngle((angle - startRotationOffset.current) % 360)
    } else if (symbolScale !== null) {
      const selectedShape = shapes.find(s => s.name === selectedShapeName)
      const {
        x: cx,
        y: cy,
        scale: initialScale,
        rotation,
      } = selectedShape.attrs

      const theta = rotation || 0
      // line gradient is tan(thetaRadians), but remember to invert it
      // (we have a vertically inverted cartesian coordinate system, with (0,0) at the top-left)
      const gradient = -Math.tan((theta * Math.PI) / 180)
      const [x1, y1] = getNormalisedCoordinates(e)
      let intersection

      if (gradient === 0) {
        intersection = [cx, y1]
      } else if (!isFinite(gradient)) {
        intersection = [x1, cy]
      } else {
        intersection = findLineIntersection(
          -1 / gradient,
          cy + cx / gradient,
          gradient,
          y1 - x1 * gradient
        )
      }

      // Apply inverse rotation to pointer and check the x coordinate is on the resize handle
      // side of the perpendicular line going through the center of the symbol.
      // Remember to invert the y-coordinates due to our inverted coordinate system.
      const rotatedPointer = rotatePoint([x1, 1 - y1], [cx, 1 - cy], -theta)
      const isPointerOnResizeSideOfCircle = rotatedPointer[0] < cx

      // If the pointer is on the resize handle side of the circle, get the distance between
      // the pointer and the perpendicular line to use for scaling, otherwise set it to 0
      // so no scaling occurs in the opposite direction, which looks unexpected from a UI
      // perspective.
      const distance = isPointerOnResizeSideOfCircle
        ? distanceBetween([x1, y1], intersection)
        : 0

      // We clamp the symbol scale range so it can't be scaled too big or too small.
      // The minimum scale is the lowest of SYMBOL_MIN_SCALE and the initialScale,
      // similarly the maximum scale is the highest of SYMBOL_MAX_SCALE and initialScale.
      setSymbolScale(
        clamp(
          ((initialScale[0] || 1) * distance) / initialZoomDistance.current,
          Math.min(SYMBOL_MIN_SCALE, initialScale[0] || 1),
          Math.max(SYMBOL_MAX_SCALE, initialScale[0] || 1)
        )
      )
    } else if (activeTool === CHART_TOOL.ERASER) {
      // To check for shapes to erase, we sample points along a straight line
      // between the last 2 eraser points and check if any of them intersect a
      // shape on the drawing. Any intersecting shapes are removed.
      let { x, y } = e.target.getStage().getPointerPosition()
      const numPoints = 10
      const dx = (x - prevEraserPtRef.current.x) / numPoints
      const dy = (y - prevEraserPtRef.current.y) / numPoints

      for (let i = 0; i <= numPoints; i++) {
        const intersection = e.target
          .getStage()
          .getIntersection({ x: x - dx * i, y: y - dy * i })

        const isIntersecting =
          intersection && intersection.name() && intersection.name() !== 'chart'

        if (
          isIntersecting &&
          deletedShapesRef.current.indexOf(intersection.name()) === -1
        ) {
          const name = intersection.name()
          deletedShapesRef.current.push(name)
          dispatch(
            addStackAction(stageId, CHART_STACK_ACTION.DELETE, name, null, null)
          )
          break
        }
      }
      prevEraserPtRef.current = { x, y }
    }
  }

  // If the active tool is the highlighter, we only allow this to be in yellow
  // currently, so override the active color option.
  const getToolColor = () =>
    activeTool === CHART_TOOL.HIGHLIGHTER
      ? 'rgba(255, 255, 0, 0.5)'
      : activeColor

  const finishLine = (points = lineSegment) => {
    isMouseDown.current = false
    if (points.length > 1) {
      dispatch(
        addStackAction(
          stageId,
          CHART_STACK_ACTION.ADD,
          `${activeTool}_${uuidv4()}`,
          activeTool,
          {
            points,
            // For now highlighter has one fixed colour
            color: getToolColor(),
            version: 1,
          }
        )
      )

      setLineSegment([])
    }
  }

  const finishRotation = () => {
    // rotation has finished, dispatch to update store
    dispatch(
      addStackAction(
        stageId,
        CHART_STACK_ACTION.MODIFY,
        selectedShapeName,
        null,
        {
          rotation: rotationAngle,
        }
      )
    )
    lastRotationAngle.current = rotationAngle
    setRotationAngle(null)
  }

  const finishScaling = () => {
    // resize has finished, dispatch to update store
    dispatch(
      addStackAction(
        stageId,
        CHART_STACK_ACTION.MODIFY,
        selectedShapeName,
        null,
        {
          scale: [symbolScale, symbolScale],
        }
      )
    )
    lastScale.current[activeSymbol] = symbolScale
    setSymbolScale(null)
  }

  const onChartTouchEnd = e => {
    e.evt.preventDefault()

    // Block multi touch events (zoom) if a draw tool is active or it is the berthing stage
    if (
      e.evt.touches &&
      e.evt.touches.length > 1 &&
      (activeTool || isBerthingStage)
    ) {
      return
    }

    if (
      activeTool === CHART_TOOL.FREEHAND ||
      activeTool === CHART_TOOL.HIGHLIGHTER ||
      activeTool === CHART_TOOL.STRAIGHT_LINE ||
      activeTool === CHART_TOOL.ARROW ||
      activeTool === CHART_TOOL.RULER
    ) {
      if (lineSegment.length > 0) {
        const points =
          activeTool === CHART_TOOL.FREEHAND ||
          activeTool === CHART_TOOL.HIGHLIGHTER
            ? [...lineSegment, getNormalisedCoordinates(e)]
            : [lineSegment[0], getNormalisedCoordinates(e)]

        const minLength =
          activeTool === CHART_TOOL.ARROW ? minArrowLength : minLineLength
        const addLine =
          points.length > 2 || distanceBetween(points[0], points[1]) > minLength
        if (addLine) {
          finishLine(points)
        } else {
          setLineSegment([])
        }
      }
    } else if (rotationAngle !== null) {
      finishRotation()
    } else if (symbolScale !== null) {
      finishScaling()
    } else if (activeTool === CHART_TOOL.ERASER) {
      // erasing is finished
      prevEraserPtRef.current = null
      deletedShapesRef.current = []
    } else if (isTap.current) {
      // user tapped the drawing, delegate to the click handler
      onChartClick(e)
      isTap.current = false
    }
  }

  // Mouse event handlers, so that the drawing functionality also
  // works on systems with a mouse instead of touch screen.
  const onChartMouseDown = e => {
    isMouseDown.current = true
    onChartTouchStart(e)
  }

  const onChartMouseMove = e => {
    if (isMouseDown.current) {
      onChartTouchMove(e)
    }
  }

  const onChartMouseUp = e => {
    isMouseDown.current = false
    onChartTouchEnd(e)
  }

  const onShapeDragStart = e => {
    if (e.target.name() !== selectedShapeName) {
      dispatch(selectShape(stageId, e.target.name()))
    }
  }

  const onShapeDragMove = e => {
    const coords = getNormalisedCoordinates(e)
    const offset = [
      coords[0] - dragStartPosRef.current[0],
      coords[1] - dragStartPosRef.current[1],
    ]
    setDragOffset(offset)
  }

  const onShapeDragEnd = e => {
    const { x, y } = e.target.getPosition()
    dispatch(
      addStackAction(
        stageId,
        CHART_STACK_ACTION.MODIFY,
        e.target.name(),
        null,
        {
          x:
            (x - (isBerthingStage ? chartImage.offset[0] : 0)) /
            chartImage.dimensions[0],
          y:
            (y - (isBerthingStage ? chartImage.offset[1] : 0)) /
            chartImage.dimensions[1],
        }
      )
    )
    setDragOffset([0, 0])
  }

  const createTextLabel = ({ top, left, value: text }) => {
    cancelTextLabel()

    if (activeLabelName) {
      dispatch(
        addStackAction(
          stageId,
          CHART_STACK_ACTION.MODIFY,
          activeLabelName,
          null,
          {
            text,
          }
        )
      )
    } else {
      // Offset the text label so it appears centered where the user clicked
      const x =
        (left + CHART_TEXT_INPUT_WIDTH / 2 - stageOffset[0]) /
        stageZoom /
        chartImage.dimensions[0]
      const y =
        (top + CHART_TEXT_INPUT_HEIGHT / 2 - stageOffset[1]) /
        stageZoom /
        chartImage.dimensions[1]

      dispatch(
        addStackAction(
          stageId,
          CHART_STACK_ACTION.ADD,
          `text_${uuidv4()}`,
          CHART_SHAPE_TYPE.TEXT,
          {
            x,
            y,
            color: getToolColor(),
            text,
            version: 1, // allows font scaling based on image
          }
        )
      )
    }
  }

  const cancelTextLabel = useCallback(
    () => {
      dispatch(removeAddLabel(stageId))
    },
    [dispatch, stageId]
  )

  const isDraggable = (blockReadOnlyDragging = true) =>
    (!(readOnly || isMasterView) || !blockReadOnlyDragging) &&
    activeTool !== CHART_TOOL.FREEHAND &&
    activeTool !== CHART_TOOL.HIGHLIGHTER &&
    activeTool !== CHART_TOOL.STRAIGHT_LINE &&
    activeTool !== CHART_TOOL.ARROW &&
    activeTool !== CHART_TOOL.RULER &&
    activeTool !== CHART_TOOL.ERASER &&
    rotationAngle === null &&
    symbolScale === null &&
    !activeLabelPosition

  const selectedSymbol = useMemo(
    () =>
      selectedShapeName && !readOnly && !isMasterView
        ? symbols.find(s => s.name === selectedShapeName)
        : null,
    [symbols, selectedShapeName]
  )

  const unselectedSymbols = useMemo(
    () =>
      selectedSymbol ? symbols.filter(s => s !== selectedSymbol) : symbols,
    [symbols, selectedSymbol]
  )

  const symbolsSorted = selectedSymbol
    ? [...unselectedSymbols, selectedSymbol]
    : symbols

  const labels = useMemo(
    () => shapes.filter(shape => shape.type === CHART_SHAPE_TYPE.TEXT),
    [shapes]
  )

  const getLinesOfType = type => {
    const lines = shapes.filter(shape => shape.type === type)

    // Make freehand line segment display on drawing live as it's drawn
    if (type === activeTool && lineSegment && lineSegment.length > 1) {
      lines.push({
        name: `${type}_active`,
        attrs: {
          color: getToolColor(),
          points: lineSegment,
          version: 1, // From tool version 1, strokeWidth scaling based on image width is applied
        },
      })
    }

    return lines
  }

  const freehandLines = useMemo(() => getLinesOfType(CHART_TOOL.FREEHAND), [
    shapes,
    lineSegment,
  ])

  const highlights = useMemo(() => getLinesOfType(CHART_TOOL.HIGHLIGHTER), [
    shapes,
    lineSegment,
  ])

  const straightLines = useMemo(
    () => getLinesOfType(CHART_TOOL.STRAIGHT_LINE),
    [shapes, lineSegment]
  )

  const arrows = useMemo(() => getLinesOfType(CHART_TOOL.ARROW), [
    shapes,
    lineSegment,
  ])

  const rulers = useMemo(() => getLinesOfType(CHART_TOOL.RULER), [
    shapes,
    lineSegment,
  ])

  const finishLineExisting = () => finishLine()

  // When the container is defocused (mouseout, touchend), make sure to finish any
  // actions which involve dragging across the chart. This prevents any weird
  // behaviour occuring for example when the user draws a line and goes off the
  // edge of the screen then interacts again.
  const containerDefocus = () => {
    finishLineExisting()
    if (symbolScale !== null) {
      finishScaling()
    }
    if (rotationAngle !== null) {
      finishRotation()
    }
  }

  const rotateBerthingStageVessel = () => {
    dispatch(
      addStackAction(
        stageId,
        CHART_STACK_ACTION.ROTATE_BERTHING_STAGE_VESSEL,
        null,
        null,
        { angle: (berthingVesselAngle + 45) % 360 }
      )
    )
  }

  const berthingVesselAngle = useMemo(
    () =>
      isBerthingStage
        ? getVesselRotation(
            actionsStack,
            actionsStackPosition,
            idx(drawingData, d => d.metadata.vesselRotation) || 0
          )
        : 0,
    [actionsStack, actionsStackPosition, isBerthingStage, drawingData]
  )

  const data = {
    shapes,
    berthingVesselAngle,
    chartImage,
    symbols: symbolsSorted,
    labels,
    freehandLines,
    highlights,
    straightLines,
    arrows,
    rulers,
    selectedSymbol,
    rotationAngle,
    symbolScale,
    dragOffset,
  }

  const events = {
    onWheel,
    onChartDragStart,
    onChartDragEnd,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartTouchStart,
    onChartTouchMove,
    onChartTouchEnd,
    containerDefocus,
    isDraggable,
    onShapeDragStart,
    onShapeDragMove,
    onShapeDragEnd,
  }

  const actions = {
    createTextLabel,
    cancelTextLabel,
    finishLineExisting,
    rotateBerthingStageVessel,
  }

  return { data, events, actions }
}

export default useCanvas
