import React from 'react'
import styled, { css } from 'styled-components'
import { TextField } from '@material-ui/core'

const FreeTextField = styled(TextField).attrs({
  multiline: true,
  rows: 4,
  rowsMax: 10,
})(
  ({ theme, disabled }) => css`
    && {
      background-color: ${theme.palette.background.dark};
      box-sizing: border-box;
      padding: ${theme.spacing(2)}px;
      border-radius: ${theme.spacing(1)}px;
      border: 1px solid
        ${disabled ? theme.palette.disabled.main : theme.palette.primary.main};

      & .Mui-disabled {
        color: inherit;
        -webkit-text-fill-color: inherit;
        opacity: 1;
      }
    }
  `
)

export default React.memo(FreeTextField)
