import idx from 'idx'

import api, { getSinglePayloadFromResponse } from 'src/utils/api/core'

export const uploadAttachment = async (pilotageUuid, attachmentType, file) => {
  const data = new FormData()
  data.append('name', file.name)
  data.append('file', file)
  data.append('attachmentType', attachmentType)
  const response = await api.post(
    `/pilotages/${pilotageUuid}/attachment`,
    data,
    {
      timeout: 0,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  const pilotage = getSinglePayloadFromResponse(response)
  return idx(pilotage, _ => _.attachments) || []
}

export const deleteAttachment = async (pilotageUuid, attachment) => {
  const response = await api.delete(`pilotages/${pilotageUuid}/attachment`, {
    data: attachment,
  })
  const pilotage = getSinglePayloadFromResponse(response)
  return idx(pilotage, _ => _.attachments) || []
}
