// Put reusuable proptypes here

import PropTypes from 'prop-types'

export const PilotageSummaryPropType = PropTypes.shape({
  dateObj: PropTypes.instanceOf(Date),
  dateStr: PropTypes.string,
  timeStr: PropTypes.string,
  vessel: PropTypes.string,
  movementType: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  pilot: PropTypes.string,
  secondaryPilot: PropTypes.string,
  maxDraft: PropTypes.string,
  status: PropTypes.string,
  raw: PropTypes.object
})
