import { encrypt, decrypt } from './crypto'

// REFRESH TOKEN
export const setRefreshTokenToStorage = refreshToken => {
  localStorage.setItem('refreshToken', encrypt(refreshToken))
}

export const getRefreshTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('refreshToken')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeRefreshTokenFromStorage = () => {
  localStorage.removeItem('refreshToken')
}

// IMAGE TOKEN
export const setImageTokenToStorage = token => {
  localStorage.setItem('imageToken', encrypt(token))
}

export const getImageTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('imageToken')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

// SESSION TOKEN
export const setTokenToStorage = token => {
  localStorage.setItem('token', encrypt(token))
}

export const getTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('token')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeTokenFromStorage = () => {
  localStorage.removeItem('token')
}

// USER DATA
export const setUserToStorage = user => {
  localStorage.setItem('user', encrypt(JSON.stringify(user)))
}

export const getUserFromStorage = () => {
  try {
    const encryptedToken = localStorage.getItem('user')
    if (encryptedToken) {
      return JSON.parse(decrypt(encryptedToken))
    }
  } catch {}
  return null
}

export const removeUserFromStorage = () => {
  localStorage.removeItem('user')
}

// ROLES DATA
export const removeRolesFromStorage = () => {
  localStorage.removeItem('roles')
}

export const setRolesToStorage = roles => {
  localStorage.setItem('roles', encrypt(JSON.stringify(roles)))
}

export const getRolesFromStorage = () => {
  try {
    const encryptedToken = localStorage.getItem('roles')
    if (encryptedToken) {
      return JSON.parse(decrypt(encryptedToken))
    }
  } catch {
    return []
  }
  return []
}

// TODO: remove when updated to save overrides to api
export const removeTideOverridesFromStorage = () => {
  localStorage.removeItem('tide_overrides')
}

export const clearLocalStorage = () => {
  removeUserFromStorage()
  removeTokenFromStorage()
  removeRefreshTokenFromStorage()
  removeTideOverridesFromStorage()
  localStorage.removeItem('loggedInEmail')
}
