import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box, Divider } from '@material-ui/core'
import idx from 'idx'

import {
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  isPrimaryPilotForActivePilotageSelector,
  pilotageSelector,
  pilotageUuidSelector,
  pilotageRouteChecksSelector,
} from 'src/store/pilotage/selectors'
import { formatPilotageData, formatUsername, formatIfNumber } from 'src/utils/formatters'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import PilotageSignOff from 'src/components/molecules/PilotageSignOff'
import { preferencesRequest } from 'src/store/preferences/actions'
import { pilotageUpdateRequest } from 'src/store/pilotage/actions'
import { pilotageExtrasRequest } from 'src/store/pilotageExtras/actions'
import { pilotageSignatureCreate, pilotageSignaturesRequest } from 'src/store/pilotageSignature/actions'
import { timezoneSelector } from 'src/store/ports/selectors'
import SubHeading from 'src/components/atoms/SubHeading'
import PortExtra from 'src/components/atoms/PortExtra/PortExtra'
import { guidelinesSelector, termsSelector } from 'src/store/extras/selectors'
import { featureFlagUseESignatures, featureFlagUseBerthingConditions } from 'src/store/preferences/selectors'
import { pilotageSignatureSelector } from 'src/store/pilotageSignature/selectors'
import { CONSTRAINT_STATUS, SIGNING_USER, MOVEMENT_TYPE } from 'src/utils/constants'
import { vesselSelector } from 'src/store/vessel/selectors'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'
import { routeSelector } from 'src/store/route/selectors'
import { pilotageTugsSelector } from 'src/store/pilotageTugs/selectors'
import { actions as routerActions } from 'redux-router5'
import {
  PILOTAGE_PLAN,
  PILOTAGE_RISKS,
} from 'src/router/routes'
import getRouteConstraints from 'src/utils/getRouteConstraints'
import useTideDataLoader from 'src/hooks/useTideDataLoader'
import useRiskAssessment from 'src/hooks/useRiskAssessment'
import { derivedMaxDraftSelector } from './../../store/pilotage/selectors'
import { getDepartingForPilotage } from 'src/store/pilotageList/selectors'
import useTideExtremes from 'src/hooks/useTideExtremes'
import { tidesSelector } from 'src/store/tides/selectors'
import { useBerthingCalculation } from 'src/components/organisms/BerthingConditions/useBerthingCalculation'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import SummaryPlanInfoBox from 'src/components/molecules/SummaryPlanInfoBox'
import { useFlowlist } from './../../hooks/useFlowlist'
import { PILOTAGE_TUGS } from './../../router/routes'

const PilotageAcceptance = () => {
  const dispatch = useDispatch()
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const timezone = useSelector(timezoneSelector)
  const portGuidelines = useSelector(guidelinesSelector)
  const portTerms = useSelector(termsSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const isEndState = isPilotageDone || isPilotageArchived || isPilotageCancelled
  const useESignatures = useSelector(featureFlagUseESignatures)
  const { signatures } = useSelector(pilotageSignatureSelector(pilotageUuid))

  const isPrimaryPilot = useSelector(isPrimaryPilotForActivePilotageSelector)
  const { data: pilotageRawData, isLoading } = useSelector(pilotageSelector)
  const pilotageData = pilotageRawData
    ? formatPilotageData(pilotageRawData, timezone)
    : null

  const pilotName =
    (pilotageRawData ? formatUsername(pilotageRawData.pilot) : null) || ''
  const pilotAccepted = idx(pilotageRawData, d => d.pilotAccepted)
  const masterAccepted = idx(pilotageRawData, d => d.masterAccepted)
  const masterName = idx(pilotageRawData, d => d.masterName)

  const {
    data: vesselData,
  } = useSelector(vesselSelector)
  let vesselMasterName
  if (vesselData && vesselData.metadata && vesselData.metadata.master_name) {
    vesselMasterName = vesselData.metadata.master_name
  }
  useEffect(
    () => {
      if (pilotageUuid) {
        dispatch(pilotageExtrasRequest(pilotageUuid))
        dispatch(pilotageSignaturesRequest(pilotageUuid))
        dispatch(preferencesRequest())
      }
    },
    [pilotageUuid]
  )

  const pilotSignOff = async ({ signatureContent }) => {

    if (useESignatures) {
      const signed = await dispatch(
        pilotageSignatureCreate(pilotageUuid, {
          image: signatureContent,
          role: SIGNING_USER.PILOT
        })
      )
      if (!signed) { return  } // don't proceed to update if signature failed
    }

    dispatch(
      pilotageUpdateRequest(
        pilotageUuid,
        {
          pilotAccepted: true,
        },
        false,
        false,
        false
      )
    )
  }
  const masterSignOff = async ({ nameContent, signatureContent }) => {

    if (useESignatures) {
      const signed = await dispatch(
        pilotageSignatureCreate(pilotageUuid, {
          image: signatureContent,
          role: SIGNING_USER.MASTER,
          name: nameContent
        })
      )
      if (!signed) { return  } // don't proceed to update if signature failed
    }

    dispatch(
      pilotageUpdateRequest(
        pilotageUuid,
        {
          masterAccepted: true,
          masterName: nameContent
        },
        false,
        false,
        false
      )
    )
  }

  const pilotageRoute = useSelector(routeSelector)
  const { data: pilotageTugs } = useSelector(pilotageTugsSelector)

  const numberOfTugs = pilotageTugs
    ? Object.keys(pilotageTugs || {}).length
    : null
  const numberOfStages = idx(pilotageRoute, r => r.stages.length)

  const goToRisks = () => {
    dispatch(
      routerActions.navigateTo(
        PILOTAGE_RISKS,
        { id: pilotageUuid },
        { replace: true }
      )
    )
  }

  const goToPlan = () => {
    dispatch(
      routerActions.navigateTo(
        PILOTAGE_PLAN,
        { id: pilotageUuid },
        { replace: true }
      )
    )
  }

  // Need useMemo here to prevent infinite tide data requests
  const routeConstraints = useMemo(() => getRouteConstraints(pilotageRoute), [
    pilotageRoute,
  ])

  useTideDataLoader(routeConstraints)

  const riskAssessment = useRiskAssessment(routeConstraints)

  // EMPX-573 include berthing conditions
  const berthingConditionsEnabled = useSelector(featureFlagUseBerthingConditions)
  const maxDraft = useSelector(derivedMaxDraftSelector)
  const timeZone = useSelector(timezoneSelector)
  const pilotage = pilotageRawData
  const departingPilotage = useSelector(getDepartingForPilotage(pilotage))
  const tideExtremes = useTideExtremes()
  const tides = useSelector(tidesSelector) || {}
  const routeChecks = useSelector(pilotageRouteChecksSelector)

  const riskAssessmentWithoutWaypoints = riskAssessment.filter(risk => !risk.routeConstraint.constraint.isWaypoint)
  const riskAssessmentActiveOnly =
    riskAssessmentWithoutWaypoints.filter(risk => formatIfNumber(risk.overrides.hideConstraint, 0, '0') !== '1')

  const berthingCalculation = useBerthingCalculation({
    pilotage,
    departingPilotage,
    timeZone,
    riskAssessment: riskAssessmentActiveOnly,
    tides,
    tideExtremes,
    maxDraft,
    routeChecks
  })

  let berthingConditionsFailed = false
  let berthingConditionsPassed = false

  if (berthingConditionsEnabled &&
    pilotage &&
    riskAssessmentActiveOnly &&
    maxDraft) {
    if (pilotage.movementType !== MOVEMENT_TYPE.DEPARTURE) {
      if (berthingCalculation && berthingCalculation.type === 'success') {
        berthingConditionsFailed = !berthingCalculation.result.passed
        berthingConditionsPassed = !berthingConditionsFailed
      } else {
        // berthingCalculation.type === failed
        // in this case, leave berthingConditionsFailed and berthingConditionsPassed to be false
        // to indicate the condition cannot be calculated
      }
    } else {
      berthingConditionsPassed = true
    }
  }

  let isRisksDataLoaded =
    pilotageRawData &&
    pilotageRoute &&
    riskAssessmentActiveOnly.filter(item => item.isLoading).length === 0

  const allConstraintsPassed =
    isRisksDataLoaded &&
    riskAssessmentActiveOnly.filter(
      item => item.constraintStatus === CONSTRAINT_STATUS.PASSED
    ).length === riskAssessmentActiveOnly.length

  const anyConstraintsFailed =
    isRisksDataLoaded &&
    riskAssessmentActiveOnly.filter(
      item => item.constraintStatus === CONSTRAINT_STATUS.FAILED
    ).length > 0

  const anyConstraintsOrBerthingConditionsFailed = anyConstraintsFailed || berthingConditionsFailed
  const allConstraintsOrBerthingConditionsPassed = allConstraintsPassed && berthingConditionsPassed

  let failedMsg
  if (anyConstraintsFailed && berthingConditionsFailed) {
    failedMsg = `One or more constraints and berthing conditions are not met.`
  } else if (!anyConstraintsFailed && berthingConditionsFailed) {
    failedMsg = `Berthing conditions are not met.`
  } else if (anyConstraintsFailed && !berthingConditionsFailed) {
    failedMsg = `One or more constraints are not met.`
  } else if (!anyConstraintsFailed && !berthingConditionsFailed) {
    // noop
  }

  const RisksIcon = allConstraintsOrBerthingConditionsPassed
    ? TickIcon
    : anyConstraintsOrBerthingConditionsFailed
      ? CrossIcon
      : null

  const iconsOrder = useFlowlist()

  const isIconVisible = (routeName) => {
    for (let i = 0; i < iconsOrder.length; i++) {
      const icon = iconsOrder[i]
      if (icon.routeName === routeName && icon.isShown) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      {isIconVisible(PILOTAGE_RISKS) &&
        <InfoBox
          title="Risks & Conditions"
          buttonText='Details'
          onButtonClick={goToRisks}
          Icon={RisksIcon}
        >
          {!isRisksDataLoaded && 'Calculating risks...'}
          {allConstraintsOrBerthingConditionsPassed &&
            'All risks and conditions have passed. Proceed with caution.'}
          {anyConstraintsOrBerthingConditionsFailed && failedMsg}
          {isRisksDataLoaded &&
            !allConstraintsOrBerthingConditionsPassed &&
            !anyConstraintsOrBerthingConditionsFailed &&
            'One or more risks could not be calculated.'}
        </InfoBox>
      }
      {isIconVisible(PILOTAGE_PLAN) &&
        <SummaryPlanInfoBox
          onDetailsClick={goToPlan}
          showTugsInfo={isIconVisible(PILOTAGE_TUGS)}
          numberOfTugs={numberOfTugs}
          numberOfStages={numberOfStages}
        />
      }
      {(isIconVisible(PILOTAGE_RISKS) || isIconVisible(PILOTAGE_PLAN)) &&
        <Divider />
      }
      {portGuidelines && portGuidelines.metadata.value && (
        <>
          <PortExtra
            heading="Port Guidelines"
            content={portGuidelines.metadata.value}
          />
          <Divider />
        </>
      )}
      {portTerms && portTerms.metadata.value && (
        <>
          <PortExtra
            heading="Port Terms &amp; Conditions"
            content={portTerms.metadata.value}
          />
          <Divider />
        </>
      )}
      <Typography variant="h2">MPX Acceptance</Typography>
      <SubHeading>
        Once agreement has been reached, sign off the MPX with the Master and
        Pilots acceptance.
      </SubHeading>
      <InfoBox mt={3} extraPadding>
        <Box display="flex" justifyContent="center">
          <PilotageSignOff
            isMaster={false}
            name={pilotName}
            isSignedOff={pilotAccepted}
            onSignOff={pilotSignOff}
            isEndState={isEndState}
            readOnly={
              !isPrimaryPilot ||
              pilotAccepted ||
              isPilotageDone ||
              isPilotageArchived ||
              isPilotageCancelled
            }
            isLoading={isLoading}
            useESignatures={useESignatures}
            signatures={
              signatures.filter(({ role }) =>
                role === SIGNING_USER.PILOT
              )
            }
          />
          <PilotageSignOff
            isMaster
            name={masterName || vesselMasterName}
            boxProps={{ ml: 3 }}
            isSignedOff={masterAccepted}
            onSignOff={masterSignOff}
            isEndState={isEndState}
            readOnly={
              !isPrimaryPilot ||
              masterAccepted ||
              isPilotageDone ||
              isPilotageArchived ||
              isPilotageCancelled
            }
            isLoading={isLoading}
            useESignatures={useESignatures}
            signatures={
              signatures.filter(({ role }) =>
                role === SIGNING_USER.MASTER
              )
            }
          />
        </Box>
      </InfoBox>
    </>
  )
}

export default PilotageAcceptance
