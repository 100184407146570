import {
  PILOTAGE_THREAD_IN_PROGRESS,
  PILOTAGE_THREAD_ERROR,
  PILOTAGE_THREAD_SUCCESS,
  PILOTAGE_THREAD_ADD_COMMENT_IN_PROGRESS,
  PILOTAGE_THREAD_ADD_COMMENT_ERROR,
  PILOTAGE_THREAD_ADD_COMMENT_SUCCESS,
} from 'src/store/pilotageThread/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  isReplyLoading: false,
  isInitialFetchDone: false,
  error: null,
}

function pilotageThreadReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case PILOTAGE_THREAD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PILOTAGE_THREAD_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
        isInitialFetchDone: true,
      }
    case PILOTAGE_THREAD_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
        isInitialFetchDone: true,
      }
    case PILOTAGE_THREAD_ADD_COMMENT_IN_PROGRESS:
      return {
        ...state,
        isReplyLoading: true,
      }
    case PILOTAGE_THREAD_ADD_COMMENT_ERROR:
      return {
        ...state,
        isReplyLoading: false,
      }
    case PILOTAGE_THREAD_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        data: payload,
        isReplyLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default pilotageThreadReducer
