import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

const PrimaryButton = ({ children, ...props }) => (
  <Button variant="contained" color="primary" {...props}>
    {children}
  </Button>
)

PrimaryButton.propTypes = {
  children: PropTypes.node,
}

export const WidePrimaryButton = styled(PrimaryButton)`
  width: 200px;
`

export default React.memo(PrimaryButton)
