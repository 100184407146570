import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatToTimeZone } from 'date-fns-timezone'

import { tideRatesRequest } from 'src/store/tideRates/actions'
import { pilotageStartTimeSelector } from 'src/store/pilotage/selectors'
import { tideRatesSelector } from 'src/store/tideRates/selectors'
import { findTideRateAtTime } from 'src/utils/findTideAtTime'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { OFFLINE_TIDES_DAYS_TO_PREFETCH } from 'src/utils/constants'
import useRiskAssessment from 'src/hooks/useRiskAssessment'

const useTideDataLoader = routeConstraints => { // only load if tide rate source is csv
  const pilotageStartTime = useSelector(pilotageStartTimeSelector)
  const tideRates = useSelector(tideRatesSelector)
  const dispatch = useDispatch()
  const isOnline = useSelector(isOnlineSelector)
  const riskAssessment = useRiskAssessment(routeConstraints)

  useEffect(
    () => {
      if (pilotageStartTime && isOnline) {
        const tideRatesTimeRangeToFetch = {}

        // EMPX-322: Cannot use convertToTimeZone here, need Date to keep epoch unchanged
        // for formatToTimeZone to work correctly below.
        // fetch data in the range [T - 6h, T + 24h]
        const fromDate = new Date(pilotageStartTime)
        // fromDate.setHours(fromDate.getHours() - 6)
        // EMPX-79: make the above consistent with offline prefetch tideRates.
        // Tidy up comment once things settle.
        fromDate.setDate(fromDate.getDate() - 1)
        // EMPX-322: Cannot use convertToTimeZone here, need Date to keep epoch unchanged
        // for formatToTimeZone to work correctly below.
        const toDate = new Date(pilotageStartTime)
        // toDate.setDate(toDate.getDate() + 1)
        // EMPX-79: make the above consistent with offline prefetch tideRates - 1.
        // Tidy up comment once things settle.
        toDate.setDate(toDate.getDate() + OFFLINE_TIDES_DAYS_TO_PREFETCH - 1)

        routeConstraints.forEach(routeConstraint => {

          const { tideRateStation } = routeConstraint.constraint

          if (!tideRateStation || tideRateStation.uuid === null) {
            return
          }

          const risk = riskAssessment.find(item =>
            item.routeConstraint.uuid === routeConstraint.uuid
          )
          if (!risk) {
            return
          }

          let tideRateData

          if (tideRates[tideRateStation.uuid] && tideRates[tideRateStation.uuid].data) {
            // EMPX-322: Cannot use convertToTimeZone here, need Date to keep epoch unchanged
            // for formatToTimeZone to work correctly below.
            const timeAtConstraint = new Date(pilotageStartTime)
            timeAtConstraint.setMinutes(
              timeAtConstraint.getMinutes() + risk.cumulativeTime
            )
            tideRateData = findTideRateAtTime(
              timeAtConstraint,
              tideRates[tideRateStation.uuid].data
            )
          }

          if (!tideRateData) {
            const dataForTideRateStation = tideRatesTimeRangeToFetch[tideRateStation.uuid]
            tideRatesTimeRangeToFetch[tideRateStation.uuid] = {
              fromDate:
                !dataForTideRateStation || dataForTideRateStation.fromDate > fromDate
                  ? fromDate
                  : dataForTideRateStation.fromDate,
              toDate:
                !dataForTideRateStation || dataForTideRateStation.toDate < toDate
                  ? toDate
                  : dataForTideRateStation.toDate,
            }
          }
        })

        Object.keys(tideRatesTimeRangeToFetch).forEach(tideRateStationUuid => {
          const { fromDate, toDate } = tideRatesTimeRangeToFetch[tideRateStationUuid]
          dispatch(
            tideRatesRequest(
              tideRateStationUuid,
              // Use normal format function, not formatToTimeZone, because the dates
              // have already been converted to the correct timezone above.
              // EMPX-79: BE only exptects date, and will append times accordingly
              // so just YYYY-MM-DD without time is required.
              // EMPX-322: BE changed to store TZ as true UTC
              formatToTimeZone(fromDate, 'YYYY-MM-DD', { timeZone: 'UTC' }),
              formatToTimeZone(toDate, 'YYYY-MM-DD', { timeZone: 'UTC' })
            )
          )
        })
      }
    },
    [routeConstraints, pilotageStartTime]
  )
}

export default useTideDataLoader
