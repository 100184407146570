import styled from 'styled-components'

export const CanvasContainer = styled.div`
  height: 100%;
  background-color: rgb(32, 41, 60);
  position: relative;
  overflow: hidden;
  touch-action: pinch-zoom;
`
export const UndoRedoWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  right: ${({ theme }) => theme.spacing(3)}px;
  z-index: 2;
  touch-action: manipulation;

  & button {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const BackSaveWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)}px;
  left: ${({ theme }) => theme.spacing(2)}px;
  z-index: 5;
  touch-action: manipulation;

  & button {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`
export const MessageWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px 40px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  font-weight: 400;
`
export const MessageBackdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
