import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import {
  DarkBox,
  FieldLabel,
  CalculationDivider,
  HighlightedCalculationDivider,
} from 'src/components/organisms/ConstraintDetails/common'
import TextInput from 'src/components/atoms/TextInput'
import { numberOrMissing, formatIfNumber } from 'src/utils/formatters'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import { CONSTRAINT_STATUS } from 'src/utils/constants'
import Tooltip from 'src/components/atoms/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'
import { isNumbersNotMatch } from './../../../utils/formatters'

const ConstraintDetailsUkc = ({ riskAssessment, readOnly }) => {
  const {
    routeConstraint,
    constraintStatus,
    calculation,
    overrides,
    tide,
    tideRaw,
    allowances,
    residual,
    setOverride,
    time,
    value,
    valueRaw,
  } = riskAssessment

  const { constraint } = routeConstraint

  const tideRef = useRef(null)
  useEffect(() => {
    if (tideRef.current) {
      tideRef.current.value = formatIfNumber(overrides.tideOverrideValue, 2,
        formatIfNumber(tide, 2, '')
      )
    }
  }, [time, overrides.time])

  let Icon = null
  if (constraintStatus === CONSTRAINT_STATUS.PASSED) {
    Icon = TickIcon
  } else if (constraintStatus === CONSTRAINT_STATUS.FAILED) {
    Icon = CrossIcon
  }

  let overrideColourSafetyMargin = isNumbersNotMatch(calculation.variables.calculatedSafetyMargin,
    calculation.variables.calculatedSafetyMarginRaw, 2)
  let overrideColourTide = isNumbersNotMatch(tide, tideRaw, 2)
  let overrideColourValue = isNumbersNotMatch(value, valueRaw, 2)

  return (
    <>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Min. Available Depth</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              {
                constraint.valueOverridable ?
                <TextInput
                  unit="m"
                  readOnly={readOnly}
                  placeholder='0.00'
                  overrideColour={overrideColourValue}
                  defaultValue={formatIfNumber(overrides.value, 2,
                    formatIfNumber(value, 2, '')
                  )}
                  onChange={setOverride.value}
                  onBlur={({ target }) => {
                    const formatted = formatIfNumber(target.value, 2,
                      formatIfNumber(value, 2, '')
                    )
                    setOverride.value(formatted)
                    target.value = formatted
                  }}
                  inputProps={{ inputMode: 'decimal' }}
                />
                :
                <TextInput
                  value={numberOrMissing(value, null, 2)}
                  readOnly
                  unit="m"
                />
              }
            </Box>
          </Box>
        </Box>
        <Box flex={1} ml={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Maximum Draft</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(calculation.variables.maxDraft, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Predicted Tide (+)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourTide}
                defaultValue={
                  formatIfNumber(overrides.tideOverrideValue, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }
                onChange={setOverride.tideOverrideValue}
                onBlur={({ target }) => {
                  setOverride.tideOverrideValue(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }}
                inputRef={tideRef}
                inputProps={{
                  inputMode: 'decimal'
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="50%" ml="auto" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>
                Safety Margin
                {calculation.variables.unitUsed === '%'
                  ? ` (+${routeConstraint.constraint.safetyMarginPct}%)`
                  : ' (+)'}
              </FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourSafetyMargin}
                defaultValue={formatIfNumber(overrides.safetyMargin, 2,
                  formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                )}
                onChange={setOverride.safetyMargin}
                onBlur={({ target }) => {
                  const formatted = formatIfNumber(target.value, 2,
                    formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                  )
                  setOverride.safetyMargin(formatted)
                  target.value = formatted
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Residual (+/-)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder={formatIfNumber(residual, 2, '0.00')}
                defaultValue={formatIfNumber(overrides.residual, 2, '')}
                onChange={setOverride.residual}
                onBlur={({ target }) => {
                  setOverride.residual(target.value)
                  target.value = formatIfNumber(target.value, 2, '')
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="50%" ml="auto" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box
              flex={1}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FieldLabel>Allowances (+)</FieldLabel>
              <Box pl={1}>
                <Tooltip content={TOOLTIPS.CONSTRAINT_ALLOWANCES} />
              </Box>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                defaultValue={formatIfNumber(overrides.allowances, 2,
                  formatIfNumber(allowances, 2, '')
                )}
                onChange={setOverride.allowances}
                onBlur={({ target }) => {
                  const formatted = formatIfNumber(target.value, 2,
                    formatIfNumber(allowances, 2, '')
                  )
                  setOverride.allowances(formatted)
                  target.value = formatted
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <HighlightedCalculationDivider />
      <Box display="flex">
        <DarkBox flex={1} mr={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Available Depth</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.availableDepth,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </DarkBox>
        <DarkBox flex={1} ml={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Required Depth</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.requiredDepth,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </DarkBox>
      </Box>
      <CalculationDivider />
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" justifyContent="space-between">
              <FieldLabel>Static UKC</FieldLabel>
              {Icon && <Icon size={20} />}
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                value={numberOrMissing(calculation.rawResult, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
        <Box flex={1} ml={2} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" justifyContent="space-between">
              <FieldLabel>Remaining UKC</FieldLabel>
              {Icon && <Icon size={20} />}
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                value={numberOrMissing(calculation.result, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

ConstraintDetailsUkc.propTypes = {
  riskAssessment: PropTypes.object,
  readOnly: PropTypes.bool,
  editableFields: PropTypes.object,
}

export default React.memo(ConstraintDetailsUkc)
