import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import BerthingConditionsDiagram from './BerthingConditionsDiagram'
import { BerthingCalculation } from './types'
import { formatToTimeZone } from 'date-fns-timezone'

const BerthingConditionsContainer = styled.div`
    .berthing-diagram {
        max-width: 700px;
        margin: 0px auto 0px 0px;
    }
`

interface BerthingConditionsProps {
    berthingCalculation: BerthingCalculation
    timeZone: string
    isMasterView?: boolean
    breakoutTitle?: boolean
}

const BerthingConditions: React.FC<BerthingConditionsProps> = ({
    berthingCalculation,
    timeZone,
    isMasterView,
    breakoutTitle
}) => {

    return (
        <BerthingConditionsContainer>
            {
                !isMasterView &&
                <Typography variant="h1">Berthing Conditions</Typography>
            }
            {
                berthingCalculation.type === 'failed' ?
                    <div>{!isMasterView && berthingCalculation.error}</div> :

                    (({ departureAccuracy, departureTime, arrivalTime }) => {
                        const arrival = formatToTimeZone(arrivalTime, "DD/MM HH:mm", { timeZone })
                        const departure = formatToTimeZone(departureTime, 'DD/MM HH:mm', { timeZone })
                        return (
                            <>
                                <p style={{
                                    fontSize: isMasterView ? 10 : undefined,
                                    margin: breakoutTitle ? '0px -100% 0px 0px' : undefined,
                                    lineHeight: breakoutTitle ? '35px' : undefined
                                }}
                                >
                                    Berthing conditions predicted for ship’s visit at the port
                                    from arrival at berthing constraint {arrival}
                                    {
                                        departureAccuracy === 'exact' ?
                                            ` until departure ${departure}` :
                                            departureAccuracy === 'estimated' ?
                                                ` until estimated departure ${departure} ` :
                                                ` until 48h later (${departure})`
                                    }
                                </p>

                                <div className="berthing-diagram">
                                    <BerthingConditionsDiagram
                                        result={berthingCalculation.result}
                                        isMasterView={isMasterView}
                                    />
                                </div>
                            </>
                        )
                    }
                    )(berthingCalculation.result)


            }
        </BerthingConditionsContainer>
    )
}

export default BerthingConditions