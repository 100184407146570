import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const TickGlyphFill = styled.g(
  ({ theme, inactive, light }) => css`
    fill: ${light
      ? '#fff'
      : inactive
      ? theme.palette.background.light
      : theme.palette.text.primary};
  `
)

const ActiveFill = styled.circle(
  ({ theme }) => css`
    fill: ${theme.palette.status.complete};
  `
)

const TickIcon = ({
  size = 28,
  inactive = false,
  light = false,
  className,
}) => (
  <svg width={size} height={size} viewBox="0 0 28 28" className={className}>
    {!inactive && <ActiveFill cx="14" cy="14" r="14" />}
    <TickGlyphFill inactive={inactive} light={light} transform="translate(2 2)">
      <path d="M8.59 15.58l-4.17-4.17L3 12.82l5.59 5.59 12-12L19.18 5z" />
    </TickGlyphFill>
  </svg>
)

TickIcon.propTypes = {
  size: PropTypes.number,
  inactive: PropTypes.bool,
  light: PropTypes.bool,
  className: PropTypes.string,
}

export default React.memo(TickIcon)
