import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { router5Middleware } from 'redux-router5'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from 'src/store/reducers'
import { router } from 'src/router/configureRouter'

export const middlewares = [thunkMiddleware, router5Middleware(router)]

export default function configureStore(initialState = {}, usePersist = true) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  if (usePersist) {
    const persistConfig = {
      key: 'root',
      blacklist: ['toast', 'auth', 'ui', 'canvas'],
      storage,
    }

    const persistedReducer = persistReducer(persistConfig, reducers)

    const store = createStore(
      persistedReducer,
      { ...initialState },
      composeEnhancers(applyMiddleware(...middlewares))
    )
    let persistor = persistStore(store)
    return { store, persistor }
  } else {
    const store = createStore(
      reducers,
      { ...initialState },
      composeEnhancers(applyMiddleware(...middlewares))
    )
    return { store }
  }
}
