import React, { useMemo } from 'react'
import styled from 'styled-components'
import { calculateTidalRate, InsufficientDataError } from 'src/domain/TidalRate/calculateTidalRate'
import { TidalRateConfig, TidalRateCalculation, TideExtreme } from 'src/utils/api/preferences'
import DirectionArrow from 'src/components/atoms/DirectionArrow'
import * as Sentry from '@sentry/react'
import { findTideRateAtTime } from 'src/utils/findTideAtTime'
import { roundTwoDecimalPlace } from 'src/utils/formatters'

const TidalRateLabelContainer = styled.div`
    border-radius: 5px;
    box-sizing: border-box;
    background: #667890;
    color: #FFF;
    text-transform: uppercase;
    padding: 0px 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0px -5px 0px;
    span {
        padding: 0px 2px;
        font-size: 12px;
        display: inline-block;
    }
    svg {
        margin-right: 3px;
    }
`

const TidalRateLabelContainerMasterView = styled.div`
    border-radius: 5px;
    background: #667890;
    color: #FFF;
    text-transform: uppercase;
    font-size: 10px;
    padding: 0px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    span {
        padding: 0px 2px;
    }
    svg {
        margin-right: 3px;
    }
`

interface TidalRateLabelFromConfigProps {
  source: 'config'
  tideRateStationName: string
  config: TidalRateConfig
  tideExtremes: TideExtreme[]
  time: number
  isLoading?: boolean
  isMasterView?: boolean
}

interface TidalRateLabelFromCsvProps {
  source: 'csv'
  tideRateStationName: string
  tideRates: any[]
  time: number
  isLoading?: boolean
  isMasterView?: boolean
}

type TidalRateLabelProps
  = TidalRateLabelFromConfigProps
  | TidalRateLabelFromCsvProps

const TidalRateFromConfig: React.FC<TidalRateLabelFromConfigProps> = ({
  time,
  config,
  tideRateStationName,
  tideExtremes,
  isMasterView
}) => {
  const tidalRate: TidalRateCalculation | Error = useMemo(() => {
    try {
      return calculateTidalRate(config, tideExtremes, time)
    } catch (err) {
      return err
    }
  }, [config, tideExtremes, time])

  if (tidalRate instanceof Error) {
    // Ronak decided to just omit the label in this case
    if (!(tidalRate instanceof InsufficientDataError)) {
      Sentry.captureException(tidalRate)
    }
    return null
  }

  const { degrees, tideDirection, range: { knotsUpper, knotsLower, term } } = tidalRate

  const Container = isMasterView
    ? TidalRateLabelContainerMasterView
    : TidalRateLabelContainer
  return (
    <Container>
      <span style={{ marginRight: 5 }}>{tideRateStationName}</span>
      {
        typeof degrees === 'number' &&
        <>
          <span style={{ marginRight: 5 }}>{Math.floor(degrees)}°</span>
          <DirectionArrow
            color="#FFF"
            scale={isMasterView ? 0.5 : 0.7}
            translateY={0}
            rotation={degrees + 180}
          />
        </>
      }
      <span>{tideDirection}</span>
      <span style={{ textTransform: 'lowercase' }}>{
        knotsLower === knotsUpper
          ? knotsLower
          : <>{knotsLower} - {knotsUpper}</>
      } kn</span>
      {term && <span>({term})</span>}
    </Container>
  )
}

const TidalRateFromCsv: React.FC<TidalRateLabelFromCsvProps> = ({
  time,
  tideRateStationName,
  tideRates,
  isMasterView
}) => {

  const Container = isMasterView
    ? TidalRateLabelContainerMasterView
    : TidalRateLabelContainer

  const tideRate = findTideRateAtTime(
    new Date(time),
    tideRates
  )

  if (!tideRate) { return null }

  const { knots, degrees, direction } = tideRate

  return (
    <Container>
      <span style={{ marginRight: 5 }}>{tideRateStationName}</span>
      <span>Tide Rate:</span>
      {
        typeof degrees === 'number' &&
        <>
          <span style={{ marginRight: 5 }}>{Math.floor(degrees)}°</span>
          <DirectionArrow
            color="#FFF"
            scale={isMasterView ? 0.5 : 0.7}
            translateY={0}
            rotation={degrees + 180}
          />
        </>
      }
      {direction && <span>{direction}</span>}
      <span style={{ textTransform: 'lowercase' }}>{roundTwoDecimalPlace(knots)} kn</span>
    </Container>
  )
}

export const TidalRateLabel: React.FC<TidalRateLabelProps> = (props) => {

  const {
    source,
    isLoading,
    isMasterView,
    time
  } = props

  const Container = isMasterView
    ? TidalRateLabelContainerMasterView
    : TidalRateLabelContainer

  if (isLoading) {
    return <Container><span>Loading...</span></Container>
  }

  switch (source) {
    case 'config': {
      const { tideExtremes, config, tideRateStationName } = props as TidalRateLabelFromConfigProps
      return (
        <TidalRateFromConfig
          source={source}
          tideRateStationName={tideRateStationName}
          isLoading={isLoading}
          isMasterView={isMasterView}
          time={time}
          tideExtremes={tideExtremes}
          config={config}
        />
      )
    }
    case 'csv': {
      const { tideRates, tideRateStationName } = props as TidalRateLabelFromCsvProps
      return (
        <TidalRateFromCsv
          source={source}
          tideRateStationName={tideRateStationName}
          isLoading={isLoading}
          isMasterView={isMasterView}
          time={time}
          tideRates={tideRates}
        />
      )
    }
    default:
      return null
  }
}

export default TidalRateLabel
