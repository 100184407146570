import { createSelector } from 'reselect'

export const tugsSelector = state => state.tugs

export const tugListSelector = createSelector(
  tugsSelector,
  state => state && state.data
)

export const getTugByUuidSelector = uuid =>
  createSelector(
    tugListSelector,
    list => list && list.find(item => item.uuid === uuid)
  )
