import {
  SET_ONLINE_PING_STATUS,
} from 'src/store/onlinePing/actions'
import { getOnlineStatus } from './../../utils/offline'

export const INITIAL_STATE = {
  isOnlinePing: getOnlineStatus(),
}

const onlinePingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_ONLINE_PING_STATUS:
      return { ...state, isOnlinePing: payload }
    default:
      return state
  }
}

export default onlinePingReducer
