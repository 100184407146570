import { SystemModel, UkcPlanModel } from 'dukcapi'
import { getPlans, getSystem, getBoundingTimeBuckets } from 'src/utils/api/omc'
import { MovementType } from 'src/types/Pilotage'
import { SEVEN_DAYS } from './../../utils/api/omc'

export type OmcAction
  = {
    type: 'OMC_SET_SYSTEM'
    payload: {
      system: SystemModel | null
    }
  }
  | {
    type: 'OMC_SET_DUKC_PLAN_LOADING'
    payload: {
      vesselIMO: number
      pilotageDate: string
      movementType: MovementType
      isLoading: boolean
      portUuid: string
    }
  }
  | {
    type: 'OMC_SET_DUKC_PLANS' // set plans in a time bucket
    payload: {
      vesselIMO: number
      fromTime: string
      toTime: string
      movementType: MovementType
      plans: UkcPlanModel[]
      portUuid: string
    }
  }

type Dispatch = (action: OmcAction) => void

export const omcGetSystem = (portUuid: string) => async (dispatch: Dispatch) => {
  const system = await getSystem(portUuid)
  dispatch({
    type: 'OMC_SET_SYSTEM',
    payload: { system: system || null }
  })
  return system
}

export const omcSetDukcPlanLoading = (
  vesselIMO: number,
  pilotageDate: string,
  movementType: MovementType,
  isLoading: boolean,
  portUuid: string,
) => (dispatch: Dispatch) => {
  dispatch({
    type: 'OMC_SET_DUKC_PLAN_LOADING',
    payload: { vesselIMO, pilotageDate, movementType, isLoading, portUuid }
  })
}

export const omcSetDukcPlans = (
  vesselIMO: number,
  fromTime: string,
  toTime: string,
  movementType: MovementType,
  plans: UkcPlanModel[],
  portUuid: string,
) => (dispatch: Dispatch, getState: any) => {

  dispatch({
    type: 'OMC_SET_DUKC_PLANS',
    payload: {
      vesselIMO,
      fromTime,
      toTime,
      movementType,
      plans,
      portUuid,
    }
  })
}

export const omcLoadDukcPlan = (
  vesselIMO: number,
  pilotageDate: string,
  movementType: MovementType,
  portUuid: string,
) => async (dispatch: any, getState: any) => {

  // Check if there is even an omc system for this port before fetching plans
  const omcState = getState().omc

  if (!omcState.systemLastSet) {
    const system = await omcGetSystem(portUuid)(dispatch)
    if (!system) { return }

  } else if (!omcState.system) {
    return
  }

  // Get the plans in the bucket for the pilotage start,
  // the bucket before, and the bucket after.
  // When we go to display a plan, from the results of the 3, pick the best plan
  // using the same heuristics as on the backend

  const setLoading = (loading: boolean) =>
    dispatch(omcSetDukcPlanLoading(vesselIMO, pilotageDate, movementType, loading, portUuid))

  setLoading(true)

  const useBuckets = false
  if (useBuckets) {
    await Promise.all(
      getBoundingTimeBuckets(pilotageDate)
        .map(async ({ fromTime, toTime }) => {

          const plans = await getPlans({
            vesselIMO,
            fromTime,
            toTime,
            movementType,
            portUuid,
          })

          return dispatch(
            omcSetDukcPlans(vesselIMO, fromTime, toTime, movementType, plans, portUuid)
          )
        })
    )
  } else {
    const time = new Date(pilotageDate).valueOf()
    const fromTime = new Date(time - SEVEN_DAYS).toISOString()
    const toTime = new Date(time + SEVEN_DAYS).toISOString()
    const plans = await getPlans({
      vesselIMO,
      fromTime,
      toTime,
      movementType,
      portUuid,
    })

    dispatch(
      omcSetDukcPlans(vesselIMO, fromTime, toTime, movementType, plans, portUuid)
    )
  }

  setLoading(false)
}
