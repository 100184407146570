import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { useRouter } from 'react-router5'
import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import { isAssignedToActivePilotageSelector } from 'src/store/pilotage/selectors'
import { useChecklist } from 'src/hooks/useChecklist'
import { ChecklistItem, ExtraType } from 'src/types/Extras'
import { CommentProps } from 'src/components/molecules/ChecklistComment'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import FormFieldError from 'src/components/atoms/FormFieldError'
import {
  CheckboxInactiveTickIcon,
  CheckboxTickIcon,
} from 'src/components/atoms/Icons/Checkbox'
import ChecklistItemComment from 'src/components/atoms/ChecklistItemComment/ChecklistItemComment'
import { WidePrimaryButton } from 'src/components/atoms/PrimaryButton'
import { MovementType } from 'src/types/Pilotage'
import { PILOTAGE_SUMMARY } from 'src/router/routes'
import ButtonRow from './../../atoms/ButtonRow/ButtonRow'

const GENERAL_COMMENT_MAX_CHARS = 500

interface ChecklistRowProps {
  item: ChecklistItem
  disabled: boolean
  onItemChecked: (item: ChecklistItem, isChecked: boolean) => void
  onItemComment: (item: ChecklistItem, value: string) => void
  itemCommentCharacterLimit: number
}

const ChecklistRow: React.FC<ChecklistRowProps> = React.memo(
  ({
    item,
    disabled,
    onItemChecked,
    onItemComment,
    itemCommentCharacterLimit,
  }) => {
    const onItemCheckedCallback = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        onItemChecked(item, e.target.checked),
      [item, onItemChecked]
    )

    const onItemCommentCallback = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        onItemComment(item, e.target.value),
      [item, onItemComment]
    )

    return (
      <Box key={item.uuid} display="flex" mb={1}>
        { !item.isHeader && (
          <>
            <Box display="flex" alignItems="flex-start" flex={1}>
              <Checkbox
                checked={item.isSelected}
                label={item.label}
                labelSpacing={3}
                checkedIcon={<CheckboxTickIcon />}
                uncheckedIcon={<CheckboxInactiveTickIcon />}
                onChange={onItemCheckedCallback}
                disabled={disabled}
              />
            </Box>
            <Box flex={1} ml={2}>
              <ChecklistItemComment
                value={item.comment || ''}
                onChange={onItemCommentCallback}
                disabled={disabled}
                maxChars={itemCommentCharacterLimit}
              />
            </Box>
          </>
        )
        }
        { item.isHeader && (
          <Box mt={2} mb={2}>
            <Typography variant="h3">{item.label}</Typography>
          </Box>
        )
        }
      </Box>
    )
  }
)

ChecklistRow.displayName = 'ChecklistRow'

interface PilotageChecklistBaseProps {
  readOnly: boolean
  movementType: MovementType,
  CommentComponent: React.FC<CommentProps>
}

export const PilotageChecklistBase: React.FC<PilotageChecklistBaseProps> = ({
  readOnly,
  movementType,
  CommentComponent,
}) => {
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)

  const {
    checklist,
    setItemState,
    comment,
    onCommentChange,
    save,
    isSaving,
    isDirty,
    isInvalid,
    itemCommentCharacterLimit,
    selectAllChecklistItems,
  } = useChecklist({
    extraType: ExtraType.Checklist,
    movementType
  })

  const router = useRouter()
  router.canDeactivate(PILOTAGE_SUMMARY, () => () => {
    return !isDirty || confirm('You have unsaved checklist changes, are you sure you want to leave this page?')
  })

  const onItemChecked = useCallback(
    (item: ChecklistItem, isChecked: boolean) => {
      setItemState(item, 'isSelected', isChecked)
    },
    [setItemState]
  )

  const onItemComment = useCallback(
    (item: ChecklistItem, comment: string) => {
      setItemState(item, 'comment', comment)
    },
    [setItemState]
  )

  const commentTooLong = useMemo(() =>
    comment && comment.length > GENERAL_COMMENT_MAX_CHARS,
    [comment]
  )

  const disabled = useMemo(() => !isDirty ||
    isSaving ||
    !isAssigned ||
    isInvalid ||
    !!commentTooLong,
    [isDirty, isSaving, isAssigned, isInvalid, commentTooLong]
  )

  const items = checklist as ChecklistItem[]

  return checklist.length > 0 ? (
    <>
      <Typography variant="h2">Checklist</Typography>
      <InfoBox extraPadding mt={3} className="unbreakable">
        {/* <Box ml="auto" pl={1} width="50%">
          <FormFieldLabel readOnly>Comment</FormFieldLabel>
        </Box> */}
        {items.map(item => (
          <ChecklistRow
            key={item.uuid}
            item={item}
            disabled={!isAssigned || readOnly}
            onItemChecked={onItemChecked}
            onItemComment={onItemComment}
            itemCommentCharacterLimit={itemCommentCharacterLimit}
          />
        ))}

        <CommentComponent
          comment={comment}
          onChange={onCommentChange}
          isDisabled={!isAssigned || readOnly}
        />
        {commentTooLong && (
          <FormFieldError>
            {comment.length} / {GENERAL_COMMENT_MAX_CHARS} character limit
          </FormFieldError>
        )}

        {!readOnly && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <ButtonRow>
              <WidePrimaryButton
                onClick={() => selectAllChecklistItems()}
              >
                Select All
              </WidePrimaryButton>
              <WidePrimaryButton
                onClick={() => save()}
                disabled={disabled}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </WidePrimaryButton>
            </ButtonRow>
          </Box>
        )}
      </InfoBox>
    </>
  ) : null
}

export default React.memo(PilotageChecklistBase)
