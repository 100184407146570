import React, { useState } from 'react'
import { Grid, Box, Menu, MenuItem } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

export interface PaginationControlsProps {
  totalRecords: number
  pageSize: number
  page: number
  onSetPage(page: number): void
  onSetPageSize(pageSize: number): void
  pageSizeOptions: number[]
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalRecords,
  page,
  pageSize,
  onSetPage,
  onSetPageSize,
  pageSizeOptions,
}) => {
  const [pageSizeAnchor, setPageSizeAnchor] = useState<EventTarget | null>(null)

  const handlePageSizeClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setPageSizeAnchor(event.currentTarget)
  }

  const handlePageSizeClose = () => {
    setPageSizeAnchor(null)
  }

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Pagination
        size="large"
        count={Math.ceil(totalRecords / pageSize)}
        page={page}
        onChange={(event, nextPage) => onSetPage(nextPage)}
        siblingCount={0}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        onClick={handlePageSizeClick}
      >
        Per page:{' '}
        <span style={{ color: '#fff', paddingLeft: 10 }}>{pageSize} </span>
        <KeyboardArrowDownIcon color="secondary" />
      </Box>
      <Menu
        anchorEl={pageSizeAnchor as Element}
        open={Boolean(pageSizeAnchor)}
        onClose={handlePageSizeClose}
      >
        {pageSizeOptions.map(option => (
          <MenuItem
            key={option}
            onClick={() => {
              onSetPageSize(option)
              handlePageSizeClose()
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}

export default PaginationControls
