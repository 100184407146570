import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

import CircleButton from 'src/components/atoms/CircleButton'

const CloseButton = props => (
  <CircleButton {...props}>
    <CloseIcon />
  </CircleButton>
)

export default React.memo(CloseButton)
