import { WeatherAction } from 'src/store/weather/actions'
import idx from 'idx'
import { round, toNumberIfExists, nonNegativeOrNull, } from 'src/utils/formatters'
import { APITypeEnum, Weather, WeatherWrapper, WeatherDataFormatted } from 'src/types/Weather'

/* 
  Weather can be set for a single pilotage at a time and is fetched
  for all weather locations defined for the current port that are
  relevant to the pilotage (the intersection of weather location constraints
  and pilotage route constraints)
*/

export interface WeatherState {
  [weatherLocationUuid: string]: {
    data: WeatherDataFormatted
    isLoading: boolean
    error?: string
    message?: string
  }
}

const INITIAL_STATE: WeatherState = {}

function weatherReducer(state = INITIAL_STATE, action: WeatherAction) {

  switch (action.type) {
    case "WEATHER_CLEAR":
    case "CLEAR_PILOTAGE_DATA": {
      return {}
    }
    case "WEATHER_REQUEST": {
      let { weatherLocationUuid } = action
      return {
        ...state,
        [weatherLocationUuid]: {
          ...state[weatherLocationUuid],
          isLoading: true
        }
      }
    }
    case 'WEATHER_ERROR': {
      let { weatherLocationUuid } = action
      return {
        [weatherLocationUuid]: {
          ...state[weatherLocationUuid],
          isLoading: false,
          error: action.error
        }
      }
    }
    case 'WEATHER_SUCCESS': {
      let { weatherLocationUuid } = action
      let formatted = formatWeatherData(action.weather)
      return {
        ...stateFromSameSource(state, action.weather.source), // clear out data from other sources
        [weatherLocationUuid]: {
          ...state[weatherLocationUuid],
          data: formatted,
          isLoading: false,
          error: undefined
        }
      }
    }
    default:
      return state
  }
}

export default weatherReducer

function stateFromSameSource(state: WeatherState, source: APITypeEnum): WeatherState {
  const nextState: WeatherState = {}
  for (const weatherLocationUuid in state) {
    const weather = state[weatherLocationUuid]
    if (weather.data && weather.data.source === source) {
      nextState[weatherLocationUuid] = weather
    }
  }
  return nextState
}

function formatWeatherData(
  data: WeatherWrapper
): { 
  source: APITypeEnum, 
  data: WeatherDataFormatted[] 
} {

  const formatHourly = (item: any) => ({
      date: item.date,
      time: item.time,
      dateTime: `${item.date} ${item.time}`,
      sunrise: item.sunrise || '',
      sunset: item.sunset || '',
      cloudCover: round(toNumberIfExists(item.cloudCover), 1),
      temperature: round(toNumberIfExists(item.temperature), 1),
      windSpeed: nonNegativeOrNull(
        round(toNumberIfExists(item.windSpeed), 1)
      ),
      windGust: nonNegativeOrNull(
        round(toNumberIfExists(item.windGust), 1)
      ),
      windDirection: nonNegativeOrNull(toNumberIfExists(item.windDirection)),
      swellHeight: nonNegativeOrNull(toNumberIfExists(item.swellHeight)),
      swellDirection: nonNegativeOrNull(
        toNumberIfExists(item.swellDirection)
      ),
      precipitation: nonNegativeOrNull(
        round(toNumberIfExists(item.precipitation), 1)
      ),
      visibility: nonNegativeOrNull(
        round(toNumberIfExists(item.visibility), 1)
      ),
      icon: idx(item, _ => _.icon) || null,
      description: idx(item, _ => _.wx3) || null,
      pressure: item.pressure
  })

  const formattedData = (data && data.weatherData || [])
    .map(({ hourly }: Weather) => hourly.map(formatHourly))

  return {
    source: data && data.source as APITypeEnum,
    data: Array.prototype.concat.apply([], formattedData),
  }
}
