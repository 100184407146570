import api from 'src/utils/api/core'

export function getVesselByIMO(portUuid, imo) {
  return api.get(`/vessels/${imo}`, { params: { portUuid } })
}

export function updateVessel(imo, data) {
  return api.put(`/vessels/${imo}`, data)
}

export function lookupVesselsByName(portUuid, name) {
  return api.get('/vessels', { params: { portUuid, name } })
}
