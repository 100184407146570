import {
  TIDES_REQUEST,
  TIDES_IN_PROGRESS,
  TIDES_ERROR,
  TIDES_SUCCESS,
} from 'src/store/tides/actions'

const INITIAL_STATE_SINGLE = {
  data: null,
  isLoading: false,
  error: null,
}

const INITIAL_STATE = {}

function tidesReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case TIDES_REQUEST:
      if (state[payload.tideStationUuid]) {
        return state
      }
      return {
        ...state,
        [payload.tideStationUuid]: { ...INITIAL_STATE_SINGLE },
      }
    case TIDES_IN_PROGRESS:
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          isLoading: true,
          error: null,
        },
      }
    case TIDES_ERROR:
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          isLoading: false,
          error: payload,
        },
      }
    case TIDES_SUCCESS: {
      const tideStationData = {
        ...(state[payload.tideStationUuid]
          ? state[payload.tideStationUuid].data
          : {}),
        ...payload.data,
      }
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          data: tideStationData,
          isLoading: false,
          error: null,
        },
      }
    }
    default:
      return state
  }
}

export default tidesReducer
