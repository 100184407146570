import { formatToTimeZone } from 'date-fns-timezone'

import {
  PORTS_IN_PROGRESS,
  PORTS_ERROR,
  PORTS_SUCCESS,
  PORTS_SELECT,
} from 'src/store/ports/actions'
import { DATE_FORMAT } from 'src/utils/constants'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  selectedUuid: null,
  selectedOrgUuid: null,
}

function portsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case PORTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PORTS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PORTS_SUCCESS:
      // Add handling in case somehow an invalid timezone is set - the pilot app will crash
      const data = payload.map(port => {
        let timezone = port.timezone
        try {
          formatToTimeZone(new Date(), DATE_FORMAT, { timeZone: timezone })
        } catch (e) {
          timezone = null
        }
        return { ...port, timezone }
      })
      return {
        ...state,
        data,
        isLoading: false,
        error: null,
      }
    case PORTS_SELECT:
      return {
        ...state,
        selectedUuid: payload.uuid,
        selectedOrgUuid: payload.orgUuid,
      }
    default:
      return state
  }
}

export default portsReducer
