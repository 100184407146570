import {
  PILOTAGE_REQUEST,
  PILOTAGE_IN_PROGRESS,
  PILOTAGE_ERROR,
  PILOTAGE_SUCCESS,
  PILOTAGE_ATTACHMENTS_UPDATED,
} from 'src/store/pilotage/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import { ROUTE_CHECK_SAVE_SUCCESS } from 'src/store/routeCheck/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  tideOverridesLoading: false,
}

function pilotageReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case PILOTAGE_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
      }
    case PILOTAGE_IN_PROGRESS:
      return {
        ...state,
        isLoading: payload,
      }
    case PILOTAGE_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PILOTAGE_SUCCESS:
      // TODO: remove once we implement better form dirty/changed checking
      // Temporary fix for PUT on pilotage returning different structure to GET
      // Causes the edit pilotage form status to revert back to unsaved
      let fromLocation = null
      let toLocation = null

      if (payload.fromLocation) {
        const { port: _1, ..._fromLocation } = payload.fromLocation
        fromLocation = _fromLocation
      }

      if (payload.toLocation) {
        const { port: _1, ..._toLocation } = payload.toLocation
        toLocation = _toLocation
      }

      const route = payload.route
        ? {
            uuid: payload.route.uuid,
            name: payload.route.name,
          }
        : null
      return {
        ...state,
        data: {
          ...payload,
          route,
          toLocation,
          fromLocation,
        },
        isLoading: false,
        error: null,
      }
    case ROUTE_CHECK_SAVE_SUCCESS:
      const checks = state.data.pilotageRouteChecks
      const existingIndex = checks.findIndex(item => item.uuid === payload.uuid)
      const data = {
        ...state.data,
        pilotageRouteChecks:
          existingIndex === -1
            ? [...checks, payload]
            : [
                ...checks.slice(0, existingIndex),
                payload,
                ...checks.slice(existingIndex + 1),
              ],
      }
      return { ...state, data }
    case PILOTAGE_ATTACHMENTS_UPDATED:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: payload,
        },
      }
    default:
      return state
  }
}

export default pilotageReducer
