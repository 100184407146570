import api from 'src/utils/api/core'

export function getExtras(portUuid) {
  return api.get('/extras', { params: { portUuid } })
}

export function createPilotageExtra(data) {
  return api.post(`/pilotages_extras`, data)
}

export function updatePilotageExtra(pilotageExtraUuid, data) {
  return api.put(`/pilotages_extras/${pilotageExtraUuid}`, data)
}
