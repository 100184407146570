import { createSelector } from 'reselect'
import { missingValueText } from 'src/utils/constants'
import { formatUsername } from 'src/utils/formatters'
import { selectedPortOrgUuidSelector } from 'src/store/ports/selectors'
import { labelSorter } from 'src/utils/sorting'

export const pilotsSelector = state => state.pilots

const filterUser = (user, portOrgUuids) => {
  const { selectedUuid, selectedOrgUuid } = portOrgUuids
  let includeUser = false
  if (user.identity) {
    if (user.identity.port) {
      // pilots
      includeUser = user.identity.port.uuid === selectedUuid
    } else if (user.identity.organisation) {
      // admins
      includeUser = user.identity.organisation.uuid === selectedOrgUuid
    } else {
      // SA
      // noop
    }
  }
  return includeUser
}
// EMPX-201 BE will restrict pilots based on org of identity.
// if logged in as:
// - pilot (list is all users under org of pilot)
// - admin (list is all users under org of admin)
// - SA (list is all users under all orgs)
// Thus, in order to filter only pilots and admins under an port
// we need the port UUID for pilots (identity with org and port id)
// and org UUID for admins (identity with only org id)
export const pilotListSelector = createSelector(
  pilotsSelector,
  selectedPortOrgUuidSelector,
  (state, portOrgUuids) =>
    state &&
    state.data &&
    state.data.filter(user => filterUser(user, portOrgUuids))
)

export const pilotOptionsSelector = createSelector(
  pilotListSelector,
  list => {
    const options = list
      ? list
          .filter(pilot => !!pilot.metadata)
          .map(pilot => ({
            value: pilot.uuid,
            label: formatUsername(pilot),
          }))
          .sort(labelSorter)
      : []
    options.unshift({
      value: missingValueText,
      label: 'Unassigned',
    })
    return options
  }
)

export const getPilotByUuidSelector = uuid =>
  createSelector(
    pilotListSelector,
    list => list && list.find(item => item.uuid === uuid)
  )
