import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import PrimaryButton from 'src/components/atoms/PrimaryButton/PrimaryButton'
import shipImage from 'src/assets/images/ship.svg'

const EmptyStateWrapper = styled.div`
  max-width: 560px;
  padding: ${({ theme }) => theme.spacing(3)}px;
`

const Footer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20%;
  background-color: ${({ theme }) => theme.palette.background.light};
`

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -100%);
`

const MediumSubtitle = styled(Typography).attrs({ variant: 'subtitle1' })`
  && {
    font-weight: ${({ theme }) => theme.weights.fontWeightMedium};
  }
`

const PilotagesEmptyState = ({
  onNew,
  pilotCreatePilotageDisabled,
  isDateRangeActive,
  isFilterActive,
  totalResultCount,
  isOnline,
}) => {
  const canClear = (isFilterActive || isDateRangeActive) && totalResultCount > 0

  return (
    <EmptyStateWrapper>
      <Typography variant="h1">
        No {canClear ? 'Results' : 'Pilotage'}
      </Typography>
      {canClear ? (
        <MediumSubtitle>
          You can try broadening your search by removing the currently applied
          filter and/or date range.
        </MediumSubtitle>
      ) : pilotCreatePilotageDisabled ? (
        <MediumSubtitle>
          eMPX is integrated with your port harbour system which should retrieve
          the upcoming pilotages schedule. In order to create pilotage manually
          in eMPX, please contact your eMPX admin to enable “New Pilotage“
          function from the admin application.
        </MediumSubtitle>
      ) : (
        <MediumSubtitle>
          You currently have no pilotages added to your eMPX. Add a new pilotage
          below and start building up your schedule.
        </MediumSubtitle>
      )}
      {!pilotCreatePilotageDisabled && (
        <PrimaryButton disabled={!isOnline} onClick={onNew}>New Pilotage</PrimaryButton>
      )}
      <Footer>
        <StyledImage src={shipImage} />
      </Footer>
    </EmptyStateWrapper>
  )
}

PilotagesEmptyState.propTypes = {
  onNew: PropTypes.func.isRequired,
  pilotCreatePilotageDisabled: PropTypes.bool,
  isDateRangeActive: PropTypes.bool,
  isFilterActive: PropTypes.bool,
  totalResultCount: PropTypes.number,
  isOnline: PropTypes.bool,
}

export default React.memo(PilotagesEmptyState)
