import { SYNC_ENTITY_TYPES, PILOTAGE_STAGE_ID } from 'src/utils/constants'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { createDrawing, updateDrawing } from 'src/utils/api/drawing'

export const sync = async syncItem =>
  new Promise(async (resolve, reject) => {
    const { isNew, entity } = syncItem
    const { uuid, ...entityWithoutUuid } = entity
    const { metadata, pilotage, stage, isFirst, isLast } = entityWithoutUuid
    try {
      const result = isNew
        ? await createDrawing(
            pilotage.uuid,
            (stage && stage.uuid) ||
              (isFirst
                ? PILOTAGE_STAGE_ID.DEPARTURE_BERTHING
                : isLast
                ? PILOTAGE_STAGE_ID.ARRIVAL_BERTHING
                : null),
            metadata
          )
        : await updateDrawing(uuid, metadata)
      resolve(getSinglePayloadFromResponse(result))
    } catch (error) {
      console.warn(error)
      reject(error)
    }
  })

export const createSyncUuid = (pilotageUuid, stageUuid) =>
  `${pilotageUuid}-${stageUuid}-${SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS}`

export default sync
