import { useSelector } from 'react-redux'
import {
  PILOTAGE_ACCEPTANCE,
  PILOTAGE_ADMIN,
  PILOTAGE_PLAN,
  PILOTAGE_RISKS,
  PILOTAGE_RISKS2, PILOTAGE_SUMMARY, PILOTAGE_TUGS, PILOTAGE_VESSEL
} from 'src/router/routes'
import { getPortExtraSelector } from 'src/store/extras/selectors'
import { ExtraType } from 'src/types/Extras'

export const useFlowlist = () => {
  let iconsOrder = [
    PILOTAGE_VESSEL,
    PILOTAGE_RISKS,
    PILOTAGE_PLAN,
    PILOTAGE_RISKS2,
    PILOTAGE_TUGS,
    PILOTAGE_SUMMARY,
    PILOTAGE_ACCEPTANCE,
    PILOTAGE_ADMIN,
  ]

  const flowlist = useSelector(getPortExtraSelector(ExtraType.Flowlist))

  if (flowlist && flowlist.metadata && flowlist.metadata.value) {
    iconsOrder = flowlist.metadata.value.map((v) => {
      if (v.label.toLowerCase() === 'vessel') {
        return { routeName: PILOTAGE_VESSEL, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'weather') {
        return { routeName: PILOTAGE_RISKS2, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'tides') {
        return { routeName: PILOTAGE_RISKS, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'pilotage plan') {
        return { routeName: PILOTAGE_PLAN, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'admin') {
        return { routeName: PILOTAGE_ADMIN, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'tugs') {
        return { routeName: PILOTAGE_TUGS, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'acceptance') {
        return { routeName: PILOTAGE_ACCEPTANCE, isShown: v.isHeader }
      } else if (v.label.toLowerCase() === 'checklist') {
        return { routeName: PILOTAGE_SUMMARY, isShown: v.isHeader }
      } else {
        throw new Error(`Unknown flow label [${v.label}]`)
      }
    })
  } else {
    iconsOrder = iconsOrder.map((v) => {
      return { routeName: v, isShown: true }
    })
  }

  return iconsOrder
}
