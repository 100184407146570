import React from 'react'

export const CrossOutlined = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="11" stroke="#1F8EFA" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="9"
      height="9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0964 16.6687L12.5028 14.0764L9.90361 16.6732C9.46872 17.1089 8.76189 17.1089 8.32701 16.6732C7.891 16.2385 7.891 15.5331 8.32701 15.0973L10.9251 12.4994L8.32701 9.90266C7.891 9.46687 7.891 8.76151 8.32701 8.32684C8.76189 7.89105 9.46872 7.89105 9.90361 8.32684L12.5028 10.9236L15.0964 8.33134C15.5313 7.89554 16.237 7.89554 16.673 8.33134C17.1079 8.76713 17.1079 9.47248 16.673 9.90715L14.0794 12.5006L16.673 15.0928C17.109 15.5275 17.109 16.234 16.673 16.6687C16.2381 17.1033 15.5313 17.1033 15.0964 16.6687Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="4" y="4" width="17" height="17" fill="#1F8EFA" />
    </g>
  </svg>
)

export default CrossOutlined
