// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost =
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
    window.location.hostname
  )

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://bit.ly/CRA-PWA'
          )
        })
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      Updater.handleUpdateFound(registration)
      Updater.handleControllerChange()
    })
    .catch(error => {
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}

export const Updater = {
  newServiceWorkerRegistration: null,
  newServiceWorkerCallback: null,
  isRefreshing: false,

  handleUpdateFound: function(registration) {
    const onInstalled = () => {
      // At this point, the updated precached content has been fetched,
      // but the previous service worker will still serve the older
      // content until all client tabs are closed.
      console.log(
        'New content is available and will be used when all ' +
          'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
      )
      this.newServiceWorkerRegistration = registration
      if (this.newServiceWorkerCallback) {
        this.newServiceWorkerCallback()
      }
    }

    const listenInstalledStateChange = function() {
      // only process if it is an update, i.e. an existing service worker is active
      if (registration.active) {
        const newServiceWorker = registration.installing
        newServiceWorker.addEventListener('statechange', () => {
          if (newServiceWorker.state === 'installed') {
            // New SW is installed and now waiting to be activated
            onInstalled()
          }
        })
      }
    }

    if (registration.waiting) {
      onInstalled()
    } else if (registration.installing) {
      listenInstalledStateChange()
    }

    registration.addEventListener('updatefound', () => {
      console.log('Updated service worker found')
      listenInstalledStateChange()
    })
  },

  handleControllerChange: function() {
    navigator.serviceWorker.addEventListener('controllerchange', function() {
      if (!this.isRefreshing) {
        window.location.reload()
        this.isRefreshing = true
      }
    })
  },

  installUpdate: function() {
    if (this.newServiceWorkerRegistration) {
      if (this.newServiceWorkerRegistration.waiting) {
        this.newServiceWorkerRegistration.waiting.postMessage('skipWaiting')
      } else if (!this.isRefreshing) {
        // Workaround for a bug where the update available banner can still appear
        // even though the new service workers activates immediately. In this case
        // just reload the app. TODO: if reason for this is found, add proper fix!
        window.location.reload()
        this.isRefreshing = true
      }
    }
  },

  onUpdateAvailable: function(callback) {
    if (this.newServiceWorkerRegistration) {
      callback()
    } else {
      this.newServiceWorkerCallback = callback
    }
  },
}
