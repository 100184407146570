import React from 'react'
import styled from 'styled-components'
import { Box, CircularProgress } from '@material-ui/core'

const StyledCircularProgress = styled(({ size }) => (
  <CircularProgress size={size} />
))`
  && {
    &.MuiCircularProgress-root {
      display: block;
    }
  }
`

interface LoaderProps {
  children: React.ReactNode
  size?: number
}

const Loader: React.FC<LoaderProps> = ({ children, size = 32 }) => (
  <Box display="flex" alignItems="center" mt={2}>
    <Box pr={3}>
      <StyledCircularProgress size={size} />
    </Box>
    <Box flex={1}>{children}</Box>
  </Box>
)

export default Loader
