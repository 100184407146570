import { actions as routerActions } from 'redux-router5'
import idx from 'idx'
import { v4 as uuidv4 } from 'uuid'
import { convertToTimeZone } from 'date-fns-timezone'

import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import {
  createPilotage,
  downloadPdf,
  getPilotage,
  updatePilotage,
} from 'src/utils/api/pilotages'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { PILOTAGE_VESSEL, EDIT_VESSEL, EDIT_PILOTAGE } from 'src/router/routes'
import { vesselRequest } from 'src/store/vessel/actions'
import { setUnsavedEntityStatus } from 'src/store/ui/actions'
import { routeRequest } from 'src/store/route/actions'
import { pilotageTugsRequest } from 'src/store/pilotageTugs/actions'
import { pilotageListRequest } from 'src/store/pilotageList/actions'
import { clearPilotageData } from 'src/store/common/actions'
import { addEntity } from 'src/store/sync/actions'
import {
  ENTITY_NAME,
  PILOTAGE_STATUS,
  SYNC_ENTITY_TYPES,
} from 'src/utils/constants'
import { getBerthStationByUuidSelector } from 'src/store/berthsStations/selectors'
import { getPilotageByUuidSelector } from 'src/store/pilotageList/selectors'
import { getPilotByUuidSelector } from 'src/store/pilots/selectors'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { selectVessel } from 'src/store/vesselLookup/actions'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { getPilotagePrefetchTimeRange } from 'src/hooks/usePrefetch'
import { timezoneSelector } from 'src/store/ports/selectors'
import { prefetchReset, prefetchPilotage } from 'src/store/prefetch/actions'
import { authUserSelector } from 'src/store/auth/selectors'

const PILOTAGE_BASE = 'PILOTAGE'

export const [
  PILOTAGE_REQUEST,
  PILOTAGE_SUCCESS,
  PILOTAGE_ERROR,
  PILOTAGE_CANCEL,
  PILOTAGE_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_BASE)

export const goToPilotage = ({ uuid, replace }) => dispatch => {
  dispatch(routerActions.navigateTo(PILOTAGE_VESSEL, { id: uuid }, { replace }))
}

export const clearPilotage = pilotage => (dispatch, getState) => {
  const existingStoreData = getState().pilotage.data

  // Clear store data first if it's for a different pilotage (so we don't see a
  // brief flicker of content from old to new, once new pilotage data loads).
  if (existingStoreData && existingStoreData.uuid !== pilotage.uuid) {
    dispatch(clearPilotageData())
  }
}

export const pilotageRequest = (uuid, fetchVesselAndRoute = true) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const pilotageFromStore = getPilotageByUuidSelector(uuid)(state)
  const isOnline = isOnlineSelector(state)

  dispatch(pilotageInProgress())

  // prevent to load the cached version of the pilotages
  // if we already have it in the store
  // when offline the store should be the most up to date
  if (!isOnline && pilotageFromStore) {
    dispatch(pilotageLoaded(pilotageFromStore, fetchVesselAndRoute))
    return Promise.resolve(pilotageFromStore)
  }

  try {
    const response = await getPilotage(uuid)
    const data = getSinglePayloadFromResponse(response)
    dispatch(pilotageLoaded(data, fetchVesselAndRoute))
    return Promise.resolve(data)
  } catch (error) {
    dispatch(pilotageError(error, TOAST_MESSAGES.LOAD_PILOTAGE_ERROR))
    return Promise.reject(error)
  }
}

export const pilotageInProgress = (status = true) =>
  createInProgressAction(PILOTAGE_BASE, status)

export const pilotageError = (error, message) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_BASE, error))
  dispatch(
    addErrorToast({
      message,
    })
  )
}

export const pilotageLoaded = (data, fetchVesselAndRoute) => dispatch => {
  dispatch(createSuccessAction(PILOTAGE_BASE, data))
  if (fetchVesselAndRoute) {
    const vesselIMO = idx(data, d => d.vessel.IMO)
    if (vesselIMO) {
      dispatch(vesselRequest(data.port.uuid, vesselIMO))
    }

    const routeId = idx(data, d => d.route.uuid)
    if (routeId) {
      dispatch(routeRequest(routeId))
    }
    if (data.uuid) {
      dispatch(pilotageTugsRequest(data.uuid))
    }
  }
}

export const pilotageCreateRequest = data => async (dispatch, getState) => {
  dispatch(pilotageInProgress())
  const state = getState()
  const isOnline = isOnlineSelector(state)
  const uuid = state.ports.selectedUuid
  const dataWithPort = { ...data, port: { uuid } }

  try {
    let payload
    if (isOnline) {
      const response = await createPilotage(dataWithPort)
      payload = getSinglePayloadFromResponse(response)
    } else {
      payload = { ...dataWithPort, uuid: uuidv4() }
      dispatch(
        addEntity({
          isNew: true,
          uuid: payload.uuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE,
          entity: payload,
        })
      )
    }
    dispatch(setUnsavedEntityStatus(ENTITY_NAME.PILOTAGE, false))
    dispatch(pilotageInProgress(false))
    dispatch(selectVessel(null))
    dispatch(
      addSuccessToast({ message: TOAST_MESSAGES.CREATE_PILOTAGE_SUCCESS })
    )
    if (isOnline) {
      dispatch(pilotageListRequest(uuid))
      const timeZone = timezoneSelector(state)
      const [from, to] = getPilotagePrefetchTimeRange(timeZone)
      const pilotageDate = convertToTimeZone(payload.date, { timeZone })
      if (pilotageDate >= from && pilotageDate <= to) {
        dispatch(prefetchReset(payload.uuid))
        dispatch(prefetchPilotage(payload))
      }
    }
    return payload
  } catch (error) {
    dispatch(pilotageError(error, TOAST_MESSAGES.CREATE_PILOTAGE_ERROR))
    return false
  }
}

export const pilotageUpdateRequest = (
  uuid,
  data,
  successMessage,
  errorMessage,
  reviewed
) => async (dispatch, getState) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)
  const pilotageInStore = getPilotageByUuidSelector(uuid)(state)
  const portUuid = state.ports.selectedUuid
  const dataWithPort = {
    // EMPX-122: initially added this at FE to set state to PLAN on any changes
    // but this should really be the responsibility of the BE.
    // status: PILOTAGE_STATUS.PLANNING,
    ...data,
    port: { uuid: portUuid },
  }
  const user = authUserSelector(state)

  try {
    let payload
    if (isOnline) {
      dispatch(pilotageInProgress())
      const response = await updatePilotage(uuid, dataWithPort, reviewed)
      payload = getSinglePayloadFromResponse(response)
      dispatch(setUnsavedEntityStatus(ENTITY_NAME.PILOTAGE, false))
      const fullPilotage = {
        ...pilotageInStore,
        ...payload,
      }
      if (
        data.status !== PILOTAGE_STATUS.ARCHIVED &&
        data.status !== PILOTAGE_STATUS.CANCELLED
      ) {
        // Only dispatch the loaded action if it hasn't been archived
        dispatch(pilotageLoaded(fullPilotage, isOnline))
        try {
          const timeZone = timezoneSelector(state)
          const [from, to] = getPilotagePrefetchTimeRange(timeZone)
          const pilotageDate = convertToTimeZone(fullPilotage.date, {
            timeZone,
          })
          if (pilotageDate >= from && pilotageDate <= to) {
            dispatch(prefetchReset(uuid))
            dispatch(prefetchPilotage(fullPilotage))
          }
        } catch (error) {
          // intentional console for devs
          console.warn(error)
        }
      } else {
        dispatch(pilotageInProgress(false))
      }
      if (successMessage !== false) {
        dispatch(
          addSuccessToast({
            message: successMessage || TOAST_MESSAGES.UPDATE_PILOTAGE_SUCCESS,
          })
        )
      }

      dispatch(pilotageListRequest(portUuid))
    } else {
      const { pilot, pilotSecond, toLocation, fromLocation, ...rest } = data

      const toBerthStation = toLocation
        ? getBerthStationByUuidSelector(toLocation.uuid)(state)
        : null

      const fromBerthStation = fromLocation
        ? getBerthStationByUuidSelector(fromLocation.uuid)(state)
        : null

      const pilotFromStore = pilot && getPilotByUuidSelector(pilot.uuid)(state)
      const pilotSecondFromStore =
        pilotSecond && getPilotByUuidSelector(pilotSecond.uuid)(state)

      payload = {
        uuid,
        ...(pilotageInStore || {}),
        ...rest,
        fromLocation: fromBerthStation || pilotageInStore.fromLocation,
        toLocation: toBerthStation || pilotageInStore.toLocation,
        pilot: pilot === null ? pilot : pilotFromStore || pilotageInStore.pilot,
        pilotSecond:
          pilotSecond === null
            ? pilotSecond
            : pilotSecondFromStore || pilotageInStore.pilotSecond,
        // Need to include alteredByUser so we don't trigger the redirect to the review screen
        alteredByUser: { uuid: user.uuid },
      }
      dispatch(
        addEntity({
          uuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE,
          entity: payload,
        })
      )
      // make sure unsaved changes flag set back to false so the discard changes modal doesn't appear
      dispatch(setUnsavedEntityStatus(ENTITY_NAME.PILOTAGE, false))
    }
    return payload
  } catch (error) {
    console.warn(error)
    if (errorMessage !== false) {
      dispatch(
        pilotageError(
          error,
          errorMessage || TOAST_MESSAGES.UPDATE_PILOTAGE_ERROR
        )
      )
    }
    return false
  }
}

export const updatePilotageVesselDisplacementRequest = (uuid, vesselDisplacement) =>
  pilotageUpdateRequest(
    uuid,
    { vesselDisplacement },
    TOAST_MESSAGES.UPDATE_DISPLACEMENT_SUCCESS,
    TOAST_MESSAGES.UPDATE_DISPLACEMENT_ERROR,
    false
  )

export const updatePilotageMaxDraftRequest = (uuid, vesselMaxDraft) =>
  pilotageUpdateRequest(
    uuid,
    { vesselMaxDraft },
    TOAST_MESSAGES.UPDATE_MAX_DRAFT_SUCCESS,
    TOAST_MESSAGES.UPDATE_MAX_DRAFT_ERROR,
    false
  )

export const archivePilotageRequest = uuid => async dispatch => {
  const result = await dispatch(
    pilotageUpdateRequest(
      uuid,
      { status: PILOTAGE_STATUS.ARCHIVED },
      TOAST_MESSAGES.ARCHIVE_SUCCESS,
      TOAST_MESSAGES.ARCHIVE_ERROR,
      false
    )
  )

  // If pilotage is archived, there will be no payload result,
  // so compare against false (which means the request failed)
  if (result !== false) {
    window.history.back()
  }

  return result
}

export const undonePilotageRequest = uuid => async dispatch => {
  const result = await dispatch(
    pilotageUpdateRequest(
      uuid,
      {
        status: PILOTAGE_STATUS.PLANNING,
        pilotAccepted: false,
        masterAccepted: false,
      },
      TOAST_MESSAGES.UNDONE_SUCCESS,
      TOAST_MESSAGES.UNDONE_ERROR,
      false
    )
  )

  return result
}

export const openPilotageDetails = id =>
  routerActions.navigateTo(PILOTAGE_VESSEL, { id })

export const openEditPilotageDetails = id =>
  routerActions.navigateTo(EDIT_PILOTAGE, { id })

export const openEditVesselDetails = id =>
  routerActions.navigateTo(EDIT_VESSEL, { id })

export const downloadPdfRequest = pilotageUuid => async dispatch => {
  try {
    const response = await downloadPdf(pilotageUuid)
    return response.data
  } catch (e) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.DOWNLOAD_PDF_ERROR,
      })
    )
    return null
  }
}

// ------------------------- Attachments ------------------------- //

export const PILOTAGE_ATTACHMENTS_UPDATED = 'PILOTAGE_ATTACHMENTS_UPDATED'

export const pilotageAttachmentsUpdated = attachments => ({
  type: PILOTAGE_ATTACHMENTS_UPDATED,
  payload: attachments,
})

export const pilotageAttachmentsRequest = uuid => async (
  dispatch,
  getState
) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)
  const pilotageFromStore = pilotageSelector(state)

  if (!isOnline) {
    return Promise.resolve(pilotageFromStore.attachments || [])
  }

  try {
    const response = await getPilotage(uuid)
    const data = getSinglePayloadFromResponse(response)
    dispatch(pilotageAttachmentsUpdated(data.attachments || []))
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
