import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGOUT_REQUEST,
  AUTH_ERROR_STATE_RESET,
  AUTH_SESSION_REFRESH_SUCCESS,
  AUTH_SESSION_REFRESH_ERROR,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
  AUTH_LOGOUT_ERROR,
  AUTH_PASSWORD_REQUEST_NEW_ERROR,
  AUTH_PASSWORD_UPDATE_ERROR,
  AUTH_PASSWORD_RESET_ERROR,
  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_IN_PROGRESS,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_ERROR,
  SESSION_EXPIRED,
  AUTH_SET_SESSION_STATUS,
} from 'src/store/auth/actions'

import {
  getTokenFromStorage,
  getRefreshTokenFromStorage,
  getUserFromStorage,
  getImageTokenFromStorage,
  getRolesFromStorage,
} from 'src/utils/storage'

import { SESSION_STATUS } from 'src/utils/constants'

export const getInitialState = () => ({
  status: null,
  error: null,
  token: getTokenFromStorage(),
  imageToken: getImageTokenFromStorage(),
  refreshToken: getRefreshTokenFromStorage(),
  user: getUserFromStorage(),
  roles: getRolesFromStorage(),
  sessionStatus: getTokenFromStorage()
    ? SESSION_STATUS.INITIALISING
    : SESSION_STATUS.INVALID,
})

const AuthReducer = (state = getInitialState(), action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH_LOGIN_REQUEST:
    case AUTH_LOGIN_IN_PROGRESS:
    case AUTH_SIGNUP_REQUEST:
    case AUTH_SIGNUP_IN_PROGRESS:
    case AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        status: type,
      }
    case AUTH_LOGIN_SUCCESS: {
      const { token, refreshToken, imageToken, user, roles } = payload
      return {
        ...state,
        status: type,
        error: null,
        token,
        refreshToken,
        imageToken,
        user,
        roles,
        sessionStatus: SESSION_STATUS.VALID,
      }
    }
    case AUTH_SESSION_REFRESH_SUCCESS: {
      const { token, refreshToken, imageToken } = payload
      return {
        ...state,
        status: type,
        error: null,
        token,
        refreshToken,
        imageToken,
        sessionStatus: SESSION_STATUS.VALID,
      }
    }
    case AUTH_LOGIN_ERROR:
    case AUTH_SIGNUP_ERROR:
      return {
        ...state,
        status: type,
        error: payload,
        sessionStatus: SESSION_STATUS.INVALID,
      }
    case AUTH_SESSION_REFRESH_IN_PROGRESS:
      return {
        ...state,
        status: type,
      }
    case AUTH_SESSION_REFRESH_ERROR:
      return {
        ...state,
        status: type,
        error: payload,
        sessionStatus:
          state.sessionStatus === SESSION_STATUS.INITIALISING
            ? SESSION_STATUS.UNKNOWN
            : state.sessionStatus,
      }
    case AUTH_LOGOUT_REQUEST:
    case SESSION_EXPIRED:
      return {
        ...state,
        status: type,
        error: null,
        token: null,
        refreshToken: null,
        user: null,
        sessionStatus: SESSION_STATUS.INVALID,
      }
    case AUTH_LOGOUT_ERROR:
    case AUTH_PASSWORD_REQUEST_NEW_ERROR:
    case AUTH_PASSWORD_UPDATE_ERROR:
    case AUTH_PASSWORD_RESET_ERROR:
      return {
        ...state,
        status: type,
        error: payload,
        sessionStatus: SESSION_STATUS.INVALID,
      }
    case AUTH_ERROR_STATE_RESET:
      const { error, ...resetState } = state
      const { status } = resetState
      if (status === AUTH_LOGIN_ERROR) {
        resetState.status = undefined
      }
      return resetState
    case AUTH_SET_SESSION_STATUS:
      return {
        ...state,
        sessionStatus: payload,
      }
    default:
      return state
  }
}

export default AuthReducer
