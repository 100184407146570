import { useEffect, useMemo } from 'react'
import { findNearestWeatherForecast } from 'src/utils/findNearestWeatherForecast'
import { useSelector, useDispatch } from 'react-redux'
import { weatherSelector } from 'src/store/weather/selectors'
import { weatherRequest } from 'src/store/weather/actions'
import { weatherLocationsStateSelector } from 'src/store/weatherLocations/selectors'
import { groupBy, dedupBy } from 'src/utils/groupBy'
import hash from 'object-hash'
import { WeatherLocationsState } from 'src/store/weatherLocations/reducer'
import { WeatherState } from 'src/store/weather/reducer'
import { PilotageWeatherProps, WeatherLocationView, WeatherView, Constraint, PilotageWeatherInputs } from './types'

export default function ({ pilotage, riskAssessment }: PilotageWeatherInputs): PilotageWeatherProps {

  const dispatch = useDispatch()
  const weather: WeatherState = useSelector(weatherSelector)
  const weatherLocations: WeatherLocationsState = useSelector(weatherLocationsStateSelector)
  const { timezone: timeZone } = pilotage.port

  useEffect(
    () => {
      if (weatherLocations && pilotage && !weatherLocations.isLoading && weatherLocations.portUuid === pilotage.port.uuid) {
        dispatch(weatherRequest(pilotage))
      }
    },
    [pilotage, weatherLocations]
  )

  const weatherToDisplay: WeatherLocationView[] = useMemo(() =>

    weatherLocations.weatherLocations
      .filter(({ uuid }) => !!weather[uuid]) // @todo make more efficient
      .map(weatherLocation => {

      const weatherAtLocation = weather[weatherLocation.uuid]

      const weatherLocationConstraints = (weatherLocation.weatherLocationConstraints || []).map(
        ({ constraint }) => constraint
      )

      const relevantRiskAssessments = riskAssessment.filter(({ routeConstraint: { constraint }}) =>
        constraint && !!weatherLocationConstraints.find(({ uuid }) => uuid === constraint.uuid)
      )

      const relevantTimes: Array<{ time: string, constraint?: Constraint }>
        = relevantRiskAssessments.length === 0

          ? [{ time: pilotage.date }]

          : relevantRiskAssessments.map(
              ({ timeAtConstraint, routeConstraint: { constraint } }) =>
                ({ time: timeAtConstraint, constraint })
            )

      const weatherViews: WeatherView[] = relevantTimes.map(({ time, constraint }) => {

        const {
          nearestWeatherForecast,
          weatherSourceName,
        } = findNearestWeatherForecast(
          time,
          timeZone,
          weatherAtLocation.data,
          weatherAtLocation.isLoading
        )

        return {
          time,
          constraint,
          nearestWeatherForecast,
          weatherSourceName,
          weatherAtLocation
        }
      })

      // to avoid repetition, we need to group by
      // forecast, and show which constraints apply
      // @todo make a version of groupBy that works on reference/identity/arrays, so hash is not needed
      const weatherViewsGroupedByForecast = groupBy(
        weatherViews.filter(({ nearestWeatherForecast }) => !!nearestWeatherForecast),
        ({ nearestWeatherForecast }) => hash(nearestWeatherForecast)
      )

      const weatherViewGroupsDedupedByConstraint = weatherViewsGroupedByForecast.map(group => ({
        ...group,
        items: dedupBy(group.items, ({ constraint }) => constraint ? constraint.uuid : '')
      }))

      return {
        weatherLocation,
        weatherViewGroups: weatherViewGroupsDedupedByConstraint
      }
    })
    .filter(({ weatherViewGroups }) => // include groups with at least 1 item
      !!weatherViewGroups.find(({ items }) => !!items[0])
    )
    .sort((a: WeatherLocationView, b: WeatherLocationView) => {

      const flatten = ({ weatherViewGroups }: WeatherLocationView) => {
        const views = weatherViewGroups.map(({ items }) => items)
        return Array.prototype.concat.apply([], views)
      }

      const average = (ns: number[]) => {
        const sum = ns.reduce((a, b) => a + b, 0);
        return (sum / ns.length) || 0;
      }

      const getAverageForecastTime = (locationView: WeatherLocationView) => {
        const views = flatten(locationView)
        return average(views.map(({ nearestWeatherForecast }) =>
          new Date(nearestWeatherForecast.dateTime).valueOf()
        ))
      }

      const A = getAverageForecastTime(a)
      const B = getAverageForecastTime(b)
      return A < B ? -1 : A > B ? 1 : 0
    }),

    [
      weather,
      weatherLocations
    ]
  )

  const sourceObject = Object.values(weather).find((w) => w && w.data && w.data.source)
  const source = sourceObject && sourceObject.data.source
  const isLoadingObject = Object.values(weather).find((w) => w && w.isLoading)
  const isLoading = isLoadingObject && isLoadingObject.isLoading
  const errorObject = Object.values(weather).find((w) => w && w.error)
  const error = errorObject && errorObject.error
  const empty = weatherToDisplay.length === 0

  return {
    pilotage,
    weatherToDisplay,
    source,
    isLoading,
    error,
    empty
  }
}
