import {
  TIDE_EXTREMES_REQUEST,
  TIDE_EXTREMES_IN_PROGRESS,
  TIDE_EXTREMES_SUCCESS,
  TIDE_EXTREMES_ERROR,
} from 'src/store/tideExtremes/actions'

const INITIAL_STATE_SINGLE = () => ({
  data: null,
  isLoading: false,
  error: null,
})

const INITIAL_STATE = {}

function tideExtremesReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case TIDE_EXTREMES_REQUEST:
      if (state[payload.tideStationUuid]) {
        return state
      }
      return {
        ...state,
        [payload.tideStationUuid]: { ...INITIAL_STATE_SINGLE() },
      }
    case TIDE_EXTREMES_IN_PROGRESS:
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          isLoading: true,
          error: null,
        },
      }
    case TIDE_EXTREMES_ERROR:
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          isLoading: false,
          error: payload.error,
        },
      }
    case TIDE_EXTREMES_SUCCESS: {
      // Don't replace the data, but append to the existing data due to required
      // offline support (replacing will clear the prefetched data). Put the new
      // data inside first, the unique data filter will keep the first duplicate
      // it sees (and we want to keep the freshest value from the server)
      const data = [
        ...payload.data,
        ...(state[payload.tideStationUuid].data || []),
      ]
      const uniqueData = data.filter(
        ({ dateTime }, index) =>
          data.findIndex(item => item.dateTime === dateTime) === index
      )
      uniqueData.sort((a, b) => a.dateTime - b.dateTime)
      return {
        ...state,
        [payload.tideStationUuid]: {
          ...state[payload.tideStationUuid],
          data: uniqueData,
          isLoading: false,
          error: null,
        },
      }
    }
    default:
      return state
  }
}

export default tideExtremesReducer
