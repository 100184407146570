import React from 'react'
import PropTypes from 'prop-types'

import {
  VesselDiagramContainer,
  LightRect,
  DiagramLabel,
  Measurement,
  MeasurementLines,
  DottedLines,
  markerRadius,
  linesToPath
} from 'src/components/molecules/VesselDiagram/common'
import { numberOrMissing } from 'src/utils/formatters'

const VesselOutlineBridgeFront = () => (
  <svg width={492} viewBox="0 0 498 102" fill="none" preserveAspectRatio="xMinYMin meet">
    <g fill="none">
      <path 
        d="M496.422 52.0985C496.422 54.9631 495.165 57.8941 492.816 60.8673C490.467 63.8396 487.079 66.7866 482.954 69.6576C474.705 75.3989 463.666 80.7288 452.573 85.2897C441.49 89.8462 430.401 93.616 422.079 96.2474C417.919 97.5628 414.453 98.5929 412.028 99.2939C410.815 99.6444 409.863 99.9126 409.215 100.093C408.891 100.183 408.642 100.251 408.475 100.297L408.351 100.331L164.212 100.331L134.323 100.331H82.0739C76.4487 100.331 70.8429 99.6709 65.3716 98.3642L6.74481 84.3623C3.91342 83.686 1.91595 81.1553 1.91595 78.2443V23.0867C1.91595 20.1757 3.91342 17.645 6.74481 16.9688L65.3716 2.96686C70.8429 1.66015 76.4487 1.00002 82.0739 1.00002L134.323 1.00002L164.212 1.00002L408.336 1.00002L408.447 1.03393C408.614 1.08492 408.862 1.16117 409.186 1.26183C409.835 1.46314 410.787 1.76204 412 2.15165C414.425 2.93089 417.891 4.0728 422.052 5.52231C430.374 8.42205 441.464 12.5494 452.548 17.4637C463.641 22.3823 474.684 28.0693 482.937 34.0792C487.064 37.0845 490.456 40.1439 492.808 43.197C495.163 46.2526 496.422 49.231 496.422 52.0985Z" 
        stroke="#FFF"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <rect x="398" y="1" width="100" height="2" transform="rotate(90 398 1)" fill="white"/>
      <rect x="329" y="0.459015" width="100" height="2" transform="rotate(90 329 0.459015)" fill="white"/>
    </g>
  </svg>
)

const VesselOutline = () => (
  <svg width={492} viewBox="0 0 498 102" preserveAspectRatio="xMinYMin meet">
    <g fill="none">
      <path
        d="M408.337 1H82.074a71.9 71.9 0 0 0-16.702 1.967L6.745 16.969a6.29 6.29 0 0 0-4.829 6.118v55.157a6.29 6.29 0 0 0 4.829 6.118l58.627 14.002a71.9 71.9 0 0 0 16.702 1.967h326.278c58.724-15.793 88.07-32.049 88.07-48.233 0-16.201-29.356-33.41-88.085-51.098z"
        stroke="#FFF"
        strokeWidth="2"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill="#FFF"
        d="M61.423 4.833v92.583l-2-.279V5.094zM130.678.459V101.79h-2V.459z"
      />
    </g>
  </svg>
)

const VesselTopDiagram = ({ bridgeToStern, bridgeToBow, beam, unit }) => {

  const bridgeFront = 
    typeof bridgeToBow === 'number' &&
    typeof bridgeToStern === 'number' &&
    bridgeToBow < bridgeToStern

  const bridgeMarkX = bridgeFront ? 360 : 114

  const dottedLines = [
    [4,137,4,83], // stern
    [bridgeMarkX,137,bridgeMarkX,50], // bridge
    [496,137,496,50] // bows
  ]

  const measurementLines = bridgeFront 
  ? [
    [4,137,114,137], // to stern
    [217,137,370,137], // stern - bow
    [460,137,496,137], // to bow
    [175,4,175,100] // beam
  ] 
  : [
    [4,137,14,137], // to stern
    [104,137,250,137], // stern - bow
    [340,137,496,137], // to bow
    [175,4,175,100] // beam
  ]

  return (
  <VesselDiagramContainer>
    <svg viewBox="0 0 600 168">
      <g transform="translate(90,0)">
        <g transform="translate(4,4)">
          {bridgeFront ? <VesselOutlineBridgeFront /> : <VesselOutline />}
        </g>
        
        <g fill="#1F8EFA" stroke="none">
          <circle r={markerRadius} cx={175} cy={5} />
          <circle r={markerRadius} cx={175} cy={103} />
          <circle r={markerRadius} cx={4} cy={137} />
          <circle r={markerRadius} cx={bridgeMarkX} cy={137} />
          <circle r={markerRadius} cx={496} cy={137} />
        </g>

        <MeasurementLines>
          <path
            d={linesToPath(measurementLines)}
            vectorEffect="non-scaling-stroke"
          />
          <DottedLines>
            <path
              d={linesToPath(dottedLines)}
              vectorEffect="non-scaling-stroke"
            />
          </DottedLines>
        </MeasurementLines>

        <LightRect x={bridgeFront ? 340 : 74} y={37} width={80} height={36} rx={20} />
        <DiagramLabel x={bridgeFront ? 355 : 89} y={55} dy="0.35em">
          Bridge
        </DiagramLabel>

        <DiagramLabel x={bridgeFront ? 132 : 26} y={137} dy="0.1em">
          To Stern
        </DiagramLabel>
        <Measurement x={bridgeFront ? 132 : 26} y={164}>
          {numberOrMissing(bridgeToStern, unit, 2)}
        </Measurement>

        <DiagramLabel x={bridgeFront ? 382 : 266} y={137} dy="0.1em">
          To Bow
        </DiagramLabel>
        <Measurement x={bridgeFront ? 382 : 266} y={164}>
          {numberOrMissing(bridgeToBow, unit, 2)}
        </Measurement>

        <DiagramLabel x={200} y={45}>
          Beam
        </DiagramLabel>
        <Measurement x={200} y={72}>
          {numberOrMissing(beam, unit, 2)}
        </Measurement>
      </g>
    </svg>
  </VesselDiagramContainer>
  )
}

VesselTopDiagram.propTypes = {
  bridgeToStern: PropTypes.number,
  bridgeToBow: PropTypes.number,
  beam: PropTypes.number,
  unit: PropTypes.string,
}

export default VesselTopDiagram
