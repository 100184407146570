const directions = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
  'N',
]

export const bearingToCompassPoint = bearing =>
  directions[Math.round(bearing / 22.5)]

export const kmhToKnots = value => Math.round(value * 0.54)

export const WEATHER_CONFIG = {
  worldWeatherOnline: 'World Weather Online',
  stormGeo: 'Storm Geo',
  metOcean: 'Met Ocean',
  weatherzone: 'Weatherzone'
}
