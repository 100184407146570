import {
  TIDE_RATES_REQUEST,
  TIDE_RATES_IN_PROGRESS,
  TIDE_RATES_ERROR,
  TIDE_RATES_SUCCESS,
} from 'src/store/tideRates/actions'

const INITIAL_STATE_SINGLE = {
  data: null,
  isLoading: false,
  error: null,
}

const INITIAL_STATE = {}

function tideRatesReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case TIDE_RATES_REQUEST:
      if (state[payload.tideRateStationUuid]) {
        return state
      }
      return {
        ...state,
        [payload.tideRateStationUuid]: { ...INITIAL_STATE_SINGLE },
      }
    case TIDE_RATES_IN_PROGRESS:
      return {
        ...state,
        [payload.tideRateStationUuid]: {
          ...state[payload.tideRateStationUuid],
          isLoading: true,
          error: null,
        },
      }
    case TIDE_RATES_ERROR:
      return {
        ...state,
        [payload.tideRateStationUuid]: {
          ...state[payload.tideRateStationUuid],
          isLoading: false,
          error: payload,
        },
      }
    case TIDE_RATES_SUCCESS: {
      const tideRateStationData = {
        ...(state[payload.tideRateStationUuid]
          ? state[payload.tideRateStationUuid].data
          : {}),
        ...payload.data,
      }
      return {
        ...state,
        [payload.tideRateStationUuid]: {
          ...state[payload.tideRateStationUuid],
          data: tideRateStationData,
          isLoading: false,
          error: null,
        },
      }
    }
    default:
      return state
  }
}

export default tideRatesReducer
