import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfoBoxBase from 'src/components/atoms/InfoBox'
import { dhiTransitsRequest } from 'src/store/dhiTransits/actions'
import { TIME_FORMAT_WITH_SEC } from 'src/utils/constants'
import { friendlyTime } from 'src/utils/date'
import dhiIconSquare from 'src/assets/images/dhi-logo-square.png'
import styled from 'styled-components'
import { dhiTransitsStateSelector } from './../../../store/dhiTransits/selectors'
import SecondaryButton from './../../atoms/SecondaryButton/index'
import { Box, CircularProgress } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { numberOrMissing, valueOrMissingText } from 'src/utils/formatters'
import { CONSTRAINT_STATUS, TIME_FORMAT } from './../../../utils/constants'
import { ConstraintName, RiskWrapper, Timestamp, ConstraintValue } from './../../molecules/RiskAssessmentConstraint/RiskAssessmentCommon'
import { formatToTimeZone } from 'date-fns-timezone'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import PropTypes from 'prop-types'
import LinkIcon from './../../atoms/Icons/Link'
import { dhiTransitsPdfStateSelector } from './../../../store/dhiTransitsPdf/selectors'
import { dhiTransitsPdfRequest } from 'src/store/dhiTransitsPdf/actions'

const InfoBox = styled(InfoBoxBase)`
    && {
        padding: 10px 10px 10px 10px;
    }

    && > div {
        width: 100%;
    }
`
const DhiControls = styled.div`
&& {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
}
`
const DhiFreshness = styled.div`
&& {
  flex: 1;
  padding: 0px 10px;
  font-size: 12px;
}
`

const DhiTransits = ({
  portUuid,
  pilotageStart,
  vesselIMO,
  timeZone,
  onClick,
}) => {
  const dispatch = useDispatch()
  const { data: transitData, lastFetched, isLoading, error } = useSelector(dhiTransitsStateSelector)
  const { isLoading: isPdfLoading } = useSelector(dhiTransitsPdfStateSelector)

  const getPdf = async () => {
    if (!transitData) { return }
    const response = await dispatch(dhiTransitsPdfRequest(portUuid, transitData.scenarioId))
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/octet-stream',
      })
    )
    const link = document.createElement('a')
    link.href = url
    const filename = `dhi_transits_${transitData.scenarioId}.pdf`
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const reloadDhiTransits = () => {
    dispatch(dhiTransitsRequest(portUuid, pilotageStart, vesselIMO))
  }
  useEffect(() => {
    if (portUuid && pilotageStart && timeZone && vesselIMO) {
      reloadDhiTransits()
    }
  }, [portUuid, pilotageStart, timeZone, vesselIMO])

  const onClickDhi = () => {
    if (transitData) {
      onClick(transitData)
    }
  }

  let requiredDepth = 0
  let availableDepth = 0
  let staticUkc = 0
  if (transitData) {
    requiredDepth = transitData.maxDraft + transitData.squat + transitData.heel + transitData.waveResponse + transitData.safetyMargin
    availableDepth = transitData.minimumDepth + transitData.tideExclOffset
    staticUkc = availableDepth - transitData.maxDraft
  }
  const remainingUkc = availableDepth - requiredDepth
  const constraintStatus = remainingUkc >= 0 && staticUkc >= 0 ? CONSTRAINT_STATUS.PASSED : CONSTRAINT_STATUS.FAILED
  let constraintStatusIcon = null
  if (constraintStatus === CONSTRAINT_STATUS.PASSED) {
    constraintStatusIcon = <TickIcon />
  } else if (constraintStatus === CONSTRAINT_STATUS.FAILED) {
    constraintStatusIcon = <CrossIcon />
  }

  return (
    <InfoBox
      mt={1} mb={2}>
      {
        !transitData &&
        <RiskWrapper dashed={false} style={{ marginBottom: 5 }}>
          <Box p={0} display="flex" alignItems="center">
            <Box flex="0 0 auto" pr={2}>~
              <img src={dhiIconSquare} alt="NCOS Transit"/>
            </Box>
            <Box
              flex={1}
              pr={2}
              display="flex"
            >
              No transit available for this pilotage.
            </Box>
          </Box>
        </RiskWrapper>
      }
      { transitData &&
        <RiskWrapper onClick={onClickDhi} status={constraintStatus} dashed={false} style={{ marginBottom: 5 }}>
          <Box p={0} display="flex" alignItems="center">

            <Box flex="0 0 auto" pr={2}>~
              <img src={dhiIconSquare} alt="NCOS Transit"/>
            </Box>

            <Box
              flex={3}
              pr={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <ConstraintName>{transitData.transitName}</ConstraintName>
              </div>
            </Box>

            <Box
              flex={1}
              pr={2}
              display="flex"
              justifyContent="space-around"
              flexDirection="column"
              height={60}
            >
              <span>Time</span>
              <Timestamp>{valueOrMissingText(formatToTimeZone(transitData.timeOfMinimumUKC, TIME_FORMAT, { timeZone }))}</Timestamp>
            </Box>

            <Box
              flex={1}
              pr={2}
              display="flex"
              justifyContent="space-around"
              flexDirection="column"
              height={60}
            >
              <span>Static UKC</span>
              <ConstraintValue>{numberOrMissing(staticUkc, null, 2)} m</ConstraintValue>
            </Box>

            <Box
              flex={1}
              pr={2}
              display="flex"
              justifyContent="space-around"
              flexDirection="column"
              height={60}
            >
              <span>Rem. UKC</span>
              <ConstraintValue>
                {numberOrMissing(remainingUkc, null, 2)} m
              </ConstraintValue>
            </Box>

            <Box width={30}>
              {constraintStatusIcon}
            </Box>
          </Box>
        </RiskWrapper>
      }
      <DhiControls>
        {
          <SecondaryButton
            onClick={reloadDhiTransits}
            disabled={isLoading || isPdfLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
          </SecondaryButton>
        }
        <DhiFreshness>
          {
            lastFetched &&
            <div>{friendlyTime('Last fetched', lastFetched, timeZone, TIME_FORMAT_WITH_SEC)}</div>
          }
          {
            error &&
            <div>Failed to fetch: {error.message}</div>
          }
        </DhiFreshness>
        <SecondaryButton onClick={getPdf} disabled={!transitData || isLoading || isPdfLoading}>
          <span style={{ paddingRight: 10 }}>Full DHI PDF</span> <LinkIcon />
        </SecondaryButton>
      </DhiControls>
    </InfoBox>
  )
}

DhiTransits.propTypes = {
  portUuid: PropTypes.string,
  pilotageStart: PropTypes.string,
  vesselIMO: PropTypes.string,
  timeZone: PropTypes.string,
  onClick: PropTypes.func,
}

export default DhiTransits
