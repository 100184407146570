import allSettled from 'promise.allsettled'

import { getPayloadFromResponse } from 'src/utils/api/core'
import {
  addPilotageTug,
  getPilotageTugs,
  removePilotageTug,
} from 'src/utils/api/tugs'
import positions from 'src/components/organisms/TugLinePositions/positions'

import { SYNC_ENTITY_TYPES } from 'src/utils/constants'

const findTugLineByLineNumber = (lineNumber, list) =>
  list.find(item => item.lineNumber === lineNumber)

export const sync = async syncItem => {
  const {
    entity: { pilotageUuid, data },
  } = syncItem
  
  let tugLinesOnServerResponse = await getPilotageTugs(pilotageUuid)
  let tugLinesOnServer = getPayloadFromResponse(tugLinesOnServerResponse)

  const tugLines = Object.values(positions).map(({ lineNumber }) => lineNumber)
  const lineNumbers = tugLines
    .sort()
    .filter(lineNumber => {
      const tugLineOnServer = findTugLineByLineNumber(
        lineNumber,
        tugLinesOnServer
      )
      const tugLineOnLocal = data[lineNumber]
      return (
        (!tugLineOnServer && tugLineOnLocal) ||
        (tugLineOnServer && !tugLineOnLocal) ||
        (tugLineOnLocal &&
          tugLineOnServer &&
          tugLineOnLocal.uuid !== tugLineOnServer.uuid)
      )
    })

  // remove first the ones which are changed or deleted
  const resultsDelete = await allSettled(
    lineNumbers.reduce((requests, lineNumber) => {
      const tugLineOnServer = findTugLineByLineNumber(
        lineNumber,
        tugLinesOnServer
      )
      if (tugLineOnServer) {
        return [...requests, removePilotageTug(tugLineOnServer.uuid)]
      }
      return requests
    }, [])
  )

  const firstRejectedResultDelete = resultsDelete.find((result) => result.status === 'rejected')
  if (firstRejectedResultDelete) {
    return Promise.reject(new Error(`Failed to delete pilotage tug: ${firstRejectedResultDelete.reason}`))
  }

  // add the ones which are added or changed
  const resultsAdd = await allSettled(
    lineNumbers.reduce((requests, lineNumber) => {
      const tugLineOnLocal = data[lineNumber]
      if (tugLineOnLocal) {
        const { isNew, uuid, ...tugLine } = tugLineOnLocal
        return [
          ...requests,
          addPilotageTug(pilotageUuid, tugLine.tug.uuid, lineNumber),
        ]
      }
      return requests
    }, [])
  )

  const firstRejectedResultAdd = resultsAdd.find((result) => result.status === 'rejected')
  if (firstRejectedResultAdd) {
    return Promise.reject(new Error(`Failed to add pilotage tug: ${firstRejectedResultAdd.reason}`))
  }

  tugLinesOnServerResponse = await getPilotageTugs(pilotageUuid)
  tugLinesOnServer = getPayloadFromResponse(tugLinesOnServerResponse)
  return Promise.resolve(tugLinesOnServer)
}

export const createSyncUuid = pilotageUuid =>
  `${pilotageUuid}-${SYNC_ENTITY_TYPES.PILOTAGE_TUGS}`

export default sync
