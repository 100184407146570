import styled, { css } from 'styled-components'
import Box from '@material-ui/core/Box'

export const AttachmentsContainer = styled.div`
  & *:focus {
    outline: none;
  }
`

export const DropZoneWrapper = styled.div`
  text-align: center;
`

export const DropZone = styled.div(
  ({ disabled, theme }) => css`
    padding: ${theme.spacing(0, 4)};
    margin-top: ${theme.spacing(3)}px;
    display: inline-block;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? 0.3 : 1};
  `
)

export const PlusRect = styled.rect`
  stroke-width: 1px;
  stroke: ${({ theme }) => theme.palette.primary.main};
  stroke-dasharray: 4, 6;
  fill: none;
  shape-rendering: crispEdges;
`

export const PlusIcon = styled.path.attrs({ d: 'M6 0v6H0v2h6v6h2V8h6V6H8V0z' })`
  fill: ${({ theme }) => theme.palette.primary.main};
`

export const AddFileLabel = styled.div(
  ({ theme }) => `
  font-weight: ${theme.weights.fontWeightSemiBold};
  font-size: ${theme.typography.pxToRem(16)};
  color: ${theme.palette.text.primary};
  text-transform: uppercase;
  margin: ${theme.spacing(1, 0, 0, 0)};
`
)

export const AddFileCaption = styled.div(
  ({ theme }) => `
  font-weight: ${theme.weights.fontWeightMedium};
  font-size: ${theme.typography.pxToRem(14)};
  color: ${theme.palette.text.secondary};
`
)

export const AttachmentHeadingStatic = styled.div(
  ({ theme }) => `
  margin: ${theme.spacing(0, 4, 0, 2)};
  font-weight: ${theme.weights.fontWeightMedium};
  font-size: ${theme.typography.pxToRem(18)};
  color: ${theme.palette.text.primary};
  word-break: break-word;
  overflow-wrap: break-word;
`
)

export const AttachmentHeading = styled(AttachmentHeadingStatic)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const AttachmentDate = styled.span(
  ({ theme }) => `
  margin-left: ${theme.spacing(2)}px;
  font-weight: ${theme.weights.fontWeightMedium};
  font-size: ${theme.typography.pxToRem(18)};
  color: ${theme.palette.text.secondary};
`
)

export const ExistingAttachment = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  min-height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const UploadError = styled.div`
  color: ${({ theme }) => theme.palette.error.light};
`
