const TOAST_MESSAGES = {
  GENERIC_ERROR: 'An error occurred',
  NETWORK_ERROR: 'Network error',
  REQUEST_TIMED_OUT: 'Your request timed out, please try again',
  SIGNUP_SUCCESS: 'Sign up successful - please login to continue',
  SIGNUP_FAILURE: 'Sign up failed',
  SIGNUP_FAILURE_409: 'The user for this token has already signed up',
  INVALID_LOGIN: 'Invalid credentials',
  LOAD_SSO_LOAD_ERROR: 'Failed to initialise page',
  LOAD_SSO_LOGOUT_ERROR: 'Failed to sign out',
  LOAD_SSO_IDP_ERROR: 'Failed to sign into identity provider',
  LOAD_SSO_TOKEN_ERROR: 'Failed to get token to sign into eMPX',
  REQUEST_NEW_PASSWORD_SUCCESS: 'Password reset instructions sent',
  REQUEST_PASSWORD_RESET_SUCCESS: 'Your password was successfully changed',
  RESET_PASSWORD_ERROR:
    'An error occurred while we tried to reset the password',
  RESET_PASSWORD_ERROR_401: 'This link is no longer valid',
  LOAD_PILOTAGES_ERROR: 'Error loading pilotages',
  EXPORT_PILOTAGES_ERROR: 'Error exporting pilotages',
  LOAD_PILOTAGE_ERROR: 'Error loading pilotage',
  LOAD_VESSEL_ERROR: 'Error loading vessel data',
  UPDATE_VESSEL_SUCCESS: 'Vessel successfully updated',
  UPDATE_VESSEL_ERROR: 'Error updating vessel data',
  UPDATE_PILOTAGE_SUCCESS: 'Pilotage successfully updated',
  UPDATE_PILOTAGE_ERROR: 'Error updating pilotage',
  UPDATE_PILOTAGE_ERROR_NO_OFFLINE_TIDE_DATA:
    'No offline tide data available for this time',
  CREATE_PILOTAGE_SUCCESS: 'Pilotage successfully created',
  CREATE_PILOTAGE_ERROR: 'Error creating pilotage',
  UPDATE_MAX_DRAFT_SUCCESS: 'Max. Draft updated',
  UPDATE_MAX_DRAFT_ERROR: 'Error updating Max. Draft',
  UPDATE_DISPLACEMENT_SUCCESS: 'Vessel Displacement updated',
  UPDATE_DISPLACEMENT_ERROR: 'Error updating Vessel Displacement',
  LOAD_TUGS_ERROR: 'Error loading tugs',
  LOAD_PILOTAGE_TUGS_ERROR: 'Error loading pilotage tugs',
  ADD_PILOTAGE_TUG_ERROR: 'Error adding tug',
  ADD_PILOTAGE_TUG_SUCCESS: 'Tug successfully added',
  REMOVE_PILOTAGE_TUG_ERROR: 'Error removing tug',
  REMOVE_PILOTAGE_TUG_SUCCESS: 'Tug successfully removed',
  VESSEL_LOOKUP_ERROR: 'Error searching for vessel',
  VESSEL_LOOKUP_NO_RESULT:
    'No matching vessel found, please enter vessel details manually',
  LOAD_PILOTS_ERROR: 'Error loading pilots list',
  LOAD_BERTHS_STATIONS_ERROR: 'Error loading berths and pilot stations',
  ROUTES_LOOKUP_ERROR: 'Error looking up routes',
  LOAD_ROUTE_ERROR: 'Error loading pilotage route data',
  LOAD_DRAWING_ERROR: 'Error loading drawings',
  SAVE_DRAWING_SUCCESS: 'Drawings successfully saved',
  SAVE_DRAWING_ERROR: 'Error saving drawings',
  LOAD_WEATHER_ERROR: 'Error loading weather data',
  LOAD_WEATHER_LOCATIONS_ERROR: 'Error loading weather locations data',
  TIDES_ERROR: 'Error loading tidal data',
  TIDE_EXTREMES_ERROR: 'Error loading tide extremes',
  LOAD_PILOTAGE_THREAD_ERROR: 'Error loading comments thread',
  PILOTAGE_THREAD_ADD_COMMENT_ERROR: 'Error adding comment',
  PILOTAGE_THREAD_ADD_COMMENT_SUCCESS: 'Your comment has been sent',
  ARCHIVE_SUCCESS: 'Pilotage has been archived',
  ARCHIVE_ERROR: 'Unable to archive pilotage',
  UNDONE_SUCCESS: 'Pilotage acceptance reverted successfully',
  UNDONE_ERROR: 'Error updating pilotage acceptance',
  SHARE_PILOTAGE_SUCCESS: 'Pilotage successfully shared',
  SHARE_PILOTAGE_ERROR: 'Error sharing the pilotage',
  LOAD_PORTS_ERROR: 'Error loading ports data',
  LOAD_EXTRAS_ERROR: 'Error loading port guidelines/terms/checklist',
  LOAD_PILOTAGE_EXTRAS_ERROR: 'Error loading checklist',
  LOAD_PREFERENCES_ERROR: 'Error loading preferences',
  SAVE_CHECKLIST_SUCCESS: 'Checklist updated',
  SAVE_CHECKLIST_ERROR: 'Error saving checklist',
  SAVE_NOTES_SUCCESS: 'Notes saved',
  SAVE_NOTES_ERROR: 'Error saving notes',
  SAVE_TIMESTAMPS_SUCCESS: 'Timestamps updated',
  SAVE_TIMESTAMPS_ERROR: 'Error saving timestamps',
  SAVE_ROUTE_CHECK_SUCCESS: 'Constraint parameters saved',
  SAVE_ROUTE_CHECK_ERROR: 'Error saving constraint parameters',
  SAVE_ROUTE_CHECK_WAYPOINT_SUCCESS: 'Waypoint parameters saved',
  SAVE_ROUTE_CHECK_WAYPOINT_ERROR: 'Error saving waypoint parameters',
  UPLOAD_ATTACHMENT_ERROR: 'Error uploading attachment',
  DELETE_ATTACHMENT_ERROR: 'Error deleting attachment',
  ATTACHMENT_REJECTED: 'One or more files were not accepted',
  ATTACHMENT_DUPLICATE:
    'Attachment with the same file name already exists, please try re-uploading with a different name',
  DOWNLOAD_PDF_ERROR: 'Error generating PDF',
  REFRESH_SUCCESS: 'Pilotages have been refreshed successfully',
  REFRESH_ERROR: 'Unable to refresh the Pilotages, please try again later',
  MAX_DRAFT_UPDATED: 'Max draft was updated',
  LOAD_OMC_ERROR: 'Unable to fetch OMC DUKC information for this pilotage',
  LOAD_DHI_ERROR: 'Unable to fetch DHI NCOS information for this pilotage',
  LOAD_DHI_PDF_ERROR: 'Unable to fetch DHI NCOS PDF for this pilotage',
}

export default TOAST_MESSAGES
