import { createSelector } from 'reselect'
import { PILOTAGE_STAGE_ID, SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { entitiesToSyncSelector } from 'src/store/sync/selectors'

const drawingsStateSelector = state => state.drawings

export const drawingsListSelector = createSelector(
  drawingsStateSelector,
  entitiesToSyncSelector,
  (state, entitiesToSync) => {
    let drawings = state.data || []
    const syncItems = Object.keys(entitiesToSync || {}).map(
      uuid => entitiesToSync[uuid]
    )
    // find those changed items which are
    // - has drawings sync type
    const pilotageDrawingsInSync = syncItems
      .filter(item => item.type === SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS)
      .map(item => item.entity)
    // replace the original ones to the offline changed ones
    let data = drawings.map(
      d =>
        pilotageDrawingsInSync.find(syncItem => syncItem.uuid === d.uuid) || d
    )
    // add those which are only in the offline changed/created list
    return [
      ...data,
      ...pilotageDrawingsInSync.filter(
        syncItem => !data.find(d => d.uuid === syncItem.uuid)
      ),
    ]
  }
)

export const drawingSelector = stageId =>
  createSelector(
    drawingsListSelector,
    drawings => {
      let stage = null
      switch (stageId) {
        case PILOTAGE_STAGE_ID.ARRIVAL_BERTHING:
          stage = drawings.find(item => item.isLast) || null
          break
        case PILOTAGE_STAGE_ID.DEPARTURE_BERTHING:
          stage = drawings.find(item => item.isFirst) || null
          break
        default:
          stage =
            drawings.find(item => item.stage && item.stage.uuid === stageId) ||
            null
      }
      return stage
    }
  )
