import { Box } from '@material-ui/core'
import styled from 'styled-components'

const ButtonRow = styled(Box)`
  && button:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

export default ButtonRow
