import React from 'react'
import styled, { css } from 'styled-components'
import TickIcon from 'src/components/atoms/Icons/Tick'
import CautionIcon from 'src/components/atoms/Icons/Caution'

interface CheckboxIconProps {
  size?: number
}

const TickOutline = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.status.complete};
    border: 1px solid ${theme.palette.status.complete};
    border-radius: 50%;
  `
)
const InactiveTickOutline = styled(TickOutline)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.dark};
  `
)

const CautionOutline = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.status.caution};
    border: 1px solid ${theme.palette.status.caution};
    border-radius: 50%;
  `
)
const InactiveCautionOutline = styled(CautionOutline)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.dark};
  `
)

export const CheckboxTickIcon: React.FC<CheckboxIconProps> = React.memo(
  ({ size = 28, ...props }) => (
    <TickOutline {...props}>
      <TickIcon size={size - 2} />
    </TickOutline>
  )
)

CheckboxTickIcon.displayName = 'TickIcon'

export const CheckboxInactiveTickIcon: React.FC<CheckboxIconProps> = React.memo(
  ({ size = 28, ...props }) => (
    <InactiveTickOutline {...props}>
      <TickIcon size={size - 2} inactive />
    </InactiveTickOutline>
  )
)

CheckboxInactiveTickIcon.displayName = 'InactiveTickIcon'

export const CheckboxCautionIcon: React.FC<CheckboxIconProps> = React.memo(
  ({ size = 28, ...props }) => (
    <CautionOutline {...props}>
      <CautionIcon size={size - 2} />
    </CautionOutline>
  )
)

CheckboxCautionIcon.displayName = 'CheckboxCautionIcon'

export const CheckboxInactiveCautionIcon: React.FC<
  CheckboxIconProps
> = React.memo(({ size = 28, ...props }) => (
  <InactiveCautionOutline {...props}>
    <CautionIcon size={size - 2} inactive />
  </InactiveCautionOutline>
))

CheckboxInactiveCautionIcon.displayName = 'CheckboxInactiveCautionIcon'
