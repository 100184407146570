import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core'
import { useDebouncedCallback } from 'use-debounce'

import FormFieldLabel from 'src/components/atoms/FormFieldLabel'
import Typeahead from 'src/components/atoms/Typeahead'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { vesselLookupSelector } from 'src/store/vesselLookup/selectors'
import {
  vesselLookupRequestByIMO,
  vesselLookupRequestByName,
  clearVesselLookupResults,
} from 'src/store/vesselLookup/actions'
import { acceptIntegerOnly } from 'src/utils/events'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'

const VesselButtonGroup = styled(ButtonGroup)`
  && {
    display: flex;
    border-radius: 6px;
    box-shadow: none;
  }
`

const VesselButton = styled(Button)(
  ({ theme, selected }) => `
  && {
    padding: 10px 18px;
    color: ${
      selected ? theme.palette.text.primary : theme.palette.primary.main
    };
    min-width: auto;
    flex: 1;
    border: 1px solid;
    border-color: ${
      selected ? theme.palette.primary.main : theme.palette.disabled.main
    };
    background-color: ${
      selected ? theme.palette.primary.main : theme.palette.background.dark
    };
    font-weight: ${theme.weights.fontWeightMedium};
    min-height: 42px;
    overflow: hidden;
    
    &:not(:first-child) {
      border-left: 0;
    }
  }
`
)

const DoneButtonWrapper = styled(Box).attrs({ pl: 2 })`
  margin-top: 27px;
`

const SEARCH_BY = {
  NAME: 'name',
  IMO: 'imo',
}

const VesselSearch = ({ onDone }) => {
  const dispatch = useDispatch()
  const [searchBy, setSearchBy] = useState(SEARCH_BY.NAME)
  const [selectedItem, setSelectedItem] = useState(null)
  const [value, setValue] = useState({ text: '' })
  const { data, isLoading } = useSelector(vesselLookupSelector)
  const portUuid = useSelector(selectedPortUuidSelector)

  const updateSearchBy = searchBy => {
    dispatch(clearVesselLookupResults())
    setSearchBy(searchBy)
  }

  const [searchDebounced] = useDebouncedCallback(value => {
    if (searchBy === SEARCH_BY.NAME) {
      if (value.text.trim().length >= 3) {
        dispatch(vesselLookupRequestByName(portUuid, value.text.trim()))
      }
    } else if (searchBy === SEARCH_BY.IMO) {
      if (/^[0-9]{3,7}$/.test(value.text.trim())) {
        dispatch(vesselLookupRequestByIMO(portUuid, value.text.trim()))
      }
    }
  }, 250)

  const search = value => {
    setValue(value)
    dispatch(clearVesselLookupResults())
    if (value.IMO) {
      setSelectedItem(value)
    } else {
      setSelectedItem(null)
      searchDebounced(value)
    }
  }

  const done = () => {
    onDone(selectedItem)
  }

  const itemToString = item =>
    searchBy === SEARCH_BY.NAME ? item.name : item.IMO

  const itemFormatter = item => (
    <Box display="flex" width="100%">
      <Box flex={1}>{itemToString(item)}</Box>
      <Box>{searchBy === SEARCH_BY.NAME ? item.IMO : item.name}</Box>
    </Box>
  )

  const endAdornment = selectedItem
    ? searchBy === SEARCH_BY.IMO
      ? selectedItem.name
      : selectedItem.IMO
    : ''

  const isValidIMO =
    searchBy === SEARCH_BY.IMO
      ? !value.text || /^[0-9]*$/.test(value.text.trim())
      : true

  return (
    <>
      <Typography variant="h1">Vessel Search</Typography>
      <Box display="flex" mt={3}>
        <Box pr={2}>
          <FormFieldLabel>Search By</FormFieldLabel>
          <VesselButtonGroup color="primary" variant="contained">
            <VesselButton
              onClick={() => updateSearchBy(SEARCH_BY.NAME)}
              selected={searchBy === SEARCH_BY.NAME}
            >
              Name
            </VesselButton>
            <VesselButton
              onClick={() => updateSearchBy(SEARCH_BY.IMO)}
              selected={searchBy === SEARCH_BY.IMO}
            >
              IMO
            </VesselButton>
          </VesselButtonGroup>
        </Box>
        <Box flex={1}>
          <Typeahead
            placeholder="Search for vessel"
            label="Vessel"
            onChange={search}
            results={data}
            isLoading={isLoading}
            itemToString={itemToString}
            itemFormatter={itemFormatter}
            itemKeyFn={item => item.IMO}
            value={value}
            onKeyDown={searchBy === SEARCH_BY.IMO ? acceptIntegerOnly : null}
            adornment={endAdornment}
            error={isValidIMO ? null : 'Invalid IMO'}
            absolutePositionResults
          />
        </Box>
        <DoneButtonWrapper>
          <PrimaryButton onClick={done} disabled={!selectedItem}>
            Done
          </PrimaryButton>
        </DoneButtonWrapper>
      </Box>
    </>
  )
}

VesselSearch.propTypes = {
  onDone: PropTypes.func,
}

export default VesselSearch
