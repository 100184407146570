import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState, useMemo } from 'react'
import { v1 as uuidv1 } from 'uuid'
import isEqual from 'fast-deep-equal'

import {
  createPilotageExtraRequest,
  updatePilotageExtraRequest,
} from 'src/store/pilotageExtras/actions'
import { pilotageRequest } from 'src/store/pilotage/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getPortExtraSelector } from 'src/store/extras/selectors'
import { getPilotageExtraSelector } from 'src/store/pilotageExtras/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import {
  BaseChecklistItem,
  ChecklistExtra,
  ChecklistItem,
  ChecklistMetadata,
  ExtraType,
  PassFailChecklistExtra,
  PassFailChecklistItem,
  PassFailChecklistMetadata,
} from 'src/types/Extras'
import flags from 'src/utils/flags'
import { MovementType } from 'src/types/Pilotage'

const getInitialState = (
  extraType: ExtraType,
  pilotageExtra: ChecklistExtra | PassFailChecklistExtra,
  portExtra: ChecklistExtra | PassFailChecklistExtra
) => {
  let checklist: BaseChecklistItem[] = []
  let comment: string | undefined

  if (pilotageExtra) {
    checklist = pilotageExtra.metadata.value
    comment = pilotageExtra.metadata.comment
  } else if (portExtra) {
    switch (extraType) {
      case ExtraType.Checklist:
        checklist = (portExtra as ChecklistExtra).metadata.value.map(item => ({
          ...item,
          isSelected: false,
        }))
        break
      case ExtraType.PassFailChecklist:
        checklist = (portExtra as PassFailChecklistExtra).metadata.value.map(
          item => ({
            ...item,
            passChecked: false,
            failChecked: false,
          })
        )
        break
      default:
        break
    }
  }
  // Due to late introduction of uuids on the admin side, existing checklists
  // cannot be assumed to have item uuids. To make sure it still works,
  // generate them here.
  checklist = checklist.map(item =>
    item.uuid ? item : { ...item, uuid: uuidv1() }
  )

  return { checklist, comment: comment || '' }
}

interface UseChecklistParams {
  extraType: ExtraType
  movementType: MovementType
  itemCommentCharacterLimit?: number
}

export const useChecklist = ({
  extraType,
  movementType,
  itemCommentCharacterLimit = 300,
}: UseChecklistParams) => {
  const portExtra = useSelector(getPortExtraSelector(extraType))
  const pilotageExtra = useSelector(getPilotageExtraSelector(extraType))
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const dispatch = useDispatch()
  const [checklist, setChecklist] = useState<BaseChecklistItem[]>([])
  const [comment, setComment] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const initialState = useRef<{
    checklist: BaseChecklistItem[]
    comment: string
  } | null>(null)

  useEffect(
    () => {
      initialState.current = getInitialState(
        extraType,
        pilotageExtra,
        portExtra
      )
      const { checklist, comment } = initialState.current

      setChecklist(checklist)
      setComment(comment)
    },
    [extraType, portExtra, pilotageExtra]
  )

  const isDirty = useMemo(() => {
    return initialState.current && !isEqual({ checklist, comment }, initialState.current)
  }, [checklist, comment, initialState.current])

  const saveChecklist = async (
    metadata: ChecklistMetadata | PassFailChecklistMetadata
  ) => {
    const payload = {
      pilotage: { uuid: pilotageUuid },
      metadata,
      extraType,
    }
    setIsSaving(true)

    if (!pilotageExtra) {
      await dispatch(
        createPilotageExtraRequest(
          payload,
          TOAST_MESSAGES.SAVE_CHECKLIST_SUCCESS,
          TOAST_MESSAGES.SAVE_CHECKLIST_ERROR
        )
      )
    } else {
      await dispatch(
        updatePilotageExtraRequest(
          pilotageExtra.uuid,
          payload,
          TOAST_MESSAGES.SAVE_CHECKLIST_SUCCESS,
          TOAST_MESSAGES.SAVE_CHECKLIST_ERROR
        )
      )
    }
    setIsSaving(false)
    if (flags.PLAN_STATUS_UPDATE_ENABLED) {
      // update causes a status update, so refetch the latest from the BE
      dispatch(pilotageRequest(pilotageUuid))
    }
  }

  const setItemState = (
    item: BaseChecklistItem,
    itemFieldName: string,
    newItemState: any
  ) => {
    const data = checklist.map(checklistItem =>
      checklistItem.uuid !== item.uuid
        ? checklistItem
        : {
            ...checklistItem,
            [itemFieldName]: newItemState,
          }
    )

    setChecklist(data)
  }

  const selectAllChecklistItems = () => {
    const data = checklist.map(checklistItem => {
      const checklistItemCast = checklistItem as ChecklistItem
      if (!checklistItemCast.isHeader && !checklistItemCast.isSelected) {
        return {
          ...checklistItem,
          isSelected: true,
        }
      } else {
        return checklistItem
      }
    })
    setChecklist(data)
  }

  const selectAllPassFailChecklistItems = () => {
    const data = checklist.map(checklistItem => {
      const checklistItemCast = checklistItem as PassFailChecklistItem
      if (!checklistItemCast.isHeader && !checklistItemCast.failChecked) {
        return {
          ...checklistItem,
          passChecked: true,
        }
      } else {
        return checklistItem
      }
    })
    setChecklist(data)
  }

  const save = (value: BaseChecklistItem[] = checklist) => {
    saveChecklist({ value: value as any, comment })
  }

  const onCommentChange = (value: string) => {
    setComment(value)
  }

  const isInvalid = checklist.some(
    item => item.comment && item.comment.length > itemCommentCharacterLimit
  )

  const checklistByMovementType = useMemo(() =>
    checklist.filter((item) => {
      const disabledFor = item.disableForMovementTypes || []
      return disabledFor.indexOf(movementType) === -1
    }),
    [movementType, checklist]
  )

  return {
    checklist: checklistByMovementType,
    setItemState,
    comment,
    onCommentChange,
    save,
    isSaving,
    isDirty,
    isInvalid,
    itemCommentCharacterLimit,
    selectAllChecklistItems,
    selectAllPassFailChecklistItems,
  }
}
