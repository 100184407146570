import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CONSTRAINT_STATUS, CONSTRAINT_TYPE } from 'src/utils/constants'
import calculateOverheadClearance from 'src/utils/calculateOverheadClearance'
import findTideAtTime from 'src/utils/findTideAtTime'
import calculateUnderKeelClearance from 'src/utils/calculateUnderKeelClearance'
import calculateDynamicUnderKeelClearance from 'src/utils/calculateDynamicUnderKeelClearance'
import { formatIfNumber, padRight } from 'src/utils/formatters'
import {
  derivedMaxDraftSelector,
  pilotageRouteChecksSelector,
  pilotageStartTimeSelector,
} from 'src/store/pilotage/selectors'
import { tidesSelector } from 'src/store/tides/selectors'
import { vesselSelector } from 'src/store/vessel/selectors'
import { timezoneSelector } from 'src/store/ports/selectors'
import { mapLegacySafetyMargin } from 'src/utils/constraint'
import { featureFlagResidualDiffIgnoreTime } from 'src/store/preferences/selectors'
import { featureFlagResidualDiff } from './../store/preferences/selectors'

const DEBUG_RESIDUAL_INDICATOR = false

const useRiskAssessment = routeConstraints => {

  // These selectors assume an active pilotage, i.e. the risk assessment is for one specific pilotage
  const maxDraft = useSelector(derivedMaxDraftSelector)
  const pilotageStartTime = useSelector(pilotageStartTimeSelector)
  const tides = useSelector(tidesSelector)
  const routeChecks = useSelector(pilotageRouteChecksSelector)
  const residualDiffEnabled = useSelector(featureFlagResidualDiff)
  const residualDiffIgnoreTime = useSelector(featureFlagResidualDiffIgnoreTime)
  const { data: vessel } = useSelector(vesselSelector)
  const timeZone = useSelector(timezoneSelector)

  // Allow values in the calculation to be overridden. This allows immediate UI
  // feedback in the risk calculation. Overrides are per-constraint

  // override values - what is saved in routeCheck for pilotage. may be currently editing
  // base values -  what is defined on the constraint itself, in the admin
  // effective values - what is used in calculations

  // override values saved in pilotage route checks :: { [routeConstraintUuid]: { [key]: number } }
  const savedOverrides = useMemo(() => {
    const overrides = {}
    for (const routeCheck of routeChecks) {
      const { routeConstraint, variables } = routeCheck
      if (routeConstraint) {
        overrides[routeConstraint.uuid] = variables || {}
      }
    }
    return overrides
  }, [routeChecks])

  const [editingOverrides, setEditingOverrides] = useState({ ...savedOverrides })

  const setEditingToSaved = () => {
    setEditingOverrides({ ...savedOverrides })
  }

  useEffect(setEditingToSaved, [savedOverrides])

  // set one
  // string, string, string -> void
  const setConstraintOverride = (routeConstraintUuid, key, value) =>
    setEditingOverrides({
      ...editingOverrides,
      [routeConstraintUuid]: {
        ...editingOverrides[routeConstraintUuid],
        [key]: value
      }
    })

  return useMemo(
    () => {

      if (!pilotageStartTime) {
        return []
      }

      let deltaT = 0 // used to calculate cumulative time per constraint

      let deltaTime2residualOverrides = {}
      // assumes routeConstraints are already sorted by time before storage in redux
      const results = routeConstraints.map(routeConstraint => {

        const uuid = routeConstraint.uuid
        const constraint = mapLegacySafetyMargin(routeConstraint.constraint)

        const routeCheck = routeChecks &&
          routeChecks.find(check =>
            check.routeConstraint && check.routeConstraint.uuid === uuid
          )

        const base = { time: routeConstraint.time, value: constraint.value }
        const overrides = editingOverrides[uuid] || {} // { [key]: number }

        const getEffective = (key) =>
          !isNaN(overrides[key]) ? Number(overrides[key])
            : !isNaN(base[key]) ? Number(base[key])
              : 0

        const setOverrideValue = (key, value) => {
          setConstraintOverride(uuid, key, value === '' || isNaN(value) ? undefined : Number(value))
        }

        // value - even if overridable has been switched off in the admin, use it if it has been defined
        // https://masterpilotexchange.atlassian.net/browse/EMPX-518?focusedCommentId=10725
        const valueRaw = !isNaN(base['value']) ? Number(base['value']) : 0
        const value = getEffective('value')

        // allowances
        const allowances = getEffective('allowances')
        const residual = getEffective('residual')
        const swellAllowance = getEffective('swellAllowance')
        const squatAllowance = getEffective('squatAllowance')
        const rissagaAllowance = getEffective('rissagaAllowance')

        const hideConstraint = getEffective('hideConstraint')

        // effective time, cumulative time, time at constraint
        // @todo support legacy timeOverrideValue prop
        const timeRaw = !isNaN(base['time']) ? Number(base['time']) : 0
        const time = getEffective('time')
        const cumulativeTimeBefore = Number(deltaT)
        deltaT += time
        const cumulativeTime = Number(deltaT)
        const timeAtConstraint = new Date(pilotageStartTime)
        timeAtConstraint.setMinutes(
          timeAtConstraint.getMinutes() + cumulativeTime
        )

        if (residualDiffEnabled) {
          if (!routeConstraint.constraint.isWaypoint) {
            const tideStationUuidCheck = routeConstraint.constraint.tideStation.uuid
            let keyCheck
            if (residualDiffIgnoreTime) {
              keyCheck = `${tideStationUuidCheck}`
            } else {
              keyCheck = `${tideStationUuidCheck}-${cumulativeTime}`
            }
            let residualOverrides = deltaTime2residualOverrides[keyCheck]
            if (!residualOverrides) {
              residualOverrides = []
              deltaTime2residualOverrides[keyCheck] = residualOverrides
            }
            if (DEBUG_RESIDUAL_INDICATOR) {
              console.log(`overrides [${JSON.stringify(overrides)}]`)
            }
            const residualStr = formatIfNumber(overrides['residual'], 2, '--')
            residualOverrides.push(
              {
                routeConstraintUuid: routeConstraint.uuid,
                tideStationUuid: routeConstraint.constraint.tideStation.uuid,
                residualOverride: residualStr
              }
            )
          }
        }

        // tide - effective tide is a bit different in that
        // it is a lookup on the tide data set by time (when not overridden)
        const tideRateStationUuid = constraint.tideRateStation && constraint.tideRateStation.uuid
        const tideRateStationName = constraint.tideRateStation && constraint.tideRateStation.name
        const tideRateStationConfig = constraint.tideRateStation && constraint.tideRateStation.metadata
        const tideStationUuid = constraint.tideStation && constraint.tideStation.uuid
        const tideStation = tides[tideStationUuid]
        const tideDataLoading = tideStation && tides[tideStationUuid].isLoading
        const tideData = tideStation && tideStation.data

        const tideRaw = !tideDataLoading && tideData && findTideAtTime(timeAtConstraint, tideData)

        const tide = !isNaN(overrides.tideOverrideValue) ? Number(overrides.tideOverrideValue) : tideRaw

        // calculation
        let calculation

        if (tideDataLoading) {
          calculation = { variables: {}, isLoading: true }

        } else if (tide === null || tide === undefined) {
          calculation = {
            variables: {},
            errors: [
              `${
                !constraint.tideStation
                  ? 'The tide station associated with this constraint appears to have been deleted'
                  : `Insufficient tide data found for tide station '${
                    constraint.tideStation.name
                  }' at the time of this pilotage`
              }`,
            ],
          }
        } else {
          switch (constraint.constraintType) {
            case CONSTRAINT_TYPE.OHC:
              calculation = calculateOverheadClearance({
                constraint,
                maxDraft,
                tideValue: tide,
                allowances,
                vessel: vessel || {},
                overrides,
              })
              break
            case CONSTRAINT_TYPE.UKC_DYNA:
              calculation = calculateDynamicUnderKeelClearance({
                constraint,
                value,
                maxDraft,
                tideValue: tide,
                allowances,
                squatAllowance,
                swellAllowance,
                rissagaAllowance,
                overrides,
              })
              break
            case CONSTRAINT_TYPE.UKC:
            default:
              calculation = calculateUnderKeelClearance({
                constraint,
                value,
                maxDraft,
                tideValue: tide,
                allowances,
                residualValue: residual,
                overrides,
              })
          }
        }

        const constraintStatus =
          calculation.errors ? CONSTRAINT_STATUS.NO_RESULT
            : calculation.isLoading ? CONSTRAINT_STATUS.LOADING
              : calculation.result >= 0 ? CONSTRAINT_STATUS.PASSED
                : CONSTRAINT_STATUS.FAILED

        // Expose functions to override time, tide, and allowances
        const setOverride = {
          value: value => setOverrideValue('value', value),
          tideOverrideValue: value => setOverrideValue('tideOverrideValue', value),
          hideConstraint: value => setOverrideValue('hideConstraint', value),
          allowances: value => setOverrideValue('allowances', value),
          residual: value => setOverrideValue('residual', value),
          safetyMargin: value => setOverrideValue('safetyMargin', value),
          swellAllowance: value => setOverrideValue('swellAllowance', value),
          squatAllowance: value => setOverrideValue('squatAllowance', value),
          rissagaAllowance: value => setOverrideValue('rissagaAllowance', value),
          time: hoursMinutesString => {

            if (hoursMinutesString === '') {
              setOverrideValue('time', '')
              return
            }

            const hhmm = hoursMinutesString.split(':')
            const hours = Number(hhmm[0])
            const minutes = hhmm[1] ? Number(padRight(hhmm[1], 2, '0')) : 0
            const totalMinutes = (hours * 60) + (minutes || 0)

            setOverrideValue('time', totalMinutes)
          }
        }

        return {
          setEditingToSaved,
          setOverride,
          routeCheck,
          calculation,
          routeConstraint,
          tideStationUuid,
          tideRateStationUuid,
          tideRateStationName,
          tideRateStationConfig,
          pilotageStartTime,
          cumulativeTime,
          cumulativeTimeBefore,
          timeAtConstraint,
          constraintStatus,

          // effective values
          tide,
          time,
          allowances,
          residual,
          swellAllowance,
          squatAllowance,
          rissagaAllowance,
          value,
          hideConstraint,

          // current override values - are being edited
          overrides,

          // raw values
          valueRaw,
          tideRaw,
          timeRaw,
        }
      })
      if (residualDiffEnabled) {
        if (DEBUG_RESIDUAL_INDICATOR) {
          console.log(`deltaTime2residualOverrides [${JSON.stringify(deltaTime2residualOverrides)}]`)
        }
        const keys = Object.keys(deltaTime2residualOverrides)
        const routeContraintUuidsWithResidualDiff = []
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          if (DEBUG_RESIDUAL_INDICATOR) {
            console.log(`key [${key}]`)
          }
          const overrideInfos = deltaTime2residualOverrides[key]
          if (overrideInfos.length > 1) {
            let lastVal = overrideInfos[0].residualOverride
            for (let j = 0; j < overrideInfos.length; j++) {
              const overrideInfo = overrideInfos[j]
              const residualOverride = overrideInfo.residualOverride
              if (residualOverride !== lastVal) {
                const routeConstraintUuids = overrideInfos.map(info => info.routeConstraintUuid)
                routeContraintUuidsWithResidualDiff.push(...routeConstraintUuids)
                break
              }
            }
          }
        }
        if (DEBUG_RESIDUAL_INDICATOR) {
          console.log(`routeContraintUuidsWithResidualDiff [${JSON.stringify(routeContraintUuidsWithResidualDiff)}]`)
        }
        for (let i = 0; i < results.length; i++) {
          const result = results[i]
          const routeConstraintUuid = result.routeConstraint.uuid
          result.hasResidualDiff = routeContraintUuidsWithResidualDiff.includes(routeConstraintUuid)
        }
      }
      return results
    },
    [
      routeConstraints,
      maxDraft,
      pilotageStartTime,
      tides,
      routeChecks,
      vessel,
      editingOverrides,
      timeZone,
    ]
  )
}

export default useRiskAssessment
