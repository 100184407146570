import styled, { css } from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import TickIcon from 'src/components/atoms/Icons/Tick'

const Outline = styled(({ disabled, outlineColor, ...props }) => (
  <div className="checkbox-outline" {...props} />
))(
  ({ theme, disabled, outlineColor }) => css`
    border-radius: 50%;
    border-width: 1px;
    border-color: ${disabled
      ? theme.palette.readOnly
      : outlineColor || theme.palette.primary.main};
    border-style: solid;
  `
)

const EmptyState = styled(Outline)(
  ({ size }) => css`
    min-width: ${size}px;
    max-width: ${size}px;
    min-height: ${size}px;
    max-height: ${size}px;
  `
)

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  & input {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  & svg {
    display: block;
  }
`

const LabelText = styled(({ spacing, ...props }) => <span {...props} />)(
  ({ theme, spacing }) => css`
    margin-left: ${theme.spacing(spacing)}px;
    color: ${({ theme }) => theme.palette.text.primary};
    word-break: break-word;
    overflow-wrap: break-word;
  `
)

const CheckboxRadio = ({
  type,
  size,
  outlineColor,
  checked,
  light,
  label,
  labelSpacing,
  disabled,
  checkedIcon,
  uncheckedIcon,
  className,
  ...props
}) => (
  <Label disabled={disabled} className={className}>
    <input type={type} checked={checked} disabled={disabled} {...props} />
    {checked || uncheckedIcon ? (
      checked ? (
        checkedIcon || <TickIcon light={light}/>
      ) : (
        uncheckedIcon
      )
    ) : (
      <EmptyState size={size} disabled={disabled} outlineColor={outlineColor} />
    )}
    {label && <LabelText spacing={labelSpacing}>{label}</LabelText>}
  </Label>
)

CheckboxRadio.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
  checked: PropTypes.bool,
  light: PropTypes.bool,
  label: PropTypes.node,
  labelSpacing: PropTypes.number,
  outlineColor: PropTypes.string,
  disabled: PropTypes.bool,
  checkedIcon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  className: PropTypes.string,
}

CheckboxRadio.defaultProps = {
  size: 30,
  labelSpacing: 1,
}

export const Checkbox = props => <CheckboxRadio type="checkbox" {...props} />
export const RadioBtn = props => <CheckboxRadio type="radio" {...props} />
