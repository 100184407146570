import { OmcState } from 'src/store/omc/reducer'
import { GetPlanQuery, getBoundingTimeBuckets, getPlanKey, getPlanBucketKey, heuristicallySortPlans } from 'src/utils/api/omc'
import { createSelector } from 'reselect'
import { UkcPlanModel } from 'dukcapi'
import { INITIAL_STATE } from './reducer'
import { SEVEN_DAYS } from './../../utils/api/omc'

export const omcStateSelector = (state: any): OmcState =>
  state.omc || INITIAL_STATE

// get selected plan and its loading state
export const omcPlanSelector = (query: GetPlanQuery | null) =>
  createSelector(
    omcStateSelector,
    ({ planBuckets, loading }) => {

      if (!query) { return }

      const { pilotageDate } = query

      const useBuckets = false
      let buckets
      if (useBuckets) {
        buckets = getBoundingTimeBuckets(pilotageDate)
          .map(({ fromTime, toTime }) => planBuckets[getPlanBucketKey({ ...query, fromTime, toTime })])
          .filter(b => b)
      } else {
        const time = new Date(pilotageDate).valueOf()
        const fromTime = new Date(time - SEVEN_DAYS).toISOString()
        const toTime = new Date(time + SEVEN_DAYS).toISOString()
        buckets = planBuckets[getPlanBucketKey({ ...query, fromTime, toTime })]
      }

      const planCandidates = Array.prototype.concat.apply([], buckets)
      const sorted = heuristicallySortPlans(planCandidates, pilotageDate)

      return {
        plans: sorted as Array<UkcPlanModel & { lastFetched: number }>,
        loading: loading[getPlanKey(query)]
      }
    }
  )
