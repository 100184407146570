import { createSelector } from 'reselect'
import {
  PILOTAGE_LIST_SUCCESS,
  PILOTAGE_LIST_ERROR,
} from 'src/store/pilotageList/actions'
import { entitiesToSyncSelector } from 'src/store/sync/selectors'
import { MOVEMENT_TYPE } from 'src/utils/constants'

export const pilotageStateSelector = state => state.pilotages

export const pilotageIsLoadingSelector = createSelector(
  pilotageStateSelector,
  state => state && state.isLoading
)

export const pilotageErrorSelector = createSelector(
  pilotageStateSelector,
  state => state && state.error
)

export const pilotageDataSelector = createSelector(
  pilotageStateSelector,
  state => state && state.data
)

export const pilotageLastLoadedPortUuidSelector = createSelector(
  pilotageStateSelector,
  state => state && state.lastLoadedPortUuid
)

export const pilotageFilterSelector = createSelector(
  pilotageStateSelector,
  state => state && state.filter
)

export const pilotageAscendingSelector = createSelector(
  pilotageStateSelector,
  state => state && state.ascending
)

export const pilotageListSelector = createSelector(
  pilotageStateSelector,
  entitiesToSyncSelector,
  (state, syncEntities) => {
    return state && state.data
      ? state.data.map(pilotage => {
        const { uuid } = pilotage
        const changedPilotage = syncEntities[uuid]
        return (changedPilotage && changedPilotage.entity) || pilotage
      })
      : []
  }
)

export const getPilotageByUuidSelector = uuid =>
  createSelector(
    pilotageListSelector,
    list => list && list.find(item => item.uuid === uuid)
  )

export const listStatusSelector = createSelector(
  pilotageStateSelector,
  state => state && state.status
)

export const isLoadedSelector = createSelector(
  listStatusSelector,
  status => [PILOTAGE_LIST_SUCCESS, PILOTAGE_LIST_ERROR].includes(status)
)

// Used in berthing conditions feature
// for any Arrival/Shift pilotage, gets the first following Shift/Departure
// pilotage for the same IMO (caller should probably define some reasonable max range)
export const getDepartingForPilotage = (pilotage) => createSelector(
  pilotageListSelector,
  list => {
    if (
      !list ||
      !pilotage ||
      !pilotage.vessel ||
      !pilotage.toLocation ||
      pilotage.movementType === MOVEMENT_TYPE.DEPARTURE
    ) {
      return
    }

    return list
      .filter(({
        vessel,
        movementType,
        fromLocation,
        date
      }) =>
        vessel && vessel.IMO === pilotage.vessel.IMO &&
          new Date(date).valueOf() > new Date(pilotage.date).valueOf() &&
          fromLocation && fromLocation.uuid === pilotage.toLocation.uuid &&
          movementType !== MOVEMENT_TYPE.ARRIVAL
      )
      .sort((a, b) =>
        new Date(a.date).valueOf() - new Date(b.date).valueOf()
      )[0]
  }
)

export const getOfflineChangedPilotages = createSelector(
  entitiesToSyncSelector,
  pilotageListSelector,
  (entities, pilotages = []) =>
    Object.values(entities).reduce((list, { pilotageUuid, uuid }) => {

      const pUuid = pilotageUuid || uuid
      const pilotage = list.find(p => p.uuid === pUuid)
        ? null
        : pilotages.find(({ uuid }) => uuid === pUuid)

      return pilotage ? [...list, pilotage] : list

    }, [])
)
