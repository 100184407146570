import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getPorts } from 'src/utils/api/ports'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'
import { extrasRequest } from 'src/store/extras/actions'
import { tugsRequest } from 'src/store/tugs/actions'
import { preferencesRequest } from 'src/store/preferences/actions'
import { weatherLocationsRequest } from 'src/store/weatherLocations/actions'
import * as Sentry from '@sentry/react'
import { preferencesReset } from './../preferences/actions'

const PORTS_BASE = 'PORTS'

export const PORTS_SELECT = 'PORTS_SELECT'

export const [
  PORTS_REQUEST,
  PORTS_SUCCESS,
  PORTS_ERROR,
  PORTS_CANCEL,
  PORTS_IN_PROGRESS,
] = createBasicActionTypes(PORTS_BASE)

export const portsRequest = () => async dispatch => {
  dispatch(portsInProgress())

  try {
    const response = await getPorts()
    const data = getPayloadFromResponse(response)
    dispatch(portsLoaded(data))
  } catch (error) {
    dispatch(portsError(error))
  }
}

export const portsInProgress = () => createInProgressAction(PORTS_BASE)

export const portsError = error => dispatch => {
  dispatch(createErrorAction(PORTS_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_PORTS_ERROR,
    })
  )
}

export const portsLoaded = data => (dispatch, getState) => {
  const selectedPortUuid = selectedPortUuidSelector(getState())
  dispatch(createSuccessAction(PORTS_BASE, data))
  if (!selectedPortUuid) {
    dispatch(setSelectedPort(data[0], data[0].organisation))
  }
}

export const setSelectedPort = (port, org) => async (dispatch) => {

  const uuid = port.uuid
  const orgUuid = org.uuid

  await dispatch({
    type: PORTS_SELECT,
    payload: { uuid, orgUuid },
  })

  dispatch(extrasRequest(uuid))
  dispatch(tugsRequest(uuid))
  dispatch(preferencesReset())
  dispatch(preferencesRequest())
  dispatch(weatherLocationsRequest(uuid))

  if (Sentry && Sentry.setTag) {
    Sentry.setTag('port_uuid', uuid)
    Sentry.setTag('port_name', port.name)
    Sentry.setTag('org_uuid', orgUuid)
    Sentry.setTag('org_name', org.name)
  }
}
