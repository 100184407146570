import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import React from 'react'
import { routeMetadata } from 'src/router/routes'

const routeTransitionDuration = 400

const RouterTransitionGroup = styled(TransitionGroup)`
  height: inherit;

  & .page {
    height: inherit;
  }

  & .page-enter {
    opacity: 0;
  }

  & .page-enter-active {
    opacity: 1;
    transition: opacity ${routeTransitionDuration}ms;
  }

  & .page-enter-done {
    opacity: 1;
  }

  & .page-exit {
    opacity: 1;
    transform: translateY(-100%);
  }

  & .page-exit-active {
    opacity: 0;
    transition: opacity ${routeTransitionDuration}ms;
  }

  & .page-exit-done {
    opacity: 0;
  }
`

const RouterTransition = ({ route, children }) => {
  const metadata = routeMetadata[route.name]
  const key = metadata && metadata.group ? metadata.group : route.name

  return (
    <RouterTransitionGroup>
      <CSSTransition
        timeout={{
          enter: routeTransitionDuration,
          exit: routeTransitionDuration,
        }}
        key={key}
        classNames="page"
      >
        <div className="page">{children}</div>
      </CSSTransition>
    </RouterTransitionGroup>
  )
}

RouterTransition.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
}

export default RouterTransition
