import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { toCalendarDateString } from 'src/utils/date'

const RangeRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 40px;
  position: relative;
`

const RangeLabelBox = styled.div`
  .tip {
    position: absolute;
    bottom: 9px;
    z-index: 1;
    color: #fff;
    width: 10px;
    height: 10px;
  }
`

const ButtonRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`

export interface DateRange {
  start: string | null | undefined // ISO calendar date, e.g: 2020-12-30
  end: string | null | undefined
}

interface RangeLabelProps {
  value: Date | null
  label: 'Start' | 'End'
  focused: boolean
}

const RangeLabel: React.FC<RangeLabelProps> = ({ value, label, focused }) => (
  <RangeLabelBox>
    {label}:
    <span style={{ color: focused ? '#fff' : undefined, padding: 5 }}>
      {value ? value.toDateString() : focused ? 'select' : '---'}
    </span>
    {focused && (
      <span
        className="tip"
        style={{
          [label === 'Start' ? 'left' : 'right']: '75px',
        }}
      >
        <ArrowDropUpIcon fontSize="large" />
      </span>
    )}
  </RangeLabelBox>
)

interface DateRangePickerProps {
  onSubmit: (range: DateRange) => void
  onCancel: () => void
  initialValues: DateRange
}

// takes and yields calendar dates in ISO format, e.g: 2020-12-30
// - only year-month-day, no time or timezone data
// !! important - this knows nothing about timezone -
// it's totally based on the user's browser locale
const DateRangePicker: React.FC<DateRangePickerProps> = ({
  onSubmit,
  onCancel,
  initialValues,
}) => {
  const initialStart = initialValues.start
    ? new Date(initialValues.start)
    : null
  const initialEnd = initialValues.end ? new Date(initialValues.end) : null

  const [start, setStart] = useState<Date | null>(initialStart)
  const [end, setEnd] = useState<Date | null>(initialEnd)
  const [focus, setFocus] = useState<'start' | 'end'>('start')

  const toggleFocus = () => {
    setFocus(focus === 'start' ? 'end' : 'start')
  }

  const handleChange = (dates: Date | [Date, Date] | null) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates
      setStart(start)
      setEnd(end)
      toggleFocus()
    }
  }

  const handleApply = () => {
    if (start && end) {
      onSubmit({
        start: toCalendarDateString(start),
        end: toCalendarDateString(end),
      })
    }
  }

  return (
    <div>
      <RangeRow>
        <RangeLabel
          label={'Start'}
          focused={focus === 'start'}
          value={start || initialStart}
        />
        <RangeLabel
          label={'End'}
          focused={focus === 'end'}
          value={end || initialEnd}
        />
      </RangeRow>

      <DatePicker
        onChange={handleChange}
        dateFormat="d MMM yyyy"
        selected={start}
        startDate={start}
        endDate={end}
        selectsRange
        inline
      />

      <ButtonRow>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton onClick={handleApply} disabled={!(start && end)}>
          Apply
        </PrimaryButton>
      </ButtonRow>
    </div>
  )
}

export default DateRangePicker
