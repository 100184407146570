import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'

const StyledFab = styled(({ dark, primaryHighlight, ...rest }) => (
  <Fab {...rest} />
))(
  ({ theme, dark, primaryHighlight }) => `
  && {
    color: ${primaryHighlight ? theme.palette.primary.main : ''};
    border: ${
      primaryHighlight ? `1px solid ${theme.palette.primary.main}` : ''
    };
    background-color: ${dark ? theme.palette.background.light : ''};
    &:hover {
      background-color: ${dark ? theme.palette.background.xlight : ''};
    }
    &:disabled {
      color: ${dark ? 'rgba(255, 255, 255, 0.3)' : ''};
    }
  }
`
)

const CircleButton = ({ children, ...props }) => (
  <StyledFab color="primary" {...props}>
    {children}
  </StyledFab>
)

CircleButton.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
  primaryHighlight: PropTypes.bool,
}

export default React.memo(CircleButton)
