import { Event, EventProcessor, Hub, Integration } from '@sentry/types'
import { getGlobalObject, logger } from '@sentry/utils'

export class Offline implements Integration {

    public static id: string = 'Offline'
    public readonly name: string = Offline.id

    private hub?: Hub
    private eventQueue: Event[] = []
    private global!: any

    public constructor(private maxEvents = 100) {
        this.global = getGlobalObject()
    }

    public setupOnce(
        addGlobalEventProcessor: (callback: EventProcessor) => void, 
        getCurrentHub: () => Hub
    ): void {
        
        this.hub = getCurrentHub()

        if (this.global.addEventListener) {
            this.global.addEventListener('online', () => {
                try {
                    this._sendEvents()
                } catch (err) {
                    logger.warn('could not send cached events')
                    logger.error(err)
                }
            })
            this.global.addEventListener('offline', () => {
                this.eventQueue = []
            })
        }

        addGlobalEventProcessor((event: Event) => {

            if (this.hub && this.hub.getIntegration(Offline)) {

                // cache if we are positively offline
                if ('navigator' in this.global && 'onLine' in this.global.navigator && !this.global.navigator.onLine) {
                    try {
                        this._cacheEvent(event)
                    } catch (err) {
                        logger.warn('could not cache event while offline')
                        logger.error(err)
                    }

                    // return null on success or failure, because being offline will still result in an error
                    return null
                }
            }

            return event
        })
    }

    private async _cacheEvent(event: Event) {
        if (this.eventQueue.length >= this.maxEvents) {
            this.eventQueue.shift()
        }
        return this.eventQueue.push(event)
    }

    private async _sendEvents() {
        if (this.hub) {
            let event: Event | undefined
            while (event = this.eventQueue.shift()) {
                this.hub.captureEvent(event)
            }
        } else {
            logger.warn('no hub found - could not send cached event');
        }
    }
}

export default Offline