import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import fileSize from 'filesize'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import DeleteIcon from '@material-ui/icons/Delete'
import { formatToTimeZone } from 'date-fns-timezone'

import SecondaryButton from 'src/components/atoms/SecondaryButton'
import AttachmentIcon from 'src/components/atoms/AttachmentIcon'
import { DATE_FORMAT } from 'src/utils/constants'
import useAttachments from 'src/hooks/useAttachments'
import Modal from 'src/components/molecules/Modal'
import {
  ATTACHMENT_OPERATION,
  ATTACHMENT_STATUS,
} from 'src/components/organisms/Attachments/constants'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { addErrorToast } from 'src/store/toast/actions'
import {
  AddFileCaption,
  AddFileLabel,
  AttachmentDate,
  AttachmentHeading,
  AttachmentHeadingStatic,
  AttachmentsContainer,
  DropZone,
  DropZoneWrapper,
  ExistingAttachment,
  PlusIcon,
  PlusRect,
  UploadError,
} from 'src/components/organisms/Attachments/common'
import AttachmentDelete from 'src/components/organisms/Attachments/AttachmentDelete'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { timezoneSelector } from 'src/store/ports/selectors'
import InfoMessage from 'src/components/atoms/InfoMessage/InfoMessage'

const Attachments = ({
  savedAttachments,
  maxSize,
  limit,
  readOnly,
  addEnabled,
  accept,
  attachmentFilter,
  rejectedMessage,
  upload,
  remove,
  onSuccess,
}) => {
  const dispatch = useDispatch()
  const isOnline = useSelector(isOnlineSelector)
  const timeZone = useSelector(timezoneSelector)

  const [viewingFile, setViewingFile] = useState(null)
  const [deletingFile, setDeletingFile] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const onError = ({ operation }) => {
    let message
    switch (operation) {
      case ATTACHMENT_OPERATION.UPLOAD:
        message = TOAST_MESSAGES.UPLOAD_ATTACHMENT_ERROR
        break
      case ATTACHMENT_OPERATION.DELETE:
        message = TOAST_MESSAGES.DELETE_ATTACHMENT_ERROR
        break
      case ATTACHMENT_OPERATION.REJECTED:
        message = rejectedMessage || TOAST_MESSAGES.ATTACHMENT_REJECTED
        break
      case ATTACHMENT_OPERATION.REJECTED_DUPLICATE:
        message = TOAST_MESSAGES.ATTACHMENT_DUPLICATE
        break
      case ATTACHMENT_OPERATION.REJECTED_OVERSIZE:
        message = `Maximum file size is ${fileSize(maxSize, { base: 10 })}`
        break
      default:
        message = TOAST_MESSAGES.GENERIC_ERROR
    }
    if (message) {
      dispatch(addErrorToast({ message }))
    }
  }

  const {
    attachments,
    isUploading,
    onUpload,
    onRemove,
    onRetry,
  } = useAttachments({
    savedAttachments,
    upload,
    remove,
    onError,
    onSuccess,
  })

  attachments.sort((a, b) => new Date(a.date) - new Date(b.date))

  const limitReached = typeof limit === 'number' && limit <= attachments.length

  const onDropAccepted = acceptedFiles => {
    let files = attachmentFilter
      ? acceptedFiles.filter(attachmentFilter)
      : acceptedFiles

    if (typeof limit === 'number') {
      files = files.slice(0, limit)
    }

    const withoutDuplicates = files.filter(
      file => !attachments.find(item => item.name === file.name)
    )

    if (withoutDuplicates.length < files.length) {
      onError({
        operation: ATTACHMENT_OPERATION.REJECTED_DUPLICATE,
      })
      files = withoutDuplicates
    } else if (
      files.length < acceptedFiles.length ||
      acceptedFiles.length === 0
    ) {
      // these should mainly be caught in onDropRejected, but if there is a
      // custom attachmentFilter they might only be caught and filtered out here.
      onError({
        operation: ATTACHMENT_OPERATION.REJECTED,
      })
    }

    if (files.length) {
      onUpload(files)
    }
  }

  const onDropRejected = files => {
    const [file] = files
    if (file) {
      if (maxSize && file.size > maxSize) {
        onError({
          operation: ATTACHMENT_OPERATION.REJECTED_OVERSIZE,
        })
      } else {
        onError({
          operation: ATTACHMENT_OPERATION.REJECTED,
        })
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    multiple: false,
    disabled: isUploading,
  })

  const closeViewFileModal = () => setViewingFile(false)

  const openFile = item => {
    if (isOnline) {
      if (/^image\//.test(item.mimeType)) {
        setViewingFile(item)
      } else if (item.url) {
        window.open(item.url)
      }
    }
  }

  const openDeleteModal = item => {
    setDeletingFile(item)
  }

  const closeDeleteModal = () => setDeletingFile(null)

  const confirmDelete = async () => {
    setIsDeleting(true)
    const result = await onRemove(deletingFile)
    setIsDeleting(false)
    if (result) {
      setDeletingFile(null)
    }
  }

  const HeadingComponent = isOnline
    ? AttachmentHeading
    : AttachmentHeadingStatic

  return (
    <AttachmentsContainer>
      {(readOnly || !addEnabled) && attachments.length === 0 && (
        <Typography variant="h5">No attachments added</Typography>
      )}
      {attachments.map((item, index) => (
        <ExistingAttachment key={index} mt={index === 0 ? -2 : 0}>
          <Box flex={1} display="flex" alignItems="center">
            <AttachmentIcon mimeType={item.mimeType} />
            <HeadingComponent onClick={() => openFile(item)}>
              {item.name}
            </HeadingComponent>
          </Box>
          <Box flex={1} display="flex" justifyContent="space-between" pr={2}>
            <AttachmentDate>
              {formatToTimeZone(item.date, DATE_FORMAT, { timeZone })}
            </AttachmentDate>
            {item.status === ATTACHMENT_STATUS.ERROR && (
              <UploadError>Upload error</UploadError>
            )}
          </Box>
          <Box flex="0 0 100px" textAlign="center">
            {(item.status === ATTACHMENT_STATUS.UPLOADING ||
              item.status === ATTACHMENT_STATUS.DELETING) && (
              <CircularProgress />
            )}
            {!readOnly && item.status === ATTACHMENT_STATUS.COMPLETED && (
              <SecondaryButton
                onClick={() => openDeleteModal(item)}
                disabled={!isOnline}
              >
                Delete
              </SecondaryButton>
            )}
            {!readOnly && item.status === ATTACHMENT_STATUS.ERROR && (
              <Box display="flex">
                <IconButton
                  title="Remove"
                  color="secondary"
                  onClick={() => onRemove(item)}
                  disabled={!isOnline}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  title="Retry"
                  color="secondary"
                  onClick={() => onRetry(item)}
                  disabled={!isOnline}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </ExistingAttachment>
      ))}
      {limitReached && (
        <InfoMessage pt={4}>
          Limit of {limit} attachment{limit === 1 ? '' : 's'} reached.
        </InfoMessage>
      )}

      {!limitReached && !readOnly && addEnabled && (
        <DropZoneWrapper>
          <input {...getInputProps()} />
          <DropZone {...getRootProps()} disabled={isUploading}>
            <svg width={82} height={82}>
              <PlusRect x={1} y={1} width={80} height={80} rx={8} ry={8} />
              <g transform="translate(34,34)">
                <PlusIcon />
              </g>
            </svg>
            <AddFileLabel>Add file{limit !== 1 ? 's' : ''}</AddFileLabel>
            {maxSize && (
              <AddFileCaption>
                (up to {fileSize(maxSize, { base: 10, round: 0 })})
              </AddFileCaption>
            )}
          </DropZone>
        </DropZoneWrapper>
      )}
      <Modal
        open={!!viewingFile}
        heading="View Attachment"
        subHeading={viewingFile ? viewingFile.name : ''}
        includeHeadingDivider={false}
        onClose={closeViewFileModal}
      >
        {viewingFile && <img src={viewingFile.url} alt={viewingFile.name} />}
      </Modal>
      <Modal
        open={!!deletingFile}
        heading="Delete Attachment"
        includeHeadingDivider={false}
        onClose={closeDeleteModal}
      >
        <AttachmentDelete
          attachment={deletingFile}
          isLoading={isDeleting}
          onCancel={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      </Modal>
    </AttachmentsContainer>
  )
}

Attachments.propTypes = {
  savedAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  accept: PropTypes.string,
  attachmentFilter: PropTypes.func,
  maxSize: PropTypes.number,
  limit: PropTypes.number,
  readOnly: PropTypes.bool,
  addEnabled: PropTypes.bool,
  rejectedMessage: PropTypes.string,
  upload: PropTypes.func,
  remove: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default React.memo(Attachments)
