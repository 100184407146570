export default function getRouteConstraints(route) {
  const routeConstraints = (route && route.routeConstraints) || []
  return routeConstraints.map(routeConstraint => {
    const { constraint } = routeConstraint
    if (!constraint.tideStation) {
      return {
        ...routeConstraint,
        // missing tide station placeholder
        constraint: {
          ...constraint,
          tideStation: { uuid: null, name: 'None found' },
        },
      }
    }
    return routeConstraint
  })
}
