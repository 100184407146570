import findLineCircleIntersection from 'src/utils/findLineCircleIntersection'
import { distanceBetween, findClosestPoint } from 'src/utils/findClosestPoint'

function calculateRotationAngle(pt, centroid, ringRadius) {
  const [x2, y2] = pt
  const [cx, cy] = centroid
  let intersections

  if (x2 === cx) {
    // line is vertical
    intersections = [[cx, cy + ringRadius], [cx, cy - ringRadius]]
  } else if (y2 === cy) {
    // line is horizontal
    intersections = [[cx - ringRadius, cy], [cx + ringRadius, cy]]
  } else {
    // calculate line y = mx + c, find nearest intersection with circle
    const m = (y2 - cy) / (x2 - cx)
    const c = y2 - x2 * m
    intersections = findLineCircleIntersection(m, c, cx, cy, ringRadius)
  }

  // calculate the angle (theta) of rotation
  const nearestIntersection = findClosestPoint([x2, y2], intersections)
  const d = distanceBetween([cx + ringRadius, cy], nearestIntersection)
  let theta = Math.acos(1 - 0.5 * Math.pow(d / ringRadius, 2))
  if (nearestIntersection[1] > cy) {
    theta = 2 * Math.PI - theta
  }

  // return the rotation angle in degrees
  return (theta * 180) / Math.PI
}

export default calculateRotationAngle
