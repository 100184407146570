import React from 'react'
import PropTypes from 'prop-types'
import { Rect, Group } from 'react-konva'

import {
  CHART_SHAPE_TYPE,
  CHART_SYMBOL_RENDER_TYPE,
} from 'src/utils/drawingConstants'
import SvgSymbol from 'src/components/atoms/ChartSymbol/SvgSymbol'
import ImageSymbol from 'src/components/atoms/ChartSymbol/ImageSymbol'
import { getSymbolInstanceRenderData } from 'src/utils/drawingHelpers'

const ChartSymbol = ({
  name,
  variant,
  color,
  x,
  y,
  scaleX,
  scaleY,
  draggable,
  onDragStart,
  onDragMove,
  onDragEnd,
  rotation,
  padding,
  ...props
}) => {
  const renderData = getSymbolInstanceRenderData(variant)
  const { width, height } = renderData

  if (!renderData) {
    // unknown symbol variant
    return null
  }

  let SymbolComponent

  switch (renderData.type) {
    case CHART_SYMBOL_RENDER_TYPE.SVG:
      SymbolComponent = SvgSymbol
      break
    case CHART_SYMBOL_RENDER_TYPE.IMAGE:
      SymbolComponent = ImageSymbol
      break
    default:
      SymbolComponent = null
  }

  if (!SymbolComponent) {
    return null
  }

  return (
    <Group
      name={name}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      x={x}
      y={y}
    >
      <SymbolComponent
        renderData={renderData}
        color={color}
        variant={variant}
        draggable
        rotation={rotation}
        scaleX={scaleX}
        scaleY={scaleY}
        {...props}
      />
      <Rect
        isSelectable
        type={CHART_SHAPE_TYPE.SYMBOL}
        name={name}
        offsetX={0.5 * (width + padding / scaleX)}
        offsetY={0.5 * (height + padding / scaleY)}
        width={width + padding / scaleX}
        height={height + padding / scaleY}
        fill="rgba(0,0,0,0)"
        rotation={rotation}
        scaleX={scaleX}
        scaleY={scaleY}
      />
    </Group>
  )
}

ChartSymbol.propTypes = {
  name: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  scaleX: PropTypes.number,
  scaleY: PropTypes.number,
  padding: PropTypes.number,
  rotation: PropTypes.number,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragMove: PropTypes.func,
  onDragEnd: PropTypes.func,
}

export default ChartSymbol
