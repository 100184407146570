import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { pilotageAttachmentsRequest } from 'src/store/pilotage/actions'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { ATTACHMENTS_EXPIRY_INTERVAL } from 'src/utils/constants'

const useAttachmentsRefresh = pilotageUuid => {
  const dispatch = useDispatch()
  const isOnline = useSelector(isOnlineSelector)
  const lastUuid = useRef(null)
  const isVisible = useRef(document.visibilityState === 'visible')
  const isOnlineRef = useRef(isOnline)

  const loadAttachments = uuid => {
    if (uuid) {
      dispatch(pilotageAttachmentsRequest(uuid))
    }
  }

  const refresh = () => {
    if (isOnlineRef.current && isVisible.current) {
      loadAttachments(lastUuid.current)
    }
  }

  useEffect(() => {
    const timer = setInterval(refresh, ATTACHMENTS_EXPIRY_INTERVAL)
    refresh()

    const visibilityChange = () => {
      isVisible.current = document.visibilityState === 'visible'
      if (isVisible.current) {
        refresh()
      }
    }

    document.addEventListener('visibilitychange', visibilityChange)

    return () => {
      clearInterval(timer)
      document.removeEventListener('visibilitychange', visibilityChange)
    }
  }, [])

  useEffect(
    () => {
      lastUuid.current = pilotageUuid
      refresh()
    },
    [pilotageUuid]
  )

  useEffect(
    () => {
      const previousOnline = isOnlineRef.current
      isOnlineRef.current = isOnline
      if (!previousOnline && isOnline) {
        refresh()
      }
    },
    [isOnline]
  )
}

export default useAttachmentsRefresh
