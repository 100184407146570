import {
  SET_UNSAVED_PILOTAGE_CHANGES,
  SET_UNSAVED_VESSEL_CHANGES,
  SET_ONLINE_STATUS,
  UPDATE_AVAILABLE,
  SET_SERVICE_WORKER_STATUS,
  SET_UNSAVED_STATUS,
} from 'src/store/ui/actions'
import { getOnlineStatus } from './../../utils/offline'

const INITIAL_STATE = {
  pilotageUnsavedChanges: false,
  vesselUnsavedChanges: false,
  isOnline: getOnlineStatus(),
  updateAvailable: false,
  serviceWorkerAvailable: false,
  unsavedEntityStatus: {},
}

const uiReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_UNSAVED_PILOTAGE_CHANGES:
      return { ...state, pilotageUnsavedChanges: payload }
    case SET_UNSAVED_VESSEL_CHANGES:
      return { ...state, vesselUnsavedChanges: payload }
    case SET_ONLINE_STATUS:
      return { ...state, isOnline: payload }
    case SET_SERVICE_WORKER_STATUS:
      return { ...state, serviceWorkerAvailable: payload }
    case UPDATE_AVAILABLE:
      return { ...state, updateAvailable: true }
    case SET_UNSAVED_STATUS: {
      const { entityName, isUnsaved } = payload
      return {
        ...state,
        unsavedEntityStatus: {
          ...state.unsavedEntityStatus,
          [entityName]: isUnsaved,
        },
      }
    }
    default:
      return state
  }
}

export default uiReducer
