import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import {
  getPilotageThread,
  updatePilotageThread,
  createPilotageThread,
} from 'src/utils/api/thread'
import { prefetchPilotageThread } from 'src/store/prefetch/actions'

const PILOTAGE_THREAD_BASE = 'PILOTAGE_THREAD'
const PILOTAGE_THREAD_ADD_COMMENT_BASE = 'PILOTAGE_THREAD_ADD_COMMENT'

export const [
  PILOTAGE_THREAD_REQUEST,
  PILOTAGE_THREAD_SUCCESS,
  PILOTAGE_THREAD_ERROR,
  PILOTAGE_THREAD_CANCEL,
  PILOTAGE_THREAD_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_THREAD_BASE)

export const [
  PILOTAGE_THREAD_ADD_COMMENT_REQUEST,
  PILOTAGE_THREAD_ADD_COMMENT_SUCCESS,
  PILOTAGE_THREAD_ADD_COMMENT_ERROR,
  PILOTAGE_THREAD_ADD_COMMENT_CANCEL,
  PILOTAGE_THREAD_ADD_COMMENT_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_THREAD_ADD_COMMENT_BASE)

export const pilotageThreadRequest = (uuid, opts) => async dispatch => {
  dispatch(pilotageThreadInProgress())

  try {
    const response = await getPilotageThread(uuid)
    let data = getSinglePayloadFromResponse(response) || null
    if (!opts || !opts.isPrefetch) {
      dispatch(pilotageThreadLoaded(data))
    }
    return true
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(
        pilotageThreadError(
          error,
          opts && 'showToast' in opts ? opts.showToast : true
        )
      )
    }
    return false
  }
}

export const pilotageThreadInProgress = () =>
  createInProgressAction(PILOTAGE_THREAD_BASE)

export const pilotageThreadError = (error, showToast) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_THREAD_BASE, error))
  if (showToast) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.LOAD_PILOTAGE_THREAD_ERROR,
      })
    )
  }
}

export const pilotageThreadLoaded = data =>
  createSuccessAction(PILOTAGE_THREAD_BASE, data)

export const pilotageThreadAddCommentRequest = (
  pilotageUuid,
  thread,
  message
) => async dispatch => {
  dispatch(pilotageThreadAddCommentInProgress())

  try {
    const payload = {
      messages: [message],
      pilotage: {
        uuid: pilotageUuid,
      },
    }

    let response
    if (thread === null) {
      response = await createPilotageThread(payload)
    } else {
      payload.uuid = thread.uuid
      response = await updatePilotageThread(thread.uuid, payload)
    }

    const data = getSinglePayloadFromResponse(response)
    dispatch(pilotageThreadAddCommentLoaded(data))
    // prefetch the thread to re-cache the new result
    // only if we have to update the cache (second argument)
    dispatch(prefetchPilotageThread(pilotageUuid, true))
    return true
  } catch (error) {
    dispatch(pilotageThreadAddCommentError(error))
    return false
  }
}

export const pilotageThreadAddCommentInProgress = () =>
  createInProgressAction(PILOTAGE_THREAD_ADD_COMMENT_BASE)

export const pilotageThreadAddCommentError = error => dispatch => {
  dispatch(createErrorAction(PILOTAGE_THREAD_ADD_COMMENT_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.PILOTAGE_THREAD_ADD_COMMENT_ERROR,
    })
  )
}

export const pilotageThreadAddCommentLoaded = data => dispatch => {
  dispatch(createSuccessAction(PILOTAGE_THREAD_ADD_COMMENT_BASE, data))
  dispatch(
    addSuccessToast({
      message: TOAST_MESSAGES.PILOTAGE_THREAD_ADD_COMMENT_SUCCESS,
    })
  )
}
