import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'

import SmallButton from 'src/components/atoms/SmallButton'
import CircleButton from 'src/components/atoms/CircleButton'

const InfoBoxWrapper = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
})`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.background.light};
`

const InfoBoxTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

const InfoBoxDescription = styled.div`
  && {
    font-size: 14px;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`

const InfoBoxContent = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ variant }) => (variant === 'small' ? '12px' : 'inherit')};
`

const InfoBoxIconWrapper = styled(Box).attrs({ flex: 0, pr: 2 })`
  align-self: flex-start;
  & svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`

const InfoBox = ({
  title = null,
  largeTitle = null,
  description = null,
  children = null,
  buttonText = null,
  onButtonClick = null,
  loading = false,
  Icon = null,
  collapsible = false,
  initiallyCollapsed = false,
  extraPadding = false,
  mt = 0,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(
    collapsible && initiallyCollapsed
  )

  return (
    <InfoBoxWrapper
      py={2}
      px={extraPadding ? 4 : 3}
      mt={mt}
      {...props}
    >
      {Icon && (
        <InfoBoxIconWrapper>
          <Icon />
        </InfoBoxIconWrapper>
      )}
      <Box flex={1}>
        {largeTitle && <Typography variant="h1">{largeTitle}</Typography>}
        {title && <InfoBoxTitle>{title}</InfoBoxTitle>}
        {description && <InfoBoxDescription>{description}</InfoBoxDescription>}
        {(!collapsible || !isCollapsed) && (
          <InfoBoxContent variant={props.variant}>{children}</InfoBoxContent>
        )}
      </Box>
      {buttonText && (
        <SmallButton onClick={onButtonClick} disabled={loading}>
          {buttonText}
        </SmallButton>
      )}
      {collapsible && (
        <Box>
          <CircleButton
            size="small"
            dark
            primaryHighlight
            onClick={() => setIsCollapsed(isCollapsed => !isCollapsed)}
          >
            {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </CircleButton>
        </Box>
      )}
    </InfoBoxWrapper>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  largeTitle: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  loading: PropTypes.bool,
  Icon: PropTypes.elementType,
  collapsible: PropTypes.bool,
  initiallyCollapsed: PropTypes.bool,
  extraPadding: PropTypes.bool,
  mt: PropTypes.number,
  variant: PropTypes.string,
}

InfoBox.defaultProps = {
  mt: 1,
}

export default React.memo(InfoBox)
