import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import useSync from 'src/hooks/useSync'
import { SYNC_STATUS } from 'src/utils/constants'
import { Fade } from '@material-ui/core'
import { DateTime } from 'luxon'
import { ping } from './../../../utils/api/offline'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { sleep } from './../../../utils/ssoHelper'
import { isOnlineNavSelector } from 'src/store/ui/selectors'
import { EMPX_PING_LOG_ENABLED } from './../../../utils/constants'
import { featureFlagManualOnlineEnabled } from './../../../store/preferences/selectors'
import { setOnlinePingStatus } from 'src/store/onlinePing/actions'

const OfflineMessageWrapper = styled.div(
  ({ theme, isOnline }) => `
  background-color: ${!isOnline ? theme.palette.error.main : theme.palette.warning.main};
  bottom: 0;
  color: ${theme.palette.primary.contrastText};
  font-size: ${theme.typography.pxToRem(12)};
  font-weight: ${theme.weights.fontWeightMedium};
  padding: ${theme.spacing(0.5, 0.5, 0.5, 0.5)};
  position: fixed;
  text-align: center;
  width: 100%
  z-index: 100;
`
)

export const OfflineMessage = ({ isOnline }) => {
  const { stat } = useSync()
  const [loading, setLoading] = useState(false)
  const totalPending = stat[SYNC_STATUS.PENDING]
  const dispatch = useDispatch()
  const isOnlineNav = useSelector(isOnlineNavSelector)
  const manualOfflineEnabled = useSelector(featureFlagManualOnlineEnabled)

  if (EMPX_PING_LOG_ENABLED) {
    console.log(`OfflineMessage isOnline [${isOnline}] isOnlineNav [${isOnlineNav}]`)
  }
  const offlineOnClick = async () => {
    setLoading(true)
    const nowLuxon = DateTime.now()
    try {
      const now = nowLuxon.toFormat('hhmmss.SSS')
      if (EMPX_PING_LOG_ENABLED) {
        console.log(`now [${now}] pinging BE...`)
      }
      await sleep(1000)
      await ping()
      const elapsed = DateTime.now().valueOf() - nowLuxon.valueOf()
      if (EMPX_PING_LOG_ENABLED) {
        console.log(`elapsed [${elapsed}]ms reach BE`)
      }
      dispatch(setOnlinePingStatus(true))
    } catch (error) {
      const elapsed = DateTime.now().valueOf() - nowLuxon.valueOf()
      if (EMPX_PING_LOG_ENABLED) {
        console.log(`elapsed [${elapsed}]ms cannot reach BE`)
      }
    }
    setLoading(false)
  }
  return (
    <Fade in={!isOnline}>
      <OfflineMessageWrapper isOnline={manualOfflineEnabled ? isOnlineNav : false}>
        {manualOfflineEnabled &&
          <PrimaryButton
            onClick={() => offlineOnClick()}
            disabled={loading}
            style={{ marginBottom: 5 }}
          >
            Check eMPX Server
          </PrimaryButton>
        }
        {manualOfflineEnabled
          ? <div>
            {!isOnlineNav ? 'No Internet Connection' : 'Internet Connection is back (offline mode)'}
            {totalPending ? ` - You have unsaved changes` : ''}
          </div>
          : <div>
            No Internet Connection
            {totalPending ? ` - You have unsaved changes` : ''}
          </div>
        }

      </OfflineMessageWrapper>
    </Fade>
  )
}
OfflineMessage.propTypes = {
  isOnline: PropTypes.bool,
}

export default OfflineMessage
