import idx from 'idx'
import { format } from 'date-fns'
import {
  convertToTimeZone,
} from 'date-fns-timezone'
import { DateTime } from 'luxon'

import { missingValueText, PILOTAGE_MODE, TRANSFER_METHOD } from 'src/utils/constants'

export function pilotageDataToFormValues(pilotageData, timeZone) {
  let time, date
  if (pilotageData && pilotageData.date) {
    const dateLuxon = DateTime.fromISO(pilotageData.date).setZone(timeZone)
    date = convertToTimeZone(pilotageData.date, { timeZone })
    time = dateLuxon.toFormat('HH:mm')
  }

  return {
    time: time || '',
    date: date || '',
    movementType: idx(pilotageData, d => d.movementType) || '',
    from: idx(pilotageData, d => d.fromLocation.uuid) || '',
    to: idx(pilotageData, d => d.toLocation.uuid) || '',
    sideTo: idx(pilotageData, d => d.sideTo) || '',
    vesselIMO: String(idx(pilotageData, d => d.vessel.IMO) || ''),
    vesselName: idx(pilotageData, d => d.vessel.name) || '',
    vesselVisitCode: idx(pilotageData, d => d.vesselVisitCode) || '',
    vesselFwdDraft: idx(pilotageData, d => d.vesselFwdDraft && d.vesselFwdDraft.toFixed(2)) || '',
    vesselAftDraft: idx(pilotageData, d => d.vesselAftDraft && d.vesselAftDraft.toFixed(2)) || '',
    vesselDisplacement: idx(pilotageData, d => d.vesselDisplacement && d.vesselDisplacement.toFixed(2)) || '',
    vesselAgent: idx(pilotageData, d => d.vesselAgent) || '',
    vesselMaxDraft: idx(pilotageData, d => d.vesselMaxDraft && d.vesselMaxDraft.toFixed(2)) || '',
    outerEnd: idx(pilotageData, d => d.outerEnd) || '',
    innerEnd: idx(pilotageData, d => d.innerEnd) || '',
    bridgeMark: idx(pilotageData, d => d.bridgeMark) || '',
    route: idx(pilotageData, d => d.route.uuid) || '',
    // use missing value text, which defaults the input to the Unassigned option
    pilot: idx(pilotageData, d => d.pilot.uuid) || missingValueText,
    pilotSecond: idx(pilotageData, d => d.pilotSecond.uuid) || missingValueText,
    pilotageMode: idx(pilotageData, d => d.pilotageMode) || PILOTAGE_MODE.DIRECT,
    transferMethod: idx(pilotageData, d => d.transferMethod) || TRANSFER_METHOD.PILOT_LAUNCH,
    notes: idx(pilotageData, d => d.notes) || ''
  }
}

export function formValuesToPilotageData(values, timeZone, pilotageData) {
  const dateStr = format(values.date, 'YYYY/MM/DD')
  const dateLuxon = DateTime.fromFormat(`${dateStr} ${values.time}`, 'yyyy/MM/dd HH:mm', { zone: timeZone })
  const dateIso = dateLuxon.toISO()

  const castNumber = (value) =>
    value && !isNaN(Number(value))
        ? Number(value)
        : null

  const castNumberToFixed = (value, n) => {
    const number = castNumber(value)
    return number !== null ? number.toFixed(n) : null
  }

  const vesselFwdDraft = castNumber(values.vesselFwdDraft)
  const vesselAftDraft = castNumber(values.vesselAftDraft)
  const vesselDisplacement = castNumber(values.vesselDisplacement)
  const vesselMaxDraft = castNumber(values.vesselMaxDraft)

  const vesselMaxDraftCandidates = [vesselFwdDraft, vesselAftDraft].filter(n => typeof n === 'number')
  const vesselMaxDraftDerived = vesselMaxDraftCandidates.length === 0 ? null : Math.max(...vesselMaxDraftCandidates)

  // derive and set the vessel max draft if fwd or aft have changed, or there is no previous data
  const shouldDeriveMaxDraft = !pilotageData || (
    castNumberToFixed(vesselFwdDraft, 2) !== castNumberToFixed(pilotageData.vesselFwdDraft, 2) ||
    castNumberToFixed(vesselAftDraft, 2) !== castNumberToFixed(pilotageData.vesselAftDraft, 2)
  )

  const maxDraft = shouldDeriveMaxDraft
    ? vesselMaxDraftDerived
    : vesselMaxDraft

  const outerEnd = castNumber(values.outerEnd)
  const innerEnd = castNumber(values.innerEnd)
  const bridgeMark = castNumber(values.bridgeMark)

  const IMO = values.vesselIMO ? String(values.vesselIMO) : ''

  return {
    date: dateIso,
    movementType: values.movementType,
    fromLocation: {
      uuid: values.from,
    },
    toLocation: {
      uuid: values.to,
    },
    vessel: {
      IMO,
      name: values.vesselName,
    },
    vesselAftDraft,
    vesselDisplacement,
    vesselAgent: values.vesselAgent,
    vesselFwdDraft,
    vesselMaxDraft: maxDraft,
    vesselVisitCode: values.vesselVisitCode,
    outerEnd,
    innerEnd,
    bridgeMark,
    notes: values.notes,
    route: {
      uuid: values.route,
    },
    pilot: values.pilot === missingValueText ? null : { uuid: values.pilot },
    pilotSecond:
      values.pilotSecond === missingValueText
        ? null
        : { uuid: values.pilotSecond },
    sideTo: values.sideTo || null,
    pilotageMode: values.pilotageMode || PILOTAGE_MODE.DIRECT,
    transferMethod: values.transferMethod || TRANSFER_METHOD.PILOT_LAUNCH
  }
}
