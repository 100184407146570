import styled from 'styled-components'

import { Box } from '@material-ui/core'

export const FullscreenWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)}px;
`

export default FullscreenWrapper
