import React, { useCallback } from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import FreeTextField from 'src/components/atoms/FreeTextField'

export interface CommentProps {
  comment: string
  onChange: (comment: string) => void
  isDisabled: boolean
}

export const ChecklistComment: React.FC<CommentProps> = React.memo(
  ({ comment, onChange, isDisabled }) => {
    const onChangeCallback = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
      [onChange]
    )
    return (
      <>
        <Divider />
        <Box mb={2}>
          <Typography variant="h4">Other Comments or Notes</Typography>
        </Box>
        <FreeTextField
          placeholder="Enter any other comments or notes here"
          value={comment}
          onChange={onChangeCallback}
          disabled={isDisabled}
        />
      </>
    )
  }
)

ChecklistComment.displayName = 'ChecklistComment'
