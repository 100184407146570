import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router5'
import { PersistGate } from 'redux-persist/integration/react'
import router from 'src/router/router'
import storeInstance from 'src/store/store'
import App from 'src/containers/App'
import RootLayout from 'src/RootLayout'
import * as serviceWorker from 'src/serviceWorker'
import Loading from 'src/containers/Loading'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { Offline } from 'src/utils/sentry'
import { versionName, versionCode } from 'src/version.json'

if (process.env.REACT_APP_SENTRY_DSN) {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing(),
        new Offline()
      ],
      tracesSampleRate: 1.0,
      release: `${versionName}-${process.env.REACT_APP_ENV}-${versionCode}`,
      environment: process.env.REACT_APP_ENV
    })
  } catch (err) {
    console.log('Failed to initialise Sentry', err)
  }
}

// Disable perf debugger on production
if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)

  const Enzyme = require('enzyme')
  const Adapter = require('enzyme-adapter-react-16')

  Enzyme.configure({ adapter: new Adapter() })
}

const { store, persistor } = storeInstance

router.start(() => {
  ReactDOM.render(
    <Provider store={store}>
      <RouterProvider router={router}>
        <RootLayout>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <App />
          </PersistGate>
        </RootLayout>
      </RouterProvider>
    </Provider>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
