import { string } from 'yup'

function forgotPasswordValidator(values) {
  const errors = {}

  if (!values.email || values.email.trim().length === 0) {
    errors.email = 'Required'
  } else if (
    !string()
      .email()
      .isValidSync(values.email)
  ) {
    errors.email = 'Please enter a valid email address'
  }

  return errors
}

export default forgotPasswordValidator
