import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { pilotagePrefetchStatusByUuidSelector } from 'src/store/prefetch/selectors'
import {
  PREFETCH_PILOTAGE_IN_PROGRESS,
  PREFETCH_PILOTAGE_SUCCESS,
  PREFETCH_PILOTAGE_ERROR,
  prefetchPilotage,
} from 'src/store/prefetch/actions'
import PrefetchSuccessIcon from 'src/components/atoms/PrefetchSuccessIcon'
import PrefetchErrorIcon from 'src/components/molecules/PrefetchErrorIcon'
import PrefetchInProgressIcon from 'src/components/molecules/PrefetchInProgressIcon'
import { addErrorToast } from 'src/store/toast/actions'

const PrefetchStatusWrapper = styled.div`
  align-items: center;
  background: rgb(32, 41, 60);
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 71px;
`

export const PrefetchStatusIndicator = ({ pilotage }) => {

  const status = useSelector(
    pilotagePrefetchStatusByUuidSelector(pilotage.uuid)
  )
  const dispatch = useDispatch()
  let title
  let PrefetchButton
  switch (status) {
    case PREFETCH_PILOTAGE_IN_PROGRESS:
      title = 'Preparing for offline use'
      PrefetchButton = PrefetchInProgressIcon
      break
    case PREFETCH_PILOTAGE_SUCCESS:
      title = 'Ready for offline use'
      PrefetchButton = PrefetchSuccessIcon
      break
    case PREFETCH_PILOTAGE_ERROR:
      title = 'Prepare for offline use'
      PrefetchButton = PrefetchErrorIcon
      break
    default:
      PrefetchButton = PrefetchErrorIcon
  }

  const handlePrefetchButtonClick = async event => {
    event.stopPropagation()
    switch (status) {
      case PREFETCH_PILOTAGE_ERROR:
        try {
          await dispatch(prefetchPilotage(pilotage, true))
        } catch (error) {
          dispatch(
            addErrorToast({
              message: 'Error preparing for offline use',
            })
          )
        }
        break
      default:
        return false
    }
  }

  return status ? (
    <Tooltip title={title}>
      <PrefetchStatusWrapper onClick={handlePrefetchButtonClick}>
        <PrefetchButton fontSize="small" />
      </PrefetchStatusWrapper>
    </Tooltip>
  ) : null
}
PrefetchStatusIndicator.propTypes = {
  pilotage: PropTypes.object,
}

export default PrefetchStatusIndicator
