import api from 'src/utils/api/core'

export function getWeatherData (
  portUuid,
  fromDate,
  toDate,
  latitude,
  longitude,
  cfg = {}
) {
  return api.get('/weather', {
    params: { portUuid, fromDate, toDate, latitude, longitude },
    ...cfg,
  })
}

export function getWeatherDataMulti (
  weatherLocations,
  cfg = {}
) {
  return api.get('/weather/multi',
    {
      params: { weatherFilters: weatherLocations },
      ...cfg,
    })
}
