import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ToolIcon from 'src/components/atoms/ToolIcon'
import { featureFlagUseChartToolsGrid } from 'src/store/preferences/selectors'

const ChartMenuSectionContainer = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  padding: ${({ spacing, theme }) => theme.spacing(spacing)}px 0;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.palette.background.default : ''};

  & img {
    display: block;
    max-width: 24px;
    max-height: 24px;
  }
`

ChartMenuSectionContainer.propTypes = {
  isExpanded: PropTypes.bool,
}

const ExpandIconWrapper = styled.div`
  margin-right: -5px;

  & svg {
    display: block;
  }
`

const ChartMenuSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ChartMenuSection = ({
  disabled,
  isExpanded,
  label,
  current,
  options,
  renderItem,
  onSelect,
  onToggle,
  ...props
}) => {
  const chartToolsGridEnabled = useSelector(featureFlagUseChartToolsGrid)

  const itemClick = (e, opt) => {
    e.stopPropagation()
    if (!disabled) {
      onSelect(opt.value)
    }
  }

  return (
    <ChartMenuSectionContainer
      isExpanded={isExpanded}
      spacing={ chartToolsGridEnabled ? 1 : 2 }
      {...props}
      onClick={onToggle}
    >
      {label && (
        <ChartMenuSectionHeader>
          {label}
          { !chartToolsGridEnabled &&
          <ExpandIconWrapper>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandIconWrapper>
          }
        </ChartMenuSectionHeader>
      )}
      { !chartToolsGridEnabled &&
          isExpanded &&
          options.map(opt => (
            <ToolIcon
              disabled={disabled}
              key={opt.value}
              onClick={e => itemClick(e, opt)}
              isSelected={opt.value === current}
            >
              {renderItem(opt)}
            </ToolIcon>
          ))
      }
      { chartToolsGridEnabled &&
        <Grid container>
          {isExpanded &&
              options.map(opt => (
                <Grid key={opt.value} item xs={4}>
                  <ToolIcon
                    disabled={disabled}
                    key={opt.value}
                    onClick={e => itemClick(e, opt)}
                    isSelected={opt.value === current}
                  >
                    {renderItem(opt)}
                  </ToolIcon>
                </Grid>
              ))}
        </Grid>
      }
    </ChartMenuSectionContainer>
  )
}

ChartMenuSection.propTypes = {
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  label: PropTypes.string,
  current: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any })),
  renderItem: PropTypes.func,
  onSelect: PropTypes.func,
  onToggle: PropTypes.func,
}

export default ChartMenuSection
