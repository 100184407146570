import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, CircularProgress, Collapse } from '@material-ui/core'
import styled from 'styled-components'

import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Updater } from 'src/serviceWorker'
import { updateAvailableSelector } from 'src/store/ui/selectors'

const BannerWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 50px;
  padding: ${theme.spacing(0.5, 4)};
  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.primary.main};
`
)

const SmallLoader = styled(CircularProgress).attrs({ size: 24 })`
  && {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

const AppUpdateBanner = () => {
  const isUpdateAvailable = useSelector(updateAvailableSelector)
  const [isUpdating, setIsUpdating] = useState(false)

  const update = () => {
    setIsUpdating(true)
    Updater.installUpdate()
  }

  return (
    <Collapse in={isUpdateAvailable}>
      <BannerWrapper>
        {isUpdateAvailable && (
          <>
            <Box flex={1} pr={2} display="flex" alignItems="center">
              <InfoIcon />
              <Box pl={2}>An updated app version is available</Box>
            </Box>
            <Box
              flex="0 0 120px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isUpdating ? (
                <SmallLoader />
              ) : (
                <Button fullWidth onClick={update}>
                  Update
                </Button>
              )}
            </Box>
          </>
        )}
      </BannerWrapper>
    </Collapse>
  )
}

AppUpdateBanner.propTypes = {}

export default React.memo(AppUpdateBanner)
