import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Popover } from '@material-ui/core'

export const TugsPopover = styled(Popover).attrs({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
})`
  & .MuiPopover-paper {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`

const TugsPopoverContent = styled(Box).attrs({ display: 'flex', px: 2, py: 1 })`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  color: ${({ theme }) => theme.palette.text.dark};
  background-color: #f5f5f5;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
`

const TugsPopoverItemTonnage = styled(Box).attrs({ pr: 2, py: 1 })`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  font-weight: ${({ theme }) => theme.weights.fontWeightBold};
  border-right: 1px solid #ededed;
  min-width: 60px;
`

const TugsPopoverItemName = styled(Box).attrs({ pl: 2, py: 1 })`
  text-transform: uppercase;
`

export const TugsPopoverItem = ({ name, tonnage, onClick }) => (
  <TugsPopoverContent onClick={onClick}>
    <TugsPopoverItemTonnage>{tonnage}t</TugsPopoverItemTonnage>
    <TugsPopoverItemName>{name}</TugsPopoverItemName>
  </TugsPopoverContent>
)

TugsPopoverItem.propTypes = {
  name: PropTypes.string,
  tonnage: PropTypes.number,
  onClick: PropTypes.func,
}

export const TugsPopoverNoTugsAvailable = () => (
  <TugsPopoverContent>No tugs available</TugsPopoverContent>
)
