import { subDays } from 'date-fns'
import { PILOTAGE_DATE_THRESHOLD_DAYS } from '../constants'
import { REGEX_VESSEL_NAME_WITH_NUMBER_VALIDATION } from "./vesselValidator"

function pilotageValidator(values) {
  const errors = {}

  const optionalFields = [
    'sideTo',
    'vesselMaxDraft',
    'vesselAftDraft',
    'vesselDisplacement',
    'vesselAgent',
    'vesselFwdDraft',
    'vesselVisitCode',
    'outerEnd',
    'innerEnd',
    'bridgeMark',
    'notes',
    'pilot',
    'pilotageMode',
    'transferMethod'
  ]

  const fields = Object.keys(values)

  for (let i = 0, n = fields.length; i < n; i++) {
    const key = fields[i]
    if (
      Object.prototype.hasOwnProperty.call(values, key) &&
      (values[key] == null || values[key] === '') &&
      optionalFields.indexOf(key) === -1
    ) {
      errors[key] = 'Required field'
    }
  }

  if (
    !errors.time &&
    values.time &&
    !/^([0-1]{1}[0-9]{1}|2[0-3]{1}):([0-5]{1}[0-9]{1})$/.test(
      values.time.trim()
    )
  ) {
    errors.time = 'Invalid time'
  }

  if (!errors.date && values.date) {
    const pilotageDate = new Date(values.date).valueOf()
    const now = new Date()
    const thresholdDate = subDays(now, PILOTAGE_DATE_THRESHOLD_DAYS).valueOf()
    if (pilotageDate < thresholdDate) {
      errors.date = `Must not be older than ${PILOTAGE_DATE_THRESHOLD_DAYS} days`
    }
  }

  if (!errors.vesselIMO && isNaN(Number(values.vesselIMO))) {
    errors.vesselIMO = 'Must contain digits 0-9 only'
  }

  if (!errors.vesselIMO && String(values.vesselIMO).length !== 7) {
    errors.vesselIMO = 'IMO must be 7 digits long'
  }

  if (!REGEX_VESSEL_NAME_WITH_NUMBER_VALIDATION.test(values.vesselName)) {
    errors.vesselName = 'Only letters, numbers and the symbols ()._-/’'
  }

  if (
    !errors.vesselAftDraft &&
    (isNaN(Number(values.vesselAftDraft)) || Number(values.vesselAftDraft) < 0)
  ) {
    errors.vesselAftDraft = 'Please enter a non-negative number'
  }

  if (
    !errors.vesselDisplacement &&
    (isNaN(Number(values.vesselDisplacement)) || Number(values.vesselDisplacement) < 0)
  ) {
    errors.vesselDisplacement = 'Please enter a non-negative number'
  }

  if (
    !errors.vesselFwdDraft &&
    (isNaN(Number(values.vesselFwdDraft)) || Number(values.vesselFwdDraft) < 0)
  ) {
    errors.vesselFwdDraft = 'Please enter a non-negative number'
  }

  return errors
}

export default pilotageValidator
