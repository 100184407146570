import api from 'src/utils/api/core'

export function getPilotageThread(uuid) {
  return api.get(`/pilotage_threads?pilotageUuid=${uuid}`)
}

export function createPilotageThread(data) {
  return api.post(`/pilotage_threads`, data)
}

export function updatePilotageThread(threadUuid, data) {
  return api.put(`/pilotage_threads/${threadUuid}`, data)
}
