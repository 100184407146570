import React, { useState } from 'react'
import styled from 'styled-components'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import seaImage from 'src/assets/images/sea.jpg'

const ErrorUiWrapper = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(4)}px;
  background-color: ${theme.palette.background.light};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10vw;
  right: 10vw;
  border-radius: 16px;
  color: ${theme.palette.text.primary}
  
  & p {
    margin: ${theme.spacing(4, 0)};
  }
`
)

const ErrorUiBackground = styled.div`
  background-image: url(${seaImage});
  background-size: cover;
  width: 100vw;
  height: 100vh;
`

const ErrorUi = () => {
  const [isLoading, setIsLoading] = useState(false)
  const onReload = () => {
    setIsLoading(true)
    window.location.href = '/'
  }
  return (
    <ErrorUiBackground>
      <ErrorUiWrapper>
        <h1>Application Error</h1>
        <p>An application error occurred. Please try to reload the app.</p>
        <PrimaryButton disabled={isLoading} onClick={onReload}>
          Reload
        </PrimaryButton>
      </ErrorUiWrapper>
    </ErrorUiBackground>
  )
}

export default ErrorUi
