import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import {
  PrimaryText as BasePrimaryText,
  CalculationDivider,
  FieldLabel,
} from 'src/components/organisms/ConstraintDetails/common'
import {
  valueOrMissingText,
} from 'src/utils/formatters'
import { CONSTRAINT_TYPE } from 'src/utils/constants'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { routeCheckSave } from 'src/store/routeCheck/actions'
import { routeCheckStateSelector } from 'src/store/routeCheck/selectors'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import ConstraintDetailsAirDraft from 'src/components/organisms/ConstraintDetails/ConstraintDetailsAirDraft'
import ConstraintDetailsUkc from 'src/components/organisms/ConstraintDetails/ConstraintDetailsUkc'
import ConstraintDetailsUkcDyna from 'src/components/organisms/ConstraintDetails/ConstraintDetailsUkcDyna'
import ConstraintError from 'src/components/organisms/ConstraintDetails/ConstraintError'
import { timezoneSelector } from 'src/store/ports/selectors'
import {
  acceptTimeOnly,
  getTimeInputOnChange,
  getTimeInputOnBlur
} from 'src/utils/events'
import equal from 'fast-deep-equal'
import TextInput from 'src/components/atoms/TextInput'
import Tooltip from 'src/components/atoms/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'
import { formatIfNumber, isNumbersNotMatch } from './../../../utils/formatters'
import { DateTime } from 'luxon'
import { TIME_AT_CONSTRAINT_LOG_ENABLED } from './../../../utils/constants'
import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import { CheckboxTickIcon, CheckboxInactiveTickIcon } from './../../atoms/Icons/Checkbox'
import { featureFlagActiveDefault } from 'src/store/preferences/selectors'

const PrimaryText = styled(BasePrimaryText)`
  font-size: 14px;
  margin-right: 5px;
`
const PrimaryTextSmall = styled(BasePrimaryText)`
  font-size: 12px;
  margin-right: 5px;
`

const TimeLabelWrapped = styled.div`
  font-size: 14px;
  width: 80px;
  line-height: 1.2rem;
  padding-right: 5px;
  display: flex;
  align-items: center;
`

const ConstraintDetails = ({
  routeConstraint,
  activeConstraintPrevIsWaypoint,
  riskAssessment,
  readOnly,
  isFirst,
  renderConstraint,
  closeModal
}) => {

  const {
    routeCheck,
    calculation,
    pilotageStartTime,
    timeAtConstraint,
    cumulativeTimeBefore,
    overrides,
    setOverride,
    saved,
    time,
    timeRaw,
    hideConstraint,
  } = riskAssessment

  const dispatch = useDispatch()
  // start time
  const hours = `${Math.floor(time / 60)}`.padStart(2, '0')
  const minutes = `${time % 60}`.padStart(2, '0')
  const [ timeLocal, setTimeLocal ] = useState(`${hours}:${minutes}`)

  // time at constraint
  const timeZone = useSelector(timezoneSelector)
  let constraintTimeDayStr = null
  let constraintTimeHoursStr = null
  let constraintTimeMinsStr = null
  if (timeAtConstraint) {
    const dateLuxon = DateTime.fromMillis(timeAtConstraint.valueOf(), { zone: timeZone })
    constraintTimeDayStr = dateLuxon.toFormat('ccc')
    constraintTimeHoursStr = dateLuxon.toFormat('HH')
    constraintTimeMinsStr = dateLuxon.toFormat('mm')
  }
  const [ timeAtConstraintLocal, setTimeAtConstraintLocal ] = useState(`${constraintTimeHoursStr}:${constraintTimeMinsStr}`)
  const { data: pilotage } = useSelector(pilotageSelector)
  const { isSaving } = useSelector(routeCheckStateSelector)

  const isChanged = !equal(saved, overrides)
  const isEditable = !calculation.errors || !renderConstraint

  const activeDefaultEnabled = useSelector(featureFlagActiveDefault)

  if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
    console.log(`hours [${hours}] minutes [${minutes}] timeAtConstraint.valueOf() [${timeAtConstraint.valueOf()}]`)
  }

  const updateTimeByConstraintAtTime = (val) => {
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`updateTimeByConstraintAtTime val [${val}]`)
    }
    // convert time to datetime
    const dateLuxonOld = DateTime.fromMillis(timeAtConstraint.valueOf(), { zone: timeZone })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`dateLuxonOld [${dateLuxonOld}]`)
    }
    // set new time in datetime
    const dateLuxonNew = dateLuxonOld.set({
      'hours': val.split(':')[0],
      'minutes': val.split(':')[1],
    })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`dateLuxonNew [${dateLuxonNew}]`)
    }
    const timeDiff = dateLuxonNew.diff(dateLuxonOld, ['hours', 'minutes']).toObject()
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`timeDiff [${JSON.stringify(timeDiff)}]`)
    }
    // get time from datetime
    const timeOld = dateLuxonOld.set({
      'hours': hours,
      'minutes': minutes,
    })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`hours [${hours}] hours [${minutes}] timeOld [${timeOld}]`)
    }
    // add time diff to time
    const timeNew = timeOld.plus(
      {
        'hours': timeDiff.hours,
        'minutes': timeDiff.minutes,
      })
    const timeNewStr = timeNew.toFormat('HH:mm')
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`val [${val}] dateLuxonOld [${dateLuxonOld}] dateLuxonNew [${dateLuxonNew}]
      timeDiff [${JSON.stringify(timeDiff)}] timeOld [${timeOld}] timeNew [${timeNew}] timeNewStr [${timeNewStr}]`)
    }
    setOverride.time(timeNewStr)
    setTimeLocal(timeNewStr)
    return timeNewStr
  }

  const updateConstraintAtTimeByTime = (val) => {
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`updateConstraintAtTimeByTime val [${val}]`)
    }
    // convert time to datetime
    const dateLuxonOld = DateTime.fromMillis(timeAtConstraint.valueOf(), { zone: timeZone })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`dateLuxonOld [${dateLuxonOld}]`)
    }
    // get time from datetime
    const timeOld = dateLuxonOld.set({
      'hours': hours,
      'minutes': minutes,
    })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`hours [${hours}] minutes [${minutes}] timeOld [${JSON.stringify(timeOld)}]`)
    }
    // set new time in time
    const timeNew = timeOld.set({
      'hours': val.split(':')[0],
      'minutes': val.split(':')[1],
    })
    const timeDiff = timeNew.diff(timeOld, ['hours', 'minutes']).toObject()
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`timeDiff [${JSON.stringify(timeDiff)}]`)
    }
    // add time diff to datetime
    const dateLuxonNew = dateLuxonOld.plus({
      'hours': timeDiff.hours,
      'minutes': timeDiff.minutes,
    })
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`hours [${hours}] hours [${minutes}] timeOld [${timeOld}]`)
    }
    const dateLuxonNewStr = dateLuxonNew.toFormat('HH:mm')
    if (TIME_AT_CONSTRAINT_LOG_ENABLED) {
      console.log(`val [${val}] dateLuxonOld [${dateLuxonOld}] dateLuxonNew [${dateLuxonNew}]
      timeDiff [${JSON.stringify(timeDiff)}] timeOld [${timeOld}] timeNew [${timeNew}] dateLuxonNewStr [${dateLuxonNewStr}]`)
    }
    setTimeAtConstraintLocal(dateLuxonNewStr)
  }

  const onTimeAtConstraintChange = (event) => {
    // on change, invalid time at constraint can be inconsistent with time to constraint
    // it will readjust itself in onblur. No, simulate on change (to update value, followed by on blur)
    getTimeInputOnChange((val) => {
      return getTimeInputOnBlur((val2) => {
        return updateTimeByConstraintAtTime(val2 || `${constraintTimeHoursStr}:${constraintTimeMinsStr}`)
      })({ target: { value: val } })
    })(event)
    getTimeInputOnChange((val) => setTimeAtConstraintLocal(val))(event)
  }

  const onTimeAtConstraintBlur = (event) => {
    getTimeInputOnBlur((val) => {
      const newTime = updateTimeByConstraintAtTime(val || `${constraintTimeHoursStr}:${constraintTimeMinsStr}`)
      updateConstraintAtTimeByTime(newTime)
    })(event)
  }

  const onTimeChange = (event) => {
    getTimeInputOnChange(setOverride.time)(event)
    getTimeInputOnChange((val) => setTimeLocal(val))(event)
    getTimeInputOnChange((val) => {
      return getTimeInputOnBlur((val2) => {
        return updateConstraintAtTimeByTime(val2 || `${hours}:${minutes}`)
      })({ target: { value: val } })
    })(event)
  }

  const onTimeBlur = (event) => {
    getTimeInputOnBlur(setOverride.time)(event)
    getTimeInputOnBlur((val) => setTimeLocal(val || `${hours}:${minutes}`))(event)
    getTimeInputOnBlur((val) => updateConstraintAtTimeByTime(val || `${hours}:${minutes}`))(event)
  }

  const previousConstraintTime = pilotageStartTime && new Date(pilotageStartTime)
  if (previousConstraintTime && typeof cumulativeTimeBefore === 'number') {
    previousConstraintTime.setMinutes(
      previousConstraintTime.getMinutes() + cumulativeTimeBefore
    )
  }

  let previousConstraintTimeStr = null
  let previousConstraintTimeDayStr = null
  if (previousConstraintTime) {
    const dateLuxon = DateTime.fromMillis(previousConstraintTime.valueOf(), { zone: timeZone })
    previousConstraintTimeStr = dateLuxon.toFormat('HH:mm')
    previousConstraintTimeDayStr = dateLuxon.toFormat('ccc')
  }

  const onSaveOverrides = () => {
    const payload = {
      routeConstraint,
      pilotage: { uuid: pilotage.uuid },
      variables: { ...overrides },
      uuid: routeCheck && routeCheck.uuid
    }
    dispatch(routeCheckSave(payload))
    closeModal()
  }

  const renderContent = () => {
    if (riskAssessment.calculation.errors) {
      return <ConstraintError errors={riskAssessment.calculation.errors} />
    }

    const { constraint } = routeConstraint
    const constraintType = constraint.constraintType || CONSTRAINT_TYPE.UKC
    const props = { riskAssessment, readOnly }

    switch (constraintType) {
      case CONSTRAINT_TYPE.OHC:
        return <ConstraintDetailsAirDraft {...props} />
      case CONSTRAINT_TYPE.UKC:
        return <ConstraintDetailsUkc {...props} />
      case CONSTRAINT_TYPE.UKC_DYNA:
        return <ConstraintDetailsUkcDyna {...props} />
      default:
        return <Box py={2}>Unknown constraint type</Box>
    }
  }

  let overrideColourTime = isNumbersNotMatch(time, timeRaw, 0)

  return (
    <>
      <Box
        position="relative"
        mt={0}
        mb={2}
        // left={-32}
        // width="calc(100% + 64px)"
        px={1}
        bgcolor="background.default"
      >
        <Box display="flex" height={56} alignItems="center" justifyContent="space-between">

          <Box display="flex" alignItems="center">
            <TimeLabelWrapped>
              {
                isFirst ?
                  <>Pilotage start</> :
                  <>Last {activeConstraintPrevIsWaypoint ? 'waypoint' : 'constraint'}</>
              }
            </TimeLabelWrapped>
            <PrimaryTextSmall>
              {valueOrMissingText(previousConstraintTimeDayStr)}
            </PrimaryTextSmall>
            <PrimaryText>
              {valueOrMissingText(previousConstraintTimeStr)}
            </PrimaryText>
          </Box>

          <Box display="flex" alignItems="center">
            <TimeLabelWrapped>
            Time to {routeConstraint.constraint.isWaypoint ? 'waypoint' : 'constraint'}
            </TimeLabelWrapped>
            <div style={{paddingRight: 5}}>
              <Tooltip content={TOOLTIPS.CONSTRAINT_TIME} />
            </div>
            <TextInput
              id="time-override"
              readOnly={readOnly}
              value={timeLocal}
              overrideColour={overrideColourTime}
              formikField={{
                onChange: onTimeChange,
                onBlur: onTimeBlur,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                placeholder: `00:00`,
                autoComplete: 'off',
                onKeyDown: acceptTimeOnly
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 5,
                style: { padding: '7px 12px', fontSize: 14 }
              }}
              style={{ width: 70, marginRight: 5 }}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <TimeLabelWrapped>
            Time at {routeConstraint.constraint.isWaypoint ? 'waypoint' : 'constraint'}
            </TimeLabelWrapped>
            <PrimaryTextSmall>{valueOrMissingText(constraintTimeDayStr)}</PrimaryTextSmall>
            <TextInput
              id="time-override"
              readOnly={readOnly}
              value={timeAtConstraintLocal}
              formikField={{
                onChange: onTimeAtConstraintChange,
                onBlur: onTimeAtConstraintBlur,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                placeholder: `00:00`,
                autoComplete: 'off',
                onKeyDown: acceptTimeOnly
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 5,
                style: { padding: '7px 12px', fontSize: 14 }
              }}
              style={{ width: 70 }}
            />
          </Box>
        </Box>
      </Box>
      {renderConstraint && renderContent()}
      {isEditable && (
        <>
          <CalculationDivider />
          <Box display="flex" justifyContent="flex-start" alignItems="center" mt={2}>
            { activeDefaultEnabled && (
            <>
              <Box>
                <Checkbox
                  checked={formatIfNumber(overrides.hideConstraint, 0, formatIfNumber(hideConstraint, 0, '0')) === '1'}
                  label={''}
                  labelSpacing={3}
                  checkedIcon={<CheckboxTickIcon />}
                  uncheckedIcon={<CheckboxInactiveTickIcon />}
                  onChange={(e) => e.target.checked ? setOverride.hideConstraint('1') : setOverride.hideConstraint('0')}
                  disabled={readOnly}
                />
              </Box>
              <Box pl={2} pr={3} display="flex" justifyContent="space-between">
                <FieldLabel>Hide</FieldLabel>
              </Box>
            </>
            )}
            <PrimaryButton
              style={{marginLeft: 'auto'}}
              disabled={!isChanged || isSaving}
              onClick={onSaveOverrides}
            >
              Save
            </PrimaryButton>
          </Box>
        </>
      )}
    </>
  )
}

ConstraintDetails.propTypes = {
  readOnly: PropTypes.bool,
  isFirst: PropTypes.bool,
  renderConstraint: PropTypes.bool,
  routeConstraint: PropTypes.object,
  activeConstraintPrevIsWaypoint: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default React.memo(ConstraintDetails)
