import {
  PREFETCH_PILOTAGE_IN_PROGRESS,
  PREFETCH_PILOTAGE_SUCCESS,
  PREFETCH_PILOTAGE_ERROR,
  PREFETCH_COMMONS_IN_PROGRESS,
  PREFETCH_COMMONS_SUCCESS,
  PREFETCH_COMMONS_ERROR,
  PREFETCH_RESET,
  PREFETCH_CHART_IMAGE_SUCCESS,
  PREFETCH_CHART_IMAGE_ERROR,
  PREFETCH_CHART_IMAGE_IN_PROGRESS,
} from 'src/store/prefetch/actions'

const initialState = {
  pilotages: {},
  commons: null,
  tides: {},
  charts: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PREFETCH_COMMONS_IN_PROGRESS:
    case PREFETCH_COMMONS_SUCCESS:
    case PREFETCH_COMMONS_ERROR: {
      return {
        ...state,
        commons: type,
      }
    }
    case PREFETCH_PILOTAGE_SUCCESS:
    case PREFETCH_PILOTAGE_ERROR:
    case PREFETCH_PILOTAGE_IN_PROGRESS: {
      const { pilotages, ...rest } = state
      return {
        ...rest,
        pilotages: {
          ...pilotages,
          [payload.uuid]: payload,
        },
      }
    }
    case PREFETCH_CHART_IMAGE_SUCCESS:
    case PREFETCH_CHART_IMAGE_ERROR:
    case PREFETCH_CHART_IMAGE_IN_PROGRESS: {
      const { charts = {}, ...rest } = state
      return {
        ...rest,
        charts: {
          ...charts,
          [payload.uuid]: type,
        },
      }
    }
    case PREFETCH_RESET:
      const { pilotages, ...restState } = state
      const pilotagesStatus = { ...pilotages }
      if (payload && pilotagesStatus[payload]) {
        delete pilotagesStatus[payload]
      }
      return payload
        ? {
            ...restState,
            pilotages: pilotagesStatus,
          }
        : {
            ...initialState,
          }
    default:
      return state
  }
}

export default reducer
