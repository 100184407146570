import { round } from 'src/utils/formatters'
import { calculateSafetyMargin } from 'src/utils/constraint'

function calculateUnderKeelClearance({
  constraint,
  value,
  maxDraft,
  tideValue,
  allowances,
  residualValue,
  overrides,
}) {
  const errors = []
  if (maxDraft == null) {
    errors.push('Vessel max. draft is missing')
  }

  if (errors.length) {
    return { errors }
  }

  const minAvailableDepth = round(value, 2)

  // use NaN for missing/non-numeric value, as empty string gets converted to 0.
  const maxDraftValue = typeof maxDraft === 'number' ? round(maxDraft, 2) : NaN

  const { calculatedSafetyMargin: calculatedSafetyMarginRaw, unitUsed } = calculateSafetyMargin(constraint, maxDraftValue)

  const calculatedSafetyMargin = !isNaN(overrides.safetyMargin) ? Number(overrides.safetyMargin) : calculatedSafetyMarginRaw

  const requiredDepth = maxDraftValue + calculatedSafetyMargin + allowances

  const tide = round(tideValue, 2)
  const residual = round(residualValue, 2)
  const availableDepth = minAvailableDepth + tide + residual
  const result = round(availableDepth - requiredDepth, 2)
  const rawResult = round(availableDepth - maxDraftValue, 2)

  const variables = {
    maxDraft: maxDraftValue,
    minAvailableDepth,
    tideValue,
    calculatedSafetyMargin,
    calculatedSafetyMarginRaw,
    availableDepth,
    requiredDepth,
    unitUsed
  }

  return { result, variables, rawResult }
}

export default calculateUnderKeelClearance
