import React from 'react'
import { Group, Path } from 'react-konva'

import theme from 'src/styles/theme'
import { ChartSymbol } from 'src/types/ChartShape'

interface Props {
  shape: ChartSymbol
  angle: number
  radius: number
  zoom: number
  isSelected: boolean
}

/**
 * ChartVesselPointer is displayed with each vessel symbol when the chart is
 * to scale, the vessel dimensions have been set, and the resulting to-scale
 * vessel symbol size falls below a threshold. Each pointer outlines its
 * corresponding vessel symbol with a circle and renders an arrow pointing in
 * the direction of vessel movement.
 */
const ChartVesselPointer: React.FC<Props> = ({
  shape,
  angle,
  zoom,
  isSelected,
  radius,
}) => {
  // Use the angle from props if it's number, otherwise use the value stored on
  // the shape, otherwise default to 0 (no rotation)
  const rotation = (angle === null ? shape.attrs.rotation : angle) || 0

  // Position is already applied to the parent group containing the vessel pointer
  // so we can set the central coordinate of the pointer to [0, 0]
  const cx = 0
  const cy = 0

  // SVG path data for defining a circle
  const circlePathData = `M ${cx -
    radius}, ${cy} a ${radius},${radius} 0 1,0 ${radius *
    2},0 a ${radius},${radius} 0 1,0 -${radius * 2},0`

  // SVG path data for defining the arrow attached to the circle
  const arrowPathData = `M${cx + radius},${cy} L${cx +
    radius +
    16},${cy} M${cx + radius + 8},${cy + 6} L${cx + radius + 16},${cy} L${cx +
    radius +
    8},${cy - 6}`

  // Default the pointer color to dark grey, use primary color when selected
  const color = isSelected ? theme.palette.primary.main : '#333'

  return (
    <Group rotation={-rotation} scaleX={1 / zoom} scaleY={1 / zoom}>
      <Path
        listening={false}
        data={arrowPathData}
        stroke={color}
        strokeWidth={1.5}
      />
      <Path
        isSelectable
        fill="rgba(0, 0, 0, 0)"
        name={shape.name}
        data={circlePathData}
        stroke={color}
        strokeWidth={1.5}
      />
    </Group>
  )
}

export default ChartVesselPointer
