import { createSelector } from 'reselect'
import { entitiesToSyncSelector } from 'src/store/sync/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'

import { createSyncUuid } from 'src/utils/sync/pilotageTugs'

export const pilotageTugsSelector = state => state.pilotageTugs

export const pilotageTugsDataSelector = createSelector(
  pilotageTugsSelector,
  pilotageUuidSelector,
  entitiesToSyncSelector,
  (state, pilotageUuid, entitiesToSync) => {
    const synUuid = pilotageUuid && createSyncUuid(pilotageUuid)
    return entitiesToSync[synUuid] || (state && state.data)
  }
)
