import {
  DRAWINGS_RESET,
  DRAWINGS_IN_PROGRESS,
  DRAWINGS_ERROR,
  DRAWINGS_SUCCESS,
  DRAWING_UPDATE_SUCCESS,
} from 'src/store/drawings/actions'
import { SYNC_ENTITY_SUCCESS } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import idx from 'idx'
import { CHART_SHAPE_TYPE } from 'src/utils/drawingConstants'

const initialState = {
  isLoading: false,
  data: null,
  error: null,
}

const drawingsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case DRAWINGS_RESET:
      return {
        ...state,
        data: null
      }
    case DRAWINGS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case DRAWINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.map(item => {
          // compatibility with old shapes - scale is now an array [scaleX, scaleY]
          const { attrs, type } = item
          if (type === CHART_SHAPE_TYPE.SYMBOL && !Array.isArray(attrs.scale)) {
            const scale = attrs.scale || 1
            return {
              ...item,
              attrs: {
                ...attrs,
                scale: [scale, scale],
              },
            }
          }
          return item
        }),
        error: null,
      }
    case DRAWINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }
    case DRAWING_UPDATE_SUCCESS: {
      let data = state.data || []
      const matchIndex = data.findIndex(item => item.uuid === payload.uuid)
      if (matchIndex !== -1) {
        // PUT response - existing drawing updated
        data = [
          ...data.slice(0, matchIndex),
          { ...data[matchIndex], metadata: payload.metadata }, // TODO: replace item when API fixed
          ...data.slice(matchIndex + 1),
        ]
      } else {
        // POST response - new drawing added
        data = [...data, payload]
      }
      return {
        ...state,
        data,
      }
    }
    case SYNC_ENTITY_SUCCESS: {
      const { type: syncType, pilotageUuid } = payload
      const { result } = action
      if (syncType !== SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS) {
        return state
      }
      let data = state.data || []
      const pilotageUuids = data.reduce((uuids, d) => {
        const { pilotage: uuid } = d
        return uuids.includes(uuid) ? uuids : [...uuids, uuid]
      }, [])
      if (!pilotageUuids.includes(pilotageUuid)) {
        return state
      }
      const { uuid } = result
      const index = data.findIndex(
        d => d.uuid === uuid && idx(d, _ => _.pilotage.uuid) === pilotageUuid
      )
      data =
        index > -1
          ? [
              ...data.slice(0, index),
              { ...data[index], metadata: result.metadata }, // TODO: replace item when API fixed
              ...data.slice(index + 1),
            ]
          : [...data, result]
      return {
        ...state,
        data,
      }
    }
    default:
      return state
  }
}

export default drawingsReducer
