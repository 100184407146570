import React from 'react'
import { useRoute } from 'react-router5'

import usePilotageLoader from 'src/hooks/usePilotageLoader'
import useDraftRedirect from 'src/hooks/useDraftRedirect'
import Loading from 'src/containers/Loading'

// Wraps a component dependent on a pilotage being loaded in an outer component
// which handles loading, conditional UI rendering, and draft redirects.
const withPilotageLoader = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const WithPilotageLoader: React.FC<P> = props => {
    const { route } = useRoute()
    const { id: pilotageId } = route.params
    const { data, isLoading } = usePilotageLoader(pilotageId)
    const { isRedirecting } = useDraftRedirect(pilotageId)

    if (
      !pilotageId ||
      (!data && isLoading) ||
      isRedirecting ||
      (data && data.uuid !== pilotageId)
    ) {
      return <Loading />
    }

    return <Component {...props} />
  }

  return WithPilotageLoader
}

export default withPilotageLoader
