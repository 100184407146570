import { useState, useMemo, useCallback } from 'react'
import { addHours, subHours } from 'date-fns'
import * as d3 from 'd3'
import { scaleTime, scaleLinear } from 'd3-scale'
import { formatToTimeZone } from 'date-fns-timezone'
import { useSelector } from 'react-redux'
import { tideStationsSelector } from 'src/store/route/selectors'
import quantize from 'src/utils/quantize'
import {
    TideGraphInputs,
    TideGraphProps,
    TideGraphTheme,
    TideMark,
    TideMarkSet,
    RiskAssessment,
    RiskViewModel,
    Tides
} from './types'
import { TideStation } from 'src/types/Tides'

const PILOTAGE_HOURS_MARGIN_DEFAULT = 3

const defaultTheme: TideGraphTheme = {
    waterFillColor: 'rgba(31,142,250,0.7)',
    waterLineColor: "rgb(31,142,250)",
    labelBackgroundColor: "rgba(0,0,0,0.3)",
    labelTextColor: '#FFF', 
    passColor: '#00DDAA',
    failColor: '#FF5080',
    textColor: '#FFF',
    showZoom: true,
    height: 235,
    dotRadius: 4,
    margin: { top: 0, right: 35, bottom: 20, left: 35 }
}

function getTideData(
    tideData: { [time: string]: number },
    fromTime: number,
    toTime: number
): TideMark[] {
    return Object
        .keys(tideData)
        .map(Number)
        .filter(time => time >= fromTime && time <= toTime)
        .sort()
        .map(time => ({
            time, 
            tide: tideData[time]
        }))
}

function detectInterval([first, second]: TideMark[]): number | undefined {
    return first && second && second.time - first.time
}

function upsample(data: TideMark[]) {

    const targetInterval = 60 * 1000 // 1 minute
    const interval = detectInterval(data)
    if (!interval || interval === targetInterval) { return data }

    const division = interval / targetInterval
    const upsampled: TideMark[] = []

    for (let i = 0; i <= data.length; i++) {
        const current = data[i]
        const next = data[i+1]
        if (next) {
            for (let d = 0; d <= division; d++) {
                upsampled.push(d3.interpolateObject(current, next)(d / division))
            }
        } else if (current) {
            upsampled.push(current)
        }
    }

    return upsampled
}

function useTideGraphTimeAndTideRange(
    riskAssessment: RiskAssessment[],
    tides: Tides,
    pilotageStartTime: number | Date,
    hoursMargin: number
) {
    return useMemo(() => {

        const lastConstraint: RiskAssessment | undefined = riskAssessment[riskAssessment.length - 1]
        
        const fromTime: number | undefined = pilotageStartTime && 
            subHours(pilotageStartTime, hoursMargin).valueOf()

        const toTime: number | undefined = lastConstraint && 
            addHours(lastConstraint.timeAtConstraint, hoursMargin).valueOf()

        // only include tide stations for the constraints
        const tideStationUuids = Object
        .keys(tides)
        .filter(uuid =>
            riskAssessment.find(risk => risk.tideStationUuid === uuid)
        )

        // we get the data so that we have no more than 1 point per pixel of width
        const tideMarks: TideMarkSet[] = []
        
        if (fromTime && toTime) {
            for (const tideStationUuid of tideStationUuids) {
                const tideStation = tides[tideStationUuid]

                if (tideStation && tideStation.data) {
                    const tideData = getTideData(tideStation.data, fromTime, toTime)

                    if (tideData[0]) {
                        tideMarks.push({
                            tideStationUuid,
                            data: tideData
                        })
                    }
                }
            }
        }

        const riskTides = riskAssessment
            .map(({ tide }) => tide)
            .filter(tide => typeof tide === 'number')

        const hour = 1000 * 60 * 60
        
        // take advantage of the fact the data is ordered to get min/max time faster
        const minTimeBase = Math.min(
            fromTime,
            ...tideMarks
                .map(({ data }) => data[0].time))
                
        const minTimeQuantized = quantize(
            minTimeBase - 0.0001,
            hour,
            true
        )

        const minTime = Math.min(minTimeBase, minTimeQuantized)

        const maxTimeBase = Math.max(
            toTime,
            ...tideMarks
                .map(({ data }) => data[data.length - 1].time))
            
        const maxTimeQuantized = quantize(
            maxTimeBase + 0.0001,
            hour,
            false
        )

        const maxTime = Math.max(maxTimeBase, maxTimeQuantized)

        const minTide = Math.min(
            ...riskTides,
            ...tideMarks
                .map(({ data }) => 
                    Math.min(...data.map(({ tide }) => tide)))) - 0.5 

        const maxTide = Math.max(
            ...riskTides,
            ...tideMarks
                .map(({ data }) => 
                    Math.max(...data.map(({ tide }) => tide)))) + 0.5

        const start = pilotageStartTime && new Date(pilotageStartTime).valueOf()

        const firstConstraintWithTideData = riskAssessment.find(({ tideStationUuid }) =>
            tides[tideStationUuid] && tides[tideStationUuid].data
        )

        const firstTideData = firstConstraintWithTideData && 
            tideMarks.find(({ tideStationUuid }) => 
                tideStationUuid === firstConstraintWithTideData.tideStationUuid
            )

        const startTideMark = firstTideData && 
            firstTideData.data.find(({ time }) => time >= start)

        const startTide = startTideMark && startTideMark.tide

        return {
            tideMarks,
            minTime,
            maxTime,
            minTide,
            maxTide,
            lastConstraint,
            start,
            startTide
        }
    }, [riskAssessment, tides, pilotageStartTime, hoursMargin])
}

function useRiskViewModels(
    riskAssessment: RiskAssessment[], // assumes at least 1
    tideMarks: TideMarkSet[], 
    tideStations: TideStation[],
    theme: TideGraphTheme,
    minTime: number, 
    maxTime: number,
    formatTime: (t: any) => string
): RiskViewModel[] {
    return useMemo(() => {

        const riskAssessmentsWithTideAndTime = riskAssessment.filter(risk =>
            risk.timeAtConstraint && risk.tide    
        )

        return riskAssessmentsWithTideAndTime.map((risk, index) => {

            const riskTime = risk.timeAtConstraint.valueOf()
            const highlight = risk.constraintStatus === 'passed' ? theme.passColor : theme.failColor
            const tideStation = tideStations.find(({ uuid }) => uuid === risk.tideStationUuid)
            const tideStationName = tideStation ? tideStation.name : '--'
            const labelText = !isNaN(risk.tide) ? 
                `${formatTime(risk.timeAtConstraint)} @ ${tideStationName}: ${Number(risk.tide).toFixed(2)}m` :
                ''
                            
            const tideMarkSet = tideMarks.find(({ tideStationUuid }) => 
                tideStationUuid === risk.tideStationUuid
            )

            const tideMarkDataUpsampled = tideMarkSet ? upsample(tideMarkSet.data) : []
            
            const prevConstraint: RiskAssessment | undefined = riskAssessmentsWithTideAndTime[index - 1]
            const nextConstraint: RiskAssessment | undefined = riskAssessmentsWithTideAndTime[index + 1]

            const beforeConstraintFromTime = prevConstraint ?
                prevConstraint.timeAtConstraint.valueOf() :
                minTime

            const afterConstraintUntilTime = nextConstraint ?
                nextConstraint.timeAtConstraint.valueOf() :
                maxTime

            const untilConstraintData = tideMarkSet && tideMarkDataUpsampled.filter(({ time }) => 
                time >= beforeConstraintFromTime &&
                time <= riskTime
            )

            const afterConstraintData = tideMarkSet && tideMarkDataUpsampled.filter(({ time }) => 
                time >= riskTime &&
                time <= afterConstraintUntilTime
            )
            
            return {
                untilConstraintData,
                afterConstraintData,
                prevConstraint,
                nextConstraint,
                highlight,
                labelText,
                riskTime, 
                riskTide: risk.tide
            }
        })

    }, [riskAssessment, theme.passColor, theme.failColor, tideMarks, minTime, maxTime, formatTime])
}

function useTideGraphLayout(
    containerWidth: number,
    theme: TideGraphTheme,
    minTime: number,
    maxTime: number,
    minTide: number,
    maxTide: number,
    labelCount: number
) {
    return useMemo(() => {

        const { margin } = theme
        const width = containerWidth - margin.left - margin.right
        const baseHeight = theme.height - margin.top - margin.bottom
        const neededHeight = 20 * (labelCount + 1)
        const height = Math.max(baseHeight, neededHeight)

        const x = scaleTime()
            .domain([
                minTime,
                maxTime
            ])
            .range([0, width]);

        const y = scaleLinear()
            .domain([
              minTide,
              maxTide
            ])
            
            .range([height, 0]);

        const tideLine = d3.line<TideMark>()
            // .curve(d3.curveCatmullRom)
            .x(d => x(d.time))
            .y(d => y(d.tide))

        const tideArea = d3.area<TideMark>()
            // .curve(d3.curveCatmullRom)
            .x(d => x(d.time))
            .y0(y(minTide))
            .y1(d => y(d.tide))  

        return {
            tideLine, 
            tideArea,
            width, 
            height,
            x, 
            y,
        }
    }, [
        containerWidth,
        theme.margin,
        theme.height,
        minTide,
        maxTide,
        minTime,
        maxTime,
        labelCount
    ])
}

export function useTideGraph({
    timeZone,
    tides,
    pilotageStartTime,
    riskAssessment,
    containerWidth,
    theme = defaultTheme,
    isMasterView
}: TideGraphInputs): TideGraphProps {

    const tideStations = useSelector(tideStationsSelector) as Array<{ uuid: string, name: string }>

    const [hoursMargin, setHoursMargin] = useState(PILOTAGE_HOURS_MARGIN_DEFAULT)

    const zoom = (increment: number) => {
        const next = hoursMargin + increment
        if (next >= 0.5 && next <= 12) {
            setHoursMargin(next)
        }
    }
    
    const formatTime = useCallback((t: any) => 
        formatToTimeZone(t, 'HH:mm', { timeZone }),
        [timeZone]
    )
    

    const {
        tideMarks,
        minTime,
        maxTime,
        minTide,
        maxTide,
        lastConstraint,
        start,
        startTide
    } = useTideGraphTimeAndTideRange(
        riskAssessment, 
        tides, 
        pilotageStartTime, 
        hoursMargin
    )


    const riskViewModels: RiskViewModel[] = useRiskViewModels(
        riskAssessment, 
        tideMarks, 
        tideStations,
        theme,
        minTime, 
        maxTime,
        formatTime
    )

    const riskViewModelsDedupedByLabel = useMemo(() => 
        riskViewModels.filter((risk, index) =>  
            !riskViewModels.slice(0, index).find(prev =>
                prev.labelText === risk.labelText
            )
        )
    , [riskViewModels])

    const hasAnyConstraintFailed = useMemo(() =>
        !!riskAssessment.find(({ constraintStatus }) =>
            constraintStatus !== 'passed'
        ),
        [riskAssessment]
    )

    const { 
        tideLine, 
        tideArea,
        width, 
        height,
        x, 
        y,
    } = useTideGraphLayout(
        containerWidth,
        theme,
        minTime,
        maxTime,
        minTide,
        maxTide,
        riskViewModelsDedupedByLabel.length
    )

    return {
        tides,
        startTide,
        minTime,
        maxTime,
        minTide,
        maxTide,
        width,
        height,
        theme,
        riskViewModels,
        riskViewModelsDedupedByLabel,
        tideArea,
        tideLine,
        x,
        y,
        start,
        lastConstraint,
        hasAnyConstraintFailed,
        isMasterView,
        formatTime,
        hoursMargin,
        zoom
    }
}
