import api from 'src/utils/api/core'

export function lookupRoutes(from, to) {
  return api.get(`/routes?fromUuid=${from}&toUuid=${to}`)
}

export function getRoute(uuid) {
  return api.get(`/routes/${uuid}`)
}

export function createRouteCheck(data) {
  return api.post(`/pilotages_route_checks`, data)
}

export function updateRouteCheck(uuid, data) {
  return api.put(`/pilotages_route_checks/${uuid}`, data)
}
