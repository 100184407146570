import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import {
  DarkBox,
  PrimaryText as BasePrimaryText,
  CalculationDivider,
  FieldLabel,
  HighlightedCalculationDivider,
} from 'src/components/organisms/ConstraintDetails/common'
import {
  valueOrMissingText,
} from 'src/utils/formatters'
import { CONSTRAINT_STATUS, DATETIME_FORMAT } from 'src/utils/constants'
import { timezoneSelector } from 'src/store/ports/selectors'
import TextInput from 'src/components/atoms/TextInput'
import { numberOrMissing } from './../../../utils/formatters'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import { formatToTimeZone } from 'date-fns-timezone'

const PrimaryTextSmall = styled(BasePrimaryText)`
  font-size: 12px;
  margin-right: 5px;
`

const TimeLabelWrapped = styled.div`
  font-size: 14px;
  line-height: 1.2rem;
  padding-right: 5px;
  display: flex;
  align-items: center;
`

const ConstraintDetailsDhi = ({
  readOnly,
  transitData,
  closeModal
}) => {
  const timeZone = useSelector(timezoneSelector)

  const renderContent = () => {
    const requiredDepth = transitData.maxDraft + transitData.squat + transitData.heel + transitData.waveResponse + transitData.safetyMargin
    const availableDepth = transitData.minimumDepth + transitData.tideExclOffset
    const remainingUkc = availableDepth - requiredDepth
    const staticUkc = availableDepth - transitData.maxDraft
    const constraintStatus = remainingUkc >= 0 && staticUkc >= 0 ? CONSTRAINT_STATUS.PASSED : CONSTRAINT_STATUS.FAILED
    let Icon = null
    if (constraintStatus === CONSTRAINT_STATUS.PASSED) {
      Icon = TickIcon
    } else if (constraintStatus === CONSTRAINT_STATUS.FAILED) {
      Icon = CrossIcon
    }
    const speedProfileDescription = transitData.speedProfileDescription

    return (
      <>
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Speed</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                {
                  <TextInput
                    value={valueOrMissingText(speedProfileDescription)}
                    readOnly
                    unit=""
                  />
                }
              </Box>
            </Box>
          </Box>
          <Box flex={1} ml={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Maximum Draft</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.maxDraft, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Min. Available Depth</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                {
                  <TextInput
                    value={numberOrMissing(transitData.minimumDepth, null, 2)}
                    readOnly
                    unit="m"
                  />
                }
              </Box>
            </Box>
          </Box>
          <Box flex={1} ml={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Squat</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.squat, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Predicted Tide (+)</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.tideExclOffset, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
          <Box flex={1} ml={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>Heel</FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.heel, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
          </Box>
          <Box width="50%" ml="auto" p={1} pl={4}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>
                  Wave Response
                </FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.waveResponse, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
          </Box>
          <Box width="50%" ml="auto" p={1} pl={4}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2}>
                <FieldLabel>
                  Safety Margin (+)
                </FieldLabel>
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  value={numberOrMissing(transitData.safetyMargin, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <HighlightedCalculationDivider />
        <Box display="flex">
          <DarkBox flex={1} mr={3} p={1} display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Available Depth</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(availableDepth, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </DarkBox>
          <DarkBox flex={1} ml={3} p={1} display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Required Depth</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(requiredDepth, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </DarkBox>
        </Box>
        <CalculationDivider />
        <Box display="flex">
          <Box flex={1} mr={3} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2} display="flex" justifyContent="space-between">
                <FieldLabel>Static UKC</FieldLabel>
                {Icon && <Icon size={20} />}
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                  errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                  value={numberOrMissing(staticUkc, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
          <Box flex={1} ml={2} p={1}>
            <Box display="flex" alignItems="center">
              <Box flex={1} pr={2} display="flex" justifyContent="space-between">
                <FieldLabel>Remaining UKC</FieldLabel>
                {Icon && <Icon size={20} />}
              </Box>
              <Box flex="0 0 125px">
                <TextInput
                  successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                  errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                  value={numberOrMissing(remainingUkc, null, 2)}
                  readOnly
                  unit="m"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box
        position="relative"
        mt={0}
        mb={2}
        px={1}
        bgcolor="background.default"
      >
        <Box display="flex" height={56} alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <TimeLabelWrapped>
            Time at transit
            </TimeLabelWrapped>
            <PrimaryTextSmall>{formatToTimeZone(transitData.timeOfMinimumUKC, DATETIME_FORMAT, { timeZone })}</PrimaryTextSmall>
          </Box>
          {/* <Box display="flex" alignItems="center">
            <TimeLabelWrapped>
            IMO
            </TimeLabelWrapped>
            <PrimaryTextSmall>{valueOrMissingText(transitData.imo)}</PrimaryTextSmall>
          </Box> */}
        </Box>
      </Box>
      {renderContent()}
    </>
  )
}

ConstraintDetailsDhi.propTypes = {
  readOnly: PropTypes.bool,
  transitData: PropTypes.object,
  closeModal: PropTypes.func,
}

export default React.memo(ConstraintDetailsDhi)
