import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Group, Image, Layer, Line, Rect, Stage } from 'react-konva'
import { Util } from 'konva'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'

import {
  canvasRedo,
  canvasUndo,
  setStageDimensions,
  setStageOffset,
} from 'src/store/canvas/actions'
import CircleButton from 'src/components/atoms/CircleButton'
import ChartSymbol from 'src/components/atoms/ChartSymbol'
import ChartTextInput from 'src/components/atoms/ChartTextInput'
import ChartLabel from 'src/components/atoms/ChartLabel'
import ChartVesselControls from 'src/components/atoms/ChartVesselControls'
import ChartArrow from 'src/components/atoms/ChartArrow'
import ChartRuler from 'src/components/atoms/ChartRuler'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import DarkButton from 'src/components/atoms/DarkButton'
import BerthingStageRotator from 'src/components/molecules/BerthingStageRotator'
import {
  BackSaveWrapper,
  CanvasContainer,
  MessageBackdrop,
  MessageWrapper,
  StageWrapper,
  UndoRedoWrapper,
} from 'src/components/organisms/DrawingCanvas/components'
import useCanvas from 'src/hooks/useCanvas'
import SvgSymbol from 'src/components/atoms/ChartSymbol/SvgSymbol'
import {
  CHART_SYMBOL_RENDER_DATA,
  CHART_TOOL,
  VESSEL_POINTER_SIZE_THRESHOLD,
  CHART_BASE_HIGHLIGHT_STROKE_WIDTH,
} from 'src/utils/drawingConstants'
import { PILOTAGE_STAGE_ID } from 'src/utils/constants'
import ChartVesselPointer from 'src/components/atoms/ChartVesselPointer/ChartVesselPointer'
import {
  canDisplayVesselToScale,
  canResizeSymbol,
  canRotateSymbol,
  convertChartPoints,
  getSymbolRenderDimensions,
  getScaledStrokeWidth,
} from 'src/utils/drawingHelpers'
import { usePrintCanvasImageConverter } from 'src/hooks/UsePrintCanvasImageConverter'

// Patch for IOS - see https://github.com/konvajs/konva/issues/187
Util.getRandomColor = () => {
  let randColor = ((Math.random() * 0xadcea0) << 0).toString(15)
  while (randColor.length < 6) {
    randColor = `0${randColor}`
  }
  return `#${randColor}`
}

const DrawingCanvas = ({
  stageId,
  state,
  menuState,
  data,
  chart,
  chartScale,
  onBack,
  onSave,
  isBerthingStage,
  readOnly,
  isMasterView,
  vessel,
  imageType,
  constrainedHeight,
}) => {
  const dispatch = useDispatch()

  // Reference to the canvas outer container
  const containerRef = useRef(null)

  // Reference to the parent element on the canvas where all added items are children
  const parentRef = useRef(null)

  usePrintCanvasImageConverter(isMasterView, containerRef.current, constrainedHeight)

  const canvas = useCanvas({
    canvasState: state,
    canvasMenuState: menuState,
    vessel,
    readOnly,
    isMasterView,
    isBerthingStage,
    chart,
    drawingData: data,
    stageId,
    containerRef,
    parentRef,
    imageType,
  })

  const {
    onWheel,
    onChartTouchStart,
    onChartTouchMove,
    onChartTouchEnd,
    onChartMouseUp,
    onChartMouseMove,
    onChartMouseDown,
    onChartDragStart,
    onChartDragEnd,
    containerDefocus,
    onShapeDragStart,
    onShapeDragMove,
    onShapeDragEnd,
    isDraggable,
  } = canvas.events

  const {
    berthingVesselAngle,
    shapes,
    chartImage,
    symbols,
    selectedSymbol,
    labels,
    freehandLines,
    highlights,
    straightLines,
    arrows,
    rulers,
    rotationAngle,
    symbolScale,
    dragOffset,
  } = canvas.data

  const {
    createTextLabel,
    cancelTextLabel,
    rotateBerthingStageVessel,
    finishLineExisting,
  } = canvas.actions

  const isToScale = canDisplayVesselToScale(chartScale, vessel)

  const isStageDraggable = () => isDraggable(false)

  const {
    stageDimensions,
    stageZoom,
    stageOffset,
    actionsStack,
    actionsStackPosition,
    selectedShapeName,
    activeLabelName,
    activeLabelValue,
    activeLabelPosition,
    hasUnsavedChanges,
    isLoading,
  } = state

  const { activeColor, activeTool } = menuState

  const containerListeners =
    readOnly || isMasterView
      ? {}
      : {
          onMouseOut: containerDefocus,
          onTouchEnd: containerDefocus,
        }

  const stageListeners =
    readOnly || isMasterView
      ? {}
      : {
          onMouseUp: finishLineExisting,
          onTouchEnd: finishLineExisting,
        }

  let rootGroupListeners = {
    onDragStart: onChartDragStart,
    onDragEnd: onChartDragEnd,
    onWheel,
  }

  if (!(readOnly || isMasterView)) {
    rootGroupListeners = {
      ...rootGroupListeners,
      onTouchStart: onChartTouchStart,
      onTouchMove: onChartTouchMove,
      onTouchEnd: onChartTouchEnd,
      onMouseDown: onChartMouseDown,
      onMouseMove: onChartMouseMove,
      onMouseUp: onChartMouseUp,
    }
  }

  const berthingStageScale = chartImage.dimensions[0] / (1024 - 105)

  useEffect(
    () => {
      if (isMasterView) {
        if (chartImage.img) {
          const { offsetWidth, offsetHeight } = containerRef.current
          const { naturalWidth, naturalHeight } = chartImage.img
          const ratio = offsetWidth / naturalWidth

          // Let the chart take as much width as possible, as long as the height remains restricted to 1000px.
          let width = offsetWidth
          let height = Math.min(naturalHeight, naturalHeight * ratio)
          if (height > 1000) {
            height = 1000
          }

          dispatch(setStageDimensions(stageId, [width, height]))
          dispatch(
            setStageOffset(stageId, [
              Math.max(0, (offsetWidth - width) / 2),
              Math.max(0, (offsetHeight - height) / 2),
            ])
          )
        } else if (
          stageId === PILOTAGE_STAGE_ID.DEPARTURE_BERTHING ||
          stageId === PILOTAGE_STAGE_ID.ARRIVAL_BERTHING
        ) {
          // For berthing stage drawings, use full width and 500px height.
          dispatch(
            setStageDimensions(stageId, [containerRef.current.offsetWidth, 500])
          )
          dispatch(setStageOffset(stageId, [0, 0]))
        }
      }
    },
    [chartImage.img]
  )

  const getSymbolRenderDims = shape =>
    getSymbolRenderDimensions({
      shape,
      selectedShapeName,
      isToScale,
      isMasterView,
      chartScale,
      symbolScale,
      chartImage,
      vessel,
      stageZoom,
      vesselPointerSizeThreshold: VESSEL_POINTER_SIZE_THRESHOLD,
    })

  const getControlsRadius = () => {
    const {
      vesselPointerRadius,
      symbolPadding,
      maxVisibleDimension,
    } = getSymbolRenderDims(selectedSymbol)

    const controlsRadius =
      vesselPointerRadius > 0
        ? vesselPointerRadius * 2
        : 0.5 * maxVisibleDimension + symbolPadding

    // adjust for zoom, to keep it the same size, add a little padding
    return (controlsRadius + 10) / stageZoom
  }

  // Only use the custom StageWrapper in the pilot app (full screen experience)
  const StageContainer = useMemo(
    () =>
      isMasterView ? ({ children }) => <div>{children}</div> : StageWrapper, // eslint-disable-line react/display-name,react/prop-types
    [isMasterView]
  )

  // swaps any white shapes to black if on master view and berthing stage because
  // berthing stage has a very light background and white symbols would not have enough contrast
  const ensureContrast = (color) => {
    if (!(isMasterView && isBerthingStage)) { return color }
    return color === '#FFF' ? '#000' : color
  }

  return (
    <CanvasContainer
      ref={containerRef}
      readOnly={readOnly || isMasterView}
      {...containerListeners}
      style={{
        border: isBerthingStage && isMasterView ? '1px solid rgba(0,0,0,0.07)' : undefined,
        background: isMasterView ? 'transparent' : undefined
      }}
    >
      <div className="img-container">
        {chart && chartImage.img && chartImage.loaded && isMasterView && (
          <img className="chart-image" src={chartImage.img.src} alt="Chart" />
        )}
      </div>
      {chart && !chartImage.loaded && (
        <MessageBackdrop>
          <MessageWrapper>Loading...</MessageWrapper>
        </MessageBackdrop>
      )}
      {!isBerthingStage && chart === null && (
        <MessageBackdrop>
          <MessageWrapper>Missing chart</MessageWrapper>
        </MessageBackdrop>
      )}
      {!isMasterView && (
        <>
          <BackSaveWrapper>
            <DarkButton variant="outlined" onClick={onBack}>
              Back
            </DarkButton>
            <PrimaryButton
              disabled={!hasUnsavedChanges || isLoading}
              onClick={() => onSave(shapes)}
            >
              Save
            </PrimaryButton>
          </BackSaveWrapper>
          <UndoRedoWrapper>
            <CircleButton
              dark
              onClick={() => dispatch(canvasUndo(stageId))}
              disabled={actionsStackPosition === -actionsStack.length}
            >
              <UndoIcon />
            </CircleButton>
            <CircleButton
              dark
              onClick={() => dispatch(canvasRedo(stageId))}
              disabled={actionsStackPosition === 0}
            >
              <RedoIcon />
            </CircleButton>
          </UndoRedoWrapper>
          {activeLabelPosition && (
            <ChartTextInput
              activeLabelValue={activeLabelValue}
              left={activeLabelPosition[0]}
              top={activeLabelPosition[1]}
              color={activeColor}
              onCreate={createTextLabel}
              onCancel={cancelTextLabel}
              chartDimensions={chartImage && chartImage.dimensions}
            />
          )}
          {isBerthingStage && (
            <BerthingStageRotator
              onRotate={rotateBerthingStageVessel}
              disabled={readOnly}
            />
          )}
        </>
      )}
      <StageContainer>
        <Stage
          width={stageDimensions[0]}
          height={stageDimensions[1]}
          {...stageListeners}
        >
          <Layer>
            <Group
              ref={parentRef}
              x={isBerthingStage ? 0 : stageOffset[0]}
              y={isBerthingStage ? 0 : stageOffset[1]}
              scaleX={stageZoom}
              scaleY={stageZoom}
              name="root"
              draggable={!isBerthingStage && isStageDraggable()}
              {...rootGroupListeners}
            >
              {chartImage.img && chartImage.loaded && !isMasterView ? (
                <Image name="chart" image={chartImage.img} />
              ) : (
                <Rect
                  name="chart"
                  x={0}
                  y={0}
                  width={stageDimensions[0]}
                  height={stageDimensions[1]}
                  fill={isBerthingStage && !isMasterView ? 'rgba(32, 41, 60)' : ''}
                />
              )}
              {isBerthingStage && (
                <SvgSymbol
                  rotation={berthingVesselAngle}
                  renderData={CHART_SYMBOL_RENDER_DATA.BERTHING_STAGE_VESSEL}
                  x={stageDimensions[0] * 0.5}
                  y={stageDimensions[1] * 0.5}
                  scaleX={0.8 * berthingStageScale}
                  scaleY={0.8 * berthingStageScale}
                  color={ensureContrast('#FFF')}
                />
              )}
              {symbols.map(shape => {
                const { name, attrs } = shape

                const isRotating =
                  name === selectedShapeName && rotationAngle !== null

                const shapeRotation = -(isRotating
                  ? rotationAngle
                  : attrs.rotation || 0)

                const dimensions = getSymbolRenderDims(shape)
                const { symbolPadding } = dimensions
                let { scaleX, scaleY, vesselPointerRadius } = dimensions

                const isShapeSelected =
                  selectedSymbol && selectedSymbol.name === name

                const x =
                  attrs.x * chartImage.dimensions[0] +
                  (isBerthingStage ? stageOffset[0] : 0)

                const y =
                  attrs.y * chartImage.dimensions[1] +
                  (isBerthingStage ? stageOffset[1] : 0)

                return (
                  <Group
                    key={name}
                    name={name}
                    x={x}
                    y={y}
                    draggable={isDraggable()}
                    onDragStart={onShapeDragStart}
                    onDragMove={onShapeDragMove}
                    onDragEnd={onShapeDragEnd}
                  >
                    {vesselPointerRadius > 0 && (
                      <ChartVesselPointer
                        shape={shape}
                        isSelected={isShapeSelected}
                        angle={isShapeSelected ? rotationAngle : null}
                        radius={vesselPointerRadius}
                        zoom={stageZoom}
                        chartDimensions={chartImage.dimensions}
                        chartOffset={isBerthingStage ? stageOffset : [0, 0]}
                        dragOffset={isShapeSelected ? dragOffset : [0, 0]}
                      />
                    )}
                    <ChartSymbol
                      name={name}
                      variant={attrs.variant}
                      color={ensureContrast(attrs.color)}
                      rotation={shapeRotation}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      zoom={stageZoom}
                      padding={symbolPadding}
                      opacity={
                        [
                          CHART_SYMBOL_RENDER_DATA.SHIP_MAIN.variant,
                          CHART_SYMBOL_RENDER_DATA.SHIP.variant
                        ].includes(attrs.variant) ? 0.6 : undefined
                      }
                    />
                  </Group>
                )
              })}
              {freehandLines.map(({ name, attrs }) => (
                <Line
                  key={name}
                  name={name}
                  stroke={ensureContrast(attrs.color)}
                  strokeWidth={2}
                  lineCap="round"
                  lineJoin="round"
                  listening={activeTool === CHART_TOOL.ERASER}
                  tension={0.5}
                  points={convertChartPoints(
                    attrs.points,
                    chartImage.dimensions,
                    isBerthingStage ? chartImage.offset : null
                  )}
                />
              ))}
              {straightLines.map(({ name, attrs }) => (
                <Line
                  key={name}
                  name={name}
                  stroke={ensureContrast(attrs.color)}
                  strokeWidth={2}
                  lineCap="round"
                  listening={activeTool === CHART_TOOL.ERASER}
                  points={convertChartPoints(
                    attrs.points,
                    chartImage.dimensions,
                    isBerthingStage ? chartImage.offset : null
                  )}
                />
              ))}
              {arrows.map(({ name, attrs }) => (
                <ChartArrow
                  key={name}
                  name={name}
                  color={ensureContrast(attrs.color)}
                  points={attrs.points}
                  version={attrs.version}
                  chartDimensions={chartImage.dimensions}
                  listening={activeTool === CHART_TOOL.ERASER}
                  offset={isBerthingStage ? chartImage.offset : null}
                />
              ))}
              {rulers.map(({ name, attrs }) => (
                <ChartRuler
                  version={attrs.version}
                  key={name}
                  name={name}
                  stroke={ensureContrast(attrs.color)}
                  listening={activeTool === CHART_TOOL.ERASER}
                  chartFullSizeScaleFactor={chartImage.fullSizeScaleFactor || 1}
                  chartScale={chartScale}
                  chartDimensions={chartImage.dimensions}
                  points={convertChartPoints(
                    attrs.points,
                    chartImage.dimensions,
                    isBerthingStage ? chartImage.offset : null
                  )}
                />
              ))}
              {labels.map(label => (
                <ChartLabel
                  key={label.name}
                  label={{
                    ...label,
                    attrs: {
                      ...label.attrs,
                      color: ensureContrast(label.attrs.color)
                    }
                  }}
                  zoom={stageZoom}
                  chartDimensions={chartImage.dimensions}
                  chartOffset={isBerthingStage ? chartImage.offset : null}
                  draggable={isDraggable()}
                  onDragStart={onShapeDragStart}
                  onDragMove={onShapeDragMove}
                  onDragEnd={onShapeDragEnd}
                  isEditing={label.name === activeLabelName}
                />
              ))}
              {highlights.map(({ name, attrs }) => {
                const strokeWidth =
                  attrs.version === 1
                    ? getScaledStrokeWidth(chartImage.dimensions[0])
                    : CHART_BASE_HIGHLIGHT_STROKE_WIDTH

                return (
                  <Line
                    key={name}
                    name={name}
                    stroke={ensureContrast(attrs.color)}
                    strokeWidth={strokeWidth}
                    lineCap="round"
                    lineJoin="round"
                    listening={activeTool === CHART_TOOL.ERASER}
                    tension={0.5}
                    points={convertChartPoints(
                      attrs.points,
                      chartImage.dimensions,
                      isBerthingStage ? chartImage.offset : null
                    )}
                  />
                )
              })}
              {selectedSymbol &&
                canRotateSymbol(selectedSymbol.attrs.variant) && (
                  <ChartVesselControls
                    shape={selectedSymbol}
                    angle={rotationAngle}
                    radius={getControlsRadius()}
                    zoom={stageZoom}
                    resizeEnabled={
                      canResizeSymbol(selectedSymbol.attrs.variant, isToScale)
                    }
                    chartDimensions={chartImage.dimensions}
                    chartOffset={isBerthingStage ? stageOffset : [0, 0]}
                    dragOffset={dragOffset}
                  />
                )}
            </Group>
          </Layer>
        </Stage>
      </StageContainer>
    </CanvasContainer>
  )
}

DrawingCanvas.propTypes = {
  stageId: PropTypes.string,
  chart: PropTypes.shape({
    data: PropTypes.object,
    expires: PropTypes.number,
  }),
  chartScale: PropTypes.number,
  isBerthingStage: PropTypes.bool,
  data: PropTypes.shape({
    metadata: PropTypes.object,
  }),
  menuState: PropTypes.shape({
    activeColor: PropTypes.string,
    activeTool: PropTypes.string,
    activeSymbol: PropTypes.string,
  }),
  state: PropTypes.shape({
    stageOffset: PropTypes.arrayOf(PropTypes.number),
    stageDimensions: PropTypes.arrayOf(PropTypes.number),
    stageZoom: PropTypes.number,
    actionsStack: PropTypes.array,
    actionsStackPosition: PropTypes.number,
    selectedShapeName: PropTypes.string,
  }),
  onSave: PropTypes.func,
  onBack: PropTypes.func,
  readOnly: PropTypes.bool,
  isMasterView: PropTypes.bool,
  vessel: PropTypes.shape({
    beam: PropTypes.number,
    length: PropTypes.number,
  }),
  imageType: PropTypes.string,
  constrainedHeight: PropTypes.any,
}

export default DrawingCanvas
