import React from 'react'
import { Line, Text } from 'react-konva'
import { Text as KonvaText } from 'konva'
import PropTypes from 'prop-types'
import { CHART_SHAPE_TYPE } from 'src/utils/drawingConstants'
import { getScaledRulerFontSize } from 'src/utils/drawingHelpers'

const ChartRuler = ({
  name,
  stroke,
  listening,
  chartFullSizeScaleFactor,
  chartScale,
  chartDimensions,
  points,
}) => {
  // If the chart scale has been removed while drawings exist, don't render, as it needs scale
  if (!chartScale) {
    return <></>
  }

  const fontSize = getScaledRulerFontSize(chartDimensions[0])

  let [x1, y1, x2, y2] = points
  if (x2 === x1 && y2 === y1) {
    y2 += 0.001
    x2 += 0.001
  }

  const lineLength = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
  const lineScaled = lineLength * (chartScale / chartFullSizeScaleFactor)
  const lineMetres = `${Math.round(lineScaled)}m`

  const xMid = (x1 + x2) / 2
  const yMid = (y1 + y2) / 2
  const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI

  const tipLength = 5
  const tipPx = y1 - y2
  const tipPy = x2 - x1
  const len = tipLength / Math.hypot(tipPx, tipPy)
  const px = tipPx * len
  const py = tipPy * len

  const startTipPoints = [x1 + px, y1 + py, x1 - px, y1 - py]
  const endTipPoints = [x2 + px, y2 + py, x2 - px, y2 - py]

  const labelFlip =
    (angle > 90 && angle <= 180) || (angle > -180 && angle <= -90)

  // It seems the only way to center a text element is to create a Konva.Text instance
  // and calculate it's width and height, when derive the offset from these values.
  const t = new KonvaText({
    fontSize,
    fontStyle: 'bold',
    text: lineMetres,
  })
  const [xOffset, yOffset] = [t.width() / 2, t.height() / 2]

  return (
    <>
      <Text
        name={name}
        type={CHART_SHAPE_TYPE.TEXT}
        x={xMid}
        y={yMid}
        offsetX={xOffset}
        offsetY={yOffset + 7}
        fill={stroke}
        text={lineMetres}
        fontSize={fontSize}
        fontStyle={'bold'}
        rotation={angle + (labelFlip ? 180 : 0)}
      />
      <Line
        name={name}
        stroke={stroke}
        strokeWidth={1}
        points={startTipPoints}
      />
      <Line name={name} stroke={stroke} strokeWidth={1} points={endTipPoints} />
      <Line
        name={name}
        stroke={stroke}
        strokeWidth={1}
        listening={listening}
        points={points}
      />
    </>
  )
}

ChartRuler.propTypes = {
  name: PropTypes.string,
  stroke: PropTypes.string,
  version: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.number),
  listening: PropTypes.bool,
  chartFullSizeScaleFactor: PropTypes.number,
  chartScale: PropTypes.number,
  chartDimensions: PropTypes.arrayOf(PropTypes.number),
}

export default React.memo(ChartRuler)
