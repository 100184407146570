import axios from 'axios'
import { sessionExpired } from './../../store/auth/actions'
import { SYNC_STATUS } from '../constants'
import { syncStatusStatSelector } from 'src/store/sync/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

export const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  timeout: process.env.REACT_APP_ENV === 'local' ? 10 * 60 * 1000 : 10000,
}

export const getCancelToken = () => axios.CancelToken.source().token

export const api = axios.create(defaultConfig)

export const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch()
  const stat = useSelector(syncStatusStatSelector)
  const totalPending = stat[SYNC_STATUS.PENDING]
  useEffect(() => {
    const resInterceptor = response => {
      return response
    }
    const errInterceptor = error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        dispatch(sessionExpired(totalPending))
      }
      return Promise.reject(error)
    }

    const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor)
    return () => api.interceptors.response.eject(interceptor)

  }, [totalPending])
  return children
}

export const addDefaultHeader = (key, value) => {
  api.defaults.headers[key] = value
}

export const setAuthTokenHeader = token => {
  addDefaultHeader('Authorization', `Bearer ${token}`)
}

export const getPayloadFromResponse = response =>
  response.data && response.data.data

export const getSinglePayloadFromResponse = response => {
  const [payload] = getPayloadFromResponse(response) || []
  return payload
}

export const getPayloadFromErrorResponse = errorResponse =>
  (errorResponse.response &&
    errorResponse.response.data &&
    errorResponse.response.data.message) ||
  errorResponse.message

export default api
