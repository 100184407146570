import * as React from 'react'
import { CSSProperties } from 'react'
import { BollardPositionName } from './positions'
import { TextAnchorProperty } from 'csstype'

interface TugsAndBollardsProps {
    readOnly: boolean
    activeTugs: Record<BollardPositionName, boolean>
    warnColors: Record<BollardPositionName, boolean>
    activeBollards: Record<BollardPositionName, boolean>
    onTugClick(pos: BollardPositionName, event: React.MouseEvent<SVGGElement, MouseEvent>): void
    onBollardClick(pos: BollardPositionName, event: React.MouseEvent<SVGGElement, MouseEvent>): void
}

const bollardLabelStyle : CSSProperties = {fontWeight: "bold", fontSize: '8px', fill: "#95A6B6", textAnchor: 'middle', dominantBaseline: 'middle'}
const bollardLabelValueStyle : CSSProperties = {fontWeight: "bold", fontSize: '10px', fill: "#ffffff", textAnchor: 'middle', dominantBaseline: 'middle'}

function TugsAndBollards({
    readOnly,
    activeTugs: tugs,
    warnColors,
    activeBollards: bollards,
    onTugClick,
    onBollardClick
}: TugsAndBollardsProps) {
    const getLineColor = (active: boolean, isWarn: boolean) =>
        active ? isWarn ? '#ffab4f' : '#05C985' : readOnly ? '#70889E' : '#1F8EFA' // "#39495F"

    const getStrokeColor = (active: boolean) =>
        active ? '#FFF' : readOnly ? '#70889E' : '#1F8EFA'

    const getFillColor = (active: boolean, isWarn: boolean) =>
        active ? isWarn ? '#ffab4f' : '#05C985' : '#20293C'

    const getDash = (active: boolean) =>
        active ? undefined : '1 2'

    const cursorStyle = { cursor: readOnly ? 'not-allowed' : 'pointer' }

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="500" viewBox="-400 -200 800 400" fill="#20293C">
        <defs>
          <pattern id="a" patternUnits="userSpaceOnUse" width="5" height="5" patternTransform="scale(0.3) rotate(-45)">
            <path d="M 0 0 H 5 " strokeWidth="7" stroke="#20293C" fill="none" />
          </pattern>
          <g id="tug">
            <path
              d="M -9.151 -8.773 C -12.381 -8.773 -15 -6.1545 -15 -2.9243 V 2.9243 C -15 6.1544 -12.381 8.773 -9.151 8.773 H 5.777 C 7.798 8.773 9.729 7.9364 11.111 6.4619 L 13.419 3.9998 C 15.527 1.7502 15.527 -1.7501 13.419 -3.9998 L 11.111 -6.4619 C 9.729 -7.9364 7.798 -8.773 5.777 -8.773 H -9.151 Z"
              strokeWidth="2" />
          </g>
          <g id="tug_mask" fill="#20293C" stroke="#1F8EFA">
            <path
              d="M -10.0661 -10.1328 C -13.6191 -10.1328 -16.5 -7.1085 -16.5 -3.3775 V 3.3775 C -16.5 7.1083 -13.6191 10.1328 -10.0661 10.1328 H 6.3547 C 8.5778 10.1328 10.7019 9.1665 12.2221 7.4635 L 14.7609 4.6198 C 17.0797 2.0215 17.0797 -2.0214 14.7609 -4.6198 L 12.2221 -7.4635 C 10.7019 -9.1665 8.5778 -10.1328 6.3547 -10.1328 H -10.0661 Z"
              fill="url(#a)" strokeWidth="0" />
          </g>
          <g id="vessel_template">
            <path
              d="M 0 0 C 0 25 -78 59 -88 59 H -415 C -421 59 -430 59 -488 42 C -494.191 40.453 -496.506 37.52 -496.506 34.146 V -34.146 C -496.506 -37.52 -494.191 -40.453 -488 -42 C -430 -59 -421 -59 -415 -59 H -88.938 C -78 -59 0 -25 0 0 Z M -435.892 -55.8 V 55.8 M -366.703 -59 V 59"
              stroke="white" strokeWidth="2" />
          </g>
          <g id="bollard_label">
          </g>
        </defs>

        <use href="#vessel_template" x="247.5" y="0" fill="#20293C" stroke="#1F8EFA" />
        <g id="bow">
          <line x1="247.5" y1="0" x2="300" y2="0"
                stroke={getLineColor(tugs['bow'] && bollards['bow'], warnColors['bow'])}
                strokeDasharray={getDash(tugs['bow'] && bollards['bow'])}
          />
          <use href="#tug" x="300" y="0" style={cursorStyle}
               onClick={(e) => onTugClick('bow', e)}
               fill={getFillColor(tugs['bow'], warnColors['bow'])}
               stroke={getStrokeColor(tugs['bow'])} />
          {
            !tugs['bow'] && !bollards['bow'] &&
            <use href="#tug_mask" x="300" y="0" style={cursorStyle} onClick={(e) => onTugClick('bow', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('bow', e)}>
            <rect x="235" y="-15" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['bow'])}
                  strokeDasharray={getDash(bollards['bow'])}
            />
            {bollards['bow'] && (
              <g>
                <text x="250" y="-6" width="30" style={bollardLabelStyle}>SWL</text>
                <text x="250" y="6" style={bollardLabelValueStyle}>{bollards['bow']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["bow"], 285, -48, "start")}
        </g>
        <g id="bow_port">
          <line x1="205" y1="-39" x2="234" y2="-68"
                stroke={getLineColor(tugs['bow_port'] && bollards['bow_port'], warnColors['bow_port'])}
                strokeDasharray={getDash(tugs['bow_port'] && bollards['bow_port'])}
          />
          <use href="#tug" x="244" y="-72"
               style={cursorStyle}
               onClick={(e) => onTugClick('bow_port', e)}
               fill={getFillColor(tugs['bow_port'], warnColors['bow_port'])}
               stroke={getStrokeColor(tugs['bow_port'])}
          />
          {
            !tugs['bow_port'] && !bollards['bow_port'] &&
            <use href="#tug_mask" x="244" y="-72" onClick={(e) => onTugClick('bow_port', e)} style={cursorStyle} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('bow_port', e)}>
            <rect x="190" y="-54" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['bow_port'])}
                  strokeDasharray={getDash(bollards['bow_port'])}
            />
            {bollards['bow_port'] && (
              <g>
                <text x="205" y="-45" style={bollardLabelStyle}>SWL</text>
                <text x="205" y="-34" style={bollardLabelValueStyle}>{bollards['bow_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["bow_port"], 230, -120, "start")}
        </g>
        <g id="fwd_port">
          <line x1="141" y1="-59" x2="141" y2="-100"
                stroke={getLineColor(tugs['fwd_port'] && bollards['fwd_port'], warnColors['fwd_port'])}
                strokeDasharray={getDash(tugs['fwd_port'] && bollards['fwd_port'])}
          />
          <use href="#tug" x="141" y="-100" style={cursorStyle}
               onClick={(e) => onTugClick('fwd_port', e)}
               fill={getFillColor(tugs['fwd_port'], warnColors['fwd_port'])}
               stroke={getStrokeColor(tugs['fwd_port'])} />
          {
            !tugs['fwd_port'] && !bollards['fwd_port'] &&
            <use href="#tug_mask" x="141" y="-100" style={cursorStyle} onClick={(e) => onTugClick('fwd_port', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('fwd_port', e)}>
            <rect x="126" y="-74" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['fwd_port'])}
                  strokeDasharray={getDash(bollards['fwd_port'])}
            />
            {bollards['fwd_port'] && (
              <g>
                <text x="141" y="-65" style={bollardLabelStyle}>SWL</text>
                <text x="141" y="-54" style={bollardLabelValueStyle}>{bollards['fwd_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["fwd_port"], 127, -148, "start")}
        </g>
        <g id="beam_port">
          <line x1="0" y1="-59" x2="0" y2="-100"
                stroke={getLineColor(tugs['beam_port'] && bollards['beam_port'], warnColors['beam_port'])}
                strokeDasharray={getDash(tugs['beam_port'] && bollards['beam_port'])}
          />
          <use href="#tug" x="0" y="-100" style={cursorStyle}
               onClick={(e) => onTugClick('beam_port', e)}
               fill={getFillColor(tugs['beam_port'], warnColors['beam_port'])}
               stroke={getStrokeColor(tugs['beam_port'])} />
          {
            !tugs['beam_port'] && !bollards['beam_port'] &&
            <use href="#tug_mask" x="0" y="-100" style={cursorStyle} onClick={(e) => onTugClick('beam_port', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('beam_port', e)}>
            <rect x="-15" y="-74" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['beam_port'])}
                  strokeDasharray={getDash(bollards['beam_port'])}
            />
            {bollards['beam_port'] && (
              <g>
                <text x="0" y="-65" style={bollardLabelStyle}>SWL</text>
                <text x="0" y="-54" style={bollardLabelValueStyle}>{bollards['beam_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["beam_port"], -13, -148, "start")}
        </g>
        <g id="aft_port">
          <line x1="-153.7975" y1="-59" x2="-153.7975" y2="-100"
                stroke={getLineColor(tugs['aft_port'] && bollards['aft_port'], warnColors['aft_port'])}
                strokeDasharray={getDash(tugs['aft_port'] && bollards['aft_port'])}
          />
          <use href="#tug" x="-153.7975" y="-100" style={cursorStyle}
               onClick={(e) => onTugClick('aft_port', e)}
               fill={getFillColor(tugs['aft_port'], warnColors['aft_port'])}
               stroke={getStrokeColor(tugs['aft_port'])} />
          {
            !tugs['aft_port'] && !bollards['aft_port'] &&
            <use href="#tug_mask" x="-153.7975" y="-100" style={cursorStyle} onClick={(e) => onTugClick('aft_port', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('aft_port', e)}>
            <rect x="-168.7975" y="-74" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['aft_port'])}
                  strokeDasharray={getDash(bollards['aft_port'])}
            />
            {bollards['aft_port'] && (
              <g>
                <text x="-153.7975" y="-65" style={bollardLabelStyle}>SWL</text>
                <text x="-153.7975" y="-54" style={bollardLabelValueStyle}>{bollards['aft_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["aft_port"], -168, -148, "start")}
        </g>
        <g id="quarter_port">
          <line x1="-215" y1="-48" x2="-215" y2="-89"
                stroke={getLineColor(tugs['quarter_port'] && bollards['quarter_port'], warnColors['quarter_port'])}
                strokeDasharray={getDash(tugs['quarter_port'] && bollards['quarter_port'])}
          />
          <use href="#tug" x="-215" y="-89" style={cursorStyle}
               onClick={(e) => onTugClick('quarter_port', e)}
               fill={getFillColor(tugs['quarter_port'], warnColors['quarter_port'])}
               stroke={getStrokeColor(tugs['quarter_port'])} />
          {
            !tugs['quarter_port'] && !bollards['quarter_port'] &&
            <use href="#tug_mask" x="-215" y="-89" style={cursorStyle}
                 onClick={(e) => onTugClick('quarter_port', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('quarter_port', e)}>
            <rect x="-230" y="-66" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['quarter_port'])}
                  strokeDasharray={getDash(bollards['quarter_port'])}
            />
            {bollards['quarter_port'] && (
              <g>
                <text x="-215" y="-57" style={bollardLabelStyle}>SWL</text>
                <text x="-215" y="-46" style={bollardLabelValueStyle}>{bollards['quarter_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["quarter_port"], -200, -138, "end")}
        </g>
        <g id="stern_port">
          <line x1="-300" y1="-75" x2="-249" y2="-39"
                stroke={getLineColor(tugs['stern_port'] && bollards['stern_port'], warnColors['stern_port'])}
                strokeDasharray={getDash(tugs['stern_port'] && bollards['stern_port'])}
          />
          <use href="#tug" x="-303" y="-68" style={cursorStyle}
               onClick={(e) => onTugClick('stern_port', e)}
               fill={getFillColor(tugs['stern_port'], warnColors['stern_port'])}
               stroke={getStrokeColor(tugs['stern_port'])} />
          {
            !tugs['stern_port'] && !bollards['stern_port'] &&
            <use href="#tug_mask" x="-303" y="-68" style={cursorStyle}
                 onClick={(e) => onTugClick('stern_port', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('stern_port', e)}>
            <rect x="-275" y="-59" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['stern_port'])}
                  strokeDasharray={getDash(bollards['stern_port'])}
            />
            {bollards['stern_port'] && (
              <g>
                <text x="-260" y="-50" style={bollardLabelStyle}>SWL</text>
                <text x="-260" y="-39" style={bollardLabelValueStyle}>{bollards['stern_port']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["stern_port"], -380, -100, "start")}
        </g>
        <g id="stern">
          <line x1="-249" y1="0" x2="-301" y2="0"
                stroke={getLineColor(tugs['stern'] && bollards['stern'], warnColors['stern'])}
                strokeDasharray={getDash(tugs['stern'] && bollards['stern'])}
          />
          <use href="#tug" x="-308" y="0" style={cursorStyle}
               onClick={(e) => onTugClick('stern', e)}
               fill={getFillColor(tugs['stern'], warnColors['stern'])}
               stroke={getStrokeColor(tugs['stern'])} />
          {
            !tugs['stern'] && !bollards['stern'] &&
            <use href="#tug_mask" x="-308" y="0" style={cursorStyle}
                 onClick={(e) => onTugClick('stern', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('stern', e)}>
            <rect x="-275" y="-15" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['stern'])}
                  strokeDasharray={getDash(bollards['stern'])}
            />
            {bollards['stern'] && (
              <g>
                <text x="-260" y="-6" style={bollardLabelStyle}>SWL</text>
                <text x="-260" y="6" style={bollardLabelValueStyle}>{bollards['stern']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["stern"], -385, -30, "start")}
        </g>
        <g id="stern_starboard">
          <line x1="-300" y1="72" x2="-249" y2="39"
                stroke={getLineColor(tugs['stern_starboard'] && bollards['stern_starboard'], warnColors['stern_starboard'])}
                strokeDasharray={getDash(tugs['stern_starboard'] && bollards['stern_starboard'])}
          />
          <use href="#tug" x="-303" y="68" style={cursorStyle}
               onClick={(e) => onTugClick('stern_starboard', e)}
               fill={getFillColor(tugs['stern_starboard'], warnColors['stern_starboard'])}
               stroke={getStrokeColor(tugs['stern_starboard'])} />
          {
            !tugs['stern_starboard'] && !bollards['stern_starboard'] &&
            <use href="#tug_mask" x="-303" y="68" style={cursorStyle}
                 onClick={(e) => onTugClick('stern_starboard', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('stern_starboard', e)}>
            <rect x="-275" y="30" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['stern_starboard'])}
                  strokeDasharray={getDash(bollards['stern_starboard'])}
            />
            {bollards['stern_starboard'] && (
              <g>
                <text x="-260" y="39" style={bollardLabelStyle}>SWL</text>
                <text x="-260" y="50" style={bollardLabelValueStyle}>{bollards['stern_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["stern_starboard"], -380, 38, "start")}
        </g>
        <g id="quarter_starboard">
          <line x1="-215" y1="48" x2="-215" y2="89"
                stroke={getLineColor(tugs['quarter_starboard'] && bollards['quarter_starboard'], warnColors['quarter_starboard'])}
                strokeDasharray={getDash(tugs['quarter_starboard'] && bollards['quarter_starboard'])}
          />
          <use href="#tug" x="-215" y="95" style={cursorStyle}
               onClick={(e) => onTugClick('quarter_starboard', e)}
               fill={getFillColor(tugs['quarter_starboard'], warnColors['quarter_starboard'])}
               stroke={getStrokeColor(tugs['quarter_starboard'])} />
          {
            !tugs['quarter_starboard'] && !bollards['quarter_starboard'] &&
            <use href="#tug_mask" x="-215" y="95" style={cursorStyle}
                 onClick={(e) => onTugClick('quarter_starboard', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('quarter_starboard', e)}>
            <rect x="-230" y="37" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['quarter_starboard'])}
                  strokeDasharray={getDash(bollards['quarter_starboard'])}
            />
            {bollards['quarter_starboard'] && (
              <g>
                <text x="-215" y="46" style={bollardLabelStyle}>SWL</text>
                <text x="-215" y="57" style={bollardLabelValueStyle}>{bollards['quarter_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["quarter_starboard"], -200, 118, "end")}
        </g>
        <g id="aft_starboard">
          <line x1="-153.7975" y1="59" x2="-153.7975" y2="100"
                stroke={getLineColor(tugs['aft_starboard'] && bollards['aft_starboard'], warnColors['aft_starboard'])}
                strokeDasharray={getDash(tugs['aft_starboard'] && bollards['aft_starboard'])}
          />
          <use href="#tug" x="-153.7975" y="100" style={cursorStyle}
               onClick={(e) => onTugClick('aft_starboard', e)}
               fill={getFillColor(tugs['aft_starboard'], warnColors['aft_starboard'])}
               stroke={getStrokeColor(tugs['aft_starboard'])} />
          {
            !tugs['aft_starboard'] && !bollards['aft_starboard'] &&
            <use href="#tug_mask" x="-153.7975" y="100" style={cursorStyle}
                 onClick={(e) => onTugClick('aft_starboard', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('aft_starboard', e)}>
            <rect x="-168.7975" y="44" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['aft_starboard'])}
                  strokeDasharray={getDash(bollards['aft_starboard'])}
            />
            {bollards['aft_starboard'] && (
              <g>
                <text x="-153.7975" y="55" style={bollardLabelStyle}>SWL</text>
                <text x="-153.7975" y="64" style={bollardLabelValueStyle}>{bollards['aft_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["aft_starboard"], -168, 122, "start")}
        </g>
        <g id="beam_starboard">
          <line x1="0" y1="59" x2="0" y2="100"
                stroke={getLineColor(tugs['beam_starboard'] && bollards['beam_starboard'], warnColors['beam_starboard'])}
                strokeDasharray={getDash(tugs['beam_starboard'] && bollards['beam_starboard'])}
          />
          <use href="#tug" x="0" y="100" style={cursorStyle}
               onClick={(e) => onTugClick('beam_starboard', e)}
               fill={getFillColor(tugs['beam_starboard'], warnColors['beam_starboard'])}
               stroke={getStrokeColor(tugs['beam_starboard'])} />
          {
            !tugs['beam_starboard'] && !bollards['beam_starboard'] &&
            <use href="#tug_mask" x="0" y="100" style={cursorStyle} onClick={(e) => onTugClick('beam_starboard', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('beam_starboard', e)}>
            <rect x="-15" y="44" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['beam_starboard'])}
                  strokeDasharray={getDash(bollards['beam_starboard'])}
            />
            {bollards['beam_starboard'] && (
              <g>
                <text x="0" y="55" style={bollardLabelStyle}>SWL</text>
                <text x="0" y="64" style={bollardLabelValueStyle}>{bollards['beam_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["beam_starboard"], -13, 122, "start")}
        </g>
        <g id="fwd_starboard">
          <line x1="141" y1="59" x2="141" y2="100"
                stroke={getLineColor(tugs['fwd_starboard'] && bollards['fwd_starboard'], warnColors['fwd_starboard'])}
                strokeDasharray={getDash(tugs['fwd_starboard'] && bollards['fwd_starboard'])}
          />
          <use href="#tug" x="141" y="100" style={cursorStyle}
               onClick={(e) => onTugClick('fwd_starboard', e)}
               fill={getFillColor(tugs['fwd_starboard'], warnColors['fwd_starboard'])}
               stroke={getStrokeColor(tugs['fwd_starboard'])} />
          {
            !tugs['fwd_starboard'] && !bollards['fwd_starboard'] &&
            <use href="#tug_mask" x="141" y="100" style={cursorStyle} onClick={(e) => onTugClick('fwd_starboard', e)} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('fwd_starboard', e)}>
            <rect x="126" y="44" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['fwd_starboard'])}
                  strokeDasharray={getDash(bollards['fwd_starboard'])}
            />
            {bollards['fwd_starboard'] && (
              <g>
                <text x="141" y="55" style={bollardLabelStyle}>SWL</text>
                <text x="141" y="64" style={bollardLabelValueStyle}>{bollards['fwd_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["fwd_starboard"], 127, 122, "start")}
        </g>
        <g id="bow_starboard">
          <line x1="205" y1="39" x2="234" y2="68"
                stroke={getLineColor(tugs['bow_starboard'] && bollards['bow_starboard'], warnColors['bow_starboard'])}
                strokeDasharray={getDash(tugs['bow_starboard'] && bollards['bow_starboard'])}
          />
          <use href="#tug" x="244" y="72"
               style={cursorStyle}
               onClick={(e) => onTugClick('bow_starboard', e)}
               fill={getFillColor(tugs['bow_starboard'], warnColors['bow_starboard'])}
               stroke={getStrokeColor(tugs['bow_starboard'])}
          />
          {
            !tugs['bow_starboard'] && !bollards['bow_starboard'] &&
            <use href="#tug_mask" x="244" y="72" onClick={(e) => onTugClick('bow_starboard', e)} style={cursorStyle} />
          }
          <g style={cursorStyle} onClick={(e) => onBollardClick('bow_starboard', e)}>
            <rect x="190" y="24" width="30" height="30" rx="5"
                  stroke={getStrokeColor(bollards['bow_starboard'])}
                  strokeDasharray={getDash(bollards['bow_starboard'])}
            />
            {bollards['bow_starboard'] && (
              <g>
                <text x="205" y="35" style={bollardLabelStyle}>SWL</text>
                <text x="205" y="44" style={bollardLabelValueStyle}>{bollards['bow_starboard']}t</text>
              </g>
            )}
          </g>
          {tugLabel(tugs["bow_starboard"], 230, 93, "start")}
        </g>
      </svg>
    )
}

const shortType = (type: string) : string => {
  const nameMap: {[key: string]: string}  = {
    'Azimuth Tractor Drive': 'ATD',
    'Azimuth Stern Drive': 'ASD'
  };

  return nameMap[type] || type;
}

const tugLabel = (tug: any, x: number, y: number, anchor: TextAnchorProperty) => {
  if(!tug) {
    return null
  }

  let name = tug.name as string;
  if(name.length > 15) {
    name = name.substring(0,15) + "..."
  }

  return <g style={{textAnchor: anchor}}>
    <text x={x} y={y} style={{ fontSize: '8px', fontWeight: 'bold', fill: '#95A6B6' }}>{shortType(tug.tugType)}</text>
    <text x={x} y={y + 15} style={{ fontSize: '12px',  fill: '#ffffff' }}>{name}
      <title>{tug.name}</title>
    </text>
    <text x={x} y={y+33} style={{ fontSize: '14px', fontWeight: "bold", fill: "#ffffff" }}>{tug.tonnage}t</text>
  </g>
}

export default TugsAndBollards
