const makeFindAtTime = (interpolate) => (time, items)  => {

  if (!items || Object.keys(items).length < 2) {
    return null
  }

  const times = Object.keys(items)
  const current = time.getTime()
  let currentIndex = times.indexOf(current)

  if (currentIndex !== -1) {
    return items[times[currentIndex]]
  }

  // Don't use interpolate values if they are too far from the desired time
  const proximityLimit = 60 * 60 * 1000

  times.push(current)
  times.sort((a, b) => a - b)
  currentIndex = times.indexOf(current)

  if (currentIndex === 0) {
    // Current time is outside the range of the item data
    return times[1] - current > proximityLimit / 2 ? null : items[currentIndex]
  }

  if (currentIndex === times.length - 1) {
    return current - times[currentIndex - 1] > proximityLimit / 2
      ? null
      : items[currentIndex]
  }

  const timeBefore = times[currentIndex - 1]
  const timeAfter = times[currentIndex + 1]

  if (
    current - timeBefore > proximityLimit ||
    timeAfter - current > proximityLimit
  ) {
    return null
  }

  const dateTimeBefore = times[currentIndex - 1]
  const dateTimeAfter = times[currentIndex + 1]

  const itemBefore = items[dateTimeBefore]
  const itemAfter = items[dateTimeAfter]

  const fullInterval = times[currentIndex + 1] - times[currentIndex - 1]
  const partialInterval = current - times[currentIndex - 1]

  // linear interpolation will suffice
  return interpolate(itemBefore, itemAfter, partialInterval / fullInterval)
}

function interpolateNumber(before, after, intervalRatio) {
  return before + (after - before) * intervalRatio
}

function interpolateTideRate(before, after, intervalRatio) {
  const beforeDegrees = parseFloat(before.degrees)
  const afterDegrees = parseFloat(after.degrees)
  return {
    knots: interpolateNumber(before.tide, after.tide, intervalRatio),
    degrees: !isNaN(beforeDegrees) && !isNaN(afterDegrees)
      ? interpolateNumber(beforeDegrees, afterDegrees, intervalRatio)
      : null,
    direction: intervalRatio < 0.5 ? before.direction : after.direction
  }
}

export const findTideAtTime = makeFindAtTime(interpolateNumber)

export const findTideRateAtTime = makeFindAtTime(interpolateTideRate)

export default findTideAtTime
