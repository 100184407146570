import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconWrapper = styled.div`
  position: relative;
  & span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 3px;
    text-align: center;
    width: 33px;
    text-transform: uppercase;
    font-size: 11px;
  }
  & svg {
    display: block;
  }
`

const AttachmentIcon = ({ mimeType }) => {
  let label
  switch (mimeType) {
    case 'image/png':
      label = 'png'
      break
    case 'image/jpeg':
      label = 'jpg'
      break
    case 'application/pdf':
      label = 'pdf'
      break
    default:
      label = ''
  }

  return (
    <IconWrapper>
      <svg width="33" height="42" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.54 1H2a1 1 0 00-1 1v38a1 1 0 001 1h29a1 1 0 001-1V11.935L20.54 1zm-.073-.247l12.63 11.227h-12.63V.753z"
          fill="#4DAAEF"
          stroke="#4DAAEF"
          strokeWidth="2"
          fillOpacity="0.4"
        />
      </svg>
      <span>{label}</span>
    </IconWrapper>
  )
}

AttachmentIcon.propTypes = {
  mimeType: PropTypes.string,
}

export default React.memo(AttachmentIcon)
