import React from 'react'
import styled, { css } from 'styled-components'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import FormFieldError from 'src/components/atoms/FormFieldError'

interface ChecklistItemCommentProps {
  value: string
  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void
  disabled: boolean
  maxChars?: number
}

const StyledTextArea = styled(TextareaAutosize)(
  ({ theme, disabled }) => css`
    && {
      resize: none;
      width: 100%;
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.dark};
      box-sizing: border-box;
      padding: 10px 15px;
      border-radius: ${theme.spacing(0.5)}px;
      border: 1px solid
        ${disabled ? theme.palette.disabled.main : theme.palette.primary.main};

      &:disabled {
        color: inherit;
        -webkit-text-fill-color: inherit;
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
    }
  `
)

const StyledError = styled(FormFieldError)`
  margin-top: -8px;
`

const ChecklistItemComment: React.FC<ChecklistItemCommentProps> = ({
  value = '',
  onChange,
  disabled,
  maxChars,
}) => (
  <>
    <StyledTextArea value={value} onChange={onChange} disabled={disabled} />
    {typeof maxChars === 'number' && value.length > maxChars && (
      <StyledError>
        {value.length} / {maxChars} character limit
      </StyledError>
    )}
  </>
)

export default React.memo(ChecklistItemComment)
