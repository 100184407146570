import React from 'react'
import styled, { css } from 'styled-components'

interface CautionIconProps {
  size?: number
  inactive?: boolean
  className?: string
}

const ActiveFill = styled.circle(
  ({ theme }) => css`
    fill: ${theme.palette.status.caution};
  `
)

const TickGlyphFill = styled(({ inactive, children, ...props }) => (
  <g {...props}>{children}</g>
))(
  ({ theme, inactive }) => css`
    fill: ${inactive
      ? theme.palette.background.light
      : theme.palette.text.primary};
  `
)

const CautionIcon: React.FC<CautionIconProps> = ({
  size = 28,
  inactive = false,
  className,
}) => (
  <svg width={size} height={size} viewBox="0 0 28 28" className={className}>
    {!inactive && <ActiveFill cx="14" cy="14" r="14" />}
    <TickGlyphFill inactive={inactive}>
      <circle cx="14" cy="20.5" r="1.5" />
      <rect x="12.5" y="6" width="3" height="11" rx="1.5" />
    </TickGlyphFill>
  </svg>
)

export default React.memo(CautionIcon)
