import {
  MASTERVIEW_TOKEN_REQUEST,
  MASTERVIEW_TOKEN_SUCCESS,
  MASTERVIEW_TOKEN_ERROR,
  MASTERVIEW_TOKEN_IN_PROGRESS,
} from './actions'

export const reducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case MASTERVIEW_TOKEN_REQUEST:
    case MASTERVIEW_TOKEN_IN_PROGRESS:
      return {
        ...state,
        status: type,
      }
    case MASTERVIEW_TOKEN_SUCCESS:
      return {
        ...payload,
        status: type,
      }
    case MASTERVIEW_TOKEN_ERROR:
      return {
        error: payload,
        status: type,
      }
    default:
      return state
  }
}

export default reducer
