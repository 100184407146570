/**
 * I created the sync handlers because the api calls and its call signature are different
 * sometimes we have to deal with custom logic and it would make the action file too big.
 * Also it can help us to extend the offline sync feature for other type of resources
 */
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'

import pilotageSync from 'src/utils/sync/pilotage'
import pilotageTugsSync from 'src/utils/sync/pilotageTugs'
import pilotageRouteChecksSync from 'src/utils/sync/pilotageRouteChecks'
import pilotageExtrasSync from 'src/utils/sync/pilotageExtras'
import pilotageDrawings from 'src/utils/sync/pilotageDrawings'
import pilotageSignatures from 'src/utils/sync/pilotageSignatures'
import vessel from 'src/utils/sync/vessel'

export default {
  [SYNC_ENTITY_TYPES.PILOTAGE]: pilotageSync,
  [SYNC_ENTITY_TYPES.PILOTAGE_TUGS]: pilotageTugsSync,
  [SYNC_ENTITY_TYPES.PILOTAGE_ROUTE_CHECKS]: pilotageRouteChecksSync,
  [SYNC_ENTITY_TYPES.PILOTAGE_VESSEL]: vessel,
  [SYNC_ENTITY_TYPES.PILOTAGE_EXTRAS]: pilotageExtrasSync,
  [SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS]: pilotageDrawings,
  [SYNC_ENTITY_TYPES.PILOTAGE_SIGNATURES]: pilotageSignatures,
}
