import api from 'src/utils/api/core'

export function getTideRateData(stationUuid, fromDate, toDate) {
  return api.get(`/tide_rate_data`, {
    params: {
      stationUuid,
      fromDate,
      toDate
    },
  })
}
