import React from 'react'
import { TideGraphTheme } from "./types"

export interface TideGraphConstraintMarkerProps {
    index: number
    x: number
    y: number
    height: number
    highlight: string
    marginTop: number
    marginLeft?: number
    labelText: string
    labelSmall: boolean
    align: 'left' | 'right'
    theme: TideGraphTheme
    invert?: boolean
}

export const TideGraphConstraintMarker: React.FC<TideGraphConstraintMarkerProps> = ({
    index,
    x,
    y,
    height,
    highlight,
    marginTop,
    theme,
    invert
}) => {

    const yOffset = (index + 1) * 20
    const top = invert ? (height - yOffset) : (-marginTop + yOffset)
    const bottom = y

    return (
        <g>
        
            <line
                x1={x}
                y1={top}
                x2={x}
                y2={bottom}
                stroke={highlight}
                style={{opacity: 0.5}}
            />

            <circle 
                cx={x}
                cy={bottom}
                r={theme.dotRadius * 2}
                fill={theme.waterFillColor}
            />

            <circle
                cx={x}
                cy={bottom}
                r={theme.dotRadius}
                fill={highlight}
            />
        </g>
    )
}