import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Badge from '@material-ui/core/Badge'
import CachedIcon from '@material-ui/icons/Cached'

const SmallBadge = styled(Badge)`
  .MuiBadge-badge {
    height: 14px;
    min-width: 14px;
    padding: 0;
    transform: scale(1) translate(50%, 0%);
    width: 14px;
    .MuiSvgIcon-root {
      height: 12px;
      width: 12px;
    }
  }
`

export const ReloadBadge = ({ children, ...props }) => {
  return (
    <SmallBadge
      color="error"
      badgeContent={<CachedIcon fontSize="small" />}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      {...props}
    >
      {children}
    </SmallBadge>
  )
}
ReloadBadge.propTypes = {
  children: PropTypes.node,
}

export default ReloadBadge
