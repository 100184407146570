import { CircularProgress, Collapse } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import * as echarts from 'echarts'
import { EChartsType } from 'echarts'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dhiIcon from 'src/assets/images/dhi-logo-horizontal-small-light.png'
import InfoBoxBase from 'src/components/atoms/InfoBox'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import { CollapseButton, CollapseButtonOpen } from 'src/components/organisms/Common'
import { dhiRequest } from 'src/store/dhi/actions'
import { dhiStateSelector } from 'src/store/dhi/selectors'
import { TIME_FORMAT_WITH_SEC } from 'src/utils/constants'
import { friendlyTime } from 'src/utils/date'
import styled from 'styled-components'

const InfoBox = styled(InfoBoxBase)`
    && {
        padding: 30px 30px 20px 30px;
    }

    && > div {
        width: 100%;
    }
`

const DhiHeader = styled.div`
    && {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
  }
`
const DhiSubHeader = styled.div`
    && {
      font-size: 14px;
      color: #95A6B6;
      padding-top: 5px;
      text-transform: capitalize;
    }
`
const DhiSubSubHeader = styled.div`
    && {
      font-size: 14px;
      color: #95A6B6;
      text-transform: capitalize;
    }
`

const DhiControls = styled.div`
&& {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}
`

const DhiFreshness = styled.div`
&& {
  flex: 1;
  padding: 0px 20px;
  font-size: 12px;
}
`

export type TimeSeriesName
  = '0.1%'
  | '1%'
  | '99%'
  | 'Depth'
  | 'DepthAVG'
  | 'BC'
  | 'MM'
  | 'MaxRoll'
  | 'Speed'
  | 'Squat'
  | 'TurningHeel'
  | 'WayPoint'
  | 'WindDirection'
  | 'WindHeel'
  | 'WindSpeed'

export interface DhiNcosGraphViewModel {
  vesselName: string
  startLocation: string
  endLocation: string
  speedProfile: number
  draft: number
  timestamps: string[]
  data: Record<TimeSeriesName, number[]>
}

// async function getNcosDhiNcosGraphData(portUuid: string, vesselIMO: string, pilotageStart: string) {
//   const response = await axios.get<DhiNcosGraphViewModel>(`http://localhost:8081/dhi/ncos`, {
//     params: {
//       portUuid,
//       vesselIMO,
//       pilotageStart
//     }
//   })
//   return response.data
// }

interface DhiNcosGraphProps {
  portUuid: string, // would be able to do a backend lookup for the current port in the real integration
  vesselIMO: string,
  pilotageStart: string
  timeZone: string
  theme?: DhiNcosGraphTheme
  minWidth?: number
  minHeight?: number
}

type DhiNcosGraphOptions = any

interface DhiNcosGraphTheme {
  text: string
  label: string
  waveAllowance: string
  squat: string
  depth: string
  depthAvg: string
  speed: string
  maxRoll: string
}

const darkTheme: DhiNcosGraphTheme = {
  text: '#fff',
  label: '#333',
  squat: '#FFD24F',
  waveAllowance: '#4BBE87',
  depth: '#AAD6FF',
  depthAvg: '#999999',
  speed: 'rgb(31, 142, 250)',
  maxRoll: '#FAB8EF'
}

/*
const lightTheme: DhiNcosGraphTheme = {
    ...darkTheme,
    background: "#FFF",
    text: '#333'
}
*/

const getSentenceAbbr = (sentence: string) => {
  let abbr = ''
  const words = sentence.split(' ')
  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    if (word) {
      abbr = `${abbr}${word.substring(0, 1)}`
    }
  }
  abbr = abbr.toUpperCase()
  return abbr
}

const getMarkPointsData = (WayPoint: number[], timestamps: string[], startLocation: string, endLocation: string) => {
  const markPointsData = []
  WayPoint.forEach((n, index) => {
    if (n > -1) {
      let waypointName
      if (n === 0) {
        // Green Buoy
        waypointName = `GB`
      } else if (n === 0) {
        // Wheel Over Inbound
        waypointName = `WOI`
      }
      if (waypointName) {
        markPointsData.push(
          { value: waypointName, xAxis: timestamps[index], yAxis: 0 }
        )
      }
    }
  })
  const startLocationAbbr = getSentenceAbbr(startLocation)
  const endLocationAbbr = getSentenceAbbr(endLocation)

  markPointsData.unshift(
    { value: startLocationAbbr, xAxis: timestamps[0], yAxis: 0 }
  )
  markPointsData.push(
    { value: endLocationAbbr, xAxis: timestamps[timestamps.length - 1], yAxis: 0 }
  )
  return markPointsData
}

function graphDataToOptions (
  dataIn: DhiNcosGraphViewModel,
  timeZone: string,
  theme: DhiNcosGraphTheme
): DhiNcosGraphOptions {

  const { timestamps, data, draft, vesselName, startLocation, endLocation, speedProfile } = dataIn

  const formatTime = (t: string) => {
    return DateTime.fromISO(t).setZone(timeZone).toFormat('HH:mm')
  }

  const formatTimeTooltip = (t: string) => {
    return DateTime.fromISO(t).setZone(timeZone).toFormat('yyyy/MM/dd HH:mm:ss')
  }

  const roundTwoDecimalPlace = (v: number) =>
    Math.round((v + Number.EPSILON) * 100) / 100

  const withDraft = (v: number) =>
    roundTwoDecimalPlace(-v - draft)

  const {
    Squat, Speed, MaxRoll, Depth, DepthAVG,
    BC, MM, WindHeel, TurningHeel, WindSpeed, WindDirection, WayPoint
  } = data

  // derived series based on documentation and Franz's input where docs are incomplete
  const METRES_PER_SECOND_TO_KNOTS = 1.94384
  const dynamicHeel = WindHeel.map((v, i) => v + TurningHeel[i])
  const waveAllowancePlusDynamicHeel = data['1%'].map(withDraft)
  const depth = Depth.map(withDraft) // Actually is minimum depth
  const depthAverage = DepthAVG.map(withDraft)
  const speed = Speed.map(n => n * METRES_PER_SECOND_TO_KNOTS)
  const waypoint = WayPoint.map(() => 0)

  const markPointsData = getMarkPointsData(WayPoint, timestamps, startLocation, endLocation)

  const textSize = (text: string, fontSize: string) => {
    const span = document.createElement('span')
    var result = {
      'width': span.offsetWidth,
      'height': span.offsetHeight
    }
    span.style.visibility = 'hidden'
    span.style.fontSize = fontSize || '14px'
    document.body.appendChild(span)

    if (typeof span.textContent !== 'undefined') { span.textContent = text || 'country' } else span.innerText = text || 'country'

    result.width = span.offsetWidth - result.width
    result.height = span.offsetHeight - result.height
    if (span.parentNode) {
      span.parentNode.removeChild(span)
    }
    return result
  }
  // we only really need to access the data index
  // because we can reference our data directly in the view model
  function tooltipFormatter (params: Array<{ dataIndex: number }>) {
    const { dataIndex: i } = params[0]!
    return (
      `<div>
        <b>${formatTimeTooltip(timestamps[i])}</b>
        <div style="width:210px">
        ${[
        ['BC', BC[i], 'm'],
        ['MM', MM[i], 'm'],
        ['Squat', Squat[i], 'm'],
        ['Dynamic Heel', dynamicHeel[i], 'm'],
        ['Wind', WindSpeed[i] * METRES_PER_SECOND_TO_KNOTS, `kn <span style="position:absolute;padding:0px 5px;transform:rotateZ(${Math.round(WindDirection[i]) + 180}deg);">&#8593;</span>`],
        ['Planned Speed', speed[i], 'kn']].map(([key, value, unit]) =>
        `<div style="display:flex">
            <div style="flex:5">${key}</div>
            <div style="flex:2">${roundTwoDecimalPlace(value as number)} ${unit}</div>
          </div>`
      ).join('')
      }
        </div>
      </div>`
    )
  }

  const fontFamily = `'Gilroy', sans-serif`

  return {
    textStyle: {
      color: theme.text,
      fontFamily
    },
    grid: {
      top: '10%',
      left: '4%',
      right: '9%',
      bottom: '10%',
      containLabel: true,
      backgroundColor: '#666666',
      show: true
    },
    title: {
      text: `Depths for transit of ${vesselName} starting ${formatTimeTooltip(timestamps[0]!)}`,
      subtext: `From: ${startLocation}. To: ${endLocation}. Speed: ${['Slow', 'Fast'][speedProfile]}. Draft: ${draft}m`,
      left: 'center',
      top: 10,
      textStyle: {
        color: theme.text,
        fontWeight: 500,
        fontFamily,
        fontSize: 13
      },
      subtextStyle: {
        color: theme.text,
        fontWeight: 500,
        fontFamily,
        fontSize: 10
      },
      show: false,
    },
    legend: {
      orient: 'horizontal',
      left: 'center',
      itemGap: 30,
      bottom: 10,
      textStyle: {
        fontSize: 11
      },
      data: [
        {
          name: 'Wave allowance + dynamic heel',
          itemStyle: { color: theme.waveAllowance },
          textStyle: { color: theme.text }
        },
        {
          name: 'Squat',
          itemStyle: { color: theme.squat },
          textStyle: { color: theme.text }
        },
        {
          name: 'Max Roll',
          itemStyle: { color: theme.maxRoll },
          textStyle: { color: theme.text }
        },
        {
          name: 'Speed',
          itemStyle: { color: theme.speed },
          textStyle: { color: theme.text }
        },
      ]
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: theme.label
        }
      },
      formatter: tooltipFormatter
    },
    xAxis: [{
      data: timestamps,
      axisLabel: {
        formatter: formatTime,
        fontSize: 11,
      },
      boundaryGap: false,
      axisPointer: {
        label: {
          show: false
        }
      }
    }],
    yAxis: [
      {
        type: 'value',
        name: 'Distance from Static Draft (m)',
        inverse: true,
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 25,
        nameTextStyle: {
          fontSize: 11,
          fontWeight: 700,
        },
        axisLabel: {
          fontSize: 11,
          margin: 3,
        },
        position: 'left',
        scale: true,
        axisPointer: {
          label: {
            show: false
          }
        },
        splitLine: {
          lineStyle: {
            opacity: 0.2
          },
          show: false
        }
      },
      {
        type: 'value',
        name: 'Speed (kn)',
        inverse: false,
        scale: true,
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 25,
        nameTextStyle: {
          fontSize: 11,
          fontWeight: 700,
          color: theme.speed
        },
        axisLabel: {
          fontSize: 11,
          fontWeight: 700,
          color: theme.speed,
          margin: 3
        },
        position: 'right',
        splitLine: {
          show: false
        },
        axisPointer: {
          label: {
            show: false
          }
        }
      },
      {
        type: 'value',
        name: 'Maxiumum Roll (degrees)',
        inverse: false,
        scale: true,
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 25,
        nameTextStyle: {
          fontSize: 11,
          fontWeight: 700,
          color: theme.maxRoll
        },
        axisLabel: {
          fontSize: 11,
          fontWeight: 700,
          color: theme.maxRoll,
          margin: 3
        },
        position: 'right',
        offset: 45,
        splitLine: {
          show: false
        },
        axisPointer: {
          label: {
            show: false
          }
        }
      }],
    series: [
      // stacked values need to be offset against previous series
      {
        animation: false,
        name: 'Avg. Depth',
        type: 'line',
        data: depthAverage,
        symbol: 'none',
        yAxisIndex: 0,
        areaStyle: {
          color: theme.depthAvg,
          opacity: 0.9
        },
        lineStyle: {
          color: theme.depthAvg
        },
        emphasis: {
          focus: 'none'
        },
        silent: true,
      },
      {
        animation: false,
        name: 'Depth',
        type: 'line',
        data: depth,
        symbol: 'none',
        yAxisIndex: 0,
        areaStyle: {
          color: theme.depth,
          opacity: 0.9
        },
        lineStyle: {
          color: theme.depth
        },
        emphasis: {
          focus: 'none'
        },
        silent: true,
      },
      {
        animation: false,
        name: 'Wave allowance + dynamic heel',
        type: 'line',
        data: waveAllowancePlusDynamicHeel,
        symbol: 'none',
        yAxisIndex: 0,
        areaStyle: {
          color: theme.waveAllowance,
          opacity: 0.9
        },
        lineStyle: {
          color: theme.waveAllowance
        },
        emphasis: {
          focus: 'none'
        },
        silent: true,
      },
      {
        animation: false,
        name: 'Squat',
        type: 'line',
        data: Squat,
        symbol: 'none',
        yAxisIndex: 0,
        areaStyle: {
          color: theme.squat,
          opacity: 0.9
        },
        lineStyle: {
          color: theme.squat
        },
        emphasis: {
          focus: 'none'
        },
        silent: true,
      },
      {
        animation: false,
        name: 'Waypoint',
        type: 'line',
        data: waypoint,
        markPoint: {
          silent: true,
          symbol: 'pin',
          symbolSize: (val: any) => {
            return [textSize(val, '26px').width + 5, 40]
          },
          label: {
            fontSize: 12,
          },
          itemStyle: {
            color: '#FFF'
          },
          data: markPointsData
        },
        symbol: 'none',
        yAxisIndex: 0,
        lineStyle: {
          color: 'tranparent',
          opacity: 0.0
        },
        emphasis: {
          focus: 'none'
        },
        silent: true,
      },
      {
        animation: false,
        name: 'Speed',
        type: 'line',
        data: speed,
        symbol: 'none',
        yAxisIndex: 1,
        emphasis: {
          focus: 'none'
        },
        silent: true,
        lineStyle: {
          color: theme.speed,
          shadowColor: 'rgba(0,0,0,0.7)',
          shadowBlur: 3
        }
      },
      {
        animation: false,
        name: 'Max Roll',
        type: 'line',
        data: MaxRoll,
        symbol: 'none',
        yAxisIndex: 2,
        emphasis: {
          focus: 'none'
        },
        silent: true,
        lineStyle: {
          color: theme.maxRoll,
          shadowColor: 'rgba(0,0,0,0.7)',
          shadowBlur: 3
        }
      }
    ]
  }
}

const DhiNcosGraph: React.FC<DhiNcosGraphProps> = ({
  portUuid,
  vesselIMO,
  pilotageStart,
  timeZone,
  theme = darkTheme,
  minWidth = 500,
  minHeight = 400
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const dispatch = useDispatch()
  const [chartInstance, setChartInstance] = useState<echarts.EChartsType | null>(null)
  const { data: chartData, isLoading, error } = useSelector(dhiStateSelector)
  const [chartOptions, setChartOptions] = useState<DhiNcosGraphOptions | null>(null)
  const [lastFetched, setLastFetched] = useState<string | null>(null)

  const reloadDhi = () => {
    dispatch(dhiRequest(portUuid, vesselIMO, pilotageStart))
  }

  const chartRef = useRef<HTMLDivElement | null>(null)

  const renderChart = (chartElement: HTMLElement, chartOpts: DhiNcosGraphOptions) => {
    const renderInstance = echarts.getInstanceByDom(chartElement)
    let chartInstance: EChartsType | null = null
    if (renderInstance) {
      chartInstance = renderInstance
    } else {
      chartInstance = echarts.init(chartElement)
    }
    chartInstance.clear()
    chartInstance.setOption(chartOpts, true)
    setChartInstance(chartInstance)
  }

  useEffect(() => {
    return () => {
      chartInstance && chartInstance.dispose()
    }
  }, [])

  useEffect(() => {
    if (portUuid && vesselIMO && pilotageStart && timeZone) {
      reloadDhi()
    }
  }, [portUuid, vesselIMO, pilotageStart, timeZone])

  useEffect(() => {
    if (chartRef.current && chartData) {
      setLastFetched(new Date().toISOString())
      const chartOpts = graphDataToOptions(chartData, timeZone, theme)
      setChartOptions(chartOpts)
      renderChart(chartRef.current, chartOpts)
    }
  }, [chartRef.current, chartData])

  useEffect(() => {
    const resize = () => {
      if (chartInstance) { chartInstance.resize() }
    }
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [chartInstance])

  if (!chartData) { return null }

  return (
    <InfoBox mt={2} mb={2}>
      <DhiHeader onClick={() => setIsCollapsed(!isCollapsed)}>
        <div>
          <img src={dhiIcon} alt="NCOS UKC Profile"/>
          <DhiSubHeader>{
            `${chartOptions && chartOptions.title.text}`}
          </DhiSubHeader>
          <DhiSubSubHeader>{
            `${chartOptions && chartOptions.title.subtext}`}
          </DhiSubSubHeader>
        </div>
        {!isCollapsed ? <CollapseButton /> : <CollapseButtonOpen />}
      </DhiHeader>
      <Collapse in={isCollapsed}>
        <div
          ref={chartRef}
          style={{ width: '100%', minWidth, minHeight, paddingTop: 10 }}>
        </div>
        <DhiControls>
          {
            <SecondaryButton
              onClick={reloadDhi}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
            </SecondaryButton>
          }

          <DhiFreshness>
            {
              lastFetched &&
              <div>{friendlyTime('Last fetched', lastFetched, timeZone, TIME_FORMAT_WITH_SEC)}</div>
            }
            {
              error &&
              <div>Failed to fetch: {error.message}</div>
            }
          </DhiFreshness>
        </DhiControls>
      </Collapse>
    </InfoBox>
  )
}

export default DhiNcosGraph
