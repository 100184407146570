import {
  PREFERENCES_IN_PROGRESS,
  PREFERENCES_ERROR,
  PREFERENCES_SUCCESS,
} from 'src/store/preferences/actions'
import { IPreferences } from 'src/utils/api/preferences'
import { PREFERENCES_RESET } from './actions'

export interface PreferencesState {
  data: IPreferences[] | null
  isLoading: boolean
  error: Error | null
}

const INITIAL_STATE: PreferencesState = {
  data: null,
  isLoading: false,
  error: null,
}

function preferencesReducer (state = INITIAL_STATE, action: any) {
  const { type, payload } = action

  switch (type) {
    case PREFERENCES_RESET:
      return { ...INITIAL_STATE }
    case PREFERENCES_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PREFERENCES_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PREFERENCES_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default preferencesReducer
