import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

const LoginPane = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.light};
  height: 100%;
  padding: ${({ theme }) => `0 ${theme.spacing(8)}px`};
`

const ImagePane = styled.div`
  flex: 1;
  display: flex;
  background-image: url(${({ src }) => src});
  background-size: cover;
  height: 100%;
  position: relative;
`

const LoginLayout = ({ loginPaneContent, imageSrc }) => (
  <Box display="flex" height="100%">
    <LoginPane>{loginPaneContent}</LoginPane>
    <ImagePane src={imageSrc} />
  </Box>
)

LoginLayout.propTypes = {
  loginPaneContent: PropTypes.node,
  imageSrc: PropTypes.string,
}

export default React.memo(LoginLayout)
