import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getScaledFontSize } from 'src/utils/drawingHelpers'

import {
  CHART_TEXT_INPUT_HEIGHT,
  CHART_TEXT_INPUT_WIDTH,
  CHART_BASE_FONT_SIZE
} from 'src/utils/drawingConstants'

const ChartTextInputBox = styled.div`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  z-index: 4;

  & textarea {
    font-family: 'Gilroy';
    font-size: ${CHART_BASE_FONT_SIZE}px;
    min-width: ${CHART_TEXT_INPUT_WIDTH}px;
    height: ${CHART_TEXT_INPUT_HEIGHT * 2}px;
    color: #000;
    font-weight: ${({ theme }) => theme.weights.fontWeightBold};
    position: relative;
    top: -1px;
    left: -5px;
    text-align: center;
  }
`

const OutsideClickRegion = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 3;
`

const ChartTextInput = ({
  activeLabelValue,
  left,
  top,
  color,
  onCreate,
  onCancel,
  chartDimensions
}) => {
  const inputRef = useRef(null)

  const onBlur = () => {
    if (inputRef.current.value) {
      onCreate({ left, top, value: inputRef.current.value })
    } else {
      onCancel()
    }
  }

  const fontSize = chartDimensions
    ? getScaledFontSize(chartDimensions[0]) 
    : CHART_BASE_FONT_SIZE

  // clicking outside the text input does not blur the field on safari ios
  // unless a click listener is present on the outside click region. We don't
  // need to listen for clicks, so use a no-op function.
  const noop = () => {}

  // We'll render an invisible box behind the input which acts as an outside
  // clicks receiver. Without this, clicks within the canvas don't seem to blur
  // the input reliably.
  return (
    <>
      <OutsideClickRegion onClick={noop} />
      <ChartTextInputBox left={left} top={top} color={color}>
        <textarea
          autoFocus
          defaultValue={activeLabelValue}
          ref={inputRef}
          placeholder="Text label"
          onBlur={onBlur}
          style={{ 
            fontSize
          }}
        />
      </ChartTextInputBox>
    </>
  )
}

ChartTextInput.propTypes = {
  activeLabelValue: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  color: PropTypes.string,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  chartDimensions: PropTypes.array
}

export default React.memo(ChartTextInput)
