import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { versionName, versionCode } from '../../../version.json'

const AppVersionWrapper = styled(Box)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

const AppVersion = props => {
  let version = `${versionName}`
  if (process.env.REACT_APP_ENV !== 'prod') {
    version = `${version}-${process.env.REACT_APP_ENV}-${versionCode}`
  }
  return version && <AppVersionWrapper {...props}>v{version}</AppVersionWrapper>
}

export default React.memo(AppVersion)
