import React from 'react'
import styled from 'styled-components'

import CircleButton from 'src/components/atoms/CircleButton'

export const FullscreenCloseIcon = styled(function FullscreenCloseIcon(props) {
  return <CircleButton {...props} />
})(
  ({ theme }) => `
  && {
    position: absolute;
    top: ${theme.spacing(4)}px;
    right: ${theme.spacing(4)}px;
  }
`
)

export default FullscreenCloseIcon
