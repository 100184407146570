import { createSelector } from 'reselect'
import {
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_PASSWORD_RESET_IN_PROGRESS,
  AUTH_PASSWORD_UPDATE_IN_PROGRESS,
  AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
} from 'src/store/auth/actions'
import { SESSION_STATUS } from 'src/utils/constants'
import { formatUsername } from 'src/utils/formatters'

export const authStateSelector = state => state.auth

export const authTokenSelector = createSelector(
  authStateSelector,
  authState => authState && authState.token
)

export const refreshTokenSelector = createSelector(
  authStateSelector,
  authState => authState && authState.refreshToken
)

export const imageTokenSelector = createSelector(
  authStateSelector,
  state => state && state.imageToken
)

export const authErrorSelector = createSelector(
  authStateSelector,
  authState => authState && authState.error
)

export const authStatusSelector = createSelector(
  authStateSelector,
  authState => authState && authState.status
)
export const authUserSelector = createSelector(
  authStateSelector,
  authState => authState && authState.user
)

export const authRolesSelector = createSelector(
  authStateSelector,
  authState => authState && authState.roles
)

export const isAdminSelector = createSelector(
  authRolesSelector,
  roles => roles.indexOf('admin') > -1
)

export const isLoginInProgressSelector = createSelector(
  authStatusSelector,
  status => status === AUTH_LOGIN_IN_PROGRESS
)

export const isRefreshSessionInProgressSelector = createSelector(
  authStatusSelector,
  status => status === AUTH_SESSION_REFRESH_IN_PROGRESS
)

export const isRequestNewPasswordInProgressSelector = createSelector(
  authStatusSelector,
  status => status === AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS
)

export const isPasswordResetInProgressSelector = createSelector(
  authStatusSelector,
  status => status === AUTH_PASSWORD_RESET_IN_PROGRESS
)

export const isPasswordUpdateInProgressSelector = createSelector(
  authStatusSelector,
  status => status === AUTH_PASSWORD_UPDATE_IN_PROGRESS
)

export const isLoggedInSelector = createSelector(
  authTokenSelector,
  token => !!token
)

export const usernameSelector = createSelector(
  authUserSelector,
  user => formatUsername(user)
)

export const userUuidSelector = createSelector(
  authUserSelector,
  user => user && user.uuid
)

export const sessionStatusSelector = createSelector(
  authStateSelector,
  state => state && state.sessionStatus
)

export const sessionIsValidSelector = createSelector(
  sessionStatusSelector,
  status => status === SESSION_STATUS.VALID
)
