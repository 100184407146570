import { createSelector } from 'reselect'

export const chartStateSelector = state => state.charts

export const createChartSelector = uuid =>
  createSelector(
    chartStateSelector,
    state =>
      uuid ? state.find(item => item.data && item.data.uuid === uuid) : null
  )
