import { createSelector } from 'reselect'
import idx from 'idx'

import { convertActionsStackToShapes } from 'src/utils/drawingHelpers'
import { drawingSelector } from 'src/store/drawings/selectors'
import { DEFAULT_CANVAS_STATE } from 'src/store/canvas/reducer'

export const canvasSelector = state => state.canvas

export const createStageCanvasSelector = stageUuid =>
  createSelector(
    canvasSelector,
    canvasState => canvasState[stageUuid] || DEFAULT_CANVAS_STATE
  )

export const createShapesSelector = stageUuid =>
  createSelector(
    drawingSelector(stageUuid),
    createStageCanvasSelector(stageUuid),
    (drawing, canvas) =>
      convertActionsStackToShapes(
        canvas.actionsStack || [],
        canvas.actionsStackPosition || 0,
        idx(drawing, _ => _.metadata.shapes) || [],
        canvas.exludedShapes
      )
  )
