import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const ConstraintErrorWrapper = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.background.dark};
  padding: ${theme.spacing(3)}px;
  border-radius: 4px;
`
)

const StyledList = styled.ul(
  ({ theme }) => `
  color: ${theme.palette.error.light};
  margin: ${theme.spacing(2, 0, 0, 3)};
`
)

const ConstraintError = ({ errors }) => (
  <ConstraintErrorWrapper>
    <Typography variant="h4" gutterBottom>
      Unable to check constraint
    </Typography>
    <div>
      This constraint is unable to be checked due to the following issues:
    </div>
    <StyledList>
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </StyledList>
  </ConstraintErrorWrapper>
)

ConstraintError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}

export default React.memo(ConstraintError)
