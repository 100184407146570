import React from 'react'

const Debug: React.FC = () => {

    return (
        <div>
            <h1>Debug</h1>
        </div>
    )
}

export default Debug