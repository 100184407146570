import { createSelector } from 'reselect'
import idx from 'idx'

import { authUserSelector } from 'src/store/auth/selectors'
import { pilotageListSelector } from 'src/store/pilotageList/selectors'
import { entitiesToSyncSelector } from 'src/store/sync/selectors'

import { createSyncUuid } from 'src/utils/sync/pilotage'
import { PILOTAGE_STATUS, SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { ATTACHMENT_TYPE } from 'src/components/organisms/Attachments/constants'

export const pilotageStateSelector = state => state.pilotage

export const pilotageSelector = createSelector(
  pilotageStateSelector,
  pilotageListSelector,
  entitiesToSyncSelector,
  (pilotageState, pilotageList, entitiesToSync) => {
    const { uuid } = (pilotageState && pilotageState.data) || {}
    const syncUuid = createSyncUuid(uuid)
    const data = uuid
      ? idx(entitiesToSync, _ => _[syncUuid].entity) ||
        idx(pilotageState, _ => _.data) ||
        (pilotageList && pilotageList.find(pilotage => pilotage.uuid === uuid))
      : null
    return {
      ...pilotageState,
      data,
    }
  }
)

export const isPilotageDoneSelector = createSelector(
  pilotageSelector,
  ({ data }) => data && data.status === PILOTAGE_STATUS.DONE
)

export const isPilotageArchivedSelector = createSelector(
  pilotageSelector,
  ({ data }) => data && data.status === PILOTAGE_STATUS.ARCHIVED
)

export const isPilotageCancelledSelector = createSelector(
  pilotageSelector,
  ({ data }) => data && data.status === PILOTAGE_STATUS.CANCELLED
)

export const pilotageStartTimeSelector = createSelector(
  pilotageSelector,
  state => (state.data ? state.data.date : null)
)

export const derivedMaxDraftSelector = createSelector(
  pilotageSelector,
  state => {
    if (!state.data) { return null }
    return deriveMaxDraft(state.data)
  }
)

export const deriveFreeboard = (vesselMaxDraft, vesselDepth) => {

  if (vesselMaxDraft === null) {
    return null
  }
  if (vesselDepth === null || vesselDepth === undefined) {
    return null
  }
  const vesselDepthFloat = parseFloat(vesselDepth)
  if (vesselDepthFloat.toFixed(2) === '0.00') {
    return null
  }
  let freeboard = vesselDepthFloat - vesselMaxDraft
  if (freeboard < 0) {
    return 0
  }
  return freeboard
}

export const deriveMaxDraft = (pilotage) => {

  const { vesselMaxDraft, vesselFwdDraft, vesselAftDraft } = pilotage

  // use max draft if defined, even if it's 0
  if (typeof vesselMaxDraft === 'number') { return vesselMaxDraft }

  // otherwise use the max of fwd and aft, if any
  const maxCandidates = [
    vesselFwdDraft,
    vesselAftDraft
  ].filter(n => typeof n === 'number')

  if (maxCandidates.length === 0) { return null }

  return Math.max(...maxCandidates)
}

export const isFailedSelector = createSelector(
  pilotageSelector,
  state => state && !!state.error
)

export const movementTypeSelector = createSelector(
  pilotageSelector,
  state => state.data ? state.data.movementType : null
)

export const omcChosenPreliminaryPlanIdSelector = createSelector(
  pilotageSelector,
  state => state.data && state.data.omcChosenPreliminaryPlanId !== null
    ? parseInt(state.data.omcChosenPreliminaryPlanId, 10)
    : null
)

export const pilotageUuidSelector = createSelector(
  pilotageSelector,
  state => (state.data ? state.data.uuid : null)
)

export const isPrimaryPilotForActivePilotageSelector = createSelector(
  pilotageSelector,
  authUserSelector,
  ({ data }, user) => {
    return !!(data && data.pilot && user && data.pilot.uuid === user.uuid)
  }
)

export const isSecondaryPilotForActivePilotageSelector = createSelector(
  pilotageSelector,
  authUserSelector,
  ({ data }, user) => {
    return !!(
      data &&
      data.pilotSecond &&
      user &&
      data.pilotSecond.uuid === user.uuid
    )
  }
)

export const isAssignedToActivePilotageSelector = createSelector(
  isPrimaryPilotForActivePilotageSelector,
  isSecondaryPilotForActivePilotageSelector,
  (isPrimary, isSecondary) => isPrimary || isSecondary
)

export const pilotageRouteChecksSelector = createSelector(
  pilotageSelector,
  entitiesToSyncSelector,
  ({ data: pilotage }, entitiesToSync) => {
    const syncItems = Object.values(entitiesToSync)
    // we replace those which are changed offline
    let routeChecks = ((pilotage && pilotage.pilotageRouteChecks) || []).map(
      item => {
        const syncRouteCheck =
          syncItems &&
          syncItems.length > 0 &&
          syncItems.find(
            ({ type, entity }) =>
              type === SYNC_ENTITY_TYPES.PILOTAGE_ROUTE_CHECKS &&
              (entity.uuid === item.uuid ||
                entity.routeConstraint.uuid === item.routeConstraint.uuid)
          )
        return syncRouteCheck ? syncRouteCheck.entity : item
      }
    )
    const pilotageUuid = pilotage && pilotage.uuid
    // find those which are created offline
    const newInSync = syncItems
      .filter(
        syncItem =>
          syncItem.type === SYNC_ENTITY_TYPES.PILOTAGE_ROUTE_CHECKS &&
          syncItem.pilotageUuid === pilotageUuid &&
          !routeChecks.find(
            routeCheck =>
              routeCheck.routeConstraint.uuid ===
              syncItem.entity.routeConstraint.uuid
          )
      )
      .map(syncItem => syncItem.entity)

    return [...routeChecks, ...newInSync]
  }
)

export const pilotageAttachmentsSelector = createSelector(
  pilotageSelector,
  state => {
    const attachments =
      state.data && Array.isArray(state.data.attachments)
        ? state.data.attachments
        : []

    return attachments.map(item => {
      let category
      switch (item.attachmentType) {
        case ATTACHMENT_TYPE.WEATHER:
          category = 'Weather'
          break
        case ATTACHMENT_TYPE.DUKC:
          category = 'UKC Documentation'
          break
        default:
          category = null
      }
      return { ...item, category }
    })
  }
)
