import { createMuiTheme } from '@material-ui/core/styles'

const fontFamily = "'Gilroy', sans-serif"

const fontWeightBold = 700
const fontWeightSemiBold = 600
const fontWeightMedium = 500
const fontWeightRegular = 400
const fontWeightLight = 300

export const spacing = factor => factor * 8

export const defaultPalette = {
  primary: {
    main: 'rgb(31, 142, 250)',
    mainActive: 'rgba(31, 142, 250, 0.6)',
    light: '#54a9fb',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    contrastText: '#aaa',
  },
  background: {
    default: 'rgb(32, 41, 60)',
    defaultFaded: 'rgba(32, 41, 60, 0.26)',
    light: 'rgb(47, 59, 82)',
    lightFaded: 'rgba(47, 59, 82, 0.56)',
    xlight: '#3e4e6c',
    dark: '#1B2438',
  },
  disabled: {
    main: '#657D95',
    light: '#f4f4f4',
  },
  success: {
    main: '#05C985',
    light: '#63c7a4',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#ff8721',
    xlight: '#fffaea',
  },
  error: {
    main: '#ED1C24',
    mainFaded: 'rgba(237, 28, 36, 0.1)',
    light: '#eb757b',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#ffffff',
    secondary: '#95A6B6',
    pdf: '#2F3B52',
    dark: '#464646',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    main: '#4B87FF',
    error: '#D2003C',
    success: '#05C985',
  },
  status: {
    caution: '#ffab4f',
    incomplete: '#FFAB4F',
    complete: '#05C985',
  },
  readOnly: 'rgba(149, 166, 182, 0.34)',
  faded: 'rgba(255, 255, 255, 0.3)',
  slightlyFaded: 'rgba(255, 255, 255, 0.1)',
}

export const lightPalette = {
  primary: {
    main: 'rgb(31, 142, 250)',
    mainActive: 'rgba(31, 142, 250, 0.6)',
    light: '#54a9fb',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    contrastText: '#aaa',
  },
  background: {
    default: '#fff',
    defaultFaded: '#fff',
    light: '#f7f7f7',
    lightFaded: '#fafafa',
    xlight: '#fff',
    dark: '#fff',
  },
  disabled: {
    main: '#657D95',
    light: '#f4f4f4',
  },
  success: {
    main: '#05C985',
    light: '#63c7a4',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#ff8721',
    xlight: '#fffaea',
  },
  error: {
    main: '#ED1C24',
    mainFaded: 'rgba(237, 28, 36, 0.1)',
    light: '#eb757b',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#000',
    secondary: '#787878',
    dark: '#000',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    main: '#4B87FF',
    error: '#D2003C',
    success: '#05C985',
  },
  status: {
    incomplete: '#FFAB4F',
    complete: '#05C985',
  },
  readOnly: 'rgba(149, 166, 182, 0.34)',
  faded: 'rgba(0, 0, 0, 0.3)',
  slightlyFaded: 'rgba(0, 0, 0, 0.1)',
}

const transition =
  '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

export const getTheme = (isLight = false) => {
  const palette = isLight ? lightPalette : defaultPalette

  const theme = createMuiTheme({
    palette,
    spacing,
    typography: {
      useNextVariants: true,
      fontFamily: fontFamily,
      fontFamilySecondary: fontFamily,
    },
    weights: {
      fontWeightBold,
      fontWeightSemiBold,
      fontWeightMedium,
      fontWeightRegular,
      fontWeightLight,
    },
    status: {
      danger: 'orange',
    },
    props: {
      MuiWithWidth: {
        initialWidth: 'xs',
      },
    },
    transition,
  })

  // We can only use typography function such as pxToRem after the createMuiTheme
  theme.typography = {
    ...theme.typography,
    h1: {
      ...theme.typography.h1,
      fontWeight: fontWeightRegular,
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(35),
      color: palette.text.primary,
      margin: 0,
      letterSpacing: 1.13,
      '&:before': {
        content: '" "',
        width: 5,
        height: 24,
        backgroundColor: palette.text.primary,
        marginRight: 13,
        display: 'inline-block',
        borderLeft: `5px solid ${palette.text.secondary}`,
        position: 'relative',
        top: 1,
      },
    },
    h2: {
      ...theme.typography.h2,
      fontWeight: fontWeightMedium,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(28),
      color: palette.text.primary,
      margin: 0,
      letterSpacing: 0.9,
      '&:before': {
        content: '" "',
        width: 5,
        height: 19,
        backgroundColor: palette.text.primary,
        marginRight: 13,
        display: 'inline-block',
        borderLeft: `5px solid ${palette.text.secondary}`,
        position: 'relative',
        top: 1,
      },
    },
    h3: {
      ...theme.typography.h3,
      fontWeight: fontWeightMedium,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      textTransform: 'uppercase',
      color: palette.text.primary,
    },
    h4: {
      ...theme.typography.h3,
      fontWeight: fontWeightMedium,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      color: palette.text.primary,
    },
    h5: {
      ...theme.typography.h5,
      fontWeight: fontWeightRegular,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(19),
      color: palette.text.secondary,
      letterSpacing: 0.5,
    },
    h6: {
      ...theme.typography.h6,
      fontWeight: fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      fontWeight: fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      letterSpacing: 0.5,
      color: palette.text.secondary,
      marginBottom: spacing(4),
      marginTop: spacing(2),
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      fontWeight: fontWeightLight,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: palette.text.secondary,
      marginBottom: spacing(4),
      marginTop: spacing(2),
    },
    body1: {
      ...theme.typography.body1,
      color: palette.text.primary,
      fontWeight: fontWeightRegular,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
    },
    body2: {
      ...theme.typography.body2,
      color: palette.text.secondary,
      letterSpacing: 0.5,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: fontWeightMedium,
    },
    button: {
      ...theme.typography.button,
      fontWeight: fontWeightSemiBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
    },
    caption: {
      ...theme.typography.caption,
      fontWeight: fontWeightRegular,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
    },
    overline: {
      ...theme.typography.overline,
    },
  }

  // Overrides are assigned here so we can use theme.breakpoints
  // Override cake ui components here based off classes api
  theme.overrides = {
    MuiContainer: {
      root: {
        padding: spacing(4),
      },
      maxWidthLg: {
        padding: spacing(4),
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        border: `1px solid ${palette.divider}`,
        boxShadow: 'none',
      },
    },
    MuiChip: {
      root: {
        marginRight: spacing(1),
        color: palette.primary.main,
        height: 28,
        backgroundColor: 'transparent',
        border: `1px solid ${palette.primary.main}`,
      },
    },
    MuiCollapse: {
      entered: {
        height: 'auto',
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'uppercase',
        borderRadius: 6,
        letterSpacing: theme.typography.pxToRem(0.57),
        minHeight: 44,
        padding: `${spacing(1)}px ${spacing(4)}px`,
      },
      contained: {
        boxShadow: '0 3px 9px 0 rgba(0,0,0,0.25)',
        minWidth: 100,
      },
      containedPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '&:disabled': {
          backgroundColor: palette.disabled.main,
          color: palette.primary.contrastText,
          opacity: 0.3,
        },
        '&:not(:disabled):hover': {
          backgroundColor: palette.primary.mainActive,
        },
      },
      outlinedSecondary: {
        padding: `${spacing(1)}px ${spacing(2)}px`,
        backgroundColor: 'transparent',
        color: palette.text.primary,
        borderColor: palette.text.primary,

        '&:disabled': {
          color: palette.text.primary,
          borderColor: palette.text.primary,
          opacity: 0.3,
        },

        '&:not(:disabled):hover': {
          color: palette.text.secondary,
          borderColor: palette.text.secondary,
        },
      },
      outlined: {
        borderColor: palette.secondary.dark,
        color: palette.secondary.dark,
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,

        '&:hover': {
          backgroundColor: palette.primary.mainActive,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: 18,
        fontWeight: 500,
        backgroundColor: palette.background.default,
        border: `1.5px solid ${palette.primary.main}`,
        borderRadius: 4,
      },
      notchedOutline: {
        borderWidth: `0 !important`,
      },
      input: {
        padding: '10px 15px',

        '&:disabled': {
          color: palette.text.primary,
        },

        '&:read-only': {
          cursor: 'default',
        },
      },
      disabled: {
        opacity: 0.3,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 10,
        minWidth: 460,
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '20px 30px 40px 30px',
      },
      action: {
        margin: '0 10px',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: isLight ? '#eeeeee' : '#33415f',
        marginTop: 24,
        marginBottom: 24,
      },
    },
    MuiSwitch: {
      switchBase: {
        height: 36,
      },
    },
    MuiSelect: {
      icon: {
        color: 'inherit',
        right: 8,
      },
      select: {
        color: theme.palette.primary.main,
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
        borderRadius: 4,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: theme.typography.pxToRem(14),
        textTransform: 'uppercase',
        fontFamily,
        fontWeight: theme.weights.fontWeightSemiBold,
        color: '#444',
        letterSpacing: theme.typography.pxToRem(0.88),
        padding: '6px 18px',
        minWidth: 100,
        margin: '0 !important',
        // Hide empty label value
        '&[data-value=""]': {
          display: 'none',
        },
        [theme.breakpoints.up('xs')]: {
          minHeight: 40,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minHeight: 56,
        [theme.breakpoints.up('xs')]: {
          minWidth: 0,
          marginLeft: 25,
          marginRight: 25,
        },
        '&:first-of-type': {
          marginLeft: 20,
        },
      },
      label: {
        fontSize: theme.typography.pxToRem(18),
        color: palette.primary.contrastText,
        fontWeight: fontWeightRegular,
      },
      textColorPrimary: {
        fontWeight: fontWeightBold,
        color: palette.primary.contrastText,
      },
      disabled: {
        opacity: 0.3,
      },
      labelContainer: {
        [theme.breakpoints.up('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: theme.typography.pxToRem(15),
        backgroundColor: '#fff',
        color: '#1b1b1b',
        fontWeight: theme.weights.fontWeightSemiBold,
        padding: theme.spacing(1, 1.5),
      },
      touch: {
        fontSize: theme.typography.pxToRem(15),
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiTableCell: {
      head: {
        color: palette.text.secondary,
        backgroundColor: palette.background.lightFaded,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: fontWeightSemiBold,
        borderBottom: 0,
        textTransform: 'uppercase',
        padding: spacing(2),
        height: 60,
      },
      body: {
        color: palette.text.primary,
        backgroundColor: palette.background.light,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: fontWeightMedium,
        position: 'relative',
        padding: spacing(2),
        height: 60,
        borderBottom: 0,
        borderTop: `1px solid ${palette.background.default}`,
      },
    },
    MuiPrivateTextarea: {
      textarea: {
        width: 'auto',
      },
    },
    MuiPaginationItem: {
      page: {
        '&.Mui-selected': {
          backgroundColor: palette.background.light,
        },
      },
    },
  }

  return theme
}

export const theme = getTheme()
export const lightTheme = getTheme(true)

export default theme
