export const ACTION_TYPE_REQUEST = 'REQUEST'
export const ACTION_TYPE_ERROR = 'ERROR'
export const ACTION_TYPE_SUCCESS = 'SUCCESS'
export const ACTION_TYPE_CANCEL = 'CANCEL'
export const ACTION_TYPE_IN_PROGRESS = 'IN_PROGRESS'

// helper, low level functions
export const getActionType = (baseAction, actionType = ACTION_TYPE_REQUEST) =>
  `${baseAction}_${actionType}`

export const createAction = (type, payload) => {
  return { type, payload }
}

// to create API/HTTP requests
export const createRequestAction = (baseAction, payload) =>
  createAction(getActionType(baseAction, ACTION_TYPE_REQUEST), payload)

// to create error result, mainly AJAX errors
export const createErrorAction = (baseAction, payload) =>
  createAction(getActionType(baseAction, ACTION_TYPE_ERROR), payload)

// mainly kind of boolean request results eg.: login
export const createSuccessAction = (baseAction, payload) =>
  createAction(getActionType(baseAction, ACTION_TYPE_SUCCESS), payload)

// mainly to cancel requests
export const createCancelAction = (baseAction, payload) =>
  createAction(getActionType(baseAction, ACTION_TYPE_CANCEL), payload)

// mainly to indicate when a request is in progress eg: AUTH_LOGIN_IN_PROGRESS
export const createInProgressAction = (baseAction, payload) =>
  createAction(getActionType(baseAction, ACTION_TYPE_IN_PROGRESS), payload)

// to create action types' constans values
export const createBasicActionTypes = baseAction => [
  getActionType(baseAction, ACTION_TYPE_REQUEST), // request action, eg: AUTH_LOGIN_REQUEST
  getActionType(baseAction, ACTION_TYPE_SUCCESS), // request action, eg: AUTH_LOGIN_SUCCESS
  getActionType(baseAction, ACTION_TYPE_ERROR), // request action, eg: AUTH_LOGIN_ERROR
  getActionType(baseAction, ACTION_TYPE_CANCEL), // request action, eg: AUTH_LOGIN_CANCEL
  getActionType(baseAction, ACTION_TYPE_IN_PROGRESS), // request action, eg: AUTH_LOGIN_CANCEL
]
