import React from 'react'
import { Box, Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'

import WarningIcon from 'src/components/atoms/Icons/Warning'
import { PilotageChangeSet } from 'src/utils/calculatePilotageChangeset'
import formatPilotageChangeSet from 'src/utils/formatPilotageChangeSet'

const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.background.default};
    border-radius: 8px;
    padding: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(4)}px;
    display: flex;

    svg {
      display: block;
    }
  `
)

const Title = styled(Typography).attrs({ variant: 'body1' })(
  ({ theme }) => css`
    && {
      font-size: ${theme.typography.pxToRem(18)};
      margin-bottom: 0.7em;
    }
  `
)

const IconWrapper = styled.div`
  width: 32px;
`

const StyledUl = styled.ul`
  margin: 0;
  list-style-type: none;

  > li:before {
    content: '- ';
  }
`

const ValueText = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`

interface PilotageUpdatesProps {
  changeSet: PilotageChangeSet
  timeZone: string
}

const PilotageUpdates: React.FC<PilotageUpdatesProps> = ({
  changeSet,
  timeZone,
}) => {
  const changes = formatPilotageChangeSet(changeSet, timeZone)
  return (
    <Container>
      <IconWrapper>
        <WarningIcon />
      </IconWrapper>
      <Box>
        <Title>Updated Content:</Title>
        <StyledUl>
          {changes.map(({ valueName, previous, current }) => (
            <li key={valueName}>
              {valueName} changed from <ValueText>{previous}</ValueText> to{' '}
              <ValueText>{current}</ValueText>
            </li>
          ))}
        </StyledUl>
      </Box>
    </Container>
  )
}

export default React.memo(PilotageUpdates)
