import React from 'react'
import { format } from 'date-fns'
import { Box, Typography } from '@material-ui/core'
import { WeatherDataTable } from 'src/components/molecules/WeatherDataTable'
import SubHeading from 'src/components/atoms/SubHeading'
import { DATE_FORMAT, TIME_FORMAT } from 'src/utils/constants'
import { convertToTimeZone } from 'date-fns-timezone'
import Loader from 'src/components/atoms/Loader'
import { WeatherDataFormatted } from 'src/types/Weather'
import { PilotageWeatherProps } from './types'
import WeatherSource from './WeatherSource'

const PilotageWeather: React.FC<PilotageWeatherProps> = ({
  pilotage,
  source, 
  isLoading, 
  weatherToDisplay,
  empty
}) => {

  return (
    <>
     <Typography variant="h1">Weather Information</Typography>
      {
        source && 
        <SubHeading style={{marginBottom: 15}}>
          <WeatherSource source={source} />
        </SubHeading>
      }
      {
        isLoading ? 
        <Loader>Loading weather forecasts</Loader> :

        empty ?
        <div>No forecasts to show for this date</div> :

        weatherToDisplay.map(({ weatherLocation, weatherViewGroups }) => {

          return (
            <div key={weatherLocation.uuid}>
              {
                weatherViewGroups.map(({ key, items }) => {

                  // only need to show the first forecast for each group
                  // as they will all be the same
                  return (
                    <div key={key}>
                    {
                      items.slice(0, 1).map(({ 
                        time, 
                        nearestWeatherForecast,
                        weatherAtLocation
                      }) =>
                      
                        <div key={nearestWeatherForecast.dateTime}>
                          <PilotageWeatherForLocation 
                            label={weatherLocation.name}
                            isLoading={weatherAtLocation.isLoading}
                            source={weatherAtLocation.data.source}
                            time={time}
                            timeZone={pilotage.port.timezone}
                            nearestWeatherForecast={nearestWeatherForecast}
                          />
                        </div>)
                    }  
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </>
  )
}

export default React.memo(PilotageWeather)



interface PilotageWeatherForLocationProps {
  label: string
  isLoading: boolean
  source: string
  time: string
  timeZone: string
  nearestWeatherForecast: WeatherDataFormatted
}

const PilotageWeatherForLocation: React.FC<PilotageWeatherForLocationProps> = ({ 
  label,
  isLoading,
  source,
  time,
  timeZone,
  nearestWeatherForecast
}) => {

  let message = ''

  if (!isLoading && !nearestWeatherForecast) {

    const startDate = convertToTimeZone(time, { timeZone })
    const now = convertToTimeZone(new Date(), { timeZone })

    if (startDate.getTime() - now.getTime() >= 7 * 24 * 60 * 60000) {
      message = 'No forecast available. The pilotage date may be outside the weather forecast range - check back closer to the pilotage date.'
    } else if (startDate < now) {
      message = 'No weather forecast data available for this date and time.'
    } else {
      message = 'No forecast available.'
    }
  }

  return (
    <>
      {isLoading && <Loader>Loading weather forecast</Loader>}
      {!isLoading && (
        <>
          {nearestWeatherForecast && (
            <Typography variant="h5">
              Forecast for{' '}
              {format(
                nearestWeatherForecast.dateTime,
                `${TIME_FORMAT} on ${DATE_FORMAT}`
              )} at {label}
            </Typography>
          )}
          <Box mt={1} mb={2}>
            {nearestWeatherForecast ? (
              <WeatherDataTable
                weather={nearestWeatherForecast}
                source={source}
              />
            ) : (
              message
            )}
          </Box>
        </>
      )}
    </>
  )
}
