import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getRoute } from 'src/utils/api/routes'
import { setChartsList } from 'src/store/charts/actions'

const ROUTE_BASE = 'ROUTE'

export const [
  ROUTE_REQUEST,
  ROUTE_SUCCESS,
  ROUTE_ERROR,
  ROUTE_CANCEL,
  ROUTE_IN_PROGRESS,
] = createBasicActionTypes(ROUTE_BASE)

export const routeRequest = (uuid, opts) => async dispatch => {
  if (!opts || !opts.isPrefetch) {
    dispatch(routeInProgress())
  }

  try {
    const response = await getRoute(uuid)
    const data = getSinglePayloadFromResponse(response)
    if (!opts || !opts.isPrefetch) {
      dispatch(routeLoaded(data))
    }
    return data
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(routeError(error))
    }
    return false
  }
}

export const routeInProgress = () => createInProgressAction(ROUTE_BASE)

export const routeError = error => dispatch => {
  dispatch(createErrorAction(ROUTE_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_ROUTE_ERROR,
    })
  )
}

export const routeLoaded = data => dispatch => {
  dispatch(createSuccessAction(ROUTE_BASE, data))

  const charts = data.stages.map(stage => ({
    data: stage.chart,
  }))

  dispatch(setChartsList(charts))
}
