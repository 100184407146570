import React from 'react'
import PropTypes from 'prop-types'
import { defaultPalette } from 'src/styles/theme'

const FilterIcon = ({ active }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6662 25.76V12.8888L2.23999 2.23999H25.76L17.3338 12.8459V17.8313C17.3332 18.5138 17.0694 19.1682 16.6004 19.6505L10.6662 25.76ZM5.59999 3.58677L12.3013 12.2037V22.0363L15.4524 18.7201C15.6076 18.5488 15.6955 18.3221 15.6987 18.0854V12.2037L22.4 3.58677H5.59999Z"
      fill={active ? defaultPalette.primary.main : '#ffffff'}
    />
  </svg>
)

FilterIcon.propTypes = {
  active: PropTypes.bool,
}

export default FilterIcon
