import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import ButtonRow from 'src/components/atoms/ButtonRow'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

const TimestampClear = ({ isLoading, onConfirm, onCancel }) => (
  <>
    <Box my={4}>Are you sure you want to clear the timestamp?</Box>
    <ButtonRow>
      <SecondaryButton disabled={isLoading} onClick={onConfirm}>
        Clear
      </SecondaryButton>
      <PrimaryButton onClick={onCancel}>Keep</PrimaryButton>
    </ButtonRow>
  </>
)

TimestampClear.propTypes = {
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default React.memo(TimestampClear)
