import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router5'
import { Formik, Form, Field } from 'formik'
import { Grid, Box } from '@material-ui/core'

import containerShipImage from 'src/assets/images/container-ship.jpg'
import TextInput from 'src/components/atoms/TextInput'
import LoginLayout from 'src/components/organisms/LoginLayout/LoginLayout'
import LoginPaneSection, {
  SmallLoginPaneSection,
} from 'src/components/organisms/LoginLayout/LoginPaneSection'
import AuthSubmitButton from 'src/components/atoms/AuthSubmitButton'
import EmpxLogo from 'src/components/organisms/LoginLayout/EmpxLogo'
import { RESET_PASSWORD } from 'src/router/routes'
import loginValidator from 'src/utils/validators/loginValidator'
import { requestLogin } from 'src/store/auth/actions'
import AppVersion from 'src/components/atoms/AppVersion'
import AppUpdateBanner from 'src/components/atoms/AppUpdateBanner'
import { defaultDateRange } from 'src/utils/date'
import { setFilter } from 'src/store/pilotageList/actions'

const Login = () => {
  const dispatch = useDispatch()

  const loginPaneContent = useMemo(
    () => (
      <>
        <SmallLoginPaneSection align="center">
          <EmpxLogo />
        </SmallLoginPaneSection>

        <LoginPaneSection align="center">
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={loginValidator}
            onSubmit={(values, actions) => {
              dispatch(requestLogin(values.email, values.password)).then(
                result => {
                  if (result) {
                    // date range defaults to next 5 days
                    dispatch(setFilter(defaultDateRange()))
                  } else {
                    actions.setSubmitting(false)
                  }
                }
              )
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      render={({ field }) => (
                        <TextInput
                          id="email"
                          type="email"
                          placeholder="Your email"
                          label="Email Address"
                          formikField={field}
                          error={touched.email && errors.email}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      render={({ field }) => (
                        <TextInput
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Your password"
                          label="Password"
                          formikField={field}
                          error={touched.password && errors.password}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <AuthSubmitButton disabled={isSubmitting}>
                  Log In
                </AuthSubmitButton>
              </Form>
            )}
          </Formik>
        </LoginPaneSection>

        <SmallLoginPaneSection align="flex-end">
          <Link routeName={RESET_PASSWORD}>Forgot Password</Link>
          <AppVersion mt={2} />
        </SmallLoginPaneSection>
      </>
    ),
    []
  )

  return (
    <>
      <Box position="absolute" top={0} left={0} right={0} zIndex={10}>
        <AppUpdateBanner />
      </Box>
      <LoginLayout
        loginPaneContent={loginPaneContent}
        imageSrc={containerShipImage}
      />
    </>
  )
}

export default Login
