export const ATTACHMENT_OPERATION = {
  UPLOAD: 'upload',
  DELETE: 'delete',
  REJECTED: 'rejected',
  REJECTED_DUPLICATE: 'rejected_duplicate',
  REJECTED_OVERSIZE: 'rejected_oversize',
}

export const ATTACHMENT_TYPE = {
  DUKC: 'dukc',
  WEATHER: 'weather',
  ADDITIONAL: 'additional',
}

export const ATTACHMENT_STATUS = {
  UPLOADING: 'uploading',
  DELETING: 'deleting',
  COMPLETED: 'completed',
  ERROR: 'error',
}

export const ATTACHMENT_SIZE_LIMIT = 1e6
export const ATTACHMENT_LIMIT = 10
export const ATTACHMENT_ACCEPT = 'image/jpeg, image/png, application/pdf'
