import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formatToTimeZone } from 'date-fns-timezone'

import { pilotageStartTimeSelector } from 'src/store/pilotage/selectors'
import { tideExtremesRequest } from 'src/store/tideExtremes/actions'
import { tideExtremesSelector } from 'src/store/tideExtremes/selectors'
import { tideStationsSelector } from 'src/store/route/selectors'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { OFFLINE_TIDES_DAYS_TO_PREFETCH } from 'src/utils/constants'

const useTideExtremes = () => {
  const dispatch = useDispatch()
  const pilotageStartTime = useSelector(pilotageStartTimeSelector)
  const tideExtremes = useSelector(tideExtremesSelector)
  const tideStations = useSelector(tideStationsSelector)
  const isOnline = useSelector(isOnlineSelector)

  // EMPX-322: Cannot use convertToTimeZone here, need Date to keep epoch unchanged
  // for formatToTimeZone to work correctly below.
  const fromDate = new Date(pilotageStartTime)
  // Go back 13 hours to account for diurnal tides (one high and low tide a day)
  // fromDate.setHours(fromDate.getHours() - 13)
  // EMPX-79: make the above consistent with offline prefetch tides.
  // Tidy up comment once things settle.
  fromDate.setDate(fromDate.getDate() - 1)

  // EMPX-322: Cannot use convertToTimeZone here, need Date to keep epoch unchanged
  // for formatToTimeZone to work correctly below.
  const toDate = new Date(pilotageStartTime)
  // Go forward far enough to pick up 3 diurnal tides after the pilotage start
  // time.
  // toDate.setHours(toDate.getHours() + 13 * 3)
  // EMPX-79: make the above consistent with offline prefetch tides - 1.
  // Tidy up comment once things settle.
  toDate.setDate(toDate.getDate() + OFFLINE_TIDES_DAYS_TO_PREFETCH - 1)

  // Use normal format function, not formatToTimeZone, because the dates
  // have already been converted to the correct timezone above.
  // EMPX-79: BE only exptects date, and will append times accordingly
  // so just YYYY-MM-DD without time is required.
  // EMPX-322: BE changed to store TZ as true UTC
  const fromDateFormatted = formatToTimeZone(fromDate, 'YYYY-MM-DD', { timeZone: 'UTC' })
  const toDateFormatted = formatToTimeZone(toDate, 'YYYY-MM-DD', { timeZone: 'UTC' })

  // Use tide station uuids for dependency comparison, as tide stations array
  // is manipulted in selectors, making it unstable as a dependency value 
  // @todo - don't manipulate in selectors, they should be read only!
  const tideStationUuids = tideStations.map(({ uuid }) => uuid).sort().join('_')

  useEffect(
    () => {
      if (isOnline) {
        tideStations.forEach(tideStation => {
          dispatch(
            tideExtremesRequest(
              tideStation.uuid,
              fromDateFormatted,
              toDateFormatted
            )
          )
        })
      }
    },
    [dispatch, isOnline, tideStationUuids, fromDateFormatted, toDateFormatted]
  )

  return tideExtremes
}

export default useTideExtremes
