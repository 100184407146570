import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import {
  DarkBox,
  FieldLabel,
  HighlightedCalculationDivider,
} from 'src/components/organisms/ConstraintDetails/common'
import TextInput from 'src/components/atoms/TextInput'
import { numberOrMissing, formatIfNumber } from 'src/utils/formatters'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import { CONSTRAINT_STATUS } from 'src/utils/constants'
import Tooltip from 'src/components/atoms/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'
import { isNumbersNotMatch } from './../../../utils/formatters'

const ConstraintDetailsUkcDyna = ({
  riskAssessment,
  readOnly
}) => {

  const {
    routeConstraint,
    constraintStatus,
    calculation,
    overrides,
    setOverride,
    tide,
    tideRaw,
    squatAllowance,
    swellAllowance,
    rissagaAllowance,
    time,
    value,
    valueRaw,
  } = riskAssessment

  const { constraint } = routeConstraint

  const tideRef = useRef(null)
  useEffect(() => {
    if (tideRef.current) {
      tideRef.current.value = formatIfNumber(overrides.tideOverrideValue, 2,
        formatIfNumber(tide, 2, '')
      )
    }
  }, [time, overrides.time])

  let Icon = null
  if (constraintStatus === CONSTRAINT_STATUS.PASSED) {
    Icon = TickIcon
  } else if (constraintStatus === CONSTRAINT_STATUS.FAILED) {
    Icon = CrossIcon
  }

  let overrideColourSafetyMargin = isNumbersNotMatch(calculation.variables.calculatedSafetyMargin,
    calculation.variables.calculatedSafetyMarginRaw, 2)
  let overrideColourTide = isNumbersNotMatch(tide, tideRaw, 2)
  let overrideColourValue = isNumbersNotMatch(value, valueRaw, 2)

  return (
    <>
      <Box display="flex" justifyContent="flex-end">

        <Box flex="0 0 50%" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Vessel Draft</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(calculation.variables.maxDraft, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Min. Available Depth</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              {
                constraint.valueOverridable
                  ? <TextInput
                    unit="m"
                    readOnly={readOnly}
                    placeholder='0.00'
                    overrideColour={overrideColourValue}
                    defaultValue={formatIfNumber(overrides.value, 2,
                      formatIfNumber(value, 2, '')
                    )}
                    onChange={setOverride.value}
                    onBlur={({ target }) => {
                      const formatted = formatIfNumber(target.value, 2,
                        formatIfNumber(value, 2, '')
                      )
                      setOverride.value(formatted)
                      target.value = formatted
                    }}
                    inputProps={{ inputMode: 'decimal' }}
                  />
                  : <TextInput
                    value={numberOrMissing(value, null, 2)}
                    readOnly
                    unit="m"
                  />
              }
            </Box>
          </Box>
        </Box>
        <Box flex={1} ml={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2}>
              <FieldLabel>Squat allow. (+)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                readOnly={readOnly}
                unit="m"
                placeholder='0.00'
                defaultValue={formatIfNumber(overrides.squatAllowance, 2,
                  formatIfNumber(squatAllowance, 2, '')
                )}
                onChange={setOverride.squatAllowance}
                onBlur={({ target }) => {
                  setOverride.squatAllowance(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(squatAllowance, 2, '')
                  )
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box display="flex" flex={1} pr={2} justifyContent="space-between">
              <FieldLabel>Predicted Tide (+)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourTide}
                defaultValue={
                  formatIfNumber(overrides.tideOverrideValue, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }
                onChange={setOverride.tideOverrideValue}
                onBlur={({ target }) => {
                  setOverride.tideOverrideValue(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }}
                inputRef={tideRef}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="50%" ml="auto" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box
              flex={1}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FieldLabel>SWL, P&amp;R, HVE ALLOW. (+)</FieldLabel>
              <Box pl={1}>
                <Tooltip content={TOOLTIPS.SWELL_ALLOWANCE} />
              </Box>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                defaultValue={formatIfNumber(overrides.swellAllowance, 2,
                  formatIfNumber(swellAllowance, 2, '')
                )}
                onChange={setOverride.swellAllowance}
                onBlur={({ target }) => {
                  setOverride.swellAllowance(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(swellAllowance, 2, '')
                  )
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} justifyContent="space-between">
              <FieldLabel>Tide CORR.FACT. (X)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(
                  calculation.variables.tideCorrectionFactor,
                  null,
                  2
                )}
                readOnly
              />
            </Box>
          </Box>
        </Box>
        <Box width="50%" ml="auto" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box
              flex={1}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FieldLabel>RISSAGA ALLOW. (+) </FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                defaultValue={formatIfNumber(overrides.rissagaAllowance, 2,
                  formatIfNumber(rissagaAllowance, 2, '')
                )}
                onChange={setOverride.rissagaAllowance}
                onBlur={({ target }) => {
                  setOverride.rissagaAllowance(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(rissagaAllowance, 2, '')
                  )
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} justifyContent="space-between">
              <FieldLabel>Corr. Tide Height</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                value={numberOrMissing(
                  calculation.variables.correctedTideHeight,
                  null,
                  2
                )}
                readOnly
              />
            </Box>
          </Box>
          <HighlightedCalculationDivider />
        </Box>
        <Box width="50%" ml="auto" p={1} pl={4}>
          <Box display="flex" alignItems="center">
            <Box
              flex={1}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FieldLabel>
                Safety Margin{' '}
                {calculation.variables.unitUsed === '%'
                  ? ` (+${routeConstraint.constraint.safetyMarginPct}%)`
                  : ' (+)'}
              </FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourSafetyMargin}
                defaultValue={formatIfNumber(overrides.safetyMargin, 2,
                  formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                )}
                onChange={setOverride.safetyMargin}
                onBlur={({ target }) => {
                  const formatted = formatIfNumber(target.value, 2,
                    formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                  )
                  setOverride.safetyMargin(formatted)
                  target.value = formatted
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </Box>
          <HighlightedCalculationDivider />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Available Depth</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.availableDepth,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </Box>
        <Box flex={1} ml={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Required Depth</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.requiredDepth,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Vessel Draft (-) </FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(calculation.variables.maxDraft, null, 2)}
              readOnly
              unit="m"
            />
          </Box>
        </Box>
        <Box flex={1} ml={3} p={1} display="flex" alignItems="center">
          <Box flex={1} pr={2}>
            <FieldLabel>Actual UKC</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(calculation.variables.actualUkc, null, 2)}
              readOnly
              unit="m"
            />
          </Box>
        </Box>
      </Box>
      <HighlightedCalculationDivider />

      <Box display="flex">
        <DarkBox flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" justifyContent="space-between">
              <FieldLabel>Static UKC</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(
                  calculation.variables.staticResult,
                  null,
                  2
                )}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </DarkBox>
        <DarkBox flex={1} ml={2} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" justifyContent="space-between">
              <FieldLabel>UKC Dyna</FieldLabel>
              {Icon && <Icon size={20} />}
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                value={numberOrMissing(calculation.result, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </DarkBox>
      </Box>
    </>
  )
}

ConstraintDetailsUkcDyna.propTypes = {
  riskAssessment: PropTypes.object,
  readOnly: PropTypes.bool,
  editableFields: PropTypes.object,
}

export default React.memo(ConstraintDetailsUkcDyna)
