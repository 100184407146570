import api from 'src/utils/api/core'

export function getTugs(portUuid) {
  return api.get('/tugs', { params: { portUuid } })
}

export function getPilotageTugs(uuid) {
  return api.get(`/pilotages/${uuid}/pilotage_tugs`)
}

export function addPilotageTug(pilotageUuid, tugUuid, lineNumber) {
  return api.post(`/pilotage_tugs`, {
    lineNumber,
    pilotage: {
      uuid: pilotageUuid,
    },
    tug: {
      uuid: tugUuid,
    },
  })
}

export function removePilotageTug(pilotageTugUuid) {
  return api.delete(`/pilotage_tugs/${pilotageTugUuid}`)
}
