import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getTideData } from 'src/utils/api/tides'
import { TIDE_TYPE } from 'src/utils/constants'
import { isOnlineSelector } from '../ui/selectors'

const TIDES_BASE = 'TIDES_BASE'

export const [
  TIDES_REQUEST,
  TIDES_SUCCESS,
  TIDES_ERROR,
  ,
  TIDES_IN_PROGRESS,
] = createBasicActionTypes(TIDES_BASE)

export const tidesRequest = (tideStationUuid, fromDate, toDate, opts) => async (
  dispatch,
  getState
) => {
  if (!isOnlineSelector(getState())) {
    return false
  }

  dispatch({
    type: TIDES_REQUEST,
    payload: { tideStationUuid },
  })
  // TODO: temporary workaround to stop UI flicker for constraints - remove when better solution found
  if (!opts || !opts.isPrefetch) {
    dispatch(tidesInProgress(tideStationUuid))
  }

  try {
    const response = await getTideData(
      tideStationUuid,
      fromDate,
      toDate,
      TIDE_TYPE.ALL
    )
    const payload = getPayloadFromResponse(response)
    const dataset = [].concat(...payload.map(item => item.metadata))

    const data = {}
    dataset.forEach(item => {
      const dateTime = new Date(item.dateTime).valueOf()
      data[dateTime] = item.tide
    })

    dispatch(tidesLoaded({ data, tideStationUuid }))
    return data
  } catch (error) {
    dispatch(tidesError({ error, tideStationUuid }))
    return false
  }
}

export const tidesInProgress = tideStationUuid =>
  createInProgressAction(TIDES_BASE, { tideStationUuid })

export const tidesError = data => dispatch => {
  dispatch(createErrorAction(TIDES_BASE, data))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.TIDES_ERROR,
    })
  )
}

export const tidesLoaded = data => createSuccessAction(TIDES_BASE, data)
