import React from 'react'

const PlanBerthingIcon = () => (
  <svg width="33" height="68">
    <g fillRule="nonzero" stroke="#FFF" fill="none">
      <path d="M32 68V0" />
      <path
        d="M5 67h11a4 4 0 0 0 4-4V14.762a4 4 0 0 0-.824-2.432l-5.5-7.183a4 4 0 0 0-6.352 0l-5.5 7.183A4 4 0 0 0 1 14.762V63a4 4 0 0 0 4 4z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default PlanBerthingIcon
