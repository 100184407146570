import { createSelector } from 'reselect'
import { EMPX_PING_LOG_ENABLED, ENTITY_NAME } from 'src/utils/constants'
import { isOnlinePingSelector } from '../onlinePing/selectors'
import { featureFlagManualOnlineEnabled } from '../preferences/selectors'

export const uiStateSelector = state => state.ui

export const isOnlineNavSelector = createSelector(
  uiStateSelector,
  state => state.isOnline
)

export const isOnlineSelector = createSelector(
  isOnlineNavSelector,
  isOnlinePingSelector,
  featureFlagManualOnlineEnabled,
  (isOnline, isOnlinePing, manualOfflineEnabled) => {
    if (EMPX_PING_LOG_ENABLED) {
      console.log(`isOnline [${isOnline}] isOnlinePing [${isOnlinePing}] manualOfflineEnabled [${manualOfflineEnabled}]`)
    }
    if (manualOfflineEnabled) {
      return isOnline && isOnlinePing
    }
    return isOnline
  }
)
export const isServiceWorkerAvailableSelector = createSelector(
  uiStateSelector,
  state => state.serviceWorkerAvailable
)

export const updateAvailableSelector = createSelector(
  uiStateSelector,
  state => state.updateAvailable
)

export const getUnsavedEntityStatusSelector = entityName =>
  createSelector(
    uiStateSelector,
    state => state.unsavedEntityStatus[entityName] === true
  )

export const unsavedPilotageSelector = getUnsavedEntityStatusSelector(
  ENTITY_NAME.PILOTAGE
)

export const unsavedVesselSelector = getUnsavedEntityStatusSelector(
  ENTITY_NAME.VESSEL
)

export const unsavedChecklistSelector = getUnsavedEntityStatusSelector(
  ENTITY_NAME.CHECKLIST
)

export const unsavedPassCautionChecklistSelector = getUnsavedEntityStatusSelector(
  ENTITY_NAME.PASS_CAUTION_CHECKLIST
)
