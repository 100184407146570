import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { updateVessel } from 'src/utils/api/vessels'

export const sync = syncItem =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        entity: { uuid: imo, data },
      } = syncItem
      const response = await updateVessel(imo, data)
      const result = getSinglePayloadFromResponse(response)
      resolve(result)
    } catch (error) {
      reject(error)
    }
  })

export const createSyncUuid = IMO =>
  `${IMO}-${SYNC_ENTITY_TYPES.PILOTAGE_VESSEL}`

export default sync
