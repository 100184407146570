export const SSO_AUTO_LOGIN_ENABLED = true

export const SSO_ENABLE_CONSOLE_LOG = false
export const SSO_ENABLE_ERROR_BORDER = false
// These are deliberate delays used to provide better UX when logging in/out
// ie to prevent flickers on sceen when the action is too quick
export const SSO_LOAD_CONFIG_DELAY_ENABLED = true
export const SSO_EMPX_LOGIN_DELAY_ENABLED = true
export const SSO_AAD_LOGIN_DELAY_ENABLED = true
export const SSO_AAD_LOGOUT_DELAY_ENABLED = true

// For registering localhost for SSO development, can set the below to true.
export const SSO_ENABLE_LOCALHOST = false
export const SSO_NOT_ENFORCE_HTTPS_PROTOCOL = false
export const SSO_NOT_ENFORCE_NO_PORT = false
// In addition, for non localhost development testing using ngrok.io or CloudFront, add URL to whitelist below
export const SSO_DOMAINS_WHITELIST = [
  // 'https://poallocal.ngrok.io',
  'https://dmry4yfwfkqk9.cloudfront.net',
]

// Error simulation for SSO, useful for regression testing.
// Going forward, we will put these test cases into an automated
// test plan.
export const SSO_SIM_ERROR_TENANT_ID = false
export const SSO_SIM_ERROR_CLIENT_ID = false
export const SSO_SIM_ERROR_REDIRECT_URL = false
export const SSO_SIM_ERROR_APP_URI = false
export const SSO_SIM_ERROR_SEND_NULL_TOKEN = false
export const SSO_SIM_ERROR_ACCOUNT_FOR_TOKEN = false
