import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import ChartMenuSection from 'src/components/molecules/ChartMenuSection'
import {
  CHART_SYMBOL_RENDER_DATA,
  CHART_SYMBOL_RENDER_TYPE,
} from 'src/utils/drawingConstants'
import { getSymbolIconRenderData } from 'src/utils/drawingHelpers'

const SymbolWrapper = styled.div`
  & svg,
  img {
    display: block;
  }
  & #style-root {
    fill: white;
    stroke: white;
  }
`

const SizedSvg = styled.div(
  ({ w, h, mt }) => css`
    & svg {
      width: ${w}px;
      height: ${h}px;
      margin-top: ${mt || 0}px;
    }
  `
)

const SizedImg = styled.img(
  ({ w, mt }) => css`
    width: ${w}px;
    margin-top: ${mt || 0}px;
  `
)

const renderSymbol = renderData => {
  let content
  switch (renderData.type) {
    case CHART_SYMBOL_RENDER_TYPE.IMAGE:
      content = (
        <SizedImg
          src={renderData.src}
          w={24}
          mt={-1}
          alt={renderData.variant.toLowerCase()}
        />
      )
      break
    case CHART_SYMBOL_RENDER_TYPE.SVG:
      content = (
        <SizedSvg
          w={24}
          h={(24 * renderData.height) / renderData.width}
          mt={-1}
          dangerouslySetInnerHTML={{ __html: renderData.svg }}
        />
      )
      break
    default:
      content = null
  }

  return <SymbolWrapper>{content}</SymbolWrapper>
}

const ChartSymbolPicker = ({
  onSelect,
  current,
  isMainVesselToScale,
  ...props
}) => {
  const symbols = Object.values(CHART_SYMBOL_RENDER_DATA)
    .filter(
      item =>
        item !== CHART_SYMBOL_RENDER_DATA.BERTHING_STAGE_VESSEL &&
        // For charts where the main vessel cannot be displayed to scale, don't show the main vessel symbol
        !(!isMainVesselToScale && item === CHART_SYMBOL_RENDER_DATA.SHIP_MAIN)
    )
    .map(({ variant }) => ({
      ...getSymbolIconRenderData(variant),
      value: variant,
    }))

  return (
    <ChartMenuSection
      current={current}
      onSelect={onSelect}
      label="Symbols"
      options={symbols}
      renderItem={renderSymbol}
      {...props}
    />
  )
}

ChartSymbolPicker.propTypes = {
  onSelect: PropTypes.func,
  current: PropTypes.string,
  isMainVesselToScale: PropTypes.bool,
}

export default React.memo(ChartSymbolPicker)
