import { WeatherLocation } from 'src/types/WeatherLocation'
import { WeatherLocationsAction } from './actions'

/* weather locations are stored for the currently active port */

export interface WeatherLocationsState {
  portUuid?: string
  isLoading: boolean
  error?: Error
  weatherLocations: WeatherLocation[]
}

export const INITIAL_STATE: WeatherLocationsState = {
  isLoading: false,
  weatherLocations: []
}

function weatherLocationsReducer(state = INITIAL_STATE, action: WeatherLocationsAction): WeatherLocationsState {

  switch (action.type) {
    case 'WEATHER_LOCATIONS_LIST_REQUEST': {
      return {
        ...state,
        portUuid: action.portUuid,
        isLoading: true
      }
    }
    case 'WEATHER_LOCATIONS_LIST_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        portUuid: action.portUuid,
        weatherLocations: action.weatherLocations
      }
    }
    case 'WEATHER_LOCATIONS_LIST_ERROR': {
      return {
        ...state,
        isLoading: false,
        portUuid: action.portUuid,
        error: action.error
      }
    }
    default:
      return state
  }
}

export default weatherLocationsReducer
