import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fade } from '@material-ui/core/styles/colorManipulator'

import { valueOrMissingText } from 'src/utils/formatters'

export const DataTable = styled.table`
  width: 100%;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};

  & td {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const DataTableRowLabel = styled.td`
  text-transform: uppercase;
`

const RowValueTd = styled.td`
  color: ${({ theme, isMissing }) =>
    fade(theme.palette.text.primary, isMissing ? 0.15 : 1)};
`

export const DataTableRowValue = ({ value, colSpan, children, ...props }) => {
  const isMissing = (value == null || value === '') && children == null

  return (
    <RowValueTd
      colSpan={colSpan === undefined ? 1 : colSpan}
      isMissing={isMissing}
      {...props}
    >
      {children == null ? valueOrMissingText(value) : children}
    </RowValueTd>
  )
}

DataTableRowValue.propTypes = {
  value: PropTypes.any,
  colSpan: PropTypes.number,
  children: PropTypes.node,
}
