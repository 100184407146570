import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'

import TextInput from 'src/components/atoms/TextInput'

const StyledPaper = styled(Paper)(
  ({ theme }) => `
  z-index: 1;
  left: 0;
  right: 0;
  margin-top: ${theme.spacing(1)}px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
`
)

const StyledMenuItem = styled(MenuItem).attrs({ component: 'div' })`
  && {
    height: 44px;
    text-transform: none;
    font-weight: ${({ theme, isSelected }) =>
      isSelected
        ? theme.weights.fontWeightSemiBold
        : theme.weights.fontWeightRegular};
  }
`

const StyledTextInput = styled(TextInput)`
  & input {
    flex: 1;
  }
`

const Typeahead = ({
  label,
  error,
  limit,
  onChange,
  results,
  isLoading,
  itemToString,
  itemFormatter,
  itemKeyFn,
  value,
  adornment,
  absolutePositionResults,
  ...props
}) => {
  const handleStateChange = changes => {
    let newValue
    if (changes.hasOwnProperty('selectedItem')) {
      newValue = changes.selectedItem
    } else if (changes.hasOwnProperty('inputValue')) {
      newValue = { text: changes.inputValue }
    }

    if (newValue) {
      onChange && onChange(newValue)
    }
  }

  return (
    <Downshift
      itemToString={item => ('text' in item ? item.text : itemToString(item))}
      selectedItem={value}
      onStateChange={handleStateChange}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        openMenu,
      }) => {
        const { ...inputProps } = getInputProps({
          ...props,
          onFocus: openMenu,
        })

        const suggestions = Array.isArray(results)
          ? typeof limit === 'number'
            ? results.slice(0, limit)
            : results
          : null

        const { style = {}, ...menuProps } = getMenuProps()
        if (absolutePositionResults) {
          style.position = 'absolute'
          style.width = '100%'
        }

        return (
          <div style={{ position: 'relative' }}>
            <StyledTextInput
              label={label}
              error={error}
              inputProps={inputProps}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{ endAdornment: adornment }}
            />
            <div {...menuProps} style={style}>
              {isOpen && (
                <StyledPaper square>
                  {isLoading && <StyledMenuItem>Searching...</StyledMenuItem>}
                  {suggestions && suggestions.length === 0 && !isLoading && (
                    <StyledMenuItem>No results found</StyledMenuItem>
                  )}
                  {suggestions &&
                    suggestions.map((item, index) => {
                      const isHighlighted = highlightedIndex === index
                      return (
                        <StyledMenuItem
                          {...getItemProps({ item })}
                          key={itemKeyFn(item)}
                          selected={isHighlighted}
                        >
                          {itemFormatter(item)}
                        </StyledMenuItem>
                      )
                    })}
                </StyledPaper>
              )}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

Typeahead.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  results: PropTypes.array,
  isLoading: PropTypes.bool,
  itemToString: PropTypes.func.isRequired,
  itemFormatter: PropTypes.func.isRequired,
  itemKeyFn: PropTypes.func.isRequired,
  value: PropTypes.shape({
    text: PropTypes.string,
  }),
  adornment: PropTypes.string,
  absolutePositionResults: PropTypes.bool,
}

Typeahead.defaultProps = {
  results: [],
}

export default Typeahead
