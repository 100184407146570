import * as React from "react"
import { BerthingCalculationResult } from "./types"
import { numberOrMissing } from 'src/utils/formatters'
import {
  CrossIcon,
  TickIcon,
} from 'src/components/atoms/Icons'
import styled from 'styled-components'

const MaxAllowableDraft = styled.tspan<{error: boolean}>`
  fill ${({ theme, error }) => error ? theme.palette.error.main : theme.palette.text.primary};
`
interface Props {
  result: BerthingCalculationResult
  isMasterView?: boolean
}

// source svg: https://www.figma.com/file/vux5Gze3WCTcl76tg7SmoZ/%5BPoAL01%5D-UI-Pilot-App-eMPX?node-id=4271%3A19901

const BerthingConditionsDiagram: React.FC<Props> = ({ result: {
  minTide,
  maxAllowableDraft,
  passed,
  berthDepth,
  calculatedSafetyMargin
}, isMasterView }) => {

  const vesselStroke = isMasterView ? '#000' : '#fff'

  return (
    <svg
      viewBox="0 0 782 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M182 257h376v12H169V124H0v-12h182v145z" fill="#667890" />
      <g opacity={0.33}>
        <mask
          id="prefix__b"
          maskUnits="userSpaceOnUse"
          x={182}
          y={102}
          width={375}
          height={69}
        >
          <g fill="#fff">
            <mask id="prefix__a">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M379.011 102H182v69h375v-69H379.011z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M379.011 102H182v69h375v-69H379.011z"
            />
            <path
              d="M182 102v-1h-1v1h1zm0 69h-1v1h1v-1zm375 0v1h1v-1h-1zm0-69h1v-1h-1v1zm-375 1h197.011v-2H182v2zm1 68v-69h-2v69h2zm196.011-1H182v2h197.011v-2zm3.397 0h-3.397v2h3.397v-2zM557 170H382.408v2H557v-2zm-1-68v69h2v-69h-2zm-173.592 1H557v-2H382.408v2zm-3.397 0h3.397v-2h-3.397v2z"
              mask="url(#prefix__a)"
            />
          </g>
        </mask>
        <g mask="url(#prefix__b)" stroke="#95A6B6">
          <path
            d="M224.719 140.379c6.526-5.919 19.252-4.492 24.483-.86 5.231 3.633 17.675 5.958 23.725 1.038M272.927 140.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.734 1.052M176.511 140.592c6.521-6.102 19.22-4.814 24.448-1.07 5.227 3.745 17.715 5.949 23.76.877M369.342 140.379c6.526-5.919 19.252-4.492 24.483-.86 5.231 3.633 17.676 5.958 23.725 1.038"
            strokeWidth={2}
          />
          <path d="M417.55 140.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.733 1.052" />
          <path
            d="M321.135 140.592c6.521-6.102 19.22-4.814 24.447-1.07 5.228 3.745 17.715 5.949 23.76.877M513.966 140.379c6.526-5.919 19.251-4.492 24.483-.86 5.231 3.633 17.675 5.958 23.724 1.038M417.55 140.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.733 1.052M465.758 140.592c6.521-6.102 19.22-4.814 24.447-1.07 5.228 3.745 17.715 5.949 23.76.877"
            strokeWidth={2}
          />
        </g>
      </g>
      <mask
        id="prefix__d"
        maskUnits="userSpaceOnUse"
        x={183}
        y={132}
        width={375}
        height={69}
      >
        <g fill="#fff">
          <mask id="prefix__c">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M380.011 132H183v69h375v-69H380.011z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M380.011 132H183v69h375v-69H380.011z"
          />
          <path
            d="M183 132v-1h-1v1h1zm0 69h-1v1h1v-1zm375 0v1h1v-1h-1zm0-69h1v-1h-1v1zm-375 1h197.011v-2H183v2zm1 68v-69h-2v69h2zm196.011-1H183v2h197.011v-2zm3.397 0h-3.397v2h3.397v-2zM558 200H383.408v2H558v-2zm-1-68v69h2v-69h-2zm-173.592 1H558v-2H383.408v2zm-3.397 0h3.397v-2h-3.397v2z"
            mask="url(#prefix__c)"
          />
        </g>
      </mask>
      <g mask="url(#prefix__d)" stroke="#95A6B6">
        <path
          d="M225.719 170.379c6.526-5.919 19.252-4.492 24.483-.86 5.231 3.633 17.675 5.958 23.725 1.038M273.927 170.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.734 1.052M177.511 170.592c6.521-6.102 19.22-4.814 24.448-1.07 5.227 3.745 17.715 5.949 23.76.877M370.342 170.379c6.526-5.919 19.252-4.492 24.483-.86 5.231 3.633 17.676 5.958 23.725 1.038"
          strokeWidth={2}
        />
        <path d="M418.55 170.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.733 1.052" />
        <path
          d="M322.135 170.592c6.521-6.102 19.22-4.814 24.447-1.07 5.228 3.745 17.715 5.949 23.76.877M514.966 170.379c6.526-5.919 19.251-4.492 24.483-.86 5.231 3.633 17.675 5.958 23.724 1.038M418.55 170.52c6.528-5.998 19.241-4.732 24.474-1.052 5.233 3.681 17.682 6.037 23.733 1.052M466.758 170.592c6.521-6.102 19.22-4.814 24.447-1.07 5.228 3.745 17.715 5.949 23.76.877"
          strokeWidth={2}
        />
      </g>
      <g fontFamily="Gilroy" fontWeight={500}>

        <text
          fill="#95A6B6"
          style={{
            whiteSpace: "pre",
          }}
          fontSize={14}
          letterSpacing={0.467}
        >
          <tspan x={612} y={144.767}>MAX. ALLOWABLE DRAFT</tspan>
        </text>
        <text
          fill={vesselStroke}
          style={{
            whiteSpace: "pre",
          }}
          fontSize={30}
          letterSpacing={0}
        >
          <MaxAllowableDraft x={612} y={173.715} error={!passed}>
            {numberOrMissing(maxAllowableDraft, 'm', 2)}
          </MaxAllowableDraft>
        </text>

        <svg x={720} y={150}>
          {
            passed ? <TickIcon light={isMasterView} /> : <CrossIcon />
          }
        </svg>


        <text
          fill="#95A6B6"
          style={{
            whiteSpace: "pre",
          }}
          fontSize={14}
          letterSpacing={0.467}
        >
          <tspan x={613} y={221.767}>SAFETY MARGIN (-)</tspan>
        </text>
        <text
          fill={vesselStroke}
          style={{
            whiteSpace: "pre",
          }}
          fontSize={30}
          letterSpacing={0}
        >
          <tspan x={613} y={249.715}>
            {numberOrMissing(calculatedSafetyMargin, 'm', 2)}
          </tspan>
        </text>


        <text
          fill="#95A6B6"
          style={{
            whiteSpace: "pre",
          }}
          fontSize={14}
          letterSpacing={0.467}
          textAnchor="end"
        >
          <tspan x={125} y={178.267}>BERTH DEPTH (+)</tspan>
        </text>
        <text
          fill={vesselStroke}
          style={{
            whiteSpace: "pre",
          }}
          fontSize={30}
          letterSpacing={0}
          textAnchor="end"
        >
          <tspan x={125} y={208.715}>
            {numberOrMissing(berthDepth, 'm', 2)}
          </tspan>
        </text>


        <text
          fill="#95A6B6"
          style={{
            whiteSpace: "pre",
          }}
          fontSize={14}
          letterSpacing={0.467}
          textAnchor="end"
        >
          <tspan x={125} y={39.267}>MIN. TIDE (+)</tspan>
          <tspan x={125} y={54.267}>DURING VISIT</tspan>
        </text>
        <text
          fill={vesselStroke}
          style={{
            whiteSpace: "pre",
          }}
          fontSize={30}
          letterSpacing={0}
          textAnchor="end"
        >
          <tspan x={125} y={81.715}>
            {numberOrMissing(minTide, 'm', 2)}
          </tspan>
        </text>
      </g>

      <path stroke={vesselStroke} strokeWidth={2} d="M245 65h248v48H245z" />

      <g fill="#fff">
        <mask id="prefix__e">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294 112h-50v50.667h.004C244.361 189.974 266.608 212 294 212h150c27.392 0 49.639-22.026 49.996-49.333H494V112H294z"
          />
        </mask>
        <path
          d="M244 112v-2h-2v2h2zm0 50.667h-2v2h2v-2zm.004 0l2-.026-.026-1.974h-1.974v2zm249.992 0v-2h-1.974l-.026 1.974 2 .026zm.004 0v2h2v-2h-2zM494 112h2v-2h-2v2zm-250 2h50v-4h-50v4zm2 48v-50h-4v50h4zm0 .667V162h-4v.667h4zm-1.996-2H244v4h.004v-4zM294 210c-26.296 0-47.653-21.145-47.996-47.359l-3.999.052C242.376 191.093 265.513 214 294 214v-4zm150 0H294v4h150v-4zm47.996-47.359C491.653 188.855 470.296 210 444 210v4c28.487 0 51.624-22.907 51.995-51.307l-3.999-.052zm2.004-1.974h-.004v4H494v-4zM492 162v.667h4V162h-4zm0-50v50h4v-50h-4zm-48 2h50v-4h-50v4zm-150 0h150v-4H294v4z"
          mask="url(#prefix__e)"
          fill={vesselStroke}
        />
      </g>
      <g fill="#fff">
        <mask id="prefix__f">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M409 0h-80v11h-85v21h40.286L294 66h150l10.389-34H494V11h-85V0z"
          />
        </mask>
        <path
          d="M329 0v-2h-2v2h2zm80 0h2v-2h-2v2zm-80 11v2h2v-2h-2zm-85 0V9h-2v2h2zm0 21h-2v2h2v-2zm40.286 0l1.923-.55-.415-1.45h-1.508v2zM294 66l-1.923.55.414 1.45H294v-2zm150 0v2h1.48l.433-1.416L444 66zm10.389-34v-2h-1.48l-.433 1.416 1.913.584zM494 32v2h2v-2h-2zm0-21h2V9h-2v2zm-85 0h-2v2h2v-2zm-80-9h80v-4h-80v4zm2 9V0h-4v11h4zm-2-2h-85v4h85V9zm-87 2v21h4V11h-4zm2 23h40.286v-4H244v4zm38.363-1.45l9.714 34 3.846-1.1-9.714-34-3.846 1.1zM294 68h150v-4H294v4zm151.913-1.416l10.389-34-3.826-1.168-10.389 34 3.826 1.168zM454.389 34H494v-4h-39.611v4zM496 32V11h-4v21h4zm-2-23h-85v4h85V9zm-87-9v11h4V0h-4z"
          mask="url(#prefix__f)"
          fill={vesselStroke}
        />
      </g>
      <g>
        <g filter="url(#prefix__filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M576.645 139.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <g filter="url(#prefix__filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M576.645 208.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <g filter="url(#prefix__filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M576.645 253.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <path d="M577 209v-64M576.5 258v-46" stroke="#1F8EFA" />
      </g>
      <g>
        <g filter="url(#prefix__filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M210.645 169.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <g filter="url(#prefix__filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M210.28 140a3.28 3.28 0 110 6.562 3.28 3.28 0 010-6.562z"
            fill="#1F8EFA"
          />
        </g>
        <g filter="url(#prefix__filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M153.645 169.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <g filter="url(#prefix__filter6_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M153.645 253.365a3.28 3.28 0 11.002 6.56 3.28 3.28 0 01-.002-6.56z"
            fill="#1F8EFA"
          />
        </g>
        <path d="M153.5 256v-83" stroke="#1F8EFA" />
      </g>
      <g strokeDasharray="3 3">
        <path stroke="#ED1C24" d="M601 212.5H183" />
        <path
          stroke="#1F8EFA"
          d="M557 142.5h44M557 257.5h44M132 257.5h44M132 172.5h44M132 53.5h78M210.5 54v117"
        />
      </g>

      <defs>
        <filter
          id="prefix__filter0_d"
          x={566.365}
          y={137.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter1_d"
          x={566.365}
          y={206.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter2_d"
          x={566.365}
          y={251.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter3_d"
          x={200.365}
          y={167.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter4_d"
          x={200}
          y={138}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter5_d"
          x={143.365}
          y={167.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter6_d"
          x={143.365}
          y={251.365}
          width={20.561}
          height={20.561}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={3.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default BerthingConditionsDiagram
