export interface GroupedResult<T> {
  key: string
  items: T[]
}

// Group an array of items by some key extractor function
// Note that the result is unsorted
// (both groups themselves and their items may need to be sorted)
export function groupBy<T>(arr: T[], getKey: (item: T) => string) {
  const groups: Record<string, T[]> = {}

  // group into a map by key
  for (const item of arr) {
    const key = getKey(item)
    if (!groups.hasOwnProperty(key)) {
      groups[key] = []
    }
    groups[key].push(item)
  }

  // collect back out into an (unsorted) array
  const grouped: Array<GroupedResult<T>> = []
  for (const key in groups) {
    grouped.push({ key, items: groups[key] })
  }

  return grouped
}

export function dedupBy<T>(arr: T[], getKey: (item: T) => any) {
  return arr.filter((item, index) =>  
      !arr.slice(0, index).find(prev =>
          getKey(prev) === getKey(item)
      )
  )
}
