import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import TextInput from 'src/components/atoms/TextInput'
import SignatureCanvas from 'react-signature-canvas'

const SignatureCanvasContainer = styled.div`
  background: #20293C;
  border-radius: 8px;
  margin-top: 10px;
  min-height: 180px;
`

const OutlineBox = styled(Box).attrs({ p: 2, width: 320 })`
  position: relative;
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
`

const SignOffHeader = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

const SignOffActions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    background: #3E4E6C;
    font-size: 11px;
    padding: 5px 15px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    outline: none;
  }
  button:disabled {
    opacity: 0.5;
  }
`

const CheckboxWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)}px;
  top: ${({ theme }) => theme.spacing(2)}px;
`

const CANVAS_WIDTH = 286
const CANVAS_HEIGHT = 180

const PilotageSignOff = ({
  isMaster,
  boxProps,
  isSignedOff,
  isEndState,
  name,
  onSignOff,
  readOnly,
  isLoading,
  useESignatures,
  signatures
}) => {
  const placeholder = isMaster ? "Master's name" : 'Unassigned'
  const role = isMaster ? 'Master' : 'Pilot'
  const [nameContent, setNameContent] = useState('')
  const [signatureContent, setSignatureContent] = useState('')
  const [signatureCanvas, setSignatureCanvas] = useState(null)
  const signOff = () => onSignOff({ nameContent, signatureContent })

  const latestSignature = signatures.sort((a, b) => {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  })[0]

  useEffect(
    () => {
      if (name) {
        setNameContent(name)
      }
    },
    [name]
  )

  useEffect(() =>
    () => signatureCanvas && signatureCanvas.off(),
  [signatureCanvas]
  )

  useEffect(() => {
    if (signatureCanvas && latestSignature) {
      signatureCanvas.fromDataURL(latestSignature.image, { width: CANVAS_WIDTH, height: CANVAS_HEIGHT })
      // EMPX-535 fromDataURL has to wait for image to load before writing to the canvas, so calling
      // toDataURL immediately after fromDataURL can result in a blank signature.
      // v3-beta of signature pad allows a 3rd argument for passing in a callback. We use setTimeout until
      // v3 is officially released.
      setTimeout(() => {
        setSignatureContent(signatureCanvas.toDataURL())
      }, 100)
    }
  },
  [signatureCanvas, latestSignature]
  )

  const handleSignature = () => {
    if (signatureCanvas) {
      setSignatureContent(signatureCanvas.toDataURL())
    }
  }

  const handleClearSignature = () => {
    if (signatureCanvas) {
      signatureCanvas.clear()
      setSignatureContent('')
    }
  }

  const canSignOff =
    (!useESignatures || signatureContent)
    && nameContent
    && !readOnly
    && !isLoading

  return (
    <OutlineBox {...boxProps} style={isSignedOff ? { border: '1px solid #05C985' } : {}}>
      <CheckboxWrapper>
        <Checkbox
          checked={isSignedOff || false}
          disabled={!canSignOff}
          onChange={signOff}
        />
      </CheckboxWrapper>
      <SignOffHeader>{role} Sign-Off</SignOffHeader>
      <Typography variant="h4">Accept and agree</Typography>
      <Box mt={2}>
        <TextInput
          value={nameContent}
          onChange={setNameContent}
          id={`signoff-${role}`}
          placeholder={placeholder}
          readOnly={!isMaster || readOnly}
        />
      </Box>
      {
        useESignatures &&
        <>
            {
              readOnly || isSignedOff
              ? (
                latestSignature
                ? (
                    <img
                      src={latestSignature.image}
                      alt="Signature"
                      style={{
                        width: CANVAS_WIDTH,
                        height: CANVAS_HEIGHT,
                        display: 'block',
                        borderBottom: '1px solid #20293C',
                        padding: 20,
                        boxSizing: 'border-box'
                      }}
                    />
                  )
                  : (!isEndState && <SignatureCanvasContainer></SignatureCanvasContainer>)
                )
              : (
                <>
                  <SignatureCanvasContainer>
                    <SignatureCanvas
                      ref={setSignatureCanvas}
                      penColor='#FFF'
                      onEnd={handleSignature}
                      canvasProps={{ width: CANVAS_WIDTH, height: CANVAS_HEIGHT }}
                    />
                  </SignatureCanvasContainer>

                  <SignOffActions>
                    <SignOffHeader>Sign Above</SignOffHeader>
                    <button disabled={readOnly} onClick={handleClearSignature}>Clear</button>
                  </SignOffActions>
                </>
              )
            }

        </>
      }
    </OutlineBox>
  )
}

PilotageSignOff.propTypes = {
  isMaster: PropTypes.bool,
  boxProps: PropTypes.object,
  isSignedOff: PropTypes.bool,
  isEndState: PropTypes.bool,
  name: PropTypes.string,
  onSignOff: PropTypes.func,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  useESignatures: PropTypes.bool,
  signatures: PropTypes.arrayOf(PropTypes.objectOf)
}

export default React.memo(PilotageSignOff)
