import { SYNC_STATUS } from 'src/utils/constants'
import {
  SYNC_ADD_ENTITY,
  SYNC_IN_PROGRESS,
  SYNC_SUCCESS,
  SYNC_ERROR,
  SYNC_RESET,
  SYNC_ENTITY_IN_PROGRESS,
  SYNC_ENTITY_SUCCESS,
  SYNC_ENTITY_ERROR,
} from 'src/store/sync/actions'

export const initialState = {
  entities: {},
  status: null,
  lastSyncAttempt: null,
  error: null,
}

const getStatusForEntitySyncActionType = type => {
  switch (type) {
    case SYNC_ENTITY_IN_PROGRESS:
      return SYNC_STATUS.IN_PROGRESS
    case SYNC_ENTITY_ERROR:
      return SYNC_STATUS.ERROR
    case SYNC_ENTITY_SUCCESS:
      return SYNC_STATUS.SUCCESS
    default:
      return SYNC_STATUS.PENDING
  }
}

export const reducer = (state = initialState, action) => {
  const { type, payload, error } = action
  const { entities, ...restOfState } = state
  switch (type) {
    case SYNC_IN_PROGRESS:
      return {
        ...state,
        status: SYNC_STATUS.IN_PROGRESS,
        lastSyncAttempt: payload,
      }
    case SYNC_SUCCESS:
      return {
        ...state,
        status: SYNC_STATUS.SUCCESS,
        lastSyncAttempt: payload,
      }
    case SYNC_ERROR: {
      return {
        ...state,
        status: SYNC_STATUS.ERROR,
        lastSyncAttempt: payload,
        error,
      }
    }

    case SYNC_ADD_ENTITY: {
      const { uuid, entity, ...restOfPayload } = payload
      if (!entity.uuid) {
        return state
      }
      return {
        ...restOfState,
        status: SYNC_STATUS.PENDING,
        entities: {
          ...entities,
          [uuid]: {
            uuid,
            ...restOfPayload,
            entity: {
              // we allow partial update
              ...((entities[uuid] && entities[uuid].entity) || {}),
              ...entity,
            },
            status: SYNC_STATUS.PENDING,
          },
        },
      }
    }

    case SYNC_ENTITY_IN_PROGRESS:
    case SYNC_ENTITY_ERROR:
    case SYNC_ENTITY_SUCCESS:
      const { uuid } = payload
      const status = getStatusForEntitySyncActionType(type)
      return {
        ...state,
        status: SYNC_STATUS.IN_PROGRESS,
        entities: {
          ...entities,
          [uuid]: {
            ...entities[uuid],
            status,
          },
        },
      }
    case SYNC_RESET: {
      return {
        ...initialState,
        lastSync: state.lastSyncAttempt,
      }
    }

    default:
      return state
  }
}

export default reducer
