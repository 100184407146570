import { PilotageChangeSet } from 'src/utils/calculatePilotageChangeset'
import { formatToTimeZone } from 'date-fns-timezone'
import { DATE_FORMAT, TIME_FORMAT } from 'src/utils/constants'
import {
  formatUsername,
  numberOrMissing,
  valueOrMissingText,
} from 'src/utils/formatters'

type ChangeData = { valueName: string; previous: any; current: any }

export default function formatPilotageChangeSet(
  changeSet: PilotageChangeSet,
  timeZone: string
): ChangeData[] {
  const changes: ChangeData[] = []

  const addChange = (valueName: string, previous: any, current: any) => {
    changes.push({
      valueName,
      previous: valueOrMissingText(previous),
      current: valueOrMissingText(current),
    })
  }

  if (changeSet.date) {
    const { previous, current } = changeSet.date
    const previousTime = formatToTimeZone(previous, TIME_FORMAT, { timeZone })
    const previousDate = formatToTimeZone(previous, DATE_FORMAT, { timeZone })
    const currentTime = formatToTimeZone(current, TIME_FORMAT, { timeZone })
    const currentDate = formatToTimeZone(current, DATE_FORMAT, { timeZone })
    if (previousTime !== currentTime) {
      addChange('Time', previousTime, currentTime)
    }
    if (previousDate !== currentDate) {
      addChange('Date', previousDate, currentDate)
    }
  }

  if (changeSet.movementType) {
    const { previous, current } = changeSet.movementType
    addChange('Movement Type', previous, current)
  }

  if (changeSet.fromLocation) {
    const { previous, current } = changeSet.fromLocation
    const from = previous ? previous.name : ''
    const to = current ? current.name : ''
    addChange('From Location', from, to)
  }

  if (changeSet.toLocation) {
    const { previous, current } = changeSet.toLocation
    const from = previous ? previous.name : ''
    const to = current ? current.name : ''
    addChange('To Location', from, to)
  }

  if (changeSet.sideTo) {
    const { previous, current } = changeSet.sideTo
    addChange('Side To', previous, current)
  }

  if (changeSet.vessel) {
    const { previous, current } = changeSet.vessel

    const previousIMO = previous ? previous.IMO : ''
    const currentIMO = current ? current.IMO : ''
    if (previousIMO !== currentIMO) {
      addChange('Vessel IMO', previousIMO, currentIMO)
    }

    const previousName = previous ? previous.name : ''
    const currentName = current ? current.name : ''
    if (previousName !== currentName) {
      addChange('Vessel Name', previousName, currentName)
    }
  }

  if (changeSet.vesselMaxDraft) {
    const { previous, current } = changeSet.vesselMaxDraft
    const from = numberOrMissing(previous, 'm', 2)
    const to = numberOrMissing(current, 'm', 2)
    addChange('Max. Draft', from, to)
  }

  if (changeSet.route) {
    const { previous, current } = changeSet.route
    const from = previous ? previous.name : ''
    const to = current ? current.name : ''
    addChange('Route', from, to)
  }

  if (changeSet.pilot) {
    const { previous, current } = changeSet.pilot
    addChange(
      'Pilot',
      formatUsername(previous) || '',
      formatUsername(current) || ''
    )
  }

  if (changeSet.pilotSecond) {
    const { previous, current } = changeSet.pilotSecond
    addChange(
      'Second Pilot',
      formatUsername(previous) || '',
      formatUsername(current) || ''
    )
  }

  if (changeSet.notes) {
    const { previous, current } = changeSet.notes
    addChange('Notes', previous, current)
  }

  if (changeSet.pilotageMode) {
    const { previous, current } = changeSet.pilotageMode
    addChange('Pilotage Mode', previous, current)
  }

  if (changeSet.transferMethod) {
    const { previous, current } = changeSet.transferMethod
    addChange('Transfer Method', previous, current)
  }

  if (changeSet.vesselFwdDraft) {
    const { previous, current } = changeSet.vesselFwdDraft
    const from = numberOrMissing(previous, 'm', 2)
    const to = numberOrMissing(current, 'm', 2)
    addChange('Fwd. Draft', from, to)
  }

  if (changeSet.vesselAftDraft) {
    const { previous, current } = changeSet.vesselAftDraft
    const from = numberOrMissing(previous, 'm', 2)
    const to = numberOrMissing(current, 'm', 2)
    addChange('Aft Draft', from, to)
  }

  if (changeSet.vesselDisplacement) {
    const { previous, current } = changeSet.vesselDisplacement
    const from = numberOrMissing(previous, 'm', 2)
    const to = numberOrMissing(current, 'm', 2)
    addChange('Displacement', from, to)
  }

  if (changeSet.vesselAgent) {
    const { previous, current } = changeSet.vesselAgent
    addChange('Agent', previous, current)
  }

  return changes
}
