import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useRouter } from 'react-router5'
import styled from 'styled-components'
import { Field, Form, Formik } from 'formik'
import { Box, Grid, Typography } from '@material-ui/core'

import { LOGIN } from 'src/router/routes'
import { requestResetPassword } from 'src/store/auth/actions'
import TextInput from 'src/components/atoms/TextInput'
import AuthSubmitButton from 'src/components/atoms/AuthSubmitButton'
import LoginPaneSection from 'src/components/organisms/LoginLayout/LoginPaneSection'
import newPasswordValidator from 'src/utils/validators/newPasswordValidator'

const FormSection = styled(LoginPaneSection)`
  align-items: flex-start;
  padding-top: 0;
`

const CreateNewPasswordForm = ({ token }) => {
  const dispatch = useDispatch()
  const router = useRouter()

  return (
    <Box width="100%" display="flex" flexDirection="column" m="auto">
      <Typography variant="h1">Reset Password</Typography>
      <Typography variant="subtitle2">
        Enter your new password below.
      </Typography>

      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={newPasswordValidator}
        onSubmit={(values, actions) => {
          dispatch(requestResetPassword(token, values.password)).then(
            result => {
              if (result) {
                router.navigate(LOGIN)
              } else {
                actions.setSubmitting(false)
              }
            }
          )
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate>
            <FormSection>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="password"
                    render={({ field }) => (
                      <TextInput
                        type="password"
                        id="create-password"
                        placeholder="Password"
                        label="Create Password"
                        formikField={field}
                        error={touched.password && errors.password}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="confirmPassword"
                    render={({ field }) => (
                      <TextInput
                        type="password"
                        id="confirm-password"
                        placeholder="Password"
                        label="Confirm Password"
                        formikField={field}
                        error={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <AuthSubmitButton disabled={isSubmitting}>
                Reset Password
              </AuthSubmitButton>
            </FormSection>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

CreateNewPasswordForm.propTypes = {
  token: PropTypes.string,
}

export default CreateNewPasswordForm
