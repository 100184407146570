import React, { ReactElement } from 'react'
import { BerthingConditionsInputs, BerthingCalculation } from './types'
import { useBerthingCalculation } from './useBerthingCalculation'

export const BerthingConditionsDataLoader: React.FC<
    BerthingConditionsInputs & {
        children: (berthingCalculation: BerthingCalculation) => ReactElement
    }
> = ({ children, ...inputs }) => {
    const berthingCalculation = useBerthingCalculation(inputs) 

    return children(berthingCalculation)
}

export default BerthingConditionsDataLoader