import { DhiNcosGraphViewModel } from 'src/components/organisms/Dhi/DhiNcosGraph'
import api from 'src/utils/api/core'

export async function getNcos(portUuid: string, vesselIMO: string, pilotageStart: string): Promise<DhiNcosGraphViewModel> {
  try {
    const res = await api.get<{ data: any }>(`/dhi/ncos`, { params: {
      portUuid,
      vesselIMO,
      pilotageStart
    } })
    return res && res.data && res.data.data && res.data.data[0]

  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getDhiTransits(portUuid: string, pilotageStart: string, vesselIMO: string): Promise<any> {
  try {
    const res = await api.get<{ data: any }>(`/dhi/transits`, { params: {
      portUuid,
      pilotageStart,
      vesselIMO,
    } })
    return res && res.data && res.data.data && res.data.data[0]

  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getDhiTransitsPdf(portUuid: string, scenarioId: string): Promise<any> {
  return api.get<{ data: any }>(`/dhi/transits-pdf`, {
    params: {
      portUuid,
      scenarioId,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
    // disable timeout for PDF download
    timeout: 0,
  })
}
