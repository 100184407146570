import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import {
  AttachmentHeadingStatic,
  ExistingAttachment,
} from 'src/components/organisms/Attachments/common'
import AttachmentIcon from 'src/components/atoms/AttachmentIcon'
import ButtonRow from 'src/components/atoms/ButtonRow'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

const StyledAttachment = styled(ExistingAttachment)`
  border: 0;
  padding: 0;
`

const AttachmentDelete = ({ attachment, isLoading, onConfirm, onCancel }) => (
  <>
    <Box my={4}>Are you sure you want to delete this attachment?</Box>
    {attachment && (
      <Box mb={4} mt={-4}>
        <StyledAttachment>
          <Box display="flex" alignItems="center">
            <AttachmentIcon mimeType={attachment.mimeType} />
            <AttachmentHeadingStatic>{attachment.name}</AttachmentHeadingStatic>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            {isLoading && <CircularProgress size={32} />}
          </Box>
        </StyledAttachment>
      </Box>
    )}
    <ButtonRow>
      <SecondaryButton disabled={isLoading} onClick={onConfirm}>
        Delete
      </SecondaryButton>
      <PrimaryButton onClick={onCancel}>Keep</PrimaryButton>
    </ButtonRow>
  </>
)

AttachmentDelete.propTypes = {
  attachment: PropTypes.object,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default React.memo(AttachmentDelete)
