import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded'
import KeyboardArrowUpRounded from '@material-ui/icons/KeyboardArrowUpRounded'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AppUpdateBanner from 'src/components/atoms/AppUpdateBanner'
import PilotageFilter from 'src/components/organisms/PilotageFilter'
import DateRangePicker, {
  DateRange,
} from 'src/components/organisms/DateRangePicker'
import { formatCalendarDate, padCalendarDateString } from 'src/utils/date'
import {
  SearchQuery,
  FilterOptions,
  queryGetActiveKeys,
} from 'src/utils/searchAndFilterUtils'
import FilterIcon from 'src/components/atoms/Icons/FilterIcon'
import FilterForm from 'src/components/organisms/FilterForm'
import { CrossOutlinedIcon } from 'src/components/atoms/Icons'
import Modal from 'src/components/molecules/Modal'
import { setFilter } from 'src/store/pilotageList/actions'
import { showDate } from './../../../utils/date'
import { addDays } from 'date-fns'
import { setAscending } from './../../../store/pilotageList/actions'
import { pilotageAscendingSelector } from './../../../store/pilotageList/selectors'

const RefreshContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    border-left: 1px solid ${theme.palette.background.dark};
    padding-left: ${theme.spacing(2)}px;
    height: 45px;
  `
)

const RefreshButton = styled(Button)(
  ({ theme }) => css`
    && {
      color: ${theme.palette.text.secondary};
      text-transform: none;
      padding-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(1)}px;

      &.Mui-disabled {
        color: inherit;
        opacity: 0.5;
      }
    }
  `
)

const RefreshIconStyled = styled(RefreshIcon)(
  ({ theme }) => css`
    && {
      margin-right: ${theme.spacing(1)}px;
      font-size: 32px;
      color: ${theme.palette.text.primary};
    }
  `
)

const RefreshButtonLabel = styled.span`
  width: 80px;
  text-align: left;
`

const DateRangeContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

const ActionContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-left: 1px solid ${theme.palette.background.dark};
    padding-left: ${theme.spacing(2)}px;
    height: 45px;
  `
)

const FilterContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-left: 1px solid ${theme.palette.background.dark};
    padding-left: ${theme.spacing(2)}px;
    height: 45px;
    margin-right: -10px;
  `
)

const ActionButton = styled(Button)(
  ({ theme }) => css`
    && {
      color: ${theme.palette.text.secondary};
      text-transform: none;
      padding-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(1)}px;

      &.Mui-disabled {
        color: inherit;
        opacity: 0.5;
      }
    }
  `
)

const DateRangeIconInactive = styled(DateRangeIcon)(
  ({ theme }) => css`
    && {
      margin-right: ${theme.spacing(1)}px;
      font-size: 28px;
      color: ${theme.palette.text.primary};
    }
  `
)

const DateRangeIconActive = styled(DateRangeIcon)(
  ({ theme }) => css`
    && {
      margin-right: ${theme.spacing(1)}px;
      font-size: 28px;
      color: ${theme.palette.primary.main};
    }
  `
)

const ActionButtonLabel = styled.span`
  font-size: 16px;
  text-align: left;
  padding: 0px 5px;
`

const Dates = styled.div`
  font-size: 10px;
  line-height: 15px;
  color: #fff;
  display: flex;
  align-items: center;
`

const ClearDates = styled.div`
  flex: 1;
  padding: 5px 5px 5px 10px;
`

const ClearFilter = styled.div`
  flex: 1;
  padding: 6px 0px 5px 10px;
`

interface Props {
  userUuid: string
  pilotageCount: number
  isRefreshing: boolean
  isOnline: boolean
  onRefresh: () => any
  query: SearchQuery
  filterOptions: FilterOptions
}

const PilotageListHeaderContent: React.FC<Props> = ({
  userUuid,
  pilotageCount,
  onRefresh,
  isRefreshing,
  isOnline,
  query,
  filterOptions,
}) => {
  const dispatch = useDispatch()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false)
  const start = padCalendarDateString(query.startDate)
  const end = padCalendarDateString(query.endDate)
  const isFilterActive = queryGetActiveKeys(query).length > 0
  const isAscending = useSelector(pilotageAscendingSelector)

  const clearDate = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setFilter({
      ...query,
      page: 1,
      startDate: undefined,
      endDate: undefined,
    }))
  }

  const setUpcoming = (event: React.MouseEvent) => {
    event.stopPropagation()
    const now = new Date()
    const startDate = showDate(now)
    const endDate = showDate(addDays(now, 7))
    dispatch(setFilter({
      ...query,
      page: 1,
      startDate,
      endDate,
    }))
  }

  const setRecent = (event: React.MouseEvent) => {
    event.stopPropagation()
    const now = new Date()
    const startDate = showDate(addDays(now, -8))
    const endDate = showDate(addDays(now, -1))
    dispatch(setFilter({
      ...query,
      page: 1,
      startDate,
      endDate,
    }))
  }

  const toggleSortOrder = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setAscending(!isAscending))
  }

  const clearFilter = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setFilter({
      page: 1,
      startDate: start,
      endDate: end,
    }))
  }

  const handleDateRangeChange = (range: DateRange) => {
    setIsDateRangeOpen(false)
    // exclude (reset) pagination because search query has changed
    dispatch(setFilter({
      ...query,
      page: 1,
      startDate: range.start,
      endDate: range.end,
    }))
  }

  const handleFilterChange = (query: SearchQuery) => {
    setIsFilterOpen(false)
    dispatch(setFilter({
      ...query,
      page: 1,
    }))
  }

  const handleFilterMyPilotages = () => {
    if (filterOptions.pilot.find(({ value }) => value === userUuid)) {
      dispatch(setFilter({
        ...query,
        pilot: userUuid,
        page: 1,
      }))
    }
  }

  caches.open('apiCache').then(cache => {
    cache.keys().then(apiCacheKeysArr => {
      const apiCacheCount = apiCacheKeysArr.length
      console.log(`apiCacheCount [${apiCacheCount}]`)
    })
  })
  caches.open('pilotageCache').then(cache => {
    cache.keys().then(pilotageCacheKeysArr => {
      const pilotageCacheCount = pilotageCacheKeysArr.length
      console.log(`pilotageCacheCount [${pilotageCacheCount}]`)
    })
  })
  caches.open('chartImageCache').then(cache => {
    cache.keys().then(chartImageCacheKeysArr => {
      const chartImageCacheCount = chartImageCacheKeysArr.length
      console.log(`chartImageCacheCount [${chartImageCacheCount}]`)
    })
  })

  return (
    <>
      <AppUpdateBanner />
      <Box display="flex" alignItems="center">
        <Box pr={4}>
          <PilotageFilter
            onClick={handleFilterMyPilotages}
            totalPilotages={pilotageCount}
          />
        </Box>

        <RefreshContainer>
          <RefreshButton
            onClick={onRefresh}
            disabled={isRefreshing || !isOnline}
          >
            <RefreshIconStyled />
            <RefreshButtonLabel>
              {isRefreshing ? 'Loading...' : 'Refresh'}
            </RefreshButtonLabel>
          </RefreshButton>
        </RefreshContainer>

        <div style={{ flex: 1 }} />

        <ActionContainer>
          { isAscending &&
            <RefreshButton onClick={toggleSortOrder}>Sort <KeyboardArrowUpRounded/></RefreshButton>
          }
          { !isAscending &&
            <RefreshButton onClick={toggleSortOrder}>Sort <KeyboardArrowDownRounded/></RefreshButton>
          }
          <RefreshButton
            onClick={setRecent}
          >
            Past 7
          </RefreshButton>
          <RefreshButton
            onClick={setUpcoming}
          >
            Next 7
          </RefreshButton>
          <ActionButton onClick={() => setIsDateRangeOpen(true)}>
            {start && end ? <DateRangeIconActive /> : <DateRangeIconInactive />}
            <ActionButtonLabel>
              {start && end ? (
                <Dates>
                  <div>
                    <div>{formatCalendarDate(start)}</div>
                    <div>{formatCalendarDate(end)}</div>
                  </div>
                  <ClearDates onClick={clearDate}>
                    <CrossOutlinedIcon />
                  </ClearDates>
                </Dates>
              ) : (
                <div>Date range</div>
              )}
            </ActionButtonLabel>
          </ActionButton>

          <Modal
            open={isDateRangeOpen}
            includeHeadingDivider={false}
            onClose={() => setIsDateRangeOpen(false)}
            heading={''}
          >
            <DateRangeContainer>
              <DateRangePicker
                onCancel={() => setIsDateRangeOpen(false)}
                onSubmit={handleDateRangeChange}
                initialValues={{
                  start,
                  end,
                }}
              />
            </DateRangeContainer>
          </Modal>
        </ActionContainer>

        <FilterContainer>
          <ActionButton onClick={() => setIsFilterOpen(true)}>
            <FilterIcon active={isFilterActive} />
            <ActionButtonLabel>Filter</ActionButtonLabel>
            {isFilterActive && (
              <ClearFilter onClick={clearFilter}>
                <CrossOutlinedIcon />
              </ClearFilter>
            )}
          </ActionButton>

          <Modal
            open={isFilterOpen}
            heading="Filter Pilotages"
            onClose={() => setIsFilterOpen(false)}
          >
            <FilterForm
              options={filterOptions}
              query={query}
              onCancel={() => setIsFilterOpen(false)}
              onSubmit={handleFilterChange}
            />
          </Modal>
        </FilterContainer>
      </Box>
    </>
  )
}

export default React.memo(PilotageListHeaderContent)
