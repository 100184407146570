import {
  BerthingCalculation,
  BerthingConditionsInputs,
  Pilotage,
  RiskAssessment,
  DepartingPilotage,
  Tides,
  TideExtremes
} from './types'
import { calculateSafetyMargin, mapLegacySafetyMargin } from 'src/utils/constraint'
import quantizeNumber from 'src/utils/quantize'
import { useMemo } from 'react'

export const DEFAULT_VISIT_DURATION = 1000 * 60 * 60 * 48 // 48 hours

export const getBerthingRiskAssessment = (pilotage: Pilotage, riskAssessment: RiskAssessment[]) => {

  if (!riskAssessment) {
    return
  }
  const lastRisk = riskAssessment[riskAssessment.length - 1]
  if (!lastRisk) { return }

  const {
    routeConstraint: {
      constraint: { constraintType, berthStation }
    }
  } = lastRisk

  if (berthStation &&
        berthStation.berthStationType === 'berth' &&
        berthStation.uuid === pilotage.toLocation.uuid &&
        (constraintType === 'ukc' || constraintType === 'ukc_dyna')
  ) {
    return lastRisk
  }
}

// try to get exact, then estimated departure time, but only if they
// are at least the default visit duration after time at last constraint
export const getDepartureTime = ({
  pilotage,
  departingPilotage,
  timeAtLastConstraint
}: {
    pilotage: Pilotage,
    departingPilotage?: DepartingPilotage,
    timeAtLastConstraint: string
}): [Date, string] => {

  const arrival = new Date(timeAtLastConstraint)
  const arrivalPlusDefaultDuration = arrival.valueOf() + DEFAULT_VISIT_DURATION
  const disableEtdAndExact = true
  if (!disableEtdAndExact) {
    const exact = departingPilotage && new Date(departingPilotage.date)
    const estimated = pilotage.etd && new Date(pilotage.etd)

    if (exact && exact.valueOf() >= arrivalPlusDefaultDuration) {
      return [exact, 'exact']
    }
    if (estimated && estimated.valueOf() >= arrivalPlusDefaultDuration) {
      return [estimated, 'estimated']
    }
  }
  return [new Date(arrivalPlusDefaultDuration), 'default']
}

// Assuming that the interval between tide data points is regular
// and if there are tide data points for X and Y that there are points between them
// we consider that there is enough tide data if there is a start and end point
// quantized to that interval. Then we take the minimum value observed between them (inclusive).
export function getMinTideRaw (
  arrivalTime: Date,
  departureTime: Date,
  tides?: Tides
): [string, number] | undefined {

  if (!tides || !tides.data) { return }

  const { data } = tides
  const tideKeys = Object.keys(data).map(Number).sort()

  if (!tideKeys[0] || !tideKeys[1]) {
    return
  }

  const interval = tideKeys[1] - tideKeys[0]
  const start = quantizeNumber(arrivalTime.valueOf(), interval, false) // quantize down (fit)
  const end = quantizeNumber(departureTime.valueOf(), interval, true) // quantize up (cover)

  if (!data[start] || !data[end]) {
    return
  }

  const tideKeyRange = tideKeys.slice(
    tideKeys.indexOf(start),
    tideKeys.indexOf(end) + 1
  )

  const minTideRaw = minBy(
    tideKeyRange,
    (tideKey) => data[tideKey]
  )

  return minTideRaw !== undefined ? ['tides', minTideRaw] : undefined
}

// for a given arrival and departure time, we also pad by 12 hours
// if we can observe a tide extreme withing the 12 hours <= start
// and 12 hours >= end, we can say there is enough tide extreme data
export function getMinTideExtremeRaw (
  arrivalTime: Date,
  departureTime: Date,
  tideExtremes?: TideExtremes
): [string, number] | undefined {

  if (!tideExtremes || !tideExtremes.data) {
    return
  }

  const data = tideExtremes.data
  const PAD = 1000 * 60 * 60 * 12
  const start = arrivalTime.valueOf()
  const startPadded = start - PAD
  const end = departureTime.valueOf()
  const endPadded = end + PAD

  const range = data.filter(({ dateTime }) =>
    dateTime >= startPadded && dateTime <= endPadded
  )

  const beforeStartRange = range.filter(({ dateTime }) =>
    dateTime <= start
  )

  const afterEndRange = range.filter(({ dateTime }) =>
    dateTime >= end
  )

  if (beforeStartRange.length === 0 || afterEndRange.length === 0) {
    return
  }

  const minTideExtremeRaw = minBy(range, ({ tide }) => tide)

  return minTideExtremeRaw !== undefined ? ['extremes', minTideExtremeRaw] : undefined
}

export const getMinTide = (
  arrivalTime: Date,
  departureTime: Date,
  tides: { [tideStationUuid: string]: Tides },
  tideExtremes: { [tideStationUuid: string]: TideExtremes },
  tideStationUuid: string
) =>
  getMinTideRaw(arrivalTime, departureTime, tides[tideStationUuid]) ||
    getMinTideExtremeRaw(arrivalTime, departureTime, tideExtremes[tideStationUuid], ) ||
    ['failed', undefined]

export function minBy<T> (array: T[], getValue: (item: T) => number | undefined): number | undefined {
  let min: number | undefined
  for (const item of array) {
    const value = getValue(item)
    if (typeof value === 'number' &&
            ((typeof min === 'number' && value < min) || min === undefined)
    ) {
      min = value
    }
  }
  return min
}

export const useBerthingCalculation = ({
  pilotage,
  departingPilotage,
  timeZone,
  riskAssessment,
  tides,
  tideExtremes,
  maxDraft,
  routeChecks,
}: BerthingConditionsInputs): BerthingCalculation => {

  return useMemo(() => {

    const berthingRiskAssessment = getBerthingRiskAssessment(pilotage, riskAssessment)
    if (!berthingRiskAssessment) {
      return {
        type: 'failed',
        error: 'No valid berthing constraint found. ' +
                'The final constraint must be assigned a berth that matches the pilotage "To" location, ' +
                'and must be of type Tide_UKC or Tide_UKC_Dyna.'
      }
    }

    const [departureTime, departureAccuracy] = getDepartureTime({
      pilotage,
      departingPilotage,
      timeAtLastConstraint: berthingRiskAssessment.timeAtConstraint
    })

    const { tideStationUuid } = berthingRiskAssessment
    const arrivalTime = new Date(berthingRiskAssessment.timeAtConstraint)

    const [minTideSource, minTideRaw]: [string, number?] = getMinTide(
      arrivalTime,
      departureTime,
      tides,
      tideExtremes,
      tideStationUuid
    )

    if (minTideSource === 'failed' || minTideRaw === undefined) {
      return { type: 'failed', error: 'Insufficient tide data from arrival at berth until departure.', }
    }

    // tide correction factor is used, but not allowances or residual, rissaga etc
    const { routeConstraint } = berthingRiskAssessment
    const constraint = mapLegacySafetyMargin(routeConstraint.constraint)
    const minTide = typeof constraint.correctionFactor === 'number'
      ? constraint.correctionFactor * minTideRaw
      : minTideRaw

    // min available depth may have been overridden in a routeCheck
    const berthingRouteCheck = routeChecks.find(routeCheck =>
      routeCheck.routeConstraint && routeCheck.routeConstraint.uuid === routeConstraint.uuid
    )

    // use override or base value
    const berthingValueOverride = berthingRouteCheck && berthingRouteCheck.variables && berthingRouteCheck.variables.value
    const berthDepth =
            !isNaN(berthingValueOverride) ? Number(berthingValueOverride)
              : !isNaN(constraint.value) ? Number(constraint.value)
                : 0

    const { calculatedSafetyMargin: calculatedSafetyMarginRaw } = calculateSafetyMargin(constraint, maxDraft)
    const safetyMarginOverride = berthingRouteCheck && berthingRouteCheck.variables && berthingRouteCheck.variables.safetyMargin

    const calculatedSafetyMargin =
    !isNaN(safetyMarginOverride) ? Number(safetyMarginOverride)
      : !isNaN(calculatedSafetyMarginRaw) ? Number(calculatedSafetyMarginRaw)
        : 0

    const maxAllowableDraft = (berthDepth + minTide) - calculatedSafetyMargin
    const passed = maxAllowableDraft >= maxDraft

    return {
      type: 'success',
      result: {
        constraint,
        arrivalTime,
        departureTime,
        departureAccuracy,
        minTide,
        minTideSource,
        maxAllowableDraft,
        passed,
        berthDepth,
        calculatedSafetyMargin,
      }
    }
  }, [
    pilotage,
    departingPilotage,
    timeZone,
    riskAssessment,
    tides,
    tideExtremes
  ])
}
