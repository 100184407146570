import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { addSignature } from 'src/utils/api/pilotages'

export const sync = async syncItem =>
  new Promise(async (resolve, reject) => {
    const { entity } = syncItem
    const { uuid, ...entityWithoutUuid } = entity
    const { pilotage, signature } = entityWithoutUuid
    try {
      const result = await addSignature(
        pilotage.uuid,
        signature,
      )
      resolve(getSinglePayloadFromResponse(result))
    } catch (error) {
      console.warn(error)
      reject(error)
    }
  })

export const createSyncUuid = (pilotageUuid, signatureRole) =>
  `${pilotageUuid}-${signatureRole}-${SYNC_ENTITY_TYPES.PILOTAGE_SIGNATURES}`

export default sync
