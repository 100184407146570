import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getTideRateData } from 'src/utils/api/tideRates'
import { isOnlineSelector } from '../ui/selectors'

const TIDE_RATES_BASE = 'TIDE_RATES_BASE'

export const [
  TIDE_RATES_REQUEST,
  TIDE_RATES_SUCCESS,
  TIDE_RATES_ERROR,
  TIDE_RATES_IN_PROGRESS,
] = createBasicActionTypes(TIDE_RATES_BASE)

export const tideRatesRequest = (tideRateStationUuid, fromDate, toDate, opts) => async (
  dispatch,
  getState
) => {
  if (!isOnlineSelector(getState())) {
    return false
  }

  dispatch({
    type: TIDE_RATES_REQUEST,
    payload: { tideRateStationUuid },
  })
  // TODO: temporary workaround to stop UI flicker for constraints - remove when better solution found
  if (!opts || !opts.isPrefetch) {
    dispatch(tideRatesInProgress(tideRateStationUuid))
  }

  try {
    const response = await getTideRateData(
      tideRateStationUuid,
      fromDate,
      toDate
    )
    const payload = getPayloadFromResponse(response)
    const dataset = [].concat(...payload.map(item => item.metadata))

    const data = {}
    dataset.forEach(item => {
      const dateTime = new Date(item.dateTime).valueOf()
      data[dateTime] = item
    })


    dispatch(tideRatesLoaded({ data, tideRateStationUuid }))
    return data
  } catch (error) {
    dispatch(tideRatesError({ error, tideRateStationUuid }))
    return false
  }
}

export const tideRatesInProgress = tideRateStationUuid =>
  createInProgressAction(TIDE_RATES_BASE, { tideRateStationUuid })

export const tideRatesError = data => dispatch => {
  dispatch(createErrorAction(TIDE_RATES_BASE, data))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.TIDE_RATES_ERROR,
    })
  )
}

export const tideRatesLoaded = data => createSuccessAction(TIDE_RATES_BASE, data)
