// This component is added because Formik isn't providing a way to subscribe
// to changes on the form overall yet

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'fast-deep-equal'

const FormikObserver = ({ values, onChange }) => {
  const lastValue = useRef(values)
  useEffect(() => {
    if (!isEqual(values, lastValue.current)) {
      onChange(values)
      lastValue.current = values
    }
  })
  return null
}

FormikObserver.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
}

export default React.memo(FormikObserver)
