import { v4 as uuidv4 } from 'uuid'
import idx from 'idx'

import { createBasicActionTypes } from 'src/utils/createAction'
import {
  createDrawing,
  getDrawings,
  updateDrawing,
} from 'src/utils/api/drawing'
import {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { createSyncUuid } from 'src/utils/sync/pilotageDrawings'
import { addEntity } from 'src/store/sync/actions'
import { SYNC_ENTITY_TYPES, PILOTAGE_STAGE_ID } from 'src/utils/constants'
import { entitiesToSyncSelector } from 'src/store/sync/selectors'
import { drawingsListSelector } from 'src/store/drawings/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { prefetchDrawings } from 'src/store/prefetch/actions'
import { pilotageTugsSelector } from 'src/store/pilotageTugs/selectors'

const DRAWINGS_BASE = 'DRAWINGS'

export const [
  DRAWINGS_REQUEST,
  DRAWINGS_SUCCESS,
  DRAWINGS_ERROR,
  DRAWINGS_CANCEL,
  DRAWINGS_IN_PROGRESS,
] = createBasicActionTypes(DRAWINGS_BASE)

export const DRAWING_UPDATE_SUCCESS = 'DRAWING_UPDATE_SUCCESS'

export const drawingUpdateSuccess = payload => dispatch => {
  dispatch({
    type: DRAWING_UPDATE_SUCCESS,
    payload,
  })
  dispatch(updateDrawingsCache())
}

export const DRAWINGS_RESET = 'DRAWINGS_RESET'

export const drawingsReset = () => ({ type: DRAWINGS_RESET })

export const drawingsRequestInProgress = () => ({ type: DRAWINGS_IN_PROGRESS })

export const drawingsRequestSuccess = payload => ({
  type: DRAWINGS_SUCCESS,
  payload,
})

export const drawingsRequestError = payload => ({
  type: DRAWINGS_ERROR,
  payload,
})

export const loadDrawings = (
  pilotageId,
  { isPrefetch } = {}
) => async dispatch => {
  try {
    if (!isPrefetch) {
      dispatch(drawingsReset())
      dispatch(drawingsRequestInProgress())
    }
    const response = await getDrawings(pilotageId)
    const data = getPayloadFromResponse(response)
    if (!isPrefetch) {
      dispatch(drawingsRequestSuccess(data))
    }
    return true
  } catch (e) {
    if (!isPrefetch) {
      dispatch(drawingsRequestError(e))
    }
    return false
  }
}

export const createDrawingRequest = (pilotageId, stageId, metadata) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState()
    const isOnline = isOnlineSelector(state)
    if (isOnline) {
      dispatch(drawingsRequestInProgress())
      const response = await createDrawing(pilotageId, stageId, metadata)
      const payload = getSinglePayloadFromResponse(response)
      dispatch(drawingUpdateSuccess(payload))
      dispatch(
        addSuccessToast({
          message: TOAST_MESSAGES.SAVE_DRAWING_SUCCESS,
        })
      )
      // prefetch the drawings to re-cache the new result
      // only if we have to update the cache (second argument)
      dispatch(prefetchDrawings(pilotageId, true))
    } else {
      const uuid = uuidv4()
      const syncEntityUuid = createSyncUuid(pilotageId, stageId)
      const isFirst = stageId === PILOTAGE_STAGE_ID.DEPARTURE_BERTHING
      const isLast = stageId === PILOTAGE_STAGE_ID.ARRIVAL_BERTHING
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS,
          pilotageUuid: pilotageId,
          isNew: true,
          entity: {
            isFirst,
            isLast,
            uuid,
            metadata,
            stage: isFirst || isLast ? null : { uuid: stageId },
            pilotage: { uuid: pilotageId },
          },
        })
      )
    }
    return true
  } catch (e) {
    dispatch(drawingsRequestError(e))
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.SAVE_DRAWING_ERROR,
      })
    )
    return false
  }
}

export const updateDrawingRequest = (drawingId, metadata) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState()
    const isOnline = isOnlineSelector(state)
    const syncEntities = entitiesToSyncSelector(state)
    if (isOnline) {
      dispatch(drawingsRequestInProgress())
      const response = await updateDrawing(drawingId, metadata)
      const payload = getSinglePayloadFromResponse(response)
      dispatch(drawingUpdateSuccess(payload))
      dispatch(
        addSuccessToast({
          message: TOAST_MESSAGES.SAVE_DRAWING_SUCCESS,
        })
      )
      const currentPilotageTugsFromStore = pilotageTugsSelector(state)
      const pilotageUuid =
        currentPilotageTugsFromStore &&
        currentPilotageTugsFromStore.pilotageUuid
      if (pilotageUuid) {
        // prefetch the drawings to re-cache the new result
        // only if we have to update the cache (second argument)
        dispatch(prefetchDrawings(pilotageUuid, true))
      }
    } else {
      const drawings = drawingsListSelector(state)
      let drawing = (drawings || []).find(
        ({ uuid, isFirst, isLast }) =>
          uuid === drawingId ||
          (drawingId === PILOTAGE_STAGE_ID.DEPARTURE_BERTHING && isFirst) ||
          (drawingId === PILOTAGE_STAGE_ID.ARRIVAL_BERTHING && isLast)
      )
      const pilotageUuid = idx(drawing, _ => _.pilotage.uuid)
      const stageUuid =
        idx(drawing, _ => _.stage.uuid) ||
        (drawing.isFirst
          ? PILOTAGE_STAGE_ID.DEPARTURE_BERTHING
          : drawing.isLast
          ? PILOTAGE_STAGE_ID.ARRIVAL_BERTHING
          : '')
      const syncEntityUuid = createSyncUuid(pilotageUuid, stageUuid)
      const { entity = {}, ...syncItem } = syncEntities[syncEntityUuid] || {}
      await dispatch(
        addEntity({
          ...syncItem,
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_DRAWINGS,
          pilotageUuid,
          entity: {
            uuid: drawingId,
            ...drawing,
            ...entity,
            metadata,
            stage: { uuid: stageUuid },
            pilotage: { uuid: pilotageUuid },
          },
        })
      )
    }
    return true
  } catch (e) {
    dispatch(drawingsRequestError(e))
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.SAVE_DRAWING_ERROR,
      })
    )
    return false
  }
}

export const updateDrawingsCache = () => (dispatch, getState) => {
  dispatch(
    loadDrawings(pilotageUuidSelector(getState()), {
      isPrefetch: true,
    })
  )
}
