import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useOmcDukc from 'src/hooks/useOmcDukc'
import { GetPlanQuery } from 'src/utils/api/omc'
import {
  UkcGraph,
  UkcProfileGraphOptions,
  SpeedProfileGraphOptions,
  UkcTable,
} from 'ukcgraph'
import RefreshIcon from '@material-ui/icons/Refresh'
import { LinkIcon } from 'src/components/atoms/Icons'
import styled from 'styled-components'
import { Box, Collapse, CircularProgress } from '@material-ui/core'
import InfoBoxBase from 'src/components/atoms/InfoBox'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import dukcIcon from 'src/assets/images/dukc-logo-horizontal-small-light.png'
import { isOnlineSelector } from 'src/store/ui/selectors'
import OmcDistanceTimeSelector from 'src/components/atoms/OmcDistanceTimeSelector/OmcDistanceTimeSelector'
import OmcDukcPlanVariantSelector from 'src/components/molecules/OmcDukcPlanVariantSelector'
import { friendlyTime, labelTime } from 'src/utils/date'
import './omcDukc.css'
import { CollapseButton, CollapseButtonOpen } from '../Common'

const ukcProfileGraphOptions: UkcProfileGraphOptions = {
  gridColor: 'rgba(255, 255, 255, 0.6)',
  mmBreachColor: 'rgba(255, 157, 0, 0.41)',
  bcBreachColor: 'rgba(255,0,0,.6)',
  abscissa: 'DISTANCE',
  staticDraftColor: '#999',
  dynamicMotionsColor: '#666',
  waterUnderKeelColor: 'rgba(66, 192, 255, 1)',
  limitLineColor: 'rgba(255, 255, 255, 0.6)',
  limitHighlightColor: 'lightblue',
  minDepthColor: 'black',
  datumColor: 'rgba(255, 255, 255, 0.6)',
  waterLevelColor: 'rgba(75, 111, 180, 1)',
  dynamicKeelColor: 'black'
}

const speedProfileGraphOptions: SpeedProfileGraphOptions = {
  gridColor: 'rgba(255, 255, 255, 0.6)',
  gridBorderColor: 'rgba(255, 255, 255, 0.6)',
  bcBreachColor: 'rgba(255,0,0,.6)',
  forecastSTWColor: 'red',
  forecastSOGColor: 'lightgreen',
  mmBreachColor: 'rgba(255, 157, 0, 0.41)'
}

const OmcWrapper = styled.div`
    & p {
        &:not(:last-child) {
            margin-bottom: ${({ theme }) => theme.spacing(3)}px;
        }
    }
    & a {
        color: ${({ theme }) => theme.palette.primary.main};
    }
    .omc-header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
    .omc-header img {
        width: 130px;
        display: block;
    }
    .omc-subheader {
        font-size: 14px;
        color: #95A6B6;
        padding-top: 5px;
        text-transform: capitalize;
    }
    .omc-controls {
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .omc-freshness {
        flex: 1;
        padding: 0px 20px;
        font-size: 12px;
    }
    .omc-disclaimer {
        font-style: italic;
        color: #667890;
        font-size: 12px;
    }
`

const InfoBox = styled(InfoBoxBase)`
    && {
        padding: 30px 30px 20px 30px;
    }

    && > div {
        width: 100%;
    }
`

const OMC_VIEW_GRAPH = 'graph'
const OMC_VIEW_TABLE = 'table'

const R = <sub style={{ fontSize: 8, bottom: '0.75em' }}>®</sub>

type OmcDukcProps = GetPlanQuery & {
  timeZone: string
  onPlanVariantChange?: (planId: number | string) => void
  pilot: any
  pilotSecond: any
  user: any
}

const OmcDukc: React.FC<OmcDukcProps> = ({
  vesselIMO,
  pilotageDate,
  movementType,
  timeZone,
  portUuid,
  pilot,
  pilotSecond,
  user,
  onPlanVariantChange
}) => {

  const [isDistance, setIsDistance] = useState(true)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [mode, setMode] = useState <'graph' | 'table'>(OMC_VIEW_GRAPH)
  const { getPlan, getPdf, getLoading, fetch, error, planVariants, planVariantActive } = useOmcDukc()
  const isOnline = useSelector(isOnlineSelector)

  // Only show user any changes if user is either primary/secondary assignee
  const isCurrentUserPilot = !!pilot && !!user && pilot.uuid === user.uuid

  const isCurrentUserPilotSecond =
    !!pilotSecond && !!user && pilotSecond.uuid === user.uuid

  const isCurrentUserEitherAssignee =
    isCurrentUserPilot || isCurrentUserPilotSecond

  const toggleMode = () => {
    if (mode === OMC_VIEW_GRAPH) {
      setMode(OMC_VIEW_TABLE)
    } else {
      setMode(OMC_VIEW_GRAPH)
    }
  }

  const fetchPlan = useCallback(() => {
    fetch({ vesselIMO, pilotageDate, movementType, portUuid })
  }, [vesselIMO, pilotageDate, movementType, portUuid])

  const loading = getLoading()

  useEffect(
    fetchPlan,
    [fetchPlan]
  )

  const plan = getPlan()

  if (!plan || !plan.passagePlan || !plan.passagePlan.ukc) { return null }

  return (
    <OmcWrapper>
      <InfoBox mt={2} mb={2}>
        <div className="omc-header" onClick={() => setIsCollapsed(!isCollapsed)}>
          <div>
            <img src={dukcIcon} alt="OMC DUKC" />
            <div className="omc-subheader">{
              `UKC ${mode}${plan.calculationInputs &&
              `: ${plan.calculationInputs.routeName} (${plan.status})`
              }`}
            </div>
          </div>
          {!isCollapsed ? <CollapseButton /> : <CollapseButtonOpen />}
        </div>

        <Collapse in={isCollapsed}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {
              planVariants.length > 1 &&
              onPlanVariantChange &&
              <OmcDukcPlanVariantSelector
                readOnly={!isCurrentUserEitherAssignee}
                active={planVariantActive}
                timeZone={timeZone}
                options={planVariants}
                onChange={onPlanVariantChange}
              />
            }
            {
              mode === OMC_VIEW_TABLE && plan.transitTable &&
              <Box mt={1}>
                <UkcTable plan={plan}></UkcTable>
              </Box>
            }
            {
              mode === OMC_VIEW_GRAPH &&
              <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <OmcDistanceTimeSelector
                  value={isDistance}
                  onChange={(value) => setIsDistance(value)}
                />
              </Box>
            }
            {
              mode === OMC_VIEW_GRAPH &&
              <UkcGraph
                ukcProfileGraphOptions={ukcProfileGraphOptions}
                speedProfileGraphOptions={speedProfileGraphOptions}
                plan={plan}
                speedGraphHeight={250}
                ukcGraphHeight={350}
                waypointDateTimeFormat={(dateTimeUtc?: string | number | Date) => labelTime(dateTimeUtc, timeZone)}
                generatedDateTimeFormat={(dateTimeUtc?: string | number | Date) => labelTime(dateTimeUtc, timeZone)}
                abscissa={isDistance ? 'DISTANCE' : 'TIME'}
              />
            }

            <div className="omc-controls">
              <SecondaryButton style={{ marginRight: 10 }} onClick={toggleMode}>
                <span>{mode === OMC_VIEW_GRAPH ? OMC_VIEW_TABLE : OMC_VIEW_GRAPH} View</span>
              </SecondaryButton>
              {
                isOnline &&
                <SecondaryButton
                  onClick={fetchPlan}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
                </SecondaryButton>
              }

              <div className="omc-freshness">
                {
                  plan.generationTime &&
                  <div>{friendlyTime('DUKC® calculation time', plan.generationTime, timeZone)}</div>
                }
                {
                  plan.lastFetched &&
                  <div>{friendlyTime('Last fetched', plan.lastFetched, timeZone)}</div>
                }
                {
                  error && isOnline &&
                  <div>Failed to fetch: {error.message}</div>
                }
                {
                  !isOnline &&
                  <span style={{ color: 'rgb(206 112 97)' }}>You are offline, we are unable to fetch the latest DUKC{R} data. The last fetched information is displayed.</span>
                }
              </div>

              <SecondaryButton onClick={getPdf} disabled={!plan}>
                <span style={{ paddingRight: 10 }}>Full DUKC{R} report</span> <LinkIcon />
              </SecondaryButton>
            </div>

            <p className="omc-disclaimer">
              This data has been provided as part of the DUKC{R} system and is intended to be used as an aid to
                            navigation for users both authorised and trained. Full information and limitations on the appropriate
                            usage of the data may be found in the DUKC{R} system documentation.
                            Use of the DUKC{R} system or this data does not relieve the master from responsibility for
                            the conduct and navigation of the vessel.
              <a href="https://www.dukc.com/Security/ConditionsOfUse" target="_blank"> Click here </a> to view the full disclaimer.
            </p>
          </div>
        </Collapse>
      </InfoBox>
    </OmcWrapper>
  )
}

export default OmcDukc
