import React from 'react'
import { Line, Path, Arrow } from 'react-konva'
import PropTypes from 'prop-types'

import findLineCircleIntersection from 'src/utils/findLineCircleIntersection'
import { findClosestPoint } from 'src/utils/findClosestPoint'
import { CHART_BASE_ARROW_HEAD_SIZE } from 'src/utils/drawingConstants'
import { getScaledArrowHeadSize } from 'src/utils/drawingHelpers'

// This is copied from:
// https://stackoverflow.com/questions/24376951/find-new-coordinates-of-point-on-line-in-javascript
// to get a shorter line.
const getPtsForLine = (pts) => {
  const [a1, b1] = pts[0]
  const [a2, b2] = pts[1]
  const xlen = a2 - a1
  const ylen = b2 - b1
  const hlen = Math.sqrt(Math.pow(xlen, 2) + Math.pow(ylen, 2))
  const smallerLen = hlen - 3 // 3 is the number of pixels shorter of the new line
  const ratio = smallerLen / hlen
  const smallerXLen = xlen * ratio
  const smallerYLen = ylen * ratio
  const smallerX = a1 + smallerXLen
  const smallerY = b1 + smallerYLen
  const ptsStart = pts[0]
  const ptsEnd = [smallerX, smallerY]
  const ptsLine = [ ptsStart, ptsEnd ]
  return ptsLine
}

const ChartArrow = ({
  name,
  color,
  version,
  points,
  chartDimensions,
  offset,
}) => {
  const pts = points.map(pt => [
    pt[0] * chartDimensions[0] + (offset ? offset[0] : 0),
    pt[1] * chartDimensions[1] + (offset ? offset[1] : 0),
  ])

  const ptsLine = getPtsForLine(pts)

  // Version 1 has arrowhead scaling and uses konva rather than custom arrow
  if (version === 1) {
    const arrowHeadSize = getScaledArrowHeadSize(chartDimensions[0])
    // EMPX-478: .getStage().getIntersection() in useCanvas is unable to detect the arrow or path shapes
    // returned. As a workaround, we set add a Line shape which can be detected, together with the arrow
    // shape, with the stokeWidth 1 pixel wider. To investigate why intersection is not working, we
    // need to dig deeper into the konva library.
    return (
      <>
        <Arrow
          points={[].concat.apply([], pts)}
          fill={color}
          stroke={color}
          strokeWidth={1}
          pointerLength={arrowHeadSize}
          pointerWidth={arrowHeadSize}
        />
        <Line
          key={name}
          name={name}
          stroke={color} // set to #FF0000 to debug line
          strokeWidth={2}
          lineCap="round"
          points={[].concat.apply([], ptsLine)}
        />
      </>
    )
  }

  const [x1, y1] = pts[0]
  const [x2, y2] = pts[1]
  // Arrow created using a path - consisting of 5 points:
  // S (start), E (end), B (arrowhead base), L (left side point of arrowhead),
  // R (right side point of arrowhead)
  //
  //                     L
  //                     |
  // S-------------------B-------E
  //                     |
  //                     R
  //
  // To calculate the base point B, we can just find the intersection between the
  // main line S-B-E and a circle centered at the arrow tip (E) which has radius
  // extending out to the base B.
  //
  // Then to get the coordinates of L and R, this is simple if the main line is
  // horizontal or vertical, but if not we just need to find the intersections
  // between the perpendicular line L-B-R and a circle centered at B with radius
  // extending out to L. We can reuse our functions for line-circle intersections
  // to get the points we need.

  let m // main line gradient
  let arrowBasePt

  if (x1 === x2) {
    // Arrow line is vertical, can find the arrow base by just offsetting the
    // y coordinate of the tip by the arrow size.
    m = Number.POSITIVE_INFINITY
    arrowBasePt = [x1, y2 + CHART_BASE_ARROW_HEAD_SIZE * (y2 > y1 ? -1 : 1)]
  } else {
    // We can find the arrow base by calculating the formula for the line S-B-E
    // and the calculating the intersection with a circle centered at E of
    // radius `arrowSize`.
    m = (y2 - y1) / (x2 - x1)
    const c = y2 - m * x2
    const intersections = findLineCircleIntersection(
      m,
      c,
      x2,
      y2,
      CHART_BASE_ARROW_HEAD_SIZE
    )
    arrowBasePt = findClosestPoint([x1, y1], intersections)
  }

  let arrowSidePts

  if (m === 0) {
    // Arrow is horizontal. Just add/subtract half arrow size from the y
    // coordinate of the base point to get the 2 side points.
    arrowSidePts = [
      [arrowBasePt[0], arrowBasePt[1] - CHART_BASE_ARROW_HEAD_SIZE / 2],
      [arrowBasePt[0], arrowBasePt[1] + CHART_BASE_ARROW_HEAD_SIZE / 2],
    ]
  } else if (m === Number.POSITIVE_INFINITY) {
    // Arrow is vertical. Just add/subtract half arrow size from the x
    // coordinate of the base point to get the 2 side points.
    arrowSidePts = [
      [arrowBasePt[0] - CHART_BASE_ARROW_HEAD_SIZE / 2, arrowBasePt[1]],
      [arrowBasePt[0] + CHART_BASE_ARROW_HEAD_SIZE / 2, arrowBasePt[1]],
    ]
  } else {
    // Find the formula for the line L-B-R and then use the line/circle
    // intersections to find the side points coordinates. The perpendicular
    // line is defined by y = (-1/m) * x + b:
    const b = arrowBasePt[0] / m + arrowBasePt[1]

    arrowSidePts = findLineCircleIntersection(
      -1 / m,
      b,
      arrowBasePt[0],
      arrowBasePt[1],
      CHART_BASE_ARROW_HEAD_SIZE / 2
    )
  }

  // Define SVG-like path data for the arrow
  const data = `M${pts[0]} L${arrowBasePt} L${arrowSidePts[0]} L${pts[1]} L${
    arrowSidePts[1]
  } L${arrowBasePt}z`

  // EMPX-478: .getStage().getIntersection() in useCanvas is unable to detect the arrow or path shapes
  // returned. As a workaround, we set add a Line shape which can be detected, together with the arrow
  // shape, with the stokeWidth 1 pixel wider. To investigate why intersection is not working, we
  // need to dig deeper into the konva library.
  return (
    <>
      <Path
        name={name}
        data={data}
        stroke={color}
        strokeWidth={1}
        lineCap="round"
        fill={color}
      />
      <Line
        key={name}
        name={name}
        stroke={color}
        strokeWidth={2}
        lineCap="round"
        points={[].concat.apply([], ptsLine)}
      />
    </>
  )
}

ChartArrow.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  version: PropTypes.number,
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  chartDimensions: PropTypes.arrayOf(PropTypes.number),
  offset: PropTypes.arrayOf(PropTypes.number),
}

export default React.memo(ChartArrow)
