import React from 'react'
import styled from 'styled-components'

import OfflineIcon from 'src/components/atoms/OfflineIcon'
import CircularProgress from '@material-ui/core/CircularProgress'

const PrefetchInProgressIconWrapper = styled.div`
  display: flex;
  position: relative;
  > * {
    display: inline-block;
  }
`
const ProgressWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-left: -2px;
  margin-top: -2px;
  position: absolute;
  .MuiCircularProgress-circleIndeterminate {
    stroke: ${({ theme }) => theme.palette.disabled.light};
    stroke-width: 2;
  }
`

export const PrefetchInProgressIcon = React.forwardRef(
  function PrefetchInProgressIcon({ ...props }, ref) {
    return (
      <PrefetchInProgressIconWrapper {...props} ref={ref}>
        <OfflineIcon fontSize="small" />
        <ProgressWrapper>
          <CircularProgress size={24} />
        </ProgressWrapper>
      </PrefetchInProgressIconWrapper>
    )
  }
)

export default PrefetchInProgressIcon
