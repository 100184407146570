import React, { useState, useCallback, useLayoutEffect } from 'react'
import useWindowSize from 'src/hooks/useWindowResize'

// Place inside a parent element to measure its width

interface RulerProps {
    onChange(width: number): void
}

export const Ruler: React.FC<RulerProps> = ({ onChange }) => {

    const { width } = useWindowSize()

    const [ruler, setRuler] = useState<null | HTMLElement>(null)

    const ref = useCallback((node: null | HTMLElement) => {
        if (node || !ruler) {
            setRuler(node)
        }
    }, [setRuler, ruler])

    useLayoutEffect(() => {
        if (ruler !== null) {
            onChange(ruler.offsetWidth)
        }
    }, [width, onChange, ruler])

    return <div ref={ref} style={{height: 0}}></div>
}

export default Ruler
