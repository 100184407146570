import React, { Fragment, useState, useMemo } from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loader from 'src/components/atoms/Loader'
import ErrorMessage from 'src/components/atoms/ErrorMessage'
import InfoMessage from 'src/components/atoms/InfoMessage/InfoMessage'
import { TideExtreme } from 'src/components/molecules/TideExtremes/types'
import { TideStation } from 'src/types/Tides'
import { getTideExtremesTimeline } from 'src/utils/getTideExtremesTimeline'
import { TideExtremesTimeline } from 'src/components/molecules/TideExtremes/TideExtremesTimeline'
import {
  TimelineItemType,
} from 'src/components/molecules/TideExtremes/types'
import { numberOrMissing } from 'src/utils/formatters'
import Pagination from '@material-ui/lab/Pagination'

const TideExtremeOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TideExtremeContainer = styled.div`
  flex: 1;
  font-size: 15px;
  line-height: 13px;
  table {
    width: 100%;
  }
  h4 {
    justify-content: space-between;
  }
`

const TideExtremeContainerSmall = styled.div`
  float: left;
  box-sizing: border-box;
  width: ${(100 / 7) * 1.5}%;
  font-size: 11px;
  line-height: 9px;
  color: #464646;
  margin-bottom: 5px;
  padding-left: 10px;
  h4 {
    line-height: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #95A6B6;
    padding-bottom: 2px;
    margin-bottom: 3px;
  }
  table, h4 {
   width: 100%;
   margin-right: 10px;
  }
  table {
    line-height: 8px;
  }
  .tide-range {
    font-weight: normal;
    font-size: 9px;
    white-space: nowrap;
    padding-left: 5px;
  }
`

const PaginationContainer = styled.div`
  padding: 10px 0px;
  display: flex;
  justify-content: center;
`

const PAGE_SIZE = 2

interface TideExtremesProps {
  tideExtremes: {
    [key: string]: {
      data: TideExtreme[]
      isLoading: boolean
      error: null | Error
    }
  }
  timeZone: string
  pilotageStartTime: string
  tideStations: TideStation[]
  isMasterView?: boolean // master has no pagination and some style overrides
}

const TideExtremes: React.FC<TideExtremesProps> = ({
  tideExtremes,
  tideStations,
  pilotageStartTime,
  timeZone,
  isMasterView
}) => {

  const timelines = getTideExtremesTimeline(
    tideExtremes,
    tideStations,
    pilotageStartTime,
    timeZone
  )

  const [page, setPage] = useState(1)

  const timelinesPage = useMemo(() => {

    if (isMasterView) { return timelines }

    const pageStartIndex = (page - 1 || 0) * PAGE_SIZE
    return timelines.slice(pageStartIndex, pageStartIndex + PAGE_SIZE)

  }, [timelines, page, isMasterView])

  const OuterContainer = isMasterView ? Fragment : TideExtremeOuterContainer
  const Container = isMasterView ? TideExtremeContainerSmall : TideExtremeContainer

  const anyIsLoading = timelinesPage.find(({ isLoading }) => isLoading)

  return (
    <OuterContainer>
      {tideStations.length === 0 ? (
        <InfoMessage>No tide data available</InfoMessage>
      ) : (
        <>
          {
          !isMasterView && 
          <Typography variant="h3" style={{paddingBottom: 15}}>High and Low Tides</Typography>
          }

          {
          anyIsLoading ? (
            <Loader>Loading tide extremes</Loader>
          ) :
          
          timelinesPage.map(({ timeline, tideStation, isLoading, error }, index) => {
            
            const startTimeIndex = timeline.findIndex(
              ({ type }) => type === TimelineItemType.PilotageStart
            )
          
            let range: number | undefined
            if (startTimeIndex > 0 && startTimeIndex + 1 < timeline.length) {
              // We can only calculate the range if the timeline has item(s) before and after
              // the start time item
              range = Math.abs(
                timeline[startTimeIndex - 1].tide! - timeline[startTimeIndex + 1].tide!
              )
            }
          
            return (
              <Container key={tideStation.uuid} style={isMasterView && index === 4 ? { clear: 'left' } : {}}>
                {
                  <Grid item>
                    <Typography variant="h4" style={{fontSize: isMasterView ? 11 : 15, display: 'flex', paddingRight: 5}}>
                      <span>{tideStation.name}</span> 
                      {
                        range ? 
                        <span className="tide-range">
                          {isMasterView ? <>Range<br/></> : <>Range: </>}
                          {numberOrMissing(range, 'm', 2)}
                        </span> :
                        <span className="tide-range"><br/><br/></span>
                      }
                    </Typography>

                    {error && (
                      <ErrorMessage>Error loading high/low tide data</ErrorMessage>
                    )}
                    {!isLoading && timeline.length === 0 && (
                      <InfoMessage>
                        <div style={{padding: 10}}>
                          No high/low tide data available for this pilotage start time
                        </div>
                      </InfoMessage>
                    )}
                    {timeline.length > 0 && (
                      <TideExtremesTimeline 
                        timeline={timeline} 
                        isMasterView={!!isMasterView} 
                      />
                    )}
                  </Grid>
                }
                
              </Container>
            )})}

            {
              !isMasterView && timelines.length > PAGE_SIZE &&
              <PaginationContainer>              
                <Pagination
                  size="small"
                  count={Math.ceil(timelines.length / PAGE_SIZE)}
                  page={page}
                  onChange={(event, nextPage) => setPage(nextPage)}
                  siblingCount={0}
                />
             </PaginationContainer>
            }

        </>
      )}
      <div style={{clear: 'left'}}></div>
    </OuterContainer>
  )
}

export default React.memo(TideExtremes)
