import {
  PILOTS_IN_PROGRESS,
  PILOTS_ERROR,
  PILOTS_SUCCESS,
} from 'src/store/pilots/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function pilotsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case PILOTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PILOTS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case PILOTS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default pilotsReducer
