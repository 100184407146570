import styled from 'styled-components'

import PrimaryButton from 'src/components/atoms/PrimaryButton'

const SubmitButton = styled(PrimaryButton).attrs({ type: 'submit' })`
  && {
    margin-top: ${({ theme }) => theme.spacing(4)}px;
    width: 100%;
  }
`

export default SubmitButton
