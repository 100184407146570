import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Divider,
  Modal as MaterialModal,
  Typography,
  Box,
} from '@material-ui/core'

import CloseButton from 'src/components/atoms/CloseButton'
import { maxWidth } from 'src/styles/GlobalStyles'

const ModalWrapper = styled.div`
  position: absolute;
  width: ${({ autoWidth }) => (autoWidth ? 'auto' : '70%')};
  max-width: ${({ autoWidth }) =>
    autoWidth ? 'auto' : `calc(0.7 * ${maxWidth}px)`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.background.light};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  border-radius: 10px;
  max-height: calc(100% - 60px);
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }

  @media (max-width: 900px) {
    width: ${({ autoWidth }) => (autoWidth ? 'auto' : '90%')};
    max-width: ${({ autoWidth }) =>
      autoWidth ? 'auto' : `calc(0.9 * ${maxWidth}px)`};
  }
`

const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  overflow: auto;
`

const ModalCloseButton = styled(CloseButton)`
  && {
    position: absolute;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1;
  }
`

const SubHeading = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.weights.fontWeightLight};
  font-size: 14px;
  padding-left: 20px;
`

const Modal = ({
  onClose = null,
  heading,
  subHeading = '',
  note = '',
  children,
  autoWidth = false,
  includeHeadingDivider = true,
  ...props
}) => {
  return (
    <MaterialModal onClose={onClose} {...props}>
      <ModalWrapper autoWidth={autoWidth}>
        <ModalCloseButton onClick={onClose} />
        <ModalContent>
          {heading && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h2">{heading}</Typography>
                {note && <SubHeading>{note}</SubHeading>}
              </Box>
              {subHeading && (
                <Box mt={1} mb={2}>
                  <Typography variant="body2">{subHeading}</Typography>
                </Box>
              )}
              {includeHeadingDivider && <Divider />}
            </>
          )}
          {children}
        </ModalContent>
      </ModalWrapper>
    </MaterialModal>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  note: PropTypes.string,
  autoWidth: PropTypes.bool,
  children: PropTypes.node,
  includeHeadingDivider: PropTypes.bool,
}

export default Modal
