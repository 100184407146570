import {
  CANVAS_DISPLAY_ADD_LABEL,
  CANVAS_REMOVE_ADD_LABEL,
  CANVAS_SET_UNSAVED_CHANGES,
} from 'src/store/canvas/actions'
import {
  CHART_COLOR_PALETTE,
  CHART_MENU_SECTION,
} from 'src/utils/drawingConstants'
import {
  CANVAS_SET_ACTIVE_COLOR,
  CANVAS_SET_ACTIVE_SYMBOL,
  CANVAS_SET_ACTIVE_TOOL,
  CANVAS_START_SELECT_MODE,
  CANVAS_TOGGLE_MENU,
} from 'src/store/canvasMenu/actions'

const INITIAL_STATE = {
  expandedMenu: CHART_MENU_SECTION.TOOL,
  activeColor: CHART_COLOR_PALETTE[0],
  activeTool: null,
  activeSymbol: null,
}

const canvasMenuReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CANVAS_SET_ACTIVE_COLOR:
      return {
        ...state,
        activeColor: payload,
      }
    case CANVAS_SET_ACTIVE_TOOL:
      return {
        ...state,
        activeTool: payload,
        activeSymbol: null,
        selectedShapeName: null,
      }
    case CANVAS_SET_ACTIVE_SYMBOL:
      return {
        ...state,
        activeSymbol: payload,
        activeTool: null,
        selectedShapeName: null,
      }
    case CANVAS_TOGGLE_MENU:
      return {
        ...state,
        expandedMenu: payload,
      }
    case CANVAS_START_SELECT_MODE:
      return {
        ...state,
        selectedShapeName: null,
        activeTool: null,
        activeSymbol: null,
      }
    case CANVAS_DISPLAY_ADD_LABEL:
      return {
        ...state,
        activeLabelName: payload.name || null,
        activeLabelValue: payload.text || '',
        activeLabelPosition: [payload.x, payload.y],
      }
    case CANVAS_REMOVE_ADD_LABEL:
      return {
        ...state,
        activeLabelPosition: null,
        activeLabelName: null,
        activeLabelValue: '',
      }
    case CANVAS_SET_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: payload,
      }
    default:
      return state
  }
}

export default canvasMenuReducer
