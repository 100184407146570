import React from 'react'

const ConstraintUkcIcon = () => (
  <svg width="43" height="43" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <path
        d="M11.555 26.667h16.571l-2.506 3.227a2 2 0 01-1.58.773h-8.4a2 2 0 01-1.579-.773l-2.506-3.227h0z"
        stroke="#92A6B8"
        fill="#92A6B8"
        strokeLinejoin="round"
      />
      <path
        d="M17.687 24h4.307a1 1 0 01.888.54l1.101 2.127h0-8.285l1.1-2.127a1 1 0 01.889-.54z"
        stroke="#95A6B6"
      />
      <path
        d="M38.76 30.012C37.443 29.302 37.057 28 35.645 28c-1.943 0-2.43 2.463-4.392 2.463-1.962 0-1.551-2.463-4.508-2.463M2.207 29.856C3.281 29.127 3.662 28 5.1 28c2.126 0 2.562 2.463 4.392 2.463C11.322 30.463 11.043 28 14 28"
        stroke="#92A6B8"
      />
      <path d="M14.317 27.667h11.047" stroke="#95A6B6" strokeLinecap="square" />
      <g stroke="#95A6B6" strokeLinecap="square">
        <path d="M19.84 18.333V21M18.805 17.667h2.071M18.805 21.667h2.071" />
      </g>
      <circle stroke="#95A6B6" cx="20.5" cy="20.5" r="20.5" />
      <path stroke="#92A6B8" d="M1 15h39v1H1z" />
    </g>
  </svg>
)

export default ConstraintUkcIcon
