import { v4 as uuidv4 } from 'uuid'

import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import {
  addPilotageTug,
  getPilotageTugs,
  removePilotageTug,
} from 'src/utils/api/tugs'
import { pilotageRequest } from 'src/store/pilotage/actions'
import { entityByUuidSelector } from 'src/store/sync/selectors'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { getTugByUuidSelector } from 'src/store/tugs/selectors'
import { pilotageTugsSelector } from 'src/store/pilotageTugs/selectors'
import { getPilotageByUuidSelector } from 'src/store/pilotageList/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { addEntity } from 'src/store/sync/actions'
import { createSyncUuid } from 'src/utils/sync/pilotageTugs'

import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { prefetchTugs } from 'src/store/prefetch/actions'
import flags from 'src/utils/flags'

const PILOTAGE_TUGS_BASE = 'PILOTAGE_TUGS'
const PILOTAGE_TUGS_ADD_BASE = 'PILOTAGE_TUGS_ADD'
const PILOTAGE_TUGS_REMOVE_BASE = 'PILOTAGE_TUGS_REMOVE'

export const [
  PILOTAGE_TUGS_REQUEST,
  PILOTAGE_TUGS_SUCCESS,
  PILOTAGE_TUGS_ERROR,
  PILOTAGE_TUGS_CANCEL,
  PILOTAGE_TUGS_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_TUGS_BASE)

export const [
  PILOTAGE_TUGS_ADD_REQUEST,
  PILOTAGE_TUGS_ADD_SUCCESS,
  PILOTAGE_TUGS_ADD_ERROR,
  PILOTAGE_TUGS_ADD_CANCEL,
  PILOTAGE_TUGS_ADD_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_TUGS_ADD_BASE)

export const [
  PILOTAGE_TUGS_REMOVE_REQUEST,
  PILOTAGE_TUGS_REMOVE_SUCCESS,
  PILOTAGE_TUGS_REMOVE_ERROR,
  PILOTAGE_TUGS_REMOVE_CANCEL,
  PILOTAGE_TUGS_REMOVE_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_TUGS_REMOVE_BASE)

export const pilotageTugsRequest = (uuid, opts) => async (
  dispatch,
  getState
) => {
  if (!opts || !opts.isPrefetch) {
    dispatch(pilotageTugsInProgress())
  }
  const state = getState()
  const isOnline = isOnlineSelector(state)
  try {
    let data = {}
    const syncEntityUuid = createSyncUuid(uuid)
    const inSyncPilotageTugs = entityByUuidSelector(syncEntityUuid)(state)
    // we load only if we are online
    // or there is no syncable (offline edited) data
    // if are offline and we don't have data in sync
    // we rely on the service workercache
    if (isOnline || !inSyncPilotageTugs) {
      const response = await getPilotageTugs(uuid)
      data = getPayloadFromResponse(response)
    } else if (inSyncPilotageTugs) {
      data = Object.values(inSyncPilotageTugs.entity.data || {})
    }
    if (!opts || !opts.isPrefetch) {
      dispatch(pilotageTugsLoaded(uuid, data))
    }
    return data
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(pilotageTugsError(error))
    }
    return false
  }
}

export const pilotageTugsInProgress = () =>
  createInProgressAction(PILOTAGE_TUGS_BASE)

export const pilotageTugsError = error => dispatch => {
  dispatch(createErrorAction(PILOTAGE_TUGS_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_PILOTAGE_TUGS_ERROR,
    })
  )
}

export const pilotageTugsLoaded = (uuid, data) =>
  createSuccessAction(PILOTAGE_TUGS_BASE, { pilotageUuid: uuid, data })

export const pilotageTugsAddRequest = (
  pilotageUuid,
  tugUuid,
  lineNumber
) => async (dispatch, getState) => {
  const state = getState()
  const isOnline = isOnlineSelector(getState())
  dispatch(pilotageTugsAddInProgress())

  try {
    let data
    if (isOnline) {
      const response = await addPilotageTug(pilotageUuid, tugUuid, lineNumber)
      data = getPayloadFromResponse(response)
      // prefetch the tugs to re-cache the new result
      // only if we have to update the cache (second argument)
      dispatch(prefetchTugs(pilotageUuid, true))
      if (flags.PLAN_STATUS_UPDATE_ENABLED) {
        // update causes a status update, so refetch the latest from the BE
        dispatch(pilotageRequest(pilotageUuid))
      }
    } else {
      const pilotage = getPilotageByUuidSelector(pilotageUuid)(state)
      const tug = getTugByUuidSelector(tugUuid)(state)
      const currentPilotageTugsFromStore = pilotageTugsSelector(state)
      const syncEntityUuid = createSyncUuid(pilotageUuid)
      const inSyncPilotageTugs = entityByUuidSelector(syncEntityUuid)(state)
      let currentData =
        currentPilotageTugsFromStore.pilotageUuid === pilotageUuid
          ? // the current data should be the most up to date
            // even in offline (see `pilotageTugsRequest` action)
            currentPilotageTugsFromStore.data
          : inSyncPilotageTugs // this should not happen, but to stay on the safe side, check the sync too
          ? inSyncPilotageTugs.data
          : {}
      data = {
        ...currentData,
        [lineNumber]: {
          uuid: uuidv4(),
          pilotage,
          tug,
          lineNumber,
        },
      }
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_TUGS,
          pilotageUuid,
          entity: {
            uuid: syncEntityUuid,
            pilotageUuid,
            data,
          },
        })
      )
    }
    dispatch(pilotageTugsAddLoaded(data))
    return true
  } catch (error) {
    console.warn(error)
    dispatch(pilotageTugsAddError(error))
    return false
  }
}

export const pilotageTugsAddInProgress = () =>
  createInProgressAction(PILOTAGE_TUGS_ADD_BASE)

export const pilotageTugsAddError = error => dispatch => {
  dispatch(createErrorAction(PILOTAGE_TUGS_ADD_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.ADD_PILOTAGE_TUG_ERROR,
    })
  )
}

export const pilotageTugsAddLoaded = data => dispatch => {
  dispatch(
    addSuccessToast({ message: TOAST_MESSAGES.ADD_PILOTAGE_TUG_SUCCESS })
  )
  dispatch(createSuccessAction(PILOTAGE_TUGS_ADD_BASE, data))
}

export const pilotageTugsRemoveRequest = pilotageTugUuid => async (
  dispatch,
  getState
) => {
  const state = getState()
  const isOnline = isOnlineSelector(getState())

  dispatch(pilotageTugsRemoveInProgress())

  try {
    const currentPilotageTugsFromStore = pilotageTugsSelector(state)
    const pilotageUuid =
      currentPilotageTugsFromStore && currentPilotageTugsFromStore.pilotageUuid
    if (isOnline) {
      await removePilotageTug(pilotageTugUuid)
      // prefetch the tugs to re-cache the new result
      // only if we have to update the cache (second argument)
      dispatch(prefetchTugs(pilotageUuid, true))
      if (flags.PLAN_STATUS_UPDATE_ENABLED) {
        // update causes a status update, so refetch the latest from the BE
        dispatch(pilotageRequest(pilotageUuid))
      }
    } else {
      const syncEntityUuid = createSyncUuid(pilotageUuid)
      let data = Object.values(currentPilotageTugsFromStore.data || {})
        .filter(item => item.uuid !== pilotageTugUuid)
        .reduce(
          (tugLines, tugLine) => ({
            ...tugLines,
            [tugLine.lineNumber]: tugLine,
          }),
          {}
        )

      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_TUGS,
          pilotageUuid,
          entity: {
            uuid: syncEntityUuid,
            pilotageUuid,
            data,
          },
        })
      )
    }
    dispatch(pilotageTugsRemoveLoaded())
    return true
  } catch (error) {
    dispatch(pilotageTugsRemoveError(error))
    return false
  }
}

export const pilotageTugsRemoveInProgress = () =>
  createInProgressAction(PILOTAGE_TUGS_REMOVE_BASE)

export const pilotageTugsRemoveError = error => dispatch => {
  dispatch(createErrorAction(PILOTAGE_TUGS_REMOVE_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.REMOVE_PILOTAGE_TUG_ERROR,
    })
  )
}

export const pilotageTugsRemoveLoaded = () => dispatch => {
  dispatch(
    addSuccessToast({ message: TOAST_MESSAGES.REMOVE_PILOTAGE_TUG_SUCCESS })
  )
  dispatch(createSuccessAction(PILOTAGE_TUGS_REMOVE_BASE))
}

export const updatePilotageTugsCache = () => (dispatch, getState) => {
  dispatch(
    pilotageTugsRequest(pilotageUuidSelector(getState()), {
      isPrefetch: true,
    })
  )
}
