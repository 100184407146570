import React from 'react'
import { useSelector } from 'react-redux'

import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  movementTypeSelector,
} from 'src/store/pilotage/selectors'
import { ChecklistComment } from 'src/components/molecules/ChecklistComment'
import { PilotagePassFailChecklistBase } from 'src/components/organisms/PilotagePassFailChecklist/PilotagePassFailChecklistBase'

export const PilotagePassFailChecklist: React.FC = () => {

  const movementType = useSelector(movementTypeSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled

  return (
    <PilotagePassFailChecklistBase
      readOnly={isReadOnly}
      CommentComponent={ChecklistComment}
      movementType={movementType}
    />
  )
}
