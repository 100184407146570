import {
  REFRESH_CHART_ERROR,
  REFRESH_CHART_SUCCESS,
  SET_CHARTS_LIST,
} from 'src/store/charts/actions'

const INITIAL_STATE = []

function chartsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case SET_CHARTS_LIST:
      return payload
    case REFRESH_CHART_SUCCESS:
    case REFRESH_CHART_ERROR:
      const existingItemIndex = state.findIndex(
        item => item.data.uuid === payload.data.uuid
      )
      return existingItemIndex === -1
        ? [...state, payload]
        : [
            ...state.slice(0, existingItemIndex),
            payload,
            ...state.slice(existingItemIndex + 1),
          ]
    default:
      return state
  }
}

export default chartsReducer
