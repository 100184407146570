import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TextInput from 'src/components/atoms/TextInput'
import { missingValueText } from 'src/utils/constants'
import { updatePilotageVesselDisplacementRequest } from 'src/store/pilotage/actions'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { acceptDecimalOnly } from 'src/utils/events'
import idx from 'idx'
import TOOLTIPS from './../../../utils/tooltips'
import Tooltip from './../../atoms/Tooltip/Tooltip'

const DisplacementBox = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(3)}px;
  background-color: ${({ theme }) => theme.palette.background.xlight};
  border-radius: 5px;
  position: relative;
`
const InvalidBorder = styled.div`
  border-top: 4px solid #ed1b23;
  position: absolute;
  margin-top: -4px;
  left: ${({ theme }) => theme.spacing(3) + 1}px;
  right: ${({ theme }) => theme.spacing(3) + 1}px;
`

const StyledTextInput = styled(TextInput)`
  && {
    margin: 0;
  }

  & label {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }

  & input {
    font-weight: ${({ theme }) => theme.weights.fontWeightSemiBold};
    letter-spacing: 1px;
    padding: 7px 15px;
  }
`
const DisplacementTooltip = styled.div`
  float: right;
`

const Displacement = ({ readOnly, ...props }) => {
  const dispatch = useDispatch()
  const { data: pilotageData } = useSelector(pilotageSelector)

  const [localValue, setLocalValue] = useState('')
  const [isValid, setIsValid] = useState(true)
  const savedValue = useRef(null)

  const format = value => {
    if (typeof value === 'number') {
      return value.toFixed(2)
    }
    if (!value) {
      return ''
    }
    return value && !isNaN(Number(value)) ? Number(value).toFixed(2) : value
  }

  useEffect(
    () => {
      savedValue.current = format(idx(pilotageData, d => d.vesselDisplacement))
      setLocalValue(savedValue.current)
    },
    [pilotageData]
  )

  const validate = v => !isNaN(Number(v)) && Number(v) >= 0

  const onChange = value => {
    setLocalValue(value)
    setIsValid(validate(value))
  }

  const onBlur = () => {
    if (!readOnly && validate(localValue)) {
      const v = format(localValue, 2)

      if (v !== savedValue.current) {
        dispatch(
          updatePilotageVesselDisplacementRequest(
            pilotageData.uuid,
            localValue === '' ? null : Number(format(localValue))
          )
        )
      }

      setLocalValue(v)
    }
  }

  return (
    <DisplacementBox>
      <DisplacementTooltip>
        <Tooltip content={TOOLTIPS.DISPLACEMENT} color="#FFF" />
      </DisplacementTooltip>

      <StyledTextInput
        label={<div style={{float: 'left'}}>Displacement</div>}
        id="displacement"
        placeholder={missingValueText}
        unit="t"
        value={localValue}
        onChange={onChange}
        onBlur={onBlur}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          onKeyDown: acceptDecimalOnly
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ inputMode: 'decimal' }}
        readOnly={readOnly}
        {...props}
      />

      {!isValid && <InvalidBorder />}
    </DisplacementBox>
  )
}

Displacement.propTypes = {
  readOnly: PropTypes.bool,
}

export default React.memo(Displacement)
