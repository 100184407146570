import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useOnlineStatus from 'src/hooks/useOnlineStatus'
import {
  requestRefreshSession,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
  setSessionStatus,
} from 'src/store/auth/actions'
import {
  sessionStatusSelector,
  authStatusSelector,
} from 'src/store/auth/selectors'
import { SESSION_STATUS } from 'src/utils/constants'
import {
  getRefreshTokenFromStorage,
  getTokenFromStorage,
} from 'src/utils/storage'
import { setAuthTokenHeader } from 'src/utils/api/core'

export const useSession = () => {
  const dispatch = useDispatch()
  const isOnline = useOnlineStatus()
  const status = useSelector(sessionStatusSelector)
  const authStatus = useSelector(authStatusSelector)

  const refreshSession = async () => {
    try {
      if (authStatus === AUTH_SESSION_REFRESH_IN_PROGRESS) {
        return
      }
      const refreshToken = getRefreshTokenFromStorage()
      const authToken = getTokenFromStorage()
      if (refreshToken && authToken) {
        setAuthTokenHeader(authToken)
        await dispatch(requestRefreshSession(refreshToken))
      }
    } catch (error) {
      // intentional console warn
      console.warn(error)
    }
  }

  useEffect(
    () => {
      if (!isOnline) {
        dispatch(setSessionStatus(SESSION_STATUS.UNKNOWN))
      } else if (
        status === SESSION_STATUS.UNKNOWN ||
        status === SESSION_STATUS.INITIALISING
      ) {
        // refresh the session once the user got online
        refreshSession()
      }
    },
    [isOnline]
  )
  return {
    status,
    refresh: refreshSession,
    isValid: status === SESSION_STATUS.VALID,
    inProgress: authStatus === AUTH_SESSION_REFRESH_IN_PROGRESS,
  }
}

export default useSession
