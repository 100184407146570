import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

import {
  DarkBox,
  FieldLabel,
  CalculationDivider,
  HighlightedCalculationDivider,
} from 'src/components/organisms/ConstraintDetails/common'
import TextInput from 'src/components/atoms/TextInput'
import {
  numberOrMissing,
  formatIfNumber
} from 'src/utils/formatters'
import { CrossIcon, TickIcon } from 'src/components/atoms/Icons'
import { CONSTRAINT_STATUS } from 'src/utils/constants'
import Tooltip from 'src/components/atoms/Tooltip/Tooltip'
import TOOLTIPS from 'src/utils/tooltips'
import { isNumbersNotMatch } from './../../../utils/formatters'

const FieldBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
`

const DarkFieldBox = styled(DarkBox)`
  margin: 4px 0;
  flex: 1;
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)}px;
`

const ConstraintDetailsAirDraft = ({
  riskAssessment,
  readOnly
}) => {
  const {
    routeConstraint,
    constraintStatus,
    calculation,
    setOverride,
    overrides,
    tide,
    tideRaw,
    allowances,
    time
  } = riskAssessment

  const tideRef = useRef(null)
  useEffect(() => {
    if (tideRef.current) {
      tideRef.current.value = formatIfNumber(overrides.tideOverrideValue, 2,
        formatIfNumber(tide, 2, '')
      )
    }
  }, [time, overrides.time])

  let Icon = null
  if (constraintStatus === CONSTRAINT_STATUS.PASSED) {
    Icon = TickIcon
  } else if (constraintStatus === CONSTRAINT_STATUS.FAILED) {
    Icon = CrossIcon
  }

  let overrideColourSafetyMargin = isNumbersNotMatch(calculation.variables.calculatedSafetyMargin,
    calculation.variables.calculatedSafetyMarginRaw, 2)
  let overrideColourTide = isNumbersNotMatch(tide, tideRaw, 2)

  return (
    <>
      <Box display="flex">

        <Box
          flex={1}
          mr={3}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <FieldBox>
            <Box flex={1} pr={2}>
              <FieldLabel>Overhead Clearance</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(
                  calculation.variables.overheadClearance,
                  null,
                  2
                )}
                readOnly
                unit="m"
              />
            </Box>
          </FieldBox>

          <FieldBox>
            <Box display="flex" flex={1} pr={2} justifyContent="space-between">
              <FieldLabel>Predicted Tide (-)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourTide}
                defaultValue={
                  formatIfNumber(overrides.tideOverrideValue, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }
                onChange={setOverride.tideOverrideValue}
                onBlur={({ target }) => {
                  setOverride.tideOverrideValue(target.value)
                  target.value = formatIfNumber(target.value, 2,
                    formatIfNumber(tide, 2, '')
                  )
                }}
                inputRef={tideRef}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </FieldBox>
        </Box>

        <Box
          flex={1}
          ml={3}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <FieldBox>
            <Box flex={1} pr={2}>
              <FieldLabel>Height Overall</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(
                  calculation.variables.heightOverall,
                  null,
                  2
                )}
                readOnly
                unit="m"
              />
            </Box>
          </FieldBox>
          <FieldBox>
            <Box flex={1} pr={2}>
              <FieldLabel>Max. Draft (-)</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(
                  calculation.variables.maxDraftValue,
                  null,
                  2
                )}
                readOnly
                unit="m"
              />
            </Box>
          </FieldBox>
          <CalculationDivider />
          <FieldBox>
            <Box flex={1} pr={2}>
              <FieldLabel>Air Draft</FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                value={numberOrMissing(calculation.variables.airDraft, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </FieldBox>
          <FieldBox>
            <Box flex={1} pr={2}>
              <FieldLabel>
                Safety Margin
                {calculation.variables.unitUsed === '%'
                  ? ` (+${routeConstraint.constraint.safetyMarginPct}%)`
                  : ' (+)'}
              </FieldLabel>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                overrideColour={overrideColourSafetyMargin}
                defaultValue={formatIfNumber(overrides.safetyMargin, 2,
                  formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                )}
                onChange={setOverride.safetyMargin}
                onBlur={({ target }) => {
                  const formatted = formatIfNumber(target.value, 2,
                    formatIfNumber(calculation.variables.calculatedSafetyMarginRaw, 2, '')
                  )
                  setOverride.safetyMargin(formatted)
                  target.value = formatted
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </FieldBox>
          <FieldBox>
            <Box
              flex={1}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FieldLabel>Allowances (+)</FieldLabel>
              <Box pl={1}>
                <Tooltip content={TOOLTIPS.CONSTRAINT_ALLOWANCES} />
              </Box>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                unit="m"
                readOnly={readOnly}
                placeholder='0.00'
                defaultValue={formatIfNumber(overrides.allowances, 2,
                  formatIfNumber(allowances, 2, '')
                )}
                onChange={setOverride.allowances}
                onBlur={({ target }) => {
                  const formatted = formatIfNumber(target.value, 2,
                    formatIfNumber(allowances, 2, '')
                  )
                  setOverride.allowances(formatted)
                  target.value = formatted
                }}
                inputProps={{ inputMode: 'decimal' }}
              />
            </Box>
          </FieldBox>
        </Box>
      </Box>
      <HighlightedCalculationDivider />
      <Box display="flex">
        <DarkFieldBox mr={3}>
          <Box flex={1} pr={2}>
            <FieldLabel>Available Clearance</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.availableClearance,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </DarkFieldBox>
        <DarkFieldBox ml={3}>
          <Box flex={1} pr={2}>
            <FieldLabel>Required Clearance</FieldLabel>
          </Box>
          <Box flex="0 0 125px">
            <TextInput
              value={numberOrMissing(
                calculation.variables.requiredClearance,
                null,
                2
              )}
              readOnly
              unit="m"
            />
          </Box>
        </DarkFieldBox>
      </Box>
      <Box display="flex">
        <Box flex={1} mr={3} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" alignItems="center" justifyContent="space-between">
              <FieldLabel>Actual OHC</FieldLabel>
              {Icon && <Icon size={20} />}
              <Box pl={1}>
                <Tooltip content={TOOLTIPS.ACTUAL_OHC} />
              </Box>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                value={numberOrMissing(calculation.rawResult, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
        <Box flex={1} ml={2} p={1}>
          <Box display="flex" alignItems="center">
            <Box flex={1} pr={2} display="flex" alignItems="center" justifyContent="space-between">
              <FieldLabel>Remaining OHC</FieldLabel>
              {Icon && <Icon size={20} />}
              <Box pl={1}>
                <Tooltip content={TOOLTIPS.REMAINING_OHC} />
              </Box>
            </Box>
            <Box flex="0 0 125px">
              <TextInput
                successOutline={constraintStatus === CONSTRAINT_STATUS.PASSED}
                errorOutline={constraintStatus === CONSTRAINT_STATUS.FAILED}
                value={numberOrMissing(calculation.result, null, 2)}
                readOnly
                unit="m"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

ConstraintDetailsAirDraft.propTypes = {
  riskAssessment: PropTypes.object,
  readOnly: PropTypes.bool,
  editableFields: PropTypes.object,
}

export default React.memo(ConstraintDetailsAirDraft)
