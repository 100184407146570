export const missingValueText = '--'

export const PILOTAGE_STATUS = {
  DRAFT: 'Draft',
  NEW: 'New',
  PLANNING: 'Plan',
  SENT_UNREAD: 'SentUnread',
  SENT_READ: 'SentRead',
  SENT_NEW_COMMENT: 'SentNewComment',
  DONE: 'Done',
  ARCHIVED: 'Arch',
  CANCELLED: 'Canc',
}

export const CONSTRAINT_STATUS = {
  LOADING: 'loading',
  PASSED: 'passed',
  FAILED: 'failed',
  NO_RESULT: 'no_result',
}

export const MOVEMENT_TYPE = {
  DEPARTURE: 'Departure',
  SHIFT: 'Shift',
  ARRIVAL: 'Arrival',
}

export const NOT_KNOWN = 'Not known'

export const vesselTypeOptions = [
  { label: 'Dry Cargo/Passenger', value: 'Dry Cargo/Passenger' },
  { label: 'Container', value: 'Container' },
  { label: 'Passenger', value: 'Passenger' },
  { label: 'Bulk', value: 'Bulk' },
  { label: 'Tanker', value: 'Tanker' },
  { label: 'General Cargo', value: 'General Cargo' },
  { label: 'Fishing', value: 'Fishing' },
  { label: 'Car Carrier', value: 'Car Carrier' },
  { label: 'Navy', value: 'Navy' },
  { label: 'Tug', value: 'Tug' },
  { label: 'Other', value: 'Other' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselPropPitchOptions = [
  { label: 'CPP', value: 'CPP' },
  { label: 'Fixed', value: 'Fixed' },
  { label: 'Azimuth Elec Dr', value: 'Azimuth Elec Dr' },
  { label: 'Voith-Schneider', value: 'Voith-Schneider' },
  { label: 'Contra-Rotating', value: 'Contra-Rotating' },
  { label: 'Directional', value: 'Directional' },
  { label: 'Water Jet', value: 'Water Jet' },
  { label: 'Paddle Wheel', value: 'Paddle Wheel' },
  { label: 'Z Type', value: 'Z Type' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselPropTypeOptions = [
  { label: 'Prop', value: 'Prop' },
  { label: 'Azipod', value: 'Azipod' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselPropNoOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselRudderTypeOptions = [
  { label: 'Conventional', value: 'Conventional' },
  { label: 'Becker', value: 'Becker' },
  { label: 'Shilling', value: 'Shilling' },
  { label: 'Fishtail', value: 'Fishtail' },
  { label: 'Balanced', value: 'Balanced' },
  { label: 'Semi-Balanced', value: 'Semi-Balanced' },
  { label: 'High-Lift', value: 'High-Lift' },
  { label: 'Azipod', value: 'Azipod' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselPropRotationOptions = [
  { label: 'Left', value: 'Left' },
  { label: 'Right', value: 'Right' },
  { label: 'Inwards', value: 'Inwards' },
  { label: 'Outwards', value: 'Outwards' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselPropEffectOptions = [
  { label: 'Right-Handed', value: 'Right-Handed' },
  { label: 'Left-Handed', value: 'Left-Handed' },
  { label: NOT_KNOWN, value: NOT_KNOWN },
]

export const vesselTrackControlOptions = [
  { label: NOT_KNOWN, value: NOT_KNOWN },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
]

export const sideToOptions = [
  { label: 'Port', value: 'Port' },
  { label: 'Starboard', value: 'Starboard' },
]

export const movementTypeOptions = [
  { label: 'Arrival', value: 'Arrival' },
  { label: 'Departure', value: 'Departure' },
  { label: 'Shift', value: 'Shift' },
]

export const PILOTAGE_STAGE_ID = {
  ARRIVAL_BERTHING: 'arrival-berthing',
  DEPARTURE_BERTHING: 'departure-berthing',
}

export const CHART_EXPIRY_INTERVAL = 40000
export const ATTACHMENTS_EXPIRY_INTERVAL = 40000

export const COMMENT_SENDER = {
  PILOT: 'pilot',
  MASTER: 'master',
}

export const SIGNING_USER = {
  PILOT: 'pilot',
  MASTER: 'master',
}

export const EXTRA_TYPE = {
  CHECKLIST: 'checklist',
  PASS_FAIL_CHECKLIST: 'pass_fail_checklist',
  VALUES_CHECKLIST: 'values_checklist',
  TERMS: 'terms',
  GUIDELINES: 'guidelines',
  TIMESTAMP_CHECKLIST: 'timestamp_checklist',
  EXTERNAL_LINK_1: 'external_link',
  EXTERNAL_LINK_2: 'external_link_2',
  EXTERNAL_LINK_3: 'external_link_3',
}
export const PILOTAGE_FORM_ACTION = {
  SAVE_AND_CLOSE: 'SAVE_AND_CLOSE',
  SAVE_AND_START_ANOTHER: 'SAVE_AND_START_ANOTHER',
  SAVE_AND_START_PLANNING: 'SAVE_AND_START_PLANNING',
}

export const CONSTRAINT_TYPE = {
  UKC: 'ukc',
  OHC: 'air',
  UKC_DYNA: 'ukc_dyna',
}

export const PILOTAGE_MODE = {
  DIRECT: 'Direct',
  INDIRECT: 'Indirect'
}

export const TRANSFER_METHOD = {
  PILOT_LAUNCH: 'Pilot Launch',
  HELICOPTER: 'Helicopter'
}

export const DATE_FORMAT = 'D MMM YYYY'
export const LONG_DATE_FORMAT = 'dddd D MMMM, YYYY'
export const LONG_DATE_FORMAT_LUXON = 'cccc d MMMM, yyyy'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_WITH_SEC = 'HH:mm:ss'
export const TIME_FORMAT_OMC = 'DD/HHmm'
export const DATETIME_FORMAT = 'HH:mm, D MMM YYYY'

export const SYNC_STATUS = {
  PENDING: 'SYNC_PENDING',
  IN_PROGRESS: 'SYNC_IN_PROGRESS',
  ERROR: 'SYNC_ERROR',
  SUCCESS: 'SYNC_SUCCESS',
}

export const SYNC_ENTITY_TYPES = {
  PILOTAGE: 'PILOTAGE',
  PILOTAGE_TUGS: 'PILOTAGE_TUGS',
  PILOTAGE_DRAWINGS: 'PILOTAGE_DRAWINGS',
  PILOTAGE_SIGNATURES: 'PILOTAGE_SIGNATURES',
  PILOTAGE_EXTRAS: 'PILOTAGE_EXTRAS',
  PILOTAGE_VESSEL: 'PILOTAGE_VESSEL',
  PILOTAGE_THREAD: 'PILOTAGE_THREAD',
  PILOTAGE_ROUTE_CHECKS: 'PILOTAGE_ROUTE_CHECKS',
}

export const SYNC_ERRORS = {
  MISSING_ENTITY_UUID: 'MISSING_ENTITY_UUID',
  PILOTAGE_SYNC_ERROR: 'PILOTAGE_SYNC_ERROR',
  PARTIAL_SYNC_ERROR: 'PARTIAL_SYNC_ERROR',
}

export const EMPX_PING_LOG_ENABLED = false
export const TIME_AT_CONSTRAINT_LOG_ENABLED = false

export const WEATHER_MAX_INTERVAL = 7 * 24 * 60 * 60000

export const OFFLINE_PILOTAGES_PREFETCH_PAST_MARGIN_HOURS = 8
export const OFFLINE_PILOTAGES_DAYS_TO_PREFETCH = 1
// EMPX-79:
// Online prefetch needs -6hr and +4d for ALL, -13h and +39h for EXTREMES
// To cater for a pilotage in next 24hrs (earliest today, latest tomorrow)
// we need at least -1d and +4d from now() to cover the pilotage
export const OFFLINE_TIDES_DAYS_TO_PREFETCH = 4
export const OFFLINE_PREFETCH_INTERVAL = 1000 * 60 * 60 // 1 hour

export const SESSION_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  UNKNOWN: 'UNKNOWN',
  INITIALISING: 'INITIALISING',
}

export const ENTITY_NAME = {
  PILOTAGE: 'pilotage',
  VESSEL: 'vessel',
  CHECKLIST: 'checklist',
  PASS_CAUTION_CHECKLIST: 'passCautionChecklist',
}

export const TIDE_TYPE = {
  ALL: 'ALL',
  EXTREMES: 'EXTREMES',
}

export const PILOTAGE_DATE_THRESHOLD_DAYS = 30
export const WEATHER_USE_MULTI_ENDPOINT = true
export const METRES_PER_SECOND_TO_KNOTS = 1.94384
