import React from 'react'
import {
  TimelineItem,
  TimelineItemType,
} from 'src/components/molecules/TideExtremes/types'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

const iconOffset = { margin: '-7px 0px -7px -7px' }

interface TideExtremesTimelineProps {
  timeline: Partial<TimelineItem>[]
  isMasterView: boolean
}

export const TideExtremesTimeline: React.FC<
  TideExtremesTimelineProps
> = React.memo(({ timeline, isMasterView }) => {

  const items = timeline.filter(({ type }) => type !== TimelineItemType.PilotageStart)

  return (
    <table style={{ marginBottom: 5, width: '100%' }}>
      <tbody>
        {items.map((item, i) => {
          const date = item.formattedDate && 
            isMasterView ? item.formattedDate.split(' ').slice(0, 2).join(' ') : item.formattedDate
          return (
            <tr key={i}>
              <td style={{ whiteSpace: 'nowrap' }}>
                { 
                  item.type === TimelineItemType.HighTide ? 
                  <ArrowDropUp style={iconOffset} /> : 
                  <ArrowDropDown style={iconOffset} /> 
                }
                {date}
              </td>
              <td>{item.formattedTime}</td>
              <td style={{ textAlign: 'right' }}>{item.tide}m</td>
            </tr>
          )
        }
        )}
      </tbody>
    </table>
  )
})

TideExtremesTimeline.displayName = 'TideExtremesTimeline'
