import api from 'src/utils/api/core'

export function getPilotages(portUuid) {
  return api.get(`/pilotages`, { params: { portUuid } })
}

export function getPilotage(uuid) {
  return api.get(`/pilotages/${uuid}`)
}

export function createPilotage(data) {
  return api.post(`/pilotages`, data)
}

export function updatePilotage(uuid, data, reviewed) {
  return api.put(`/pilotages/${uuid}?review=${reviewed === true}`, data)
}

export function getPilotageExtras(uuid) {
  return api.get(`/pilotages_extras?pilotageUuid=${uuid}`)
}

export function getBerthsStations(portUuid) {
  return api.get(`/berth_stations?portUuid=${portUuid}`)
}

export function sharePilotagePlan(uuid, emails) {
  return api.post(
    `/pilotages/share`,
    {
      pilotage: { uuid },
      emails,
    },
    {
      timeout: 60000,
    }
  )
}

export function getSignatures(uuid) {
  return api.get(`/pilotages/${uuid}/signature`)
}

export function addSignature(uuid, signature) {
  return api.post(`/pilotages/${uuid}/signature`, { ...signature, pilotage: { uuid }})
}

export function getMasterViewToken(token) {
  return api.get(`/pilotages/share/${token}`)
}

export function downloadPdf(uuid) {
  return api.post(`/pilotages/${uuid}/pdf`, null, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
    // some ports use 8MB charts and till we allow this size
    // we have to disable timeout
    timeout: 0,
  })
}
