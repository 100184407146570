/**
 * Returns points where the line defined by y = mx + c and the circle defined
 * by (x - p)^2 + (y - q)^2 = r^2 intersect each other.
 * @param m - line gradient
 * @param c - line y intercept
 * @param p - circle centroid x coordinate
 * @param q - circle centroid y coordinate
 * @param r - circle radius
 */
function findLineCircleIntersection(m, c, p, q, r) {
  const A = Math.pow(m, 2) + 1
  const B = 2 * (m * c - m * q - p)
  const C =
    Math.pow(p, 2) +
    Math.pow(q, 2) -
    Math.pow(r, 2) +
    Math.pow(c, 2) -
    2 * c * q

  const sqrtTerm = Math.pow(B, 2) - 4 * A * C

  if (sqrtTerm < 0) {
    // No intersection
    return []
  }

  const x = [
    (-B + Math.sqrt(sqrtTerm)) / (2 * A),
    (-B - Math.sqrt(sqrtTerm)) / (2 * A),
  ]

  return x.map(x => [x, m * x + c])
}

export default findLineCircleIntersection
