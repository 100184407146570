import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import { useRoute } from 'react-router5'

import { routeSelector } from 'src/store/route/selectors'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { MOVEMENT_TYPE, PILOTAGE_STAGE_ID } from 'src/utils/constants'
import {
  PlanApproachIcon,
  PlanBerthingIcon,
  PlanChannelIcon,
} from 'src/components/atoms/Icons'

const NavigationItemBox = styled(({ theme, isActive, ...rest }) => (
  <Box {...rest} />
)).attrs({ height: 100, p: 2 })(
  ({ theme, isActive }) => css`
    && {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: ${isActive
        ? theme.palette.primary.main
        : theme.palette.background.light // eslint-disable-line
      };
      border-radius: 5px;
      text-transform: uppercase;
      color: ${theme.palette.text.primary};
      cursor: pointer;

      &:not(:first-child) {
        margin-left: ${theme.spacing(2)}px;
      }
    }
  `
)

const NavigationItemLabel = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
`

const ITEM_TYPE = {
  OUTER: 'outer',
  INNER: 'inner',
  BERTHING: 'berthing',
}

const NavigationItem = ({ type, label, status, isActive, ...rest }) => {
  let icon
  switch (type) {
    case ITEM_TYPE.BERTHING:
      icon = <PlanBerthingIcon />
      break
    case ITEM_TYPE.INNER:
      icon = <PlanApproachIcon />
      break
    case ITEM_TYPE.OUTER:
      icon = <PlanChannelIcon />
      break
    default:
      icon = null
  }

  return (
    <Grid item xs={6} md={4}>
      <NavigationItemBox m={1} isActive={isActive} {...rest}>
        <NavigationItemLabel>{label}</NavigationItemLabel>
        <Box pl={2} display="flex" mx={0} ml="auto" my="auto">
          {icon}
        </Box>
      </NavigationItemBox>
    </Grid>
  )
}

NavigationItem.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  isActive: PropTypes.bool,
}

const NavigationBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

const getItemType = (isFirst, isLast, movementType) => {
  // We assume the first stage of a departure is an inner stage (e.g. inner harbour).
  // Likewise we assume the last stage of an arrival is an inner stage. Other stages
  // (excluding berthing stages) are outer stages. Inner and outer stages have their
  // own icons displayed.
  if (
    (isFirst && movementType === MOVEMENT_TYPE.DEPARTURE) ||
    (isLast && movementType === MOVEMENT_TYPE.ARRIVAL)
  ) {
    return ITEM_TYPE.INNER
  }
  return ITEM_TYPE.OUTER
}

const PassagePlanGrid = ({ topMargin, onChange }) => {
  const { route } = useRoute()
  const { data: pilotage } = useSelector(pilotageSelector)
  const pilotageRoute = useSelector(routeSelector)

  const { movementType } = pilotage || {}
  const { stages = [], showBerthingStages } = pilotageRoute || {}
  stages.sort((a, b) => a.order - b.order)

  const showDepartureBerthing =
    showBerthingStages &&
    (movementType === MOVEMENT_TYPE.SHIFT ||
      movementType === MOVEMENT_TYPE.DEPARTURE)

  const showArrivalBerthing =
    showBerthingStages &&
    (movementType === MOVEMENT_TYPE.SHIFT ||
      movementType === MOVEMENT_TYPE.ARRIVAL)

  const currentStageId = route.params.stageId

  const items = []
  if (showDepartureBerthing) {
    items.push({
      type: 0,
    })
  }
  stages.forEach((stage) => {
    items.push({
      type: 1,
      stage,
    })
  })
  if (showArrivalBerthing) {
    items.push({
      type: 2,
    })
  }

  return (
    <NavigationBox mt={topMargin ? 4 : 0}>
      {/* {chunk(items, 2).map((row, rowIndex) => */}
      <Grid container>
        {
          items.map((item, itemIndex) => {
            if (item.type === 0) {
              return (
                <NavigationItem
                  key={itemIndex}
                  type={ITEM_TYPE.BERTHING}
                  label="Berthing"
                  onClick={() =>
                    onChange(PILOTAGE_STAGE_ID.DEPARTURE_BERTHING, currentStageId)
                  }
                  isActive={
                    route.params.stageId === PILOTAGE_STAGE_ID.DEPARTURE_BERTHING
                  }
                />
              )
            } else if (item.type === 1) {
              const stage = item.stage
              const type = getItemType(
                stage.order === 0,
                stage.order === stages.length - 1,
                movementType
              )

              return (
                <NavigationItem
                  key={stage.uuid}
                  type={type}
                  label={stage.name}
                  onClick={() => onChange(stage.uuid, currentStageId)}
                  isActive={stage.uuid === route.params.stageId}
                />
              )
            } else if (item.type === 2) {
              return (
                <NavigationItem
                  key={itemIndex}
                  type={ITEM_TYPE.BERTHING}
                  label="Berthing"
                  onClick={() =>
                    onChange(PILOTAGE_STAGE_ID.ARRIVAL_BERTHING, currentStageId)
                  }
                  isActive={route.params.stageId === PILOTAGE_STAGE_ID.ARRIVAL_BERTHING}
                />
              )
            } else {
              return <></>
            }
          })
        }
      </Grid>
      {/* )} */}
    </NavigationBox>
  )
}

PassagePlanGrid.propTypes = {
  topMargin: PropTypes.bool,
  onChange: PropTypes.func, // (nextStageId, currentStageId?) => void
}

export default PassagePlanGrid
