import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Typography, Collapse } from '@material-ui/core'
import Autolinker from 'autolinker'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoBox from 'src/components/atoms/InfoBox'

const PortExtraWrapper = styled.div`
  & p {
    margin: 0px;
    
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
  }
  & a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const HeaderContainer = styled(({ collapsible, children, ...props }) => (
  <div {...props}>{children}</div>
))(
  ({ collapsible }) => css`
    display: flex;
    justify-content: space-between;
    cursor: ${collapsible ? 'pointer' : 'auto'};
  `
)

const CollapseButton = styled(ExpandMoreIcon)`
  && {
    border: 1px solid;
    border-radius: 100%;
    transform: ${({ open }) => `rotate(${open ? 180 : 0}deg)`};
    transition: transform 400ms;
  }
`

const PortExtra = ({ heading, content, collapsible = true, ...props }) => {
  const [isCollapsed, setIsCollapsed] = useState(!collapsible)

  const sections = content
    .split('\n\n')
    .filter(section => section.trim().length > 0)
    .map((section, index) => {

      const withLinks = Autolinker.link(
        section.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      )

      const withBreaks = withLinks.replace(/\n/g, '<br/>')

      return <p key={index} dangerouslySetInnerHTML={{ __html: withBreaks }} />
    })

  const handleCollapse = () => setIsCollapsed(!isCollapsed)

  return (
    <PortExtraWrapper>
      <HeaderContainer
        collapsible={collapsible}
        onClick={collapsible ? handleCollapse : null}
      >
        {heading && <Typography variant="h2">{heading}</Typography>}
        {collapsible && <CollapseButton open={isCollapsed} />}
      </HeaderContainer>
      <Collapse in={isCollapsed}>
        <InfoBox mt={heading ? 3 : 0} variant={props.variant}>
          {props.minHeight ? (
            <div style={{ minHeight: props.minHeight }}>{sections}</div>
          ) : (
            sections
          )}
        </InfoBox>
      </Collapse>
    </PortExtraWrapper>
  )
}

PortExtra.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  collapsible: PropTypes.bool,
  variant: PropTypes.string,
  minHeight: PropTypes.number,
}

export default React.memo(PortExtra)
