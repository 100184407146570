import { addErrorToast } from 'src/store/toast/actions'
import { getDhiTransitsPdf } from 'src/utils/api/dhi'
import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction
} from 'src/utils/createAction'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const DHI_TRANSITS_BASE = 'DHI_TRANSITS_PDF'

export const [
  DHI_TRANSITS_PDF_REQUEST,
  DHI_TRANSITS_PDF_SUCCESS,
  DHI_TRANSITS_PDF_ERROR,
  DHI_TRANSITS_PDF_CANCEL,
  DHI_TRANSITS_PDF_IN_PROGRESS,
] = createBasicActionTypes(DHI_TRANSITS_BASE)

export const dhiTransitsPdfRequest = (portUuid, scenarioId, opts) => async dispatch => {
  if (!opts || !opts.isPrefetch) {
    dispatch(dhiTransitsInProgress())
  }

  try {
    const data = await getDhiTransitsPdf(portUuid, scenarioId)
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiTransitsLoaded(data))
    }
    return Promise.resolve(data)
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiTransitsError(error, TOAST_MESSAGES.LOAD_DHI_PDF_ERROR))
    }
    return Promise.reject(error)
  }
}

export const dhiTransitsInProgress = () => createInProgressAction(DHI_TRANSITS_BASE)

export const dhiTransitsLoaded = data => createSuccessAction(DHI_TRANSITS_BASE, data)

export const dhiTransitsError = (error, message) => dispatch => {
  dispatch(createErrorAction(DHI_TRANSITS_BASE, error))
  dispatch(addErrorToast({ message }))
}
