import { getSignaturesInState, PilotageSignatureState } from 'src/store/pilotageSignature/reducer'
import { createSelector } from 'reselect'
import { entitiesToSyncSelector } from './../sync/selectors'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { SIGNING_USER } from './../../utils/constants'

export const pilotageSignatureStateSelector = (
  state: { pilotageSignatures: PilotageSignatureState }
): PilotageSignatureState =>
  state.pilotageSignatures

export const pilotageSignatureSelector = (pilotageUuid: string) => createSelector(
  pilotageSignatureStateSelector,
  entitiesToSyncSelector,
  (state: any, entitiesToSync: any) => {
    const syncItems = Object.keys(entitiesToSync || {}).map(
      uuid => entitiesToSync[uuid]
    )
    // find those changed items which are
    // - has signatures sync type
    const pilotageSignaturesInSync = syncItems
      .filter(item => item.type === SYNC_ENTITY_TYPES.PILOTAGE_SIGNATURES)
      .map(item => item.entity)

    // find sync entity by pilotage uuid
    let signaturePilot
    let signatureMaster
    let loading = false
    // get signatures from sync items first
    for (let i = 0; i < pilotageSignaturesInSync.length; i++) {
      const syncItem = pilotageSignaturesInSync[i]
      if (syncItem.pilotage.uuid === pilotageUuid) {
        if (syncItem.signature.role === SIGNING_USER.PILOT) {
          signaturePilot = syncItem.signature
        } else if (syncItem.signature.role === SIGNING_USER.MASTER) {
          signatureMaster = syncItem.signature
        } else {
          // internal error
        }
      }
    }
    // then, get signatures from state
    const entityInState = state && state[pilotageUuid]
    if (entityInState) {
      loading = entityInState.loading
      const signaturesInState = getSignaturesInState(entityInState)
      if (!signaturePilot && signaturesInState.signaturePilot) {
        signaturePilot = signaturesInState.signaturePilot
      }
      if (!signatureMaster && signaturesInState.signatureMaster) {
        signatureMaster = signaturesInState.signatureMaster
      }
    }
    let signatures = []
    if (signaturePilot) {
      signatures.push(signaturePilot)
    }
    if (signatureMaster) {
      signatures.push(signatureMaster)
    }
    return {
      loading: loading,
      signatures,
    }
  }
)
