import { hotjar } from 'react-hotjar'

const useHotjar = () => {
  const initHJ = () => {
    if (process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV) {
      hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV)
    }
  }
  return {
    initHJ
  }
}

export default useHotjar
