import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatToTimeZone, convertToTimeZone } from 'date-fns-timezone'
import allSettled from 'promise.allsettled'

import {
  OFFLINE_PILOTAGES_DAYS_TO_PREFETCH,
  OFFLINE_TIDES_DAYS_TO_PREFETCH,
  OFFLINE_PREFETCH_INTERVAL,
  OFFLINE_PILOTAGES_PREFETCH_PAST_MARGIN_HOURS,
  PILOTAGE_STATUS,
  TIDE_TYPE,
} from 'src/utils/constants'
import {
  prefetchPilotages,
  prefetchCommons,
  prefetchChartImage,
  prefetchTides,
  prefetchTideRates,
  prefetchTideExtremes,
  prefetchReset,
} from 'src/store/prefetch/actions'
import {
  timezoneSelector,
  selectedPortUuidSelector,
} from 'src/store/ports/selectors'
import { useServiceWorker } from './useServiceWorker'
import { addErrorToast } from 'src/store/toast/actions'
import {
  imageTokenSelector,
  isLoggedInSelector,
} from 'src/store/auth/selectors'
import { chartImagePrefetchStatusSelector } from 'src/store/prefetch/selectors'
import {
  isOnlineSelector,
  isServiceWorkerAvailableSelector,
} from 'src/store/ui/selectors'
import { pilotageListSelector } from 'src/store/pilotageList/selectors'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { getTideStations } from 'src/utils/api/tideStations'
import { getTideRateStations } from 'src/utils/api/tideRateStations'

const createPilotagesDateFilter = (from, to, timeZone) => pilotage => {
  const dateTime = convertToTimeZone(pilotage.date, { timeZone })
  return from <= dateTime && dateTime <= to
}

export const getPilotagePrefetchTimeRange = timeZone => {
  const from = convertToTimeZone(new Date(), { timeZone })
  // this is a middle solution to fetch pilotages
  // which can be in progress
  // TODO find a better solution to find a from to date for prefetching
  from.setHours(from.getHours() - OFFLINE_PILOTAGES_PREFETCH_PAST_MARGIN_HOURS)
  const to = convertToTimeZone(new Date(), { timeZone })
  to.setDate(to.getDate() + OFFLINE_PILOTAGES_DAYS_TO_PREFETCH)
  return [from, to]
}

export const usePrefetch = () => {
  const dispatch = useDispatch()
  const [fetchIsInProgress, setFetchIsInProgress] = useState(false)
  const timeZone = useSelector(timezoneSelector)
  const { isReady: serviceWorkerIsReady } = useServiceWorker()
  const prefetchTimer = useRef()
  const clearTimer = useRef()
  const imageToken = useSelector(imageTokenSelector)
  const prefetchedChartImages = useSelector(chartImagePrefetchStatusSelector)
  const isOnline = useSelector(isOnlineSelector)
  const isServiceWorkerReady = useSelector(isServiceWorkerAvailableSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const pilotageList = useSelector(pilotageListSelector)
  const selectedPortUuid = useSelector(selectedPortUuidSelector)

  useEffect(
    () => {
      if (isServiceWorkerReady && isOnline && prefetchedChartImages) {
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage('clearChartImageCache')
        }
        Object.keys(prefetchedChartImages).forEach(uuid =>
          dispatch(prefetchChartImage({ uuid }))
        )
      }
    },
    [imageToken]
  )

  // Don't prefetch draft pilotages
  const statusFilterFn = pilotage =>
    pilotage.status !== PILOTAGE_STATUS.DRAFT &&
    pilotage.status !== PILOTAGE_STATUS.DONE &&
    pilotage.status !== PILOTAGE_STATUS.ARCHIVED &&
    pilotage.status !== PILOTAGE_STATUS.CANCELLED

  // Restrict the date range for which we will prefetch pilotages
  const dateRangeFilterFn = createPilotagesDateFilter(
    ...getPilotagePrefetchTimeRange(timeZone),
    timeZone
  )

  const prefetchFilterFn = pilotage =>
    statusFilterFn(pilotage) && dateRangeFilterFn(pilotage)

  const fetchPilotages = async force =>
    serviceWorkerIsReady
      ? dispatch(prefetchPilotages(selectedPortUuid, prefetchFilterFn, force))
      : false

  const fetchCommons = () =>
    serviceWorkerIsReady ? dispatch(prefetchCommons(selectedPortUuid)) : false

  const fetchTides = async (tideStations, type) => {
    if (!serviceWorkerIsReady) {
      return false
    }
    let from = new Date()
    from.setDate(from.getDate() - 1)
    // EMPX-322: BE changed to store TZ as true UTC
    from = `${formatToTimeZone(from, 'YYYY-MM-DD', { timeZone: 'UTC' })}`
    let to = new Date()
    to.setDate(to.getDate() + OFFLINE_TIDES_DAYS_TO_PREFETCH)
    // EMPX-322: BE changed to store TZ as true UTC
    to = `${formatToTimeZone(to, 'YYYY-MM-DD', { timeZone: 'UTC' })}`

    return dispatch(
      type === TIDE_TYPE.ALL
        ? prefetchTides(tideStations, from, to)
        : prefetchTideExtremes(tideStations, from, to)
    )
  }

  const fetchTideRates = async (tideRateStations) => {
    if (!serviceWorkerIsReady) {
      return false
    }
    let from = new Date()
    from.setDate(from.getDate() - 1)
    // EMPX-322: BE changed to store TZ as true UTC
    from = `${formatToTimeZone(from, 'YYYY-MM-DD', { timeZone: 'UTC' })}`
    let to = new Date()
    to.setDate(to.getDate() + OFFLINE_TIDES_DAYS_TO_PREFETCH)
    // EMPX-322: BE changed to store TZ as true UTC
    to = `${formatToTimeZone(to, 'YYYY-MM-DD', { timeZone: 'UTC' })}`

    return dispatch(prefetchTideRates(tideRateStations, from, to))
  }

  const fetch = async (force = false) => {
    if (!selectedPortUuid) {
      return false
    }
    if (fetchIsInProgress) {
      return false
    }
    try {
      setFetchIsInProgress(true)
      await clear()
      clearPrefetchTimer()
      if (!serviceWorkerIsReady) {
        dispatch(
          addErrorToast({
            message: 'Error prefetching data for offline use',
          })
        )
        return false
      }
      await fetchCommons()
      // EMPX-97: get tides before pilotages do that blue indicator on pilotage
      // will not turn blue if we fail to prefetch tides. To test this:
      // - add the 2 lines below in BE TideDataService.getAll()
      //     await this.sleep(30000);
      //     throw new Error('SIM ERROR');
      // - in browser, clear Local Storage and Cache Storage
      // - refresh browser, it should not show a blue icon for pilotages
      const tideStations = getPayloadFromResponse(await getTideStations(selectedPortUuid))
      const tideRateStations = getPayloadFromResponse(await getTideRateStations(selectedPortUuid))
      await fetchTides(tideStations, TIDE_TYPE.ALL)
      await fetchTides(tideStations, TIDE_TYPE.EXTREMES)
      await fetchTideRates(tideRateStations)
      await fetchPilotages(force)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setFetchIsInProgress(false)
      setPrefetchTimer()
    }
  }

  const clearPrefetchTimer = () =>
    prefetchTimer.current && clearInterval(prefetchTimer.current)
  const setPrefetchTimer = () => {
    prefetchTimer.current = setTimeout(fetch, OFFLINE_PREFETCH_INTERVAL)
  }

  const clearClearTimer = () =>
    clearTimer.current && clearInterval(clearTimer.current)
  const setClearTimer = () => {
    clearTimer.current = setTimeout(clear, 1000 * 60)
  }

  useEffect(() => {
    setPrefetchTimer()
    return () => {
      clearPrefetchTimer()
    }
  }, [])

  const handlePrefetchError = error => {
    // intentional console
    // TODO decide if we warn the user that
    // the app is not ready for offline usage
    console.warn(error)
  }

  useEffect(
    () => {
      if (isLoggedIn) {
        // EMPX-97: Call fetch() instead of fetchPilotages() so that tides can be
        // prefetched as well. WIP, enable after confirming side effects.
        const CALL_FETCH_WIP = false
        if (!CALL_FETCH_WIP) {
          clear()
          fetchPilotages()
        } else {
          fetch(false).catch(error => handlePrefetchError(error))
        }
      }
    },
    [pilotageList]
  )

  const clear = async () => {
    clearClearTimer()
    if (pilotageList) {
      const filter = createPilotagesDateFilter(
        ...getPilotagePrefetchTimeRange(timeZone),
        timeZone
      )
      await allSettled(
        // clear out of range pilotages
        pilotageList.reduce((promises, pilotage) => {
          if (filter(pilotage)) {
            return promises
          }
          return [...promises, dispatch(prefetchReset(pilotage.uuid))]
        }, [])
      )
    }
    setClearTimer()
  }

  return {
    fetch,
    clear,
  }
}

export default usePrefetch
