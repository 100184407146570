import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  VesselDiagramContainer,
  DiagramLabel,
  Measurement,
  MeasurementLines,
  DottedLines,
  markerRadius,
  linesToPath
} from 'src/components/molecules/VesselDiagram/common'
import { numberOrMissing } from 'src/utils/formatters'
import { useSelector } from 'react-redux'
import { vesselFieldDisplayConfigSelector } from 'src/store/preferences/selectors'

const VesselOutlineBridgeFront = () => (
  <svg width={492} viewBox="0 0 502 162" preserveAspectRatio="xMinYMin meet">
    <path
      fill="none"
      d="M402 54.6826H499.701C499.701 54.6826 454.252 160.155 440.201 160.6C435.942 160.6 363.221 160.6 75.4387 160.6L67.8853 152.402L57.5037 137.102L14.3125 124.512L1.59998 54.6826H328V1.74999H398L402 54.6826Z"
      stroke="#FFF"
      strokeWidth="2"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

const VesselOutline = () => (
  <svg width={492} viewBox="0 0 502 162" preserveAspectRatio="xMinYMin meet">
    <path
      d="M370.108 54.683h129.593c-30.3 70.315-50.133 105.62-59.5 105.917H75.44l-7.554-8.198-10.381-15.3-43.191-12.59L1.6 54.682h58.827l.996-52.932h69.255l6 52.25 233.43.683z"
      stroke="#FFF"
      strokeWidth="2"
      fill="none"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
)

const BgRect = styled.rect`
  fill: ${({ theme }) => theme.palette.background.default};
`

const VesselSideDiagram = ({
  bridgeToBow,
  bridgeToStern,
  heightOverall,
  lengthOverall,
  maxDraft,
  freeboard,
  metaAirDraft,
  unit,
}) => {

  const vesselFieldDisplayConfig = useSelector(vesselFieldDisplayConfigSelector)

  const fieldEnabled = (fieldName) =>
    !vesselFieldDisplayConfig ||
    !vesselFieldDisplayConfig.disabled_pilot_app[fieldName.replace(/^metadata_/, '')]

  const bridgeFront =
    typeof bridgeToBow === 'number' &&
    typeof bridgeToStern === 'number' &&
    bridgeToBow < bridgeToStern

  const showAirDraft = fieldEnabled('air_draft') && !isNaN(metaAirDraft)
  const showFreeboard = fieldEnabled('freeboard')

  const bottomDottedLineY = showAirDraft ? 131 : 162

  const dottedLines = [
    [10, 5, 146, 5], // top h
    [10, bottomDottedLineY, showAirDraft ? 110 : 161, bottomDottedLineY], // bottom h
    [89, 58, 89, 200], // stern v
    [582, 58, 582, 200] // bow v
  ]

  const measurementLines = [
    showAirDraft ? [10, 5, 10, 13] : [10, 5, 10, 50], // top - label v
    showAirDraft ? [10, 60, 10, bottomDottedLineY] : [10, 112, 10, bottomDottedLineY], // label - bottom v
    [89, 200, 281, 200], // stern - label h
    [390, 200, 582, 200], // label - bow h
    [466, 131, 466, 162], // max draft h
  ]

  if (showFreeboard) {
    measurementLines.push([466, 58, 466, 131]) // freeboard h
  }

  return (
    <VesselDiagramContainer>
      <svg viewBox="0 0 600 228" style={{ overflow: 'visible' }}>
        <g transform={`translate(${bridgeFront ? 95 : 90},4)`}>
          {bridgeFront ? <VesselOutlineBridgeFront /> : <VesselOutline />}
        </g>
        <g transform="translate(24, 125)">
          <svg
            viewBox="0 0 574 9"
            width="100%"
            preserveAspectRatio="xMinYMin meet"
          >
            <g stroke="#39495F" fill="none" fillRule="evenodd">
              <path
                d="M65 5.015c9.882-5.92 29.152-4.492 37.074-.86 7.921 3.632 26.765 5.958 35.926 1.038M138 5.156c9.885-5.998 29.136-4.733 37.06-1.052 7.925 3.68 26.776 6.037 35.94 1.052M-8 5.228C1.875-.874 21.105.413 29.02 4.158c7.917 3.744 26.826 5.949 35.98.877M284 5.015c9.882-5.92 29.152-4.492 37.074-.86 7.921 3.632 26.765 5.958 35.926 1.038"
                strokeWidth="2"
              />
              <path d="M357 5.156c9.885-5.998 29.136-4.733 37.06-1.052 7.925 3.68 26.776 6.037 35.94 1.052" />
              <path
                d="M211 5.228c9.875-6.102 29.105-4.815 37.02-1.07 7.917 3.744 26.826 5.949 35.98.877M503 5.015c9.882-5.92 29.152-4.492 37.074-.86 7.921 3.632 26.765 5.958 35.926 1.038 6.107-3.28 25.774 8.057 59 34.013M357 5.156c9.885-5.998 29.136-4.733 37.06-1.052 7.925 3.68 26.776 6.037 35.94 1.052M430 5.228c9.875-6.102 29.105-4.815 37.02-1.07 7.917 3.744 26.826 5.949 35.98.877"
                strokeWidth="2"
              />
            </g>
          </svg>
        </g>

        <g fill="#1F8EFA" stroke="none">
          <circle r={markerRadius} cx={10} cy={5} />
          <circle r={markerRadius} cx={10} cy={bottomDottedLineY} />
          <circle r={markerRadius} cx={466} cy={131} />
          { showFreeboard &&
            <circle r={markerRadius} cx={466} cy={58} />
          }
          <circle r={markerRadius} cx={466} cy={162} />
          <circle r={markerRadius} cx={89} cy={200} />
          <circle r={markerRadius} cx={582} cy={200} />
        </g>

        <BgRect x={326} y={100} width={105} height={50} />

        <MeasurementLines>
          <path
            d={linesToPath(measurementLines)}
            vectorEffect="non-scaling-stroke"
          />
          <DottedLines>
            <path
              d={linesToPath(dottedLines)}
              vectorEffect="non-scaling-stroke"
            />
          </DottedLines>
        </MeasurementLines>

        {
          showAirDraft ?
            <>
              <DiagramLabel x={1} y={26} dy="0.1em">
                Air Draft
              </DiagramLabel>
              <Measurement x={1} y={52}>
                {numberOrMissing(metaAirDraft, unit, 2)}
              </Measurement>
            </>
            : <>
              <DiagramLabel x={-14} y={70} dy="0.1em">
                HOA
              </DiagramLabel>
              <Measurement x={-14} y={97}>
                {numberOrMissing(heightOverall, unit, 2)}
              </Measurement>
            </>
        }

        <DiagramLabel x={291} y={200} dy="0.1em">
          LOA
        </DiagramLabel>
        <Measurement x={291} y={227}>
          {numberOrMissing(lengthOverall, unit, 2)}
        </Measurement>

        <DiagramLabel x={333} y={122} dy="0.1em">
          Max. Draft
        </DiagramLabel>
        <Measurement x={333} y={149}>
          {numberOrMissing(maxDraft, unit, 2)}
        </Measurement>
        {
          showFreeboard &&
          <>
            <DiagramLabel x={333} y={74} dy="0.1em">
              Freeboard
            </DiagramLabel>
            <Measurement x={333} y={101}>
              {numberOrMissing(freeboard, unit, 2)}
            </Measurement>
          </>
        }
      </svg>
    </VesselDiagramContainer>
  )
}

VesselSideDiagram.propTypes = {
  heightOverall: PropTypes.number,
  lengthOverall: PropTypes.number,
  maxDraft: PropTypes.number,
  freeboard: PropTypes.number,
  unit: PropTypes.string,
}

export default VesselSideDiagram
