import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Divider, Typography } from '@material-ui/core'

import InfoBarHeader from 'src/containers/Pilotage/InfoBarHeader'
import {
  isAssignedToActivePilotageSelector,
  pilotageSelector,
  pilotageUuidSelector,
} from 'src/store/pilotage/selectors'
import { isAdminSelector } from 'src/store/auth/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import { timezoneSelector } from 'src/store/ports/selectors'
import SharePilotagePlan from 'src/components/organisms/SharePilotagePlan/SharePilotagePlan'
import PilotageAttachments from 'src/components/organisms/PilotageAttachments'
import useTimestamps from 'src/hooks/useTimestamps'
import SubHeading from 'src/components/atoms/SubHeading'
import PilotageTimestamps from 'src/components/organisms/PilotageTimestamps/PilotageTimestamps'
import {
  ArchivePilotageButton,
  MpxDoneButton,
} from 'src/components/atoms/MpxButton'
import { TickIcon } from 'src/components/atoms/Icons'
import { Checkbox } from 'src/components/atoms/CheckboxRadio'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import idx from 'idx'
import { PILOTAGE_STATUS } from 'src/utils/constants'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import { pilotageExtrasRequest } from 'src/store/pilotageExtras/actions'
import { archivePilotageRequest, undonePilotageRequest } from 'src/store/pilotage/actions'
import ButtonRow from 'src/components/atoms/ButtonRow'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import Modal from 'src/components/molecules/Modal'
import getRouteConstraints from 'src/utils/getRouteConstraints'
import { routeSelector } from 'src/store/route/selectors';
import useRiskAssessment from 'src/hooks/useRiskAssessment'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'

const PositionedRight = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)}px;
`

const canUndonePilotage = (mpxDone: boolean, isArchived: boolean, isCancelled: boolean, routeConstraints: any, riskAssessment: any) => {
  let canUndone: boolean = false
  if (mpxDone && !isArchived && !isCancelled) {
    const now = new Date()
    let constraintAtMs = 0
    if (riskAssessment && routeConstraints && routeConstraints.length > 0) {
      const routeConstraintsLast = routeConstraints[routeConstraints.length - 1]
      const risk = riskAssessment.find((item: any) =>
        item.routeConstraint.uuid === idx(routeConstraintsLast, data => data.uuid)
      )
      if (risk) {
        constraintAtMs = risk.timeAtConstraint.valueOf()
      }
    }
    // the limit to allow change of status is the latest time at constraint + 24hr
    const changeLimit = constraintAtMs + (1000 * 60 * 60 * 24)
    const timeLeft = changeLimit - now.valueOf()
    canUndone = timeLeft > 0
  }
  return canUndone
}

const PilotageAdmin: React.FC = () => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false)
  const dispatch = useDispatch()
  const timezone = useSelector(timezoneSelector)
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const { data: pilotageRawData, isLoading } = useSelector(pilotageSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isAdmin = useSelector(isAdminSelector)
  const isOnline = useOnlineStatus()
  const timestamps = useTimestamps()
  const pilotageRoute = useSelector(routeSelector)
  const routeConstraints = useMemo(() => getRouteConstraints(pilotageRoute), [
    pilotageRoute,
  ])
  const riskAssessment = useRiskAssessment(routeConstraints)

  const pilotageData = pilotageRawData
    ? formatPilotageData(pilotageRawData, timezone)
    : null

  const pilotAccepted = idx(pilotageRawData, d => d.pilotAccepted)
  const masterAccepted = idx(pilotageRawData, d => d.masterAccepted)
  const mpxDone = pilotAccepted && masterAccepted
  const isArchived =
    pilotageRawData && pilotageRawData.status === PILOTAGE_STATUS.ARCHIVED
  const isCancelled =
    pilotageRawData && pilotageRawData.status === PILOTAGE_STATUS.CANCELLED

  useEffect(
    () => {
      if (pilotageUuid) {
        // In case timestamps checklist isn't loaded
        dispatch(pilotageExtrasRequest(pilotageUuid))
      }
    },
    [pilotageUuid]
  )

  const archivePilotage = () => {
    dispatch(archivePilotageRequest(pilotageUuid))
  }

  const undonePilotage = () => {
    if (mpxDone) {
      // recalculate undone on click, so if user stays on page after
      // limit, we will still catch it here.
      const canUndone = canUndonePilotage(mpxDone, isArchived, isCancelled, routeConstraints, riskAssessment)
      if (canUndone) {
        dispatch(undonePilotageRequest(pilotageUuid))
        setIsRevertModalOpen(false)
      }
    }
  }

  const canArchive = isOnline && (isAssigned || isAdmin)
  let canUndone = canUndonePilotage(mpxDone, isArchived, isCancelled, routeConstraints, riskAssessment)

  const timestampsForMovementType = useMemo(() =>
    !pilotageRawData ? [] :
    timestamps.data.filter((item) => {
      const disabledFor = (item as any).disableForMovementTypes || []
      return disabledFor.indexOf(pilotageRawData.movementType) === -1
    }),
    [pilotageRawData && pilotageRawData.movementType, timestamps]
  )

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      <PilotageAttachments />
      <Divider />
      <SharePilotagePlan />
      {timestampsForMovementType.length > 0 && (
        <>
          <Divider />
          <Typography variant="h2">Timestamps (Events)</Typography>
          <SubHeading>Time recordings of events</SubHeading>
          <PilotageTimestamps
            {...timestamps}
            timeZone={timezone}
            disabled={!isAssigned || isArchived || isCancelled}
            data={timestampsForMovementType}
          />
        </>
      )}
      <Divider />
      <InfoBox mt={2} extraPadding>
        <Box display="flex" flexDirection="column" alignItems="center">
          <MpxDoneButton active={mpxDone} canUndone={canUndone}
            onClick={() => canUndone && setIsRevertModalOpen(true)}
          >
            MPX Done
            <PositionedRight>
              {mpxDone ? <TickIcon size={30} /> : <Checkbox disabled />}
            </PositionedRight>
          </MpxDoneButton>
          <ArchivePilotageButton
            active
            onClick={() => setIsArchiveModalOpen(true)}
            disabled={!canArchive || isArchived}
          >
            Archive Pilotage
            <PositionedRight>
              {isArchived ? (
                <TickIcon size={30} />
              ) : (
                <Checkbox disabled={!canArchive} />
              )}
            </PositionedRight>
          </ArchivePilotageButton>
        </Box>
      </InfoBox>
      <Modal
        heading="Archive Pilotage"
        open={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
      >
        <Box mb={4}>
          Are you sure you want to archive this pilotage? This action cannot be
          undone. The pilotage will no longer be visible for all pilots at your
          port.
        </Box>
        <ButtonRow>
          <PrimaryButton disabled={isLoading} onClick={archivePilotage}>
            Archive
          </PrimaryButton>
          <SecondaryButton onClick={() => setIsArchiveModalOpen(false)}>
            Cancel
          </SecondaryButton>
        </ButtonRow>
      </Modal>
      <Modal
        heading="Revert MPX Acceptance"
        open={isRevertModalOpen}
        onClose={() => setIsRevertModalOpen(false)}
      >
        <Box mb={4}>
          You're about to revert the MPX acceptance which means you'd require to go
          through the whole plan again with master, and agree to any changes made.
          Are you sure?
        </Box>
        <ButtonRow>
          <PrimaryButton disabled={isLoading} onClick={undonePilotage}>
            Revert
          </PrimaryButton>
          <SecondaryButton onClick={() => setIsRevertModalOpen(false)}>
            Cancel
          </SecondaryButton>
        </ButtonRow>
      </Modal>
    </>
  )
}

export default PilotageAdmin
