import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useEffect, useState } from 'react'
import isEqual from 'fast-deep-equal'
import {
  createPilotageExtraRequest,
  updatePilotageExtraRequest,
} from 'src/store/pilotageExtras/actions'
import { pilotageRequest } from 'src/store/pilotage/actions'
import { getPilotageExtraSelector } from 'src/store/pilotageExtras/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'

import { ExtraType, Extra } from 'src/types/Extras'
import flags from 'src/utils/flags'

interface UseExtraParams {
  extraType: ExtraType
  successMessage: string
  errorMessage: string
}

export function usePilotageExtra<ExtraMetaData>({
  extraType,
  successMessage,
  errorMessage,
}: UseExtraParams) {
  const pilotageExtra = useSelector(getPilotageExtraSelector(extraType)) as
    | Extra<ExtraMetaData>
    | undefined
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const dispatch = useDispatch()

  const metadata = pilotageExtra ? pilotageExtra.metadata : undefined

  const [isSaving, setIsSaving] = useState(false)
  const [editing, setEditing] = useState<ExtraMetaData | undefined>(metadata)

  useEffect(
    () => {
      const metadata = pilotageExtra ? pilotageExtra.metadata : undefined
      setIsSaving(false)
      setEditing(metadata)
    },
    [pilotageExtra, pilotageUuid]
  )

  const isDirty = useMemo(() => !isEqual(metadata, editing), [
    pilotageExtra,
    editing,
  ])

  const update = setEditing

  const save = async () => {
    if (!isDirty || !editing) {
      return
    }

    setIsSaving(true)

    if (!pilotageExtra) {
      await dispatch(
        createPilotageExtraRequest(
          {
            pilotage: { uuid: pilotageUuid },
            metadata: editing,
            extraType,
          },
          successMessage,
          errorMessage
        )
      )
    } else {
      await dispatch(
        updatePilotageExtraRequest(
          pilotageExtra.uuid,
          {
            pilotage: { uuid: pilotageUuid },
            metadata: editing,
            extraType,
          },
          successMessage,
          errorMessage
        )
      )
    }

    setIsSaving(false)

    if (flags.PLAN_STATUS_UPDATE_ENABLED) {
      // update causes a status update, so refetch the latest from the BE
      dispatch(pilotageRequest(pilotageUuid))
    }
  }

  return {
    metadata: editing,
    save,
    update,
    isSaving,
    isDirty,
  }
}
