import { createSelector } from 'reselect'

export const portsSelector = state => state.ports

export const selectedPortUuidSelector = createSelector(
  portsSelector,
  state => state.selectedUuid
)

export const selectedPortOrgUuidSelector = createSelector(
  portsSelector,
  state => { return { selectedUuid: state.selectedUuid, selectedOrgUuid: state.selectedOrgUuid } }
)

export const selectedPortSelector = createSelector(
  portsSelector,
  state => {
    let port = null
    if (state.selectedUuid) {
      port = state.data.find(item => item.uuid === state.selectedUuid)
    }
    return port
  }
)

export const portIsLoadingSelector = createSelector(
  portsSelector,
  state => state.isLoading
)

export const timezoneSelector = createSelector(
  selectedPortSelector,
  // TODO: remove default once the BE provides default?
  port => (port ? port.timezone : null) || 'Pacific/Auckland'
)
