import routes, { DEFAULT_ROUTE } from './routes'

import browserPlugin from 'router5-plugin-browser'
import createRouter from 'router5'
import { isUserAuthenticated } from './authMiddleware'

export const router = createRouter(routes, {
  defaultRoute: DEFAULT_ROUTE,
})

export default function configureRouter(store) {
  // Plugins
  router.usePlugin(
    browserPlugin({
      useHash: false,
    })
  )

  router.useMiddleware(isUserAuthenticated(store))

  return router
}
