import { Constraint } from 'src/types/Constraint'
import { round } from 'src/utils/formatters'

// mapping is done wherever constraints are selected
// so that stale clients don't break. this is also done on backend 
export function mapLegacySafetyMargin(constraint: Constraint): Constraint {
    const mapped = { ...constraint }

    if (
        typeof constraint.safetyMarginM !== 'number' && 
        typeof constraint.safetyMargin === 'number' && 
        constraint.unit === 'm'
    ) {
        mapped.safetyMarginM = constraint.safetyMargin
    }

    if (
        typeof constraint.safetyMarginPct !== 'number' && 
        typeof constraint.safetyMargin === 'number' && 
        constraint.unit === '%'
    ) {
        mapped.safetyMarginPct = constraint.safetyMargin
    }   
    
    return mapped
}

export function calculateSafetyMargin(
    constraint: Constraint, 
    draft: number
): { 
    calculatedSafetyMargin: number, 
    unitUsed: 'm' | '%' 
} {

    const { safetyMarginM, safetyMarginPct } = constraint
  
    const calculatedM = typeof safetyMarginM === 'number'
      ? round(safetyMarginM, 2)
      : 0
    const calculatedPct = typeof safetyMarginPct === 'number'
      ? round((safetyMarginPct / 100) * draft, 2)
      : 0

    return calculatedPct > calculatedM
        ? ({ calculatedSafetyMargin: calculatedPct, unitUsed: '%' })
        : ({ calculatedSafetyMargin: calculatedM, unitUsed: 'm' })
} 
