import React from 'react'
import styled, { keyframes } from 'styled-components'

import logo from 'src/assets/images/empx-logo.png'

export const glow = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.default};

  img {
    width: 120px;
    animation: ${glow} 2s infinite;
  }
`

const Loading = () => (
  <LoadingWrapper>
    <img src={logo} alt="eMPX" />
  </LoadingWrapper>
)

export default Loading
