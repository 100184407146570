import { SystemModel, UkcPlanModel } from 'dukcapi'
import { getPlanBucketKey, getPlanKey } from 'src/utils/api/omc'
import { OmcAction } from './actions'

export interface OmcState {
  system: SystemModel | null
  systemLastSet: number | null
  loading: { //vesselIMO/pilotageDate/movementType
    [passagePlanKey: string]: boolean 
  }
  planBuckets: { // vesselIMO/fromTime/toTime/movementType
    [passagePlanBucketKey: string]: Array<UkcPlanModel & { lastFetched: number }>
  }
}

export const INITIAL_STATE: OmcState = {
  system: null,
  systemLastSet: null,
  loading: {},
  planBuckets: {}
}

function omcReducer(state = INITIAL_STATE, action: OmcAction): OmcState {
  
  switch (action.type) {
    case 'OMC_SET_SYSTEM': {
      let { payload } = action
      let sameSystem = state.system && 
        payload.system && 
        state.system.name === payload.system.name

      return sameSystem ? state : {
        planBuckets: {},
        loading: {},
        system: payload.system,
        systemLastSet: Date.now()
      }
    }

    case 'OMC_SET_DUKC_PLAN_LOADING': {
      let { payload } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [getPlanKey(payload)]: payload.isLoading
        }
      }
    }

    case 'OMC_SET_DUKC_PLANS': {
      let { payload } = action
      let lastFetched = Date.now()
      return ({
          ...state,
          planBuckets: {
            ...withStaleCleaned(state.planBuckets),
            [getPlanBucketKey(payload)]: payload.plans.map(plan => ({ ...plan, lastFetched }))
          }
      })
    }

    default:
      return state
  }
}

export default omcReducer

// clear out old stored plans so the store doesn't get too big/stale

const MAX_PLAN_AGE_MS = 1000 * 60 * 60 // 1 hour

type OmcPlanBuckets = OmcState['planBuckets']

function withStaleCleaned(
  planBuckets: OmcPlanBuckets
): OmcPlanBuckets {

  const bucketKeys = Object.keys(planBuckets)
  const nextPlanBuckets: OmcPlanBuckets = {}
  const now = Date.now()

  for (const bucketKey of bucketKeys) {
    const nextBucket = planBuckets[bucketKey].filter(plan =>
      plan.lastFetched + MAX_PLAN_AGE_MS >= now
    )
    if (nextBucket.length > 0) {
      nextPlanBuckets[bucketKey] = nextBucket
    }
  }

  return nextPlanBuckets
}
