import React, { useState } from 'react'
import styled from 'styled-components'
import { UkcPlanModel } from 'dukcapi'
import Modal from 'src/components/molecules/Modal'
import IconWarning from '@material-ui/icons/Warning'
import { RadioBtn } from 'src/components/atoms/CheckboxRadio'
import { DateTime } from 'luxon'

const PlanMessage = styled.div`
    background: #20293C;
    border-radius: 8px;
    padding: 15px 20px;
    margin: 20px 0px;
    color: #95A6B6;
    display: flex;
    align-items: center;
    div {
        flex: 1;
        padding-left: 15px;
    }
    span {
        color: ${({ theme }) => theme.palette.primary.main};
        cursor: pointer;
    }
`

const PlanSelect = styled.div`
    color: #fff;
    .plan-variant {
        padding: 10px 0px 10px 0px;
        text-transform: uppercase;
    }
`

const R = <sub style={{fontSize: 8, bottom: '0.75em'}}>®</sub>

interface Props {
    readOnly?: boolean
    active?: number | null
    timeZone: string,
    options: UkcPlanModel[] // assumed to be > 1
    onChange: (active: number) => void
}

const OmcDukcPlanVariantSelector: React.FC<Props> = ({
  readOnly,
  active,
  timeZone,
  options,
  onChange
}) => {

  const [open, setOpen] = useState(false)

  const activePlan = (active &&
        options.find(({ id }) => id === active)) ||
        options[0]

  if (!activePlan) { return null }

  const activePlanName = activePlan.calculationInputs && activePlan.calculationInputs.routeName
  if (!activePlanName) { return null }

  return (
        <>
            <PlanMessage>
              <IconWarning style={{ color: '#FFC94F' }} />
              <div>
                    We’ve found more than one preliminary DUKC{R} plan for this pilotage.
                    We’ve currently defaulted the DUKC{R} plan to <b>{activePlanName}</b>.
                    Please <span onClick={() => setOpen(true)}> click here </span>
                    to switch to another preliminary DUKC{R} plan option.
              </div>
            </PlanMessage>

            <Modal
              open={open}
              onClose={() => setOpen(false)}
              heading={
                    <><span>Select Preliminary DUKC</span>
                    <sub style={{fontSize: 8, bottom: '12px'}}>® </sub>
                    <span> Plan</span></>
              }
            >
              <PlanSelect>
                <p>
                        You are about to change the default preliminary DUKC{R} plan for this pilotage.
                        Chosen DUKC{R} plan will be defaulted for this pilotage and
                        upon sharing eMPX pilotage plan with master.
                </p>
                <div>
                  {
                    options.map((plan) => {
                      let omcTimeFormat
                      if (plan.passagePlan && plan.passagePlan.timeStamp) {
                        const dateLuxon = DateTime.fromISO(plan.passagePlan.timeStamp).setZone(timeZone)
                        omcTimeFormat = dateLuxon.toFormat('ddMMMyyyy HHmm')
                      }
                      return <div key={plan.id} className="plan-variant">
                        <RadioBtn
                          label={plan.calculationInputs && `${plan.calculationInputs.routeName} (${plan.id}.${plan.majorRevision}${omcTimeFormat ? `, ${omcTimeFormat}` : ''})`}
                          checked={active && plan.id === active}
                          disabled={readOnly}
                          onChange={() => {
                            plan.id && onChange(plan.id as number)
                            setOpen(false)
                          }}
                        />
                      </div>
                    }
                    )
                  }
                </div>
              </PlanSelect>
            </Modal>
        </>
  )
}

export default OmcDukcPlanVariantSelector
