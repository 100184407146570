import { createSelector } from 'reselect'
import { MASTERVIEW_TOKEN_ERROR, MASTERVIEW_TOKEN_IN_PROGRESS } from './actions'

export const masterViewState = state => state.masterview

export const masterViewTokenFailed = createSelector(
  masterViewState,
  state => state.status === MASTERVIEW_TOKEN_ERROR
)

export const masterViewTokenLoading = createSelector(
  masterViewState,
  state => state.status === MASTERVIEW_TOKEN_IN_PROGRESS
)

export const masterViewTokenError = createSelector(
  masterViewState,
  state => state.error
)

export const getMasterViewToken = createSelector(
  masterViewState,
  state => state.token
)

export const getMasterViewPilotageUuid = createSelector(
  masterViewState,
  state => state.pilotageUuid
)

export const getMasterViewImageToken = createSelector(
  masterViewState,
  state => state.imageToken
)
