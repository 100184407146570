import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'

export default function useWindowSize() {

    const [windowSize, setWindowSize] = useState<{ width: number | undefined, height: number | undefined }>({
        width: undefined,
        height: undefined,
    });
  
    useEffect(() => {

        const handleResize = debounce(() => { // debounce to prevent vdom thrashing
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }, 100)
        
        window.addEventListener("resize", handleResize)
        
        handleResize()
        
        return () => window.removeEventListener("resize", handleResize)

    }, [])
  
    return windowSize
}
