import React, { useEffect } from 'react'
import { APITypeEnum } from 'src/types/Weather'

const WeatherSource: React.FC<{ source: string }> = ({ source }) => {

    const validSource = Object.values(APITypeEnum).includes(source as APITypeEnum)

    useEffect(() => {
        if (!validSource) {
            console.error(`Invalid weather source: ${source}`)
        }
    }, [source])

    const displaySource = (source === APITypeEnum.MET_OCEAN_V2 || source === APITypeEnum.MET_OCEAN) ? 'MetOcean Solutions' : source

    return (
        validSource &&
        displaySource !== APITypeEnum.NONE ?
        <>Sourced from {displaySource}</> :
        null
    )
}

export default WeatherSource