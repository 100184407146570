import React from 'react'
import styled from 'styled-components'
import { Box, Divider, Typography } from '@material-ui/core'

import { CommentProps } from 'src/components/molecules/ChecklistComment'

const MasterViewCommentText = styled.div(
  ({ theme }) => `
  color: ${theme.palette.text.primary};
`
)

export const ChecklistCommentMasterView: React.FC<CommentProps> = React.memo(
  ({ comment }) =>
    comment ? (
      <>
        <Divider />
        <Typography variant="h4" color="textSecondary" gutterBottom>
          Comment from Pilot
        </Typography>
        <Box mt={2}>
          <MasterViewCommentText>{comment}</MasterViewCommentText>
        </Box>
      </>
    ) : null
)

ChecklistCommentMasterView.displayName = 'ChecklistComment'
