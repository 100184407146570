import {
  CHART_STACK_ACTION,
  CHART_SYMBOL_RENDER_DATA,
  CHART_BASE_WIDTH,
  CHART_BASE_FONT_SIZE,
  CHART_MIN_FONT_SIZE,
  CHART_BASE_HIGHLIGHT_STROKE_WIDTH,
  CHART_MIN_HIGHLIGHT_STROKE_WIDTH,
  CHART_BASE_ARROW_HEAD_SIZE,
  CHART_MIN_ARROW_HEAD_SIZE,
  CHART_BASE_RULER_FONT_SIZE,
} from 'src/utils/drawingConstants'

export function convertActionsStackToShapes(
  actionsStack,
  actionsStackPosition,
  savedShapes,
  excludedShapes = []
) {
  // Exclude any saved shapes which have ADD actions present in the actions stack.
  // This happens when the user makes changes and saves - saved shapes is updated
  // to contain the changes, but they are also still present in the actions stack.
  // Also exclude shapes specifically included in the excluded list.
  const filteredSavedShapes = savedShapes.filter(
    shape =>
      !actionsStack.find(
        ({ name, action }) =>
          name === shape.name && action === CHART_STACK_ACTION.ADD
      ) && !excludedShapes.find(name => name === shape.name)
  )

  // Need to build view representation based on actions stack
  const currentActions =
    actionsStackPosition < 0
      ? actionsStack.slice(0, actionsStackPosition)
      : actionsStack

  let shapes = [...filteredSavedShapes]

  currentActions.forEach(a => {
    const { action, name, type, attrs } = a
    switch (action) {
      case CHART_STACK_ACTION.ADD:
        shapes.push({ type, name, attrs })
        break
      case CHART_STACK_ACTION.MODIFY:
        const shape = shapes.find(s => s.name === name)
        if (!shape) {
          // TODO: Not sure how this error occurs but have seen it a couple of times
          console.error('No shape for stack action', a)
        } else {
          shape.attrs = { ...shape.attrs, ...attrs }
        }
        break
      case CHART_STACK_ACTION.DELETE:
        const index = shapes.findIndex(s => s.name === name)
        // Just in case it doesn't exist, make sure we check
        if (index !== -1) {
          shapes.splice(index, 1)
        }
        break
      case CHART_STACK_ACTION.CLEAR_ALL:
        shapes = []
        break
      default:
        break
    }
  })

  return shapes
}

export function getVesselRotation(
  actionsStack,
  actionsStackPosition,
  savedAngle
) {
  const actions =
    actionsStackPosition < 0
      ? actionsStack.slice(0, actionsStackPosition)
      : actionsStack

  const rotateActions = actions.filter(
    item => item.action === CHART_STACK_ACTION.ROTATE_BERTHING_STAGE_VESSEL
  )

  if (rotateActions.length > 0) {
    return rotateActions[rotateActions.length - 1].attrs.angle
  }
  return savedAngle
}

export function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value))
}

/**
 * Get the render data to render an instance of a symbol on the chart
 * @param variant
 * @returns {*}
 */
export function getSymbolInstanceRenderData(variant) {
  // Other vessels use the same symbol as the regular ship (the different SVG
  // is for the sidebar icon only).
  return variant === CHART_SYMBOL_RENDER_DATA.SHIP.variant
    ? { ...CHART_SYMBOL_RENDER_DATA.SHIP_MAIN, variant }
    : CHART_SYMBOL_RENDER_DATA[variant]
}

/**
 * Get the render data to render the symbol icon in the sidebar
 * @param variant
 * @returns {*}
 */
export function getSymbolIconRenderData(variant) {
  return CHART_SYMBOL_RENDER_DATA[variant]
}

export function canRotateSymbol(variant) {
  return (
    variant === CHART_SYMBOL_RENDER_DATA.SHIP.variant ||
    variant === CHART_SYMBOL_RENDER_DATA.SHIP_MAIN.variant ||
    variant === CHART_SYMBOL_RENDER_DATA.TUG.variant
  )
}

export function canResizeSymbol(variant, isToScale) {
  return (
    variant === CHART_SYMBOL_RENDER_DATA.SHIP.variant ||
    variant === CHART_SYMBOL_RENDER_DATA.TUG.variant
  )
}

export function canDisplayVesselToScale(chartScale, vessel) {
  return (
    typeof chartScale === 'number' &&
    typeof vessel.length === 'number' &&
    typeof vessel.beam === 'number' &&
    chartScale > 0 &&
    vessel.length > 0 &&
    vessel.beam > 0
  )
}

export function calculateMainVesselDimensions(
  chartImageScaleFactor, // factor the chart image is multiplied by to fit in the window
  chartScale, // meters per pixel
  vessel
) {
  return [vessel.length, vessel.beam].map(length =>
    scaleToChart(chartImageScaleFactor, chartScale, length)
  )
}

export function scaleToChart(
  chartImageScaleFactor, // factor the chart image is multiplied by to fit in the window
  chartScale, // meters per pixel
  length
) {
  const scaleFactor = (chartImageScaleFactor || 1) / chartScale
  return length * scaleFactor
}

/**
 * Takes normalised points and converts them to pixel space based on the given
 * dimensions and offset
 * @param points - e.g. [[0.1, 0.2], [0.15, 0.4], ...]
 * @param dims - [width, height]
 * @param offset - [offsetX, offsetY]
 * @returns {Array<number[]>}
 */
export const convertChartPoints = (points, dims, offset) =>
  Array.prototype.concat.apply(
    [],
    points.map(pt => [
      pt[0] * dims[0] + (offset ? offset[0] : 0),
      pt[1] * dims[1] + (offset ? offset[1] : 0),
    ])
  )

export const getSymbolRenderDimensions = ({
  shape,
  selectedShapeName,
  symbolScale,
  isToScale,
  isMasterView,
  chartImage,
  chartScale,
  vessel,
  stageZoom,
  vesselPointerSizeThreshold,
}) => {
  const { name, attrs } = shape

  const isScaling = name === selectedShapeName && symbolScale !== null

  const renderData = getSymbolInstanceRenderData(attrs.variant)
  const { width, height } = renderData

  let scaleX, scaleY

  const isMainVessel =
    attrs.variant === CHART_SYMBOL_RENDER_DATA.SHIP_MAIN.variant

  let symbolDimensions

  if (isToScale && isMainVessel) {
    // Need to take the chart image scale factor into account too
    // (how much the chart is shrunk/grown to fit the available screen space)
    symbolDimensions = calculateMainVesselDimensions(
      chartImage.fullSizeScaleFactor || 1,
      chartScale,
      vessel
    )
    scaleX = symbolDimensions[0] / width
    scaleY = symbolDimensions[1] / height
  } else {
    scaleX = isScaling ? symbolScale : (attrs.scale && attrs.scale[0]) || 1
    scaleY = isScaling ? symbolScale : (attrs.scale && attrs.scale[1]) || 1

    // scaling tweaks for symbols requested in https://masterpilotexchange.atlassian.net/browse/EMPX-533
    switch (attrs.variant) {
      // make our vessel symbol closer to 1:6 beam:loa ratio
      case CHART_SYMBOL_RENDER_DATA.SHIP.variant:
        scaleY *= 0.8 
        break
      // reduce other symbols
      case CHART_SYMBOL_RENDER_DATA.LADDER.variant:
      case CHART_SYMBOL_RENDER_DATA.PROPELLOR.variant:
      case CHART_SYMBOL_RENDER_DATA.HAZARD.variant:
        scaleX *= 0.5
        scaleY *= 0.5
        break
      default:
        break
    }

    if (chartImage.scaleFactor) {
      scaleX *= chartImage.scaleFactor
      scaleY *= chartImage.scaleFactor
    }

    symbolDimensions = [width * scaleX, height * scaleY]
  }

  const minVisibleDimension =
    Math.min(symbolDimensions[0], symbolDimensions[1]) * stageZoom

  const maxVisibleDimension =
    Math.max(symbolDimensions[0], symbolDimensions[1]) * stageZoom

  // Symbol padding is added such that a minimum size area around the
  // symbol can still be interacted with. This is important to ensure
  // tiny symbols are not very hard to select or manipulate.
  const symbolPadding = Math.max(0, 20 - minVisibleDimension) / stageZoom

  // Only show the vessel pointer below a certain size threshold
  const displayVesselPointer =
    !isMasterView &&
    isMainVessel && 
    maxVisibleDimension < vesselPointerSizeThreshold

  // If the vessel pointer will be shown set the radius to 75% of
  // the maximum visible dimension plus the symbol padding.
  const vesselPointerRadius = displayVesselPointer
    ? 0.75 * maxVisibleDimension + symbolPadding
    : 0

  return {
    maxVisibleDimension,
    vesselPointerRadius,
    scaleX,
    scaleY,
    symbolPadding,
  }
}

export const makeGetScaled = (
  propertyBaseWidth,
  propertyMinWidth
) => chartWidth => {
  const basePercent = propertyBaseWidth / CHART_BASE_WIDTH
  const scaled = Math.floor(chartWidth * basePercent)
  return scaled < propertyMinWidth ? propertyMinWidth : scaled
}
export const getScaledRulerFontSize = makeGetScaled(
  CHART_BASE_RULER_FONT_SIZE,
  CHART_MIN_FONT_SIZE
)
export const getScaledFontSize = makeGetScaled(
  CHART_BASE_FONT_SIZE,
  CHART_MIN_FONT_SIZE
)
export const getScaledStrokeWidth = makeGetScaled(
  CHART_BASE_HIGHLIGHT_STROKE_WIDTH,
  CHART_MIN_HIGHLIGHT_STROKE_WIDTH
)
export const getScaledArrowHeadSize = makeGetScaled(
  CHART_BASE_ARROW_HEAD_SIZE,
  CHART_MIN_ARROW_HEAD_SIZE
)
