import isEqual from 'fast-deep-equal'
import { pilotageDataToFormValues } from 'src/utils/pilotageDataFormConverters'

// Determines if the pilotage form state differs only from the saved pilotage by
// the assignee.
export const isPilotOnlyChanged = (formState, pilotage, timeZone) => {
  if (!pilotage || !formState) {
    return false
  }

  const isPilotChanged =
    (!!pilotage.pilot && !formState.pilot) ||
    (!pilotage.pilot && !!formState.pilot) ||
    pilotage.pilot.uuid !== formState.pilot.uuid

  if (!isPilotChanged) {
    return false
  }

  const prevWithoutPilots = {
    ...pilotageDataToFormValues(pilotage, timeZone),
    pilot: null,
    pilotSecond: null,
  }
  const currWithoutPilots = { ...formState, pilot: null, pilotSecond: null }

  return isEqual(prevWithoutPilots, currWithoutPilots)
}
