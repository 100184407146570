import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  pilotageSyncStatusByUuidSelector,
  getStatusByUuid,
} from 'src/store/sync/selectors'
import { SYNC_STATUS } from 'src/utils/constants'
import PrefetchInProgressIcon from 'src/components/molecules/PrefetchInProgressIcon'
import PrefetchSuccessIcon from 'src/components/atoms/PrefetchSuccessIcon'
import SyncErrorIcon from 'src/components/atoms/SyncErrorIcon/SyncErrorIcon'
import SyncPendingIcon from 'src/components/atoms/SyncPendingIcon/SyncPendingIcon'
import SyncUnknownIcon from 'src/components/atoms/SyncUnknownIcon'

export const SyncStatusIcon = ({ pilotageUuid, uuid, ...props }) => {
  const status = useSelector(
    pilotageUuid
      ? pilotageSyncStatusByUuidSelector(pilotageUuid)
      : getStatusByUuid(uuid)
  )
  let SyncStatusIndicatorButton
  switch (status) {
    case SYNC_STATUS.IN_PROGRESS:
      SyncStatusIndicatorButton = PrefetchInProgressIcon
      break
    case SYNC_STATUS.SUCCESS:
      SyncStatusIndicatorButton = PrefetchSuccessIcon
      break
    case SYNC_STATUS.PENDING:
      SyncStatusIndicatorButton = SyncPendingIcon
      break
    case SYNC_STATUS.ERROR:
      SyncStatusIndicatorButton = SyncErrorIcon
      break
    default:
      SyncStatusIndicatorButton = SyncUnknownIcon
  }

  return <SyncStatusIndicatorButton fontSize="small" {...props} />
}
SyncStatusIcon.propTypes = {
  pilotageUuid: PropTypes.string,
  uuid: PropTypes.string,
}

export default SyncStatusIcon
