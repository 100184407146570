import React from 'react'
import Attachments from 'src/components/organisms/Attachments/Attachments'
import {
  ATTACHMENT_ACCEPT,
  ATTACHMENT_LIMIT,
  ATTACHMENT_SIZE_LIMIT,
  ATTACHMENT_TYPE,
} from 'src/components/organisms/Attachments/constants'
import InfoBox from 'src/components/atoms/InfoBox/InfoBox'
import { deleteAttachment, uploadAttachment } from 'src/utils/api/attachments'
import {
  pilotageAttachmentsUpdated,
  pilotageRequest,
} from 'src/store/pilotage/actions'
import useAttachmentsRefresh from 'src/hooks/useAttachmentsRefresh'
import { useDispatch, useSelector } from 'react-redux'
import {
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
  pilotageAttachmentsSelector,
  pilotageUuidSelector,
} from 'src/store/pilotage/selectors'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import SubHeading from 'src/components/atoms/SubHeading'
import { Typography } from '@material-ui/core'
import flags from 'src/utils/flags'

const PilotageAttachments: React.FC = () => {
  const dispatch = useDispatch()
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const attachments = useSelector(pilotageAttachmentsSelector)
  const isOnline = useOnlineStatus()

  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled

  const onDeleteAttachment = (attachment: any) =>
    deleteAttachment(pilotageUuid, attachment)

  const onAttachmentSuccess = ({ attachments }: { attachments: any[] }) => {
    dispatch(pilotageAttachmentsUpdated(attachments))
    if (flags.PLAN_STATUS_UPDATE_ENABLED) {
      // update causes a status update, so refetch the latest from the BE
      dispatch(pilotageRequest(pilotageUuid))
    }
  }

  useAttachmentsRefresh(pilotageUuid)

  const onUploadAttachment = (file: File) =>
    uploadAttachment(pilotageUuid, ATTACHMENT_TYPE.ADDITIONAL, file)

  // Known bug on iOS safari <=12 with accept attribute: https://openradar.appspot.com/36726477
  const setAcceptAttribute = !(
    /(iPhone|iPod|iPad)/i.test(navigator.userAgent) &&
    /OS ([1-9]|1[0-2])(.*) like Mac OS X/i.test(navigator.userAgent)
  )

  const attachmentFilter = (file: File) => {
    const ext = file.name.split('.').pop()
    return !!ext && ['jpg', 'jpeg', 'png', 'pdf'].includes(ext.toLowerCase())
  }

  return (
    <>
      <Typography variant="h2">Attachments</Typography>
      <SubHeading>
        Attach any relevant file based information such as Weather, Dynamic UKC,
        Tide etc. here. Accepted files types are PDF, PNG and JPG. Keep file
        size to a minimum - less than 500KB.
      </SubHeading>
      <InfoBox extraPadding mt={3}>
        <Attachments
          accept={setAcceptAttribute ? ATTACHMENT_ACCEPT : null}
          attachmentFilter={attachmentFilter}
          limit={ATTACHMENT_LIMIT}
          maxSize={ATTACHMENT_SIZE_LIMIT}
          savedAttachments={attachments}
          addEnabled={isOnline}
          readOnly={isReadOnly}
          upload={onUploadAttachment}
          remove={onDeleteAttachment}
          onSuccess={onAttachmentSuccess}
        />
      </InfoBox>
    </>
  )
}

export default PilotageAttachments
