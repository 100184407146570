import styled from 'styled-components'
import { Button } from '@material-ui/core'

const SmallButton = styled(Button)(
  ({ theme }) => `
  && {
    background-color: ${theme.palette.background.xlight};
    font-size: ${theme.typography.pxToRem(11)};
    font-weight: ${theme.weights.fontWeightRegular};
    border-radius: 8px;
    padding: 4px 16px;
    color: ${theme.palette.text.primary};
    min-height: auto;
  }
`
)

export default SmallButton
