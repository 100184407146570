import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { createPilotageExtra, updatePilotageExtra } from 'src/utils/api/extras'
import { getPilotageExtras } from 'src/utils/api/pilotages'
import { getPayloadFromResponse } from 'src/utils/api/core'

export const sync = async syncItem => {
  try {
    const {
      entity: { data, pilotageUuid },
    } = syncItem
    // EMPX-97: we make calls sequentially for now, then optimise
    // parallelism later.
    const callSequentially = true
    if (!callSequentially) {
      await Promise.all(
        data.map(extraData => {
          const { isNew, ...extra } = extraData
          return isNew
            ? createPilotageExtra(extra)
            : updatePilotageExtra(extra.uuid, extra)
        })
      )
    } else {
      for (let i = 0; i < data.length; i++) {
        const extraData = data[i]
        const { isNew, ...extra } = extraData
        if (isNew) {
          await createPilotageExtra(extra)
        } else {
          await updatePilotageExtra(extra.uuid, extra)
        }
      }
    }
    const response = await getPilotageExtras(pilotageUuid)
    const result = getPayloadFromResponse(response)
    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createSyncUuid = pilotageUuid =>
  `${pilotageUuid}-${SYNC_ENTITY_TYPES.PILOTAGE_EXTRAS}`

export default sync
