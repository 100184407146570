import React from 'react'
import ReloadBadge from 'src/components/molecules/ReloadBadge'
import OfflineIcon from 'src/components/atoms/OfflineIcon'

export const PrefetchErrorIcon = props => (
  <ReloadBadge>
    <OfflineIcon {...props} />
  </ReloadBadge>
)

export default PrefetchErrorIcon
