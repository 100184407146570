import {
  PILOTAGE_LIST_IN_PROGRESS,
  PILOTAGE_LIST_ERROR,
  PILOTAGE_LIST_SUCCESS,
  PILOTAGE_LIST_SET_FILTER,
  PILOTAGE_LIST_SET_ASCENDING
} from 'src/store/pilotageList/actions'
import { PILOTAGE_SUCCESS } from 'src/store/pilotage/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  lastLoadedPortUuid: '',
  error: null,
  status: null,
  filter: null,
  ascending: true,
}

function pilotageListReducer (state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case PILOTAGE_LIST_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
        status: type,
      }
    case PILOTAGE_LIST_ERROR:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
        status: type,
      }
    case PILOTAGE_LIST_SUCCESS:
      return {
        ...state,
        data: payload.data,
        lastLoadedPortUuid: payload.portUuid,
        isLoading: false,
        error: null,
        status: type,
      }
    case PILOTAGE_SUCCESS: {
      const data = (state.data || []).map(pilotage => {
        return pilotage.uuid === payload.uuid
          ? {
            ...pilotage,
            ...payload,
          }
          : pilotage
      })

      return {
        ...state,
        data,
      }
    }
    case PILOTAGE_LIST_SET_FILTER: {
      return {
        ...state,
        filter: action.filter
      }
    }
    case PILOTAGE_LIST_SET_ASCENDING: {
      return {
        ...state,
        ascending: action.ascending
      }
    }
    default:
      return state
  }
}

export default pilotageListReducer
