import allSettled from 'promise.allsettled'

import { SYNC_STATUS, SYNC_ENTITY_TYPES } from 'src/utils/constants'
import {
  syncInProgressSelector,
  entitiesToSyncSelector,
} from 'src/store/sync/selectors'
import syncHandlers from 'src/utils/sync'

export class MissingEntityUuid extends Error {}
export class PilotageSyncError extends Error {}
export class PartialSyncError extends Error {}

export const SYNC_ADD_ENTITY = 'SYNC_ADD_ENTITY'
export const addEntity = syncEntity => dispatch => {
  const { entity } = syncEntity
  if (!entity.uuid) {
    throw new MissingEntityUuid('No uuid for entity queued to sync')
  }

  dispatch({
    type: SYNC_ADD_ENTITY,
    payload: syncEntity,
  })
}

export const SYNC_START = 'SYNC_START'
export const startSync = () => async (dispatch, getState) => {
  const state = getState()

  const inProgress = syncInProgressSelector(state)

  if (inProgress) {
    return false
  }

  dispatch(syncInProgress())
  try {
    const itemsToSync = entitiesToSyncSelector(state)

    const uuids = Object.keys(itemsToSync)

    // Run the pilotages sync first as they are/can be the dependencies
    // for the other entities
    const pilotagesToSync = uuids.reduce((items, uuid) => {
      const itemToSync = itemsToSync[uuid]
      if (
        itemToSync &&
        itemToSync.type === SYNC_ENTITY_TYPES.PILOTAGE &&
        (itemToSync.status === SYNC_STATUS.PENDING ||
          itemToSync.status === SYNC_STATUS.ERROR)
      ) {
        return [...items, itemToSync]
      }
      return items
    }, [])
    // SYNC pilotages
    // EMPX-97: we make calls sequentially for now, then optimise
    // parallelism later.
    const callSequentially = true
    let pilotageResults = []
    if (!callSequentially) {
      pilotageResults = await allSettled(
        pilotagesToSync.map(syncItem => dispatch(syncEntity(syncItem)))
      )
    } else {
      for (let i = 0; i < pilotagesToSync.length; i++) {
        const syncItem = pilotagesToSync[i]
        const pilotageResult = await dispatch(syncEntity(syncItem))
        pilotageResults.push(pilotageResult)
      }
    }

    const failedPilotageSyncUuids = pilotageResults.reduce(
      (uuids, promiseState) => {
        const { value: result, status } = promiseState
        if (status === 'rejected' && result) {
          return [...uuids, result.uuid]
        }
        return uuids
      },
      []
    )

    // Find all the non-pilotage items
    const nonPilotageItemsToSync = uuids.reduce((items, uuid) => {
      const itemToSync = itemsToSync[uuid]
      // only those which are not syncing or synced yet
      if (
        itemToSync &&
        itemToSync.type !== SYNC_ENTITY_TYPES.PILOTAGE &&
        (itemToSync.status === SYNC_STATUS.PENDING ||
          itemToSync.status === SYNC_STATUS.ERROR)
      ) {
        // we mark as failed sync for the sync item if
        // the pilotage dependency is failed
        if (
          itemToSync.isNew && // the item is new
          failedPilotageSyncUuids.length && // we have failed pilotages syncs
          itemToSync.dependencies && // the item has dependencies
          itemToSync.dependencies[SYNC_ENTITY_TYPES.PILOTAGE] && // the item has pilotage dependency (this will be the uuid of the pilotage)
          failedPilotageSyncUuids.includes(
            // the dependent pilotage is failed
            itemToSync.dependencies[SYNC_ENTITY_TYPES.PILOTAGE]
          )
        ) {
          dispatch(
            entitySyncError(
              itemToSync.uuid,
              new PilotageSyncError(
                `Pilotage sync error: ${
                  itemToSync.dependencies[SYNC_ENTITY_TYPES.PILOTAGE]
                }`
              )
            )
          )
          return items
        }

        return [...items, itemToSync]
      }
      return items
    }, [])

    let nonPilotageResults = []
    if (!callSequentially) {
      nonPilotageResults = await allSettled(
        nonPilotageItemsToSync.map(syncItem => dispatch(syncEntity(syncItem)))
      )
    } else {
      for (let i = 0; i < nonPilotageItemsToSync.length; i++) {
        const syncItem = nonPilotageItemsToSync[i]
        const pilotageResult = await dispatch(syncEntity(syncItem))
        nonPilotageResults.push(pilotageResult)
      }
    }

    const failedNonPilotageSyncUuids = nonPilotageResults.reduce(
      (uuids, result) => {
        if (result.error) {
          return [...uuids, result.uuid]
        }
        return uuids
      },
      []
    )

    if (
      failedPilotageSyncUuids.length > 0 ||
      failedNonPilotageSyncUuids.length > 0
    ) {
      throw new PartialSyncError('Partial sync')
    }
    if (
      [...pilotageResults, ...nonPilotageResults].find(
        result => result.status === 'rejected'
      )
    ) {
      throw new Error('Partial sync')
    }
    return dispatch(syncSuccess([...pilotageResults, ...nonPilotageResults]))
  } catch (error) {
    return dispatch(syncError(error))
  }
}

export const SYNC_IN_PROGRESS = 'SYNC_IN_PROGRESS'
export const syncInProgress = () => ({
  type: SYNC_IN_PROGRESS,
  payload: new Date(),
})

export const SYNC_SUCCESS = 'SYNC_SUCCESS'
export const syncSuccess = result => ({
  type: SYNC_SUCCESS,
  payload: new Date(),
  result,
})

export const SYNC_ERROR = 'SYNC_ERROR'
export const syncError = error => ({
  type: SYNC_ERROR,
  payload: new Date(),
  error,
})

export const SYNC_ENTITY = 'SYNC_ENTITY'
export const syncEntity = syncItem => async (dispatch, getState) => {
  const { type, status } = syncItem
  if (status === SYNC_STATUS.IN_PROGRESS) {
    return false
  }

  dispatch(entitySyncInProgress(syncItem))
  try {
    let request = syncHandlers[type]

    if (!request) {
      throw new Error(`Unknown sync entity type: ${type}`)
    }

    const result = await request(syncItem, dispatch, getState)

    dispatch(entitySyncSuccess(syncItem, result))
    return Promise.resolve(result)
  } catch (error) {
    dispatch(entitySyncError(syncItem, error))
    return Promise.reject(error)
  }
}

export const SYNC_ENTITY_IN_PROGRESS = 'SYNC_ENTITY_IN_PROGRESS'
export const entitySyncInProgress = payload => ({
  type: SYNC_ENTITY_IN_PROGRESS,
  payload,
})

export const SYNC_ENTITY_SUCCESS = 'SYNC_ENTITY_SUCCESS'
export const entitySyncSuccess = (payload, result) => ({
  type: SYNC_ENTITY_SUCCESS,
  payload,
  result,
})

export const SYNC_ENTITY_ERROR = 'SYNC_ENTITY_ERROR'
export const entitySyncError = (payload, error) => ({
  type: SYNC_ENTITY_ERROR,
  payload,
  error,
})

export const SYNC_RESET = 'SYNC_RESET'
export const resetSync = () => {
  return {
    type: SYNC_RESET,
  }
}
