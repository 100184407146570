export const CANVAS_RESET = 'CANVAS_RESET'

export const CANVAS_ADD_STACK_ACTION = 'CANVAS_ADD_STACK_ACTION'
export const CANVAS_UNDO = 'CANVAS_UNDO'
export const CANVAS_REDO = 'CANVAS_REDO'
export const CANVAS_SELECT_SHAPE = 'CANVAS_SELECT_SHAPE'
export const CANVAS_SET_STAGE_DIMENSIONS = 'CANVAS_SET_STAGE_DIMENSIONS'
export const CANVAS_SET_STAGE_OFFSET = 'CANVAS_SET_STAGE_OFFSET'
export const CANVAS_SET_STAGE_ZOOM = 'CANVAS_SET_STAGE_ZOOM'
export const CANVAS_DISPLAY_ADD_LABEL = 'CANVAS_DISPLAY_ADD_LABEL'
export const CANVAS_REMOVE_ADD_LABEL = 'CANVAS_REMOVE_ADD_LABEL'
export const CANVAS_SET_UNSAVED_CHANGES = 'CANVAS_SET_UNSAVED_CHANGES'

export const canvasReset = stageUuid => ({
  type: CANVAS_RESET,
  payload: { stageUuid },
})

export const canvasUndo = stageUuid => ({
  type: CANVAS_UNDO,
  payload: { stageUuid },
})

export const canvasRedo = stageUuid => ({
  type: CANVAS_REDO,
  payload: { stageUuid },
})

export const selectShape = (stageUuid, shapeName) => ({
  type: CANVAS_SELECT_SHAPE,
  payload: { stageUuid, shapeName },
})

export const addStackAction = (stageUuid, action, name, type, attrs) => ({
  type: CANVAS_ADD_STACK_ACTION,
  payload: {
    stageUuid,
    action,
    name,
    type,
    attrs,
  },
})

export const setStageDimensions = (stageUuid, dims) => ({
  type: CANVAS_SET_STAGE_DIMENSIONS,
  payload: { stageUuid, dims },
})

export const setStageOffset = (stageUuid, offset) => ({
  type: CANVAS_SET_STAGE_OFFSET,
  payload: { stageUuid, offset },
})

export const setStageZoom = (stageUuid, zoom) => ({
  type: CANVAS_SET_STAGE_ZOOM,
  payload: { stageUuid, zoom },
})

export const displayAddLabel = (stageUuid, name, text, x, y) => ({
  type: CANVAS_DISPLAY_ADD_LABEL,
  payload: { stageUuid, name, text, x, y },
})

export const removeAddLabel = stageUuid => ({
  type: CANVAS_REMOVE_ADD_LABEL,
  payload: { stageUuid },
})

export const setUnsavedChanges = (stageUuid, hasUnsavedChanges) => ({
  type: CANVAS_SET_UNSAVED_CHANGES,
  payload: { stageUuid, hasUnsavedChanges },
})
