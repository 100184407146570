import React, { ReactElement } from 'react'
import { PilotageWeatherProps, PilotageWeatherInputs } from './types'
import usePilotageWeather from './usePilotageWeather'

export const PilotageWeatherDataLoader: React.FC<
    PilotageWeatherInputs & {
        children: (pilotageWeatherData: PilotageWeatherProps) => ReactElement
    }
> = (props) => {
    const pilotageWeatherData = usePilotageWeather(props) 

    return (
        props.pilotage && props.riskAssessment ?
        props.children(pilotageWeatherData) :
        null
    )
}

export default PilotageWeatherDataLoader
