import {
  ROUTE_IN_PROGRESS,
  ROUTE_ERROR,
  ROUTE_SUCCESS,
} from 'src/store/route/actions'
import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function routeReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case ROUTE_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case ROUTE_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case ROUTE_SUCCESS:
      return {
        ...state,
        data: {
          ...payload,
          routeConstraints: payload.routeConstraints.sort((r1, r2) => r1.order - r2.order)
        },
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default routeReducer
