import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { getVesselByIMO, lookupVesselsByName } from 'src/utils/api/vessels'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const VESSEL_LOOKUP_BASE = 'VESSEL_LOOKUP'

export const [
  VESSEL_LOOKUP_REQUEST,
  VESSEL_LOOKUP_SUCCESS,
  VESSEL_LOOKUP_ERROR,
  VESSEL_LOOKUP_CANCEL,
  VESSEL_LOOKUP_IN_PROGRESS,
] = createBasicActionTypes(VESSEL_LOOKUP_BASE)

export const SELECT_VESSEL = 'SELECT_VESSEL'

export const vesselLookupRequestByIMO = (portUuid, imo) => async dispatch => {
  dispatch(vesselLookupInProgress())

  try {
    // can specify null for portUuid, API can determine the port from the user identity
    const response = await getVesselByIMO(portUuid, imo)
    const data = getPayloadFromResponse(response)
    dispatch(vesselLookupSuccess({ data, searchText: imo }))
    return data
  } catch (error) {
    dispatch(vesselLookupError(error))
  }
}

export const vesselLookupRequestByName = (portUuid, name) => async dispatch => {
  dispatch(vesselLookupInProgress())

  try {
    const response = await lookupVesselsByName(portUuid, name)
    const data = getPayloadFromResponse(response)
    dispatch(vesselLookupSuccess({ data, searchText: name }))
    return data
  } catch (error) {
    dispatch(vesselLookupError(error))
  }
}

export const vesselLookupInProgress = () =>
  createInProgressAction(VESSEL_LOOKUP_BASE)

export const vesselLookupSuccess = data =>
  createSuccessAction(VESSEL_LOOKUP_BASE, data)

export const vesselLookupError = error => dispatch => {
  dispatch(createErrorAction(VESSEL_LOOKUP_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.VESSEL_LOOKUP_ERROR,
    })
  )
}

export const clearVesselLookupResults = () =>
  vesselLookupSuccess({
    data: null,
    searchText: null,
    error: null,
  })

export const selectVessel = vessel => ({
  type: SELECT_VESSEL,
  payload: vessel,
})
