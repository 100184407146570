import { Box, Grid } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as routerActions } from 'redux-router5'
import containerShipImage from 'src/assets/images/container-ship.jpg'
import AppUpdateBanner from 'src/components/atoms/AppUpdateBanner'
import AuthSubmitButton from 'src/components/atoms/AuthSubmitButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import TextInput from 'src/components/atoms/TextInput'
import EmpxLogo from 'src/components/organisms/LoginLayout/EmpxLogo'
import LoginLayout from 'src/components/organisms/LoginLayout/LoginLayout'
import LoginPaneSection, {
  SmallLoginPaneSection,
} from 'src/components/organisms/LoginLayout/LoginPaneSection'
import { PILOTAGES } from 'src/router/routes'
import { requestLogin, requestLogout } from 'src/store/auth/actions'
import { usernameSelector } from 'src/store/auth/selectors'
import loginValidator from 'src/utils/validators/loginValidator'

const Locked = () => {
  const dispatch = useDispatch()
  const username = useSelector(usernameSelector)

  const logOut = () => {
    dispatch(requestLogout())
  }

  const loggedInEmail = localStorage.getItem('loggedInEmail')

  const loginPaneContent = useMemo(
    () => (
      <>
        <SmallLoginPaneSection align="center">
          <EmpxLogo />
        </SmallLoginPaneSection>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
            Session for {username} has timed out. Please enter your password to unlock the app.
        </div>
        <LoginPaneSection align="center">
          <Formik
            initialValues={{ email: loggedInEmail, password: '' }}
            validate={loginValidator}
            onSubmit={(values, actions) => {
              dispatch(requestLogin(values.email, values.password)).then(
                result => {
                  if (result) {
                    dispatch(routerActions.navigateTo(PILOTAGES))
                  } else {
                    actions.setSubmitting(false)
                  }
                }
              )
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      render={({ field }) => (
                        <TextInput
                          id="email"
                          type="email"
                          placeholder="Your email"
                          label="Email Address"
                          readOnly
                          formikField={field}
                          error={touched.email && errors.email}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      render={({ field }) => (
                        <TextInput
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Your password"
                          label="Password"
                          formikField={field}
                          error={touched.password && errors.password}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <AuthSubmitButton disabled={isSubmitting}>
                  Log In
                </AuthSubmitButton>
              </Form>
            )}
          </Formik>
          <PrimaryButton style={{ marginTop: 10, width: '100%' }} onClick={logOut}>
            Log Out
          </PrimaryButton>
        </LoginPaneSection>
      </>
    ),
    []
  )

  return (
    <>
      <Box position="absolute" top={0} left={0} right={0} zIndex={10}>
        <AppUpdateBanner />
      </Box>
      <LoginLayout
        loginPaneContent={loginPaneContent}
        imageSrc={containerShipImage}
      />
    </>
  )
}

export default Locked
