import idx from 'idx'
import allSettled from 'promise.allsettled'
import { createBasicActionTypes } from 'src/utils/createAction'
import { pilotsRequest } from 'src/store/pilots/actions'
import { berthsStationsRequest } from 'src/store/berthsStations/actions'
import { tugsRequest } from 'src/store/tugs/actions'
import { tidesRequest } from 'src/store/tides/actions'
import { omcLoadDukcPlan } from 'src/store/omc/actions'
import { vesselRequest } from 'src/store/vessel/actions'
import { weatherRequest } from 'src/store/weather/actions'
import { pilotageListRequest } from 'src/store/pilotageList/actions'
import { routeRequest } from 'src/store/route/actions'
import { pilotageTugsRequest } from 'src/store/pilotageTugs/actions'
import { pilotageThreadRequest } from 'src/store/pilotageThread/actions'
import { pilotageExtrasRequest } from 'src/store/pilotageExtras/actions'
import { pilotageListSelector } from 'src/store/pilotageList/selectors'
import {
  isOnlineSelector,
  isServiceWorkerAvailableSelector,
} from 'src/store/ui/selectors'

import {
  commonsPrefetchStatusSelector,
  pilotagePrefetchIsInProgressSelector,
  pilotagePrefetchIsSuccessSelector,
  pilotagePrefetchByUuidSelector,
} from 'src/store/prefetch/selectors'

import { extrasRequest } from 'src/store/extras/actions'
import { imageTokenSelector } from 'src/store/auth/selectors'
import getChartImageUrl from 'src/utils/getChartImageUrl'
import { loadDrawings } from 'src/store/drawings/actions'
import { TIDE_TYPE } from 'src/utils/constants'
import { tideExtremesRequest } from 'src/store/tideExtremes/actions'
import { pilotageSignaturesRequest } from '../pilotageSignature/actions'
import { weatherLocationsRequest } from '../weatherLocations/actions'
import { tideRatesRequest } from '../tideRates/actions'

const canPrefetch = state =>
  isOnlineSelector(state) && isServiceWorkerAvailableSelector(state)

// PILOTAGES
const PREFETCH_PILOTAGE_BASE = 'PREFETCH_PILOTAGE'
export const [
  PREFETCH_PILOTAGE_REQUEST,
  PREFETCH_PILOTAGE_SUCCESS,
  PREFETCH_PILOTAGE_ERROR,
  PREFETCH_PILOTAGE_CANCEL,
  PREFETCH_PILOTAGE_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_PILOTAGE_BASE)

export const prefetchTugs = (pilotageUuid, updateCacheIfPrefetched) => async (
  dispatch,
  getState
) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotageUuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(
    pilotageTugsRequest(pilotageUuid, { isPrefetch: true })
  ))
}

export const prefetchDrawings = (
  pilotageUuid,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotageUuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(loadDrawings(pilotageUuid, { isPrefetch: true })))
}

export const prefetchPilotageExtras = (
  pilotageUuid,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotageUuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(
    pilotageExtrasRequest(pilotageUuid, { isPrefetch: true })
  ))
}

export const prefetchPilotageThread = (
  pilotageUuid,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotageUuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(
    pilotageThreadRequest(pilotageUuid, { isPrefetch: true })
  ))
}

export const prefetchPilotageWeather = (
  pilotage,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotage.uuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(weatherRequest(pilotage, { isPrefetch: true })))
}

export const prefetchPilotageSignatures = (
  pilotage,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (
    updateCacheIfPrefetched &&
    !pilotagePrefetchByUuidSelector(pilotage.uuid)(getState())
  ) {
    return false
  }
  return !!(await dispatch(pilotageSignaturesRequest(pilotage.uuid, { isPrefetch: true })))
}

export const prefetchVessel = (
  portUuid,
  vesselIMO,
  updateCacheIfPrefetched
) => async (dispatch, getState) => {
  if (updateCacheIfPrefetched && !pilotagePrefetchByUuidSelector(getState())) {
    return false
  }
  return !!(await dispatch(
    vesselRequest(portUuid, vesselIMO, { isPrefetch: true })
  ))
}

export const prefetchPilotageOmc = (
  vesselIMO,
  pilotageDate,
  movementType,
  portUuid,
  updateCacheIfPrefetched,
) => async (dispatch, getState) => {

  if (updateCacheIfPrefetched && !pilotagePrefetchByUuidSelector(getState())) {
    return false
  }

  return !!(await dispatch(omcLoadDukcPlan(vesselIMO, pilotageDate, movementType, portUuid)))
}

export const prefetchPilotage = (pilotage, force) => async (
  dispatch,
  getState
) => {
  const state = getState()
  if (!canPrefetch(state)) {
    return false
  }
  const prefetchStatus = pilotagePrefetchByUuidSelector(pilotage.uuid)(state) || {}

  const {
    status,
    charts,
    tugs,
    drawings,
    vessel,
    route,
    extras,
    weather,
    omc,
    signatures
  } = prefetchStatus

  const { uuid } = pilotage

  try {
    if (
      [PREFETCH_PILOTAGE_IN_PROGRESS, PREFETCH_PILOTAGE_SUCCESS].includes(
        status
      )
    ) {
      return Promise.resolve()
    }
    dispatch({
      type: PREFETCH_PILOTAGE_IN_PROGRESS,
      payload: {
        ...prefetchStatus,
        uuid,
        status: PREFETCH_PILOTAGE_IN_PROGRESS,
      },
    })

    // tugs
    if (force || !tugs) {
      prefetchStatus.tugs = !!(await dispatch(prefetchTugs(uuid)))
    }

    // drawings
    if (force || !drawings) {
      prefetchStatus.drawings = !!(await dispatch(prefetchDrawings(uuid)))
    }

    // vessel
    if (force || !vessel) {
      const vesselIMO = idx(pilotage, _ => _.vessel.IMO)
      const vesselResult = vesselIMO
        ? !!(await dispatch(prefetchVessel(pilotage.port.uuid, vesselIMO)))
        : true
      prefetchStatus.vessel = !!vesselResult
    }

    // route
    if (force || !route || !charts) {
      const routeUUID = idx(pilotage, _ => _.route.uuid)
      const routeResult = routeUUID
        ? await dispatch(routeRequest(routeUUID, { isPrefetch: true }))
        : true
      prefetchStatus.route = !!routeResult
      if (routeResult && routeResult.stages) {
        const result = await allSettled(
          routeResult.stages
            .reduce((currentCharts, stage) => {
              const { chart } = stage
              return !chart ||
                currentCharts.find(item => item.uuid === chart.uuid)
                ? currentCharts
                : [...currentCharts, chart]
            }, [])
            .map(chart => dispatch(prefetchChartImage(chart)))
        )
        prefetchStatus.charts = !result.find(
          ({ status }) => status === 'rejected'
        )
      }
    }

    // extras
    if (force || !extras) {
      prefetchStatus.extras = !!(await dispatch(prefetchPilotageExtras(uuid)))
    }

    // weather (depends on weatherLocations being loaded by prefetchCommons)
    if (force || !weather) {
      prefetchStatus.weather = !!(await dispatch(
        prefetchPilotageWeather(pilotage)
      ))
    }

    // omc dukc
    if ((force || !omc) && pilotage && pilotage.vessel && pilotage.date && pilotage.movementType) {
      try {
        if (!process.env.REACT_APP_OMC_DISABLE) {
          prefetchStatus.omc = !!(await dispatch(
            prefetchPilotageOmc(pilotage.vessel.IMO, pilotage.date, pilotage.movementType, pilotage.port.uuid))
          )
        }
      } catch (err) {
        // should not cause prefetch error because it's not necessarily available for every pilotage
        console.error(err)
      }
    }

    // signatures
    if (force || !signatures) {
      prefetchStatus.signatures = !!(await dispatch(
        prefetchPilotageSignatures(pilotage)
      ))
    }

    const pilotageStatus =
      !prefetchStatus.weather ||
      !prefetchStatus.tugs ||
      !prefetchStatus.drawings ||
      !prefetchStatus.vessel ||
      !prefetchStatus.route ||
      !prefetchStatus.extras ||
      !prefetchStatus.signatures ||
      !prefetchStatus.charts
        ? PREFETCH_PILOTAGE_ERROR
        : PREFETCH_PILOTAGE_SUCCESS
    dispatch({
      type: pilotageStatus,
      payload: { ...prefetchStatus, status: pilotageStatus, uuid },
    })
    return Promise.resolve(pilotage)
  } catch (error) {
    dispatch({
      type: PREFETCH_PILOTAGE_ERROR,
      error,
      payload: { ...prefetchStatus, status: PREFETCH_PILOTAGE_ERROR, uuid },
    })
    return Promise.reject(error)
  }
}

export const prefetchPilotages = (portUuid, filterFn, forcePrefetch) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState()
    if (!canPrefetch(state)) {
      return false
    }
    let result = []
    let pilotages = pilotageListSelector(state)
    if (pilotages === null) {
      try {
        const result = await dispatch(pilotageListRequest(portUuid, true))
        pilotages = result.payload
      } catch (error) {}
    }
    let pilotagesToPrefetch = pilotages
      ? typeof filterFn === 'function'
        ? pilotages.filter(filterFn)
        : pilotages
      : null
    if (pilotagesToPrefetch && pilotagesToPrefetch.length > 0) {
      // filter out the ones which are prefteched or in progress
      // except if it is a forced prefetch
      pilotagesToPrefetch = forcePrefetch
        ? pilotagesToPrefetch
        : pilotagesToPrefetch.filter(
            ({ uuid }) =>
              !pilotagePrefetchIsInProgressSelector(uuid)(state) &&
              !pilotagePrefetchIsSuccessSelector(uuid)(state)
          )
      result = await allSettled(
        pilotagesToPrefetch.map(pilotage =>
          dispatch(prefetchPilotage(pilotage))
        )
      )
    }
    return Promise.resolve(result)
  } catch (error) {
    return Promise.reject(error)
  }
}

const PREFETCH_CHART_IMAGE = 'PREFETCH_CHART_IMAGE'
export const [
  PREFETCH_CHART_IMAGE_REQUEST,
  PREFETCH_CHART_IMAGE_SUCCESS,
  PREFETCH_CHART_IMAGE_ERROR,
  PREFETCH_CHART_IMAGE_CANCEL,
  PREFETCH_CHART_IMAGE_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_CHART_IMAGE)
export const prefetchChartImage = chart => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const state = getState()
    if (!canPrefetch(state)) {
      reject(new Error('Prefetch is not ready'))
      return false
    }
    const imageToken = imageTokenSelector(state)
    const isOnline = isOnlineSelector(state)
    if (isOnline && imageToken) {
      dispatch({
        type: PREFETCH_CHART_IMAGE_IN_PROGRESS,
        payload: chart,
      })
      const img = new Image()
      img.addEventListener('error', () => {
        dispatch({
          type: PREFETCH_CHART_IMAGE_ERROR,
          error: new Error(`Error loading chart image: ${chart.uuid}`),
          payload: chart,
        })
      })
      img.addEventListener('load', () => {
        dispatch({
          type: PREFETCH_CHART_IMAGE_SUCCESS,
          payload: chart,
        })
        resolve(chart)
      })
      img.src = getChartImageUrl(chart, imageToken)
    }
  })

// COMMONS
const PREFETCH_COMMONS_BASE = 'PILOTAGE_PREFETCH'
export const [
  PREFETCH_COMMONS_REQUEST,
  PREFETCH_COMMONS_SUCCESS,
  PREFETCH_COMMONS_ERROR,
  PREFETCH_COMMONS_CANCEL,
  PREFETCH_COMMONS_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_COMMONS_BASE)

export const prefetchCommons = (portUuid, ignoreStatus) => async (
  dispatch,
  getState
) => {
  const state = getState()
  if (!canPrefetch(state)) {
    return false
  }
  const commonsPrefetchStatus = commonsPrefetchStatusSelector(state)
  try {
    if (
      !ignoreStatus &&
      [PREFETCH_COMMONS_SUCCESS, PREFETCH_COMMONS_IN_PROGRESS].includes(
        commonsPrefetchStatus
      )
    ) {
      return
    }
    dispatch({
      type: PREFETCH_COMMONS_IN_PROGRESS,
    })
    dispatch(tugsRequest(portUuid))
    dispatch(extrasRequest(portUuid))
    dispatch(pilotsRequest())
    dispatch(berthsStationsRequest(portUuid))
    dispatch(weatherLocationsRequest(portUuid))
    dispatch({
      type: PREFETCH_COMMONS_SUCCESS,
    })
    return Promise.resolve(PREFETCH_COMMONS_SUCCESS)
  } catch (error) {
    dispatch({
      type: PREFETCH_COMMONS_ERROR,
      error,
    })
    return Promise.reject(error)
  }
}

// TIDE RATES
const PREFETCH_TIDE_RATES = 'PREFETCH_TIDE_RATES'
export const [
  PREFETCH_TIDE_RATES_REQUEST,
  PREFETCH_TIDE_RATES_SUCCESS,
  PREFETCH_TIDE_RATES_ERROR,
  PREFETCH_TIDE_RATES_CANCEL,
  PREFETCH_TIDE_RATES_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_TIDE_RATES)

export const prefetchTideRates = (tideRateStations, from, to) => async (
  dispatch,
  getState
) => {
  try {
    if (!canPrefetch(getState())) {
      return Promise.reject(new Error('No prefetch'))
    }
    dispatch({
      type: PREFETCH_TIDE_RATES_IN_PROGRESS,
    })

    const fetchResults = await fetchTideRatesAndGetResults(
      dispatch,
      tideRateStations,
      from,
      to
    )
    if (fetchResults.failureCount > 0) {
      return Promise.reject(
        new Error(
          `Failed to prefetch tide rates, success [${
            fetchResults.successCount
          }] success [${fetchResults.failureCount}] total [${
            fetchResults.totalCount
          }]`
        )
      )
    }

    dispatch({
      type: PREFETCH_TIDE_RATES_SUCCESS,
    })
    return Promise.resolve()
  } catch (error) {
    dispatch({
      type: PREFETCH_TIDE_RATES_ERROR,
      error,
    })
    return Promise.reject(error)
  }
}

// TIDES
const PREFETCH_TIDES = 'PREFETCH_TIDES'
export const [
  PREFETCH_TIDES_REQUEST,
  PREFETCH_TIDES_SUCCESS,
  PREFETCH_TIDES_ERROR,
  PREFETCH_TIDES_CANCEL,
  PREFETCH_TIDES_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_TIDES)

export const prefetchTides = (tideStations, from, to) => async (
  dispatch,
  getState
) => {
  try {
    if (!canPrefetch(getState())) {
      return Promise.reject(new Error('No prefetch'))
    }
    dispatch({
      type: PREFETCH_TIDES_IN_PROGRESS,
    })

    const fetchResults = await fetchTidesAndGetResults(
      dispatch,
      tideStations,
      TIDE_TYPE.ALL,
      from,
      to
    )
    if (fetchResults.failureCount > 0) {
      return Promise.reject(
        new Error(
          `Failed to prefetch tides, success [${
            fetchResults.successCount
          }] success [${fetchResults.failureCount}] total [${
            fetchResults.totalCount
          }]`
        )
      )
    }

    dispatch({
      type: PREFETCH_TIDES_SUCCESS,
    })
    return Promise.resolve()
  } catch (error) {
    dispatch({
      type: PREFETCH_TIDES_ERROR,
      error,
    })
    return Promise.reject(error)
  }
}

// TIDE EXTREMES
const PREFETCH_TIDE_EXTREMES = 'PREFETCH_TIDE_EXTREMES'
export const [
  PREFETCH_TIDE_EXTREMES_REQUEST,
  PREFETCH_TIDE_EXTREMES_SUCCESS,
  PREFETCH_TIDE_EXTREMES_ERROR,
  PREFETCH_TIDE_EXTREMES_CANCEL,
  PREFETCH_TIDE_EXTREMES_IN_PROGRESS,
] = createBasicActionTypes(PREFETCH_TIDE_EXTREMES)

export const prefetchTideExtremes = (tideStations, from, to) => async (
  dispatch,
  getState
) => {
  try {
    if (!canPrefetch(getState())) {
      return Promise.reject(new Error('No prefetch'))
    }
    dispatch({
      type: PREFETCH_TIDE_EXTREMES_IN_PROGRESS,
    })

    const fetchResults = await fetchTidesAndGetResults(
      dispatch,
      tideStations,
      TIDE_TYPE.EXTREMES,
      from,
      to
    )
    if (fetchResults.failureCount > 0) {
      return Promise.reject(
        new Error(
          `Failed to prefetch tide extremes, success [${
            fetchResults.successCount
          }] success [${fetchResults.failureCount}] total [${
            fetchResults.totalCount
          }]`
        )
      )
    }

    dispatch({
      type: PREFETCH_TIDE_EXTREMES_SUCCESS,
    })
    return Promise.resolve()
  } catch (error) {
    dispatch({
      type: PREFETCH_TIDE_EXTREMES_ERROR,
      error,
    })
    return Promise.reject(error)
  }
}

const fetchTidesAndGetResults = async (
  dispatch,
  tideStations,
  type,
  from,
  to
) => {
  let totalCount = tideStations.length
  let successCount = 0
  let failureCount = 0
  await allSettled(
    tideStations.map(async tideStation => {
      const success = !!(await dispatch(
        type === TIDE_TYPE.ALL
          ? tidesRequest(tideStation.uuid, from, to, { isPrefetch: true })
          : tideExtremesRequest(tideStation.uuid, from, to, {
              isPrefetch: true,
            })
      ))
      if (success) {
        successCount += 1
      } else {
        failureCount += 1
      }
      return success
    })
  )
  return {
    totalCount,
    successCount,
    failureCount,
  }
}

const fetchTideRatesAndGetResults = async (
  dispatch,
  tideRateStations,
  from,
  to
) => {
  let totalCount = tideRateStations.length
  let successCount = 0
  let failureCount = 0
  await allSettled(
    tideRateStations.map(async tideRateStation => {
      const success = !!(await dispatch(
        tideRatesRequest(tideRateStation.uuid, from, to, { isPrefetch: true })
      ))
      if (success) {
        successCount += 1
      } else {
        failureCount += 1
      }
      return success
    })
  )
  return {
    totalCount,
    successCount,
    failureCount,
  }
}

export const PREFETCH_RESET = 'PREFETCH_RESET'
export const prefetchReset = uuid => ({
  type: PREFETCH_RESET,
  payload: uuid,
})

export const prefetchResetAll = () => ({
  type: PREFETCH_RESET
})
