export const CANVAS_SET_ACTIVE_TOOL = 'CANVAS_SET_ACTIVE_TOOL'
export const CANVAS_SET_ACTIVE_COLOR = 'CANVAS_SET_ACTIVE_COLOR'
export const CANVAS_SET_ACTIVE_SYMBOL = 'CANVAS_SET_ACTIVE_SYMBOL'
export const CANVAS_START_SELECT_MODE = 'CANVAS_START_SELECT_MODE'
export const CANVAS_TOGGLE_MENU = 'CANVAS_TOGGLE_MENU'

export const setActiveColor = color => ({
  type: CANVAS_SET_ACTIVE_COLOR,
  payload: color,
})

export const setActiveTool = tool => ({
  type: CANVAS_SET_ACTIVE_TOOL,
  payload: tool,
})

export const setActiveSymbol = symbol => ({
  type: CANVAS_SET_ACTIVE_SYMBOL,
  payload: symbol,
})

export const toggleChartMenu = menu => ({
  type: CANVAS_TOGGLE_MENU,
  payload: menu,
})

export const startSelectMode = () => ({
  type: CANVAS_START_SELECT_MODE,
})
