import { CLEAR_PILOTAGE_DATA } from 'src/store/common/actions'
import {
  DHI_ERROR, DHI_IN_PROGRESS, DHI_REQUEST, DHI_SUCCESS
} from 'src/store/dhi/actions'

export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function dhiReducer (state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case CLEAR_PILOTAGE_DATA:
      return { ...INITIAL_STATE }
    case DHI_REQUEST:
      return {
        ...state,
        data: null,
        error: null,
      }
    case DHI_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
      }
    case DHI_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case DHI_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default dhiReducer
