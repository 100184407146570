import { TOAST_ADD, TOAST_REMOVE } from 'src/store/toast/actions'

const MAX_TOASTS = 10

const getInitialState = () => []

const reducer = (state = getInitialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case TOAST_ADD: {
      return state
        .filter(({ message }) => message !== payload.message)
        .slice(-MAX_TOASTS)
        .concat([payload])
    }
    case TOAST_REMOVE: {
      return state.filter(({ id }) => id !== payload.id)
    }
    default:
      return state
  }
}

export default reducer
