import { actions as routerActions } from 'redux-router5'

import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPilotages } from 'src/utils/api/pilotages'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { NEW_PILOTAGE, PILOTAGES } from 'src/router/routes'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { pilotageListSelector } from 'src/store/pilotageList/selectors'
import { isOnlineSelector } from 'src/store/ui/selectors'

const PILOTAGE_LIST_BASE = 'PILOTAGE_LIST'

export const [
  PILOTAGE_LIST_REQUEST,
  PILOTAGE_LIST_SUCCESS,
  PILOTAGE_LIST_ERROR,
  PILOTAGE_LIST_CANCEL,
  PILOTAGE_LIST_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_LIST_BASE)

export const PILOTAGE_LIST_SET_FILTER = 'PILOTAGE_LIST_SET_FILTER'
export const PILOTAGE_LIST_SET_ASCENDING = 'PILOTAGE_LIST_SET_ASCENDING'

export const pilotageListRequest = (portUuid, showErrorToast = true) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)
  const pilotages = pilotageListSelector(state)

  // do not fetch the pilotages when it is offline
  // because can override the changes
  if (!isOnline && pilotages && pilotages.length > 0) {
    return
  }
  dispatch(pilotageListInProgress())
  try {
    const response = await getPilotages(portUuid)
    const data = getPayloadFromResponse(response)
    dispatch(pilotageListLoaded(data, portUuid))
    return data
  } catch (error) {
    dispatch(pilotageListError(error, showErrorToast))
    return false
  }
}

export const pilotageListInProgress = () =>
  createInProgressAction(PILOTAGE_LIST_BASE)

export const pilotageListError = (error, showErrorToast) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_LIST_BASE, { error }))
  if (showErrorToast) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.LOAD_PILOTAGES_ERROR,
      })
    )
  }
}

export const pilotageListLoaded = (data, portUuid ) =>
  createSuccessAction(PILOTAGE_LIST_BASE, { data, portUuid })

export const startNewPilotage = () => routerActions.navigateTo(NEW_PILOTAGE)
export const openPilotageList = () => routerActions.navigateTo(PILOTAGES)

export const setFilter = (params) => (dispatch) => {
  dispatch({ type: PILOTAGE_LIST_SET_FILTER, filter: params })
  dispatch(routerActions.navigateTo(PILOTAGES, params))
}
export const setAscending = (isAscending) => (dispatch) => {
  dispatch({ type: PILOTAGE_LIST_SET_ASCENDING, ascending: isAscending })
}
