import styled from 'styled-components'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { RadioBtn } from 'src/components/atoms/CheckboxRadio'

const LinesTypeLabel = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  margin-left: 16px;
`

const LinesRadioBtnWrapper = styled.div`
  padding: 8px 24px 8px 8px;
  border-radius: 23px;
  background-color: ${({ theme }) => theme.palette.background.dark};
`

const OmcDistanceTimeSelector = ({ value: activeValue, onChange, readOnly }) => {
  const options = [
    { value: true, label: 'Distance' },
    { value: false, label: 'Time' },
  ]
  return (
    <Box display="flex">
      {options.map(({ value, label }, index) => (
        <Box key={value} pl={index > 0 ? 2 : 0}>
          <LinesRadioBtnWrapper>
            <RadioBtn
              label={<LinesTypeLabel>{label}</LinesTypeLabel>}
              checked={value === activeValue}
              disabled={readOnly}
              onChange={() => onChange(value)}
            />
          </LinesRadioBtnWrapper>
        </Box>
      ))}
    </Box>
  )
}

OmcDistanceTimeSelector.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export default OmcDistanceTimeSelector
