import React from 'react'
import PropTypes from 'prop-types'

import ChartMenuSection from 'src/components/molecules/ChartMenuSection'
import freehandImg from 'src/assets/images/icons/freehand.png'
import highlighterImg from 'src/assets/images/icons/highlighter.png'
import lineImg from 'src/assets/images/icons/line.png'
import arrowImg from 'src/assets/images/icons/arrow.png'
import textImg from 'src/assets/images/icons/text.png'
import eraserImg from 'src/assets/images/icons/eraser.png'
import rulerImg from 'src/assets/images/icons/ruler.png'
import { CHART_TOOL } from 'src/utils/drawingConstants'

const tools = [
  { src: freehandImg, value: CHART_TOOL.FREEHAND, alt: 'Freehand' },
  { src: highlighterImg, value: CHART_TOOL.HIGHLIGHTER, alt: 'Highlighter' },
  { src: lineImg, value: CHART_TOOL.STRAIGHT_LINE, alt: 'Line' },
  { src: arrowImg, value: CHART_TOOL.ARROW, alt: 'Arrow' },
  { src: rulerImg, value: CHART_TOOL.RULER, alt: 'Ruler', omitIfNoScale: true },
  { src: textImg, value: CHART_TOOL.TEXT, alt: 'Text' },
  { src: eraserImg, value: CHART_TOOL.ERASER, alt: 'Eraser' },
]

const ChartTools = props => {
  const options = !props.chartScale
    ? tools.filter(({ omitIfNoScale }) => !omitIfNoScale)
    : tools

  return (
    <ChartMenuSection
      {...props}
      isExpandedInitial
      label="Tools"
      options={options}
      renderItem={({ src, alt }) => <img src={src} alt={alt} />}
    />
  )
}

ChartTools.propTypes = {
  onSelect: PropTypes.func,
  current: PropTypes.string,
  chartScale: PropTypes.number,
}

export default ChartTools
