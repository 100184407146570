import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from 'react-router5'
import { Typography, Box, Divider } from '@material-ui/core'

import SubHeading from 'src/components/atoms/SubHeading'
import TugLinePositions from 'src/components/organisms/TugLinePositions'
import { pilotageTugsRequest } from 'src/store/pilotageTugs/actions'
import {
  pilotageSelector,
  isAssignedToActivePilotageSelector,
  isPilotageDoneSelector,
  isPilotageArchivedSelector,
  isPilotageCancelledSelector,
} from 'src/store/pilotage/selectors'
import { timezoneSelector } from 'src/store/ports/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'

const MainPilotageTugs = () => {
  const dispatch = useDispatch()
  const { route } = useRoute()
  const pilotageId = route.params.id

  const isAssigned = useSelector(isAssignedToActivePilotageSelector)
  const isPilotageDone = useSelector(isPilotageDoneSelector)
  const isPilotageArchived = useSelector(isPilotageArchivedSelector)
  const isPilotageCancelled = useSelector(isPilotageCancelledSelector)
  const isReadOnly =
    !isAssigned || isPilotageDone || isPilotageArchived || isPilotageCancelled

  const { data: pilotageRawData } = useSelector(pilotageSelector)

  const timezone = useSelector(timezoneSelector)
  const pilotageData = pilotageRawData ? formatPilotageData(pilotageRawData, timezone) : null

  useEffect(() => {
    dispatch(pilotageTugsRequest(pilotageId))
  }, [])

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      <Box display="flex" justifyContent="space-between">
        <Box mb={4}>
          <Typography variant="h1">Tug Details</Typography>
          <SubHeading>Select tug(s) and line(s) positions</SubHeading>
        </Box>
      </Box>
      <TugLinePositions readOnly={isReadOnly} />
    </>
  )
}

export default MainPilotageTugs
