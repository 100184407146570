import { createSelector } from 'reselect'
import { PreferencesState } from 'src/store/preferences/reducer'
import { FEATURE_FLAG_KEY, TIDAL_RATE_CONFIG_KEY, IPreferencesFeatureFlags, IPreferencesTidalRateConfig, VESSEL_FIELD_DISPLAY_CONFIG_KEY, IPreferencesVesselFieldDisplayConfig } from 'src/utils/api/preferences'

export const preferencesStateSelector = (state: any): PreferencesState =>
  state.preferences

export const preferencesSelector = createSelector(
  preferencesStateSelector,
  preferences =>
    // changing from IPreferences to IPreferences[], we need to wrap data stored the old way
    // can be removed in a future release when clients have been refreshed
    Array.isArray(preferences.data)
      ? preferences.data
      : typeof preferences.data === 'object' && preferences.data !== null
        ? [preferences.data]
        : []
)

export const preferencesLoadedSelector = createSelector(
  preferencesStateSelector,
  preferences => !!preferences.data
)

export const tidalRateConfigSelector = createSelector(
  preferencesSelector,
  preferences => {
    const config = preferences.find(({ key }) => key === TIDAL_RATE_CONFIG_KEY)
    return config && config.metadata as IPreferencesTidalRateConfig['metadata']
  }
)

export const vesselFieldDisplayConfigSelector = createSelector(
  preferencesSelector,
  preferences => {
    const config = preferences.find(({ key }) => key === VESSEL_FIELD_DISPLAY_CONFIG_KEY)
    return config && config.metadata as IPreferencesVesselFieldDisplayConfig['metadata']
  }
)

export const featureFlagsSelector = createSelector(
  preferencesSelector,
  preferences => {
    const featureFlags = preferences.find(({ key }) => key === FEATURE_FLAG_KEY)
    return featureFlags && featureFlags.metadata as IPreferencesFeatureFlags['metadata']
  }
)

export const featureFlagPilotCreatePilotageDisabled = createSelector(
  featureFlagsSelector,
  flags => (flags ? flags.pilot_create_pilotage_disabled : false)
)

export const featureFlagManualOnlineEnabled = createSelector(
  featureFlagsSelector,
  flags => (flags ? flags.manual_online_enabled : false)
)

export const featureFlagNoTugsExcludeInPdf = createSelector(
  featureFlagsSelector,
  flags => flags && flags.no_tugs_exclude_in_pdf_enabled
)

export const featureFlagResidualDiff = createSelector(
  featureFlagsSelector,
  flags => flags && flags.residual_diff_enabled
)

export const featureFlagResidualDiffIgnoreTime = createSelector(
  featureFlagsSelector,
  flags => flags && flags.residual_diff_ignore_time_enabled
)

export const featureFlagShowChecklistInMasterView = createSelector(
  featureFlagsSelector,
  flags => flags && flags.show_checklist_in_master_view
)

export const featureFlagActiveDefault = createSelector(
  featureFlagsSelector,
  flags => flags && flags.active_default_enabled
)

export const featureFlagHideWaypointLocation = createSelector(
  featureFlagsSelector,
  flags => flags && flags.hide_waypoint_location_enabled
)

export const featureFlagShowTimestampsInMasterView = createSelector(
  featureFlagsSelector,
  flags => flags && flags.show_timestamps_in_master_view
)

export const featureFlagShowPassFailInMasterView = createSelector(
  featureFlagsSelector,
  flags => flags && flags.show_passfail_in_master_view
)

export const featureFlagUseESignatures = createSelector(
  featureFlagsSelector,
  flags => (flags ? flags.use_e_signatures && !process.env.REACT_APP_ESIGN_DISABLE : false)
)

export const featureFlagUseBerthingConditions = createSelector(
  featureFlagsSelector,
  flags => flags && flags.use_berthing_conditions
)

export const featureFlagUseChartToolsGrid = createSelector(
  featureFlagsSelector,
  flags => flags && flags.use_chart_tools_grid
)

export const featureFlagShowOmcDukcGraphInMasterView = createSelector(
  featureFlagsSelector,
  flags => flags && flags.omc_config && flags.omc_config.show_graph_in_master_view
)

export const featureFlagTideRateSource = createSelector(
  featureFlagsSelector,
  flags => (flags && flags.tide_rate_source) || 'config'
)
