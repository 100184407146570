import styled from 'styled-components'

const LoginPaneSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  justify-content: ${({ align }) => align};
  align-items: center;
  width: 100%;
  position: relative;

  & input {
    font-size: 16px;
  }
`

export default LoginPaneSection

export const SmallLoginPaneSection = styled(LoginPaneSection)`
  flex: 0 1 240px;
`
