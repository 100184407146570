import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Box, Container, Typography } from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { Link, useRouteNode } from 'react-router5'
import { hasVisitedPilotagesList } from 'src/router/router'

import vesselIcon from 'src/assets/images/icons/vessel.png'
import planIcon from 'src/assets/images/icons/plan.png'
import summaryIcon from 'src/assets/images/icons/checklist.png'
import acceptanceIcon from 'src/assets/images/icons/acceptance.png'
import tidesIcon from 'src/assets/images/icons/Tide48x42.png'
import weatherIcon from 'src/assets/images/icons/Weather48x42.png'
import tugsIcon from 'src/assets/images/icons/Tug48x29.png'
import adminIcon from 'src/assets/images/icons/admin.png'
import {
  EDIT_PILOTAGE,
  EDIT_VESSEL,
  PILOTAGE_ACCEPTANCE,
  PILOTAGE_ADMIN,
  PILOTAGE_DETAIL,
  PILOTAGE_PLAN,
  PILOTAGE_RISKS,
  PILOTAGE_RISKS2,
  PILOTAGE_TUGS,
  PILOTAGE_SUMMARY,
  PILOTAGE_VESSEL
} from 'src/router/routes'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { pilotageRequest } from 'src/store/pilotage/actions'
import InfoBox from 'src/components/atoms/InfoBox'
import { CrossIcon } from 'src/components/atoms/Icons'
import withPilotageLoader from 'src/containers/withPilotageLoader'
import {
  PilotageVessel,
  PilotageRisks,
  PilotageAdmin,
  PilotagePlan,
  PilotageSummary,
  PilotageAcceptance,
} from 'src/containers/Pilotage'
import { defaultDateRange } from 'src/utils/date'
import { setFilter } from 'src/store/pilotageList/actions'
import { setSelectedPort } from 'src/store/ports/actions'
import { selectedPortSelector } from 'src/store/ports/selectors'
import MainPilotageWeather from 'src/containers/Pilotage/MainPilotageWeather'
import MainPilotageTugs from './../../../containers/Pilotage/MainPilotageTugs'
import { useFlowlist } from './../../../hooks/useFlowlist'

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 96px;
  background-color: ${({ theme }) => theme.palette.background.light};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`

const ContentContainer = styled(Container)`
  && {
    margin-left: 96px;
    width: calc(100% - 96px);
  }
`

const ItemWrapper = styled.div(
  ({ theme, isActive }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 13px;
    font-weight: ${theme.weights.fontWeightBold};
    background-color: ${isActive ? theme.palette.background.default : ''};
    color: ${theme.palette.text.primary};
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    width: 100%;
    max-height: 100px;
    flex: 1;

    & img {
      margin-bottom: ${theme.spacing(1)}px;
      width: 40%;
    }

    & a {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    @media screen and (max-height: 600px) {
      & img {
        width: 30%;
        margin-bottom: 3px;
      }
    }
  `
)

const BackButton = styled(ItemWrapper)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    width: 100%;
    max-height: 100px;
    min-height: 50px;
    flex: 1;
    color: ${theme.palette.primary.contrastText};

    & a:hover {
      color: ${theme.palette.primary.contrastText};
    }
  `
)

const DetailsLayout = () => {
  const dispatch = useDispatch()

  const { route } = useRouteNode(PILOTAGE_DETAIL)
  const pilotageId = route.params.id

  const { data, isLoading, error } = useSelector(pilotageSelector)
  const selectedPort = useSelector(selectedPortSelector)

  const retryLoadPilotage = () => {
    dispatch(pilotageRequest(pilotageId))
  }

  // EMPX-545 when a user comes in directly into a pilotage details page
  // from browser address, ie without first going into pilotage list page,
  // the selected port can be different from the port associated with the
  // pilotage. We set the selected port here, so when user goes back
  // to pilotage list, it will be correctly set.
  // As the setSelectedPort() call make additional network calls to BE
  // we only want to trigger that when the UUID of the port/org has changed.
  useEffect(() => {
    if (data && data.port && selectedPort) {
      const portUuid = data.port.uuid
      const orgUuid = data.port.organisation.uuid
      const portOrgChanged = selectedPort.uuid !== portUuid || selectedPort.organisation.uuid !== orgUuid
      if (portOrgChanged) {
        dispatch(setSelectedPort(data.port, data.port.organisation))
      }
    }
  }, [data && data.port, selectedPort])

  let content
  switch (route.name) {
    case PILOTAGE_VESSEL:
    case EDIT_VESSEL:
    case EDIT_PILOTAGE:
      content = <PilotageVessel />
      break
    case PILOTAGE_RISKS:
      content = <PilotageRisks />
      break
    case PILOTAGE_RISKS2:
      content = <MainPilotageWeather />
      break
    case PILOTAGE_TUGS:
      content = <MainPilotageTugs />
      break
    case PILOTAGE_PLAN:
      content = <PilotagePlan />
      break
    case PILOTAGE_SUMMARY:
      content = <PilotageSummary />
      break
    case PILOTAGE_ACCEPTANCE:
      content = <PilotageAcceptance />
      break
    case PILOTAGE_ADMIN:
      content = <PilotageAdmin />
      break
    default:
      content = null
  }

  const iconsOrder = useFlowlist()

  return (
    <Box display="flex">
      <ItemsContainer>
        <BackButton>
          <div
            onClick={() => {
              if (!hasVisitedPilotagesList) {
                // if they have landed on this page directly from url, navigate to pilotages
                dispatch(setFilter(defaultDateRange()))
              } else {
                window.history.back()
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <KeyboardArrowLeftIcon />
          </div>
        </BackButton>

        {iconsOrder.map(({ routeName, isShown }) => {
          if (isShown && routeName === PILOTAGE_VESSEL) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_VESSEL}>
                <Link
                  routeName={PILOTAGE_VESSEL}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={vesselIcon} alt="Vessel" />
                    <div>Vessel</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_RISKS) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_RISKS}>
                <Link
                  routeName={PILOTAGE_RISKS}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={tidesIcon} alt="Tides" />
                    <div>Tides</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_PLAN) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_PLAN}>
                <Link
                  routeName={PILOTAGE_PLAN}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={planIcon} alt="Plan" />
                    <div>Pilotage Plan</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_RISKS2) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_RISKS2}>
                <Link
                  routeName={PILOTAGE_RISKS2}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={weatherIcon} alt="Weather" />
                    <div>Weather</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_TUGS) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_TUGS}>
                <Link
                  routeName={PILOTAGE_TUGS}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={tugsIcon} alt="Tugs" />
                    <div>Tugs</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_SUMMARY) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_SUMMARY}>
                <Link
                  routeName={PILOTAGE_SUMMARY}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={summaryIcon} alt="Checklist" />
                    <div>Checklist</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_ACCEPTANCE) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_ACCEPTANCE}>
                <Link
                  routeName={PILOTAGE_ACCEPTANCE}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={acceptanceIcon} alt="Acceptance" />
                    <div>Acceptance</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          if (isShown && routeName === PILOTAGE_ADMIN) {
            return (
              <ItemWrapper key={routeName} isActive={route.name === PILOTAGE_ADMIN}>
                <Link
                  routeName={PILOTAGE_ADMIN}
                  routeParams={{ id: pilotageId }}
                  routeOptions={{ replace: true }}
                >
                  <Box width="100%">
                    <img src={adminIcon} alt="Admin" />
                    <div>Admin</div>
                  </Box>
                </Link>
              </ItemWrapper>
            )
          }
          return null
        })}
      </ItemsContainer>
      <ContentContainer>
        {!data && error && (
          <>
            <Typography variant="h1">View Pilotage</Typography>
            <Box my={4}>
              <InfoBox
                title="Error - Pilotage"
                buttonText="Retry"
                onButtonClick={retryLoadPilotage}
                loading={isLoading}
                Icon={CrossIcon}
              >
                Something went wrong trying to load this pilotage.
              </InfoBox>
            </Box>
          </>
        )}
        {/* TODO: use DetailsTransition when there is time to figure out the flickering issue */}
        {content}
      </ContentContainer>
    </Box>
  )
}

export default withPilotageLoader(DetailsLayout)
