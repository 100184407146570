import { addErrorToast } from 'src/store/toast/actions'
import { getDhiTransits } from 'src/utils/api/dhi'
import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction
} from 'src/utils/createAction'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const DHI_TRANSITS_BASE = 'DHI_TRANSITS'

export const [
  DHI_TRANSITS_REQUEST,
  DHI_TRANSITS_SUCCESS,
  DHI_TRANSITS_ERROR,
  DHI_TRANSITS_CANCEL,
  DHI_TRANSITS_IN_PROGRESS,
] = createBasicActionTypes(DHI_TRANSITS_BASE)

export const dhiTransitsRequest = (portUuid, pilotageStart, vesselIMO, opts) => async dispatch => {
  if (!opts || !opts.isPrefetch) {
    dispatch(dhiTransitsInProgress())
  }

  try {
    const data = await getDhiTransits(portUuid, pilotageStart, vesselIMO)
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiTransitsLoaded(data))
    }
    return Promise.resolve(data)
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiTransitsError(error, TOAST_MESSAGES.LOAD_DHI_ERROR))
    }
    return Promise.reject(error)
  }
}

export const dhiTransitsInProgress = () => createInProgressAction(DHI_TRANSITS_BASE)

export const dhiTransitsLoaded = data => createSuccessAction(DHI_TRANSITS_BASE, data)

export const dhiTransitsError = (error, message) => dispatch => {
  dispatch(createErrorAction(DHI_TRANSITS_BASE, error))
  dispatch(addErrorToast({ message }))
}
