const acceptNumbersOnly = (e, isAllowedCharacter) => {
  const isNumber = /^\d$/.test(e.key) && !e.shiftKey && !e.altKey && !e.metaKey

  const isTab = e.keyCode === 9
  const isArrow = e.keyCode >= 37 && e.keyCode <= 40
  const isDelete = e.key === 'Backspace' || e.key === 'Delete'
  const isPaste = e.metaKey && e.key === 'v'

  if (
    !(
      isTab ||
      isNumber ||
      isArrow ||
      isDelete ||
      isPaste ||
      (isAllowedCharacter && isAllowedCharacter(e))
    )
  ) {
    e.preventDefault()
  }
}

export const acceptIntegerOnly = e => {
  acceptNumbersOnly(e)
}

export const acceptDecimalOnly = e => {
  // Allow numeric input only and up to one decimal point
  acceptNumbersOnly(e, e => e.key === '.' && e.target.value.indexOf('.') === -1)
}

export const acceptTimeOnly = e => {
  // Allow numeric input only and up to one colon
  acceptNumbersOnly(e, e => e.key === ':' && e.target.value.indexOf(':') === -1)
}

export const getTimeInputOnChange = setValue => e => {
  let val = e.target.value
  if (
    e.nativeEvent.inputType !== 'deleteContentBackward' &&
    /^[0-9]{2}$/.test(e.target.value)
    // EMPX-621: remove check for 24hr check here,
    // since below it will add : once length is 4 anyways.
    // && Number(e.target.value) < 24
  ) {
    val += ':'
    e.target.value = val
  }

  if (/^[0-9]{4}$/.test(val)) {
    val = `${val.slice(0, 2)}:${val.slice(2, 4)}`
    e.target.value = val
  }

  setValue(val)
}

export const getTimeInputOnBlur = setValue => e => {
  const { value } = e.target
  let nextValue = value

  if (/^\d{3}$/.test(value)) {
    nextValue = `0${value[0]}:${value.slice(1)}`
  } else if (value) {
    const [hours = '00', minutes = '00'] = value.split(':')
    nextValue = `${hours.padStart(2, '0')}:${minutes.padEnd(2, '0')}`
  }
  setValue(nextValue)
}
