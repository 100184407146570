import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getPreferences, IPreferences } from 'src/utils/api/preferences'
import { selectedPortUuidSelector } from '../ports/selectors'

const PREFERENCES_BASE = 'PREFERENCES'

export const PREFERENCES_RESET = 'PREFERENCES_RESET'

export const [
  PREFERENCES_REQUEST,
  PREFERENCES_SUCCESS,
  PREFERENCES_ERROR,
  PREFERENCES_CANCEL,
  PREFERENCES_IN_PROGRESS,
] = createBasicActionTypes(PREFERENCES_BASE)

export const preferencesReset = () => (
  dispatch: any,
) => {
  dispatch({ type: PREFERENCES_RESET })
}

export const preferencesRequest = (showErrorToast = false) => async (
  dispatch: any,
  getState: any
) => {
  const portUuid = selectedPortUuidSelector(getState())

  dispatch(preferencesInProgress())

  try {
    const data = await getPreferences(portUuid)
    dispatch(preferencesLoaded(data))
    return data
  } catch (error) {
    dispatch(preferencesError(error, showErrorToast))
    return false
  }
}

export const preferencesInProgress = () =>
  createInProgressAction(PREFERENCES_BASE)

export const preferencesError = (error: Error, showErrorToast: boolean) => (
  dispatch: any
) => {
  dispatch(createErrorAction(PREFERENCES_BASE, error))
  if (showErrorToast) {
    dispatch(
      addErrorToast({
        message: TOAST_MESSAGES.LOAD_PREFERENCES_ERROR,
      })
    )
  }
}

export const preferencesLoaded = (data: IPreferences[]) =>
  createSuccessAction(PREFERENCES_BASE, data)
