import { createRouteCheck, updateRouteCheck } from 'src/utils/api/routes'

export const sync = syncItem => {
  const { isNew, entity } = syncItem
  const { uuid, ...entityWithoutUuid } = entity
  return isNew
    ? createRouteCheck(entityWithoutUuid)
    : updateRouteCheck(uuid, entity)
}

export default sync
