import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from 'react-router5'
import { Divider } from '@material-ui/core'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import { formatPilotageData } from 'src/utils/formatters'
import { PilotageChecklist } from 'src/components/organisms/PilotageChecklist'
import { PilotagePassFailChecklist } from 'src/components/organisms/PilotagePassFailChecklist'
import { PilotageNotes } from 'src/components/organisms/PilotageNotes'
import { timezoneSelector } from 'src/store/ports/selectors'
import InfoBarHeaderNoTitle from './InfoBarHeaderNoTitle'
import { pilotageExtrasRequest } from 'src/store/pilotageExtras/actions'

const PilotageSummary = () => {
  const dispatch = useDispatch()
  const { route } = useRoute()
  const pilotageUuid = route.params.id

  const { data: pilotageRawData } = useSelector(pilotageSelector)
  const timezone = useSelector(timezoneSelector)

  const pilotageData = pilotageRawData
    ? formatPilotageData(pilotageRawData, timezone)
    : null

  useEffect(
    () => {
      if (pilotageUuid) {
        dispatch(pilotageExtrasRequest(pilotageUuid))
      }
    },
    [pilotageUuid]
  )

  return (
    <>
      <InfoBarHeaderNoTitle pilotageData={pilotageData} />
      <Divider style={{ marginTop: 0 }}/>
      <PilotageChecklist />
      <PilotagePassFailChecklist />
      <PilotageNotes />
    </>
  )
}

export default PilotageSummary
