import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-konva'

const SvgSymbol = ({ renderData, color, opacity, ...props }) => {
  const { width, height, svg } = renderData

  const [img, setImg] = useState(false)

  useEffect(() => {
    // Parse the SVG string into a Document Object Model
    const parser = new DOMParser()
    const dom = parser.parseFromString(svg, 'image/svg+xml')

    // Set the width and height
    dom.documentElement.setAttribute('width', width)
    dom.documentElement.setAttribute('height', height)

    const styleRoot = dom.querySelector('#style-root')

    // Apply the color
    if (styleRoot && color) {
      styleRoot.setAttribute('fill', color)
      styleRoot.setAttribute('stroke', color)
    }

    // Apply opacity
    if (opacity) {
      styleRoot.setAttribute('fill-opacity', opacity)
      styleRoot.setAttribute('stroke-width', 0.3)
    }

    // Serialize the DOM back to string and convert to a blob
    const xmlSerializer = new XMLSerializer()
    const customSVG = xmlSerializer.serializeToString(dom)
    const blob = new Blob([customSVG], { type: 'image/svg+xml' })

    // Create an image from the blob
    const imageObj = new window.Image()
    imageObj.src = URL.createObjectURL(blob)
    imageObj.onload = () => setImg(imageObj)
  }, [])

  return (
    img && (
      <Image
        offsetX={0.5 * width}
        offsetY={0.5 * height}
        image={img}
        {...props}
        width={width}
        height={height}
      />
    )
  )
}

SvgSymbol.propTypes = {
  renderData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    svg: PropTypes.string,
  }),
  color: PropTypes.string,
  opacity: PropTypes.number
}

export default React.memo(SvgSymbol)
