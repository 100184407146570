import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { pilotageSelector } from 'src/store/pilotage/selectors'
import { pilotageRequest } from 'src/store/pilotage/actions'
import { sessionStatusSelector } from 'src/store/auth/selectors'
import { SESSION_STATUS } from 'src/utils/constants'
import { isOnlineSelector } from 'src/store/ui/selectors'

const usePilotageLoader = pilotageId => {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector(pilotageSelector)
  const sessionStatus = useSelector(sessionStatusSelector)
  const isOnline = useSelector(isOnlineSelector)

  useEffect(
    () => {
      if (
        pilotageId &&
        (sessionStatus === SESSION_STATUS.VALID ||
          (!isOnline && sessionStatus === SESSION_STATUS.UNKNOWN))
      ) {
        // TODO: after returning to landing and coming back to same pilotage, it's not refreshed
        // TODO: cancel any pending requests if id switches (potential for race condition)
        dispatch(pilotageRequest(pilotageId))
      }
    },
    [pilotageId, sessionStatus]
  )

  return {
    data: data && pilotageId === data.uuid ? data : null,
    isLoading,
  }
}

export default usePilotageLoader
