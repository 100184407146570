import idx from 'idx'

import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getSinglePayloadFromResponse } from 'src/utils/api/core'
import { getVesselByIMO, updateVessel } from 'src/utils/api/vessels'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { setUnsavedEntityStatus } from 'src/store/ui/actions'
import { isOnlineSelector } from 'src/store/ui/selectors'
import { vesselSelector } from 'src/store/vessel/selectors'
import { addEntity } from 'src/store/sync/actions'
import { ENTITY_NAME, SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { createSyncUuid } from 'src/utils/sync/vessel'
import { prefetchVessel } from 'src/store/prefetch/actions'
import * as Sentry from '@sentry/react'

const VESSEL_BASE = 'VESSEL'

export const [
  VESSEL_REQUEST,
  VESSEL_SUCCESS,
  VESSEL_ERROR,
  VESSEL_CANCEL,
  VESSEL_IN_PROGRESS,
] = createBasicActionTypes(VESSEL_BASE)

export const vesselRequest = (portUuid, imo, opts) => async dispatch => {
  if (!opts || !opts.isPrefetch) {
    dispatch(vesselInProgress())
  }

  try {
    const response = await getVesselByIMO(portUuid, imo)
    const data = getSinglePayloadFromResponse(response)
    if (!opts || !opts.isPrefetch) {
      dispatch(vesselLoaded(data))
    }
    return Promise.resolve(data)
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(vesselError(error, TOAST_MESSAGES.LOAD_VESSEL_ERROR))
    }
    return Promise.reject(error)
  }
}

export const vesselInProgress = () => createInProgressAction(VESSEL_BASE)

export const vesselLoaded = data => createSuccessAction(VESSEL_BASE, data)

export const vesselError = (error, message) => dispatch => {
  dispatch(createErrorAction(VESSEL_BASE, error))
  dispatch(addErrorToast({ message }))
}

export const updateVesselRequest = (
  imo,
  data,
  successMessage,
  errorMessage
) => async (dispatch, getState) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)
  try {
    if (isOnline) {
      dispatch(vesselInProgress())
      const response = await updateVessel(imo, data)
      const payload = getSinglePayloadFromResponse(response)
      dispatch(vesselLoaded(payload))
      dispatch(
        addSuccessToast({
          message: successMessage || TOAST_MESSAGES.UPDATE_VESSEL_SUCCESS,
        })
      )
      dispatch(prefetchVessel(idx(data, _ => _.port.uuid), imo, true))
    } else {
      const syncEntityUuid = createSyncUuid(imo)
      const originalVesselState = vesselSelector(state)
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_VESSEL,
          entity: {
            uuid: imo,
            data: {
              ...(originalVesselState.data || {}),
              ...data,
            },
          },
        })
      )
    }
    dispatch(setUnsavedEntityStatus(ENTITY_NAME.VESSEL, false))
    return true
  } catch (error) {
    dispatch(
      vesselError(error, errorMessage || TOAST_MESSAGES.UPDATE_VESSEL_ERROR)
    )
    if (Sentry && Sentry.captureException) {
      Sentry.captureException(error)
    }
    return false
  }
}

export const updateVesselSafeWorkLoadRequest = (
  imo,
  safeWorkLoad,
  port
) => async dispatch => {
  dispatch(
    updateVesselRequest(imo, {
      safeWorkLoad,
      port,
    })
  )
}
