export const SET_UNSAVED_PILOTAGE_CHANGES = 'SET_UNSAVED_PILOTAGE_CHANGES'
export const SET_UNSAVED_VESSEL_CHANGES = 'SET_UNSAVED_VESSEL_CHANGES'
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS'
export const SET_SERVICE_WORKER_STATUS = 'SET_SERVICE_WORKER_STATUS'
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE'
export const SET_UNSAVED_STATUS = 'SET_UNSAVED_STATUS'

export const setOnlineStatus = onlineStatus => ({
  type: SET_ONLINE_STATUS,
  payload: onlineStatus,
})

export const setServiceWorkerStatus = status => ({
  type: SET_SERVICE_WORKER_STATUS,
  payload: status,
})

export const updateAvailable = () => ({
  type: UPDATE_AVAILABLE,
})

export const setUnsavedEntityStatus = (entityName, isUnsaved) => ({
  type: SET_UNSAVED_STATUS,
  payload: { entityName, isUnsaved },
})
