import React, { useState, useRef } from 'react'
import { roundTwoDecimalPlace } from 'src/utils/formatters'
import styled from 'styled-components'

const BollardSwlInputContainer = styled.div`
    background: none;
    height: 40px;
    padding: 2px 4px;
    .swl-label {
        color: #95A6B6;
        text-align: center;
        font-size: 8px;
        font-weight: bold;
        line-height: 10px;
        height: 10px;
    }
    .swl-input {
        border: 1px solid #1F8EFA;
        background: #20293C;
        border-radius: 4px;
        input[type=number] {
            background: #20293C;
            color: #fff;
            border: none;
            outline: none;
            width: 30px;
            padding: 4px 6px;
            border-radius: 4px;
            font-size: 12px;
        }
        span {
            padding: 5px;
            color: #95A6B6;
        }
        input[type=submit] {
            display: none;
        }
    }
`

const BollardSwlInput: React.FC<{
    value?: string
    onChange(value?: string): void,
}> = ({ value, onChange }) => {

    const inputRef = useRef<HTMLInputElement | null>(null)
    const [swl, setSwl] = useState(value)
    const save = () => 
        swl !== value && 
        onChange(swl !== undefined && swl !== '' ? String(roundTwoDecimalPlace(Number(swl))) : undefined)

    // this is to prevent the input being covered by iOS keyboard
    const scrollToInput = () => {
        setTimeout(() => {
            inputRef.current && inputRef.current.scrollIntoView({
                behavior: 'smooth'
            })   
        }, 500)
    }

    return (
        <BollardSwlInputContainer ref={inputRef}>
            <div className="swl-label">SWL</div>
            <form className="swl-input" onSubmit={save}>
                <input 
                    type="number"
                    min="0"
                    step="0.01"
                    max="500"
                    onChange={evt => {
                        if (evt.target.checkValidity()) {
                            setSwl(evt.target.value)
                        } else {
                            evt.preventDefault()
                        }
                    }}
                    onFocus={scrollToInput}
                    onBlur={save}
                    value={swl}
                    autoFocus
                />
                <span>t</span>
            </form>
        </BollardSwlInputContainer>
    )
}

export default BollardSwlInput
