import idx from 'idx'
import { v4 as uuidv4 } from 'uuid'
import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPilotageExtras } from 'src/utils/api/pilotages'
import {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/api/core'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { createPilotageExtra, updatePilotageExtra } from 'src/utils/api/extras'

import { isOnlineSelector } from 'src/store/ui/selectors'
import { addEntity } from 'src/store/sync/actions'
import { createSyncUuid } from 'src/utils/sync/pilotageExtras'
import { entityByUuidSelector } from 'src/store/sync/selectors'
import { pilotageExtrasSelector } from 'src/store/pilotageExtras/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { SYNC_ENTITY_TYPES } from 'src/utils/constants'
import { prefetchPilotageExtras } from 'src/store/prefetch/actions'

const PILOTAGE_EXTRAS_BASE = 'PILOTAGE_EXTRAS'
const PILOTAGE_CREATE_EXTRA_BASE = 'PILOTAGE_CREATE_EXTRA'
const PILOTAGE_UPDATE_EXTRA_BASE = 'PILOTAGE_UPDATE_EXTRA'

export const [
  PILOTAGE_EXTRAS_REQUEST,
  PILOTAGE_EXTRAS_SUCCESS,
  PILOTAGE_EXTRAS_ERROR,
  PILOTAGE_EXTRAS_CANCEL,
  PILOTAGE_EXTRAS_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_EXTRAS_BASE)

export const [
  PILOTAGE_CREATE_EXTRA_REQUEST,
  PILOTAGE_CREATE_EXTRA_SUCCESS,
  PILOTAGE_CREATE_EXTRA_ERROR,
  PILOTAGE_CREATE_EXTRA_CANCEL,
  PILOTAGE_CREATE_EXTRA_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_CREATE_EXTRA_BASE)

export const [
  PILOTAGE_UPDATE_EXTRA_REQUEST,
  PILOTAGE_UPDATE_EXTRA_SUCCESS,
  PILOTAGE_UPDATE_EXTRA_ERROR,
  PILOTAGE_UPDATE_EXTRA_CANCEL,
  PILOTAGE_UPDATE_EXTRA_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_UPDATE_EXTRA_BASE)

// ----------------------- FETCH ----------------------- //

export const pilotageExtrasRequest = (pilotageUuid, opts) => async (dispatch) => {
  if (!opts || !opts.isPrefetch) {
    dispatch(pilotageExtrasInProgress())
  }
  try {
    let data
    // EMPX-667 selectors already merge entities in sync with cached data, so don't
    // need to do check here.
    const response = await getPilotageExtras(pilotageUuid)
    data = getPayloadFromResponse(response) || []
    if (!opts || !opts.isPrefetch) {
      dispatch(pilotageExtrasLoaded(data))
    }
    return data
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(pilotageExtrasError(error))
    }
    return false
  }
}

export const pilotageExtrasInProgress = () =>
  createInProgressAction(PILOTAGE_EXTRAS_BASE)

export const pilotageExtrasError = payload => dispatch => {
  dispatch(createErrorAction(PILOTAGE_EXTRAS_BASE, payload))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_PILOTAGE_EXTRAS_ERROR,
    })
  )
}

export const pilotageExtrasLoaded = data =>
  createSuccessAction(PILOTAGE_EXTRAS_BASE, data)

// ----------------------- CREATE ----------------------- //

export const createPilotageExtraRequest = (
  data,
  successMessage,
  errorMessage
) => async (dispatch, getState) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)

  try {
    if (isOnline) {
      dispatch(createPilotageExtraInProgress())
      const response = await createPilotageExtra(data)
      const payload = getSinglePayloadFromResponse(response)
      dispatch(createPilotageExtraSuccess(payload, successMessage))
      // prefetch the extras to re-cache the new result
      // only if we have to update the cache (second argument)
      dispatch(prefetchPilotageExtras(data.pilotage.uuid), true)
    } else {
      const syncEntityUuid = createSyncUuid(data.pilotage.uuid)
      const inSyncPilotageExtras = entityByUuidSelector(syncEntityUuid)(state)
      // for now it is always empty since we only have checklist extras
      // later on we can have other extras
      let syncData = idx(inSyncPilotageExtras, _ => _.entity.data) || []
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_EXTRAS,
          pilotageUuid: data.pilotage.uuid,
          entity: {
            pilotageUuid: data.pilotage.uuid,
            uuid: syncEntityUuid,
            data: [
              ...syncData,
              {
                ...data,
                // we need a generated uuid in order to check which item
                // has been changed in the list
                uuid: uuidv4(),
                isNew: true,
              },
            ],
          },
        })
      )
    }
    return true
  } catch (error) {
    dispatch(createPilotageExtraError(error, errorMessage))
    return false
  }
}

export const createPilotageExtraInProgress = () =>
  createInProgressAction(PILOTAGE_CREATE_EXTRA_BASE)

export const createPilotageExtraError = (payload, errorMessage) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_CREATE_EXTRA_BASE, payload))
  if (errorMessage) {
    dispatch(
      addErrorToast({
        message: errorMessage,
      })
    )
  }
}

export const createPilotageExtraSuccess = (
  data,
  successMessage
) => dispatch => {
  dispatch(createSuccessAction(PILOTAGE_CREATE_EXTRA_BASE, data))
  dispatch(updatePilotagExtrasCache())
  if (successMessage) {
    dispatch(
      addSuccessToast({
        message: successMessage,
      })
    )
  }
}

// ----------------------- UPDATE ----------------------- //

export const updatePilotageExtraRequest = (
  pilotageExtraUuid,
  data,
  successMessage,
  errorMessage
) => async (dispatch, getState) => {
  const state = getState()
  const isOnline = isOnlineSelector(state)

  try {
    if (isOnline) {
      dispatch(updatePilotageExtraInProgress())
      const response = await updatePilotageExtra(pilotageExtraUuid, data)
      const payload = getSinglePayloadFromResponse(response)
      dispatch(updatePilotageExtraSuccess(payload, successMessage))
    } else {
      const inStoreExtrasState = pilotageExtrasSelector(state)
      const inStoreExtras = idx(inStoreExtrasState, _ => _.data) || []
      const syncEntityUuid = createSyncUuid(data.pilotage.uuid)
      const inSyncPilotageExtras = entityByUuidSelector(syncEntityUuid)(state)
      let syncEntity = idx(inSyncPilotageExtras, _ => _.entity)
      let syncData = idx(syncEntity, _ => _.data)
      let dataWithUuid = { ...data, uuid: pilotageExtraUuid }
      syncData = syncData
        ? syncData.map(sd =>
          sd.uuid === pilotageExtraUuid ? { ...sd, ...dataWithUuid } : sd
        )
        : inStoreExtras.map(extra =>
          extra.uuid === pilotageExtraUuid ? dataWithUuid : extra
        )
      await dispatch(
        addEntity({
          uuid: syncEntityUuid,
          type: SYNC_ENTITY_TYPES.PILOTAGE_EXTRAS,
          pilotageUuid: data.pilotage.uuid,
          entity: {
            ...(syncEntity || {}),
            pilotageUuid: data.pilotage.uuid,
            uuid: syncEntityUuid,
            data: syncData,
          },
        })
      )
    }
    return true
  } catch (error) {
    dispatch(updatePilotageExtraError(error, errorMessage))
    return false
  }
}

export const updatePilotageExtraInProgress = () =>
  createInProgressAction(PILOTAGE_UPDATE_EXTRA_BASE)

export const updatePilotageExtraError = (payload, errorMessage) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_UPDATE_EXTRA_BASE, payload))
  if (errorMessage) {
    dispatch(
      addErrorToast({
        message: errorMessage,
      })
    )
  }
}

export const updatePilotageExtraSuccess = (
  data,
  successMessage
) => dispatch => {
  dispatch(createSuccessAction(PILOTAGE_UPDATE_EXTRA_BASE, data))
  dispatch(updatePilotagExtrasCache())
  if (successMessage) {
    dispatch(
      addSuccessToast({
        message: successMessage,
      })
    )
  }
}

export const updatePilotagExtrasCache = () => (dispatch, getState) => {
  dispatch(
    pilotageExtrasRequest(pilotageUuidSelector(getState()), {
      isPrefetch: true,
    })
  )
}
