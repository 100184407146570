import { createSelector } from 'reselect'
import { selectedPortUuidSelector } from 'src/store/ports/selectors'
import { EXTRA_TYPE } from 'src/utils/constants'

export const extrasStateSelector = state => state.extras

export const extrasSelector = createSelector(
  extrasStateSelector,
  selectedPortUuidSelector,
  (extras, portUuid) =>
    extras.data
      ? extras.data.filter(extra => extra.port.uuid === portUuid)
      : extras.data
)

export const guidelinesSelector = createSelector(
  extrasSelector,
  extras =>
    extras
      ? extras.find(item => item.extraType === EXTRA_TYPE.GUIDELINES)
      : null
)

export const termsSelector = createSelector(
  extrasSelector,
  extras =>
    extras ? extras.find(item => item.extraType === EXTRA_TYPE.TERMS) : null
)

export const externalLink1Selector = createSelector(
  extrasSelector,
  extras =>
    extras
      ? extras.find(item => item.extraType === EXTRA_TYPE.EXTERNAL_LINK_1)
      : null
)

export const externalLink2Selector = createSelector(
  extrasSelector,
  extras =>
    extras
      ? extras.find(item => item.extraType === EXTRA_TYPE.EXTERNAL_LINK_2)
      : null
)

export const externalLink3Selector = createSelector(
  extrasSelector,
  extras =>
    extras
      ? extras.find(item => item.extraType === EXTRA_TYPE.EXTERNAL_LINK_3)
      : null
)

export const getPortExtraSelector = extraType =>
  createSelector(
    extrasSelector,
    extras => {
      if (!extras) {
        return null
      }
      return extras.find(item => item.extraType === extraType) || null
    }
  )
