import { round } from 'src/utils/formatters'
import { calculateSafetyMargin } from 'src/utils/constraint'

function calculateDynamicUnderKeelClearance({
  constraint,
  value,
  maxDraft,
  tideValue,
  rissagaAllowance,
  swellAllowance,
  squatAllowance,
  overrides,
}) {
  const errors = []
  if (maxDraft == null) {
    errors.push('Vessel max. draft is missing')
  }

  if (errors.length) {
    return { errors }
  }

  const minAvailableDepth = round(value, 2)

  // use NaN for missing/non-numeric value, as empty string gets converted to 0.
  const maxDraftValue = typeof maxDraft === 'number'
    ? round(maxDraft, 2)
    : NaN

  const { calculatedSafetyMargin: calculatedSafetyMarginRaw, unitUsed } = calculateSafetyMargin(constraint, maxDraftValue)

  const calculatedSafetyMargin = !isNaN(overrides.safetyMargin) ? Number(overrides.safetyMargin) : calculatedSafetyMarginRaw

  const requiredDepth =
    maxDraftValue +
    calculatedSafetyMargin +
    rissagaAllowance +
    swellAllowance +
    squatAllowance

  const tide = round(tideValue, 2)
  const tideCorrectionFactor = constraint.correctionFactor
  const correctedTideHeight = tide * tideCorrectionFactor
  const availableDepth = minAvailableDepth + correctedTideHeight
  const staticResult = availableDepth - maxDraftValue
  const ukcDyna = availableDepth - requiredDepth
  const actualUkc = ukcDyna + calculatedSafetyMargin
  const result = round(ukcDyna, 2)

  const variables = {
    maxDraft: maxDraftValue,
    minAvailableDepth,
    tideValue,
    calculatedSafetyMargin,
    calculatedSafetyMarginRaw,
    availableDepth,
    requiredDepth,
    tideCorrectionFactor,
    correctedTideHeight,
    staticResult,
    actualUkc,
    unitUsed
  }

  return {
    result,
    rawResult: round(staticResult, 2),
    variables
  }
}

export default calculateDynamicUnderKeelClearance
