import { calculatePilotageOnlyChangeset, calculateVesselChangeset } from 'src/utils/calculatePilotageChangeset'
import { PILOTAGE_STATUS } from 'src/utils/constants'

export default function getPilotageChangesNeedingReview (pilotage, user) {
  const {
    alteredByUser,
    pilot,
    pilotSecond,
    status,
    previousData,
  } = pilotage || {}

  // There can be a slight time difference on the BE between reviewedAt and updatedAt
  // due to updatedAt being at the database layer. Add a threshold for which if the
  // difference between the two times falls within, then we consider them equal.
  // NOTE: When a pilotage is in ARCH/CANC/DONE, it cannot be edited, so we do not need
  // to exclude them in the logic below. However, we currently link pilotages to
  // vessel by reference, so updating the vessel name in another pilotage (in Plan state)
  // will cause an indicator change in an ARCH/CANC/DONE pilotage. For now, we add
  // ARCH/CANC/DONE in the logic below, until we fix up the "link by reference" issue.
  // EMPX-313: now that we check the actual changeset (piltoage and vessel) to determine
  // if there is a change, we don't need the reviewedAt check here anymore.
  const hasChanged =
    !!pilotage &&
    status !== PILOTAGE_STATUS.DRAFT &&
    status !== PILOTAGE_STATUS.ARCHIVED &&
    status !== PILOTAGE_STATUS.CANCELLED &&
    status !== PILOTAGE_STATUS.DONE &&
    !!previousData

  const pilotageChangeSet = calculatePilotageOnlyChangeset(pilotage, previousData)
  const isChangeByCurrentUserPilotage =
    !!alteredByUser && !!user && alteredByUser.uuid === user.uuid

  const vesselChangeSet = calculateVesselChangeset(pilotage, previousData)
  const alteredByUserVessel = pilotage.alteredByUserVessel
  const isChangeByCurrentUserVessel =
    !!alteredByUserVessel && !!user && alteredByUserVessel.uuid === user.uuid

  const isChangeByOtherUser = (!isChangeByCurrentUserPilotage && (Object.keys(pilotageChangeSet).length > 0)) ||
    (!isChangeByCurrentUserVessel && (Object.keys(vesselChangeSet).length > 0))

  // Only show user any changes if user is either primary/secondary assignee
  const isCurrentUserPilot = !!pilot && !!user && pilot.uuid === user.uuid

  const isCurrentUserPilotSecond =
    !!pilotSecond && !!user && pilotSecond.uuid === user.uuid

  const isCurrentUserEitherAssignee =
    isCurrentUserPilot || isCurrentUserPilotSecond

  if (hasChanged && isChangeByOtherUser && isCurrentUserEitherAssignee) {
    return { ...pilotageChangeSet, ...vesselChangeSet }
  }
  return null
}
