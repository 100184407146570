import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Container } from '@material-ui/core'

import AppHeader from 'src/components/molecules/AppHeader'

const ExtraHeaderContent = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px ${theme.spacing(3)}px;
    background-color: ${theme.palette.background.light};
    border: solid ${theme.palette.background.defaultFaded};
    border-width: 1px 0;
  `
)

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.palette.background.light};
`

const FixedHeaderLayout = ({
  disablePortSelector,
  headerContent,
  children,
}) => (
  <>
    <HeaderWrapper>
      <AppHeader disablePortSelector={disablePortSelector} />
      {headerContent && (
        <ExtraHeaderContent>{headerContent}</ExtraHeaderContent>
      )}
    </HeaderWrapper>
    <Container>{children}</Container>
  </>
)

FixedHeaderLayout.propTypes = {
  disablePortSelector: PropTypes.bool,
  headerContent: PropTypes.node,
  children: PropTypes.node,
}

export default FixedHeaderLayout
