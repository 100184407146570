import styled from 'styled-components'

import empxLogoSrc from 'src/assets/images/empx-logo.png'

const EmpxLogo = styled.img.attrs({
  alt: 'eMPX',
  src: empxLogoSrc,
})`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

export default EmpxLogo
