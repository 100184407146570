import { useEffect, useState } from 'react'

import {
  ATTACHMENT_OPERATION,
  ATTACHMENT_STATUS,
} from 'src/components/organisms/Attachments/constants'

const useAttachments = ({
  savedAttachments,
  upload,
  remove,
  onError,
  onSuccess,
}) => {
  const [attachments, setAttachments] = useState([])

  useEffect(
    () => {
      setAttachments(attachments => {
        const unsaved = attachments.filter(
          item =>
            item.status === ATTACHMENT_STATUS.ERROR ||
            (item.status === ATTACHMENT_STATUS.UPLOADING &&
              !savedAttachments.find(a => a.name === item.name))
        )

        return [
          ...savedAttachments.map(item => ({
            ...item,
            status: ATTACHMENT_STATUS.COMPLETED,
          })),
          ...unsaved,
        ]
      })
    },
    [savedAttachments]
  )

  const onUploadSingle = async file => {
    let updatedAttachments
    try {
      updatedAttachments = await upload(file)
      if (onSuccess) {
        onSuccess({
          attachments: updatedAttachments,
          operation: ATTACHMENT_OPERATION.UPLOAD,
        })
      }
    } catch (error) {
      if (onError) {
        onError({ error, file, operation: ATTACHMENT_OPERATION.UPLOAD })
      }
      updatedAttachments = null
    }

    if (!updatedAttachments) {
      setAttachments(attachments =>
        attachments.map(item =>
          item.file === file
            ? { ...item, status: ATTACHMENT_STATUS.ERROR }
            : item
        )
      )
    }
  }

  const onUpload = files => {
    setAttachments(attachments => [
      ...attachments,
      ...files.map(file => ({
        file,
        status: ATTACHMENT_STATUS.UPLOADING,
        name: file.name,
        mimeType: file.type,
        date: new Date().toISOString(),
      })),
    ])

    files.forEach(onUploadSingle)
  }

  const onRemove = async attachment => {
    if (attachment.status === ATTACHMENT_STATUS.ERROR) {
      setAttachments(attachments =>
        attachments.filter(item => item.file !== attachment.file)
      )
    } else {
      setAttachments(attachments =>
        attachments.map(item =>
          item.name === attachment.name
            ? { ...item, status: ATTACHMENT_STATUS.DELETING }
            : item
        )
      )

      let updatedAttachments
      try {
        updatedAttachments = await remove(attachment)
        setAttachments(updatedAttachments)
        onSuccess({
          attachments: updatedAttachments,
          operation: ATTACHMENT_OPERATION.DELETE,
        })
        return true
      } catch (error) {
        if (onError) {
          onError({
            error,
            attachment,
            operation: ATTACHMENT_OPERATION.DELETE,
          })
          setAttachments(attachments =>
            attachments.map(item =>
              item.name === attachment.name
                ? { ...item, status: ATTACHMENT_STATUS.COMPLETED }
                : item
            )
          )
        }
        updatedAttachments = null
        return false
      }
    }
  }

  const onRetry = attachment => {
    setAttachments(attachments =>
      attachments.map(item => {
        if (item.name === attachment.name) {
          return { ...item, status: ATTACHMENT_STATUS.UPLOADING }
        }
        return item
      })
    )
    onUploadSingle(attachment.file)
  }

  const isUploading = attachments.some(
    item => item.status === ATTACHMENT_STATUS.UPLOADING
  )

  return {
    attachments,
    isUploading,
    onUpload,
    onRemove,
    onRetry,
  }
}

export default useAttachments
