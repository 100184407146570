import { formatCalendarDate, calendarDateAtTimeZone, padCalendarDateString } from 'src/utils/date'
import { labelSorter } from 'src/utils/sorting'
import * as Sentry from "@sentry/react"

export interface SearchQuery {
  search?: string
  status?: string
  movement?: string
  pilot?: string // uuid
  vessel?: string // IMO
  startDate?: string // ISO calendar string e.g: 2020-12-30 (yyyy-mm-dd) with NO time or timezone info
  endDate?: string
}

// Partially covers pilotage properties (only those relevant to search)
// @todo fully typed interface for DTO - or use openapi generated DTO
interface PilotageItem {
  pilot?: Pilot
  pilotSecond?: Pilot
  movementType: string
  status: string
  vessel?: Vessel
  vesselMaxDraft: number
  date: string // Full ISO string
  fromLocation: Location
  toLocation: Location
  port: Port
  route: Route
  sideTo: string
}
interface Pilot {
  uuid: string
  metadata?: {
    firstName: string
    lastName: string
  }
}
interface Vessel {
  name: string
  IMO: string
}
interface Location {
  name: string
}
interface Port {
  name: string
}
interface Route {
  name: string
}

export const editable: Array<keyof FilterOptions> = [
  'status',
  'movement',
  'pilot',
  'vessel',
]

export const dateRangeToString = (query: SearchQuery) => {
  const range = [query.startDate, query.endDate]
    .filter(date => date !== undefined && date !== '')
    .map(d => formatCalendarDate(padCalendarDateString(d)))
    .join(' - ')
  return range ? `Date Range: ${range}` : ''
}

export const queryToString = (
  query: SearchQuery,
  filterOptions: FilterOptions
) =>
  queryGetActiveKeys(query)
    .map(key => {
      const value = query[key]
      const options = filterOptions[key]
      const option =
        value && options && options.find(option => option.value === value)
      const label = option && option.label
      return label && `${key}: ${label}`
    })
    .filter(s => s)
    .join(', ')

export const queryGetActiveKeys = (query: SearchQuery) =>
  editable.filter(key => query[key] !== undefined)

const searchMatchesAny = (
  searchTerm: string | undefined,
  props: Array<string | false | undefined>
) => {
  if (!searchTerm) {
    return true
  }
  for (const prop of props) {
    if (typeof prop === 'string' && prop.toLowerCase().includes(searchTerm)) {
      return true
    }
  }
}

// 'Sent' can match against 'SentRead' | 'SentUnread' | 'SentNewComment'
const matchStatus = (itemStatus: string, queryStatus?: string) =>
  !queryStatus ||
  (queryStatus === 'Sent' && itemStatus.indexOf('Sent') === 0) ||
  itemStatus === queryStatus

interface DateIsInRangeProps {
  target: number
  start?: number
  end?: number
}

const dateIsInRange = ({ target, start, end }: DateIsInRangeProps) => {
  if (!start && !end) {
    return true
  }

  const startTime = start || 0
  const endTime = end || Infinity

  return target >= startTime && target <= endTime
}

export interface FilterOption {
  value: string
  label: string
}

export interface FilterOptions {
  pilot: FilterOption[]
  movement: FilterOption[]
  status: FilterOption[]
  vessel: FilterOption[]
}

// FilterOptions are derived from unique values in the full unfiltered pilotage result set
export const extractFilterOptions = (
  pilotages: PilotageItem[]
): FilterOptions => {
  const pilotOptions: Record<string, string> = {}
  const movementOptions: Record<string, string> = {}
  const statusOptions: Record<string, string> = {}
  const vesselOptions: Record<string, string> = {}

  for (const pilotage of pilotages) {
    const { pilot, pilotSecond, movementType, status, vessel } = pilotage

    if (pilot) {
      pilotOptions[pilot.uuid] = getPilotName(pilot)
    }
    if (pilotSecond) {
      pilotOptions[pilotSecond.uuid] = getPilotName(pilotSecond)
    }
    if (vessel && vessel.IMO && vessel.name) {
      vesselOptions[vessel.IMO] = vessel.name
    }
    movementOptions[movementType] = movementType

    // Workaround for muddy status enum:
    // 'SentRead', 'SentUnread', 'SentNewComment' are treated as 'Sent' for UI purposes
    // The filtering function correspondingly matches against all
    const statusMapped = status.indexOf('Sent') === 0 ? 'Sent' : status
    statusOptions[statusMapped] = statusMapped
  }

  const toOptions = (optionSet: Record<string, string>): FilterOption[] =>
    Object.keys(optionSet)
      .map(key => ({ value: key, label: optionSet[key] }))
      .sort(labelSorter)

  return {
    pilot: toOptions(pilotOptions),
    movement: toOptions(movementOptions),
    status: toOptions(statusOptions),
    vessel: toOptions(vesselOptions),
  }
}

// @todo use an interface for Pilotage
// @todo unit test this
export const makePilotageMatcher = (query: SearchQuery, timeZone: string) => {
  // the start and end dates are given as calendar dates, from these
  // we want to calculate the start of the start date at the port timezone
  // and the end of the end date at the port timezone

  query.startDate = padCalendarDateString(query.startDate)
  query.endDate = padCalendarDateString(query.endDate)

  const startDate = query.startDate
    ? calendarDateAtTimeZone(query.startDate, timeZone).valueOf()
    : undefined

  const endDate = query.endDate
    ? calendarDateAtTimeZone(query.endDate, timeZone, 23, 59, 59, 999).valueOf() // include up to end of day
    : undefined

  return (item: PilotageItem) => {
    try {
      return (
        // pilot
        (!query.pilot ||
          (item.pilot && item.pilot.uuid === query.pilot) ||
          (item.pilotSecond && item.pilotSecond.uuid === query.pilot)) &&
        // movement
        (!query.movement || item.movementType === query.movement) &&
        // status
        matchStatus(item.status, query.status) &&
        // vessel
        (!query.vessel || (item.vessel && item.vessel.IMO === query.vessel)) &&
        // date range
        dateIsInRange({
          target: new Date(item.date).valueOf(), // item.date is an iso string
          start: startDate,
          end: endDate,
        }) &&
        // search
        searchMatchesAny(query.search, [
          item.pilot && getPilotName(item.pilot),
          item.pilotSecond && getPilotName(item.pilotSecond),
          item.fromLocation && item.fromLocation.name,
          item.toLocation && item.toLocation.name,
          item.port && item.port.name,
          item.route && item.route.name,
          item.sideTo,
          item.vessel && item.vessel.name,
          item.vessel && item.vessel.IMO,
        ])
      )
    } catch (error) {
      Sentry.captureException(error)
      return true
    }
  }
}

const getPilotName = ({ metadata }: Pilot) =>
  metadata ? `${metadata.firstName} ${metadata.lastName}` : ''
