import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as routerActions } from 'redux-router5'

import { PILOTAGE_STATUS } from 'src/utils/constants'
import { DRAFT_PILOTAGE, REVIEW_PILOTAGE } from 'src/router/routes'
import { pilotageSelector } from 'src/store/pilotage/selectors'
import getPilotageChangesNeedingReview from 'src/utils/getPilotageChangesNeedingReview'
import { authUserSelector } from 'src/store/auth/selectors'

interface Result {
  isRedirecting: boolean
}

const useDraftRedirect: (id: string) => Result = pilotageUuid => {
  // For draft pilotages, redirect the user to the draft pilotage definition screen.
  // Make sure to replace the current history entry so the user can still use the
  // back button (when in desktop browsers).
  const { data } = useSelector(pilotageSelector)
  const user = useSelector(authUserSelector)
  const dispatch = useDispatch()

  const isRedirecting = useRef(false)
  if (!isRedirecting.current && data && data.uuid === pilotageUuid) {
    if (data.status === PILOTAGE_STATUS.DRAFT) {
      isRedirecting.current = true
      dispatch(
        routerActions.navigateTo(
          DRAFT_PILOTAGE,
          { id: pilotageUuid },
          {
            replace: true,
          }
        )
      )
    } else if (getPilotageChangesNeedingReview(data, user)) {
      isRedirecting.current = true
      dispatch(
        routerActions.navigateTo(
          REVIEW_PILOTAGE,
          { id: pilotageUuid },
          {
            replace: true,
          }
        )
      )
    }
  }

  return {
    isRedirecting: isRedirecting.current,
  }
}

export default useDraftRedirect
