import {
  TUGS_IN_PROGRESS,
  TUGS_ERROR,
  TUGS_SUCCESS,
} from 'src/store/tugs/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function tugsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case TUGS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case TUGS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }
    case TUGS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}

export default tugsReducer
