import {
  MovementType,
  PilotageLocation,
  PilotageModeType,
  SideTo,
  TransferMethodType,
} from 'src/types/Pilotage'

export interface Change<T> {
  previous: T
  current: T
}

export interface PilotageChangeSet {
  date?: Change<string>
  movementType?: Change<MovementType>
  fromLocation?: Change<PilotageLocation>
  toLocation?: Change<PilotageLocation>
  route?: Change<any> // TODO: replace when we have Route interface
  pilot?: Change<any> // TODO: replace when we have Pilot interface
  pilotSecond?: Change<any> // TODO: replace when we have Pilot interface
  sideTo?: Change<SideTo>
  vessel?: Change<any> // TODO: replace when we have Vessel interface
  vesselMaxDraft?: Change<number | null>
  notes?: Change<string | null>
  pilotageMode?: Change<PilotageModeType | null>
  transferMethod?: Change<TransferMethodType | null>
  vesselFwdDraft?: Change<number | null>
  vesselAftDraft?: Change<number | null>
  vesselDisplacement?: Change<number | null>
  vesselAgent?: Change<string>
}

type Field = {
  name: keyof PilotageChangeSet
  compareFn?: (a: any, b: any) => boolean
}

const vesselCompareFn = (a: any, b: any) =>
  (!a && !b) || (a && b && a.IMO === b.IMO && a.name === b.name)

// EMPX-468: when introducing a new field, it will be NULL from DB, but undefined in previous_data
// In order to prevent a change from -- to -- because of null vs undefined, we add a falsey check
// in this comparator.
const primitiveCompareFn = (a: any, b: any) => (!a && !b) || (a === b)

const defaultEntityCompareFn = (a: any, b: any) =>
  (!a && !b) || (a && b && a.uuid === b.uuid)

const textCompareFn = (a: any, b: any) =>
  (!a && !b) || (a && b && a.trim() === b.trim())

export function calculatePilotageOnlyChangeset (
  current: any,
  previous: any
): PilotageChangeSet {
  const changeSet: PilotageChangeSet = Object.create(null)

  // The list of fields in the pilotage we want to check for changes, along with
  // comparison functions specifying how to compare 2 objects for equality.
  const fieldsToCheck: Array<Field> = [
    { name: 'date', compareFn: primitiveCompareFn },
    { name: 'movementType', compareFn: primitiveCompareFn },
    { name: 'fromLocation' },
    { name: 'toLocation' },
    { name: 'route' },
    { name: 'pilot' },
    { name: 'pilotSecond' },
    { name: 'sideTo', compareFn: primitiveCompareFn },
    { name: 'vesselMaxDraft', compareFn: primitiveCompareFn },
    { name: 'notes', compareFn: textCompareFn },
    { name: 'pilotageMode', compareFn: primitiveCompareFn },
    { name: 'transferMethod', compareFn: primitiveCompareFn },
    { name: 'vesselFwdDraft', compareFn: primitiveCompareFn },
    { name: 'vesselAftDraft', compareFn: primitiveCompareFn },
    { name: 'vesselDisplacement', compareFn: primitiveCompareFn },
    { name: 'vesselAgent', compareFn: primitiveCompareFn },
  ]

  fieldsToCheck.forEach(field => {
    const { name, compareFn = defaultEntityCompareFn } = field
    const currentValue = current[name]
    const previousValue = previous[name]
    const isChanged = !compareFn(currentValue, previousValue)

    if (isChanged) {
      changeSet[name] = {
        previous: previousValue,
        current: currentValue,
      }
    }
  })

  return changeSet
}

export function calculateVesselChangeset (
  current: any,
  previous: any
): PilotageChangeSet {
  const changeSet: PilotageChangeSet = Object.create(null)

  // The list of fields in the pilotage we want to check for changes, along with
  // comparison functions specifying how to compare 2 objects for equality.
  const fieldsToCheck: Array<Field> = [
    { name: 'vessel', compareFn: vesselCompareFn },
  ]

  fieldsToCheck.forEach(field => {
    const { name, compareFn = defaultEntityCompareFn } = field
    const currentValue = current[name]
    const previousValue = previous[name]
    const isChanged = !compareFn(currentValue, previousValue)

    if (isChanged) {
      changeSet[name] = {
        previous: previousValue,
        current: currentValue,
      }
    }
  })

  return changeSet
}

export function calculatePilotageChangeset (
  current: any,
  previous: any
): PilotageChangeSet {
  const changeSetPilotage: PilotageChangeSet = calculatePilotageOnlyChangeset(current, previous)
  const changeSetVessel: PilotageChangeSet = calculateVesselChangeset(current, previous)
  return { ...changeSetPilotage, ...changeSetVessel }
}
