import { addErrorToast } from 'src/store/toast/actions'
import { getNcos } from 'src/utils/api/dhi'
import {
    createBasicActionTypes,
    createErrorAction,
    createInProgressAction,
    createSuccessAction
} from 'src/utils/createAction'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const DHI_BASE = 'DHI'

export const [
  DHI_REQUEST,
  DHI_SUCCESS,
  DHI_ERROR,
  DHI_CANCEL,
  DHI_IN_PROGRESS,
] = createBasicActionTypes(DHI_BASE)

export const dhiRequest = (portUuid, imo, pilotageStart, opts) => async dispatch => {
  if (!opts || !opts.isPrefetch) {
    dispatch(dhiInProgress())
  }

  try {
    const data = await getNcos(portUuid, imo, pilotageStart)
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiLoaded(data))
    }
    return Promise.resolve(data)
  } catch (error) {
    if (!opts || !opts.isPrefetch) {
      dispatch(dhiError(error, TOAST_MESSAGES.LOAD_DHI_ERROR))
    }
    return Promise.reject(error)
  }
}

export const dhiInProgress = () => createInProgressAction(DHI_BASE)

export const dhiLoaded = data => createSuccessAction(DHI_BASE, data)

export const dhiError = (error, message) => dispatch => {
  dispatch(createErrorAction(DHI_BASE, error))
  dispatch(addErrorToast({ message }))
}
