import React from 'react'

const WarningIcon: React.FC = props => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5844 14.4215L12.5194 1.43418C11.3843 -0.476792 8.61722 -0.479331 7.48058 1.43418L0.415894 14.4215C-0.744497 16.3742 0.660386 18.847 2.93472 18.847H17.065C19.3375 18.847 20.7448 16.3762 19.5844 14.4215ZM10 16.5032C9.35398 16.5032 8.82812 15.9773 8.82812 15.3313C8.82812 14.6853 9.35398 14.1595 10 14.1595C10.646 14.1595 11.1719 14.6853 11.1719 15.3313C11.1719 15.9773 10.646 16.5032 10 16.5032ZM11.1719 11.8157C11.1719 12.4617 10.646 12.9876 10 12.9876C9.35398 12.9876 8.82812 12.4617 8.82812 11.8157V5.95633C8.82812 5.31032 9.35398 4.78446 10 4.78446C10.646 4.78446 11.1719 5.31032 11.1719 5.95633V11.8157Z"
      fill="#FFC94F"
    />
  </svg>
)

export default React.memo(WarningIcon)
