import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatToTimeZone } from 'date-fns-timezone'
import { format } from 'date-fns'
import { v1 as uuidv1 } from 'uuid'

import { getPortExtraSelector } from 'src/store/extras/selectors'
import { getPilotageExtraSelector } from 'src/store/pilotageExtras/selectors'
import { pilotageUuidSelector } from 'src/store/pilotage/selectors'
import { EXTRA_TYPE } from 'src/utils/constants'
import {
  createPilotageExtraRequest,
  updatePilotageExtraRequest,
} from 'src/store/pilotageExtras/actions'
import { pilotageRequest } from 'src/store/pilotage/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { timezoneSelector } from 'src/store/ports/selectors'
import flags from 'src/utils/flags'
import { DateTime } from 'luxon'

const portExtraSelector = getPortExtraSelector(EXTRA_TYPE.TIMESTAMP_CHECKLIST)
const pilotageExtraSelector = getPilotageExtraSelector(
  EXTRA_TYPE.TIMESTAMP_CHECKLIST
)

const useTimestamps = () => {
  const timeZone = useSelector(timezoneSelector)
  const portExtra = useSelector(portExtraSelector)
  const pilotageExtra = useSelector(pilotageExtraSelector)
  const pilotageUuid = useSelector(pilotageUuidSelector)
  const dispatch = useDispatch()
  const saveTimer = useRef(null)
  const [data, setData] = useState([])
  const [error, setError] = useState(false)

  useEffect(
    () => {
      let data

      if (pilotageExtra) {
        data = pilotageExtra.metadata.value.map(item => ({
          ...item,
          isValidTime: true,
          isValidTimeEta: true,
        }))
      } else if (portExtra) {
        data = portExtra.metadata.value.map(item => ({
          ...item,
          isValidTime: true,
          isValidTimeEta: true,
        }))
      } else {
        data = []
      }
      // Due to late introduction of uuids on the admin side, existing lists
      // cannot be assumed to have item uuids. To make sure it still works,
      // generate them here.
      data = data.map(item => (item.uuid ? item : { ...item, uuid: uuidv1() }))
      setData(data)
    },
    [portExtra, pilotageExtra]
  )

  const updateItem = timestamp => {
    const newData = data.map(item =>
      item.uuid === timestamp.uuid ? timestamp : item
    )
    setData(newData)

    if (saveTimer.current) {
      clearTimeout(saveTimer.current)
    }

    saveTimer.current = setTimeout(() => save(newData), 1000)
  }

  const validateTime = t => /^(((0|1)[0-9])|2[0-3]):[0-5][0-9]$/.test(t)

  const editTime = (item, editedTime) => {
    updateItem({
      ...item,
      editedTime,
      isValidTime:
        validateTime(editedTime) ||
        (!editedTime && (!item.dateTime || !item.editedDate)),
    })
  }

  const editDate = (item, editedDate) => {
    updateItem({
      ...item,
      editedDate,
      isValidTime: !!item.dateTime || validateTime(item.editedTime),
    })
  }

  const editTimeEta = (item, editedTimeEta) => {
    updateItem({
      ...item,
      editedTimeEta,
      isValidTimeEta:
        validateTime(editedTimeEta) ||
        (!editedTimeEta && (!item.dateTimeEta || !item.editedDateEta)),
    })
  }

  const editDateEta = (item, editedDateEta) => {
    updateItem({
      ...item,
      editedDateEta,
      isValidTimeEta: !!item.dateTimeEta || validateTime(item.editedTimeEta),
    })
  }

  const clearDateTime = (item) => {
    updateItem({
      ...item,
      dateTime: undefined,
      editedTime: undefined,
      editedDate: undefined,
      isValidTime: true,
    })
  }

  const clearDateTimeEta = (item) => {
    updateItem({
      ...item,
      dateTimeEta: undefined,
      editedTimeEta: undefined,
      editedDateEta: undefined,
      isValidTimeEta: true,
    })
  }

  const recordNow = item => {
    updateItem({
      ...item,
      dateTime: new Date().toISOString(),
      editedTime: undefined,
      editedDate: undefined,
      isValidTime: true,
    })
  }

  const save = async data => {
    // Item is only invalid if it is not empty and either time or date
    // is missing or the entered time is not a valid format.
    const anyInvalid = data.some(item => {
      if (!item.dateTime) {
        if (!item.editedDate && !item.editedTime) {
          // empty, so the item is not invalid
          return false
        }
        // invalid if one of date or time is missing, or time is invalid
        return !(item.editedDate && item.editedTime && item.isValidTime)
      }
      // existing datetime set, it's invalid if the time is edited but not valid
      // or has been removed
      return !item.isValidTime || item.editedTime === ''
    })

    const anyInvalidEta = data.some(item => {
      if (!item.dateTimeEta) {
        if (!item.editedDateEta && !item.editedTimeEta) {
          // empty, so the item is not invalid
          return false
        }
        // invalid if one of date or time is missing, or time is invalid
        return !(item.editedDateEta && item.editedTimeEta && item.isValidTimeEta)
      }
      // existing datetime set, it's invalid if the time is edited but not valid
      // or has been removed
      return !item.isValidTimeEta || item.editedTimeEta === ''
    })
    if (anyInvalid || anyInvalidEta) {
      return
    }

    // only save the metadata we need (label, dateTime)
    const metadata = {
      value: data.map(
        ({ dateTime, editedDate, editedTime, isValidTime,
          dateTimeEta, editedDateEta, editedTimeEta, isValidTimeEta, ...rest }) => {

          const haveTimeToSave = dateTime || editedDate || editedTime
          const haveTimeEtaToSave = dateTimeEta || editedDateEta || editedTimeEta
          if (!haveTimeToSave && !haveTimeEtaToSave) {
            return rest
          }

          // If the user edited the date, we use the normal format function because
          // the datepicker is not timezone aware
          let dateTimeFinal
          if (haveTimeToSave) {
            const d = editedDate
              ? format(editedDate, 'DD/MM/YY')
              : formatToTimeZone(dateTime, 'DD/MM/YY', { timeZone })

            const t = editedTime
              ? `${editedTime}:00`
              : formatToTimeZone(dateTime, 'HH:mm:ss', { timeZone })

            const dateLuxon = DateTime.fromFormat(`${t} ${d}`, 'HH:mm:ss dd/MM/yy', { zone: timeZone })
            dateTimeFinal = dateLuxon.toISO()
          }

          let dateTimeFinalEta
          if (haveTimeEtaToSave) {
            const d = editedDateEta
              ? format(editedDateEta, 'DD/MM/YY')
              : formatToTimeZone(dateTimeEta, 'DD/MM/YY', { timeZone })

            const t = editedTimeEta
              ? `${editedTimeEta}:00`
              : formatToTimeZone(dateTimeEta, 'HH:mm:ss', { timeZone })

            const dateLuxon = DateTime.fromFormat(`${t} ${d}`, 'HH:mm:ss dd/MM/yy', { zone: timeZone })
            dateTimeFinalEta = dateLuxon.toISO()
          }

          return {
            ...rest,
            dateTime: dateTimeFinal,
            dateTimeEta: dateTimeFinalEta,
          }
        }
      ),
    }

    const payload = {
      pilotage: { uuid: pilotageUuid },
      metadata,
      extraType: EXTRA_TYPE.TIMESTAMP_CHECKLIST,
    }

    let wasSuccessful

    if (!pilotageExtra) {
      if (metadata.value.some(item => {
        const hasDateTime = !!item.dateTime
        const hasDateTimeEta = !!item.dateTimeEta
        return hasDateTime || hasDateTimeEta
      })) {
        wasSuccessful = await dispatch(
          createPilotageExtraRequest(
            payload,
            TOAST_MESSAGES.SAVE_TIMESTAMPS_SUCCESS,
            TOAST_MESSAGES.SAVE_TIMESTAMPS_ERROR
          )
        )
      } else {
        return
      }
    } else if (pilotageExtra) {
      wasSuccessful = await dispatch(
        updatePilotageExtraRequest(
          pilotageExtra.uuid,
          payload,
          TOAST_MESSAGES.SAVE_TIMESTAMPS_SUCCESS,
          TOAST_MESSAGES.SAVE_TIMESTAMPS_ERROR
        )
      )
    }

    setError(!wasSuccessful)
    if (flags.PLAN_STATUS_UPDATE_ENABLED) {
      if (wasSuccessful) {
        // update causes a status update, so refetch the latest from the BE
        dispatch(pilotageRequest(pilotageUuid))
      }
    }
  }

  const retry = () => save(data)

  return {
    data,
    editTime,
    editDate,
    editTimeEta,
    editDateEta,
    recordNow,
    clearDateTime,
    clearDateTimeEta,
    error,
    retry,
  }
}

export default useTimestamps
