import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction,
} from 'src/utils/createAction'
import { getPayloadFromResponse } from 'src/utils/api/core'
import { addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { getPilots } from 'src/utils/api/users'

const PILOTS_BASE = 'PILOTS_BASE'

export const [
  PILOTS_REQUEST,
  PILOTS_SUCCESS,
  PILOTS_ERROR,
  PILOTS_CANCEL,
  PILOTS_IN_PROGRESS,
] = createBasicActionTypes(PILOTS_BASE)

export const pilotsRequest = () => async dispatch => {
  dispatch(pilotsInProgress())

  try {
    const response = await getPilots()
    const data = getPayloadFromResponse(response)
    dispatch(pilotsLoaded(data))
  } catch (error) {
    dispatch(pilotsError(error))
  }
}

export const pilotsInProgress = () => createInProgressAction(PILOTS_BASE)

export const pilotsError = error => dispatch => {
  dispatch(createErrorAction(PILOTS_BASE, error))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.LOAD_PILOTS_ERROR,
    })
  )
}

export const pilotsLoaded = data => createSuccessAction(PILOTS_BASE, data)
